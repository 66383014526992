import get from 'lodash/get'
import settings from '../../settings'
import ensureSlash from './ensureSlash'

const getRequestEndpoint = (
  blobContainer,
  requestUrl = get(settings, 'uploads.requestUrl')
) => (requestUrl != null ? ensureSlash(requestUrl) + blobContainer : requestUrl)

export default getRequestEndpoint
