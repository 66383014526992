const globals = Object.assign(
  {
    settings: {},
    messages: {},
    features: {},
    publicUrl: '',
    root: '',
  },
  typeof global !== 'undefined' ? global.zeus : {}
)

export default globals
