import PropTypes from 'prop-types'

export const valuesType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  address: PropTypes.string,
  comments: PropTypes.string,
  newsletterConsent: PropTypes.bool,
  usePrepform: PropTypes.bool,
})

export const valuesDefaultProps = {}
