import { css } from 'styled-components'

import invitaFonts from './invita'
import magnetFonts from './magnet'
import marbodalFonts from './marbodal'
import noremaFonts from './norema'
import hthFonts from './hth'
import unoformFonts from './unoform'
import sigdalFonts from './sigdal'
import novartFonts from './novart'
import settings from '../app/components/settings'

const availableFonts = {
  invita: invitaFonts,
  marbodal: marbodalFonts,
  magnet: magnetFonts,
  sigdal: sigdalFonts,
  norema: noremaFonts,
  novart: novartFonts,
  hthdk: hthFonts,
  hthfi: hthFonts,
  hthse: hthFonts,
  hthno: hthFonts,
  hthde: hthFonts,
  unoform: unoformFonts,
  unoformse: unoformFonts,
  unoformno: unoformFonts,
  unoformbe: unoformFonts,
}

const brandFonts =
  css`
    ${availableFonts[settings.brand]}
  ` || css``

export default brandFonts
