import TypographyFactory from './TypographyFactory'
import Link from './Link'

const ImagePageTitle = TypographyFactory('h1', 'imagePageTitle')
const PageTitle = TypographyFactory('h1', 'pageTitle')
const Headline = TypographyFactory('h2', 'headline')
const SubHeadline = TypographyFactory('h3', 'subHeadline')
const Title = TypographyFactory('h4', 'title')
const Preamble = TypographyFactory('p', 'preamble')
const Paragraph = TypographyFactory('p', 'paragraph')

export {
  ImagePageTitle,
  PageTitle,
  Headline,
  SubHeadline,
  Title,
  Preamble,
  Paragraph,
  Link,
}
