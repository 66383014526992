import { graphql } from '@apollo/client/react/hoc'
import AppointmentQuery from '../../../graphql/Appointment.graphql'
import CmsPageQuery from '../../../graphql/CmsPageQuery.graphql'
import ServicesQuery from '../../../graphql/ServicesQuery.graphql'
import CreateAppointmentMutation from '../../../graphql/CreateAppointmentMutation.graphql'
import ListStaffMembersForStoreQuery from '../../../graphql/ListStaffMembersForStoreQuery.graphql'
import RetrieveAddressQuery from '../../../graphql/RetrieveAddressQuery.graphql'
import SearchAddressQuery from '../../../graphql/SearchAddressQuery.graphql'
import SearchOpenAuthStoresByAddressQuery from '../../../graphql/SearchOpenAuthStoresByAddressQuery.graphql'
import StoreWithDistanceQuery from '../../../graphql/StoreWithDistanceQuery.graphql'
import UpdateBookingMutation from '../../../graphql/UpdateBookingMutation.graphql'
import { appBaseUrl } from '../../../utils/appBaseUrl'
import settings from '../../components/settings'

const fetchStores = graphql(SearchOpenAuthStoresByAddressQuery, {
  name: 'storesByAddressData',
  options: ({ selectedAddress }) => ({
    variables: {
      input: {
        includeFakes: true, // always use true
        city: selectedAddress.city,
        countryName: selectedAddress.countryName,
        line1: selectedAddress.line1,
        line2: selectedAddress.line2,
        line3: selectedAddress.line3,
        postalCode: selectedAddress.postalCode,
        latitude: selectedAddress.latitude,
        longitude: selectedAddress.longitude,
      },
    },
    fetchPolicy: 'no-cache',
  }),
  skip: ({ selectedAddress }) => selectedAddress === null,
})

const fetchStaffMembers = graphql(ListStaffMembersForStoreQuery, {
  name: 'listStaffMembersForStoreData',
  options: ({ selectedStore }) => ({
    variables: {
      storeId: selectedStore.id,
    },
  }),
})

const fetchStoreWithDistance = graphql(StoreWithDistanceQuery, {
  name: 'StoreWithDistance',
  options: ({ storeId, selectedAddress }) => {
    // eslint-disable-next-line camelcase
    const { id, label, __typename, ...input } = selectedAddress || {}
    return {
      variables: {
        storeId,
        input,
      },
      fetchPolicy: 'no-cache',
    }
  },
  skip: ({ storeId, selectedAddress }) =>
    storeId === null || selectedAddress === null,
})

const fetchPageContent = graphql(CmsPageQuery, {
  name: 'pageContent',
  options: () => ({
    variables: {
      url: appBaseUrl,
    },
  }),
})

const searchAddressQuery = graphql(SearchAddressQuery, {
  name: 'searchAddressId',
  options: () => ({
    variables: {
      input: {
        searchText: '',
        refinementId: settings.addressPrefix,
        limit: 1,
      },
    },
  }),
  skip: ({ selectedAddress }) => selectedAddress === null,
})

const retrieveAddressQuery = graphql(RetrieveAddressQuery, {
  name: 'retrieveAddressQuery',
  options: ({ searchAddressId }) => ({
    variables: {
      id:
        searchAddressId &&
        searchAddressId.searchAddress &&
        searchAddressId.searchAddress.addresses[0].id,
    },
  }),
  skip: ({ searchAddressId }) =>
    searchAddressId === null ||
    (searchAddressId && searchAddressId.loading) ||
    (searchAddressId &&
      searchAddressId.searchAddress &&
      searchAddressId.searchAddress.addresses &&
      searchAddressId.searchAddress.addresses.length === 0),
})

const createBooking = graphql(CreateAppointmentMutation)
const updateBooking = graphql(UpdateBookingMutation)

const getBooking = graphql(AppointmentQuery, {
  name: 'appointment',
  options: ({ appointmentId }) => ({
    variables: {
      id: appointmentId,
    },
  }),
  skip: ({ appointmentId }) => !appointmentId,
})

const fetchServices = graphql(ServicesQuery, {
  name: 'services',
  options: ({ selectedStore }) => ({
    variables: {
      subsystem: selectedStore.simplyBookMeSubsystem,
    },
  }),
})

export {
  fetchStoreWithDistance,
  fetchStores,
  fetchStaffMembers,
  fetchPageContent,
  fetchServices,
  createBooking,
  updateBooking,
  getBooking,
  searchAddressQuery,
  retrieveAddressQuery,
}
