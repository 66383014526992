import React from 'react'
import ReactDOM from 'react-dom'
import settings from './app/components/settings'
import Root from './app/components/root'
import App from './app'
import './polyfills'
import './styles/global.css'
import { appBasePath } from './utils/appBasePath'

const renderApp = () => {
  ReactDOM.render(
    <Root
      apiUrl={settings.gatewayAPI}
      brand={settings.brand}
      basename={appBasePath}
    >
      <App />
    </Root>,
    document.getElementById('root')
  )
}

renderApp()

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', renderApp)
}
