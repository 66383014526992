import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M.807 17.775L17.777.805l1.415 1.414-16.97 16.97z" />
    <path d="M2.22.804l16.972 16.97-1.414 1.415L.808 2.22z" />
  </svg>
)

const StyledCloseIcon = styled(CloseIcon)`
  path {
    fill: currentColor;
  }
`

const StyledButton = styled.button`
  border: 0;
  padding: 7px;
  margin: 0;
  cursor: pointer;
  color: ${prop('color', prop('theme.overlay.closeButton.color'))};
  background: ${prop(
    'background',
    prop('theme.overlay.closeButton.background', 'none')
  )};

  ${StyledCloseIcon} {
    display: block;
    width: 17.5px;
    height: 17.5px;
    transition: transform 0.3s;
  }

  &:hover {
    ${StyledCloseIcon} {
      transform: scale(1.2);
    }
  }
`

const OverlayCloseButton = (props) => (
  <StyledButton {...props}>
    <StyledCloseIcon />
  </StyledButton>
)

export default OverlayCloseButton
