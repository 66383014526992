import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import AuthClient from './AuthClient'
import { useStore } from '../redux'
import AuthNobiaClient from './AuthNobiaClient'

export const AuthContext = createContext({ client: null })

export const useAuthentication = () => useContext(AuthContext)

/* eslint-disable no-underscore-dangle */
export const AuthProvider = ({
  children,
  store,
  options,
  nobiaClient = false,
  preserveState = true,
}) => {
  const reduxStore = useStore()
  const [client, setClient] = useState()

  useEffect(() => {
    const _store = store || reduxStore
    invariant(
      _store,
      `Store is missing in context and wasn't passed as a prop to AuthProvider.`
    )

    const _client = nobiaClient
      ? new AuthNobiaClient(options, {
          store: _store,
          preserveState,
        })
      : new AuthClient(options, {
          store: _store,
          preserveState,
        })
    setClient(_client)

    _client.subscribe()
    if (preserveState && !_client.isAuthenticated()) {
      _client.restoreLocalSession()
    }
    return () => _client.unsubscribe()
  }, [])

  const memoedState = useMemo(() => ({ client }))

  return (
    <AuthContext.Provider value={memoedState}>{children}</AuthContext.Provider>
  )
}

export const authContextShape = PropTypes.object

export default withRouter(AuthProvider)
