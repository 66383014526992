import React, { Component } from 'react'
import PropTypes from 'prop-types'
import features from '../features'
import tracker from '../../../utils/tracker'
import { StoresHeader } from './header'
import StoreList from './store-list/StoreList'
import { scrollTo } from '../../containers/bada/utils'

class FullSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTab: 'near',
      hasSelectedAddress: true,
      toggleAddressChange: false,
    }
  }

  onAddressChange(selectedAddress) {
    if (selectedAddress.postalCode || !features.requireCustomerAddress) {
      this.setState({ hasSelectedAddress: true, toggleAddressChange: false })
      tracker.trackClick('BADA 2.0 search store', 'search other store')

      this.props.onChange({
        selectedAddress,
        selectedStore: null,
        selectedSlot: null,
      })

      scrollTo('store-list')
    }
  }

  render() {
    const {
      pageContent,
      stores,
      loading,
      error,
      onChange,
      selectedAddress,
      selectedCity,
      selectedStore,
    } = this.props
    const { hasSelectedAddress, toggleAddressChange } = this.state
    const { currentTab } = this.state
    return (
      <>
        <StoresHeader
          pageContent={pageContent}
          currentTab={currentTab}
          selectedAddress={selectedAddress}
          selectedCity={selectedCity}
          changeTab={(id) => {
            this.setState({ currentTab: id, selectedStore: null })
            onChange({
              selectedStore: null,
              selectedService: null,
              selectedSlot: null,
            })
          }}
          hasSelectedAddress={hasSelectedAddress}
          toggleAddressChange={toggleAddressChange}
          onAddressChange={(address) => this.onAddressChange(address)}
          storesCount={stores.length}
          toggleAddress={() =>
            this.setState({
              toggleAddressChange: !toggleAddressChange,
            })
          }
        />
        {(hasSelectedAddress && (
          <StoreList
            pageContent={pageContent}
            stores={stores}
            currentTab={currentTab}
            loading={loading}
            error={error}
            onChange={onChange}
            selectedAddress={selectedAddress}
            selectedStore={selectedStore}
            toggleAddressChange={false}
            hasSelectedAddress={hasSelectedAddress}
          />
        )) || <></>}
      </>
    )
  }
}

FullSelection.propTypes = {
  pageContent: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  selectedAddress: PropTypes.shape({
    postalCode: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    countryName: PropTypes.string,
  }),
  selectedCity: PropTypes.string,
  selectedStore: PropTypes.shape({
    address: PropTypes.string,
    id: PropTypes.string,
    storeManagerId: PropTypes.string,
    storeName: PropTypes.string,
  }),
  stores: PropTypes.arrayOf(PropTypes.shape()),
  storesByAddressData: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    searchStoresByAddress: PropTypes.shape({
      stores: PropTypes.arrayOf(PropTypes.shape()),
      size: PropTypes.number,
    }),
  }),
  onChange: PropTypes.func.isRequired,
}

FullSelection.defaultProps = {
  pageContent: null,
  storesByAddressData: null,
  selectedAddress: null,
  selectedStore: null,
  selectedCity: null,
  loading: false,
  error: false,
  stores: [],
  isMobile: false,
}

export default FullSelection
