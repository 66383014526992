import store from 'store/dist/store.modern'

const STORE_KEY = '__NOBIA__'

const localStore = {
  get() {
    return store.get(STORE_KEY)
  },

  set(value) {
    store.set(STORE_KEY, value)
  },

  clear() {
    store.remove(STORE_KEY)
  },
}

export default localStore
