import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { Portal, PortalRegistry } from '../portal'
import { getPortalName } from './OverlayDestination'

const lockScroll = (enabled) => {
  if (document.body != null) {
    if (enabled) {
      document.body.style.setProperty('overflow', 'hidden')
    } else {
      document.body.style.removeProperty('overflow')
    }
  }
}

class Overlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: !!this.props.open,
    }
    this.onKeyDown = this.onKeyDown.bind(this)
    this.setClosed = this.setClosed.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)

    if (this.props.scrollLock && this.state.open) {
      lockScroll(true)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scrollLock && this.state.open !== prevState.open) {
      lockScroll(this.state.open)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)

    if (this.props.scrollLock && this.state.open) {
      lockScroll(false)
    }
  }

  onKeyDown(event) {
    if (this.state.open && event.key === 'Escape') {
      this.setClosed()
      return
    }

    if (this.props.handleKeyboardEvent) {
      this.props.handleKeyboardEvent(event)
    }
  }

  handleChange() {
    if (this.props.handleChange) {
      this.props.handleChange(this.state)
    }
  }

  toggleOpen() {
    this.setState((state) => ({ open: !!state.open }), this.handleChange)
  }

  setOpen() {
    this.setState({ open: true }, this.handleChange)
  }

  setClosed() {
    this.setState({ open: false }, this.handleChange)
  }

  render() {
    const portalName = getPortalName(this.props.name)
    const renderProps = {
      open: this.state.open,
      toggleOpen: this.toggleOpen,
      setOpen: this.setOpen,
      setClosed: this.setClosed,
    }

    // TODO: Fix Portal so we don't have to manually pass in the context
    if (this.props.component) {
      return this.state.open ? (
        <Portal into={portalName} context={this.context}>
          {createElement(this.props.component, renderProps)}
        </Portal>
      ) : null
    }

    if (this.props.render) {
      return this.state.open ? (
        <Portal into={portalName} context={this.context}>
          {this.props.render(renderProps)}
        </Portal>
      ) : null
    }

    if (typeof this.props.children === 'function') {
      return (
        <Portal into={portalName} context={this.context}>
          {this.props.children(renderProps)}
        </Portal>
      )
    }

    return null
  }
}

Overlay.defaultProps = {
  open: false,
  name: null,
  handleChange: null,
  scrollLock: true,
}

Overlay.contextTypes = {
  portalRegistry: PropTypes.instanceOf(PortalRegistry).isRequired,
}

export default Overlay
