import React from 'react'
import PropTypes from 'prop-types'
import Calendar from 'react-calendar'
import moment from 'moment'
import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { SectionItem, SectionItemHeading, SectionItemText } from '../sharedUI'
import useTranslation from '../../../../utils/translation'

const SelectedDate = styled.div`
  padding: 12px 10px 10px 10px;
  background-color: #fff;
  border: ${(props) =>
    theme('forms.selectedDate.border')(props) || '1px solid #ccc'};
  cursor: pointer;
  font-family: ${theme('typography.paragraph.fontFamily')};
  border-radius: ${(props) => theme('forms.selectedDate.borderRadius')(props)};
`

const CalendarContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding-right: 10px;
`

const StyledCalendar = styled(Calendar)`
  width: 300px;
  position: absolute;
  top: 43px;
  left: 0px;
  background: #fff;
  z-index: 100000;
  display: ${ifProp('visible', 'block', 'none')};
  padding: 16px 20px 16px 20px;
  border: 1px solid #ccc;
  & div.react-calendar__month-view__weekdays__weekday {
    display: none;
  }
  & button.react-calendar__navigation__label,
  button.react-calendar__navigation__arrow {
    background-color: #ffffff;
    border: 0px;
    font-family: ${theme('typography.paragraph.fontFamily')};
  }
  & button.react-calendar__tile {
    background-color: #ffffff;
    border: 0px;
    padding: 6px;
    cursor: pointer;
  }
  & button.react-calendar__navigation__prev-button,
  button.react-calendar__navigation__next-button {
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
  }
  & button.react-calendar__navigation__label:disabled {
    color: #000000;
    cursor: default;
  }
  & button.react-calendar__navigation__prev2-button,
  button.react-calendar__navigation__next2-button {
    display: none;
  }

  & button.react-calendar__navigation__prev-button:focus,
  button.react-calendar__navigation__next-button:focus,
  button.react-calendar__navigation__prev2-button:focus,
  button.react-calendar__navigation__next2-button:focus,
  button.react-calendar__tile:focus {
    outline: 0;
    cursor: pointer;
  }

  & button.react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(160, 160, 160, 1);
  }

  & button.react-calendar__month-view__days__day:disabled {
    color: rgba(182, 182, 182, 0.5);
    text-decoration: line-through;
  }

  & div.react-calendar__navigation {
    margin-bottom: 10px;
  }
`

const DateHandler = ({
  onChange,
  toggleCalendarVisible,
  calendarVisible,
  newSelectedDate,
  selectedDate,
  canceled,
  available,
}) => {
  const t = useTranslation('appointment-details')
  const showFormInput = !canceled && available
  return (
    <SectionItem>
      <SectionItemHeading>{t('chosen-date')}</SectionItemHeading>
      {showFormInput ? (
        <CalendarContainer>
          <SelectedDate onClick={() => toggleCalendarVisible()}>
            {newSelectedDate}
          </SelectedDate>
          <StyledCalendar
            visible={calendarVisible}
            minDetail="month"
            minDate={new Date()}
            defaultActiveStartDate={moment(selectedDate).toDate()}
            onChange={(e) => {
              toggleCalendarVisible()
              onChange(e)
            }}
          />
        </CalendarContainer>
      ) : (
        <SectionItemText>{selectedDate}</SectionItemText>
      )}
    </SectionItem>
  )
}

DateHandler.propTypes = {
  onChange: PropTypes.func,
  toggleCalendarVisible: PropTypes.func,
  calendarVisible: PropTypes.bool,
  newSelectedDate: PropTypes.string,
  selectedDate: PropTypes.string,
  canceled: PropTypes.bool,
}

DateHandler.defaultProps = {
  onChange: null,
  toggleCalendarVisible: null,
  calendarVisible: false,
  newSelectedDate: null,
  selectedDate: null,
  canceled: null,
}

export default DateHandler
