import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import {
  Section,
  SectionItem,
  SectionItemHeading,
  SectionItemText,
} from './sharedUI'
import { checkEmpty, getNewsletterConsent } from './utils'
import useTranslation from '../../../utils/translation'
import features from '../../components/features'
import settings from '../../components/settings'
import useBrandConfig from '../../hooks/useBrandConfig'

const AdditionalInfo = ({
  createdAt,
  newsletterConsent,
  client,
  comment,
  category,
  purpose,
  clientIp,
  recaptchaScore,
}) => {
  const brandConfig = useBrandConfig(settings.brand).data
  const t = useTranslation('appointment-details')

  const timeZone = brandConfig ? brandConfig.ianaTimeZoneId : 'UTC'
  const utcTime = moment.utc(createdAt)
  const localTime = utcTime.tz(timeZone)

  return (
    <>
      <Section noBottomBorder>
        {createdAt !== null && (
          <SectionItem>
            <SectionItemHeading>{t('created')}</SectionItemHeading>
            <SectionItemText>
              {localTime
                ? localTime.format('YYYY-MM-DD HH:mm')
                : moment(createdAt).format('YYYY-MM-DD HH:mm')}
            </SectionItemText>
          </SectionItem>
        )}
        {newsletterConsent !== null && (
          <SectionItem>
            <SectionItemHeading>{t('newsletter-consent')}</SectionItemHeading>
            <SectionItemText>
              {getNewsletterConsent(newsletterConsent, t)}
            </SectionItemText>
          </SectionItem>
        )}
        {comment && comment.length !== 0 && (
          <SectionItem mobileSize="100%">
            <SectionItemHeading>{t('comments')}</SectionItemHeading>
            <SectionItemText>{checkEmpty(comment)}</SectionItemText>
          </SectionItem>
        )}
        {settings.brand === 'magnet' && clientIp && (
          <SectionItem mobileSize="100%">
            <SectionItemHeading>{t('clientIp')}</SectionItemHeading>
            <SectionItemText>{checkEmpty(clientIp)}</SectionItemText>
          </SectionItem>
        )}
        {features.useRecaptcha && (
          <SectionItem mobileSize="100%">
            <SectionItemHeading>{t('recaptchaScore')}</SectionItemHeading>
            <SectionItemText>{checkEmpty(recaptchaScore)}</SectionItemText>
          </SectionItem>
        )}
      </Section>
      {features.kratos && client === 'kratos' && (
        <Section>
          <SectionItem>
            <SectionItemHeading>{t('purpose')}</SectionItemHeading>
            <SectionItemText>{t(`purpose.${purpose}`)}</SectionItemText>
          </SectionItem>
          {category !== null && (
            <SectionItem>
              <SectionItemHeading>{t('category')}</SectionItemHeading>
              <SectionItemText>{t(`category.${category}`)}</SectionItemText>
            </SectionItem>
          )}
        </Section>
      )}
    </>
  )
}

AdditionalInfo.propTypes = {
  createdAt: PropTypes.string,
  newsletterConsent: PropTypes.bool,
  comment: PropTypes.string,
}

AdditionalInfo.defaultProps = {
  createdAt: null,
  newsletterConsent: null,
  comment: false,
}

export default AdditionalInfo
