import React from 'react'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'
import { mediaMin } from '../../../styles/media'
import { Row, Column } from '../grid'
import { Headline } from '../text'

const StyledHeadline = styled(Headline)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledRow = styled(Row).attrs({ component: 'header' })`
  height: ${prop('theme.overlay.header.height')};
  padding-top: ${prop('theme.grid.gutter')};
  padding-bottom: ${prop('theme.grid.gutter')};
  color: ${prop('theme.overlay.header.color')};
  background: ${prop('theme.overlay.header.background')};
  align-items: center;
  flex-wrap: nowrap;
`

const LeftColumn = styled(Column).attrs({ size: 'auto', sm: 3 })`
  text-align: left;
`

const CenterColumn = styled(Column).attrs({ size: 10, sm: 6 })`
  text-align: left;
  padding-left: 0;

  ${mediaMin(
    'sm',
    css`
      text-align: center;
      padding-left: ${prop('theme.grid.gutter')};
    `
  )};
`

const RightColumn = styled(Column).attrs({ size: 'auto', sm: 3 })`
  text-align: right;
`

const FullscreenOverlayHeader = ({
  title,
  children,
  leftColumn,
  rightColumn,
  ...props
}) => (
  <StyledRow {...props}>
    <LeftColumn>{leftColumn}</LeftColumn>
    <CenterColumn>
      <StyledHeadline noMargins>{children || title}</StyledHeadline>
    </CenterColumn>
    <RightColumn>{rightColumn}</RightColumn>
  </StyledRow>
)

FullscreenOverlayHeader.defaultProps = {
  title: '',
  children: null,
  rightColumn: null,
  leftColumn: null,
}

export default FullscreenOverlayHeader
