import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import Button from '../button/baseButton'

const NavigationButton = styled(Button)`
  z-index: 10;
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 7px !important;

  path {
    color: #fff;
  }
  &:hover {
    background-color: ${theme('colors.blue')};
  }
  :before {
    border-radius: ${theme('calendar.navigationButton.borderRadius')};
  }

  ${(p) => p.theme.media.min.xl`
      position: absolute;
      top: 50%;
      left: ${ifProp('left', '-50px', 'auto')};
      right: ${ifProp('left', 'auto', '-40px')};
      transform: translateY(-100%);
      padding: 40px 12px;
    `}
`

export default NavigationButton
