import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'
import LoadingIndicator from '../loadingIndicator'
import Icon from '../icons/photo'

const DRAW_STATUS_PENDING = 'pending'
const DRAW_STATUS_SUCCESS = 'success'
const DRAW_STATUS_FAILURE = 'failure'

class Thumbnail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawStatus: DRAW_STATUS_PENDING,
      src: '',
    }
    this.mounted = null
    this.canvas = null
    this.onDrawThumbnailSuccess = this.onDrawThumbnailSuccess.bind(this)
    this.onDrawThumbnailError = this.onDrawThumbnailError.bind(this)
    this.drawThumbnail = this.drawThumbnail.bind(this)
    this.setCanvasRef = this.setCanvasRef.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.drawThumbnail()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fromServer !== this.props.fromServer) {
      this.drawThumbnail()
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  drawThumbnail() {
    this.setState((state) => {
      if (state.drawStatus === DRAW_STATUS_FAILURE) {
        return { drawStatus: DRAW_STATUS_PENDING }
      }

      return null
    })

    this.props.uploader.methods
      .drawThumbnail(
        this.props.id,
        this.canvas,
        this.props.maxSize,
        this.props.fromServer,
        this.props.customResizer
      )
      .then(this.onDrawThumbnailSuccess, this.onDrawThumbnailError)
  }

  onDrawThumbnailSuccess() {
    if (this.mounted) {
      this.setState({
        drawStatus: DRAW_STATUS_SUCCESS,
        src: this.canvas != null ? this.canvas.toDataURL() : '',
      })
    }
  }

  onDrawThumbnailError() {
    if (this.mounted) {
      this.setState({ drawStatus: DRAW_STATUS_FAILURE })
    }
  }

  setCanvasRef(ref) {
    this.canvas = ref
  }

  render() {
    const { drawStatus, src } = this.state
    const { className, imageClassName, placeholderClassName, status } =
      this.props
    const style = { backgroundImage: `url("${src}")` }

    return (
      <div className={className} style={style}>
        <canvas className={imageClassName} ref={this.setCanvasRef} hidden />
        {drawStatus !== DRAW_STATUS_SUCCESS && (
          <div className={placeholderClassName}>
            {drawStatus === DRAW_STATUS_PENDING && (
              <LoadingIndicator size="small" />
            )}
            {drawStatus === DRAW_STATUS_FAILURE &&
              status !== qq.status.UPLOAD_FAILED &&
              status !== qq.status.REJECTED && (
                <Icon type="photo" width={45} height={45} />
              )}
          </div>
        )}
      </div>
    )
  }
}

Thumbnail.defaultProps = {
  className: 'react-fine-uploader-thumbnail',
  imageClassName: 'react-fine-uploader-thumbnail-image',
  placeholderClassName: 'react-fine-uploader-thumbnail-placeholder',
  maxSize: 400,
  fromServer: false,
  customResizer: undefined,
}

export default Thumbnail
