import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import settings from '../settings'
import { Row, Column } from '../grid'
import SubHeadline from '../typography/subHeadline'
import Link from '../typography/link'
import { mobileCheck } from '../../containers/bada/utils'
import useTranslation from '../../../utils/translation'
import Checkmark from '../icons/checkmark'
import CheckmarkAlternate from '../icons/checkmark-alternate'
import checkHexagon from '../../../assets/check-hexagon.png'
import checkCircle from '../../../assets/check-circle.png'
import magnetCircle from '../../../assets/magnet-circle.png'
import useBrandConfig from '../../hooks/useBrandConfig'

const timeString = (dateTime) => {
  const time = dateTime.split('T')[1].split(':')
  return `${time[0]}:${time[1]}`
}

const ImageContainer = styled.div`
  ${(props) => props.theme.media.max.xs`
    position:relative;
    width:100%;
  `}
`
const InfoColumn = styled(Column)`
  text-transform: capitalize;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: '15px';
  font-size: ${theme('typography.paragraph.fontSize')};
  font-weight: ${theme('typography.paragraph.fontWeight')};
  line-height: ${theme('bookingDetails.lineHeight')};
  flex: 0 0 75%;

  &:after {
    display: block;
    content: '';
    margin: 20px 0px 10px 0px;
    border-bottom: 1px solid ${theme('bookingDetails.divider.color')};
  }
  ${(props) => props.theme.media.min.sm`
  flex: 0 0 30%;
  border-bottom: 0;
  &:after {
    display: none;
  }
  `}
`

const LastColumn = styled(InfoColumn)`
  text-transform: none;
  &:after {
    display: none;
  }
  ${(props) => props.theme.media.min.sm`
  flex: 1 1 25%;
  `}
`

const StyledSubHeadline = styled(SubHeadline)`
  margin: 10px 0px 5px 0px;
  letter-spacing: 0.5px;
  letter-spacing: ${theme('bookingDetails.subHeadline.letterSpacing')};
  color: ${theme('bookingDetails.subHeadline.color')};
  text-transform: ${theme('bookingDetails.subHeadline.textTransform')};
  font-size: 16px;
  font-size: ${theme('bookingDetails.subHeadline.fontSize')};
  font-weight: ${theme('typography.subHeadline.fontWeight')};
  font-family: ${theme('typography.subHeadline.fontFamily')};
`

const StyledLink = styled(Link)`
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: -20px;
  }
  color: ${theme('bookingDetails.subHeadline.color')};
`

const StyledEmailLink = styled(StyledLink)`
  line-height: ${theme('bookingDetails.addressItem.lineHeight')};
  text-transform: none;
`

const ShowMapContainer = styled.div`
  margin-top: 15px;
`
const ImageColumn = styled(Column)`
  ${(props) => props.theme.media.max.xs`
    position: absolute;
    display:none;
    top: 0px;
    right: 0px;
    width: auto;
  `}
  ${(props) => props.theme.media.min.sm`
      flex: 0 0 122px;
  `}
`

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  max-width: 65px;
  margin-top: 14px;
`

const StyledImage = styled.img`
  max-width: 65px;
`

const DetailsWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
  transition-delay: 0s;
  margin-top: ${theme('bookingDetails.marginTop')};
  margin-bottom: ${theme('bookingDetails.marginBottom')};
  background-color: ${theme('bookingDetails.backgroundColor')};

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${theme('bookingDetails.border.color')};
      border-radius: ${theme('bookingDetails.borderRadius')};
      padding: ${theme('bookingDetails.padding')};
      max-height: 2000px;

      ${ImageColumn} {
        display: block;
      }
      ${props.theme.media.min.sm`
        padding: ${theme('bookingDetails.paddingDesktop')};
      `}
    `}
`

const AddressItem = styled.div`
  line-height: ${theme('bookingDetails.addressItem.lineHeight')};
  text-transform: ${theme('bookingDetails.addressItem.textTransform')};
`

const getFormattedDate = (date, locale) => {
  if (!date) return null
  const currentDate = new Date(date)

  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  return new Intl.DateTimeFormat(locale, options).format(currentDate)
}

const image = {
  marbodal: checkHexagon,
  novart: checkCircle,
  hthdk: checkCircle,
  hthno: checkCircle,
  hthse: checkCircle,
  hthfi: checkCircle,
  hthde: checkCircle,
  magnet: magnetCircle,
  norema: checkCircle,
  unoformno: checkCircle,
  unoformse: checkCircle,
  unoformbe: checkCircle,
}

const svgColor = {
  invita: '#C9682E',
  unoform: '#C19A5B',
  unoformno: '#C19A5B',
  unoformse: '#C19A5B',
  unoformbe: '#C19A5B',
  novart: '#1E44CB',
  sigdal: '#1E44CB',
  hthdk: '#111111',
  hthse: '#111111',
  hthno: '#111111',
  hthfi: '#111111',
  hthde: '#111111',
  marbodal: '#1E44CB',
}

const BookingDetails = ({
  display,
  selectedStore,
  selectedSlot,
  selectedServiceName,
  detailsActive,
}) => {
  const brandConfig = useBrandConfig(settings.brand).data

  const t = useTranslation('app.prep-form')

  const icon = () => {
    if (
      settings.brand === 'invita' ||
      settings.brand === 'sigdal' ||
      settings.brand === 'novart' ||
      settings.brand === 'marbodal' ||
      settings.brand === 'unoform' ||
      settings.brand === 'unoformse' ||
      settings.brand === 'unoformno' ||
      settings.brand === 'unoformbe'
    ) {
      // change all brands to use svg checkmark insted of png
      return (
        <StyledIconContainer>
          <Checkmark width={58} height={58} color={svgColor[settings.brand]} />
        </StyledIconContainer>
      )
    }
    if (
      settings.brand === 'hthdk' ||
      settings.brand === 'hthse' ||
      settings.brand === 'hthfi' ||
      settings.brand === 'hthno' ||
      settings.brand === 'hthde'
    ) {
      return (
        <StyledIconContainer>
          <CheckmarkAlternate
            width={65}
            height={65}
            color={svgColor[settings.brand]}
          />
        </StyledIconContainer>
      )
    }
    return <StyledImage src={image[settings.brand]} />
  }

  return (
    <DetailsWrapper
      data-track="summary"
      active={detailsActive || display === 'expanded'}
    >
      <Row>
        <ImageContainer data-track="tick">
          <ImageColumn>{icon()}</ImageColumn>
        </ImageContainer>

        <InfoColumn data-track="block-room" sm={3}>
          <StyledSubHeadline>{t('store')}</StyledSubHeadline>
          <AddressItem data-track="showroom">
            {selectedStore.storeName}
          </AddressItem>
          {selectedStore.address &&
            selectedStore.address.split(',').map((element) => (
              <AddressItem data-track="address" key={element}>
                {element}
              </AddressItem>
            ))}
          {selectedStore.phoneNumber && (
            <>
              {mobileCheck() ? (
                <AddressItem data-track="tel">
                  <Link
                    href={`tel:${selectedStore.phoneNumber.replace(
                      /[^0-9.]+/g,
                      ''
                    )}`}
                  >
                    {selectedStore.phoneNumber}
                  </Link>
                </AddressItem>
              ) : (
                <AddressItem data-track="tel">
                  {selectedStore.phoneNumber}
                </AddressItem>
              )}
            </>
          )}
          {selectedStore.email && (
            <StyledEmailLink href={`mailto:${selectedStore.email}`}>
              {selectedStore.email.toLowerCase()}
            </StyledEmailLink>
          )}
          <ShowMapContainer>
            <StyledLink
              href={`https://www.google.com/maps/dir//${selectedStore.latitude},${selectedStore.longitude}/@${selectedStore.latitude},${selectedStore.longitude},16z`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('show-map')}
            </StyledLink>
          </ShowMapContainer>
        </InfoColumn>
        {selectedSlot &&
          selectedSlot.from &&
          brandConfig &&
          brandConfig.languageTag && (
            <InfoColumn data-track="block-date" sm={3}>
              <StyledSubHeadline>{t('date')}</StyledSubHeadline>
              {getFormattedDate(selectedSlot.from, brandConfig.languageTag)}
              <br />
              {timeString(selectedSlot.from)} - {timeString(selectedSlot.to)}
            </InfoColumn>
          )}
        <LastColumn data-track="block-type">
          <StyledSubHeadline>{t('type')}</StyledSubHeadline>
          {selectedServiceName || ''}
        </LastColumn>
      </Row>
    </DetailsWrapper>
  )
}

export default BookingDetails
