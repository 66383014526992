import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Paragraph from '../typography/paragraph'
import Designer from '../designer/Designer'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  padding: ${theme('confirmation.row.padding')};
`

const StyledParagraph = styled(Paragraph)`
  font-size: '17px';
  font-weight: ${theme('typography.paragraph.fontWeight')};
  margin-bottom: 14px;
`

const transformText = (
  returningVisitor,
  serviceContent,
  appointmentId,
  selectedStore
) => {
  const textValue = (
    returningVisitor
      ? serviceContent.confirmationDesignerTextReturning
      : serviceContent.confirmationDesignerText
  )
    .replace(
      '{link}',
      `?appointment=${appointmentId}&studioid=${
        selectedStore && selectedStore.id
      }`
    )
    .replace('{storeName}', `${selectedStore && selectedStore.storeName}`)
    .replace('{serviceName}', `${serviceContent.heading}`)
  return textValue
}

const ConfirmationInformation = ({
  selectedSlot,
  selectedStore,
  serviceContent,
  staff,
  appointmentId,
  returningVisitor,
}) => {
  if (!serviceContent) return null

  const designerHeading = returningVisitor
    ? serviceContent.confirmationDesignerHeadingReturning
    : serviceContent.confirmationDesignerHeading

  const designerText =
    designerHeading && designerHeading.length
      ? transformText(
          returningVisitor,
          serviceContent,
          appointmentId,
          selectedStore
        )
      : ''

  const showDesignerBox = !!(designerHeading && designerText)

  return (
    <Container>
      <Row>
        {showDesignerBox && (
          <Designer
            heading={designerHeading}
            text={designerText}
            image={serviceContent.preparationDesignerImage}
            name={serviceContent.preparationDesignerName}
            location={serviceContent.preparationDesignerLocation}
            staff={staff}
            selectedStore={selectedStore}
          />
        )}
      </Row>
      <Row>
        {selectedSlot && selectedSlot.to && (
          <StyledParagraph align="left">
            {serviceContent.confirmationChangeBookingText}
          </StyledParagraph>
        )}
      </Row>
    </Container>
  )
}

export default ConfirmationInformation
