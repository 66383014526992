import * as constants from '../constants'

export const defaultLocale = {
  locale: 'en-GB',
}

export default {
  [constants.BRAND_MAGNET]: { locale: 'en-GB' },
  [constants.BRAND_MAGNETTRADE]: { locale: 'en-GB' },
  [constants.BRAND_HTHGO]: { locale: 'da-DK' },
  [constants.BRAND_HTHDK]: { locale: 'da-DK' },
  [constants.BRAND_HTHNO]: { locale: 'nb-NO' },
  [constants.BRAND_HTHSE]: { locale: 'sv-SE' },
  [constants.BRAND_HTHFI]: { locale: 'fi-FI' },
  [constants.BRAND_HTHDE]: { locale: 'de' },
  [constants.BRAND_NOREMA]: { locale: 'nb-NO' },
  [constants.BRAND_NOVART]: { locale: 'fi-FI' },
  [constants.BRAND_SIGDAL]: { locale: 'nb-NO' },
  [constants.BRAND_MARBODAL]: { locale: 'sv-SE' },
  [constants.BRAND_UNOFORM]: { locale: 'da-DK' },
  [constants.BRAND_UNOFORMNO]: { locale: 'nb-NO' },
  [constants.BRAND_UNOFORMSE]: { locale: 'sv-SE' },
  [constants.BRAND_UNOFORMBE]: { locale: 'nl-BE' },
  [constants.BRAND_EWE]: { locale: 'de-AT' },
  [constants.BRAND_EWEEN]: { locale: 'en-GB' },
  [constants.BRAND_INTUO]: { locale: 'de-AT' },
  [constants.BRAND_INVITA]: { locale: 'da-DK' },
}
