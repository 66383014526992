import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme('textOption.wrap.mobile.padding')};
  margin: 0px auto;
  height: 100%;
  background-color: ${theme('textOption.backgroundColor')};

  ${(props) => props.theme.media.min.sm`
    width: ${theme('textOption.wrap.mobile.width')};
    padding: ${theme('textOption.wrap.desktop.padding')};
  `}
`
const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${theme('textOption.text.minHeight')};
  min-width: ${theme('textOption.text.minWidth')};
  margin: 0px auto;
  font-family: ${theme('textOption.text.fontFamily')};
  font-size: ${theme('textOption.text.fontSize.mobile')};
  font-weight: ${theme('textOption.text.fontWeight')};
  color: ${theme('colors.link')};
  white-space: pre-wrap;
  text-align: ${theme('textOption.text.textAlign')};
  font-style: ${theme('textOption.text.fontStyle')};

  em {
    font-weight: 400;
  }
  ${(props) =>
    props.active &&
    css`
      font-weight: 700;
      font-weight: ${theme('textOption.text.active.fontWeight')};
    `}
  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('textOption.text.fontSize.desktop')};
  `}
`

const TextOption = ({ firstText, active }) => (
  <TextWrap>
    {firstText && (
      <Text
        active={active}
        dangerouslySetInnerHTML={{ __html: firstText.value }}
      />
    )}
  </TextWrap>
)

export default TextOption
