import React from 'react'
import PropTypes from 'prop-types'
import Hth from './hth'
import Novart from './novart'
import Marbodal from './marbodal'
import Sigdal from './sigdal'
import Invita from './invita'
import Norema from './norema'
import Unoform from './unoform'
import Magnet from './magnet'

const Logo = ({ brand }) => {
  switch (brand) {
    default:
      return null
    case 'hthdk':
    case 'hthse':
    case 'hthno':
    case 'hthfi':
    case 'hthde':
      return <Hth />
    case 'marbodal':
      return <Marbodal />
    case 'sigdal':
      return <Sigdal />
    case 'invita':
      return <Invita />
    case 'norema':
      return <Norema />
    case 'novart':
      return <Novart />
    case 'magnet':
      return <Magnet />
    case 'unoform':
    case 'unoformno':
    case 'unoformse':
    case 'unoformbe':
      return <Unoform />
  }
}

Logo.propTypes = {
  brand: PropTypes.string,
}

Logo.defaultProps = {
  brand: null,
}

export default Logo
