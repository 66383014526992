import merge from 'deepmerge'
import themes, { baseTheme } from './themes'

const getTheme = (brand, localTheme = {}, noBaseTheme) => {
  // Special handling for 'base' value, but we should remove if safe.
  if (!brand || brand === 'base') {
    return merge(baseTheme, localTheme)
  }

  const brandTheme = themes[brand]

  if (brandTheme == null) {
    throw new Error(`Missing theme for brand '${brand}'`)
  }

  if (noBaseTheme) {
    return merge(brandTheme, localTheme)
  }

  return merge.all([baseTheme, brandTheme, localTheme])
}

export default getTheme
