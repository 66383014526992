import React from 'react'
import { hoistStatics, wrapDisplayName } from 'recompose'
import Auth from './Auth'

const withAuth = (WrappedComponent) => {
  const Component = (props) => (
    <Auth
      render={(authComponentProps) => (
        <WrappedComponent {...props} auth={authComponentProps} />
      )}
    />
  )

  Component.displayName = wrapDisplayName(WrappedComponent, 'withAuth')

  return Component
}

export default hoistStatics(withAuth)
