import { createClient as initSharedClient } from '../apollo'
import initSharedStore from '../sharedState/initSharedStore'

/* eslint-disable no-underscore-dangle */

function initState({
  apiUrl,
  persistSharedState = false,
  storeConfig = {},
  cacheConfig = {},
}) {
  const store = initSharedStore({
    ...storeConfig,
    persistSharedState,
  })

  const client = initSharedClient({
    uri: apiUrl,
    store,
    cacheOptions: cacheConfig,
  })

  return {
    client,
    store,
  }
}

const pageHasSharedStore = () =>
  typeof window !== 'undefined' &&
  window.nobia != null &&
  window.nobia.sharedStore != null

const pageHasSharedClient = () =>
  typeof window !== 'undefined' &&
  window.nobia != null &&
  window.nobia.sharedClient != null

const pageHasOmniContent = () =>
  typeof window !== 'undefined' &&
  (window.zeus != null ||
    (window.__NEXT_DATA__ != null && window.__NEXT_DATA__.props != null))

export default initState
export { pageHasSharedStore, pageHasSharedClient, pageHasOmniContent }
