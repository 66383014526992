import React from 'react'
import styled, { css } from 'styled-components'
import { theme, ifProp } from 'styled-tools'

const StyledTime = styled.time`
  display: block;
  padding: 10px 0;
  text-align: center;
  font-family: ${theme('typography.paragraph.fontFamily')};

  ${ifProp(
    { type: 'reserved' },
    css`
      border: 1px solid rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.3);
      background-color: ${theme('colors.buttonBackgroundColor')};
    `
  )}
`

const time = (input) => {
  const splitInput = input.split(':')
  return `${splitInput[0]}:${splitInput[1]}`
}

const Slot = (props) => {
  const { to, from, type } = props

  return (
    <StyledTime type={type}>
      {time(from)} &ndash; {time(to)}
    </StyledTime>
  )
}

export default Slot
