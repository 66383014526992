import store from 'store/dist/store.modern'

class LocalAuthStore {
  constructor(clientId) {
    this.prefix = `auth.${clientId}`
  }

  get(key) {
    return store.get(`${this.prefix}.${key}`)
  }

  set(key, value) {
    store.set(`${this.prefix}.${key}`, value)
  }

  delete(key) {
    store.remove(`${this.prefix}.${key}`)
  }

  clear() {
    store.each((val, key) => {
      if (key.startsWith(this.prefix)) {
        store.remove(key)
      }
    })
  }
}

export default LocalAuthStore
