import React from 'react'
import PropTypes from 'prop-types'

const Video = ({ width, height }) => (
  <svg viewBox="0 0 43 26" width={width} height={height}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Group-4"
        transform="translate(-1.490560, 1.909831)"
        stroke="currentColor"
        strokeWidth="2"
      >
        <rect
          id="Rectangle-Copy"
          x="8.9905599"
          y="0"
          width="28"
          height="18.0901693"
          rx="1"
        />
        <path
          d="M9.10091146,18.1119792 L36.8929036,18.1119792 L42.7347544,21.025705 C43.2289763,21.2722069 43.429793,21.8726821 43.1832911,22.366904 C43.0140748,22.706173 42.6675507,22.9205729 42.2884233,22.9205729 L4.03321084,22.9205729 C3.48092609,22.9205729 3.03321084,22.4728577 3.03321084,21.9205729 C3.03321084,21.5498692 3.23828149,21.2095816 3.56604689,21.0364022 L9.10091146,18.1119792 L9.10091146,18.1119792 Z"
          id="Rectangle"
        />
        <path
          d="M21.0078125,6.26041667 L26.0078125,9.26041667"
          id="Line"
          strokeLinecap="round"
        />
        <path
          d="M26.0078125,9.29664376 L21.0078125,12.2966438"
          id="Line-Copy"
          strokeLinecap="round"
        />
        <path
          d="M20.9905599,6.34627279 L20.9905599,12.1773275"
          id="Line-2"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
)

Video.defaultProps = {
  width: 50,
  height: 50,
}

Video.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Video
