import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'

class PauseResumeButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      atLeastOneChunkUploaded: false,
      pausable: false,
      resumable: false,
    }

    this.mounted = false
    this.onStatusChange = this.onStatusChange.bind(this)
    this.onResume = this.onResume.bind(this)
    this.onUploadChunkSuccess = this.onUploadChunkSuccess.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.props.uploader.on('resume', this.onResume)
    this.props.uploader.on('statusChange', this.onStatusChange)
    this.props.uploader.on('uploadChunkSuccess', this.onUploadChunkSuccess)
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.uploader.off('resume', this.onResume)
    this.props.uploader.off('statusChange', this.onStatusChange)
    this.props.uploader.off('uploadChunkSuccess', this.onUploadChunkSuccess)
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (this.mounted && id === this.props.id) {
      this.setState((state) => {
        const pausable =
          newStatus === qq.status.UPLOADING && state.atLeastOneChunkUploaded
        const resumable = newStatus === qq.status.PAUSED

        if (pausable !== state.pausable || resumable !== state.resumable) {
          return { pausable, resumable }
        }

        return null
      })

      if (
        newStatus === qq.status.DELETED ||
        newStatus === qq.status.CANCELED ||
        newStatus === qq.status.UPLOAD_SUCCESSFUL
      ) {
        this.props.uploader.off('resume', this.onResume)
        this.props.uploader.off('statusChange', this.onStatusChange)
        this.props.uploader.off('uploadChunkSuccess', this.onUploadChunkSuccess)
      }
    }
  }

  onResume(id) {
    if (this.mounted && id === this.props.id) {
      this.setState((state) => {
        if (!state.atLeastOneChunkUploaded) {
          return {
            atLeastOneChunkUploaded: true,
            pausable: true,
            resumable: false,
          }
        }

        return null
      })
    }
  }

  onUploadChunkSuccess(id) {
    if (this.mounted && id === this.props.id) {
      this.setState((state) => {
        if (!state.atLeastOneChunkUploaded) {
          return {
            atLeastOneChunkUploaded: true,
            pausable: true,
            resumable: false,
          }
        }

        return null
      })
    }
  }

  onClick() {
    if (this.state.pausable) {
      this.props.uploader.methods.pauseUpload(this.props.id)
    } else if (this.state.resumable) {
      this.props.uploader.methods.continueUpload(this.props.id)
    }
  }

  render() {
    const { pausable, resumable } = this.state
    const {
      id,
      className,
      pauseClassName,
      resumeClassName,
      pauseChildren,
      resumeChildren,
      onlyRenderIfEnabled,
      uploader,
      ...props
    } = this.props

    if (pausable || resumable || !onlyRenderIfEnabled) {
      return (
        <button
          {...props}
          type="button"
          className={`${className} ${
            resumable ? resumeClassName : pauseClassName
          }`}
          disabled={!pausable && !resumable}
          onClick={pausable || resumable ? this.onClick : undefined}
        >
          {resumable ? resumeChildren : pauseChildren}
        </button>
      )
    }

    return null
  }
}

export default PauseResumeButton
