import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ifProp, theme } from 'styled-tools'
import Headline from '../typography/headline'
import Paragraph from '../typography/paragraph'
import { isEmbedded } from '../../containers/bada/utils'
import { extractUrlSettings } from '../../../utils/cloudinary'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme('designer.background.color')};
  padding: ${theme('designer.padding.mobile')};
  margin: ${theme('designer.margin.mobile')};
  border-radius: ${theme('designer.borderRadius')};

  ${(props) => props.theme.media.min.md`
    flex-direction: row;
    padding: ${theme('designer.padding.desktop')};
    margin: ${theme('designer.margin.desktop')};
    min-height: ${theme('designer.minHeight')};
  `}
`

const StyledHeadline = styled(Headline)`
  margin-top: 25px;
  margin-top: ${theme('designer.headline.marginTop')};
  margin-bottom: ${theme('designer.headline.marginBottom')};
  letter-spacing: ${ifProp(
    'embedded',
    theme('designer.headline.letterSpacing.embedded'),
    theme('designer.headline.letterSpacing.normal')
  )};
  text-transform: ${ifProp(
    'embedded',
    theme('designer.headline.textTransform.embedded'),
    theme('designer.headline.textTransform.normal')
  )};
  font-family: ${theme('designer.headline.fontFamily')};
  font-size: ${theme('designer.headline.fontSize')};
  font-weight: ${theme('designer.headline.fontWeight')};
  line-height: ${theme('designer.headline.lineHeight')};

  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('designer.headline.fontSize')};
  `}
`

const TextParagraph = styled(Paragraph)`
  max-width: 450px;
  line-height: ${theme('designer.paragraph.lineHeight.mobile')};
  margin-bottom: 20px;
  color: ${theme('designer.paragraph.color')};
  font-size: ${theme('typography.paragraph.fontSize')};

  ${(props) => props.theme.media.min.sm`
    margin-bottom: 15px;
    font-size: ${theme('designer.paragraph.fontSize.desktop')};
    line-height: ${theme('designer.paragraph.lineHeight.desktop')};
  `}

  a {
    color: ${theme('typography.link.color')};
  }
`

const Left = styled.div`
  width: 100%;
  margin: ${theme('designer.left.margin')};
  ${(props) => props.theme.media.min.md`
    margin:0;
  `}
`

const Right = styled.div`
  display: flex;
  flex-direction: ${theme('designer.right.flexDirection')};
  justify-content: space-between;
  flex-direction: row-reverse;

  ${(props) => props.theme.media.min.sm`
    position: relative;
    min-width: 420px;
    flex-direction: column-reverse;
  `}
  ${(props) => props.theme.media.min.md`
    height: 225px;
    align-self: flex-end;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  ${(props) => props.theme.media.min.md`
    position: static;
    height: 225px;
  `}
`

const StyledImage = styled.img`
  max-width: 120px;
  max-height: 120px;
  position: absolute;
  margin-bottom: -25px;
  order: 1;
  object-fit: contain;
  bottom: 25px;
  right: 0;

  ${(props) => props.theme.media.min.md`
    max-width: ${theme('designer.image.maxWidth')};
    max-height: ${theme('designer.image.maxHeight')};
    order: 0;
    object-fit: contain;
    left: 0;
    margin-left: auto;
    margin-right: 170px;
  `}
`

const DesignerNameLocation = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  padding-top: 15px;
  max-width: 160px;
  text-align: left;

  ${(props) => props.theme.media.min.md`
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
 `}
`

const DesignerNameParagraph = styled(Paragraph)`
  margin-top: ${theme('designer.name.marginTop')};
  margin-bottom: 0;
  max-width: 180px;
  margin-left: 0px;
  font-weight: ${theme('designer.name.fontWeight')};
  font-size: ${theme('designer.name.fontSize')};
  line-height: ${theme('designer.name.lineHeight')};
  text-transform: ${theme('designer.name.textTransform')};
  letter-spacing: ${theme('designer.name.letterSpacing')};
  ${(props) => props.theme.media.min.sm`
    margin-left: auto;
  `};
`

const DesignerLocationParagraph = styled(Paragraph)`
  margin-top: ${theme('designer.location.marginTop')};
  margin-bottom: ${theme('designer.location.marginBottom')};
  max-width: 160px;
  margin-left: 0px;
  word-break: normal;
  font-weight: ${theme('designer.location.fontWeight')};
  font-size: ${theme('designer.location.fontSize')};
  ${(props) => props.theme.media.min.sm`
    margin-left: auto;
  `}
`

const getDesigner = (
  defaultImage,
  defaultName,
  defaultLocation,
  store,
  staff
) => {
  let imageUrl = defaultImage ? defaultImage.url : undefined
  if (defaultImage && defaultImage.publicId) {
    const publicId = defaultImage.publicId

    let type = defaultImage.type
    let resourceType = defaultImage.resourceType
    if (!type || !resourceType) {
      const { deliveryType, assetType } = extractUrlSettings(defaultImage.url)

      type = type || deliveryType
      resourceType = resourceType || assetType
    }

    imageUrl = `https://assets.nobiadigital.com/image/${type}/c_scale,w_480/q_auto/f_auto/${publicId}.png`
  }

  const defaultContent = {
    imageUrl,
    name: defaultName,
    location: defaultLocation,
  }

  if (!store || !store.managerId || !staff) {
    return defaultContent
  }

  const manager = staff.find(
    (s) =>
      s.id.toString() === store.managerId.toString() ||
      s.id === store.managerId.toString()
  )

  if (
    !manager ||
    !manager.appImages.Bada ||
    !manager.lastName ||
    !manager.firstName ||
    !store.name
  ) {
    return defaultContent
  }
  return {
    imageUrl: manager.appImages.Bada,
    name: `${manager.firstName} ${manager.lastName}`,
    location: store.name,
  }
}

const Designer = ({
  heading,
  text,
  image,
  name,
  location,
  staff,
  selectedStore,
}) => {
  const designer = getDesigner(image, name, location, selectedStore, staff)
  return (
    <StyledContainer data-track="designer">
      <Left>
        <StyledHeadline embedded={isEmbedded()}>{heading}</StyledHeadline>
        <TextParagraph
          embedded={isEmbedded()}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Left>
      <Right>
        <ImageContainer>
          {designer.imageUrl && <StyledImage src={`${designer.imageUrl}`} />}
        </ImageContainer>
        <DesignerNameLocation>
          <DesignerNameParagraph>{designer.name}</DesignerNameParagraph>
          <DesignerLocationParagraph
            dangerouslySetInnerHTML={{ __html: designer.location }}
          />
        </DesignerNameLocation>
      </Right>
    </StyledContainer>
  )
}

Designer.propTypes = {
  selectedStore: PropTypes.shape(),
  heading: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.shape({}),
  name: PropTypes.string,
  location: PropTypes.string,
  staff: PropTypes.arrayOf(PropTypes.shape()),
}

Designer.defaultProps = {
  selectedStore: null,
  heading: '',
  text: '',
  image: {},
  name: '',
  location: '',
  staff: null,
  alternate: false,
}

export default Designer
