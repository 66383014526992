import React from 'react'
import { PortalDest } from '../portal'

const PORTAL_NAME = 'zeus/overlay'

export const getPortalName = (name) =>
  name != null ? `${PORTAL_NAME}/${name}` : PORTAL_NAME

const OverlayDestination = ({ name, ...rest }) => (
  <PortalDest name={getPortalName(name)} {...rest} />
)

OverlayDestination.defaultProps = {
  name: null,
}

export default OverlayDestination
