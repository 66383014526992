import React from 'react'
import { compose, lifecycle, withProps } from 'recompose'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import settings from '../settings'
import { Column, Row } from '../grid'
import Headline from '../typography/headline'
import Preamble from '../typography/preamble'
import useBrandConfig from '../../hooks/useBrandConfig'
import BookingSummary from '../../components/booking-summary'
import { fetchStaffMembers } from '../../containers/bada/actions'
import { findServiceData, getDisplayData } from '../../containers/bada/content'
import { mobileCheck, toSimpleService } from '../../containers/bada/utils'
import Form from './Form'

const StyledRow = styled(Row)`
  margin-bottom: 0;
  ${(p) => p.theme.media.min.sm`
    margin-bottom: 100px;
  `}
`
const StyledHeadline = styled(Headline)`
  text-transform: inherit;
  text-transform: ${theme('forms.customerForm.headings.textTransform')};
  line-height: ${theme('forms.customerForm.headings.lineHeight')};
  margin-bottom: 0;
  margin-top: ${theme('forms.customerForm.headings.marginTop')};
  font-size: ${theme('forms.customerForm.headings.fontSize')};
  color: ${theme('forms.customerForm.headings.color')};
`

const StyledPreamble = styled(Preamble)`
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: ${theme('forms.customerForm.fontFamily')};
  font-size: ${theme('forms.customerForm.fontSize')};
  font-weight: ${theme('forms.customerForm.fontWeight')};
  color: ${theme('forms.customerForm.color')};
`

const HeaderContainer = styled.div`
  margin-bottom: ${theme('forms.customerForm.margin')};
`

const CustomerForm = ({
  selectedSlot,
  selectedService,
  selectedStore,
  overrideMeetingType,
  values,
  pageContent,
  updateUiState,
  addHistory,
  staff,
}) => {
  const brandConfig = useBrandConfig(settings.brand).data
  const displayData = getDisplayData(pageContent)
  const service =
    (overrideMeetingType && toSimpleService(overrideMeetingType)) ||
    selectedService
  const serviceData = findServiceData(displayData, service)

  const mutatedValues = {
    ...values,
    usePrepform: serviceData && serviceData.usePrepform,
  }
  const badaBlockData = JSON.parse(
    window.localStorage.getItem('herculesBadaBlock')
  )
  if (badaBlockData && badaBlockData.email) {
    mutatedValues.email = badaBlockData.email
    mutatedValues.newsletterConsent = !!badaBlockData.optin
  }

  if (!serviceData) {
    return null
  }
  return (
    <>
      <StyledRow id="customer-form">
        <Column sm={12}>
          <HeaderContainer>
            <StyledHeadline>
              {serviceData.formHeading || displayData.formHeading}
            </StyledHeadline>
            {serviceData.formPreamble && (
              <StyledPreamble>{serviceData.formPreamble}</StyledPreamble>
            )}
          </HeaderContainer>
        </Column>
        <Column sm={6}>
          {brandConfig && (
            <Form
              selectedStore={selectedStore}
              selectedSlot={selectedSlot}
              selectedService={service}
              staff={staff}
              updateUiState={updateUiState}
              addHistory={addHistory}
              values={mutatedValues}
              pageContent={pageContent}
              isBooking={!!selectedSlot && !!selectedSlot.from}
              brandCountry={brandConfig.countryCode}
            />
          )}
        </Column>
        {!mobileCheck() && (
          <Column sm={6} lg={5} lgOffset={1}>
            <BookingSummary
              store={selectedStore}
              selectedSlot={selectedSlot}
              meetingType={service}
              pageContent={pageContent}
              serviceContent={serviceData}
            />
          </Column>
        )}
      </StyledRow>
    </>
  )
}

CustomerForm.propTypes = {
  updateUiState: PropTypes.func.isRequired,
  addHistory: PropTypes.func.isRequired,
  selectedSlot: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  selectedService: PropTypes.shape({
    id: PropTypes.string.isRequired,
    serviceId: PropTypes.number,
  }),
  selectedStore: PropTypes.shape({
    id: PropTypes.string,
    simplyBookMeId: PropTypes.number,
  }),
  staff: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address: PropTypes.string,
  }).isRequired,
  pageContent: PropTypes.shape({}),
  overrideMeetingType: PropTypes.shape({}),
}

CustomerForm.defaultProps = {
  meetingType: null,
  selectedStore: null,
  selectedService: null,
  selectedSlot: null,
  staff: null,
  pageContent: {},
  overrideMeetingType: null,
}

const enhance = compose(
  fetchStaffMembers,
  withProps(({ listStaffMembersForStoreData }) => ({
    staff:
      listStaffMembersForStoreData &&
      listStaffMembersForStoreData.listStaffMembersForStore,
  })),
  lifecycle({
    componentDidMount() {
      this.props.onMount()
    },
  })
)

export default enhance(CustomerForm)
