import React, { Component } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: inline-block;
  position: relative;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
`

class FileInput extends Component {
  constructor(props) {
    super(props)
    this.state = { key: 0 }
    this.onChange = this.onChange.bind(this)
    this.resetInput = this.resetInput.bind(this)
  }

  onChange(event) {
    this.props.uploader.methods.addFiles(event.target)
    this.resetInput()
  }

  resetInput() {
    // The most reliable, cross-browser way to reset a file input is to
    // re-create the element. By incrementing the key, we trick react into
    // thinking this is a new element, and instead of updating the existing
    // DOM element, a new one will be created (with an empty value).
    this.setState((state) => ({ key: state.key + 1 }))
  }

  render() {
    const { children, className, inputClassName, uploader, ...props } =
      this.props

    return (
      <Container className={className}>
        {children}
        <Input
          {...props}
          type="file"
          key={this.state.key}
          onChange={this.onChange}
          className={inputClassName}
        />
      </Container>
    )
  }
}

export default FileInput
