import { createElement, Component } from 'react'
import PropTypes from 'prop-types'
import PortalRegistry from './PortalRegistry'

/* eslint-disable react/sort-comp */
class PortalDest extends Component {
  constructor(props) {
    super(props)
    this.state = { children: null }
  }
  componentDidMount() {
    this.context.portalRegistry.addContainer(this.props.name, this)
  }

  componentWillUnmount() {
    this.context.portalRegistry.removeContainer(this.props.name)
  }

  render() {
    const { children } = this.state
    const { component, ...props } = this.props

    delete props.name

    if (children && children.length) {
      return createElement(component, props, children)
    }

    return null
  }
}

PortalDest.contextTypes = {
  portalRegistry: PropTypes.instanceOf(PortalRegistry).isRequired,
}

PortalDest.defaultProps = {
  children: undefined,
  component: 'div',
}

export default PortalDest
