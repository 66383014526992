import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import settings from '../settings'
import Link from '../typography/link'
import Logo from './logo/index'
import ArrowBackIcon from '../icons/arrow-back'
import ArrowBackSmallIcon from '../icons/arrow-back-small'
import ArrowLeftIcon from '../icons/arrow-left'
import useTranslation from '../../../utils/translation'

const HeaderWrapper = styled.div`
  height: ${theme('header.height')};

  ${(props) => props.theme.media.min.sm`
    height: ${theme('header.heightDesktop')};
`}
`

const StyledHeader = styled.header`
  background: ${theme('header.backgroundColor')};
  border-bottom: ${theme('header.border')};
  box-shadow: ${theme('header.shadow')};
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: ${theme('header.height')};

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.media.min.sm`
        height: ${theme('header.heightDesktop')};
  `}

  ${(props) => props.theme.media.min.xl`
    height: ${theme('header.heightDesktopLarge')};
  `}
`

const StyledLogo = styled(Logo)`
  width: ${theme('header.logoWidth')};
`

const LogoWrapper = styled.a`
  display: none;
  ${(props) => props.theme.media.min.sm`
        display: inline-block;
        margin: ${theme('header.logoMargin')};
        letter-spacing:0;
    `}
`

const StyledLink = styled(Link)`
  color: ${theme('header.color')};
  text-decoration: none;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${theme('header.fontSize')};
  letter-spacing: ${theme('header.letterSpacing')};
  font-family: ${theme('header.fontFamily')};
  font-weight: ${theme('header.fontWeight')};

  &:before {
    content: '';
    left: 30px;
    right: 30px;
    bottom: calc(50% - 15px);
    height: 1px;
    background: currentColor;
    display: block;
    opacity: 0;
    position: absolute;
    transition: all 0.4s ease 0s;
    pointer-events: none;
  }

  &:hover {
    color: ${theme('header.color')};
    &:before {
      left: 16px;
      right: 16px;
      opacity: 1;
    }
  }
`

const ArrowContainer = styled.div`
  display: inline-block;
  padding-right: ${theme('header.arrowContainer.paddingRight')};
  position: relative;
  top: -1px;
`

const ArrowBack = () => {
  if (
    settings.brand === 'invita' ||
    settings.brand === 'sigdal' ||
    settings.brand === 'novart' ||
    settings.brand === 'marbodal'
  )
    return (
      <ArrowContainer>
        <ArrowBackIcon
          width={24}
          height={14}
          color={settings.brand === 'invita' ? '#C9682E' : '#1E44CB'}
        />
      </ArrowContainer>
    )
  if (
    settings.brand === 'unoform' ||
    settings.brand === 'unoformse' ||
    settings.brand === 'unoformno' ||
    settings.brand === 'unoformbe'
  )
    return (
      <ArrowContainer>
        <ArrowLeftIcon width={26} height={14} color={'#333333'} />
      </ArrowContainer>
    )
  if (
    settings.brand === 'hthdk' ||
    settings.brand === 'hthse' ||
    settings.brand === 'hthfi' ||
    settings.brand === 'hthno' ||
    settings.brand === 'hthde'
  )
    return (
      <ArrowContainer>
        <ArrowBackSmallIcon width={20} height={20} color={'#111111'} />
      </ArrowContainer>
    )
  return <>&lt; </>
}

const Header = () => {
  const t = useTranslation()
return (
  <HeaderWrapper>
    <StyledHeader>
      <StyledLink href="/" aria-label={t('app.header-backlink')}>
        {ArrowBack()}
        <FormattedMessage id="app.header-backlink" />
      </StyledLink>
      <LogoWrapper href="/" aria-label="Logo">
        <StyledLogo brand={settings.brand} />
      </LogoWrapper>
    </StyledHeader>
  </HeaderWrapper>
)}

export default Header
