import { useQuery } from '@apollo/react-hooks'
import DesignerBookingsQuery from '../../../graphql/DesignerBookingsQuery.graphql'
import { getDateRange } from './useSbmCalendar'

const useDesignerAppointments = ({
  storeId,
  locationId,
  serviceId,
  appointmentId,
}) => {
  const { fromDate, toDate } = getDateRange(serviceId)

  return useQuery(DesignerBookingsQuery, {
    variables: {
      storeId: storeId.toString(),
      locationId: parseInt(locationId, 10),
      appointmentId,
      from: fromDate,
      to: toDate,
      serviceId,
    },
    fetchPolicy: 'no-cache',
  })
}

export default useDesignerAppointments
