import React, { Component } from 'react'
import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import LoadingIndicator from '../loadingIndicator'
import LightboxOverlayCloseButton from './LightboxOverlayCloseButton'

const Image = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  cursor: pointer;
`

const Container = styled.div`
  position: relative;

  ${Image}, ${LightboxOverlayCloseButton} {
    transition: opacity 0.2s;
    opacity: ${ifProp('loaded', 1, 0)};
  }
`

const StyledLoader = styled(LoadingIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${ifProp('loaded', 0, 1)};
  transition: opacity 0.2s;
`

const loaderColor = prop('theme.overlay.lightbox.loader')

class LightboxOverlayImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  setImageRef(ref) {
    if (ref && this.state.loaded === false) {
      const isLoaded = ref.complete && ref.naturalHeight !== 0

      if (isLoaded) {
        this.setState({ loaded: isLoaded })
      }
    }
  }

  onImageLoad() {
    if (!this.state.loaded) {
      this.setState({ loaded: true })
    }
  }

  render() {
    return (
      <Container loaded={this.state.loaded} className={this.props.className}>
        <LightboxOverlayCloseButton onClick={this.props.setClosed} />
        <StyledLoader color={loaderColor} loaded={this.state.loaded} />
        <Image
          src={this.props.src}
          onClick={this.props.setClosed}
          ref={this.setImageRef}
          onLoad={this.onImageLoad}
        />
      </Container>
    )
  }
}

export default LightboxOverlayImage
