const fontUrl =
  'https://www.hth.dk/gui/styleguide/project/old/brands/onehth/fonts/'

const hthgoFonts = `
@font-face {
  font-family: 'Clan';
  src: url(${fontUrl}ClanforHTHW01-News.woff2) format("woff2"),
        url(${fontUrl}ClanforHTHW01-News.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: 'Clan';
  src: url(${fontUrl}ClanforHTHW01-Book.woff2) format("woff2"),
        url(${fontUrl}ClanforHTHW01-Book.woff) format("woff");
  font-weight: 300;
}
@font-face {
  font-family:'Clan';
  src: url(${fontUrl}ClanforHTHW01-Bold.woff2) format("woff2"),
        url(${fontUrl}ClanforHTHW01-Bold.woff) format("woff");
  font-weight: 700;
}
`

export default hthgoFonts
