import React from 'react'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Title, Paragraph, Link } from '../text'

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${prop('theme.colors.borderPrimary')};
  margin-bottom: ${prop('theme.gutterWidth')};
  padding: 30px;
`
const ErrorMessage = ({ className, title, message }) => (
  <ErrorWrapper className={className}>
    <Title>{title}</Title>
    <Paragraph>{message}</Paragraph>
  </ErrorWrapper>
)

ErrorMessage.defaultProps = {
  className: undefined,
}

const enhance = compose(
  injectIntl,
  withProps(({ title, message, name = 'content' }) => {
    const titleDescriptor = {
      id: 'app.error.title',
      defaultMessage: 'Sorry, an error ocurred when loading your {name}!',
      values: {
        name: (
          <FormattedMessage
            id={`app.error.sections.${name}`}
            defaultMessage={name}
          />
        ),
      },
    }
    const messageDescriptor = {
      id: 'app.error.message',
      defaultMessage: 'Please {refresh} the page or try again later',
      values: {
        refresh: (
          <Link href={window.location.href} onClick={window.location.reload}>
            <FormattedMessage id="app.error.refresh" defaultMessage="refresh" />
          </Link>
        ),
      },
    }
    return {
      title: title || <FormattedMessage {...titleDescriptor} />,
      message: message || <FormattedMessage {...messageDescriptor} />,
    }
  })
)

export default enhance(ErrorMessage)
