import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

const PRIMARY = '#190606'
const SECONDARY = '#000'
const ON_SECONDARY = '#fff'

const basePixelSize = 18
const FONT_FAMILIES = {
  Inter: '"Inter", sans-serif',
}

const GlobalStyles = createGlobalStyle`
  :root {
    font: normal ${basePixelSize}px/1.43 ${FONT_FAMILIES.Inter};
  }

  html {
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: ${FONT_FAMILIES.Inter};
    background-color: #ffffff;
  }

  h1{
    font-weight: 900
  }

  strong{
    font-weight: 900
  }

  label{
    font-weight: 500;
    margin-bottom: 5px;    
  }
`

export default {
  global: {
    fontSmoothing: 'antialiased',
    fontSize: '18px',
    dl: {
      margin: '0px',
    },
  },
  colors: {
    primary: '#190606',
    link: SECONDARY,
    buttonBackgroundColor: ON_SECONDARY,
    transparent: 'transparent',
    inputBorder: '#d3d0d5',
    activeBorder: '#190606',
    accentShadow: '0 0 0 5px RGB(238, 241, 242)',
    darkGrey: '#190606',
  },
  typography: {
    pageTitle: {
      textTransform: 'uppercase',
      fontFamily: FONT_FAMILIES.Inter,
      fontSize: '42px',
      fontSizeLarge: '42px',
      letterSpacing: '0px',
    },
    headline: {
      textTransform: 'uppercase',
      fontSize: '26px',
      fontSizeLarge: '26px',
      fontFamily: FONT_FAMILIES.Inter,
      color: '#190606',
    },
    subHeadline: {
      textTransform: 'uppercase',
      fontWeight: 900,
      fontSize: '11px',
      fontFamily: FONT_FAMILIES.Inter,
    },
    title: {
      textTransform: 'none',
      fontFamily: FONT_FAMILIES.Inter,
    },
    paragraph: {
      fontSize: '16px',
      fontSizeLarge: '18px',
      fontSizeLead: '20px',
      fontFamily: FONT_FAMILIES.Inter,
    },
    preamble: {
      fontSize: '14px',
      fontFamily: FONT_FAMILIES.Inter,
    },
    storeName: {
      fontSize: '16px',
      fontWeight: 900,
      color: '190606',
    },
    storeAddress: {
      color: '#190606',
      fontSize: '16px',
    },
  },
  radioButtonRich: {
    borderRadius: '3px',
  },

  header: {
    color: PRIMARY,
    backgroundColor: '#fff',
    border: '0',
    shadow: '0px 1px 0px rgba(25,6,6, 0.096496)',
    height: '58px',
    heightDesktop: '83px',
    logoMargin: '0',
    fontSize: '16px',
    letterSpacing: '0px',
  },
  contentHeader: {
    heading: {
      mobile: {
        fontSize: '26px',
        lineHeight: '32px',
      },
      desktop: {
        fontSize: '42px',
        letterSpacing: '0px',
      },
    },
    mobile: {
      alignItems: 'center',
      height: '370px',
    },
    otherNeeds: {
      height: '76px',
      textDecoration: 'none',
      lineHeight: '26px',
      linkColor: '#001C33',
      desktop: {
        positionTop: '-76px',
        marginBottom: '-66px',
      },
      mobile: {
        positionTop: '-76px',
        marginBottom: '-66px',
      },
      fontSize: {
        desktop: '16px',
        mobile: '16px',
      },
      popDownBgColor: '#EBE8E2',
    },
    usps: {
      desktop: {
        fontSize: '21px',
      },
    },
  },
  stores: {
    headers: {
      textTransform: 'uppercase',
      fontSize: '26px',
    },
    distance: {
      fontSize: '16px',
    },
    changeStoreLink: {
      fontWeight: '400',
      fontSize: { mobile: '16px', desktop: '18px' },
    },
  },
  storeList: {
    row: {
      marginBottom: '20px',
    },
    heading: {
      fontSize: '18px',
      margin: '20px 0px 0px 0px',
    },
  },
  showMore: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  postalCodeOverlay: {
    inputField: {
      height: '50px',
      padding: '10px 10px 10px 10px',
    },
  },
  tabs: {
    color: {
      inactive: theme('colors.primary'),
      bottomBorder: '#d3d0d5',
      default: theme('colors.primary'),
    },
    fontWeight: {
      active: '900',
      inactive: '400',
    },
    fontSize: '16px',
    header: {
      link: {
        color: SECONDARY,
        textDecoration: 'none',
      },
    },
  },
  forms: {
    errorMessage: {
      fontSize: '14px',
    },
    inputWrapper: {
      minHeight: '90px',
    },
    fontFamily: FONT_FAMILIES.Inter,
    paddingInput: '20px',
    position: {
      top: '21px',
    },
    colors: {
      valid: '#9be587',
      border: '#D3D0D5',
    },
    borderRadius: '3px',
    customerForm: {
      fontSize: '18px',
      headings: {
        fontSize: '26px',
        textTransform: 'uppercase',
        marginTop: '60px',
      },
      preamble: {
        fontSize: '15px',
      },
    },
    prepForm: {
      paragraph: {
        marginBottom: '30px',
      },
      headline: {
        letterSpacing: {
          normal: '0px',
        },
        desktop: { fontSize: '42px' },
      },
    },
    label: {
      fontSize: '16px',
      fontWeight: 400,
    },
    button: {
      padding: '13px 36px 14px 36px',
      height: '50px',
    },
    paragraph: {
      fontSize: '18px',
    },
  },
  photoUpload: {
    button: {
      padding: '15px 0px 15px 0px',
    },
  },
  contactDetails: {
    dt: {
      fontWeight: 900,
      textTransform: 'none',
    },
  },
  bookingSummary: {
    backgroundColor: '#EEF1F2',
    borderColor: 'transparent',
    borderRadius: '3px',
    itemTitle: {
      color: '#190606',
      textTransform: 'none',
      fontSize: '16px',
      marginBottom: '0px',
    },
    itemText: {
      fontSize: '16px',
    },
  },
  meetingType: {
    text: {
      fontSize: '14px',
    },
    name: {
      fontWeight: '900',
      fontSize: '16px',
    },
  },
  button: {
    colors: {
      primaryColor: '#fff',
      primaryBackground: '#0D5E66',
    },
    backgroundColor: 'transparent',
    fontWeight: 900,
    textTransform: 'none',
    fontSize: '16px',
    letterSpacing: '0px',
    border: '1px solid transparent',
    borderRadius: '25px',
    blockButtonWidth: 'auto',
    wideButtonWidth: 'auto',
    padding: '14px 36px 14px 36px',
    height: '50px',
  },
  designer: {
    headline: {
      marginBottom: '8px',
      fontSize: '21px',
      textTransform: {
        normal: 'none',
      },
      letterSpacing: {
        normal: '0px',
      },
    },
    paragraph: {
      lineHeight: {
        mobile: '20px',
        desktop: '20px',
      },
      fontSize: {
        desktop: '16px',
      },
    },
    background: {
      color: '#EEF1F2',
    },
    name: {
      fontSize: '14px',
    },
    location: {
      fontSize: '14px',
    },
    border: '1px solid transparent',
    borderRadius: '3px',
  },
  questionBlock: {
    options: {
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0',
      },
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      },
    },
    optionsItem: {
      wrapper: {
        justifyContent: 'flex-end',
      },
      backgroundColor: 'transparent',
      borderRadius: '5px',
      width: {
        mobile: '120px',
        desktop: '140px',
      },
      margin: {
        mobile: '0 0 10px 0',
        desktop: '7px',
      },
    },
    textArea: {
      fontSize: '16px',
    },
    anotherOption: {
      text: {
        fontSize: '16px',
      },
    },
  },
  textOption: {
    backgroundColor: 'transparent',
    wrap: {
      mobile: {
        width: '140px',
      },
    },
    desktop: {
      minWidth: '150px',
    },
    text: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      fontStyle: 'none',
      fontSize: {
        mobile: '14px',
        desktop: '14px',
        fontWeight: 900,
      },
    },
  },
  confirmation: {
    header: {
      textAlign: 'left',
      fontSize: '42px',
      fontSizeDesktop: '42px',
    },
    row: {
      padding: '0 20px 0 20px',
    },
  },
  imageOption: {
    text: {
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      fontStyle: 'none',
    },
  },
  bookingDetails: {
    image: {
      margin: {
        top: 0,
        left: 0,
        right: 0,
      },
    },
    backgroundColor: '#fff',
    border: {
      color: '#D3D0D5',
    },
    borderRadius: '5px',
    subHeadline: {
      textTransform: 'none',
      fontSize: '16px',
    },
  },
  quickQuestions: {
    heading: {
      style1: {
        fontSize: {
          mobile: '16px',
          desktop: '16px',
        },
        letterSpacing: '0px',
        textTransform: 'none',
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
      },
      style2: {
        fontSize: {
          mobile: '21px',
          desktop: '21px',
        },
        letterSpacing: '0px',
        textTransform: 'none',
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
      },
    },
    preamble: {
      fontSize: { mobile: '16px', desktop: '16px' },
      lineHeight: '24px',
    },
  },
  GlobalStyles,
}
