import React from 'react'
import PropTypes from 'prop-types'

const Discover = ({ width, height }) => (
  <svg viewBox="0 0 26 23" width={width} height={height}>
    <defs>
      <rect id="path-1" x="0" y="0" width={width} height={height} />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-174.000000, -388.000000)">
        <g transform="translate(16.000000, 359.000000)">
          <g transform="translate(16.000000, 24.000000)">
            <g transform="translate(139.000000, 0.000000)">
              <use id="Padding" fillOpacity="0" fill="#FFFFFF" />
              <g
                id="airplay"
                mask="url(#mask-2)"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <g transform="translate(4.000000, 6.000000)" id="Shape">
                  <path d="M3.6,16.3333333 L2.4,16.3333333 C1.0745166,16.3333333 0,15.2886644 0,14 L0,2.33333333 C0,1.04466892 1.0745166,0 2.4,0 L21.6,0 C22.9254834,0 24,1.04466892 24,2.33333333 L24,14 C24,15.2886644 22.9254834,16.3333333 21.6,16.3333333 L20.4,16.3333333" />
                  <polygon points="12 14 18 21 6 21" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

Discover.defaultProps = {
  width: 50,
  height: 50,
}

Discover.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Discover
