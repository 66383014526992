import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const Paragraph = styled.p`
  font-family: ${prop(`theme.typography.paragraph.fontFamily`)};
  font-weight: ${prop(`theme.typography.paragraph.fontWeight`)};
  font-size: ${prop(`theme.typography.paragraph.fontSize`)};
  font-style: ${prop(`theme.typography.paragraph.fontStyle`)};
  line-height: ${prop(`theme.typography.paragraph.lineHeight`)};
  margin-top: ${prop(`theme.typography.paragraph.marginTop`)};
  margin-bottom: ${prop(`theme.typography.paragraph.marginBottom`)};
  text-transform: ${prop(`theme.typography.paragraph.textTransform`)};
  letter-spacing: ${prop(`theme.typography.paragraph.letterSpacing`)};

  color: ${prop(`theme.typography.paragraph.color`, 'inherit')};
  text-align: ${prop('align', 'inherit')};

  ${ifProp('noMargins', `margin: 0;`)};

  ${(props) => props.theme.media.min.sm`
    font-size: ${prop(`theme.typography.paragraph.fontSizeDesktop`)};
    line-height: ${prop(`theme.typography.paragraph.lineHeightDesktop`)};
    text-shadow: none;
  `};
`

export default Paragraph
