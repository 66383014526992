import React from 'react'

const CheckmarkFilled = (props) => (
  <svg viewBox="0 0 26 26" {...props}>
    <circle
      fill="currentColor"
      cx="12.8222656"
      cy="12.9575797"
      r="12.4889836"
    />
    <polygon
      fill="#FFFFFF"
      transform="translate(13.667105, 12.982042) rotate(-5.000000) translate(-13.667105, -12.982042) "
      points="7.42615852 13.4404311 12.0149273 18.0291999 19.9080514 9.78745277 18.1834116 7.93488462 12.1168972 14.6121944 9.25631839 11.7366153"
    />
  </svg>
)

export default CheckmarkFilled
