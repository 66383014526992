import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

const basePixelSize = 14
const FONT_FAMILIES = {
  Apercu: 'Apercu,arial,sans-serif',
}

const GlobalStyles = createGlobalStyle`
  :root {
    font: 400 ${basePixelSize}px/1.43 ${FONT_FAMILIES.Apercu};    
  }
  
  html {
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll;
  }

  body {        
    font-family: ${FONT_FAMILIES.Apercu};      
  } 

  h1{
    font-weight: 400
  }

  strong{
    font-weight: 700
  }

  label{
    font-weight: 400;
    margin-bottom: 5px;    
  }
       
`

export default {
  global: {
    fontSmoothing: 'antialiased',
    boxSizing: 'border-box',
    dl: {
      marginTop: '0px',
    },
    p: {
      marginBlockStart: '0px',
      marginBottom: '0px',
    },
    h2: {
      marginBlockStart: '0px',
    },
  },
  colors: {
    default: theme('colors.darkGrey'),
    link: '#000',
    secondaryBackgroundColor: '#f3f3f3',
    accentShadow: '0 0 0 4px rgba(0, 27, 51, 0.15)',
  },
  stores: {
    headers: {
      marginTop: '30px',
      textTransform: 'uppercase',
    },
  },
  slots: {
    headers: {
      textTransform: 'uppercase',
    },
  },
  tabs: {
    color: {
      inactive: '#909090',
      links: theme('colors.link'),
      default: theme('colors.darkGrey'),
    },
  },
  header: {
    backgroundColor: '#fff',
    border: '0',
    color: theme('colors.link'),
    shadow: '0 0 7px rgba(0,0,0,.3)',
    height: '58px',
    heightDesktop: '68px',
    logoWidth: '95px',
    logoMargin: '25px 0 0',
  },
  contentHeader: {
    height: {
      desktop: '460px',
      small: '290px',
      mobile: '250px',
    },
    subtitle: {
      display: 'block',
      marginTop: '10px',
      marginRight: 'auto',
      marginBottom: '20px',
      marginLeft: 'auto',
      maxWidth: '1200px',
      color: '#ffffff',
      fontSize: '14px',
      desktop: {
        marginBottom: '30px',
      },
    },
    imageHeader: {
      backgroundColor: '#f9f8f2',
    },
    backgroundColor: '#fff',
    heading: {
      textAlign: 'left',
      color: '#ffffff',
      desktop: {
        marginTop: '75px',
        marginBottom: '40px',
        fontSize: '40px',
        maxWidth: '600px',
        lineHeight: '50px',
      },
      mobile: {
        marginTop: '40px',
        marginRight: '10px',
        marginBottom: '15px',
        marginLeft: '10px',
        fontSize: '26px',
        lineHeight: '28px',
        maxWidth: '270px',
      },
    },
    desktop: {
      height: '500px',
      alignItems: 'center',
    },
    mobile: {
      height: '400px',
      alignItems: 'center',
      paddingBottom: '60px',
    },
    icons: {
      color: '#fff',
      desktop: {
        width: '25px',
        height: '25px',
      },
      mobile: {
        width: '25px',
        height: '25px',
      },
    },
    usps: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '20px',
      marginRight: 'auto',
      marginBottom: '60px',
      marginLeft: 'auto',
      paddingLeft: '15px',
      fontSize: '18px',
      color: '#fff',
      lineHeight: '20px',
      textAlign: 'left',
      maxWidth: '800px',
      mobile: {
        maxWidth: '250px',
        marginBottom: '8px',
      },
    },
    otherNeeds: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  typography: {
    pageTitle: {
      textTransform: 'none',
    },
    headline: {
      textTransform: 'uppercase',
      fontSizeDesktop: '28px',
      fontSizeLarge: '28px',
      fontWeight: 700,
      letterSpacing: '2.5px',
    },
    title: {
      textTransform: 'none',
      letterSpacing: '0.6px',
    },
    usp: {
      fontSize: '18px',
    },

    paragraph: {
      fontWeight: 400,
      fontWeightLarge: 400,
      fontWeightSmall: 400,
    },
    subHeadline: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSizeDesktop: '19px',
    },
    storeName: {
      marginTop: '4px',
    },
  },
  showMore: {
    color: '#909090',
  },
  meetingTypeSelection: {
    meetingTypeItem: {
      flexDirection: 'row',
      textAlign: 'center',
      padding: '0 20px 10px 20px',
      display: 'flex',
    },
    margin: '0 0 80px 0',
  },
  meetingType: {
    name: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '22px',
      maxWidth: '100%',
    },
    text: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '18px',
      padding: '0 10px',
    },
    home: {
      marginTop: '10px',
      position: 'relative',
      top: '0',
    },
    video: {
      marginTop: 0,
      position: 'relative',
      top: '0',
    },
    store: {
      marginTop: '10px',
      position: 'relative',
      top: '0',
    },
  },
  skipSlots: {
    fontWeight: 700,
    fontSize: '15px',
  },
  designer: {
    background: {
      color: theme('colors.secondaryBackgroundColor'),
    },
    headline: {
      textTransform: { normal: 'none', embedded: 'none' },
      fontSize: '22px',
      margin: '15px 0 15px 0',
      letterSpacing: {
        normal: '1px',
        embedded: '0px',
      },
    },
    margin: {
      desktop: '30px 0',
      mobile: '15px 0 30px 0',
    },
    right: {
      flexDirection: 'row-reverse',
    },
  },
  quickQuestions: {
    container: {
      bottomBorder: '1px solid #dbdbdb',
      mobile: {
        padding: '0 0 5px 0',
        margin: '20px 0 0 0',
      },
      desktop: {
        flexDirection: 'row',
        padding: '0 0 10px 0',
        margin: '40px 0 0 0',
      },
    },
    heading: {
      style1: {
        color: '#555',
        fontWeight: 400,
        letterSpacing: '.5px',
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '16px',
          desktop: '16px',
        },
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'uppercase',
        minWidth: {
          desktop: '265px',
        },
      },
      style2: {
        color: '#555',
        fontWeight: 700,
        letterSpacing: '.5px',
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '22px',
          desktop: '28px',
        },
        lineHeight: {
          mobile: '33px',
          desktop: '42px',
        },
        margin: {
          mobile: '0 0 18px 0',
          desktop: '0 30px 18px 0',
        },
        textTransform: 'uppercase',
        minWidth: {
          desktop: '420px',
        },
      },
      style3: {
        color: '#555',
        fontWeight: 400,
        letterSpacing: '.5px',
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '16px',
          desktop: '16px',
        },
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'uppercase',
        minWidth: {
          desktop: '265px',
        },
      },
    },
  },
  questionBlock: {
    options: {
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0 0 0 auto',
      },
    },
    optionsItem: {
      width: {
        mobile: '160px',
        desktop: '160px',
      },
      margin: {
        mobile: '0 0 10px 0',
      },
    },
  },
  imageOption: {
    wrap: {
      flexDirection: 'column',
      padding: '15px 0',
    },
    image: {
      mobile: {
        height: '100px',
      },
      desktop: {
        height: '50px',
      },
    },
    text: {
      fontSize: {
        desktop: '16px',
      },
      marginTop: '10px',
      marginBottom: '5px',
    },
  },
  textOption: {
    wrap: {
      mobile: {
        padding: '15px',
        width: '160px',
      },
      desktop: {
        padding: '25px 15px',
      },
    },
    text: {
      fontFamily: theme('typography.paragraph.fontFamily'),
      fontSize: {
        mobile: '16px',
        desktop: '16px',
        fontWeight: 400,
      },
      fontWeight: 400,
      active: {
        fontWeight: 400,
      },
    },
  },
  photoUpload: {
    headline: {
      fontSize: '18px',
    },
    section: {
      padding: `40px 0px`,
      borderBottom: `1px solid ${theme('colors.slate')}`,
    },
    container: {
      padding: `20px 0px`,
    },
    checkbox: {
      text: {
        fontSize: '13px',
        fontWeight: 300,
      },
    },
  },
  forms: {
    position: {
      top: '22px',
    },
    paddingInput: '22px',
    colors: {
      prefilledBackground: '#f1f1f1',
      valid: '#9be587',
    },
    customerForm: {
      headings: {
        textTransform: 'uppercase',
      },
      margin: '21px',
    },
    prepForm: {
      headline: {
        desktop: {
          fontSize: '28px',
          marginTop: '80px',
          lineHeight: '48px',
        },
        textTransform: { normal: 'none', embedded: 'none' },
        letterSpacing: { normal: '1px', embedded: '0px' },
      },
    },
  },
  appointmentDetails: {
    heading: {
      fontWeight: 700,
      textTransform: 'none',
    },
    text: {
      fontWeight: 400,
      textTransform: 'none',
    },
  },
  confirmation: {
    header: {
      textTransform: 'none',
      fontSize: '28px',
      fontSizeDesktop: '40px',
    },
  },
  GlobalStyles,
}
