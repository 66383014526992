import reducerMap from '../redux/reducerMap'
import { actionTypes } from './actions'

export const initialState = {
  wishlist: [],
  viewedProducts: [],
  onboardingResult: {},
  kitchenConfigurations: {},
  resetNotificationsAt: new Date(0).toISOString(),
  sharedStateAge: {
    timestamp: new Date(0).toISOString(),
    source: null,
  },
}

const wishlistReducer = reducerMap(
  {
    [actionTypes.ADD_WISHLIST]: (state, action) =>
      state.map((product) => product.id).includes(action.product.id)
        ? state
        : [...state, action.product],
    [actionTypes.REMOVE_WISHLIST]: (state, action) =>
      state.map((product) => product.id).includes(action.productId)
        ? state.filter((product) => product.id !== action.productId)
        : state,
    [actionTypes.CLEAR_WISHLIST]: () => [],
  },
  initialState.wishlist
)

const viewedProductsReducer = reducerMap(
  {
    [actionTypes.VIEW_PRODUCT]: (state, action) =>
      [
        action.product,
        ...state.filter((product) => product.id !== action.product.id),
      ].slice(0, 7),
  },
  initialState.viewedProducts
)

const onboardingResultReducer = reducerMap(
  {
    [actionTypes.SET_ONBOARDING_RESULT]: (state, action) => action.result,
    [actionTypes.CLEAR_ONBOARDING_RESULT]: () => ({}),
  },
  initialState.onboardingResult
)

const kitchenConfigurationsReducer = reducerMap(
  {
    [actionTypes.ADD_KITCHEN_CONFIGURATION]: (state, action) => {
      const { kitchenRangeName, config } = action
      if (!kitchenRangeName || !config) return state

      const configurationNumber = parseInt(config.configurationNumber, 10)

      const newConfig = {
        ...config,
        configurationNumber: configurationNumber || 1,
        createdAt: new Date().toISOString(),
      }

      if (state[kitchenRangeName]) {
        const kitchenRange = state[kitchenRangeName]

        const existingConfigurationIds = kitchenRange.map(
          (c) => c.configurationNumber
        )

        const configurationNumberIndex = kitchenRange.findIndex(
          (item) => item.configurationNumber === configurationNumber
        )

        if (configurationNumberIndex === -1) {
          const newNumber = Math.max(...existingConfigurationIds) + 1

          const newConfigIncremented = {
            ...newConfig,
            configurationNumber: newNumber,
          }

          return {
            ...state,
            [kitchenRangeName]: [...kitchenRange, newConfigIncremented],
          }
        }
        return {
          ...state,
          [kitchenRangeName]: kitchenRange.map((currentConfig, i) =>
            i === configurationNumberIndex ? newConfig : currentConfig
          ),
        }
      }

      return {
        ...state,
        [kitchenRangeName]: [newConfig],
      }
    },
    [actionTypes.CLEAR_KITCHEN_CONFIGURATIONS]: () => ({}),
  },
  initialState.kitchenConfigurations
)

const notificationReducer = reducerMap(
  {
    [actionTypes.RESET_NOTIFICATIONS]: () => new Date().toISOString(),
  },
  initialState.resetNotificationsAt
)

const sharedStateAgeReducer = reducerMap(
  {
    [actionTypes.UPDATED_SHARED_STATE]: (state, action) => ({
      source: action.source,
      timestamp: new Date().toISOString(),
    }),
  },
  initialState.sharedStateAge
)

export const reducers = {
  wishlist: wishlistReducer,
  viewedProducts: viewedProductsReducer,
  onboardingResult: onboardingResultReducer,
  kitchenConfigurations: kitchenConfigurationsReducer,
  resetNotificationsAt: notificationReducer,
  sharedStateAge: sharedStateAgeReducer,
}
