import magnetFonts from './magnet'
import hthgoFonts from './hthgo'
import noremaFonts from './norema'
import marbodalFonts from './marbodal'
import eweFonts from './ewe'
import invitaFonts from './invita'
import intuoFonts from './intuo'

export {
  magnetFonts,
  hthgoFonts,
  noremaFonts,
  marbodalFonts,
  eweFonts,
  invitaFonts,
  intuoFonts,
}
