import PropTypes from 'prop-types'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'
import { prop, ifProp, switchProp, withProp } from 'styled-tools'

const sizeStyle = switchProp('size', {
  small: css`
    font-size: 13px;
  `,
  normal: css`
    font-size: 14px;
  `,
  large: css`
    font-size: 16px;
  `,
})

const colorStyle = switchProp('kind', {
  primary: css`
    color: ${ifProp(
      'inverted',
      prop('theme.button.colors.primaryBackground'),
      prop('theme.button.colors.primaryColor')
    )};
  `,
  secondary: css`
    color: ${ifProp(
      'inverted',
      prop('theme.button.colors.secondaryBackground'),
      prop('theme.button.colors.secondaryColor')
    )};
  `,
  success: css`
    color: ${ifProp(
      'inverted',
      prop('theme.button.colors.successBackground'),
      prop('theme.button.colors.successColor')
    )};
  `,
  inverted: css`
    color: ${prop('theme.button.colors.primaryBackground')};
  `,
})

const backgroundStyle = switchProp('kind', {
  primary: css`
    background: ${ifProp(
      'inverted',
      prop('theme.button.colors.primaryColor'),
      prop('theme.button.colors.primaryBackground')
    )};
  `,
  secondary: css`
    background: ${ifProp(
      'inverted',
      prop('theme.button.colors.secondaryColor'),
      prop('theme.button.colors.secondaryBackground')
    )};
  `,
  success: css`
    background: ${ifProp(
      'inverted',
      prop('theme.button.colors.successColor'),
      prop('theme.button.colors.successBackground')
    )};
  `,
  inverted: css`
    background: transparent;
    border-color: ${prop('theme.button.colors.primaryBackground')};
  `,
})

const lightenColorStyle = switchProp('kind', {
  primary: css`
    color: ${prop('theme.button.colors.primaryColor')};
  `,
  secondary: css`
    color: ${prop('theme.button.colors.secondaryColor')};
  `,
  success: css`
    color: ${prop('theme.button.colors.successColor')};
  `,
})

const lightenBackgroundStyle = switchProp('kind', {
  primary: css`
    background: ${withProp(
      'theme.button.colors.primaryBackground',
      lighten(0.1)
    )};
  `,
  secondary: css`
    background: ${withProp(
      'theme.button.colors.secondaryBackground',
      lighten(0.1)
    )};
  `,
  success: css`
    background: ${withProp(
      'theme.button.colors.successBackground',
      lighten(0.1)
    )};
  `,
})

const blockStyle = ifProp(
  'block',
  css`
    display: block;
    width: 100%;
    margin-right: 0;
  `
)

const BaseButton = styled.button`
  ${colorStyle};
  ${sizeStyle};
  ${blockStyle};

  padding: ${prop('padding', prop('theme.button.padding'))};
  margin: ${prop('margin', prop('theme.button.margin'))};
  border: 0;
  font-family: ${prop('theme.button.fontFamily')};
  font-weight: ${prop('theme.button.fontWeight')};
  cursor: pointer;
  text-transform: ${prop('theme.button.textTransform')};
  letter-spacing: ${prop('theme.button.letterSpacing')};
  position: relative;
  z-index: 0;
  background-color: ${prop('theme.button.backgroundColor')};
  border-radius: ${prop('theme.button.borderRadius')};

  &::before {
    border-width: ${ifProp(
      { kind: 'inverted' },
      prop('theme.button.borderWidth'),
      '0'
    )};
    border-style: solid;
    border-radius: ${prop('theme.button.borderRadius')};
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: ${ifProp(
      { kind: 'inverted' },
      prop('theme.button.borderRightPosition'),
      '0'
    )};
    bottom: 0;
    left: ${ifProp(
      { kind: 'inverted' },
      prop('theme.button.borderLeftPosition'),
      '0'
    )};
    z-index: -1;
    ${backgroundStyle};
    transition-property: transform, background, left, right;
    transition-duration: 0.625s;
    transition-timing-function: cubic-bezier(0, 0.8, 0.26, 0.99);
  }

  &:not([disabled]) {
    &:hover,
    &:focus {
      ${lightenColorStyle};
      &::before {
        ${lightenBackgroundStyle};
        left: 0;
        right: 0;
      }
    }
  }

  &:focus {
    outline: 0;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  & + & {
    margin-left: ${ifProp('block', 0, prop('theme.button.siblingMargin'))};
    margin-top: ${ifProp('block', prop('theme.button.siblingMargin'), 0)};
  }
`

BaseButton.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary', 'success', 'inverted']),
  size: PropTypes.oneOf(['normal', 'small', 'large']),
  block: PropTypes.bool,
  inverted: PropTypes.bool,
}

BaseButton.defaultProps = {
  kind: 'primary',
  size: 'normal',
  block: false,
  inverted: false,
}

export default BaseButton
