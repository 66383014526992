import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import React, { Component } from 'react'
import { badaDefaultProps, badaPropTypes } from '../../../../types'
import { scrollTo, trackerDate } from '../utils'
import tracker from '../../../../utils/tracker'
import { StepBookingSlot } from './Components'

class BookingSlotStep extends Component {
  render() {
    const {
      props: {
        badaProps: { bookings },
        pageContent,
        wizard: {
          step: { two, three },
        },
        selectedStore,
        selectedService,
        updateUiState,
        history,
      },
    } = this.props

    return (
      <>
        <StepBookingSlot
          show={three}
          props={{
            bookings,
            pageContent,
            selectedStore,
            selectedService,
            updateUiState,
            onChange: (state) => {
              history.push(`${window.location.search}#booking`, {
                ...state,
                bookingCompleted: false,
              })
              scrollTo(this.ref)
              updateUiState(state)
              tracker.trackClick(
                'BADA 2.0 meeting slots',
                trackerDate(state.selectedSlot && state.selectedSlot.from)
              )
            },
            onMount: () => {
              if (!two) {
                tracker.trackAuto(
                  'BADA 2.0 meeting type',
                  selectedStore.acceptMeetings
                    ? `singletype store - ${selectedStore.name}`
                    : `singletype home - ${selectedStore.name}`
                )
              }
              tracker.trackPageview('BADA 2.0 badaslots', '/bada/step-2/')
            },
          }}
        />
        <div
          ref={(ref) => {
            this.ref = ref
          }}
        />
      </>
    )
  }
}

BookingSlotStep.propTypes = {
  ...badaPropTypes,
}

BookingSlotStep.defaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(withApollo)

export default enhance(BookingSlotStep)
