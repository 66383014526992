import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const bubbleAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(0.4);
  }
`

const sizeStyle = (props) => {
  switch (props.size) {
    case 'small':
      return '0.25em'
    case 'large':
      return '1.25em'
    default:
      return '0.75em'
  }
}

const BubbleWrapper = styled.div`
  margin: 0 auto;
  width: 7.5em;
  height: 2.5em;
  text-align: center;
  font-size: ${sizeStyle};
  color: ${prop('color')};
  opacity: 0.7;
  ${ifProp(
    'center',
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `
  )}
`

const Bubble1 = styled.div`
  animation: ${(props) =>
    css`
      ${props.duration}s ${bubbleAnimation} ease-in-out infinite both
    `};
  animation-delay: ${(props) => `${props.duration * -0.32}s`};
  width: 2.5em;
  height: 2.5em;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
`

const Bubble2 = styled(Bubble1)`
  animation-delay: ${(props) =>
    css`
      ${props.duration * -0.16}s
    `};
`

const Bubble3 = styled(Bubble1)`
  animation-delay: 0s;
`

const BubbleLoader = ({ duration, color, size, ...rest }) => (
  <BubbleWrapper color={color} size={size} {...rest}>
    <Bubble1 duration={duration} />
    <Bubble2 duration={duration} />
    <Bubble3 duration={duration} />
  </BubbleWrapper>
)

BubbleLoader.defaultProps = {
  duration: 0.7,
  color: '#000',
  size: null,
}

export default BubbleLoader
