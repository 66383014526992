import merge from 'deepmerge'
import localStore from './localStore'

export const ACTION = '@@REHYDRATE_STATE'

export const rehydrateStateReducer = (reducer) => (state, action) => {
  if (action.type === ACTION && action.state) {
    return merge(state, action.state)
  }

  return reducer(state, action)
}

export const rehydrateState = () => ({
  type: ACTION,
  state: localStore.get(),
})

const defaultOpts = { keys: [] }

const syncLocalStorage =
  ({ keys } = defaultOpts) =>
  (store) =>
  (next) =>
  (action) => {
    const result = next(action)
    const state = store.getState()

    localStore.set(
      keys.reduce((acc, key) => {
        const payload = state[key]
        const data = payload

        acc[key] = data || {}
        return acc
      }, {})
    )

    return result
  }

export default syncLocalStorage
