import { withProps } from 'recompose'
import badaPropsChange from './BadaProps'
import wizardChange from './Wizard'

const reducer = withProps(
  ({
    storesByAddressData: { searchOpenAuthStoresByAddress },
    StoreWithDistance,
    sbmBookingsByStoreId,
    msBookingsByStoreId,
    pageContent,
    selectedStore,
    selectedService,
    selectedSlot,
    bookingCompleted,
    prepCompleted,
    searchAddressId,
    selectedAddress,
    retrieveAddressQuery,
    overrideMeetingType,
  }) => ({
    wizard: wizardChange({
      selectedStore,
      selectedService,
      selectedSlot,
      bookingCompleted,
      prepCompleted,
      sbmBookingsByStoreId,
      msBookingsByStoreId,
      pageContent,
    }),
    badaProps: badaPropsChange({
      searchOpenAuthStoresByAddress,
      StoreWithDistance,
      sbmBookingsByStoreId,
      msBookingsByStoreId,
      pageContent,
      overrideMeetingType,
    }),
    selectedCity:
      searchAddressId &&
      searchAddressId.searchAddress &&
      searchAddressId.searchAddress.addresses.length === 0
        ? selectedAddress && selectedAddress.city // if postcode anywhere cannot deliver any address then set ipstack city name as selectedCity
        : retrieveAddressQuery &&
          retrieveAddressQuery.retrieveAddress &&
          retrieveAddressQuery.retrieveAddress.city, // set selectedCity to response from postcode anywhere
  })
)

export default reducer
