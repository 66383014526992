import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import settings from '../../../../settings'
import { StoreItemLong } from '../store-item'
import useBrandConfig from '../../../../../hooks/useBrandConfig'

const StyledStoreList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
`

const StyledRow = styled.div`
  margin-bottom: ${theme('storeList.row.marginBottom')};
`
const StyledHeader = styled.div`
  color: ${theme('storeList.heading.color')};
  display: block;
  height: 42px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('storeList.heading.fontSize')};
  margin: ${theme('storeList.heading.margin')};
  font-weight: 700;
`

/**
 * @param {{
 *  onChange: (any) => any,
 *  stores: any[],
 *  brandStoresHeading: string,
 *  otherStoresHeading: string
 * }} params
 */
const StoreItemsFull = ({
  stores,
  onChange,
  brandStoresHeading,
  otherStoresHeading,
}) => {
  const brandConfig = useBrandConfig(settings.brand).data

  if (brandConfig) {
    stores.sort((a, b) =>
      a.compareName.localeCompare(b.compareName, brandConfig.languageTag)
    )
  }
  const brandStores = stores.filter(
    (s) => s.storeType === settings.brandStoreType
  )
  const otherStores = stores.filter(
    (s) => s.storeType !== settings.brandStoreType
  )

  const showHeadings = otherStores.length > 0 && brandStores.length > 0

  return (
    <>
      {brandStores.length > 0 && (
        <StyledRow>
          {showHeadings && <StyledHeader>{brandStoresHeading}</StyledHeader>}
          <StyledStoreList>
            {brandStores.map((store) => (
              <StoreItemLong key={store.id} store={store} onChange={onChange} />
            ))}
          </StyledStoreList>
        </StyledRow>
      )}
      {otherStores.length > 0 && (
        <StyledRow>
          {showHeadings && <StyledHeader>{otherStoresHeading}</StyledHeader>}
          <StyledStoreList>
            {otherStores.map((store) => (
              <StoreItemLong key={store.id} store={store} onChange={onChange} />
            ))}
          </StyledStoreList>
        </StyledRow>
      )}
    </>
  )
}

export default StoreItemsFull
