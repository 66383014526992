import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import warning from 'warning'

import RadioButton from './radioButton'
import RadioButtonSquare from './radioButtonSquare'

const deprecations = (props) => {
  warning(
    props.changeHandler == null,
    '[RadioGroup] `changeHandler` is deprecated. Use `onChange` instead.'
  )

  warning(
    props.blurHandler == null,
    '[RadioGroup] `blurHandler` is deprecated. Use `onBlur` instead.'
  )
}

const RadioGroupWrapper = styled.span`
  ${(props) =>
    props.type === 'square-fluid' &&
    css`
      display: block;
      width: 100%;
    `};
`

class RadioGroup extends Component {
  static defaultProps = {
    onChange: null,
    onBlur: null,
    changeHandler: null,
    blurHandler: null,
    defaultValue: '',
    value: '',
    type: 'regular',
  }

  state = {
    value: this.props.value,
  }

  componentWillMount() {
    deprecations(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }

    deprecations(nextProps)
  }

  stateUpdated = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event)
    } else if (this.props.changeHandler) {
      this.props.changeHandler(event)
    }
  }

  selectOption = (event) => {
    event.persist()

    this.setState({ value: event.currentTarget.value }, () =>
      this.stateUpdated(event)
    )

    if (this.props.onBlur) {
      this.props.onBlur(event)
    } else if (this.props.blurHandler) {
      this.props.blurHandler(event)
    }
  }

  render() {
    const { name, options, type } = this.props
    const { value } = this.state
    const width = `${100 / options.length}%`

    return (
      <RadioGroupWrapper type={type}>
        {options.map((item, index) => {
          if (type === 'square' || type === 'square-fluid') {
            return (
              <RadioButtonSquare
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name={name}
                value={item.value}
                label={item.label}
                checked={item.value === value}
                onChange={this.selectOption}
                width={type === 'square-fluid' ? width : null}
              />
            )
          }

          return (
            <RadioButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              name={name}
              value={item.value}
              label={item.label}
              checked={item.value === value}
              onChange={this.selectOption}
            />
          )
        })}
      </RadioGroupWrapper>
    )
  }
}

export default RadioGroup
