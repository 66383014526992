import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import Label, { LabelText, ExplanationText } from './label'
import ErrorMessage from './errorMessage'

const getColor = (props) =>
  props.active
    ? props.theme.forms.colors.color
    : props.theme.forms.colors.active

const getBackground = (props) => {
  if (props.valid === true) return props.theme.forms.colors.validBackground
  if (props.valid === false) return props.theme.forms.colors.invalidBackground
  return props.theme.forms.colors.background
}

const getBorder = (props) => {
  if (props.valid === false) return props.theme.forms.colors.invalidBorder
  if (props.active) return props.theme.forms.colors.activeBorder
  return props.theme.forms.colors.border
}

const getOpacity = (props) => (props.disabled ? 0.6 : 1)

const StyledTextarea = styled.textarea`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 120px;
  min-height: ${prop('theme.forms.height')}};
  border-radius: ${prop('theme.forms.borderRadius')}};
  outline: none;
  padding: 12px;
  font-family: ${prop('theme.forms.fontFamily')};
  font-size: ${prop('theme.forms.fontSize')};
  font-weight: : ${prop('theme.forms.fontWeight')};
  line-height: 1.5;
  resize: ${prop('resize', 'none')};

  color: ${getColor};
  border: 1px solid ${getBorder};
  background-color: ${getBackground};
  opacity: ${getOpacity};
  z-index: 1;

  &:focus {
    outline: none;
    border: 1px solid ${prop('theme.forms.colors.activeBorder')};
    color: ${prop('theme.forms.colors.active')};
  }

  ${(props) => props.theme.media.min.sm`
    font-size: ${props.theme.forms.fontSizeDesktop};
  `};
`

const InputWrapper = styled.div`
  margin-bottom: ${prop('theme.forms.marginBottom')};
`

const Textarea = ({ label, valid, error, explanation, ...rest }) => {
  const textarea = <StyledTextarea valid={valid} {...rest} />
  const errorMsg =
    valid === false && error ? <ErrorMessage>{error}</ErrorMessage> : null

  return label ? (
    <InputWrapper>
      <Label>
        <LabelText>{label}</LabelText>
        {textarea}
        {errorMsg}
        <ExplanationText>{explanation}</ExplanationText>
      </Label>
    </InputWrapper>
  ) : (
    <InputWrapper>
      {textarea}
      {errorMsg}
    </InputWrapper>
  )
}

Textarea.defaultProps = {
  label: null,
  explanation: null,
  valid: null,
  error: null,
  rows: 4,
}

export default Textarea
