import { css } from 'styled-components'

const fontUrl =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1510650331/Magnet/fonts/'

const magnetFonts = css`
  @font-face {
    font-family: 'NewSpirit';
    src: url(${fontUrl}NewSpirit-Light.woff2) format('woff2'),
      url(${fontUrl}NewSpirit-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'NewSpirit';
    src: url(${fontUrl}NewSpirit-Regular.woff2) format('woff2'),
      url(${fontUrl}NewSpirit-Regular.woff) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'NewSpirit';
    src: url(${fontUrl}NewSpirit-Medium.woff2) format('woff2'),
      url(${fontUrl}NewSpirit-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'NewSpirit';
    src: url(${fontUrl}NewSpirit-SemiBold.woff2) format('woff2'),
      url(${fontUrl}NewSpirit-SemiBold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Campton';
    src: url(${fontUrl}Campton-Light.woff2) format('woff2'),
      url(${fontUrl}Campton-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Campton';
    src: url(${fontUrl}Campton-Book.woff) format('woff'),
      url(${fontUrl}Campton-Book.woff2) format('woff2'),
      url(${fontUrl}Campton-Book.ttf) format('ttf'),
      url(${fontUrl}Campton-Book.eot) format('eot');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Campton';
    src: url(${fontUrl}Campton-Medium.woff2) format('woff2'),
      url(${fontUrl}Campton-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Campton';
    src: url(${fontUrl}Campton-Bold.woff2) format('woff2'),
      url(${fontUrl}Campton-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Campton-Book';
    src: url(${fontUrl}Campton-Book.woff) format('woff'),
      url(${fontUrl}Campton-Book.woff2) format('woff2'),
      url(${fontUrl}Campton-Book.ttf) format('ttf'),
      url(${fontUrl}Campton-Book.eot) format('eot');
    font-weight: 300;
    font-style: normal;
  }
`

export default magnetFonts
