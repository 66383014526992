import PropTypes from 'prop-types'
import { valuesType } from './values'

const storeType = PropTypes.shape({
  id: PropTypes.string,
})

const bookingType = PropTypes.shape({
  loading: PropTypes.bool,
  error: PropTypes.shape(),
})

export const badaPropTypes = {
  badaProps: PropTypes.shape({
    store: storeType,
    stores: PropTypes.arrayOf(storeType),
    bookings: PropTypes.arrayOf(PropTypes.shape()),
    pageContent: PropTypes.shape(),
    overrideMeetingType: PropTypes.shape(),
  }),
  wizard: PropTypes.shape({
    one: PropTypes.bool,
    two: PropTypes.bool,
    three: PropTypes.bool,
    four: PropTypes.bool,
    five: PropTypes.bool,
    six: PropTypes.bool,
  }),
  selectedAddress: PropTypes.shape({
    city: PropTypes.string,
  }),
  selectedStore: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedService: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedSlot: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  updateUiState: PropTypes.func,
  storesByAddressData: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape(),
  }),
  sbmBookingsByStoreId: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape(),
  }),
  msBookingsByStoreId: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.shape(),
  }),
  bookingCompleted: PropTypes.bool,
  booking: bookingType,
  values: valuesType,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape(),
  customerAddress: PropTypes.shape({
    address: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
}

export const badaDefaultProps = {
  storeId: null,
  selectedAddress: null,
  selectedStore: null,
  selectedService: null,
  selectedSlot: null,
  bookingCompleted: false,
  prepCompleted: false,
  sbmBookingsByStoreId: null,
  msBookingsByStoreId: null,
  booking: null,
  selectedCity: '',
  values: {
    name: '',
    email: '',
    phone: '',
    address: '',
    comments: '',
    newsletterConsent: false,
    usePrepform: false,
  },
  location: {},
  auth: null,
  customerAddress: null,
  overrideMeetingType: null,
}
