import React from 'react'

const Photo = (props) => (
  <svg viewBox="0 0 96 96" {...props}>
    <path
      d="M7 9v78a2 2 0 0 0 2 2h78a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2zM2 0h92a2 2 0 0 1 2 2v92a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm57.104 43.946l1.982-1.982a2 2 0 0 1 2.828 0L95.45 73.5l-4.95 4.95-28-28-44 44-4.95-4.95 45.554-45.554zM32.5 41.5c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm0-5.882a4.118 4.118 0 1 0 0-8.236 4.118 4.118 0 0 0 0 8.236z"
      fill="currentColor"
    />
  </svg>
)

export default Photo
