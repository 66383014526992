import { injectIntl } from 'react-intl'
import { compose, mapProps } from 'recompose'
import settings from '../settings'

const enhance = compose(
  injectIntl,
  mapProps(({ intl, children, ...rest }) => ({
    ...rest,
    children: intl.formatNumber(children, {
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currency: settings.currency,
    }),
  }))
)

export default enhance
