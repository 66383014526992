import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'

export const isCancelable = (status) =>
  [
    qq.status.DELETE_FAILED,
    qq.status.PAUSED,
    qq.status.QUEUED,
    qq.status.UPLOAD_RETRYING,
    qq.status.SUBMITTED,
    qq.status.UPLOADING,
    qq.status.UPLOAD_FAILED,
    qq.status.REJECTED,
  ].includes(status)

class CancelButton extends Component {
  constructor(props) {
    super(props)
    this.mounted = false

    const file = this.props.uploader.methods.getUploads({ id: props.id })

    this.state = {
      cancelable: file != null ? isCancelable(file.status) : false,
    }

    this.onStatusChange = this.onStatusChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.props.uploader.on('statusChange', this.onStatusChange)
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.uploader.off('statusChange', this.onStatusChange)
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (id === this.props.id && this.mounted) {
      const cancelable = isCancelable(newStatus)

      this.setState((state) => {
        if (cancelable !== state.cancelable) {
          return { cancelable }
        }

        return null
      })

      if (newStatus === qq.status.DELETED || newStatus === qq.status.CANCELED) {
        this.props.uploader.off('statusChange', this.onStatusChange)
      }
    }
  }

  onClick() {
    this.props.uploader.methods.cancel(this.props.id)
  }

  render() {
    const {
      id,
      children,
      className,
      onlyRenderIfCancelable,
      uploader,
      ...props
    } = this.props

    if (this.state.cancelable || !onlyRenderIfCancelable) {
      return (
        <button
          {...props}
          type="button"
          className={className}
          disabled={!this.state.cancelable}
          onClick={this.state.cancelable ? this.onClick : undefined}
        >
          {children}
        </button>
      )
    }

    return null
  }
}

export default CancelButton
