import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { navigateToStep } from '../utils'
import useTranslation from '../../../utils/translation'

const Heading = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('cancel.step1.heading.fontFamily')};
  text-align: center;
  margin: ${theme('cancel.step1.heading.margin')};
  max-width: 550px;
  font-size: ${theme('cancel.step1.heading.fontSize.mobile')};
  line-height: ${theme('cancel.step1.heading.lineHeight.mobile')};
  font-weight: ${theme('cancel.step1.heading.fontWeight')};
  padding: 0px 20px 0px 20px;
  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('cancel.step1.heading.fontSize.desktop')};
    line-height: ${theme('cancel.step1.heading.lineHeight.desktop')};
  `}
`

const SubHeading = styled.h2`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('cancel.step1.subHeading.fontFamily')};
  margin: 0px auto 5px auto;
  font-size: ${theme('cancel.step1.subHeading.fontSize')};
  font-weight: ${theme('cancel.step1.subHeading.fontWeight')};
  margin-top: 15px;
`

const Box = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0px auto;
  background-color: ${theme('cancel.step1.box.backgroundColor')};
  padding: 20px 35px 25px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  ul {
    padding-left: 17px;
    li {
      margin-bottom: 15px;
    }
  }
`

const Usps = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('cancel.step1.usps.fontSize')};
  ul {
    list-style-type: none;
    padding-left: 20px;
  }
  ul > li {
    position: relative;
    line-height: ${theme('cancel.step1.usps.li.lineHeight')};
  }
  ul > li:before {
    position: absolute;
    left: -18px;
    top: ${theme('cancel.step1.usps.li.before.top')};
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${theme('cancel.step1.usps.li.before.backgroundColor')};
  }
`

const Details = styled.div``

const ConfirmContainer = styled.div`
  text-align: center;
  margin: 40px auto;
  max-width: 550px;
`

const ConfirmText = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('cancel.step1.confirmText.fontSize')};
  font-weight: ${theme('cancel.step1.confirmText.fontWeight')}; ;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`

const Button = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: #ffffff;
  background-color: ${theme('cancel.step1.button.backgroundColor')};
  text-decoration: none;
  padding: ${(props) =>
    theme('cancel.step1.button.padding')(props) || '13px 30px 13px 30px'};
  font-size: ${theme('cancel.step1.button.fontSize')};
  border-radius: ${theme('cancel.step1.button.borderRadius')};
  width: 100%;
  text-transform: ${(props) =>
    theme('cancel.step1.button.textTransform')(props) || 'none'};
  max-width: 260px;
  font-weight: ${(props) =>
    theme('cancel.step1.button.fontWeight')(props) || 500};
  cursor: pointer;
  :first-of-type {
    margin-right: 10px;
  }
  :last-of-type {
    margin-left: 10px;
  }
  transition: opacity 0.5s;
  :hover {
    opacity: 0.9;
  }
`
const WhiteLink = styled(Button)`
  background-color: ${theme('cancel.step1.whiteLink.backgroundColor')};
  border: ${theme('cancel.step1.whiteLink.border')};
  color: ${theme('cancel.step1.whiteLink.color')};
`

const getFirstName = (fullName) => {
  if (fullName.indexOf(' ') > -1) {
    return fullName.split(' ')[0]
  }
  return fullName
}

const CancelStep1 = ({ customerName }) => {
  const t = useTranslation('cancel.step1')
  const history = useHistory()

  return (
    <>
      <Heading>
        {customerName && t('heading', { name: getFirstName(customerName) })}
      </Heading>
      <Box>
        <Details>
          <SubHeading>{t('subHeading')}</SubHeading>
          <Usps
            dangerouslySetInnerHTML={{
              __html: t('usps'),
            }}
          />
        </Details>
      </Box>
      <ConfirmContainer>
        <ConfirmText>{t('confirm')}</ConfirmText>
        <ButtonContainer>
          <Button onClick={() => navigateToStep(4, history)}>{t('no')}</Button>
          <WhiteLink onClick={() => navigateToStep(2, history)}>
            {t('yes')}
          </WhiteLink>
        </ButtonContainer>
      </ConfirmContainer>
    </>
  )
}
export default CancelStep1
