export const BRAND_MAGNET = 'magnet'
export const BRAND_MAGNETTRADE = 'magnettrade'
export const BRAND_HTHGO = 'hthgo'
export const BRAND_HTHDK = 'hthdk'
export const BRAND_HTHDE = 'hthde'
export const BRAND_HTHNO = 'hthno'
export const BRAND_HTHSE = 'hthse'
export const BRAND_HTHFI = 'hthfi'
export const BRAND_NOREMA = 'norema'
export const BRAND_NOVART = 'novart'
export const BRAND_MARBODAL = 'marbodal'
export const BRAND_UNOFORM = 'unoform'
export const BRAND_UNOFORMDK = 'unoform'
export const BRAND_UNOFORMNO = 'unoformno'
export const BRAND_UNOFORMSE = 'unoformse'
export const BRAND_UNOFORMBE = 'unoformbe'
export const BRAND_SIGDAL = 'sigdal'
export const BRAND_EWE = 'ewe'
export const BRAND_EWEEN = 'eween'
export const BRAND_INTUO = 'intuo'
export const BRAND_INVITA = 'invita'
