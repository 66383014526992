import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { compose, withProps, withStateHandlers } from 'recompose'
import { graphql } from '@apollo/client/react/hoc'
import features from '../../../components/features'
import Button from '../../../components/button/baseButton'
import Loader from '../../../components/loader/Loader'
import searchOpenAuthStoresByAddressQuery from '../../../../graphql/SearchOpenAuthStoresByAddressQuery.graphql'
import bookingServicesQuery from '../../../../graphql/BookingServicesQuery.graphql'
import bookingServiceProvidersQuery from '../../../../graphql/BookingServiceProvidersQuery.graphql'
import bookingTimesQuery from '../../../../graphql/BookingTimesQuery.graphql'
import updateBookingDetails from '../../../../graphql/UpdateBookingDetailsMutation.graphql'
import cancelAppointment from '../../../../graphql/CancelAppointmentMutation.graphql'
import TimeEditor from './TimeEditor'
import DateEditor from './DateEditor'
import ServiceEditor from './ServiceEditor'
import StoreEditor from './StoreEditor'
import ServiceProviderEditor from './ServiceProviderEditor'
import { WideSectionItem, SectionItemHeading } from '../sharedUI'
import { getOptions, lengthFromEndTime, endTimeFromLength } from '../utils'
import { update, isValid, cancel } from './actions'
import useTranslation from '../../../../utils/translation'
import { appBaseUrl } from '../../../../utils/appBaseUrl'

const LoaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const LoaderCover = styled.div`
  position: absolute;
  left: 0;
  width: 800px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 100000;
  position: relative;
  padding-top: 60px;
`

const ButtonContainer = styled.div`
  width: 100%;
`

const UpdateButton = styled(Button)`
  margin-left: auto;
  display: block;
  width: 399px;
  max-width: 100%;
  margin-top: 0px;
  height: 43px;
  ${(props) => props.theme.media.max.xs`
    width: 100%;
  `};
`

const CancelButton = styled.a`
  display: block;
  text-align: center;
  color: red;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 12px;
  text-decoration: underline;
  margin-top: 10px;
  cursor: pointer;
`

const Canceled = styled.div`
  font-weight: 600;
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  width: 100%;
  background-color: #f6f7f8;
  padding: 20px;
  margin-top: 20px;
`

const Error = styled.div`
  background-color: red;
  color: white;
  padding: 10px;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  border-radius: 2px;
`

const getAvailable = (serviceProviderOptions, serviceProviderId) => {
  const designer = serviceProviderOptions.find(
    (option) => `${option.value}` === `${serviceProviderId}`
  )
  return !!designer && designer.disabled !== true
}

const MeetingEditor = ({
  externalBookingSystem,
  simplyBookMeSubsystem,
  externalAppointmentId,
  onUpdate,
  updateBookingDetailsMutation,
  canceled,
  calendarVisible,
  toggleCalendarVisible,
  selectedDate,
  storeOptions,
  timeOptions,
  lengthOptions,
  services,
  serviceOptions,
  serviceProviderOptions,
  closed,
  stores,
  storeId,
  storesData,
  serviceProviderId,
  serviceProviderName,
  appointmentId,
  formData,
  setFormData,
  cancelAppointmentMutation,
  onCancel,
  meetingHasPassed,
}) => {
  const t = useTranslation('appointment-details')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isAvailable, setIsAvailable] = useState(false)
  const [selectedDesignerId, setSelectedDesignerId] =
    useState(serviceProviderId)

  useEffect(() => {
    if (storeOptions.length > 0 && serviceProviderOptions.length > 0) {
      setLoading(false)
    }
  }, [storeOptions, serviceProviderOptions])

  useEffect(() => {
    const designerIsAvailable = getAvailable(
      serviceProviderOptions,
      selectedDesignerId
    )
    setIsAvailable(designerIsAvailable)
  }, [serviceProviderOptions, selectedDesignerId])

  if (loading) {
    return (
      <LoaderContainer>
        <LoaderCover>
          <Loader duration={3} />
        </LoaderCover>
      </LoaderContainer>
    )
  }

  return (
    <>
      <DateEditor
        available={isAvailable}
        canceled={canceled}
        selectedDate={selectedDate}
        newSelectedDate={formData.newSelectedDate}
        calendarVisible={calendarVisible}
        toggleCalendarVisible={toggleCalendarVisible}
        onChange={(date) =>
          setFormData({
            newSelectedDate: moment(date).format('YYYY-MM-DD'),
          })
        }
      />
      <StoreEditor
        canceled
        stores={stores}
        storeId={parseInt(storeId, 10)}
        serviceProviderId={parseInt(serviceProviderId, 10)}
        storeOptions={storeOptions}
        serviceProviderOptions={serviceProviderOptions}
        onChange={(e) =>
          setFormData({
            newStoreId: parseInt(e.target.value, 10),
          })
        }
      />
      <ServiceProviderEditor
        canceled={canceled}
        serviceProviderId={`${selectedDesignerId}`}
        serviceProviderName={serviceProviderName}
        serviceProviderOptions={serviceProviderOptions}
        onChange={(e) => {
          setSelectedDesignerId(e.target.value)
          setFormData({
            newServiceProviderId: parseInt(e.target.value, 10),
          })
        }}
      />
      <ServiceEditor
        available={isAvailable}
        canceled={canceled}
        serviceId={formData.newServiceId && formData.newServiceId.toString()}
        serviceName={
          services.find((service) => service.id === formData.newServiceId)
            ?.heading || 'Unknown meeting type'
        }
        serviceOptions={serviceOptions.filter((option) =>
          services.find((service) => service.id === option.value)
        )}
        onChange={(e) =>
          setFormData({
            newServiceId: parseInt(e.target.value, 10),
          })
        }
      />
      <TimeEditor
        canceled={canceled}
        selectedTime={formData.newSelectedTime}
        selectedLength={lengthFromEndTime(
          formData.newSelectedTime,
          formData.newSelectedEndTime
        )}
        timeOptions={timeOptions}
        lengthOptions={lengthOptions}
        closed={closed}
        loading={loading}
        available={isAvailable}
        onChangeTime={(e) => {
          const newEndTime = endTimeFromLength(
            e.target.value,
            formData.newSelectedLength
          )
          setFormData({
            newSelectedTime: e.target.value,
            newSelectedEndTime: newEndTime,
          })
        }}
        onChangeLength={(e) =>
          setFormData({
            newSelectedEndTime: endTimeFromLength(
              formData.newSelectedTime,
              parseInt(e.target.value, 10)
            ),
            newSelectedLength: parseInt(e.target.value, 10),
          })
        }
      />
      {!canceled && (
        <WideSectionItem>
          <SectionItemHeading>&nbsp;</SectionItemHeading>
          <ButtonContainer>
            <UpdateButton
              onClick={() =>
                update(
                  simplyBookMeSubsystem,
                  appointmentId,
                  externalAppointmentId,
                  formData,
                  storesData,
                  updateBookingDetailsMutation,
                  setLoading,
                  setError,
                  onUpdate,
                  timeOptions,
                  lengthOptions
                )
              }
              size="small"
              disabled={
                !isValid(formData, timeOptions, lengthOptions).valid || closed
              }
            >
              {t('update-appointment')}
            </UpdateButton>
            {features.useAmendCancel && !meetingHasPassed ? (
              <CancelButton
                href={`${appBaseUrl}cancel/${appointmentId}/?step=2&store=true`}
              >
                {t('cancel-appointment')}
              </CancelButton>
            ) : (
              <CancelButton
                onClick={() =>
                  cancel(
                    externalBookingSystem,
                    `${storeId}`,
                    appointmentId,
                    simplyBookMeSubsystem,
                    externalAppointmentId,
                    cancelAppointmentMutation,
                    setLoading,
                    onCancel
                  )
                }
              >
                {t('cancel-appointment')}
              </CancelButton>
            )}
          </ButtonContainer>
        </WideSectionItem>
      )}
      {canceled && <Canceled>{t('appointment-canceled')}</Canceled>}
      {error && <Error>{error}</Error>}
    </>
  )
}

const enhance = compose(
  graphql(updateBookingDetails, {
    name: 'updateBookingDetailsMutation',
  }),
  graphql(cancelAppointment, { name: 'cancelAppointmentMutation' }),
  withStateHandlers(
    (props) => ({
      calendarVisible: false,
      formData: {
        newSelectedDate: props.selectedDate,
        newSelectedTime: props.selectedTime,
        newSelectedEndTime: props.selectedEndTime,
        newSelectedLength: lengthFromEndTime(
          props.selectedTime,
          props.selectedEndTime
        ),
        newServiceProviderId: props.serviceProviderId,
        newStoreId: props.storeId,
        newServiceId: props.serviceId,
      },
      options: {},
    }),
    {
      toggleCalendarVisible:
        ({ calendarVisible }) =>
        () => ({
          calendarVisible: !calendarVisible,
        }),
      setFormData:
        ({ formData }) =>
        (propertyObject) => ({
          formData: { ...formData, ...propertyObject },
        }),
      setOptions:
        ({ options }) =>
        (propertyObject) => ({
          formData: { ...options, ...propertyObject },
        }),
    }
  ),
  graphql(searchOpenAuthStoresByAddressQuery, {
    name: 'storesData',
    options: () => ({
      variables: {
        input: {
          includeFakes: true,
          city: 'Stockholm',
          countryName: 'Sweden',
          postalCode: '118 58',
        },
      },
      fetchPolicy: 'no-cache',
    }),
  }),
  graphql(bookingTimesQuery, {
    name: 'timesData',
    options: ({ formData, externalAppointmentId, simplyBookMeSubsystem }) => ({
      variables: {
        subsystem: simplyBookMeSubsystem,
        serviceProviderId: formData.newServiceProviderId,
        serviceId: formData.newServiceId,
        day: formData.newSelectedDate,
        bookingId: parseInt(externalAppointmentId, 10),
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ formData }) => !formData.newServiceId,
  }),
  graphql(bookingServiceProvidersQuery, {
    name: 'serviceProvidersData',
    options: ({ simplyBookMeSubsystem, externalAppointmentId }) => ({
      variables: {
        bookingId: parseInt(externalAppointmentId, 10),
        subsystem: simplyBookMeSubsystem,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ externalAppointmentId }) => !externalAppointmentId,
  }),
  graphql(bookingServicesQuery, {
    name: 'servicesData',
    options: ({ formData, simplyBookMeSubsystem }) => ({
      variables: {
        serviceProviderId: formData.newServiceProviderId,
        subsystem: simplyBookMeSubsystem,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ formData }) => !formData.newServiceProviderId,
  }),
  withProps(
    ({
      storesData,
      servicesData,
      serviceProvidersData,
      timesData,
      formData,
      services,
    }) =>
      getOptions({
        storesData,
        servicesData,
        services,
        serviceProvidersData,
        timesData,
        selectedTime: formData.newSelectedTime,
      })
  )
)

MeetingEditor.propTypes = {
  externalBookingSystem: PropTypes.string,
  simplyBookMeSubsystem: PropTypes.string,
  appointmentId: PropTypes.string,
  updateBookingDetailsMutation: PropTypes.func,
  onUpdate: PropTypes.func,
  serviceOptions: PropTypes.arrayOf(PropTypes.shape()),
  serviceProviderOptions: PropTypes.arrayOf(PropTypes.shape()),
  storeOptions: PropTypes.arrayOf(PropTypes.shape()),
  timeOptions: PropTypes.arrayOf(PropTypes.shape()),
  lengthOptions: PropTypes.arrayOf(PropTypes.shape()),
  calendarVisible: PropTypes.bool,
  toggleCalendarVisible: PropTypes.func,
  selectedDate: PropTypes.string,
  serviceProviderId: PropTypes.number,
  externalAppointmentId: PropTypes.string,
  canceled: PropTypes.bool,
  closed: PropTypes.bool,
  stores: PropTypes.shape(),
  storeId: PropTypes.number,
  storesData: PropTypes.shape(),
  formData: PropTypes.shape(),
  setFormData: PropTypes.func,
  cancelAppointmentMutation: PropTypes.func,
  onCancel: PropTypes.func,
  services: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  meetingHasPassed: PropTypes.bool.isRequired,
}

MeetingEditor.defaultProps = {
  externalBookingSystem: null,
  simplyBookMeSubsystem: null,
  appointmentId: null,
  updateBookingDetailsMutation: null,
  cancelAppointmentMutation: null,
  onCancel: null,
  onUpdate: null,
  serviceOptions: [],
  serviceProviderOptions: [],
  storeOptions: [],
  timeOptions: [],
  lengthOptions: [],
  calendarVisible: false,
  toggleCalendarVisible: null,
  selectedDate: null,
  serviceProviderId: null,
  externalAppointmentId: null,
  canceled: null,
  closed: null,
  stores: null,
  storeId: null,
  storesData: null,
  formData: null,
  setFormData: null,
}

export default enhance(MeetingEditor)
