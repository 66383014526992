import * as React from 'react'
import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'
import styled from 'styled-components'
import { prop, theme } from 'styled-tools'
import LoadingIndicator from '../../loadingIndicator'
import features from '../../features'
import { LightboxOverlay, OverlayCloseButton } from '../../overlay'
import settings from '../../settings'
import { Container, Row, Column } from '../../grid'
import Button from '../../button/baseButton'
import Headline from '../../typography/headline'
import Paragraph from '../../typography/paragraph'
import InputField from '../../customer-form/InputField'
import SearchAddressQuery from '../../../../graphql/SearchAddressQuery.graphql'
import RetrieveAddressQuery from '../../../../graphql/RetrieveAddressQuery.graphql'

const InputGroup = styled(Column)`
  button {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  input {
    font-size: 16px;
  }
  ${(props) => props.theme.media.min.sm`
    display: flex;
    align-items: flex-start;

    > span {
      margin-right: 10px;
      margin-bottom: 0;
      flex: 1 1 60%;
    }
    button {
      flex: 1 1 40%;
      margin:0;
      font-size: ${prop('theme.button.fontSize', '12px')};
    }
    `}
`

const OverlayContent = styled(Container)`
  position: relative;
  padding: 25px 40px 40px;
  background-color: ${theme('postalCodeOverlay.backgroundColor')};
  max-width: 600px;
  margin: 0;
  min-height: 250px;
  border-radius: ${theme('postalCodeOverlay.borderRadius')};
`

const StyledColumn = styled(Column)`
  padding-top: ${theme('postalCodeOverlay.text.paddingTop')};
  padding-bottom: ${theme('postalCodeOverlay.text.paddingBottom')};
`

const StyledButton = styled(Button)``

const StyledInputField = styled(InputField)`
  height: ${theme('postalCodeOverlay.inputField.height')};
  border-radius: ${theme('postalCodeOverlay.inputField.borderRadius')};
  padding: ${theme('postalCodeOverlay.inputField.padding')};

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

const StyledCloseButton = styled(OverlayCloseButton)`
  position: absolute;
  top: 20px;
  color: #333;
  right: 20px;
  margin-bottom: 0;
  margin-right: 0;
`

const StyledParagraph = styled(Paragraph)`
  font-weight: ${theme('postalCodeOverlay.text.fontWeight')};
  margin-bottom: 0;
`

const StyledHeadline = styled(Headline)`
  margin: 0;
`

class PostalCode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      searchFailed: false,
      loading: false,
      valid: undefined,
    }
    this.postcodeInputRef = React.createRef()
    this.onCancel = this.onCancel.bind(this)
    this.findCity = this.findCity.bind(this)
  }

  findCity() {
    if (features.requirePostCode) {
      this.setState({ valid: undefined, loading: false })
      this.props.onChange({
        line1: this.state.input,
        postalCode: this.state.input,
        countryName: this.props.brandCountry,
        city: '',
      })

      return
    }

    this.setState({ loading: true })

    this.props.client
      .query({
        query: SearchAddressQuery,
        variables: {
          input: {
            searchText: '',
            refinementId:
              settings.addressPrefix + this.state.input.replace(/[^0-9]/g, ''),
            limit: 1,
          },
        },
      })
      .then((result) => {
        if (result.data.searchAddress.addresses.length < 1) {
          this.setState({ valid: false, loading: false })
          return
        }
        this.props.client
          .query({
            query: RetrieveAddressQuery,
            variables: { id: result.data.searchAddress.addresses[0].id },
          })
          .then((address) => {
            this.setState({ valid: undefined, loading: false })
            this.props.onChange(address.data.retrieveAddress)
          })
      })
  }

  componentDidMount() {
    this.postcodeInputRef.current.focus()
  }

  onCancel() {
    this.setState({
      input: '',
      valid: undefined,
    })
    this.props.setClosed()
  }

  render() {
    return (
      <LightboxOverlay setClosed={this.onCancel}>
        <OverlayContent>
          <Row justifyContent="center">
            <Column>
              <StyledHeadline>{this.props.headline}</StyledHeadline>
            </Column>
            <StyledColumn>
              <StyledParagraph size="large">{this.props.label}</StyledParagraph>
            </StyledColumn>
            {this.state.loading ? (
              <StyledColumn>
                <LoadingIndicator />
              </StyledColumn>
            ) : (
              <InputGroup>
                <StyledInputField
                  ref={this.inputRef}
                  inputRef={this.postcodeInputRef}
                  placeholder={this.props.placeholder}
                  autocomplete="postal-code"
                  id="postalCode"
                  value={this.state.input}
                  valid={this.state.valid}
                  error={this.props.error}
                  disabled={this.state.loading}
                  type={features.requirePostCode ? 'string' : 'number'}
                  onChange={(e) =>
                    this.setState({ input: e.target.value, valid: undefined })
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) this.findCity()
                  }}
                />
                <StyledButton
                  onClick={this.findCity}
                  disabled={this.state.loading}
                >
                  {this.props.buttonText}
                </StyledButton>
              </InputGroup>
            )}
          </Row>
          <StyledCloseButton
            inverted
            onClick={this.onCancel}
            title={this.props.cancelText}
          />
        </OverlayContent>
      </LightboxOverlay>
    )
  }
}

PostalCode.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  setClosed: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  placeholder: PropTypes.string,
  headline: PropTypes.string,
}

PostalCode.defaultProps = {
  label: null,
  error: null,
  buttonText: null,
  cancelText: null,
  headline: null,
  placeholder: null,
  setClosed: () => {},
}

const PostalCodeWithApollo = withApollo(PostalCode)

export default PostalCodeWithApollo
