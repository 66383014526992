import { Component } from 'react'
import PropTypes from 'prop-types'
import PortalRegistry from './PortalRegistry'

/* eslint-disable react/sort-comp */
class Portal extends Component {
  constructor(props) {
    super(props)
    this.portalRegistry = props.context.portalRegistry
    this.id = null
  }

  componentWillMount() {
    this.id = this.portalRegistry.register(this.props.into, this.props.children)

    this.renderIntoPortalNode(this.props)
  }

  componentWillReceiveProps(props) {
    this.portalRegistry.clearChild(this.props.into, this.id)
    this.renderIntoPortalNode(props)
  }

  componentWillUnmount() {
    this.portalRegistry.unregister(this.props.into, this.id)
  }

  renderIntoPortalNode(props) {
    this.portalRegistry.addChild(this.props.into, this.id, props.children)
  }

  render() {
    return null
  }
}

Portal.contextTypes = {
  portalRegistry: PropTypes.instanceOf(PortalRegistry).isRequired,
}

export default Portal
