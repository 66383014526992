import React from 'react'
import { compose, lifecycle } from 'recompose'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import { Column, Row } from '../grid'
import Button from '../button/baseButton'
import {
  getDisplayData,
  getMeetingTypeContent,
  getMeetingTypeName,
} from '../../containers/bada/content'
import {
  CUSTOMER_FORM,
  SIMPLY_BOOK_ME,
} from '../../containers/bada/reducer/Wizard'
import ConfirmationHeader from './ConfirmationHeader'
import ConfirmationInformation from './ConfirmationInformation'
import useTranslation from '../../../utils/translation'
import appInsights, { SeverityLevel } from '../logging/appInsights'
import settings from '../settings'

const StyledButton = styled(Button)`
  width: 100%;
  border: ${theme('button.border')};
  height: ${theme('button.height')};
  font-size: ${theme('confirmation.button.fontSize')};
  margin-top: 30px;
  background-color: ${theme('confirmation.button.backgroundColor')};

  ${(props) => props.theme.media.min.sm`
    width: ${theme('button.wideButtonWidth')};
  `}

  &:not([disabled]):hover {
    ${css(theme('buttonHover'))}
    &:before {
      ${css(theme('buttonHoverBefore'))}
    }
  }
`

const Confirmation = ({
  selectedStore,
  selectedSlot,
  booking,
  pageContent,
  customerValues,
  staff,
  returningVisitor,
  appointmentId,
  appointment,
}) => {
  const loading = !pageContent || !appointment || appointment.loading
  if (loading) {
    return null // TODO: loader
  }

  const sbmBooking = appointment.appointment

  const t = useTranslation('app.confirmation')

  const renderError = !booking || booking.error || !sbmBooking
  if (renderError) {
    appInsights.trackException({
      exception: new Error(
        `Error in Confirmation for brand ${settings.brand}, selectedStore ${selectedStore}, selectedSlot ${selectedSlot}`
      ),
      severityLevel: SeverityLevel.Error,
    })
    return (
      <Row>
        <Column sm={12}>
          <StyledButton
            onClick={() => {
              window.location =
                t('error-button-href') || t('to-startpage-href') || '/'
            }}
          >
            {t('error-button') || t('to-startpage')}
          </StyledButton>
        </Column>
      </Row>
    )
  }

  // TODO: fully handle 'overrideMeetingType', i.e. if appointment.appointment.serviceId === null then take meeting type name from overrideMeetingType
  const isSlotBooking = selectedSlot && selectedSlot.from && selectedSlot.to
  const defaultBookingSystem = isSlotBooking ? SIMPLY_BOOK_ME : CUSTOMER_FORM
  const bookingSystem = sbmBooking.externalBookingSystem || defaultBookingSystem

  const content = getDisplayData(pageContent)
  const currentMeetingTypeName = getMeetingTypeName(sbmBooking)

  const serviceContent = getMeetingTypeContent({
    meetingTypes: content.services,
    currentMeetingTypeName,
    bookingSystem,
    storeSubSystem: sbmBooking.simplyBookMeSubsystem,
    serviceId: sbmBooking.serviceId,
  })

  const confirmButtonLink = returningVisitor
    ? serviceContent?.confirmationButtonLinkReturning
    : serviceContent?.confirmationButtonLink

  const confirmButtonText = returningVisitor
    ? serviceContent?.confirmationButtonTextReturning
    : serviceContent?.confirmationButtonText

  const renderConfirmBtn = confirmButtonLink && confirmButtonText

  return (
    <>
      <Row data-track="block-summary">
        <Column sm={12}>
          <ConfirmationHeader
            selectedStore={selectedStore}
            selectedSlot={selectedSlot}
            booking={booking}
            pageContent={content}
            serviceContent={serviceContent}
          />
        </Column>
      </Row>
      <Row data-track="designer" sm={12}>
        <ConfirmationInformation
          pageContent={content}
          serviceContent={serviceContent}
          selectedStore={selectedStore}
          selectedSlot={selectedSlot}
          customerValues={customerValues}
          staff={staff}
          returningVisitor={returningVisitor}
          appointmentId={appointmentId}
          appointment={appointment}
        />
      </Row>
      {renderConfirmBtn && (
        <Row data-track="btn-view">
          <Column sm={12}>
            <StyledButton
              onClick={() => {
                window.location = confirmButtonLink
              }}
            >
              {confirmButtonText}
            </StyledButton>
          </Column>
        </Row>
      )}
    </>
  )
}

const enhance = compose(
  lifecycle({
    componentDidMount() {
      if (this.props.onMount) {
        this.props.onMount()
      }
    },
  })
)

export default enhance(Confirmation)
