import React from 'react'
import ImageOption from './ImageOption'
import TextOption from './TextOption'

const TypeMapper = new Map()

const Blocks = {
  ImageOption,
  TextOption,
}

TypeMapper.set('Block', Blocks)

const Option = ({ type, ...props }) => {
  const Elem = TypeMapper.get(type[0])[type[1].replace('Block', '')]
  return props.data && <Elem {...props.data} {...props} />
}

export default Option
