import { css } from 'styled-components'

const fontUrl =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1660218691/HTH/fonts/'

const hthFonts = css`
  @font-face {
    font-family: 'Clan';
    src: url(${fontUrl}ClanforHTHW01-News.woff2) format('woff2'),
      url(${fontUrl}ClanforHTHW01-News.woff) format('woff');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Clan';
    src: url(${fontUrl}ClanforHTHW01-Book.woff2) format('woff2'),
      url(${fontUrl}ClanforHTHW01-Book.woff) format('woff');
    font-weight: 300;
  }
  @font-face {
    font-family: 'Clan';
    src: url(${fontUrl}ClanforHTHW01-Bold.woff2) format('woff2'),
      url(${fontUrl}ClanforHTHW01-Bold.woff) format('woff');
    font-weight: 700;
  }
`

export default hthFonts
