import React from 'react'

const PenIcon = ({ width, height }) => (
  <svg viewBox="-3 -1 24.5 26.89" width={width} height={height}>
    <g>
      <g>
        <path
          d="M16.39,1l5.33,4.64-15.38,18-5,.35L1,19Z"
          stroke="#001c33"
          strokeWidth={1.7}
          strokeLinejoin="round"
          fill="#fff"
        />
        <line
          x1="3.18"
          y1="16.41"
          x2="8.53"
          y2="21.03"
          stroke="#001c33"
          strokeWidth={1.7}
        />
      </g>
    </g>
  </svg>
)

PenIcon.defaultProps = {
  width: 20,
  height: 20,
}

export default PenIcon
