import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import BubbleLoader from './BubbleLoader'
import BouncyLoader from './BouncyLoader'

const LoadingWrapper = styled.div`
  background: ${prop('background')};
`

const LoadingIndicator = ({
  background,
  duration,
  color,
  size,
  small,
  large,
  center,
  type,
  ...props
}) => {
  let useType = 'bubble'
  if (type) {
    useType = 'bouncy'
  }

  return (
    <LoadingWrapper background={background} {...props}>
      {useType === 'bubble' && (
        <BubbleLoader
          duration={duration}
          color={color}
          center={center}
          size={size || (small && 'small') || (large && 'large') || null}
        />
      )}
      {useType === 'bouncy' && (
        <BouncyLoader
          duration={duration}
          color={color}
          center={center}
          size={size || (small && 'small') || (large && 'large') || null}
        />
      )}
    </LoadingWrapper>
  )
}

LoadingIndicator.propTypes = {
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  duration: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
  small: PropTypes.bool,
  large: PropTypes.bool,
  center: PropTypes.string,
  type: PropTypes.string,
}

LoadingIndicator.defaultProps = {
  ...BubbleLoader.defaultProps,
  background: 'none',
  small: null,
  large: null,
  center: null,
  type: null,
}

export default LoadingIndicator
