import React, { Component } from 'react'

/* eslint-disable no-console */
class Filename extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filename: this.props.uploader.methods.getName(this.props.id),
    }
  }

  componentDidMount() {
    console.warn(
      `[Filename] This component is deprecated. ` +
        `Use \`uploader.methods.getName(id)\` directly instead.`
    )
  }

  render() {
    const { id, uploader, ...props } = this.props

    return <span {...props}>{this.state.filename}</span>
  }
}

export default Filename
