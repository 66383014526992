import PropTypes from 'prop-types'

export const addressType = PropTypes.shape({
  postalCode: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string,
  countryName: PropTypes.string,
})

export const addressDefaultProps = {
  postalCode: null,
  addressLine1: null,
  addressLine2: null,
  addressLine3: null,
  city: null,
  countryName: null,
}
