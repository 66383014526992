import { compose, withState } from 'recompose'
import styled from 'styled-components'
import { theme, ifNotProp } from 'styled-tools'
import React from 'react'
import features from '../features'
import { Column, Container, Row } from '../grid'
import Alert from './Alert'
import Images from './Images'
import Titles from './Titles'
import USP from './USP'
import useTranslation from '../../../utils/translation'

const MainContainer = styled(Container)`
  width: 100%;
  max-width: ${theme('grid.containerMaxWidth')};
  display: flex;
  min-height: ${ifNotProp(
    '$compact',
    theme('contentHeader.mobile.height'),
    'auto'
  )};
  text-align: ${theme('contentHeader.mobile.textAlign')};
  align-items: ${theme('contentHeader.mobile.alignItems')};
  padding-bottom: ${theme('contentHeader.heading.mobile.paddingBottom')};
  justify-content: ${theme('contentHeader.justifyContent')};

  ${(p) => p.theme.media.min.sm`
    min-height: ${ifNotProp(
      '$compact',
      theme('contentHeader.desktop.height'),
      'auto'
    )};
    align-items: ${theme('contentHeader.desktop.alignItems')};
    text-align: ${theme('contentHeader.desktop.textAlign')};
  `}
`

const Content = ({
  pageHeading,
  preambleDesktop,
  preambleMobile,
  uspsDesktop,
  uspsMobile,
  imagesDesktop,
  imagesMobile,
  compact,
}) => {
  const t = useTranslation('app.header')
  const alertHeading = (features.showAlertHeader && t('alert-heading')) || ''
  const alertPreamble = (features.showAlertHeader && t('alert-preamble')) || ''
  return (
    <MainContainer $compact={compact}>
      {features.showAlertHeader && (
        <Alert alertHeading={alertHeading} alertPreamble={alertPreamble} />
      )}
      <Row>
        <Column>
          <Titles
            pageHeading={pageHeading}
            preambleDesktop={preambleDesktop}
            preambleMobile={preambleMobile}
          />
          <USP uspsDesktop={uspsDesktop} uspsMobile={uspsMobile} />
          <Images imagesDesktop={imagesDesktop} imagesMobile={imagesMobile} />
        </Column>
      </Row>
    </MainContainer>
  )
}

const enhance = compose(withState('detailsActive', 'toggleDetails', false))

export default enhance(Content)
