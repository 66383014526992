import React from 'react'

const Hth = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={75}
    height={59}
    viewBox="-100 0 720 550"
    version="1.1"
  >
    <g
      transform="translate(0.000000,462.000000) scale(0.100000,-0.100000)"
      fill="#f60000"
    >
      <path
        d="M1231,4403c-56-20-122-86-139-138c-7-22-201-944-431-2050L244,205l719-3c396-1,722-1,724,1s75,352,164,778
      c88,426,162,784,166,796l5,23h-187h-187l6,33c4,17,24,114,45,215l38,182h-237h-237l-33-162c-53-265-49-262-272-266
      c-148-4-158-3-158,15c1,10,49,251,109,537c106,505,109,519,140,550l31,31l165,3c91,2,165,1,165-2s-18-91-40-196s-40-195-40-201
      c0-5,94-9,234-9h234l15,68c9,37,23,105,32,152c36,181,48,190,263,190c83,0,152,2,152,5c0,2,67,330,150,728s150,729,150,735
      c0,9-151,12-642,11C1362,4419,1269,4416,1231,4403z"
      />
      <path
        d="M3740,4417c0-2-67-329-150-726c-82-398-150-730-150-737c0-11,34-14,184-14h183l-42-205l-43-205h237h236l34,163
      c18,89,40,174,49,189c29,49,64,58,232,58h152l-5-22c-3-13-53-252-112-532c-58-279-112-517-119-528c-34-48-53-53-218-56
      c-150-4-158-3-158,15c0,11,18,105,40,209s40,192,40,197c0,4-105,7-234,7c-215,0-235-1-240-17c-2-10-19-88-36-173
      c-48-233-48-233-269-240l-155-5l-163-780c-89-429-162-788-162-797c-1-17,36-18,637-18c568,0,644,2,687,17c57,19,125,82,142,133
      c7,19,194,913,417,1985c223,1073,412,1980,419,2018l15,67h-724C4066,4420,3740,4419,3740,4417z"
      />
      <path
        d="M2255,2918c-38-164-42-216-20-257c25-50,66-61,224-61c78,0,141-2,141-4s-36-179-80-392c-44-214-80-390-80-391
      c0-2,72-3,159-3c156,0,160,1,190,25c16,14,33,36,37,48s39,177,79,367l71,345l164,5c172,5,197,11,225,53c12,19,65,245,65,278
      c0,5-263,9-585,9h-585L2255,2918z"
      />
    </g>
  </svg>
)

export default Hth
