import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import createGridComponent from './createGridComponent'
import Column from './Column'

const filteredProps = [
  'noGutters',
  'wrap',
  'alignItems',
  'alignContent',
  'justifyContent',
]

const GridComponent = createGridComponent('Row', filteredProps)

const Row = styled(GridComponent)`
  display: flex;
  flex-wrap: ${prop('wrap', 'wrap')};
  margin-left: -${prop('theme.grid.gutter')};
  margin-right: -${prop('theme.grid.gutter')};
  align-items: ${prop('alignItems', 'stretch')};
  align-content: ${prop('alignContent', 'stretch')};
  justify-content: ${prop('justifyContent', 'flex-start')};

  ${ifProp(
    'debug',
    css`
      & + & {
        margin-top: ${prop('theme.grid.gutter')};
      }

      & > ${Column} {
        padding-top: ${prop('theme.grid.gutter')};
        padding-bottom: ${prop('theme.grid.gutter')};
        border: 1px solid rgba(86, 61, 124, 0.2);
        background-color: rgba(86, 61, 124, 0.15);
      }
    `
  )};

  ${ifProp(
    'noGutters',
    css`
      margin-left: 0;
      margin-right: 0;
      & > ${Column} {
        padding-left: 0;
        padding-right: 0;
      }
    `
  )};
`

Row.displayName = 'Row'

export default Row
