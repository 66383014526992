import { onError } from '@apollo/client/link/error'

// 401s should not happen courtesy of our token refresh solution
// Always log 500s
const CHECK_ERRORS = [401, 500]

/* eslint-disable no-console */
const errorLink = onError((errors) => {
  const { graphQLErrors, networkError } = errors

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (err instanceof Error) {
        console.error('Error in /apollo component:', err)
        return
      }

      if (
        err.data &&
        err.data.statusCode &&
        CHECK_ERRORS.includes(err.data.statusCode)
      ) {
        console.error('Error in Apollo', err)
      }
    })
  }

  if (networkError) {
    console.error('Error in /apollo component:', networkError)
  }

  if (!graphQLErrors && !networkError) {
    console.error('Unknown error in /apollo component')
  }
})

export default errorLink
