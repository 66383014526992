import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import { compose, lifecycle, withState } from 'recompose'
import { SimpleGallery, Uploader } from '../uploads'
import settings from '../settings'
import Checkbox from '../customer-form/checkbox'
import withSplash from '../with-splash'
import { allowedExtensions } from '../../../utils/cloudinary'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${theme('photoUpload.section.borderBottom')};
  padding: ${theme('photoUpload.section.padding')};

  ${(props) => props.theme.media.min.sm`
    flex-direction: row;
  `}
`
const ImagesLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${(props) => props.theme.media.min.sm`
    flex-direction: column;
    max-width: 570px;
    margin-bottom: 0;
  `}

  ${(props) =>
    props.hasImage &&
    css`
      width: 100%;
      flex-direction: column;
      ${props.theme.media.min.sm`
        max-width: 270px;
      `}
      ${props.theme.media.min.lg`
        flex-direction: row;
      `}
    `}
`

const Right = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  ${(props) => props.theme.media.min.sm`
    min-width: 370px;
  `}
  ${(props) => props.theme.media.min.lg`
    margin-top: 0;
  `}
`

const CheckBoxContainer = styled.div`
  width: 100%;
  max-width: 370px;
  text-align: left;
  padding: 14px;
  padding-top: 0;

  label {
    margin: 25px auto 0px auto;
    span {
      color: ${theme('colors.link')};
      font-size: ${theme('photoUpload.checkbox.fontSize')};
      text-transform: none;
      letter-spacing: ${theme('photoUpload.checkbox.letterSpacing')};
      font-family: ${theme('typography.paragraph.fontFamily')};
    }
    > div {
      border-color: ${theme('forms.colors.checkboxBorder')};
    }
  }
  ${(props) => props.theme.media.min.sm`
    max-width: 270px;
  `}
  ${(props) => props.theme.media.min.md`
    max-width: 370px;
  `}

  input[type='checkbox']:checked + div {
    border-color: ${theme('forms.colors.checkboxBorder')};
    svg {
      color: ${theme('forms.colors.svgIconColor')};
    }
  }
`

const StyledCheckBox = styled(Checkbox)`
  > div {
    border-color: ${theme('forms.colors.checkboxBorder')};
  }
`

const UploadContainer = styled.div`
  width: 100%;
  max-width: 370px;
  padding: ${theme('photoUpload.container.padding')};

  label {
    padding: ${theme('photoUpload.button.padding')};
    color: ${theme('photoUpload.button.color')};
    font-size: ${theme('photoUpload.button.fontSize')};
    letter-spacing: ${theme('photoUpload.button.letterSpacing')};

    &:before {
      color: ${theme('photoUpload.button.color')};
      background-color: ${theme('photoUpload.button.backgroundColor')};
      border: ${theme('photoUpload.button.border')};
      height: ${theme('photoUpload.button.height')};
    }

    &:not([disabled]):hover {
      color: ${theme('photoUpload.buttonHover.color')};

      &:before {
        color: ${theme('photoUpload.buttonHover.color')};
        background-color: ${theme('photoUpload.buttonHover.backgroundColor')};
        border: ${theme('photoUpload.buttonHover.border')};
      }
    }
  }
  ${(props) => props.theme.media.min.sm`
    max-width: 270px;
    margin-left: ${theme('photoUpload.container.marginLeft')};
  `}
  ${(props) => props.theme.media.min.md`
    max-width: 370px;
  `}
`

const ImageContainer = styled.div`
  margin: 0px auto;
  width: 100%;
`

const StyledImage = styled.img`
  max-width: 120px;
  max-height: 170px;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
  object-fit: contain;

  ${(props) => props.theme.media.min.sm`
    max-width: 120px;
  `}
  ${(props) => props.theme.media.min.md`
    max-width: 180px;
  `}
`

const CheckBoxSpan = styled.span`
  font-size: ${theme('photoUpload.checkbox.text.fontSize')};
  font-weight: ${theme('photoUpload.checkbox.text.fontWeight')};
  letter-spacing: ${theme('photoUpload.checkbox.text.letterSpacing')};
`

const optOut = (
  updateUiState,
  prepData,
  key,
  event,
  setOptedOut,
  checkboxText,
  isImages
) => {
  setOptedOut(event.target.checked)

  const unmappedQuestions = prepData.questions || []
  const questionExists = unmappedQuestions.find((f) => f.question === key)
  if (!questionExists && event.target.checked) {
    unmappedQuestions.push({
      question: key,
      answers: [checkboxText],
    })
  }
  const questions = unmappedQuestions.map((q) => {
    const filteredAnswers = q.answers.filter((a) => a !== checkboxText)
    if (q.question === key && event.target.checked) {
      return {
        question: key,
        answers: [...filteredAnswers, checkboxText],
      }
    }
    if (q.question === key && !event.target.checked) {
      return {
        question: key,
        answers: filteredAnswers,
      }
    }
    return q
  })

  if (isImages) {
    updateUiState({
      prepData: {
        ...prepData,
        questions,
        willBringKitchenImages: event.target.checked,
      },
    })
  } else {
    updateUiState({
      prepData: {
        ...prepData,
        questions,
        willBringKitchenSketchImages: event.target.checked,
      },
    })
  }
}

const PhotoUpload = ({
  videoUrl,
  videoText,
  buttonText,
  checkboxText,
  image,
  fileInputId,
  parameterName,
  updateUiState,
  prepData,
  handleUploadsChange,
  uploadedFiles,
  onUpdate,
  deleting,
  setDeleting,
  splash,
  allowOptOut,
  optedOut,
  setOptedOut,
  isImages,
  useSplash,
}) => {
  if (uploadedFiles === null) {
    return null
  }

  return (
    <SectionWrapper>
      {((videoUrl && videoText) || !!image) && (
        <ImagesLeft hasImage={!!image}>
          <div>
            {videoUrl && videoText && (
              <a target="_blank" rel="noopener noreferrer" href={videoUrl}>
                {videoText}
              </a>
            )}
          </div>
          {image && (
            <ImageContainer>
              <StyledImage src={image} />
            </ImageContainer>
          )}
        </ImagesLeft>
      )}
      <Right>
        <UploadContainer data-track="upload-container">
          <Uploader
            {...settings.uploads}
            userId="bada"
            handleChange={(e) => {
              handleUploadsChange(e)
              if (useSplash) splash()
            }}
            initialFiles={uploadedFiles.filter(Boolean)}
            allowedExtensions={allowedExtensions}
            render={(uploaderProps) => {
              const question =
                prepData &&
                prepData.questions &&
                prepData.questions.find((f) => f.question === parameterName)
              const completedFiles = uploaderProps.files.filter(
                (file) => file.status === 'upload successful'
              )

              if (completedFiles.length > 0 && !deleting) {
                setDeleting(true)
                completedFiles.forEach((file) => {
                  const answer = question.answers.find(
                    (a) => a === file.metadata.url
                  )
                  if (!answer && file.metadata.url) {
                    uploaderProps.uploader.methods.deleteFile(file.id)
                  }
                })
                setTimeout(() => setDeleting(false), 3000)
              }

              return (
                <SimpleGallery
                  {...uploaderProps}
                  fileInputId={fileInputId}
                  fileInputText={`+
                  ${buttonText}`}
                />
              )
            }}
          />
        </UploadContainer>
        {allowOptOut && (
          <CheckBoxContainer data-track="btn-later">
            <StyledCheckBox
              onChange={(event) => {
                optOut(
                  updateUiState,
                  prepData,
                  parameterName,
                  event,
                  setOptedOut,
                  checkboxText,
                  isImages
                )
                onUpdate()
                if (useSplash) splash()
              }}
              value={optedOut}
              checked={optedOut}
            >
              <CheckBoxSpan>{checkboxText}</CheckBoxSpan>
            </StyledCheckBox>
          </CheckBoxContainer>
        )}
      </Right>
    </SectionWrapper>
  )
}

PhotoUpload.propTypes = {
  videoUrl: PropTypes.string,
  videoText: PropTypes.string,
  buttonText: PropTypes.string,
  checkboxText: PropTypes.string,
  image: PropTypes.string,
  fileInputId: PropTypes.string,
  handleUploadsChange: PropTypes.func,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  prepData: PropTypes.shape(),
  updateUiState: PropTypes.func,
  handlePhotosUploadsChange: PropTypes.func,
  handleSketchUploadsChange: PropTypes.func,
  onUpdate: PropTypes.func,
  splash: PropTypes.func,
  deleting: PropTypes.bool.isRequired,
  setDeleting: PropTypes.func.isRequired,
  parameterName: PropTypes.string.isRequired,
  optedOut: PropTypes.bool,
  allowOptOut: PropTypes.bool,
  setOptedOut: PropTypes.func.isRequired,
  isImages: PropTypes.bool.isRequired,
}

PhotoUpload.defaultProps = {
  videoUrl: null,
  videoText: null,
  buttonText: null,
  checkboxText: null,
  image: null,
  fileInputId: null,
  handleUploadsChange: null,
  uploadedFiles: null,
  prepData: null,
  updateUiState: null,
  handlePhotosUploadsChange: null,
  handleSketchUploadsChange: null,
  onUpdate: () => null,
  splash: () => null,
  allowOptOut: false,
  optedOut: false,
}

const enhance = compose(
  withSplash,
  withState('deleting', 'setDeleting', false),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {
      const { parameterName, splash, prepData, useSplash } = this.props

      if (
        prevProps.prepData[parameterName] &&
        prepData[parameterName] &&
        prevProps.prepData[parameterName].length !==
          prepData[parameterName].length
      ) {
        if (useSplash) splash()
      }
    },
  })
)

export default enhance(PhotoUpload)
