const eweFonts = `
 @font-face {
   font-family: 'Tahoma';
 }
 @font-face {
   font-weight: 200;
   font-family: 'Tahoma';
 }
 @font-face {
   font-weight: 700;
   font-family: 'Tahoma';
 }
 `

export default eweFonts
