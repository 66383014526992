import React from 'react'
import { PrepFormStep } from '../wizard'
import { badaDefaultProps, badaPropTypes } from '../../../../types'

const PrepFormPage = ({ props }) => <PrepFormStep {...props} />

PrepFormPage.defaultProps = {
  ...badaDefaultProps,
}

PrepFormPage.propTypes = {
  ...badaPropTypes,
}

export default PrepFormPage
