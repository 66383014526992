import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose, combineReducers } from './index'
import { reducers as sharedReducers } from '../sharedState/reducers'
import syncLocalStorage from '../sharedState/syncLocalStorage'

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const getDefaultEnhancers = (middlewares = [], persistSharedState = false) => {
  const middleware = [thunkMiddleware, ...middlewares]

  if (persistSharedState) {
    middleware.push(syncLocalStorage({ keys: Object.keys(sharedReducers) }))
  }
  return [applyMiddleware(...middleware)]
}

const configureStore = ({
  reducers,
  middlewares = [],
  initialState = {},
  getEnhancers = getDefaultEnhancers,
  persistSharedState = false,
} = {}) =>
  createStore(
    typeof reducers !== 'function' ? combineReducers(reducers) : reducers,
    initialState,
    composeEnhancers(...getEnhancers(middlewares, persistSharedState))
  )

export default configureStore
