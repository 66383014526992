import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import styled, { css } from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import Headline from '../typography/headline'
import Paragraph from '../typography/paragraph'
import checkCircle from '../../../assets/check-circle.png'
import checkHexagon from '../../../assets/check-hexagon.png'
import magnetCircle from '../../../assets/magnet-circle.png'
import { isEmbedded } from '../../containers/bada/utils'
import BookingDetails from '../booking-details/BookingDetails'
import useTranslation from '../../../utils/translation'
import tracker from '../../../utils/tracker'
import ArrowDownIcon from '../icons/arrow-down'

const DetailsToggleButton = styled.button`
  text-decoration: underline;
  position: relative;
  color: ${theme('colors.link')};
  border: 0;
  background: transparent;
  font-weight: ${theme('forms.prepForm.detailsToggleButton.fontWeight')};

  svg {
    position: absolute;
    top: 5px;
    right: -15px;
    transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s;
  }
  ${(props) =>
    props.active &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`

const StyledHeadline = styled(Headline)`
  color: ${theme('forms.prepForm.headline.color')};
  margin-top: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.mobile.marginTop.embedded'),
    theme('forms.prepForm.headline.mobile.marginTop.normal')
  )};
  text-transform: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.textTransform.embedded'),
    theme('forms.prepForm.headline.textTransform.normal')
  )};
  text-align: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.textAlign.embedded'),
    theme('forms.prepForm.headline.textAlign.normal')
  )};
  letter-spacing: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.letterSpacing.embedded'),
    theme('forms.prepForm.headline.letterSpacing.normal')
  )};
  line-height: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.lineHeight.embedded'),
    theme('forms.prepForm.headline.lineHeight.normal')
  )};
  font-size: ${theme('forms.headline.fontSize.mobile')};

  ${(props) => props.theme.media.min.sm`
    margin-top: ${theme('forms.prepForm.headline.desktop.marginTop')};
    font-size: ${theme('forms.prepForm.headline.desktop.fontSize')};
    font-weight: ${theme('forms.prepForm.headline.desktop.fontWeight')};
    line-height: ${theme('forms.prepForm.headline.desktop.lineHeight')};
    text-transform: ${ifProp(
      'embedded',
      theme('forms.prepForm.headline.textTransform.embedded'),
      theme('forms.prepForm.headline.textTransform.normal')
    )};
  `}
`

const IntroParagraph = styled(Paragraph)`
  text-align: ${theme('forms.prepForm.paragraph.textAlign')};
  color: ${theme('forms.prepForm.paragraph.color')};
  max-width: ${theme('forms.prepForm.paragraph.maxWidth')};
  text-align: ${ifProp(
    'embedded',
    theme('forms.prepForm.headline.textAlign.embedded'),
    theme('forms.prepForm.headline.textAlign.normal')
  )};
  margin: ${ifProp(
    'embedded',
    theme('forms.prepForm.paragraph.margin.embedded'),
    theme('forms.prepForm.paragraph.margin.normal')
  )};

  font-size: ${theme('forms.paragraph.fontSize')};

  ${(props) =>
    props.alternate &&
    props.theme.media.min.sm`
    margin-top: 10px;
    margin-bottom: ${
      theme('forms.prepForm.paragraph.marginBottom') || '50px'
    };    
  `}
`

const getName = (values) =>
  values && values.name ? ` ${values.name.split(' ')[0]}` : ''

const renderHeading = (text, values, alternate, embedded) => {
  if (text) {
    return (
      <StyledHeadline embedded={embedded}>
        {text.replace('{name}', `${getName(values)}`)}
      </StyledHeadline>
    )
  }

  return null
}

const getPreamble = (content, meetingTypeName) => {
  if (content && content.preparationText) {
    return (content.preparationText || '').replace(
      '{meetingtype}',
      (meetingTypeName || '').toLowerCase()
    )
  }

  return null
}

const image = {
  sigdal: checkCircle,
  marbodal: checkHexagon,
  hthdk: checkCircle,
  hthno: checkCircle,
  hthfi: checkCircle,
  hthse: checkCircle,
  hthde: checkCircle,
  invita: checkCircle,
  magnet: magnetCircle,
  norema: checkCircle,
  novart: checkCircle,
  unoform: checkCircle,
  unoformno: checkCircle,
  unoformse: checkCircle,
  unoformbe: checkCircle,
}

const PrepHeader = ({
  values,
  selectedStore,
  selectedSlot,
  selectedService,
  content,
  detailsActive,
  toggleDetails,
  returningVisitor,
  alternate,
}) => {
  useEffect(() => {
    const storeName = selectedStore && selectedStore.name
    if (storeName) {
      tracker.trackPageview(
        'BADA 2.0 prepform',
        `/bada/step-5-details/${storeName.toLowerCase().replace(/ /g, '-')}/${
          selectedService.id
        }/${selectedSlot && selectedSlot.from ? 'slot' : 'no-slot'}`
      )
    }
  }, [])

  if (!content) return null

  const t = useTranslation('app.prep-form')
  return (
    <>
      {!returningVisitor
        ? renderHeading(
            content.preparationHeading,
            values,
            alternate,
            isEmbedded()
          )
        : renderHeading(
            content.preparationHeadingReturning,
            values,
            alternate,
            isEmbedded()
          )}

      <IntroParagraph size="large" embedded={isEmbedded()} alternate>
        {!returningVisitor
          ? getPreamble(content, selectedService.name)
          : content.preparationTextReturning}{' '}
        {!returningVisitor &&
          selectedSlot &&
          Object.entries(selectedSlot).length > 0 && (
            <DetailsToggleButton
              data-cy="booking-summary-button"
              onClick={() => toggleDetails(!detailsActive)}
              active={detailsActive}
            >
              {t('show-details')}
              <ArrowDownIcon
                width={10}
                height={10}
                color={theme('colors.default') || '#000'}
              />
            </DetailsToggleButton>
          )}
      </IntroParagraph>

      {selectedStore && !returningVisitor && (
        <BookingDetails
          detailsActive={detailsActive}
          image={image[window.zeus.settings.brand]}
          selectedStore={selectedStore}
          selectedSlot={selectedSlot}
          selectedServiceName={content.heading}
        />
      )}
    </>
  )
}

PrepHeader.propTypes = {
  values: PropTypes.shape(),
  selectedStore: PropTypes.shape(),
  selectedSlot: PropTypes.shape(),
  selectedService: PropTypes.shape(),
  content: PropTypes.shape(),
  detailsActive: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func.isRequired,
  returningVisitor: PropTypes.bool,
  alternate: PropTypes.bool,
}

PrepHeader.defaultProps = {
  values: {},
  selectedStore: {},
  selectedSlot: {},
  selectedService: {},
  content: {},
  returningVisitor: false,
  alternate: false,
}

const enhance = compose(withState('detailsActive', 'toggleDetails', false))

export default enhance(PrepHeader)
