import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'

export const isDeletable = (status) =>
  [qq.status.DELETE_FAILED, qq.status.UPLOAD_SUCCESSFUL].includes(status)

class DeleteButton extends Component {
  constructor(props) {
    super(props)

    this.mounted = false
    const file = this.props.uploader.methods.getUploads({ id: props.id })

    this.state = {
      deletable: file != null ? isDeletable(file.status) : false,
      deleting: file != null ? file.status === qq.status.DELETING : false,
    }

    this.onStatusChange = this.onStatusChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.props.uploader.on('statusChange', this.onStatusChange)
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.uploader.off('statusChange', this.onStatusChange)
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (id === this.props.id && this.mounted) {
      const deletable = isDeletable(newStatus)
      const deleting = newStatus === qq.status.DELETING

      this.setState((state) => {
        if (deletable !== state.deletable || deleting !== state.deleting) {
          return { deletable, deleting }
        }

        return null
      })
    }
  }

  onClick() {
    this.props.uploader.methods.deleteFile(this.props.id)
  }

  render() {
    const { deletable, deleting } = this.state
    const {
      id,
      children,
      className,
      onlyRenderIfDeletable,
      uploader,
      ...props
    } = this.props

    if (deletable || deleting || !onlyRenderIfDeletable) {
      return (
        <button
          {...props}
          type="button"
          className={className}
          disabled={!deletable || deleting}
          onClick={deletable && !deleting ? this.onClick : undefined}
        >
          {children}
        </button>
      )
    }

    return null
  }
}

export default DeleteButton
