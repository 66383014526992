import { combineReducers, configureStore } from '../redux'
import { reducers } from './reducers'
import { rehydrateState, rehydrateStateReducer } from './syncLocalStorage'
import authReducer from '../auth/authReducer'
import { parseServerState } from '../auth/helpers'
import { authRequestSuccess } from '../auth/authActions'
import * as globals from './globals'

const hydrateState = (store, persistSharedState) => {
  // Get state from localStorage
  if (persistSharedState) {
    store.dispatch(rehydrateState())
  }

  if (globals.omni != null) {
    const session = parseServerState(globals.omni)
    const now = Date.now()

    if (session.expiresAt != null && now < session.expiresAt) {
      store.dispatch(authRequestSuccess(session))
    }
  }
}

const getMaybeServerRenderData = () => {
  if (globals.next != null && globals.next.props != null) {
    return globals.next.props.serverState
  }

  return {}
}

// Setup the omni data stack with Apollo and Redux
// Initialize with server data if it exists and setup Redux
const initSharedState = (storeConfig = {}) => {
  if (globals.nobia != null && globals.nobia.sharedStore != null) {
    return globals.nobia.sharedStore
  }

  const initialState =
    storeConfig != null && storeConfig.initialState != null
      ? storeConfig.initialState
      : getMaybeServerRenderData()

  const sharedReducers =
    storeConfig != null && storeConfig.persistSharedState ? reducers : {}

  const baseReducer = combineReducers({
    auth: authReducer,
    ...sharedReducers,
    ...storeConfig.reducers,
  })

  const combinedReducers = rehydrateStateReducer(baseReducer)

  const store = configureStore({
    ...storeConfig,
    reducers: combinedReducers,
    initialState,
  })

  if (globals.nobia != null) {
    const nobia = globals.nobia // preserve flow's type refinement

    hydrateState(store, storeConfig.persistSharedState)

    nobia.sharedStore = store
  }

  return store
}

export default initSharedState
