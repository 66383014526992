import CryptoJS from 'crypto-js'

window.dataLayer = window.dataLayer || []

const CLICK_ACTION = 'click'
const CLICK_FAIL_ACTION = 'click fail'
const CLICK_SUCCESS_ACTION = 'click success'
const AUTO_ACTION = 'autoevent'
const FILLED_ACTION = 'filled'

const trackAction = (category, action, label) => {
  window.dataLayer.push({
    event: 'badagtmpush.e',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
  })
}

const hashValue = (value) => {
  if (!value) return value
  return CryptoJS.SHA256(value).toString()
}

const anonymizeState = (state) => {
  if (!state) {
    return state
  }
  const lowerCaseEmail = state.customerFormInput?.email.toLowerCase()
  let customerFormInput;

  if (state.customerFormInput && state.customerFormInput.bookedByDesigner !== undefined) {
    const { bookedByDesigner, ...rest } = state.customerFormInput;
    customerFormInput = rest;
  } else {
    customerFormInput = state.customerFormInput || {};
  }

  return {
    ...state,
    appointmentId: hashValue(state.appointmentId),
    customerFormInput: {
      ...customerFormInput,
      address: hashValue(customerFormInput?.address),
      address2: hashValue(customerFormInput?.address2),
      address3: hashValue(customerFormInput?.address3),
      city: hashValue(customerFormInput?.city),
      comments: hashValue(customerFormInput?.comments),
      country: hashValue(customerFormInput?.country),
      email: hashValue(lowerCaseEmail),
      name: hashValue(customerFormInput?.name),
      postCode: hashValue(customerFormInput?.postCode),
      phone: hashValue(customerFormInput?.phone),
      trackingCookie: hashValue(customerFormInput?.trackingCookie),
      userIdentifier: hashValue(customerFormInput?.userIdentifier),
    },
    selectedAddress: {
      ...state.selectedAddress,
      city: hashValue(state.selectedAddress?.city),
      countryName: hashValue(state.selectedAddress?.countryName),
      postalCode: hashValue(state.selectedAddress?.postalCode),
    },
    prepData: {
      ...state.prepData,
      questions: state.prepData?.questions?.map((question) => ({
        ...question,
        answers: question?.answers?.map((answer) => hashValue(answer)),
      })),
    },
    values: {
      ...state.values,
      address: hashValue(state.values?.address),
      address2: hashValue(state.values?.address2),
      address3: hashValue(state.values?.address3),
      city: hashValue(state.values?.city),
      comments: hashValue(state.values?.comments),
      country: hashValue(state.values?.country),
      email: hashValue(lowerCaseEmail),
      name: hashValue(state.values?.name),
      zipcode: hashValue(state.values?.zipcode),
      phone: hashValue(state.values?.phone),
    },
  }
}

/**
 * Tracks a user click.
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackClick = (category, label) => {
  trackAction(category, CLICK_ACTION, label)
}

/**
 * Tracks a successful user click.
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackClickSuccess = (category, label) => {
  trackAction(category, CLICK_SUCCESS_ACTION, label)
}

/**
 * Tracks a successful user click.
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackClickFail = (category, label) => {
  trackAction(category, CLICK_FAIL_ACTION, label)
}

/**
 * Tracks an 'automatic' action, such as a component
 * being displayed as a result of another action.
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackAuto = (category, label) => {
  trackAction(category, AUTO_ACTION, label)
}

/**
 * Tracks 'init visitor' action,
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackInitVisitor = (category, label, auth) => {
  let bookedByDesigner = false
  if (
    auth &&
    auth.status &&
    auth.status === 'authenticated' &&
    auth.profile &&
    auth.profile.username
  ) {
    bookedByDesigner = auth.profile.roles?.includes('staff')
  }

  window.dataLayer.push({
    event: 'badagtmpush.e',
    eventCategory: category,
    eventAction: AUTO_ACTION,
    eventLabel: label,
    bookedByDesigner,
  })
}

/**
 * Tracks a user updating an input field.
 * @param {string} category User friendly name of event.
 * @param {string} label Event metadata.
 */
const trackFilled = (category, label) => {
  trackAction(category, FILLED_ACTION, label)
}

/**
 * Tracks entire wizard state.
 * @param {*} state The wizard state.
 */
const trackState = (state) => {
  const anonymizedState = anonymizeState(state)
  window.dataLayer.push({
    event: 'badastateupdate',
    ...anonymizedState,
  })
}

/**
 * Tracks a pageview.
 * @param {string} title User friendly name of the view.
 * @param {string} page Path to view.
 */
const trackPageview = (title, page) => {
  window.dataLayer.push({
    event: 'badagtmpush.pv',
    title,
    page,
  })
}

export default {
  trackPageview,
  trackState,
  trackClick,
  trackClickSuccess,
  trackClickFail,
  trackAuto,
  trackFilled,
  trackInitVisitor,
}
