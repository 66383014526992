import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Information from '../../../../icons/information'

const Container = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  display: flex;
  margin-top: ${theme('temporarilyClosed.marginTop')};
  background-color: ${theme('colors.boxColor')};
  padding: ${theme('temporarilyClosed.padding')};
  border-radius: ${theme('temporarilyClosed.borderRadius')};
`

const IconContainer = styled.div`
  width: ${theme('temporarilyClosed.icon.width')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  padding-left: ${theme('temporarilyClosed.text.paddingLeft')};
`

const Text = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  p {
    margin: ${theme('temporarilyClosed.text.p.margin')};
  }
`

const TemporarilyClosed = ({ text }) => (
  <Container>
    <IconContainer>
      <Information width={26} height={26} />
    </IconContainer>
    <TextContainer>
      <Text
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </TextContainer>
  </Container>
)

export default TemporarilyClosed
