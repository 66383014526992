import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { theme } from 'styled-tools'
import { mediaMin } from '../../../../styles/media'

export const TabButtonContainer = styled.dt`
  border-bottom: ${theme('colors.border')};

  ${mediaMin(
    'sm',
    css`
      order: -1;
      border-bottom: 0;
    `
  )};
`

export const TabButtonToggle = styled.button.attrs({ type: 'button' })`
  position: relative;
  background: none;
  border: 0;
  color: ${theme('tabs.color.inactive')};
  display: block;
  font-size: ${theme('tabs.fontSize')};
  font-weight: ${theme('tabs.fontWeight.inactive')};
  text-transform: ${theme('tabs.textTransform')};
  letter-spacing: ${theme('tabs.letterSpacing')};
  margin: 0;
  padding: ${theme('tabs.padding')};
  text-align: left;
  width: 100%;
  font-family: ${theme('typography.paragraph.fontFamily')};
  border-bottom: 1px solid ${theme('tabs.color.bottomBorder')};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    display: block;
    width: 100%;
    height: 0;
    background: ${theme('tabs.color.activeBorder')};
    transition: height 0.2s;
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${theme('tabs.colors.links')};
  }

  &[aria-expanded='true'] {
    color: ${theme('tabs.color.default')};
    font-weight: ${theme('tabs.fontWeight.active')};

    &::after {
      height: ${theme('tabs.borderHeight.active')};
      opacity: 1;
    }
  }
`

export const TabButtonTitle = styled.span`
  display: inline-block; /* For Edge bug https://goo.gl/JtRjUR */
  pointer-events: none;
`

const StoresTabButton = ({ id, title, currentTab, changeTab }) => (
  <TabButtonContainer>
    <TabButtonToggle
      id={`tab-button-${id}`}
      aria-controls={`tab-button-${id}`}
      aria-expanded={currentTab === id ? 'true' : 'false'}
      aria-disabled={currentTab === id ? 'true' : undefined}
      onClick={() => changeTab(id)}
    >
      <TabButtonTitle>{title}</TabButtonTitle>
    </TabButtonToggle>
  </TabButtonContainer>
)

StoresTabButton.propTypes = {
  id: PropTypes.string.isRequired,
  currentTab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
}

export default StoresTabButton
