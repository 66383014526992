import { createGlobalStyle } from 'styled-components'

const FONT_FAMILIES = {
  HelveticaNeue: '"HelveticaNeueW02", "Helvetica Neue", Arial, sans-serif',
}

const GlobalStyles = createGlobalStyle`
  
  html {
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll;
  }

  body {
      background-color: #ffffff;
      padding:0;
      margin:0;
  }
`

export default {
  global: {
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    fontWeight: 300,
    fontFamily: FONT_FAMILIES.HelveticaNeue,
  },
  colors: {
    default: '#0B0A07',
    link: '#0B0A07',
    activeBorder: '#C19A5B',
    accent: '#C19A5B',
    accentShadow: '0 0 0 5px RGB(238, 241, 242)',
    inputBorder: '#BBBBBB',
    darkGrey: '#0B0A07',
  },
  forms: {
    height: '53px',
    fontSize: '14px',
    inputWrapper: {
      minHeight: '90px',
    },
    position: {
      top: '18px',
    },
    colors: {
      selected: '#000',
      valid: 'green',
    },
    prepForm: {
      headline: {
        color: '#333333',
        desktop: {
          fontSize: '82px',
          lineHeight: '84px',
        },
        textTransform: {
          embeded: 'none',
          normal: 'none',
        },
        lineHeight: {
          normal: '44px',
        },
      },
      paragraph: {
        color: '#0B0A07',
      },
    },
    label: {
      fontSize: '14px',
    },
    customerForm: {
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      margin: '14px', // bottom only
      headings: {
        fontSize: '42px',
        color: '#4a4a4a',
      },
    },
    headline: {
      fontSize: {
        mobile: '42px',
      },
    },
  },
  typography: {
    pageTitle: {
      fontSize: '42px',
      fontSizeLarge: '82px',
      fontStyle: 'normal',
      fontWeight: 100,
      textTransform: 'none',
      marginTop: '0px',
      marginBottom: '60px',
      lineHeight: '44px',
      lineHeightLarge: '84px',
      textAlign: 'left',
      fontFamily: FONT_FAMILIES.HelveticaNeue,
    },
    headline: {
      textTransform: 'none',
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontWeight: 100,
      fontSize: '26px',
      fontSizeLarge: '42px',
    },
    subHeadline: {
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '16px',
      fontSizeLarge: '16px',
    },
    title: {
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      textTransform: 'none',
    },
    paragraph: {
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontWeightLead: 300,
      fontWeight: 300,
      fontSize: '16px',
      fontSizeLarge: '16px',
      fontSizeLead: '16px',
      color: '#0B0A07',
      lineHeight: '24px',
    },
    storeHeader: {
      marginTop: '5px',
      marginBottom: '5px',
    },
    storeName: {
      fontWeight: 200,
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontSize: '20px',
      textTransform: 'none',
      letterSpacing: 'normal',
      mobile: {
        fontSize: '16px',
      },
    },
    storeAddress: {
      fontSize: '14px',
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontWeight: 300,
      color: '#000',
      marginBottom: '10px',
    },
    storeAddressLong: {
      padding: '25px 20px 25px 20px',
      paddingDesktop: '20px',
    },
    link: {
      textDecoration: 'underline',
    },
  },
  header: {
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid rgba(25,6,6,0.1)',
    shadow: 'none',
    height: '58px',
    heightDesktop: '100px',
    logoMargin: '0',
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: '0px',
    arrowContainer: {
      paddingRight: '0px',
    },
  },
  showMore: {
    textTransform: 'uppercase',
    letterSpacing: '3.5px',
    fontWeight: '400',
    fontSize: '14px',
  },
  bookingDetails: {
    addressItem: {
      textTransform: 'none',
    },
    lineHeight: '24px',
    backgroundColor: '#fff',
    border: {
      color: '#BBBBBB',
    },
    subHeadline: {
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '3.5px',
      lineHeight: '24px',
    },
  },
  contentHeader: {
    heading: {
      desktop: {
        fontWeight: 200,
        fontSize: '82px',
        lineHeight: '84px',
      },
      mobile: {
        fontSize: '42px',
        lineHeight: '44px',
        textAlign: 'left',
      },
    },
    otherNeeds: {
      popDownBgColor: '#ECECE7',
      fontWeight: 300,
      textDecoration: 'none',
      fontSize: {
        mobile: '16px',
        desktop: '16px',
      },
      desktop: {
        positionTop: '-58px',
        marginBottom: '-58px',
        fontSize: '16px',
      },
      mobile: {
        positionTop: '-52px',
        marginBottom: '-52px',
        fontSize: '16px',
        fontWeight: 300,
      },
    },
    desktop: {
      alignItems: 'center',
    },
    mobile: {
      alignItems: 'center',
    },
  },
  postalCodeOverlay: {
    inputField: {
      height: '46px',
      borderRadius: '0px',
    },
  },
  tabs: {
    color: {
      inactive: '#0B0A07',
      bottomBorder: 'rgba(33,19,46,0.2)',
      activeBorder: '#C19A5B',
      default: '#C19A5B',
    },
    fontWeight: {
      active: '400',
      inactive: '400',
    },
    textTransform: 'uppercase',
    letterSpacing: '3.5px',
    fontSize: '14px',
    header: {
      link: {
        color: '#000000',
        textDecoration: 'none',
      },
    },
    borderHeight: {
      active: '1px',
    },
  },

  stores: {
    distance: {
      fontSize: '16px',
    },
    changeStoreLink: {
      fontWeight: 300,
      color: '#0B0A07',
      textDecoration: 'underline',
    },
  },
  bookingSummary: {
    padding: '1px 30px 10px 30px',
    backgroundColor: '#ECECE7',
    borderWidth: '0px',
    itemTitle: {
      color: '#0B0A07',
      textTransform: 'uppercase',
      letterSpacing: '3.5px',
      fontWeight: '400',
      marginTop: '30px',
      marginBottom: '10px',
    },
    itemText: {
      marginBottom: '10px',
      lineHeight: '26px',
    },
    storeName: {
      marginBottom: '0px',
    },
  },

  contactDetails: {
    dt: {
      fontWeight: 500,
      textTransform: 'none',
    },
  },

  meetingType: {
    name: {
      fontSize: '14px',
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '3.5px',
      textTransform: 'uppercase',
    },
    text: {
      fontSize: '12px',
      fontFamily: FONT_FAMILIES.HelveticaNeue,
      fontWeight: '300',
      lineHeight: '15px',
      padding: '14px',
    },
  },

  quickQuestions: {
    heading: {
      style1: {
        color: '#333333',
        fontWeight: 100,
        letterSpacing: '0px',
        fontFamily: FONT_FAMILIES.HelveticaNeue,
        fontSize: {
          mobile: '20px',
          desktop: '20px',
        },
        lineHeight: {
          mobile: '26px',
          desktop: '26px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'none',
        minWidth: {
          desktop: '265px',
        },
      },
      style2: {
        color: '#333333',
        fontWeight: 100,
        letterSpacing: '0px',
        fontFamily: FONT_FAMILIES.HelveticaNeue,
        fontSize: {
          mobile: '32px',
          desktop: '32px',
        },
        lineHeight: {
          mobile: '34px',
          desktop: '34px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'none',
        minWidth: {
          desktop: '265px',
        },
      },
      style3: {
        color: '#333333',
        fontWeight: 100,
        letterSpacing: '0px',
        fontFamily: FONT_FAMILIES.HelveticaNeue,
        fontSize: {
          mobile: '20px',
          desktop: '20px',
        },
        lineHeight: {
          mobile: '26px',
          desktop: '26px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'none',
        minWidth: {
          desktop: '100%',
        },
      },
    },
    optionsLabel: {
      color: '#0B0A07',
    },
    preamble: {
      color: '#0B0A07',
    },
    container: {
      mobile: {
        padding: '10px',
      },
      desktop: {
        padding: '10px',
        margin: '0 0 10px 0',
      },
    },
  },
  questionBlock: {
    optionsItem: {
      border: '1px solid #bbbbbb',
      borderRadius: '0px',
      activeBorder: '1px solid #C19A5B',
      activeBoxShadow: '0 0 0 5px RGB(238, 241, 242)',
    },
    container: {
      flexBasis: '45%',
    },
    heading: {
      container: {
        style1: {
          flexBasis: '85%',
        },
        style2: {
          flexBasis: '100%',
        },
        style3: {
          flexBasis: '85%',
        },
      },
    },
  },
  confirmation: {
    header: {
      textAlign: 'left',
      fontSize: '42px',
      fontSizeDesktop: '82px',
    },
  },
  imageOption: {
    text: {
      fontSize: {
        desktop: '13px',
        mobile: '13px',
      },
    },
  },
  textOption: {
    text: {
      fontSize: {
        mobile: '14px',
        desktop: '16px',
        fontWeight: 300,
      },
      fontWeight: 300,
      active: {
        fontWeight: 400,
      },
    },
  },
  button: {
    colors: {
      primaryColor: '#fff',
      primaryBackground: '#C19A5B',
    },
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '3.5px',
    border: '1px solid transparent',
    blockButtonWidth: 'auto',
    wideButtonWidth: 'auto',
    marginTop: 0,
  },
  designer: {
    margin: {
      desktop: '28px 0',
      mobile: '28px 0',
    },
    background: {
      color: '#ECECE7',
    },
    paragraph: {
      color: '#0B0A07',
      fontSize: {
        desktop: '16px',
      },
    },
    headline: {
      textTransform: {
        normal: 'none',
      },
      fontSize: '32px',
      fontWeight: 100,
      marginBottom: 0,
    },
    name: {
      fontSize: '14px',
      fontWeight: '400',
      textTransform: 'uppercase',
      letterSpacing: '3.5px',
      marginTop: '0px',
    },
    location: {
      fontWeight: '300',
      fontSize: '14px',
      marginTop: '0px',
      marginBottom: '10px',
    },
  },

  GlobalStyles,
}
