import React, { Component } from 'react'

const defaultStatusText = {
  canceled: 'Canceled',
  deleted: 'Deleted',
  deleting: 'Deleting',
  paused: 'Paused',
  queued: 'Queued',
  retrying_upload: 'Retrying',
  submitting: 'Submitting',
  uploading: 'Uploading',
  upload_failed: 'Failed',
  upload_successful: 'Completed',
}

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: '',
      text: { ...defaultStatusText, ...this.props.text },
    }

    this.mounted = false
    this.onStatusChange = this.onStatusChange.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.props.uploader.on('statusChange', this.onStatusChange)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.setState((state) => ({
        text: { ...state.text, ...nextProps.text },
      }))
    }
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.uploader.off('statusChange', this.onStatusChange)
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (this.mounted && id === this.props.id) {
      this.setState((state) => {
        const status = Status.getStatus(newStatus)

        if (status != null && status !== state.status) {
          return { status }
        }

        return null
      })
    }
  }

  static getStatus(status) {
    return status.replace(' ', '_')
  }

  render() {
    const { text, status } = this.state
    const { className, ...props } = this.props

    return (
      <span className={className} {...props}>
        {status ? text[status] : undefined}
      </span>
    )
  }
}
export default Status
