const populateVariables = (text, variableMap) => {
  let populatedText = text
  if (populatedText) {
    variableMap.forEach(item => {
      populatedText = populatedText.replace(item.variable, item.value)
    })
  }
  return populatedText
}

export default populateVariables
