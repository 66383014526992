import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Headline from '../typography/headline'
import Paragraph from '../typography/paragraph'
import tracker from '../../../utils/tracker'
import { StoreItem } from './store-list/components/store-item'
import TemporarilyClosed from './store-list/components/store-item/TemporarilyClosed'

import { getDisplayData } from '../../containers/bada/content'

const ChangeStoreLink = styled(Paragraph)`
  cursor: pointer;
  text-decoration: ${theme('stores.changeStoreLink.textDecoration')};
  color: ${theme('tabs.header.link.color')};
  color: ${theme('stores.changeStoreLink.color')};
  font-weight: ${theme('stores.changeStoreLink.fontWeight')};
  line-height: 21px;
  margin: 0;
  margin-bottom: 40px;
`

const ParagraphUI = styled(Paragraph)`
  color: ${theme('colors.darkGrey')};
  font-size: ${theme('stores.changeStoreLink.fontSize')};
  font-weight: ${theme('typography.storeAddress.fontWeight')};
  line-height: 21px;
  margin: 20px 0px 10px 0px;
`

const HeadlineUI = styled(Headline)`
  display: none;
  margin-top: 30px;
  margin-bottom: 20px;
`
const HeadlineDesktop = styled(HeadlineUI)`
  ${(props) => props.theme.media.min.md`
    display: block;
  `}
  text-transform: inherit;
  text-transform: ${theme('stores.headers.textTransform')};
  line-height: ${theme('stores.headers.lineHeight')};
`

const HeadlineMobile = styled(HeadlineUI)`
  ${(props) => props.theme.media.max.sm`
    display: block;
  `}
  text-transform: inherit;
  text-transform: ${theme('stores.headers.textTransform')};
`

const BlockElement = styled.div`
  display: block;
`

const PreSelectionStore = ({ store, checked }) => (
  <div id="store-list">
    <StoreItem key={store.id} store={store} checked={checked} >
      {store.isTemporarilyClosed && store.temporarilyClosedInformation && (
        <TemporarilyClosed text={store.temporarilyClosedInformation} />
      )}
    </StoreItem>
  </div>
)

const PreSelection = ({ pageContent, store, onChange }) => {
  const {
    storeSelectionHeadingSelectedDesktop: headingDesktop,
    storeSelectionHeadingSelectedMobile: headingMobile,
    storeSelectionChangeStoreText: storeText,
    storeSelectionChangeStoreLinkLabel: linkLabel,
  } = getDisplayData(pageContent)
  return (
    <>
      <BlockElement>
        {headingDesktop && <HeadlineDesktop>{headingDesktop}</HeadlineDesktop>}
        {headingMobile && <HeadlineMobile>{headingMobile}</HeadlineMobile>}
      </BlockElement>
      {store ? (
        <BlockElement>
          <PreSelectionStore key={store.id} store={store} checked />
        </BlockElement>
      ) : (
        <BlockElement>
          <PreSelectionStore
            store={{
              id: 'store-loading',
              value: 'loading-store',
            }}
            checked
          />
        </BlockElement>
      )}
      {storeText && (
        <BlockElement>
          <ParagraphUI>{storeText}</ParagraphUI>
        </BlockElement>
      )}
      {linkLabel && (
        <BlockElement>
          <ChangeStoreLink
            onClick={() => {
              tracker.trackClick(
                'BADA 2.0 change store',
                'Book a meeting in a different store'
              )
              onChange({
                storeId: null,
                selectedStore: null,
                selectedService: null,
                selectedSlot: null,
              })
            }}
          >
            {linkLabel}
          </ChangeStoreLink>
        </BlockElement>
      )}
    </>
  )
}

export default PreSelection
