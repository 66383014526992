import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SectionItem, SectionItemHeading, SectionItemText } from '../sharedUI'
import useTranslation from '../../../../utils/translation'

const DateSectionItemText = styled(SectionItemText)`
  ${(p) =>
    p.meetingHasPassed
      ? `
    color: red;
  `
      : ``}
`

const MeetingViewer = ({
  storeName,
  selectedDate,
  selectedTime,
  serviceName,
  meetingHasPassed,
}) => {
  const t = useTranslation('appointment-details')
  return (
    <>
      {selectedDate !== null && (
        <SectionItem>
          <SectionItemHeading>{t('chosen-date')}</SectionItemHeading>
          <DateSectionItemText meetingHasPassed={meetingHasPassed}>
            {selectedDate}
          </DateSectionItemText>
        </SectionItem>
      )}
      {selectedTime !== null && (
        <SectionItem>
          <SectionItemHeading>{t('chosen-time')}</SectionItemHeading>
          <SectionItemText>{selectedTime}</SectionItemText>
        </SectionItem>
      )}
      {serviceName !== null && (
        <SectionItem>
          <SectionItemHeading>{t('meeting-type')}</SectionItemHeading>
          <SectionItemText>{serviceName}</SectionItemText>
        </SectionItem>
      )}
      {storeName !== null && (
        <SectionItem>
          <SectionItemHeading>{t('chosen-store')}</SectionItemHeading>
          <SectionItemText>{storeName}</SectionItemText>
        </SectionItem>
      )}
    </>
  )
}

MeetingViewer.propTypes = {
  storeName: PropTypes.string,
  selectedDate: PropTypes.string,
  selectedTime: PropTypes.string,
  serviceName: PropTypes.string.isRequired,
  meetingHasPassed: PropTypes.bool.isRequired,
}

MeetingViewer.defaultProps = {
  storeName: null,
  selectedDate: null,
  selectedTime: null,
}

export default MeetingViewer
