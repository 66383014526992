import React from 'react'
import queryString from 'query-string'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import LightboxOverlayCloseButton from './LightboxOverlayCloseButton'

const Container = styled.div`
  position: relative;
  width: 80vw;
  max-height: 90vh;
  padding-top: 56.25%;
  background: ${prop('theme.colors.black')};
`

const Iframe = styled.iframe.attrs({
  frameBorder: 0,
  allowFullScreen: true,
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const getOrigin = () => {
  const { protocol, hostname, port } = window.location

  return `${protocol}//${hostname}${port ? `:${port}` : ''}`
}

const getUrl = (props) => {
  const options = queryString.stringify({
    rel: +props.rel,
    showinfo: +props.showInfo,
    autoplay: +props.autoPlay,
    modestbranding: +props.modestBranding,
    origin: getOrigin(),
  })

  return `https://www.youtube.com/embed/${props.id}?${options}`
}

const LightboxOverlayVideo = (props) => {
  const src = getUrl(props)

  return (
    <Container className={props.className}>
      <Iframe src={src} onClick={props.setClosed} />
      <LightboxOverlayCloseButton onClick={props.setClosed} />
    </Container>
  )
}

LightboxOverlayVideo.defaultProps = {
  rel: false,
  showInfo: false,
  autoPlay: true,
  modestBranding: true,
  className: undefined,
}

export default LightboxOverlayVideo
