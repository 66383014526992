import React from 'react'
import { useParams } from 'react-router-dom'

import styled from 'styled-components'
import { theme } from 'styled-tools'

import Loader from '../../components/loader/Loader'
import Header from '../../components/header'

import AmendStep1 from './AmendStep1'
import AmendStep2 from './AmendStep2'
import AmendStep3 from './AmendStep3'

import useAppointment from '../../hooks/appointment/useAppointment'
import useStore from '../../hooks/useStore'
import useTranslation from '../../../utils/translation'

import { isEmbedded } from '../bada/utils'
import { checkMeetingHasPassed } from '../appointment-details/utils'
import useCmsContent from '../../hooks/useCmsContent'

const MainContainer = styled.div`
  padding: 20px;
  max-width: 1097px;
  margin: 0px auto 400px auto;
`

const LoaderCover = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
`

const LoaderContainer = styled.div`
  margin-top: 40vh;
`

export const ErrorMessage = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('typography.amend.headline.fontFamily')};
  font-weight: ${theme('amend.error.fontWeight')};
  font-size: 36px;
  text-align: center;
  margin-top: 40px;
`

const validateAppointment = (appointment, step) => {
  const failedFetchingAppointment = !appointment
  if (failedFetchingAppointment) return 'appointment-not-found'

  const appointmentHasPassed = checkMeetingHasPassed(
    appointment.selectedDate,
    appointment.selectedTime
  )
  if (appointmentHasPassed) return 'appointment-passed'

  const appointmentTypeCanBeCanceled = appointment.selectedTime !== null
  if (!appointmentTypeCanBeCanceled) return 'not-slot-booking'

  const appointmentCanceled =
    appointment.simplyBookMeStatus === 'canceled' && step !== '3'
  if (appointmentCanceled) return 'appointment-canceled'

  return null
}

export const useAmendPageData = (appointmentId) => {
  const {
    data: appointment,
    isLoading: appointmentIsLoading,
    refetch: refetchAppointment,
  } = useAppointment(appointmentId)
  const { data: store, loading: storeIsLoading } = useStore(
    appointment?.storeId
  )
  const { data: pageContent, loading: cmsContentIsLoading } = useCmsContent()

  const isLoading =
    appointmentIsLoading || storeIsLoading || cmsContentIsLoading

  return {
    appointment,
    refetchAppointment,
    store: store?.getStoreWithDistance?.store,
    pageContent: pageContent?.page,
    isLoading,
  }
}

const AmendPage = () => {
  const t = useTranslation('amend')
  const { appointmentId } = useParams()
  const { appointment, refetchAppointment, store, pageContent, isLoading } =
    useAmendPageData(appointmentId)

  const step = new URLSearchParams(window.location.search).get('step')

  if (isLoading) {
    return (
      <LoaderCover>
        <LoaderContainer>
          <Loader duration={1} />
        </LoaderContainer>
      </LoaderCover>
    )
  }

  const errorMessage = validateAppointment(appointment, step)

  return (
    <>
      {!isEmbedded() && <Header />}
      <MainContainer>
        {errorMessage ? (
          <ErrorMessage>{t(errorMessage)}</ErrorMessage>
        ) : (
          (() => {
            switch (step) {
              case 1:
              default:
                return <AmendStep1 appointment={appointment} store={store} />
              case '2':
                return (
                  <AmendStep2
                    appointment={appointment}
                    store={store}
                    pageContent={pageContent}
                    refetchAppointment={refetchAppointment}
                  />
                )
              case '3':
                return (
                  <AmendStep3
                    appointment={appointment}
                    store={store}
                    pageContent={pageContent}
                  />
                )
            }
          })()
        )}
      </MainContainer>
    </>
  )
}

export default AmendPage
