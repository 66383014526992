import React from 'react'
import PropTypes from 'prop-types'

const Store = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 1416.000000 1386.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(-170.000000,1550.000000) scale(0.120000,-0.120000)"
      stroke="currentColor"
    >
      <path
        d="M3345 11750 c-107 -42 -98 -28 -405 -570 -212 -374 -408 -720 -465
-820 -114 -201 -642 -1131 -715 -1260 -27 -47 -98 -172 -159 -278 -61 -107
-120 -214 -132 -239 -29 -59 -35 -154 -19 -295 61 -561 392 -1048 890 -1310
l85 -45 5 -2514 c5 -2392 6 -2516 23 -2554 22 -50 89 -120 142 -148 l40 -22
4559 -3 4559 -2 51 23 c70 32 122 80 154 141 l27 51 3 2512 2 2513 60 30 c452
228 780 648 890 1141 29 131 47 347 33 413 -8 35 -42 107 -96 203 -46 81 -169
297 -272 478 -103 182 -253 445 -333 585 -80 140 -182 320 -227 400 -119 209
-512 901 -687 1207 -165 290 -201 335 -298 366 -49 16 -318 17 -3859 16 -3733
0 -3807 0 -3856 -19z m7540 -762 c54 -95 181 -319 283 -498 101 -179 231 -406
287 -505 56 -99 178 -313 270 -475 92 -162 212 -373 266 -468 55 -95 99 -177
99 -182 0 -7 -1715 -10 -4886 -10 -2687 0 -4883 3 -4881 8 2 4 53 93 112 197
307 543 381 673 530 935 90 157 175 308 190 335 16 28 72 127 125 220 53 94
147 260 210 370 62 110 119 210 126 223 l14 22 3579 0 3578 0 98 -172z m-6743
-2773 c-5 -50 -42 -156 -85 -239 -152 -295 -433 -504 -757 -562 -96 -17 -284
-17 -380 0 -369 67 -674 322 -803 675 -37 100 -48 149 -33 155 6 2 472 4 1036
3 l1025 -2 -3 -30z m2730 -5 c-6 -81 -87 -264 -167 -375 -53 -74 -191 -208
-267 -258 -86 -56 -212 -114 -313 -142 -111 -32 -319 -44 -435 -26 -153 24
-310 84 -440 170 -85 56 -226 197 -286 287 -94 142 -175 349 -147 377 3 4 468
6 1032 5 l1026 -3 -3 -35z m2717 33 c29 -7 26 -31 -19 -156 -116 -319 -398
-571 -734 -658 -101 -26 -310 -36 -416 -20 -403 62 -732 343 -854 729 -31 98
-31 99 -8 105 27 7 2004 8 2031 0z m2757 -20 c-50 -172 -86 -255 -161 -367
-48 -73 -170 -199 -248 -255 -382 -279 -900 -273 -1277 15 -169 129 -306 328
-365 530 -14 47 -25 88 -25 90 0 2 468 4 1041 4 986 0 1040 -1 1035 -17z
m-7814 -795 c53 -72 165 -188 253 -261 203 -170 488 -302 760 -353 141 -27
471 -27 600 -1 235 48 488 156 670 287 108 77 267 228 338 322 29 37 55 68 58
68 4 0 26 -25 49 -56 188 -249 495 -466 798 -563 198 -64 276 -75 517 -75 192
0 234 3 325 22 323 70 593 211 818 427 61 58 135 137 164 176 29 38 56 69 59
69 3 0 32 -34 64 -75 73 -95 225 -240 335 -318 98 -70 315 -184 410 -214 203
-65 295 -83 483 -90 l147 -6 -2 -2246 -3 -2246 -2275 -3 c-1251 -1 -2285 0
-2297 3 l-23 5 -3 1593 -3 1592 -22 80 c-26 92 -85 221 -138 301 -112 168
-314 323 -502 384 -147 48 -181 50 -730 50 -476 0 -525 -2 -603 -19 -215 -50
-351 -123 -500 -270 -135 -134 -216 -282 -275 -506 -4 -16 -10 -745 -13 -1620
l-6 -1590 -475 0 -475 0 0 2245 0 2245 145 6 c163 7 312 34 452 80 304 101
590 299 779 540 35 43 65 79 69 79 3 0 27 -28 52 -62z m1431 -1772 c64 -33
144 -111 175 -172 43 -84 43 -90 40 -1674 l-3 -1515 -785 0 -785 0 -3 1539
c-2 1704 -7 1588 63 1693 40 61 108 114 180 143 48 18 72 19 555 17 l505 -2
58 -29z"
      />
      <path
        d="M7895 6284 c-295 -71 -513 -279 -597 -571 -23 -78 -23 -80 -23 -808
0 -824 -4 -778 80 -950 79 -163 197 -280 360 -360 179 -88 141 -85 1127 -85
941 0 931 -1 1081 56 180 69 362 244 434 419 36 87 52 148 63 235 13 112 13
1258 0 1370 -42 344 -277 610 -613 690 -79 19 -119 20 -967 19 -734 -1 -895
-3 -945 -15z m1833 -620 c33 -19 50 -39 68 -77 l24 -52 0 -630 c0 -592 -2
-632 -19 -670 -22 -47 -64 -89 -104 -104 -19 -8 -295 -11 -853 -11 -801 0
-826 1 -863 20 -47 23 -77 60 -92 109 -7 24 -9 251 -7 683 l3 646 30 38 c65
79 -3 74 934 74 l834 0 45 -26z"
      />
    </g>
  </svg>
)

Store.defaultProps = {
  width: 50,
  height: 50,
}

Store.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Store
