import { Component } from 'react'
import invariant from 'invariant'
import withAuth from './withAuth'

class AuthRefresh extends Component {
  componentDidMount() {
    this.checkSession()
  }

  componentDidUpdate(prevProps) {
    const { accessToken, expiresAt, idToken, refreshToken, provider } =
      this.props.auth.session
    const {
      accessToken: oldAccessToken,
      expiresAt: oldExpiresAt,
      idToken: oldIdToken,
      refreshToken: oldRefreshToken,
      provider: oldProvider,
    } = prevProps.auth.session
    if (
      accessToken === oldAccessToken &&
      expiresAt === oldExpiresAt &&
      idToken === oldIdToken &&
      refreshToken === oldRefreshToken &&
      provider === oldProvider
    ) {
      return
    }
    if (this.timer != null) {
      clearTimeout(this.timer)
    }

    if (this.props.auth.session != null) {
      this.checkSession()
    }
  }

  componentWillUnmount() {
    if (this.timer != null) {
      clearTimeout(this.timer)
    }
  }

  checkSession() {
    const { offset, auth } = this.props
    const { session } = auth

    if (session != null && session.expiresAt != null) {
      const expiresAt = session.expiresAt - offset
      const now = Date.now()

      if (now < expiresAt) {
        this.timer = setTimeout(this.renewToken, expiresAt - now)
      } else {
        this.renewToken()
      }
    }
  }

  renewToken() {
    const { client } = this.props.auth

    invariant(
      client,
      `AuthClient missing in context. ` +
        `Did you forget to wrap your app in AuthProvider?`
    )

    client.renewToken((err, result) => {
      if (err) {
        if (this.props.onError != null) {
          this.props.onError(err)
        }
      } else if (this.props.onSuccess != null) {
        this.props.onSuccess(result)
      }
    })
  }

  render() {
    return null
  }
}

AuthRefresh.defaultProps = {
  offset: 10000,
  onSuccess: undefined,
  onError: undefined,
}

export default withAuth(AuthRefresh)
