import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import LoadingIndicator from './loadingIndicator'

const StyledLoader = styled(LoadingIndicator)`
  transform: scale(0.7);
  color: ${theme('typography.storeAddress.color')};
  opacity: 0.5;
  margin-top: 25px;
  margin-right: 15px;
`

const StyledLoaderText = styled.div`
  color: ${theme('typography.storeAddress.color')};
  margin-top: 20px;
`

const InlineAlignedComponents = styled.div`
  display: flex;
  ${(props) =>
    props.inline
      ? ``
      : `
      flex-direction: column; 
      text-align: center;
      margin-top: 15px;
      `}
`

const Loader = (props) => (
  <>
    <InlineAlignedComponents inline={props.inline}>
      <StyledLoader size={props.size} duration={props.duration} />
      <StyledLoaderText inline={props.inline}>{props.text}</StyledLoaderText>
    </InlineAlignedComponents>
  </>
)

Loader.propTypes = {
  inline: PropTypes.string,
  size: PropTypes.string,
  duration: PropTypes.number,
  text: PropTypes.string,
}

Loader.defaultProps = {
  inline: null,
  size: null,
  duration: 3,
  text: null,
}

export default Loader
