import React from 'react'
import invariant from 'invariant'
import { withTheme, ThemeProvider } from 'styled-components'
import memoizeOne from 'memoize-one'
import isShallowEqual from '../../../utils/isShallowEqual'
import createMediaQueries from './createMediaQueries'
import BreakpointContext from './BreakpointContext'

const mapPropsToState = memoizeOne((props) => {
  const breakpoints = {
    ...props.theme.breakpoints,
    ...props.breakpoints,
  }

  invariant(
    breakpoints != null,
    '[BreakpointProvider] No breakpoints provided. ' +
      'Ensure you have a `breakpoints` object in your theme, ' +
      'or pass it explicitly to `BreakpointProvider`.'
  )

  const mediaQueries = createMediaQueries(breakpoints)
  const themeGrid = props.theme.grid != null ? props.theme.grid : {}

  return {
    context: {
      breakpoints,
      mediaQueries,
    },
    theme: {
      ...props.theme,
      breakpoints,
      media: mediaQueries,
      grid: {
        ...themeGrid,
        columns: props.columns != null ? props.columns : themeGrid.columns,
        gutter: props.gutter != null ? props.gutter : themeGrid.gutter,
        container: props.container || themeGrid.container,
      },
    },
  }
}, isShallowEqual)

const BreakpointProvider = (props) => {
  const state = mapPropsToState(props)

  return (
    <BreakpointContext.Provider value={state.context}>
      <ThemeProvider theme={state.theme}>{props.children}</ThemeProvider>
    </BreakpointContext.Provider>
  )
}

BreakpointProvider.defaultProps = {
  gutter: undefined,
  columns: undefined,
  breakpoints: undefined,
  container: undefined,
}

export default withTheme(BreakpointProvider)
