import { findServiceData, getDisplayData } from '../content'

const hasIntervals = (sbmBookingsByStoreId) =>
  sbmBookingsByStoreId &&
  sbmBookingsByStoreId.getSbmCalendar &&
  !!sbmBookingsByStoreId.getSbmCalendar.find(
    (booking) =>
      booking.intervals &&
      booking.intervals.find((interval) => interval.type === 'available')
  )

const hasMsBookingsIntervals = (msBookingsByStoreId) =>
  msBookingsByStoreId &&
  msBookingsByStoreId.getMsBookingsCalendar &&
  !!msBookingsByStoreId.getMsBookingsCalendar.find(
    (booking) =>
      booking.intervals &&
      booking.intervals.find((interval) => interval.type === 'available')
  )

export const SIMPLY_BOOK_ME = 'simplybookme'
export const MS_BOOKINGS = 'microsoftbookings'
export const CUSTOMER_FORM = 'customerform'

export const isSimplyBookMe = (store) => {
  if (!store) {
    return false
  }

  const hasNewSettings =
    store.bookingSystem != null && store.bookingSystem !== ''
  const hasOldSettings = !!store.hasSimplyBookMe

  let bookingSystem = null
  if (hasNewSettings && hasOldSettings) {
    bookingSystem = store.bookingSystem
  } else if (!hasNewSettings && hasOldSettings) {
    bookingSystem = SIMPLY_BOOK_ME
  }

  if (bookingSystem === SIMPLY_BOOK_ME) return true

  return false
}

export const isMsBookings = (store) => {
  if (!store) return false

  const hasNewSettings =
    store.bookingSystem != null && store.bookingSystem !== ''

  if (
    hasNewSettings &&
    store.bookingSystem === MS_BOOKINGS &&
    !!store.msBookingsStoreUniqueId
  ) {
    return true
  }

  return false
}

const getStoreState = (
  selectedStore,
  sbmBookingsByStoreId,
  msBookingsByStoreId
) => ({
  selected: selectedStore,
  bookings: {
    supported: isSimplyBookMe(selectedStore) || isMsBookings(selectedStore),
    none: !isSimplyBookMe(selectedStore) && !isMsBookings(selectedStore),
    intervals:
      (isSimplyBookMe(selectedStore) && hasIntervals(sbmBookingsByStoreId)) || // OR
      (isMsBookings(selectedStore) &&
        hasMsBookingsIntervals(msBookingsByStoreId)),
    loading:
      !!(sbmBookingsByStoreId && sbmBookingsByStoreId.loading) ||
      !!(msBookingsByStoreId && msBookingsByStoreId.loading),
  },
  meeting: {
    supported: true,
  },
})

const getStepsState = (store, service, slot, usePrepform) => ({
  meetingType: store.meeting.supported,
  calendar:
    (service &&
      service.serviceType === SIMPLY_BOOK_ME &&
      store.bookings.supported) ||
    (service &&
      service.serviceType === 'bookings' &&
      store.bookings.supported) || // TODO: remove this 'service.serviceType === 'bookings'' when changed in hercules.
    (service &&
      service.serviceType === MS_BOOKINGS &&
      store.bookings.supported),
  customerForm: (service && service.serviceType === CUSTOMER_FORM) || !!slot,
  prepform: service && usePrepform,
})

const wizardState = (
  bookingCompleted,
  prepCompleted,
  steps,
  loading,
  usePrepform
) => ({
  one: !bookingCompleted,
  two: !bookingCompleted && steps.meetingType,
  three: !bookingCompleted && !loading && steps.calendar,
  four: !bookingCompleted && !loading && steps.customerForm,
  five: !!(
    !!steps.prepform &&
    !loading &&
    !!bookingCompleted &&
    !prepCompleted &&
    !!usePrepform
  ),
  six: bookingCompleted && (prepCompleted || !steps.prepform),
})

const wizardStage = (
  bookingCompleted, // customer form
  prepCompleted, // prepform
  homeAppointment, // special case
  usePrepform // setting in epi if to use prepform
) => [
  {
    key: 0,
    active: !bookingCompleted && !prepCompleted,
    label: 'Boka Möte',
  },
  {
    key: 1,
    active: !!(
      bookingCompleted &&
      !prepCompleted &&
      !homeAppointment &&
      usePrepform
    ),
    label: 'Inför Mötet',
  },
  {
    key: 2,
    active:
      (bookingCompleted && prepCompleted) ||
      !usePrepform ||
      (homeAppointment && bookingCompleted),
    label: 'Klart',
  },
]

const wizardChange = ({
  selectedStore,
  selectedService,
  selectedSlot,
  bookingCompleted,
  prepCompleted,
  sbmBookingsByStoreId,
  msBookingsByStoreId,
  pageContent,
}) => {
  const store = getStoreState(
    selectedStore,
    sbmBookingsByStoreId,
    msBookingsByStoreId
  )

  const displayData = getDisplayData(pageContent.page)
  const serviceData = findServiceData(displayData, selectedService)
  const usePrepform = serviceData && serviceData.usePrepform

  const stepState = getStepsState(
    store,
    selectedService,
    selectedSlot,
    usePrepform
  )

  const step = wizardState(
    bookingCompleted,
    prepCompleted,
    stepState,
    store.bookings.loading,
    selectedService,
    selectedSlot,
    usePrepform
  )

  const stage = wizardStage(
    bookingCompleted,
    prepCompleted,
    selectedService && selectedService.id === 'home',
    usePrepform
  )

  return {
    step,
    stage,
    loading: store.bookings.loading && selectedService && selectedService.id,
  }
}

export default wizardChange
