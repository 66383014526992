import React from 'react'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

import Label from './label'

const CheckMarkSvg = (props) => (
  <svg viewBox="0 0 11 11" {...props}>
    <path
      d="M3.828 8.086L2.114 6.408A1.003 1.003 0 0 0 .533 7.643l2.522 2.469.027.033a1.003 1.003 0 0 0 1.558.142l6.018-7.706a1.003 1.003 0 1 0-1.443-1.394L3.828 8.086z"
      fill="currentColor"
    />
  </svg>
)

const CheckBoxLabel = styled(Label)`
  position: relative;
  display: inline-block;
  min-height: 19px;
  line-height: 19px;
  margin-right: 24px;
  padding-left: 27px;
  font-weight: ${prop('theme.forms.fontWeight')};
`

const CheckMark = styled.div`
  position: absolute;
  left: 0;
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 8px;
  border-radius: ${prop('theme.forms.checkbox.borderRadius')};
  border: 1px solid ${prop('theme.forms.colors.border')};
  background-color: ${prop('theme.forms.colors.background')};
  transition: background-color 0.1s ease-in;
  vertical-align: middle;

  ${(props) =>
    props.valid === false &&
    css`
      border: 1px solid ${prop('theme.forms.colors.invalidBorder')};
    `};
`

const CheckMarkIcon = styled(CheckMarkSvg)`
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  transform: scale(0);
  transition: transform 0.1s ease-in 0.1s;
`

const Input = styled.input.attrs({ type: 'checkbox' })`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${CheckMark} {
    border: 1px solid ${prop('theme.forms.colors.selected')};
    background-color: ${prop('theme.forms.colors.selected')};

    > ${CheckMarkIcon} {
      transform: scale(1);
      color: ${prop('theme.forms.colors.background')};
    }
  }

  &:focus + ${CheckMark} {
    border: 1px solid ${prop('theme.forms.colors.selected')};
  }

  &:focus:checked + ${CheckMark} {
    border: 1px solid ${prop('theme.forms.colors.border')};
  }
`

// type Props = {
//   label: ?string,
//   children: ?Node,
//   className: string,
// }

const CheckBox = ({ label, className, children, ...props }) => (
  <CheckBoxLabel className={className}>
    <Input {...props} />
    <CheckMark {...props}>
      <CheckMarkIcon />
    </CheckMark>
    {label || children}
  </CheckBoxLabel>
)

CheckBox.defaultProps = {
  label: null,
  children: null,
  className: '',
  valid: undefined,
}

export default CheckBox
