import { css } from 'styled-components'
import memoizeOne from 'memoize-one'
import isShallowEqual from '../../../utils/isShallowEqual'

const getSortedKeys = (obj) => Object.keys(obj).sort((a, b) => obj[a] - obj[b])

const createMinQueries = (keys, obj, opts) =>
  keys.reduce((acc, key) => {
    if (key !== opts.minKey) {
      const bp = obj[key]

      acc[key] = (...styles) => css`
        @media (min-width: ${bp}px) {
          ${css(...styles)};
        }
      `
    }

    return acc
  }, {})

const createMaxQueries = (keys, obj, opts) =>
  keys.reduce((acc, key, index) => {
    if (key !== opts.maxKey) {
      const nextKey = keys[index + 1]
      const bp = obj[nextKey] - 1

      acc[key] = (...styles) => css`
        @media (max-width: ${bp}px) {
          ${css(...styles)};
        }
      `
    }

    return acc
  }, {})

const createOnlyQueries = (keys, obj, opts) =>
  keys.reduce((acc, key, index) => {
    if (key === opts.minKey) {
      acc[key] = opts.maxQueries[key]
    } else if (key === opts.maxKey) {
      acc[key] = opts.minQueries[key]
    } else {
      const minBp = obj[key]
      const nextKey = keys[index + 1]
      const maxBp = obj[nextKey] - 1

      acc[key] = (...styles) => css`
        @media (min-width: ${minBp}px) and (max-width: ${maxBp}px) {
          ${css(...styles)};
        }
      `
    }

    return acc
  }, {})

const createMediaQueries = memoizeOne((bps) => {
  const keys = getSortedKeys(bps)
  const minKey = keys[0]
  const maxKey = keys[keys.length - 1]

  const minQueries = createMinQueries(keys, bps, { minKey })
  const maxQueries = createMaxQueries(keys, bps, { maxKey })
  const onlyQueries = createOnlyQueries(keys, bps, {
    minKey,
    minQueries,
    maxKey,
    maxQueries,
  })

  return { keys, min: minQueries, max: maxQueries, only: onlyQueries }
}, isShallowEqual)

export default createMediaQueries
