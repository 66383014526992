import React from 'react'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

import Label from './label'

const SelectLabel = styled(Label)`
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  height: ${prop('theme.forms.height')};
  border-top: 1px solid ${prop('theme.forms.colors.border')};
  border-bottom: 1px solid ${prop('theme.forms.colors.border')};
  border-right: 1px solid ${prop('theme.forms.colors.border')};
  border-left: 1px solid ${prop('theme.forms.colors.border')};
  padding: 0px 12px;
  line-height: ${prop('theme.forms.height')};
  margin-bottom: -1px;
  margin-right: -1px;
  color: ${prop('theme.forms.colors.color')};
  background-color: ${prop('theme.forms.colors.background')};
  z-index: 1;

  &:first-child {
    border-left: 1px solid ${prop('theme.forms.colors.border')};
    border-radius: ${prop('theme.forms.borderRadius')} 0 0
      ${prop('theme.forms.borderRadius')};
  }
  &:last-child {
    border-right: 1px solid ${prop('theme.forms.colors.border')};
    border-radius: 0 ${prop('theme.forms.borderRadius')}
      ${prop('theme.forms.borderRadius')} 0;
  }

  ${(props) =>
    props.width !== null &&
    css`
      text-align: center;
      width: calc(${props.width} + 1px});
      overflow: hidden;
    `};
`

const LabelSpan = styled.span`
  position: relative;
  z-index: 1;
  ${'' /* white-space: nowrap; */};
`

const StyledBG = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${prop('theme.forms.colors.background')};
  transition: background-color, transform 0.1s linear;
  z-index: 1;
`

const StyledInput = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + ${StyledBG} {
    background-color: ${prop('theme.forms.colors.selected')};
  }
  &:checked + ${StyledBG} + ${LabelSpan} {
    color: ${prop('theme.forms.colors.background')};
  }
`

const RadioButtonSquare = (props) => {
  const { name, value, label, width, ...rest } = props
  const input = (
    <StyledInput name={props.name} value={props.value} {...rest} data-cs-mask />
  )

  return (
    <SelectLabel width={width}>
      {input}
      <StyledBG />
      <LabelSpan>{props.label}</LabelSpan>
    </SelectLabel>
  )
}

RadioButtonSquare.defaultProps = {
  label: '',
  width: null,
}

export default RadioButtonSquare
