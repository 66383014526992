import React from 'react'

const CheckmarkAlternate = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    color={color}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="0.8"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" fill="none" />
    <polyline points="22 4 12 14.01 9 11.01" fill="none" />
  </svg>
)

export default CheckmarkAlternate
