import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Paragraph from '../../../../typography/paragraph'
import Link from '../../../../typography/link'
import { StoreItem } from '../store-item'

const StyledStoreList = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  text-decoration: none;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
`
const ShowMoreContainer = styled.div`
  text-align: center;
  position: relative;
  padding: 16px;
`
const ShowMoreLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-size: ${theme('showMore.fontSize')};
  margin-bottom: 4px;
  font-weight: ${theme('showMore.fontWeight')};
  color: ${theme('showMore.color')};
  text-transform: ${theme('showMore.textTransform')};
  letter-spacing: ${theme('showMore.letterSpacing')};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${theme('showMore.marginBottom')};
`

class StoreItems extends Component {
  state = {
    showNumberOfStores: this.props.pageSize,
  }
  showMoreStores = () => {
    this.setState({
      showNumberOfStores: this.state.showNumberOfStores + this.props.pageSize,
    })
  }

  render() {
    const { stores, showMoreLink, onChange } = this.props
    const showButton = stores.length > this.state.showNumberOfStores
    return (
      <>
        <StyledStoreList>
          {this.props.stores
            .slice(0, this.state.showNumberOfStores)
            .map((store) => (
              <StoreItem
                key={store.id}
                store={store}
                onChange={() => onChange(store)}
              />
            ))}
        </StyledStoreList>

        <ShowMoreContainer>
          <StyledParagraph>
            {showButton && (
              <ShowMoreLink onClick={this.showMoreStores}>
                {showMoreLink}
              </ShowMoreLink>
            )}
          </StyledParagraph>
        </ShowMoreContainer>
      </>
    )
  }
}

StoreItems.propTypes = {
  onChange: PropTypes.func.isRequired,
  stores: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  pageSize: PropTypes.number.isRequired,
  showMoreLink: PropTypes.string.isRequired,
}

export default StoreItems
