import Moment from 'moment'
import { extendMoment } from 'moment-range'
import settings from '../../components/settings'

const moment = extendMoment(Moment)

const getTimeOptions = (timesData) => {
  const timeOptions = timesData.bookingTimes.map((interval) => {
    const start = interval.start.substr(0, 5)
    return {
      value: start,
      label: start,
      disabled: interval.reserved,
    }
  })
  return timeOptions
}

const getLengthOptions = (timesData, selectedTime) => {
  const lengthOptions = []
  const day = '1970-01-01'
  const timeframe = settings.timeframe
  let temp = timeframe
  while (temp <= 180) {
    let disabled = false
    const potentialStart = moment(`${day} ${selectedTime}`)
    const potentialEnd = moment(`${day} ${selectedTime}`).add(temp, 'minutes')
    const potentialRange = moment.range(potentialStart, potentialEnd)
    const dayEnd = moment(
      `${day} ${timesData.bookingTimes[timesData.bookingTimes.length - 1].end}`
    )

    if (potentialEnd > dayEnd) {
      disabled = true
    }

    const reservedIntervals = timesData.bookingTimes.filter(
      (interval) => interval.reserved === true
    )

    if (reservedIntervals && reservedIntervals.length > 0) {
      let tempStart = reservedIntervals[0].start
      let tempEnd = null
      const reservedRanges = []
      reservedIntervals.forEach((interval, index) => {
        const nextInterval = reservedIntervals.find(
          (i) => i.start === interval.end
        )
        if (!nextInterval) {
          tempEnd = interval.end
          reservedRanges.push({ start: tempStart, end: tempEnd })
          if (index + 1 < reservedIntervals.length) {
            tempStart = reservedIntervals[index + 1].start
          }
        }
      })

      reservedRanges.forEach((range) => {
        const momentRange = moment.range(
          moment(`${day} ${range.start}`),
          moment(`${day} ${range.end}`)
        )
        if (momentRange.contains(potentialEnd, { exclusive: true })) {
          disabled = true
        }
        if (potentialRange.overlaps(momentRange)) {
          disabled = true
        }
      })

      if (
        potentialEnd.isAfter(
          moment(
            `${day} ${
              timesData.bookingTimes[timesData.bookingTimes.length - 1].end
            }`
          )
        )
      ) {
        disabled = true
      }
    }

    lengthOptions.push({ value: temp, label: `${temp} mins`, disabled })
    temp += timeframe
  }
  return lengthOptions
}

export const checkEmpty = (value, returnEmptyString) => {
  const returnValue = returnEmptyString ? '' : '-'
  if (!value) return returnValue
  if (value.length === 0) return returnValue
  return value
}

export const getNewsletterConsent = (newsletterConsent, translateFunc) => {
  if (!newsletterConsent) return translateFunc('no')
  return translateFunc('yes')
}

// TODO: Remove this when we confirm that subsystems are working properly
export const getServiceId = ({
  externalMeetingProvider,
  subsystem,
  serviceId,
}) => {
  let videoServiceId = settings.videoServiceId
  let storeServiceId = settings.storeServiceId
  let discoverServiceId = settings.discoverServiceId
  if (subsystem) {
    const mapping = settings.serviceMappings.find(
      (s) => s.subsystem === subsystem
    )
    if (mapping) {
      const videoService = mapping.services.find(
        (s) => s.from === videoServiceId
      )
      const storeService = mapping.services.find(
        (s) => s.from === storeServiceId
      )
      const discoverService = mapping.services.find(
        (s) => s.from === discoverServiceId
      )
      videoServiceId = videoService.to
      storeServiceId = storeService.to
      discoverServiceId = discoverService.to
    }
  } else {
    return serviceId
  }

  if (externalMeetingProvider === 'videoslot-appointment') {
    return videoServiceId
  }
  if (externalMeetingProvider === 'discoverslot-appointment') {
    return discoverServiceId
  }
  if (externalMeetingProvider === 'simplybookme-appointment') {
    return storeServiceId
  }
  return null
}

export const lengthFromEndTime = (startTime, endTime) => {
  const minutes = moment(`1970-01-01 ${endTime}`).diff(
    moment(`1970-01-01 ${startTime}`),
    'minutes'
  )
  return minutes
}

export const endTimeFromLength = (startTime, minutes) => {
  if (!minutes || minutes === '') {
    return null
  }
  const endTime = moment(`1970-01-01 ${startTime}`)
    .add(minutes, 'minutes')
    .format('HH:mm')
  return endTime
}

export const getOptions = ({
  storesData,
  servicesData,
  services,
  serviceProvidersData,
  timesData,
  selectedTime,
}) => {
  let serviceOptions = []
  if (
    servicesData &&
    servicesData.bookingServices &&
    servicesData.bookingServices.length > 0
  ) {
    serviceOptions = servicesData.bookingServices.map((i) => {
      const cmsService = services?.find((service) => service.id === i.id)
      return {
        value: i.id,
        label: (cmsService && cmsService.heading) || '',
        disabled: i.disabled,
      }
    })
  }
  serviceOptions.unshift({ value: '', label: '', disabled: false })

  let serviceProviderOptions = []
  if (
    serviceProvidersData &&
    serviceProvidersData.bookingServiceProviders &&
    serviceProvidersData.bookingServiceProviders.length > 0
  ) {
    serviceProviderOptions = serviceProvidersData.bookingServiceProviders.map(
      (i) => ({
        value: i.id,
        label: i.name,
      })
    )
  }
  serviceProviderOptions.unshift({ value: '', label: '', disabled: false })

  let storeOptions = []
  if (
    storesData &&
    storesData.searchOpenAuthStoresByAddress &&
    storesData.searchOpenAuthStoresByAddress.stores &&
    storesData.searchOpenAuthStoresByAddress.stores.length > 0
  ) {
    const orderedStores = storesData.searchOpenAuthStoresByAddress.stores.sort(
      (s1, s2) => {
        if (s1.store.storeName > s2.store.storeName) return 1
        if (s1.store.storeName < s2.store.storeName) return -1
        return 0
      }
    )
    storeOptions = orderedStores.map((i) => ({
      value: i.store.id,
      label: i.store.storeName,
      disabled: i.store.externalMeetingData.meetingData.simplyBookMeId === '0',
    }))
  }

  let timeOptions = []
  let lengthOptions = []
  let closed = false
  if (
    timesData &&
    timesData.bookingTimes &&
    timesData.bookingTimes.length > 0
  ) {
    timeOptions = getTimeOptions(timesData)
    lengthOptions = getLengthOptions(timesData, selectedTime)
  } else {
    closed = true
  }
  timeOptions.unshift({ value: '', label: '', disabled: false })
  lengthOptions.unshift({ value: '', label: '', disabled: false })

  return {
    storeOptions,
    serviceOptions,
    serviceProviderOptions,
    timeOptions,
    lengthOptions,
    closed,
    storesDataRefetch: storesData.refetch,
    servicesDataRefetch: servicesData && servicesData.refetch,
    serviceProvidersDataRefetch:
      serviceProvidersData && serviceProvidersData.refetch,
  }
}

export const getLocationId = (storeId, storesData) => {
  let locationId = null
  if (
    storesData.searchOpenAuthStoresByAddress &&
    storesData.searchOpenAuthStoresByAddress.stores
  ) {
    const store =
      storesData.searchOpenAuthStoresByAddress &&
      storesData.searchOpenAuthStoresByAddress.stores.find(
        (s) => parseInt(s.store.id, 10) === parseInt(storeId, 10)
      )
    if (store && store.store && store.store.externalMeetingData) {
      locationId = parseInt(
        store.store.externalMeetingData.meetingData.simplyBookMeId,
        10
      )
    }
  }
  return locationId
}

export const checkMeetingHasPassed = (meetingDate, meetingTime) => {
  const now = new Date()
  const timeAndDate = moment(`${meetingDate} ${meetingTime}`)
  const hasPassed = now > timeAndDate.toDate()

  return hasPassed
}
