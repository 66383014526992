import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { prop, theme } from 'styled-tools'
import { compose, lifecycle } from 'recompose'
import { getApolloContext } from '@apollo/client'
import { withAuth } from '../auth'

import features from '../features'
import { Overlay } from '../overlay'

import settings from '../settings'
import Textarea from '../customer-form/textarea'
import Checkbox from '../customer-form/checkbox'
import Button from '../button/baseButton'
import ArrowLeftIcon from '../icons/arrow-left'
import ArrowRightIcon from '../icons/arrow-right'

import { storeType, valuesType } from '../../../types'
import {
  validateAddress,
  validateEmail,
  validateName,
  validatePostalCode,
  validatePhoneNumber,
} from '../../../utils/validation'
import tracker from '../../../utils/tracker'
import { createBooking } from '../../containers/bada/actions'
import { findServiceData, getDisplayData } from '../../containers/bada/content'
import Loader from '../loader/Loader'
import { bookingForm, formHandlers } from './bookingHandler'
import InputField from './InputField'
import TermsOverlay from './TermsOverlay'
import Error from '../error/Error'
import { getTranslator } from '../../../utils/translation'
import * as badaGatewayApiGraphQl from '../../../utils/badaGatewayApiGraphQl'
import LocationIcon from '../icons/location'
import { promiseRetry } from '../../../utils/promiseRetry'
import { withGoogleReCaptcha, RecaptchaMessage } from '../recaptcha'

const CustomerForm = styled.form`
  font-family: ${theme('typography.paragraph.fontFamily')};
`

const StyledInputField = styled(InputField)`
  font-size: ${theme('typography.paragraph.fontSize') || '16px'};
  height: ${theme('forms.fields.height')};
  border: 1px solid ${theme('forms.colors.border')};
  border-radius: ${theme('forms.borderRadius')};
`
const AddressInputField = styled(StyledInputField)`
  text-overflow: ellipsis;
`

const StyledTextarea = styled(Textarea)`
  textarea& {
    border: 1px solid ${theme('forms.colors.border')};

    &:focus,
    &:active {
      outline: none;
      border: 1px solid ${prop('theme.forms.colors.activeBorder')};
      box-shadow: ${theme('colors.accentShadow')};
    }
    &::placeholder {
      color: ${theme('forms.colors.placeholder')};
    }
  }

  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: '16px';

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px
      ${prop('theme.forms.colors.prefilledBackground')} inset !important;
  }
`

const StyledLabel = styled.label`
  display: block;
  color: ${theme('colors.darkGrey')};
  font-size: ${theme('forms.label.fontSize')};
  font-weight: ${theme('forms.label.fontWeight')};
  line-height: ${theme('forms.label.lineHeight')};
  margin-bottom: ${theme('forms.label.marginBottom')};
`

const OptinText = styled.span`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('forms.optinText.fontSize')};
  font-weight: ${theme('forms.optinText.fontWeight')};
  line-height: 22px;
  color: ${theme('colors.darkGrey')};
`

const TermsConditions = styled.span`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('forms.termsConditions.fontSize')};
  font-weight: ${theme('forms.termsConditions.fontWeight')};
  font-weight: ${theme('forms.termsConditions.fontWeight')};
  color: ${theme('forms.termsConditions.color')};
  font-weight: ${theme('forms.termsConditions.lineHeight')};
`

const TermsConditionsLink = styled.span`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('forms.termsConditions.fontSize')};
  font-weight: ${theme('forms.termsConditions.fontWeight')};
  font-weight: ${theme('forms.termsConditions.fontWeight')};
  color: ${theme('forms.termsConditions.color')};
  font-weight: ${theme('forms.termsConditions.lineHeight')};
  border-bottom: ${theme('forms.label.link.borderBottom')};
  border-bottom-color: ${theme('forms.label.link.borderBottomColor')};
  padding-bottom: ${theme('forms.label.link.paddingBottom')};
  cursor: pointer;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-top: ${prop('theme.button.marginTop')};
  font-size: ${theme('button.fontSize', '12px')};
  font-weight: ${theme('button.fontWeight')};
  border: ${theme('button.border')};
  text-transform: ${theme('button.textTransform')};
  min-height: ${theme('button.height')};
  background-color: ${theme('button.backgroundColor')};

  border-radius: ${prop('theme.button.borderRadius')};
  line-height: ${prop('theme.button.lineHeight')};
  letter-spacing: ${prop('theme.button.letterSpacing')};
  padding: ${theme('forms.button.padding')};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.media.min.sm`
    width: ${theme('button.blockButtonWidth')};
  `}

  &:not([disabled]):hover {
    ${css(theme('buttonHover'))}
    &:before {
      ${css(theme('buttonHoverBefore'))}
    }
  }

  ${(props) =>
    props.incomplete &&
    css`
      opacity: 0.5;
    `}
`

const StyledCheckboxContainer = styled.div`
  margin-top: 16px;

  input[type='checkbox']:checked + div {
    border-color: ${theme('forms.colors.checkboxBorder')};
    svg {
      color: ${theme('forms.colors.svgIconColor')};
    }
  }
`
const StyledCheckBox = styled(Checkbox)`
  > div {
    border-color: ${theme('forms.colors.checkboxBorder')};
  }
`

const SplitRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${(p) => p.theme.media.min.md`
    flex-direction: row;
  `}
`

const Row = styled.div`
  flex-basis: 100%;
  ${(p) => p.theme.media.min.md`
    flex-basis: 48%;
  `}
`

const AddressInputWrapper = styled.div`
  margin-bottom: -14px;
  position: relative;
`

/* eslint-disable prefer-template */
const AddressSearchResult = styled.ul`
  font-size: ${theme('typography.paragraph.fontSize') || '16px'};
  position: absolute;
  z-index: 2;
  background-color: #fff;
  padding: 0;
  margin: 0;
  margin-top: ${(p) => '-' + p.theme.forms.marginBottom};
  width: 100%;
  list-style-type: none;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.forms.colors.border};
  max-height: 400px;
  overflow-y: scroll;
  top: 65px;
`

/* eslint-enable prefer-template */

const AddressSearchResultItem = styled.li`
  cursor: pointer;
  padding: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: space-between;

  &:hover {
    border-color: ${(p) => p.theme.forms.colors.border};
  }

  :nth-child(even) {
    background-color: ${(p) => p.theme.colors.secondaryBackgroundColor};
  }

  svg {
    display: flex;
    align-self: center;
  }
`

const StyledLocationWidthIcon = styled.div`
  display: flex;

  svg {
    margin-right: 7px;
    display: flex;
    align-self: flex-start;
  }
`

const ctx = getApolloContext()

const ADDRESS_RESULT_COUNT = 50

const getAddressQuery = (addressData) => {
  if (!addressData || typeof addressData !== 'object') return ''

  const { line1, line2, line3, company, city, postalCode } = addressData

  const postfix = `${city}, ${postalCode}`

  const prefix = [line1, line2, line3].filter((x) => !!x).join(', ')

  if (prefix) return `${prefix}, ${postfix}`

  if (company) {
    const result = `${company}, ${postfix}`
    return result
  }

  return postfix
}

// need to reset assigned address
// in order to force validation
const resetAddress = (values) => {
  if (!values && typeof values === 'object') return

  /* eslint-disable no-param-reassign */
  values.address = ''
  values.address2 = ''
  values.address3 = ''
  values.city = ''
  values.zipcode = ''
  /* eslint-enable no-param-reassign */
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overlayOpen: false,
      valid: null,
      addressQuery: '',
      addressQueryTimeout: null,
      addressResult: null,
      addressDrillDown: false,
      addressError: '',
    }
    this.nameInputRef = React.createRef()
    this.validEmail = validateEmail(this.props.values.email)
    this.validName = validateName(this.props.values.name)
    this.validAddress = validateAddress(this.props.values.address)
    this.trackInput = this.trackInput.bind(this)
    this.trackSubmit = this.trackSubmit.bind(this)
    this.closeOverlay = this.closeOverlay.bind(this)
    this.openOverlay = this.openOverlay.bind(this)
  }

  componentDidMount() {
    setTimeout(
      () => this.nameInputRef.current && this.nameInputRef.current.focus(),
      1200
    )
    this.props.updateUiState({ customerForm: { touched: false } })
  }

  closeOverlay() {
    this.setState({ overlayOpen: false })
  }

  openOverlay(event) {
    event.preventDefault()
    this.setState({ overlayOpen: true })
  }

  trackInput(event) {
    if (event && event.target && event.target.value) {
      tracker.trackFilled('BADA 2.0 dataform', event.target.name)
      this.props.updateUiState({ customerForm: { touched: true } })
    }
  }

  trackSubmit() {
    setTimeout(() => {
      if (Object.keys(this.props.errors).length > 0) {
        tracker.trackClickFail(
          'BADA 2.0 submit form',
          this.props.selectedStore.name
        )
      } else {
        tracker.trackClickSuccess(
          'BADA 2.0 submit form',
          this.props.selectedStore.name
        )
        tracker.trackClick(
          'BADA 2.0 newsletter',
          this.props.values.newsletterConsent ? 'true' : 'false'
        )
      }
    }, 100)
  }

  checkError(id) {
    if (this.props.errors[id] && this.props.touched[id]) {
      return this.errorMessages[this.props.errors[id]]
    }
    return ''
  }

  checkValid(id) {
    if (this.props.touched[id]) {
      if (id === 'phone') {
        return validatePhoneNumber(
          this.props.values.phone,
          this.props.brandCountry
        )
      }
      return typeof this.props.errors[id] === 'undefined'
    }
    return undefined
  }

  checkRequiredCheckbox(id) {
    if (this.props.touched[id]) {
      return typeof this.props.errors[id] === 'undefined'
    }
    return undefined
  }

  validateValuesIncludingAddress({
    name,
    phone,
    email,
    address,
    zipcode,
    city,
  }) {
    const inValidName = !name || !validateName(name)
    const inValidEmail = !email || !validateEmail(email)
    const inValidAddress =
      !address ||
      !validatePostalCode(zipcode, this.props.brandCountry) ||
      !city ||
      !validateAddress(address)
    const inValidPhone =
      phone.length === 0 || !validatePhoneNumber(phone, this.props.brandCountry)
    const inComplete =
      inValidName || inValidEmail || inValidAddress || inValidPhone

    return inComplete
  }

  static validateValues({ name, phone, email }) {
    const inValidName = !name || !validateName(name)
    const inValidEmail = !email || !validateEmail(email)
    const inValidPhone =
      phone.length === 0 || !validatePhoneNumber(phone, this.props.brandCountry)
    const inComplete = inValidName || inValidEmail || inValidPhone

    return inComplete
  }

  searchAddress(query, refinementId) {
    this.setState({ addressQuery: query, addressDrillDown: !!refinementId })
    clearTimeout(this.state.addressQueryTimeout)
    if (!query || !query.trim()) {
      this.setState({ addressResult: null, addressQueryTimeout: null })
      return
    }

    this.setState({
      addressQueryTimeout: setTimeout(() => {
        // the 'addressCountry' (only used by Magnet) can be removed when the setting is put in the bada config
        let countries = settings.addressCountry || this.props.brandCountry
        if (typeof countries === 'string') {
          // wraps in array if the 'addressCountry' setting was a string, e.g. "SE" vs ["GB", "IM"]
          countries = [countries]
        }
        promiseRetry(
          badaGatewayApiGraphQl.searchAddresses(
            this.context.client,
            query,
            refinementId,
            ADDRESS_RESULT_COUNT,
            countries
          ),
          2
        )
          .then((addresses) => {
            if (this.state.addressQuery) {
              this.setState({ addressResult: addresses, addressError: '' })
            }
          })
          .catch((e) => {
            const message =
              (e && e.message) || 'An error occured when getting addresses'
            // if address is null replace it with address query
            if (!this.props.values.address)
              this.props.values.address = this.state.addressQuery
            this.setState({ addressError: message, addressResult: null })
          })
          .finally(() => this.setState({ addressQueryTimeout: null }))
      }, 500),
    })
  }

  emitFormikValue(name, value) {
    this.props.handleTextChange({
      target: { name, value },
    })
  }

  selectAddress(address) {
    if (address.type !== badaGatewayApiGraphQl.ADDRESS_ITEM_TYPE) {
      this.searchAddress(this.state.addressQuery, address.id)

      return
    }

    promiseRetry(
      badaGatewayApiGraphQl.retrieveAddress(this.context.client, address.id),
      2
    )
      .then((addressData) => {
        if (!addressData.line1 && !addressData.line2 && !addressData.line2) {
          // this is used for companies that dont have any address lines
          this.emitFormikValue(
            'address',
            addressData.company || addressData.city
          )
        } else {
          this.emitFormikValue('address', addressData.line1)
          this.emitFormikValue('address2', addressData.line2)
          this.emitFormikValue('address3', addressData.line3)
        }

        this.emitFormikValue('zipcode', addressData.postalCode)
        this.emitFormikValue('city', addressData.city)
        this.emitFormikValue('country', addressData.countryCode)

        this.setState({
          addressQuery: getAddressQuery(addressData),
          addressResult: null,
          addressError: '',
        })
      })
      .catch((e) => {
        const message =
          (e && e.message) || 'An error occured when selecting the addresses'
        // if address is null replace it with address query
        if (!this.props.values.address)
          this.props.values.address = this.state.addressQuery
        this.setState({ addressError: message, addressResult: '' })
      })
  }

  render() {
    const {
      auth,
      values,
      handleTextChange,
      handleCheckboxChange,
      handleSubmit,
      isSubmitting,
      pageContent,
      errors,
      selectedService,
      intl,
    } = this.props

    const displayData = getDisplayData(pageContent)
    const serviceData = findServiceData(displayData, selectedService)

    const t = getTranslator(intl, 'app.form')
    const generalText = getTranslator(intl)

    const showAddressCmsSetting = serviceData && serviceData.showAddressField
    const showAddressInputs =
      (showAddressCmsSetting && !features.requireCustomerAddress) ||
      (features.requireCustomerAddress && this.state.addressError)

    return (
      <CustomerForm
        onSubmit={handleSubmit}
        data-cy="CustomerForm"
        noValidate
        onKeyPress={(event) => {
          if (event.key === 'Enter' && event.target.type !== 'textarea')
            event.preventDefault()
        }}
      >
        {serviceData.formRequiredFields &&
          serviceData.formRequiredFields.length > 0 && (
            <div>* {serviceData.formRequiredFields}</div>
          )}
        {!serviceData.formRequiredFields &&
          displayData.formRequiredFields &&
          displayData.formRequiredFields.length > 0 && (
            <div>* {displayData.formRequiredFields}</div>
          )}
        <br />
        <StyledLabel htmlFor="name">
          {serviceData.formName || displayData.formName}
        </StyledLabel>
        <StyledInputField
          id="name"
          name="name"
          type="text"
          value={values.name}
          onChange={handleTextChange}
          onBlur={this.trackInput}
          valid={this.checkValid('name')}
          error={t('name.error')}
          placeholder={t('name.placeholder')}
          inputRef={this.nameInputRef}
        />

        <StyledLabel htmlFor="email">
          {serviceData.formEmail || displayData.formEmail}
        </StyledLabel>
        <StyledInputField
          id="email"
          name="email"
          type="email"
          value={values.email}
          onChange={handleTextChange}
          onBlur={this.trackInput}
          valid={this.checkValid('email')}
          error={t('email.error')}
          placeholder={t('email.placeholder')}
          disabled={
            features.useLoggedInCustomerData &&
            auth &&
            auth.status === 'authenticated' &&
            !auth.profile?.roles?.includes('staff') &&
            this.validEmail
          }
        />
        <StyledLabel htmlFor="phone">
          {serviceData.formPhone || displayData.formPhone}
        </StyledLabel>
        <StyledInputField
          id="phone"
          name="phone"
          type="tel"
          value={values.phone}
          onChange={(e) => {
            handleTextChange(e)
          }}
          onBlur={this.trackInput}
          valid={this.checkValid('phone')}
          placeholder={t('phone.placeholder')}
          error={
            errors.phone === 'required' ? t('phone.missing') : t('phone.error')
          }
        />
        {this.state.addressError && (
          <Error
            header={generalText('app.error.title')}
            message={
              generalText('app.form.address-search.error-message') ||
              this.state.addressError
            }
          />
        )}
        {features.requireCustomerAddress && !this.state.addressError && (
          <>
            <StyledLabel>
              {serviceData.formAddress ||
                generalText('app.form.address-search.label')}
            </StyledLabel>
            <AddressInputWrapper>
              <AddressInputField
                autocomplete="address"
                id="address"
                value={this.state.addressQuery}
                onFocus={(e) => this.searchAddress(e.target.value)}
                onChange={(e) => {
                  resetAddress(values)
                  errors.address = 'Select an address'
                  this.searchAddress(e.target.value)
                }}
                onBlur={this.trackInput}
                valid={this.checkValid('address')}
                placeholder={generalText('app.form.address-search.placeholder')}
                error={t('address.error')}
              />
              {this.state.addressQueryTimeout && (
                <AddressSearchResult>
                  <li>
                    <Loader size="small" duration={3} />
                  </li>
                </AddressSearchResult>
              )}
              {this.state.addressResult && !this.state.addressQueryTimeout && (
                <AddressSearchResult>
                  {this.state.addressDrillDown && (
                    <AddressSearchResultItem
                      onClick={() =>
                        this.searchAddress(this.state.addressQuery)
                      }
                    >
                      <ArrowLeftIcon width={13} height={13} />
                    </AddressSearchResultItem>
                  )}
                  {this.state.addressResult.map((address) => (
                    <AddressSearchResultItem
                      key={address.id}
                      onClick={() => this.selectAddress(address)}
                    >
                      {address.type ===
                      badaGatewayApiGraphQl.ADDRESS_ITEM_TYPE ? (
                        <StyledLocationWidthIcon>
                          <LocationIcon width={20} height={20} /> {address.text}
                          , {address.description}
                        </StyledLocationWidthIcon>
                      ) : (
                        <>
                          {address.text}
                          <ArrowRightIcon width={13} height={13} />
                        </>
                      )}
                    </AddressSearchResultItem>
                  ))}
                </AddressSearchResult>
              )}
            </AddressInputWrapper>
          </>
        )}
        {showAddressInputs && (
          <>
            <StyledLabel htmlFor="address">
              {serviceData.formAddress || displayData.formAddress}
            </StyledLabel>
            <StyledInputField
              id="address"
              name="address"
              type="text"
              value={values.address}
              onChange={handleTextChange}
              onBlur={this.trackInput}
              valid={this.checkValid('address')}
              placeholder={t('address.placeholder')}
              error={t('address.error')}
            />
            <SplitRow>
              <Row>
                <StyledLabel htmlFor="zipcode">
                  {t('zipcode.label')}
                </StyledLabel>
                <StyledInputField
                  id="zipcode"
                  name="zipcode"
                  type="text"
                  value={values.zipcode}
                  onChange={handleTextChange}
                  onBlur={this.trackInput}
                  valid={this.checkValid('zipcode')}
                  placeholder={t('zipcode.placeholder')}
                  error={t('zipcode.error')}
                />
              </Row>
              <Row>
                <StyledLabel htmlFor="city">{t('city.label', '')}</StyledLabel>
                <StyledInputField
                  id="city"
                  name="city"
                  type="text"
                  value={values.city}
                  onChange={handleTextChange}
                  onBlur={this.trackInput}
                  valid={this.checkValid('city')}
                  placeholder={t('city.placeholder', '')}
                  error={t('city.error')}
                />
              </Row>
            </SplitRow>
          </>
        )}

        {serviceData && serviceData.showTextareaField && (
          <>
            <StyledLabel htmlFor="comments">
              {serviceData.formTextarea || displayData.formTextarea}
            </StyledLabel>
            <StyledTextarea
              name="comments"
              placeholder={
                serviceData.formTextareaPlaceholder ||
                displayData.formTextareaPlaceholder
              }
              onBlur={this.trackInput}
              value={values.comments}
              onChange={handleTextChange}
            />
          </>
        )}
        {serviceData.formOptin && serviceData.formOptin.length > 0 && (
          <StyledCheckboxContainer>
            <StyledCheckBox
              name="newsletterConsent"
              checked={values.newsletterConsent}
              onChange={handleCheckboxChange}
            >
              <OptinText>{serviceData.formOptin}</OptinText>
            </StyledCheckBox>
          </StyledCheckboxContainer>
        )}
        {!serviceData.formOptin &&
          displayData.formOptin &&
          displayData.formOptin.length > 0 && (
            <StyledCheckBox
              name="newsletterConsent"
              checked={values.newsletterConsent}
              onChange={handleCheckboxChange}
            >
              <OptinText>{displayData.formOptin}</OptinText>
            </StyledCheckBox>
          )}
        <StyledButton
          block
          type="submit"
          disabled={isSubmitting}
          onClick={this.trackSubmit}
          size="large"
        >
          {serviceData.formSubmitButtonText || displayData.formSubmitButtonText}
        </StyledButton>
        <br />
        {features.useRecaptcha && <RecaptchaMessage />}
        {displayData.showTermsAndConditions && (
          <>
            <TermsConditions>
              {displayData.termsAndConditionsText}
            </TermsConditions>
            &nbsp;
            <TermsConditionsLink onClick={this.openOverlay}>
              {displayData.termsAndConditionsLinkLabel}
            </TermsConditionsLink>
          </>
        )}
        {isSubmitting && <Loader duration={3} />}
        {displayData.showTermsAndConditions && (
          <Overlay
            open={this.state.overlayOpen}
            handleChange={this.closeOverlay}
            setClosed={this.closeOverlay}
            render={(overlayProps) => (
              <TermsOverlay pageContent={displayData} {...overlayProps} />
            )}
          />
        )}
      </CustomerForm>
    )
  }
}

Form.contextType = ctx

Form.propTypes = {
  isSubmitting: PropTypes.bool,
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  meetingType: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  updateUiState: PropTypes.func.isRequired,
  values: valuesType.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isBooking: PropTypes.bool,
  selectedStore: storeType,
  selectedSlot: PropTypes.shape(),
  selectedService: PropTypes.shape(),
  pageContent: PropTypes.shape(),
}

Form.defaultProps = {
  meetingType: null,
  isSubmitting: false,
  errors: null,
  touched: null,
  selectedSlot: null,
  isBooking: false,
  pageContent: {},
  selectedStore: null,
  selectedService: null,
  auth: null,
}

const enhance = compose(
  injectIntl,
  withAuth,
  createBooking,
  withGoogleReCaptcha,
  bookingForm,
  formHandlers,
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        this.props.selectedStore.id !== prevProps.selectedStore.id ||
        this.props.selectedService.id !== prevProps.selectedService.id ||
        this.props.selectedService.serviceType !==
          prevProps.selectedService.serviceType
      ) {
        this.props.updateUiState({ customerForm: { touched: false } })
        this.props.resetForm()
      }
      const { isSubmitting, isValidating, errors } = prevProps

      const errorKeys = Object.keys(errors)
      if (errorKeys && errorKeys.length > 0 && isSubmitting && !isValidating) {
        const element = document.getElementById(errorKeys[0])
        if (element) {
          element.focus()
        }
      }
    },
  })
)

export default enhance(Form)
