import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import useTranslation from '../../../utils/translation'

const Container = styled.div`
  max-width: 550px;
  margin: 0px auto;
`

const Heading = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('cancel.step3.heading.fontFamily')};
  text-align: center;
  margin: ${theme('cancel.step3.heading.margin')};
  max-width: 475px;
  font-size: ${theme('cancel.step3.heading.fontSize.mobile')};
  line-height: ${theme('cancel.step3.heading.lineHeight.mobile')};
  font-weight: ${theme('cancel.step3.heading.fontWeight')};
  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('cancel.step3.heading.lineHeight.desktop')};
    line-height: ${theme('cancel.step3.heading.lineHeight.desktop')};
  `}
`

const Preamble = styled.p`
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  max-width: 475px;
  margin: 20px auto 40px auto;
  font-size: 18px;
`

const Link = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  background-color: ${theme('cancel.step3.link.backgroundColor')};
  color: #ffffff;
  text-decoration: none;
  padding: ${(props) =>
    theme('cancel.step3.link.padding')(props) || '13px 30px 13px 30px'};
  font-size: 16px;
  border-radius: ${theme('cancel.step3.link.borderRadius')};
  width: 100%;
  font-weight: ${(props) =>
    theme('cancel.step3.link.fontWeight')(props) || 500};
  text-transform: ${(props) =>
    theme('cancel.step3.link.textTransform')(props) || 'none'};
  display: block;
  text-align: center;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`
const CancelStep3 = () => {
  const t = useTranslation('cancel.step3')
  const store = new URLSearchParams(window.location.search).get('store')
  const isStore = !!store

  return (
    <Container>
      <Heading>{t(`${isStore ? 'store-' : ''}heading`)}</Heading>
      <Preamble>{t('preamble')}</Preamble>
      <Link href={window.location.origin}>{t('buttonText')}</Link>
    </Container>
  )
}

export default CancelStep3
