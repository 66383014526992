import * as React from 'react'
import { setDisplayName, wrapDisplayName } from 'recompose'
import BreakpointContext from './BreakpointContext'

const withBreakpoints = (BaseComponent) => {
  const baseFactory = React.createFactory(BaseComponent)
  const breakpointsFactory = React.createFactory(BreakpointContext.Consumer)

  const WithBreakpoints = (baseProps) =>
    breakpointsFactory({
      children: (props) => baseFactory({ ...baseProps, ...props }),
    })

  if (process.env.NODE_ENV !== 'production') {
    return setDisplayName(wrapDisplayName(BaseComponent, 'withBreakpoints'))(
      WithBreakpoints
    )
  }

  return React.forwardRef((props, ref) => (
    <WithBreakpoints {...props} ref={ref} />
  ))
}

export default withBreakpoints
