import { css } from 'styled-components'

const fontUrlCloudinary =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1651582419/Unoform/fonts/'

const unoformFonts = css`
  @font-face {
    font-family: 'HelveticaNeueW02';
    src: url(${fontUrlCloudinary}775a65da-14aa-4634-be95-6724c05fd522.woff)
      format('woff');
    font-weight: 100;
  }

  @font-face {
    font-family: 'HelveticaNeueW02';
    src: url(${fontUrlCloudinary}9a2e4855-380f-477f-950e-d98e8db54eac.woff)
      format('woff');
    font-weight: 300;
  }

  @font-face {
    font-family: 'HelveticaNeueW02';
    src: url(${fontUrlCloudinary}5b4a262e-3342-44e2-8ad7-719998a68134.woff)
      format('woff');
    font-weight: 500;
  }
`

export default unoformFonts
