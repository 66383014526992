const toRgba = (hexColor, alpha) => {
  const color =
    hexColor.charAt(0) === '#'
      ? hexColor.substring(1, hexColor.length)
      : hexColor

  let redHex
  let greenHex
  let blueHex

  // TODO: support other lenghts
  if (color.length !== 3 && color.length !== 6) {
    throw new Error(
      'Unsupported color code! Please provide a six or three digit hexadecimal color code.'
    )
  }

  if (color.length === 3) {
    redHex = color.charAt(0) + color.charAt(0)
    greenHex = color.charAt(1) + color.charAt(1)
    blueHex = color.charAt(2) + color.charAt(2)
  } else {
    redHex = color.charAt(0) + color.charAt(1)
    greenHex = color.charAt(2) + color.charAt(3)
    blueHex = color.charAt(4) + color.charAt(5)
  }

  const red = parseInt(redHex, 16)
  const green = parseInt(greenHex, 16)
  const blue = parseInt(blueHex, 16)

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

module.exports = {
  toRgba,
}
