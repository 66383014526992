import { createGlobalStyle } from 'styled-components'
import { basePixelSize } from './baseTheme'

const gutterWidth = '15px'

const colors = {
  black: '#1A1919',
  middleGrey: '#8D8D8D',
  darkGrey: '#666666',
  blue: '#37559C',
  grey: '#DDDCDB',
  lightGrey: '#f3f3f3',
  borderGrey: '#E2E3DF',
  offWhite: '#F9F9F9',
  white: '#fff',
  yellow: '#E5C936',
  green: '#7DB552',
}

const contextualColors = {
  primary: colors.black,
  secondary: colors.middleGrey,
  backgroundPrimary: colors.white,
  backgroundDark: colors.black,
  backgroundLight: colors.offWhite,
  backgroundSecondary: colors.lightGrey,
  borderColor: colors.borderGrey,
  paletteColor: colors.middleGrey,
  facebook: colors.blue,
  footerBackground: colors.grey,
  keyboardBackground: colors.grey,
  tooltip: colors.yellow,
  step: colors.darkGrey,
  iconColor: colors.black,
}

const fonts = {
  base: 'Apercu,arial,sans-serif',
  headline: 'Apercu,arial,sans-serif',
  subHeadline: 'Apercu,arial,sans-serif',
  paragraph: 'Apercu,arial,sans-serif',
}

const baseTypography = {
  fontFamily: fonts.base,
  headlineFontFamily: fonts.headline,
  lineHeight: 1.5,
  marginBottom: '1px',
}

const typography = {
  pageTitle: {
    fontFamily: baseTypography.headlineFontFamily,
    fontWeight: 400,
    fontSize: '28px',
    fontSizeLarge: '40px',
    lineHeight: baseTypography.lineHeight,
    lineHeightLarge: '42px',
    marginBottom: baseTypography.marginBottom,
    textTransform: 'uppercase',
    letterSpacing: '3px',
  },
  headline: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '16px',
    fontSizeLarge: '25px',
    lineHeight: baseTypography.lineHeight,
    marginBottom: '5px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: contextualColors.primary,
  },
  subHeadline: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '18px',
    fontSizeLarge: '24px',
    lineHeight: baseTypography.lineHeight,
    marginBottom: '0.5px',
  },
  preamble: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: 1.375,
    fontSizeDesktop: '15px',
    lineHeightDesktop: 1.44,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    color: colors.darkGrey,
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '14px',
    fontSizeLarge: '15px',
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
    color: colors.darkGrey,
  },
  pageDescription: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '18px',
    fontSizeLarge: '20px',
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
  },
}

const button = {
  borderRadius: 0,
  fontFamily: baseTypography.fontFamily,
  fontSize: '14px',
  fontWeight: '700',
  textTransform: 'uppercase',
  letterSpacing: '1.2px',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.black,
    secondaryColor: colors.white,
    secondaryBackground: colors.darkGrey,
  },
  margin: '0',
  siblingMargin: gutterWidth,
}

const forms = {
  colors: {
    selected: colors.black,
  },
}

const tooltip = {
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.green,
  },
}

const GlobalStyles = createGlobalStyle`
  :root {
    font: 400 ${basePixelSize}px/1.43 "Apercu", Arial, sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Apercu", Arial, sans-serif;
  }
`

const theme = {
  basePixelSize,
  GlobalStyles,
  typography,
  fonts,
  tooltip,
  button,
  forms,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
