import styled from 'styled-components'
import { theme } from 'styled-tools'
import React from 'react'

const ImageHeaderBackground = styled.div`
  background-color: ${theme('contentHeader.imageHeader.backgroundColor')};
  padding: ${(p) => (p.hasImages ? 32 : 20)}px 0px 20px 0px;
  ${(p) => p.theme.media.min.sm`
    padding: ${p.hasImages ? 70 : 40}px 0px 40px 0px;
  `}
`
const ImageHeaderText = styled.div`
  text-align: center;
  margin: 0px auto;
  font-weight: 400;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 14px;
  line-height: 18px;
  max-width: 280px;

  ${(p) => p.theme.media.min.sm`
    line-height: 22px;
    font-size: 17px;
    max-width: 600px;
  `}
`

const ImagesText = ({ imagesText, imagesMobile, imagesDesktop }) => (
  <ImageHeaderBackground hasImages={imagesDesktop && imagesMobile}>
    <ImageHeaderText
      dangerouslySetInnerHTML={{
        __html: imagesText,
      }}
    />
  </ImageHeaderBackground>
)

export default ImagesText
