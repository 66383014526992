import React, { Component, createElement } from 'react'
import ErrorMessage from './ErrorMessage'

/* eslint-disable no-console */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      info: null,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      error,
      info,
    })
    console.error('Error in ErrorBoundary', error)
  }

  render() {
    if (this.state.error) {
      if (this.props.component) {
        return createElement(this.props.component, {
          ...this.props,
          ...this.state,
        })
      }
      if (this.props.render) {
        return this.props.render({ ...this.props, ...this.state })
      }
      return <ErrorMessage name={this.props.name} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
