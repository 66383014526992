import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SectionItem, SectionItemText, SectionItemHeading } from '../sharedUI'
import Select from './Select'
import useTranslation from '../../../../utils/translation'

const StyledText = styled(SectionItemText)`
  margin-top: 20px;
  ${(p) =>
    p.canceled &&
    `
    margin-top: 0;
  `}
`
const StoreEditor = ({ onChange, storeOptions, storeId, canceled }) => {
  if (storeOptions && storeOptions.length === 0) return null
  const t = useTranslation('appointment-details')
  const isFakeStore =
    (storeId && storeId.toString().indexOf('-') === 0) || false

  const storeName = !isFakeStore
    ? storeOptions &&
      storeOptions.find((o) => o.value === storeId.toString()).label
    : `Fake store ${Math.abs(storeId)}`

  return (
    <SectionItem>
      <SectionItemHeading>{t('chosen-store')}</SectionItemHeading>
      {canceled ? (
        <StyledText canceled={canceled}>{storeName}</StyledText>
      ) : (
        <Select
          id="stores"
          name="store"
          label="Select store"
          options={storeOptions}
          value={`${storeId}`}
          onChange={onChange}
          hideLabel
        />
      )}
    </SectionItem>
  )
}

StoreEditor.propTypes = {
  onChange: PropTypes.func,
  storeOptions: PropTypes.arrayOf(PropTypes.shape()),
  storeId: PropTypes.number,
  canceled: PropTypes.bool,
}

StoreEditor.defaultProps = {
  onChange: null,
  storeOptions: null,
  storeId: null,
  canceled: false,
}

export default StoreEditor
