import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import SubHeadline from '../typography/subHeadline'

const StyledItem = styled.li`
  &:first-child {
    border-left: 1px solid ${theme('calendar.border.color')};
  }
  border-right: 1px solid ${theme('calendar.border.color')};

  min-height: 14px;
  flex: 0 0 50%;

  ${(props) => props.theme.media.min.sm`
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    flex: 0 0 25%;
  `}

  ${(props) => props.theme.media.min.md`
    flex: 0 0 ${100 / 6}%;
  `}

  ${(props) => props.theme.media.min.lg`
    flex: 0 0 151px;
  `}
`

const StyledList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 10px 14px;
`

const StyledHeading = styled(SubHeadline)`
  background: ${theme('colors.secondaryBackgroundColor')};
  margin: 0;
  padding: 18px 0px;
  text-align: center;
  text-transform: inherit;
  text-transform: ${theme('slots.headers.textTransform')};

  ::first-letter {
    text-transform: uppercase;
  }
  span {
    font-size: ${theme('calendar.heading.fontSize')};
  }

  &::first-child {
  }

  ${(props) => props.theme.media.min.sm`
    border-bottom: 1px solid #d5d5d5;
  `}
`

const DAY = 24 * 60 * 60 * 1000 // a day in milliseconds

const label = (datetime, todayLabel, tomorrowLabel, locale) => {
  const now = new Date()
  const tomorrow = new Date(now.getTime() + DAY)
  const current = new Date(datetime)

  if (
    now.getDate() === current.getDate() &&
    now.getMonth() === current.getMonth() &&
    now.getYear() === current.getYear()
  )
    return <span>{todayLabel}</span>
  if (
    tomorrow.getDate() === current.getDate() &&
    tomorrow.getMonth() === current.getMonth() &&
    tomorrow.getYear() === current.getYear()
  )
    return <span>{tomorrowLabel}</span>

  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
  }

  return <span>{new Intl.DateTimeFormat(locale, options).format(current)}</span>
}

/**
 * @param {{
 *  day: Date,
 *  children: any | null,
 *  today: string | null,
 *  tomorrow: string | null,
 *  locale: string}} props
 */
const Day = (props) => (
  <StyledItem>
    <StyledHeading>
      {label(props.day, props.today, props.tomorrow, props.locale)}
    </StyledHeading>
    <StyledList>{props.children}</StyledList>
  </StyledItem>
)

export default Day
