const fontUrl =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1517562690/Marbodal/fonts/ginger/'

const marbodalFonts = `
@font-face {
  font-family: 'Ginger';
  src: url(${fontUrl}F37Ginger-Light.eot?) format('eot'),
    url(${fontUrl}F37Ginger-Light.woff) format('woff'),
    url(${fontUrl}F37Ginger-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ginger';
  src: url(${fontUrl}F37Ginger-Regular.eot?) format('eot'),
    url(${fontUrl}F37Ginger-Regular.woff) format('woff'),
    url(${fontUrl}F37Ginger-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ginger';
  src: url(${fontUrl}F37Ginger-Bold.eot?) format('eot'),
    url(${fontUrl}F37Ginger-Bold.woff) format('woff'),
    url(${fontUrl}F37Ginger-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}`

export default marbodalFonts
