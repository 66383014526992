import { WebAuth } from 'auth0-js'
import { sessionSelector } from './authSelectors'
import {
  authRequest,
  authRequestSuccess,
  authRequestError,
} from './authActions'
import LocalAuthStore from './LocalAuthStore'
import { calculateExpiration, hasSessionExpired } from './helpers'

export default class AuthClient {
  constructor(options, { store, preserveState = true } = {}) {
    this.client = new WebAuth(options)
    this.localStore = new LocalAuthStore(options.clientID)
    this.store = store
    this.session = sessionSelector(store.getState())
    this.preserveState = preserveState
    this.onStateChange = this.onStateChange.bind(this)
  }

  subscribe() {
    if (this.unsubscribeFromStore == null) {
      const session = sessionSelector(this.store.getState())

      if (session != null) {
        this.setSession(session)

        if (this.preserveState) {
          this.setLocalSession(session)
        }
      }

      this.unsubscribeFromStore = this.store.subscribe(this.onStateChange)
    }
  }

  unsubscribe() {
    if (this.unsubscribeFromStore != null) {
      this.unsubscribeFromStore()
      this.unsubscribeFromStore = null
    }
  }

  onStateChange() {
    const oldSession = this.session
    const newSession = sessionSelector(this.store.getState())

    if (oldSession !== newSession) {
      this.setSession(newSession)

      if (this.preserveState) {
        this.setLocalSession(newSession)
      }
    }
  }

  getSession() {
    return this.session
  }

  setSession(session) {
    this.session = session
  }

  getLocalSession() {
    return this.localStore.get('session')
  }

  setLocalSession(session) {
    if (session == null) {
      this.localStore.delete('session')
    } else {
      this.localStore.set('session', session)
    }
  }

  isAuthenticated() {
    const session = this.getSession()

    return session != null && hasSessionExpired(session) === false
  }

  restoreLocalSession() {
    const localSession = this.getLocalSession()

    if (localSession != null && !hasSessionExpired(localSession)) {
      this.store.dispatch(authRequestSuccess(localSession))
    }
  }

  handleAuthentication(callback) {
    this.store.dispatch(authRequest())

    this.client.parseHash((err, result) => {
      if (err) {
        this.store.dispatch(authRequestError(err))
      } else {
        this.store.dispatch(
          authRequestSuccess({
            ...result,
            expiresAt: calculateExpiration(result),
          })
        )
      }

      if (callback != null) {
        callback(err, result)
      }
    })
  }

  login(options) {
    this.client.authorize(options)
  }

  logout(options = {}) {
    this.localStore.clear()
    this.client.logout(options)
  }

  renewToken(callback) {
    this.client.checkSession({}, (err, result) => {
      if (err) {
        this.store.dispatch(authRequestError(err))
      } else {
        this.store.dispatch(
          authRequestSuccess({
            ...result,
            expiresAt: calculateExpiration(result),
          })
        )
      }

      if (callback != null) {
        callback(err, result)
      }
    })
  }
}
