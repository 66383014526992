/* eslint-disable no-underscore-dangle */
export const getNobiaState = () => {
  if (typeof window === 'undefined') return undefined

  window.nobia = window.nobia || {}

  return window.nobia
}

export const getOmniState = () => {
  if (typeof window === 'undefined' || window.__OMNI__ == null) {
    return undefined
  }

  return window.__OMNI__
}

export const getNextDataState = () => {
  if (typeof window === 'undefined' || window.__NEXT_DATA__ == null) {
    return undefined
  }

  return window.__NEXT_DATA__
}

export const nobia = getNobiaState()
export const omni = getOmniState()
export const next = getNextDataState()
