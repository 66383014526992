/* eslint-disable no-underscore-dangle */
import settings from '../app/components/settings'

const getBasePath = () => {
  if (window && window.__HERCULES__ && window.__HERCULES__.pagePath) {
    // Running through Epi
    return window.__HERCULES__.pagePath
  }
  // Running locally or directly at the app URL (e.g. https://bada-magnet.nobiadigital.com): use the default path
  return settings.defaultContentPath
}

export const appBasePath = getBasePath()
