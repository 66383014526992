import uuid from 'uuid/v4'
import { ApolloLink } from '@apollo/client'
import { accessTokenSelector } from '../auth/authSelectors'

const createAuthLink = (store) => {
  const requestGuidLink = new ApolloLink((operation, next) => {
    const token = accessTokenSelector(store.getState())

    const context = operation.getContext()

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'request-guid': uuid(),
        ...context.headers,
      },
      ...context,
    })
    return next(operation)
  })
  return requestGuidLink
}

export default createAuthLink
