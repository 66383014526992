import React from 'react'
import styled, { useTheme } from 'styled-components'
import { toRgba } from '../../../themes/themeUtil'
import ErrorIcon from '../icons/error'

const ErrorWrapper = styled.div`
  background: ${(props) => toRgba(props.theme.colors.error, 0.2)};
  padding: 20px;
  display: flex;
  border-radius: ${(props) => props.theme.box.radius};
  font-size: ${(props) => props.theme.typography.paragraph.fontSize};
  font-family: ${(props) => props.theme.typography.paragraph.fontFamily};
  margin-bottom: 21px;
`

const ErrorHeader = styled.div`
  font-weight: bold;
`

const ErrorMessage = styled.span``

const ErrorIconWrapper = styled.div`
  margin-left: auto;
  margin-top: 3px;
`

/**
 * Error component.
 * @param {{
 *  header: string,
 *  message: string, }} props Component properties.
 * @returns {JSX.Element} The error component.
 */
const Error = ({ header, message }) => {
  const theme = useTheme()
  return (
    <ErrorWrapper>
      <div>
        <ErrorHeader>{header}</ErrorHeader>
        <ErrorMessage>{message}</ErrorMessage>
      </div>
      <ErrorIconWrapper>
        <ErrorIcon width={29} height={29} color={theme.colors.error} />
      </ErrorIconWrapper>
    </ErrorWrapper>
  )
}

export default Error
