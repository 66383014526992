import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import getServiceById from './utils'
import settings from '../../components/settings'
import useTranslation from '../../../utils/translation'
import CheckmarkClean from '../../components/icons/checkmark-clean'
import Checkmark from '../../components/icons/checkmark'
import CheckmarkAlternate from '../../components/icons/checkmark-alternate'

const Wrapper = styled.div`
  background-color: ${theme(
    'amend.appointmentInformation.wrapper.backgroundColor'
  )};
  padding: ${theme('amend.appointmentInformation.wrapper.padding')};
  border-radius: ${theme('amend.appointmentInformation.wrapper.borderRadius')};
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 0px auto;
  flex-direction: column;
  position: relative;
  ${(props) => props.theme.media.min.sm`
    flex-direction: row;
  `}
`

const Heading = styled.h3`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('typography.amend.heading.fontFamily')};
  font-weight: ${theme('amend.appointmentInformation.heading.fontWeight')};
  margin-bottom: 8px;
  font-size: 16px;
`

const InfoItem = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  width: 100%;
  font-size: ${theme('amend.appointmentInformation.infoItem.fontSize')};
  line-height: 22px;
  ${(props) => props.theme.media.min.sm`
    width: 160px;
  `}
`

const Column = styled.div`
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
`

const IconContainer = styled.div`
  background-color: ${theme(
    'amend.appointmentInformation.iconContainer.backgroundColor'
  )};
  width: 56px;
  height: 56px;
  border-radius: ${theme(
    'amend.appointmentInformation.iconContainer.borderRadius'
  )};
  box-shadow: ${theme('amend.appointmentInformation.iconContainer.boxShadow')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin: 15px 15px 0px 15px;
  position: absolute;
  left: calc(50vw - 70px);
  top: -50px;
  display: ${theme(
    'amend.appointmentInformation.iconContainer.display.mobile'
  )};

  ${(props) => props.theme.media.min.sm`
    position: static;
    display: ${theme(
      'amend.appointmentInformation.iconContainer.display.desktop'
    )};
  `}
`

const formatDate = (date, time) => {
  const momentDate = moment(`${date} ${time}`)
  return momentDate.format('ddd DD MMM YYYY')
}

const Link = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: ${theme('amend.appointmentInformation.link.color')};
  font-size: 15px;
`

const icon = () => {
  if (
    settings.brand === 'marbodal' ||
    settings.brand === 'novart' ||
    settings.brand === 'sigdal'
  ) {
    return <Checkmark width="56px" height="56px" color="#1E44CB" />
  } else if (settings.brand === 'invita') {
    return <Checkmark width="56px" height="56px" color="#c9682e" />
  } else if (settings.brand === 'hthdk' || settings.brand === 'hthno') {
    return <CheckmarkAlternate width="56px" height="56px" />
  }
  return <CheckmarkClean width="30" height="30px" />
}

const AppointmentInformation = ({ appointment, store, pageContent }) => {
  const t = useTranslation('amend.step3.appointmentInformation')

  const appointmentTypeName = getServiceById(
    pageContent,
    appointment.serviceId,
    store?.externalMeetingData?.meetingData?.simplyBookMeSubsystem || null
  ).heading

  return (
    <Wrapper>
      <Column>
        <IconContainer>{icon()}</IconContainer>
      </Column>
      <Column>
        <Heading>{t('store')}</Heading>
        {store && (
          <>
            {store && (
              <>
                <InfoItem>{store.storeName}</InfoItem>
                <InfoItem>{store.address}</InfoItem>
              </>
            )}
            <Link
              href={`https://www.google.com/maps/dir//${store.latitude},${store.longitude}/@${store.latitude},${store.longitude},16z`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('map-link')}
            </Link>
          </>
        )}
      </Column>
      <Column>
        <Heading>{t('date')}</Heading>
        {appointment && (
          <>
            <InfoItem>
              {formatDate(appointment.selectedDate, appointment.selectedTime)}
            </InfoItem>
            <InfoItem>
              {appointment.selectedTime}-{appointment.selectedEndTime}
            </InfoItem>
            <InfoItem>
              <Link
                href={`${settings.icalAPI}&appointmentId=${appointment.id}`}
              >
                {t('add-calendar')}
              </Link>
            </InfoItem>
          </>
        )}
      </Column>
      <Column>
        <Heading>{t('meetingType')}</Heading>
        <InfoItem>{appointmentTypeName}</InfoItem>
      </Column>
    </Wrapper>
  )
}

export default AppointmentInformation
