import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import settings from '../../../components/settings'
import { ConfirmationStep } from '../wizard'
import { badaDefaultProps } from '../../../../types'
import searchOpenAuthStoresByAddress from '../../../../graphql/SearchOpenAuthStoresByAddressQuery.graphql'
import Loader from '../../../components/loader/Loader'
import { transformStoreAddress } from '../reducer/transform'

const useAppointmentStore = ({ appointment }) => {
  const storeId = appointment?.appointment?.storeId
  if (!storeId) return null

  const { data, loading, error, refetch } = useQuery(
    searchOpenAuthStoresByAddress,
    {
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          includeFakes: true,
          city: 'Stockholm',
          countryName: 'Sweden',
          postalCode: '118 58',
        },
      },
    }
  )

  useEffect(() => {
    refetch()
  }, [storeId])

  const allStores = data?.searchOpenAuthStoresByAddress?.stores || []

  const listResultItem = allStores
    .filter((item) => item.store.id.toString() === storeId.toString())
    .filter(
      (item) => !settings.excludedStudioTypes.includes(item.store.storeType)
    )

  let store = {}
  if (listResultItem && listResultItem.length) {
    const firstStore = listResultItem[0].store
    store = {
      ...firstStore,
      address: transformStoreAddress(
        firstStore.address,
        firstStore.addressLine1,
        firstStore.addressLine2,
        firstStore.city
      ),
    }
  }

  return {
    store,
    loading,
    error,
  }
}

const ConfirmationPage = ({ props }) => {
  const result = useAppointmentStore(props)

  if (!result) return null

  const { store, loading, error } = result

  if (loading && !error) return <Loader />

  return <ConfirmationStep props={props} selectedStore={store} />
}

ConfirmationPage.defaultProps = {
  ...badaDefaultProps,
}

export default ConfirmationPage
