import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { switchProp } from 'styled-tools'
import { basePixelSize } from './baseTheme'

const lineHeight = (height, base = basePixelSize) => {
  const lh = (parseFloat(height) / parseFloat(base)).toFixed(6)
  return `${parseFloat(lh)}`
}

const GlobalStyles = createGlobalStyle`

  body {
    padding: 0;
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;
  }
`

// COLORS
const colors = {
  black: '#000',
  white: '#fff',
  grey: '#E2DCD6',
  lightGrey: '#AFAFAF',
  lightGreyBorder: '#A49F9E',
  mediumGrey: '#E9E5E4',
  darkGrey: '#4a4a4a',
  fbBlue: '#37559C',
  green: '#57CBA1',
  red: '#FA6060',
  lightYellow: '#FEFFCC',
  lightRed: '#FFE4E4',
  lightRedBorder: '#C79898',
}

const contextualColors = {
  primary: colors.darkGrey,
  secondary: colors.black,
  backgroundPrimary: colors.mediumGrey,
  backgroundSecondary: colors.white,
  backgroundDark: colors.darkGrey,
  backgroundBox: transparentize(1, colors.white),
  invalid: colors.lightRed,
  invalidBorder: colors.lightRedBorder,
  valid: colors.lightYellow,
  inputBorder: colors.lightGreyBorder,
  error: colors.red,
  wishlistIcon: colors.black,
  overlayClose: colors.black,
  overlayText: colors.black,
  overlayButton: colors.black,
  lightboxBackground: colors.white,
  lightboxBackdrop: transparentize(0.15, colors.black),
  lightboxLoader: colors.white,
  borderPrimary: colors.lightGreyBorder,
  alert: colors.lightRedBorder,
  active: colors.black,
  success: colors.green,
}

const baseTypography = {
  fontFamily: '"Helvetica Neue", Arial, sans-serif',
  inputFontFamily: '"Helvetica Neue", Arial, sans-serif',
  lineHeight: 1.5,
  marginBottom: '21px',
}

const typography = {
  pageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '24px',
    fontSizeLarge: '48px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
    textTransform: 'none',
  },
  headline: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '18px',
    fontSizeLarge: '26px',
    fontStyle: 'normal',
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
    textTransform: 'none',
  },
  subHeadline: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '16px',
    fontSizeLarge: '16px',
    fontStyle: 'normal',
    lineHeight: baseTypography.lineHeight,
    marginBottom: '7px',
    textTransform: 'none',
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '14px',
    fontSizeLarge: '14px',
    fontStyle: 'normal',
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
    fontWeight: '400',
  },
  pageDescription: {
    fontFamily: baseTypography.fontFamily,
    fontSize: '16px',
    fontSizeLarge: '18px',
    fontStyle: 'normal',
    lineHeight: baseTypography.lineHeight,
    marginBottom: baseTypography.marginBottom,
  },
  input: {
    fontFamily: baseTypography.inputFontFamily,
    fontSize: '14px',
    fontSizeLarge: '14px',
    fontStyle: 'normal',
    lineHeight: 1,
    marginBottom: '28px',
  },
  label: {
    fontFamily: baseTypography.inputFontFamily,
    fontSize: '14px',
    fontSizeLarge: '14px',
    fontStyle: 'normal',
    explanationFontSize: '12px',
    lineHeight: 1,
    marginBottom: '8px',
  },
  error: {
    fontFamily: baseTypography.inputFontFamily,
    fontSize: '13px',
    fontSizeLarge: '13px',
    fontStyle: 'normal',
    lineHeight: 1,
  },
  link: {
    color: contextualColors.primary,
  },
}

const forms = {
  borderRadius: '2px',
  height: '42px',
  padding: '12px',
  fontFamily: baseTypography.fontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1,
  fontSizeDesktop: '14px',
  lineHeightDesktop: 1,
  marginBottom: '28px',
  addressResults: {
    height: '242px',
  },
  colors: {
    color: contextualColors.primary, // colors.primary
    background: colors.white, // colors.white
    border: contextualColors.borderPrimary, // colors.inputbBorder
    valid: contextualColors.valid, // colors.valid
    validBackground: contextualColors.valid, // colors.valid
    errorColor: colors.white, // colors.invalidBorder
    errorBackground: contextualColors.invalidBorder, // colors.invalidBorder
    invalidBorder: contextualColors.invalidBorder, //  contextualColors.alert, // colors.invalidBorder
    invalidBackground: contextualColors.invalid, // contextualColors.error, // colors.invalid
    activeBorder: contextualColors.active, // colors.black
    active: contextualColors.active,
    invalid: colors.red, // colors.invalidBorder
    selected: contextualColors.success,
    checkboxBackground: colors.white, // colors.black
  },
  label: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    explanationFontSize: '12px',
    lineHeight: 1,
    fontSizeDesktop: '14px',
    lineHeightDesktop: 1,
    marginBottom: '8px',
  },
}

const button = {
  borderRadius: 0,
  borderWidth: '1px',
  borderLeftPosition: '0',
  borderRightPosition: '0',
  fontFamily: baseTypography.fontFamily,
  fontSize: '14px',
  fontWeight: '300',
  textTransform: 'none',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.black,
    secondaryColor: colors.lightGrey,
    secondaryBackground: colors.darkGrey,
    successColor: colors.white,
    successBackground: colors.green,
  },
  margin: switchProp('size', {
    small: `0px 5px 5px 0px`,
    normal: `0px 15px 15px 0px`,
    large: `0px 10px 10px 0px`,
  }),
  padding: switchProp('size', {
    small: `10px 40px`,
    normal: `15px 45px`,
    large: `20px 50px`,
  }),
  siblingMargin: 0,
}

const zIndex = {
  overlay: 1000,
}

const icons = {
  padding: 0,
  textMargin: '8px',
  colors: {
    primaryColor: contextualColors.primary,
    secondaryColor: colors.white,
  },
}

const overlay = {
  container: {
    zIndex: zIndex.overlay,
    background: contextualColors.backgroundSecondary,
  },
  header: {
    height: '72px',
    color: colors.white,
    background: contextualColors.backgroundDark,
  },
  lightbox: {
    zIndex: zIndex.overlay,
    backdrop: contextualColors.lightboxBackdrop,
    loader: contextualColors.lightboxLoader,
    closeButton: {
      color: colors.white,
      background: 'none',
      hoverBackground: 'none',
    },
  },
  closeButton: {
    color: colors.white,
  },
}

const grid = {
  columns: 12,
  gutter: '20px', // hedron default
  container: '960px', // hedron default
}

const breakpoints = {
  xxs: 0,
  xs: 540,
  sm: 720,
  md: 960,
  lg: 1140,
  xl: 1920,
  xxl: 2560,
}

const tooltip = {
  colors: {
    primaryColor: contextualColors.primary,
    primaryBackground: contextualColors.backgroundPrimary,
  },
}

const keyboard = {
  colors: {
    key: colors.black,
    shadow: colors.grey,
  },
}

const uploads = {
  galleryThumbnail: {
    background: colors.grey,
    button: {
      background: contextualColors.primary,
      color: contextualColors.backgroundPrimary,
    },
    progressBar: {
      background: transparentize(0.2, colors.black),
      color: colors.white,
    },
  },
  errorMessage: {
    color: colors.white,
    background: contextualColors.invalidBorder,
    border: contextualColors.invalidBorder,
  },
}

const tabs = {
  border: `1px solid ${colors.grey}`,
  color: {
    default: colors.grey,
    active: colors.black,
    activeMarker: colors.black,
  },
  fontWeight: 'normal',
  fontSize: '14px',
  textTransform: 'uppercase',
  padding: grid.gutter,
  paddingDesktop: grid.gutter,
  textAlign: 'left',
  activeFontWeight: 'normal',
}

const baseTheme = {
  GlobalStyles,
  breakpoints,
  grid,
  zIndex,
  zOverlay: zIndex.overlay,
  lineHeight,
  button,
  typography,
  forms,
  icons,
  overlay,
  tooltip,
  keyboard,
  uploads,
  tabs,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default baseTheme
