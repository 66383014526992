import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import React from 'react'

const NoBackground = styled.div`
  width: 100vw;
  max-width: 100%;
  background-color: ${theme('contentHeader.backgroundColor')};

  ${(p) => p.theme.media.min.md`
    padding-bottom: 20px;
  `}

  ${(p) =>
    p.showPromo &&
    `
    padding-bottom: 20px;
  `}
  ${(p) =>
    p.showPromo &&
    p.theme.media.min.md`
    padding-bottom: 40px;
  `}
`
const Background = styled.div`
  width: 100vw;
  max-width: 100%;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    url('${(p) => p.mobileBackground}');
  background-position: center;
  background-size: cover;
  min-height: ${theme('contentHeader.height.mobile')};

  ${(p) => p.theme.media.min.xs`
    min-height: ${theme('contentHeader.height.small')};
  `}

  ${(p) => p.theme.media.min.sm`
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url('${
      p.desktopBackground
    }');
    min-height: ${theme('contentHeader.height.desktop')};    
  `}

  ${(p) =>
    p.showPromo &&
    `
    padding-bottom: 20px;
  `}
`

const QUALITY = 'q_65,f_jpg'
const WIDTH_DESKTOP = 'w_2560'
const WIDTH_SMALL = 'w_720'

export const formatImageUrl = ({ urlToFormat, isSmall = false }) => {
  if (urlToFormat.indexOf('upload') === -1) return urlToFormat

  let IMG_FORMAT = `upload/c_fit,${WIDTH_DESKTOP},${QUALITY}`

  if (isSmall) {
    IMG_FORMAT = `upload/c_fit,${WIDTH_SMALL},${QUALITY}`
  }

  return urlToFormat.replace('upload', IMG_FORMAT)
}

const Header = ({ mobileBackground, desktopBackground, showPromo, children }) =>
  !desktopBackground && !mobileBackground ? (
    <NoBackground showPromo={showPromo}>{children}</NoBackground>
  ) : (
    <Background
      mobileBackground={formatImageUrl({
        urlToFormat: mobileBackground,
        isSmall: true,
      })}
      desktopBackground={formatImageUrl({ urlToFormat: desktopBackground })}
      showPromo={showPromo}
    >
      {children}
    </Background>
  )

Header.defaultProps = {
  mobileBackground: '',
  desktopBackground: '',
  showPromo: false,
}

Header.propTypes = {
  mobileBackground: PropTypes.string,
  desktopBackground: PropTypes.string,
  showPromo: PropTypes.bool,
  children: PropTypes.shape({}).isRequired,
}

export default Header
