import React from 'react'

const Novart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="116"
    height="26.5"
    fill="none"
    viewBox="0 0 500 115"
  >
    <path
      fill="#31261D"
      d="M0 113.6V23.95h21.85V37.8c5-9.8 15.1-15.3 27.9-15.3C70 22.5 83.2 37.1 83.2 58.05v55.45H61.35V62.75c0-12.25-7.45-20.8-18.65-20.8-12.25 0-20.8 9.6-20.8 22.75v48.9H0Zm96.95-44.85c0-26.65 19.55-46.25 46.95-46.25 27.2 0 46.75 19.55 46.75 46.25S171.1 115 143.9 115c-27.35 0-46.95-19.55-46.95-46.25Zm71.65 0c0-16.2-9.95-27.4-24.7-27.4-14.95 0-24.9 11.2-24.9 27.4s9.95 27.4 24.9 27.4c14.75 0 24.7-11.2 24.7-27.4Zm56.2 44.85-32.7-89.65h22.2l20.8 61.85 20.6-61.85h22.4l-32.9 89.6h-20.4v.05Zm54.8-44.85c0-27.4 15.65-46.25 42.3-46.25 11.2 0 21 4.6 26.15 11.55v-10.1h21.85v89.6h-21.85v-10.5c-4.25 7.1-14.6 11.9-25.8 11.9-27 .05-42.65-18.8-42.65-46.2Zm70.05 0c0-16.55-9.6-27.4-24.35-27.4-14.4 0-24 10.85-24 27.4 0 16.55 9.6 27.4 24 27.4 14.75 0 24.35-10.85 24.35-27.4ZM442.6 42.3h-10.85c-13.85 0-19.4 11.9-19.4 29v42.3H390.5V23.95h21.85v15.8c4.45-11.4 11.4-15.8 22.95-15.8h7.3V42.3ZM453.25 0h21.85v23.95H500v18.3h-24.9V80.1c0 9.05 4.8 13.7 13 13.7H500v19.75h-14.4c-19.55 0-32.35-11.4-32.35-31.3V0Z"
    />
  </svg>
)

export default Novart
