/* eslint-disable no-underscore-dangle */
import settings from '../app/components/settings'
import { appBasePath } from './appBasePath'

function getBaseUrl() {
  if (window && window.__HERCULES__ && window.__HERCULES__.pagePath) {
    // Running through Epi
    return `${window.location.origin}${appBasePath}`
  }
  // Running locally or directly at the app URL (e.g. https://bada-magnet.nobiadigital.com): use the default path
  return `${settings.defaultContentOrigin}${appBasePath}`
}

export const appBaseUrl = getBaseUrl()
