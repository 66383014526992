import styled from 'styled-components'
import { prop } from 'styled-tools'
import React from 'react'
import { formatImageUrl } from './Header'

const ImageDesktop = styled.div`
  display: none;
  width: 100%;
  height: 200px;
  max-width: 38vw;
  max-height: 38vw;
  background-image: url(${prop('src')});
  background-size: cover;
  :first-of-type {
    margin-right: 20px;
  }
  :last-of-type {
    margin-left: 20px;
  }
  ${(p) => p.theme.media.min.md`
    display: block;
  `}
`
const ImageMobile = styled.div`
  display: block;
  width: 100%;
  height: 120px;
  max-width: 38vw;
  max-height: 38vw;
  background-image: url(${prop('src')});
  background-size: cover;
  background-position: center;
  :first-of-type {
    margin-right: 10px;
  }
  :last-of-type {
    margin-left: 10px;
  }
  ${(p) => p.theme.media.min.md`
    display: none;
  `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 800px;
  max-height: 180px;
  margin: 0 auto 20px;
`

const Images = ({ imagesDesktop, imagesMobile }) => {
  const hasImages =
    imagesDesktop &&
    imagesDesktop.length > 0 &&
    imagesMobile &&
    imagesMobile.length > 0
  return (
    <>
      {hasImages && (
        <Container>
          {imagesDesktop.length > 0 && (
            <ImageDesktop
              src={formatImageUrl({
                urlToFormat: imagesDesktop[0],
                isSmall: true,
              })}
            />
          )}
          {imagesMobile.length > 0 && (
            <ImageMobile
              src={formatImageUrl({
                urlToFormat: imagesMobile[0],
                isSmall: true,
              })}
            />
          )}
          {imagesDesktop.length > 1 && (
            <ImageDesktop
              src={formatImageUrl({
                urlToFormat: imagesDesktop[1],
                isSmall: true,
              })}
            />
          )}
          {imagesMobile.length > 1 && (
            <ImageMobile
              src={formatImageUrl({
                urlToFormat: imagesMobile[1],
                isSmall: true,
              })}
            />
          )}
        </Container>
      )}
    </>
  )
}

export default Images
