import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const SubHeadline = styled.h3`
  font-family: ${prop(`theme.typography.subHeadline.fontFamily`)};
  font-weight: ${prop(`theme.typography.subHeadline.fontWeight`)};
  font-size: ${prop(`theme.typography.subHeadline.fontSize`)};
  font-style: ${prop(`theme.typography.subHeadline.fontStyle`)};
  line-height: ${prop(`theme.typography.subHeadline.lineHeight`)};
  margin-top: ${prop(`theme.typography.subHeadline.marginTop`)};
  margin-bottom: ${prop(`theme.typography.subHeadline.marginBottom`)};
  text-transform: ${prop(`theme.typography.subHeadline.textTransform`)};
  letter-spacing: ${prop(`theme.typography.subHeadline.letterSpacing`)};

  color: ${prop(`theme.typography.subHeadline.color`, 'inherit')};
  text-align: ${prop('align', 'inherit')};

  ${ifProp('noMargins', `margin: 0;`)};

  ${(props) => props.theme.media.min.sm`
    font-size: ${prop(`theme.typography.subHeadline.fontSizeDesktop`)};
    line-height: ${prop(`theme.typography.subHeadline.lineHeightDesktop`)};
    text-shadow: none;
  `};
`

export default SubHeadline
