import { Component } from 'react'
import invariant from 'invariant'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import withAuth from './withAuth'

class AuthCallback extends Component {
  componentDidMount() {
    const { auth, location } = this.props
    const { client } = auth

    invariant(
      client,
      `AuthClient missing in context. ` +
        `Did you forget to wrap your app in AuthProvider?`
    )

    if (
      /access_token|id_token|error/.test(location.hash) ||
      /code/.test(location.search)
    ) {
      client.handleAuthentication((err, result) => {
        if (err) {
          if (this.props.onError != null) {
            this.props.onError(err)
          }
        } else if (this.props.onSuccess != null) {
          this.props.onSuccess(result)
        }
      })
    }
  }

  render() {
    return this.props.children
  }
}

AuthCallback.defaultProps = {
  children: undefined,
  onSuccess: undefined,
  onError: undefined,
}

const enhance = compose(withRouter, withAuth)

export default enhance(AuthCallback)
