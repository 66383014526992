import { cloneElement } from 'react'

class PortalRegistry {
  constructor() {
    this.containers = {}
    this.children = {}
    this.currentId = 0
  }

  addContainer(name, container) {
    this.containers[name] = container
    this.renderContainer(name)
  }

  removeContainer(name) {
    delete this.containers[name]
  }

  addChild(name, portalId, child) {
    this.children[name][portalId] = child

    this.renderContainer(name)
  }

  clearChild(name, portalId) {
    delete this.children[name][portalId]
  }

  register(name, child) {
    const portalId = `${name}_${this.currentId}`

    this.children[name] = this.children[name] || {}
    this.children[name][portalId] = child
    this.currentId += 1

    return portalId
  }

  unregister(name, portalId) {
    this.clearChild(name, portalId)
    this.renderContainer(name)
  }

  renderContainer(name) {
    if (this.containers[name] && this.children[name]) {
      this.containers[name].setState({
        children: Object.keys(this.children[name])
          .sort()
          .map((id) => cloneElement(this.children[name][id], { key: id })),
      })
    }
  }
}

export default PortalRegistry
