import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { switchProp, ifProp } from 'styled-tools'
import { magnetFonts } from '../fonts'
import { basePixelSize } from './baseTheme'

const gutterWidth = '15px'

const baseTypography = {
  fontFamily: '"Campton", Arial, sans-serif',
  inputFontFamily: '"Campton", Arial, sans-serif',
}

const rebrandTypography = {
  fontFamily: '"NewSpirit", sans-serif',
  inputFontFamily: '"NewSpirit", Arial, sans-serif',
}

const colors = {
  black: '#000',
  darkBlue: '#122126',
  blue: '#002b5e',
  red: '#d81f43',
  green: '#7fb940',
  white: '#fff',
  gray: '#babcbe',
  lightGray: '#dddedf',
  darkGray: '#7f8487',
  lightYellow: '#feffcc',
  warmWood: '#cc9c74',
  toneA: '#f7efe9',
  toneB: '#faf5f2',
}

const contextualColors = {
  primary: colors.darkBlue,
  secondary: colors.blue,
  link: colors.blue,
  active: colors.darkBlue,
  alert: colors.red,
  success: colors.green,
  error: transparentize(0.5, colors.red),
  valid: colors.green,
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.gray,
  borderPrimary: colors.darkGray,
}

const typography = {
  imagePageTitle: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '50px', '63px'),
    lineHeightDesktop: ifProp('small', 1, 1.12),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    textShadow: '0 0 30px rgba(0, 0, 0, 0.43)',
    letterSpacing: '2px',
  },
  pageTitle: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '24px', '32px'),
    lineHeightDesktop: ifProp('small', 1.125, 1.125),
    marginTop: '10px',
    marginBottom: '20px',
    textTransform: 'none',
    letterSpacing: '1px',
  },
  headline: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 500,
    fontSize: ifProp('small', '13px', '16px'),
    lineHeight: ifProp('small', 1.3, 1.375),
    fontSizeDesktop: ifProp('small', '15px', '19px'),
    lineHeightDesktop: ifProp('small', 1.33, 1.21),
    marginTop: '5px',
    marginBottom: '15px',
    textTransform: 'none',
    letterSpacing: '1.2px',
  },

  subHeadline: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.375,
    fontSizeDesktop: '16px',
    lineHeightDesktop: 1.375,
    marginTop: '10px',
    marginBottom: '20px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  title: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.428,
    fontSizeDesktop: '14px',
    lineHeightDesktop: 1.428,
    marginTop: 0,
    marginBottom: '5px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  preamble: {
    fontFamily: rebrandTypography.fontFamily,
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.44,
    marginTop: 0,
    marginBottom: '20px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 300,
    fontSize: switchProp('size', {
      small: '12px',
      large: '16px',
      default: '14px',
    }),
    lineHeight: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    fontSizeDesktop: switchProp('size', {
      small: '12px',
      large: '16px',
      default: '14px',
    }),
    lineHeightDesktop: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    marginTop: 0,
    marginBottom: '20px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  link: {
    color: contextualColors.link,
    textDecoration: 'underline',
  },
}

const forms = {
  borderRadius: 0,
  height: '42px',
  padding: '12px',
  fontFamily: baseTypography.fontFamily,
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: 1,
  fontSizeDesktop: '14px',
  lineHeightDesktop: 1,
  marginBottom: '22px',
  addressResults: {
    height: '232px',
  },
  colors: {
    color: contextualColors.primary, // colors.primary
    background: colors.white, // colors.white
    border: contextualColors.borderPrimary, // colors.inputbBorder
    valid: contextualColors.valid, // colors.valid
    validBackground: contextualColors.valid, // colors.valid
    errorColor: contextualColors.alert, // colors.invalidBorder
    errorBackground: 'none', // colors.invalidBorder
    invalidBorder: contextualColors.alert, // colors.invalidBorder
    invalidBackground: colors.white, // colors.invalid
    activeBorder: contextualColors.active, // colors.black
    active: contextualColors.primary,
    selected: contextualColors.primary,
  },
  label: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    explanationFontSize: '12px',
    lineHeight: 1,
    fontSizeDesktop: '14px',
    lineHeightDesktop: 1,
    marginBottom: '8px',
  },
}

const icons = {
  padding: 0,
  textMargin: '8px',
}

const tooltip = {
  colors: {
    primaryColor: colors.darkBlue,
    primaryBackground: colors.lightYellow,
  },
}

const keyboard = {
  colors: {
    key: colors.black,
    shadow: colors.black,
  },
}

const button = {
  borderRadius: 0,
  borderWidth: '1px',
  borderLeftPosition: '0',
  borderRightPosition: '0',
  fontFamily: baseTypography.fontFamily,
  fontSize: '12px',
  fontWeight: '500',
  textTransform: 'none',
  letterSpacing: '1.2px',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.darkBlue,
    secondaryColor: colors.white,
    secondaryBackground: colors.blue,
    successColor: colors.white,
    successBackground: colors.green,
  },
  margin: '0',
  padding: switchProp('size', {
    small: `10px 40px`,
    normal: `15px 45px`,
    large: `20px 50px`,
  }),
  siblingMargin: gutterWidth,
}

const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const grid = {
  columns: 12,
  gutter: '15px',
  container: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
  },
}

const zIndex = {
  overlay: 1000,
}

const overlay = {
  container: {
    zIndex: zIndex.overlay,
    background: contextualColors.backgroundSecondary,
  },
  header: {
    height: '63px',
    color: contextualColors.primary,
    background: contextualColors.backgroundPrimary,
  },
  lightbox: {
    zIndex: zIndex.overlay,
    backdrop: transparentize(0.15, colors.black),
    loader: colors.white,
    closeButton: {
      color: colors.white,
      background: transparentize(0.5, colors.black),
      hoverBackground: colors.black,
    },
  },
  closeButton: {
    color: colors.black,
    background: 'none',
  },
}

const uploads = {
  galleryThumbnail: {
    background: colors.gray,
    button: {
      background: contextualColors.primary,
      color: contextualColors.backgroundPrimary,
    },
    progressBar: {
      background: transparentize(0.2, colors.black),
      color: colors.white,
    },
  },
  errorMessage: {
    color: contextualColors.alert,
    background: 'none',
    border: contextualColors.alert,
  },
}

const GlobalStyles = createGlobalStyle`
  ${magnetFonts}
  :root {
    font: 300 ${basePixelSize}px/1.43 "Campton", Arial, sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    color: ${contextualColors.primary};
    background-color: ${contextualColors.backgroundPrimary};
  }
`

const theme = {
  basePixelSize,
  GlobalStyles,
  breakpoints,
  grid,
  zIndex,
  gutterWidth,
  typography,
  forms,
  icons,
  tooltip,
  keyboard,
  button,
  overlay,
  uploads,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
