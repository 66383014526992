import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import sanitize from '../styles/sanitize'
import brandFonts from '../fonts/brands'

export default createGlobalStyle`
  ${sanitize};
  ${brandFonts};

  * {
    box-sizing: ${theme('global.boxSizing')};
    -webkit-font-smoothing: ${theme('global.fontSmoothing')};
    letter-spacing: ${theme('global.letterSpacing')};
    line-height: ${theme('global.lineHeight')};
    font-family: ${theme('global.fontFamily')};
  }

  body, html {
    line-height: ${theme('global.lineHeight')};
    font-size: ${theme('global.fontSize')};
    font-family: ${theme('global.fontFamily')};
    font-weight: ${theme('global.fontWeight')};
    font-size: ${theme('global.fontSize')};
    letter-spacing: ${theme('global.letterSpacing')};
  }

  body {
    padding: 0;
    line-height: ${theme('global.lineHeight')};
    font-size: ${theme('global.fontSize')};
    color: ${theme('global.colors.textColor')};
    font-family: ${theme('global.fontFamily')};
    letter-spacing: ${theme('global.letterSpacing')};
  }

  abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    border: ${theme('global.blockElements.border')};
    margin: ${theme('global.blockElements.margin')};
    padding: ${theme('global.blockElements.padding')};
    vertical-align: ${theme('global.blockElements.verticalAlign')};
    letter-spacing: ${theme('global.letterSpacing')};
  }  

  label {
    display: ${theme('global.label.display')};
    margin-bottom: ${theme('global.label.marginBottom')};
    font-weight: ${theme('global.label.fontWeight')};
  }

  dl {
    margin: ${theme('global.dl.margin')};
    margin-top: ${theme('global.dl.marginTop')};
  }

  h1 {
    margin-top: ${theme('global.h1.marginTop')};
  }

  button {
    background-color: ${theme('global.button.backgroundColor')};
    cursor: ${theme('global.button.cursor')};
    border: ${theme('global.button.border')};
    font-family: ${theme('global.button.fontFamily')};
    letter-spacing: ${theme('global.button.letterSpacing')};
  }

  strong {
    font-weight: ${theme('global.strong.fontWeight')};
  }

  label {
    font-weight: ${theme('global.label.fontWeight')};
    display:${theme('global.label.display')};
    margin-bottom:${theme('global.label.marginBottom')};
  }

  button > span {
    letter-spacing: ${theme('global.span.letterSpacing')};
  }

  a {
    color: ${theme('global.colors.linkColor')};
  }

  p {
    margin-block-start:${theme('global.p.marginBlockStart')};
    margin-bottom:${theme('global.p.marginBottom')};
    margin-top:${theme('global.p.marginTop')};
    font-weight:${theme('global.p.fontWeight')};
  }

  h2 {
    margin-block-start:${theme('global.h2.marginBlockStart')};
    font-weight:${theme('global.h2.fontWeight')};
    margin-top:${theme('global.h2.marginTop')};
  }

  h4 {
    font-weight:${theme('global.h4.fontWeight')};
  }

  .grecaptcha-badge {
    visibility: hidden;
  }  
`
