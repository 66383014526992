import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

const Title = styled.h4`
  font-family: ${prop('theme.typography.title.fontFamily')};
  font-size: ${prop('theme.typography.title.fontSize')};
  font-weight: ${prop('theme.typography.title.fontWeight')};
  line-height: ${prop('theme.typography.title.lineHeight')};
  margin-bottom: ${prop('theme.typography.title.marginBottom')};
  text-transform: ${prop('theme.typography.title.textTransform')};
  letter-spacing: ${prop('theme.typography.title.letterSpacing')};

  color: ${prop('theme.colors.primary')};

  text-align: ${prop('align')};

  ${(props) =>
    props.noMargins &&
    css`
      margin: 0;
    `};

  ${(props) =>
    props.theme.media.min.sm`font-size:
      ${props.theme.typography.title.fontSizeLarge};`};
`

export default Title
