import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { theme } from 'styled-tools'
import Preamble from '../../typography/preamble'
import { mobileCheck } from '../../../containers/bada/utils'
import { StoreItems, StoreItemsFull } from './components/store-items'
import { getDisplayData } from '../../../containers/bada/content'

import useTranslation from '../../../../utils/translation'

const SkeletonStoreItem = styled.div`

  ${(props) => props.theme.media.min.md`
    flex-basis: calc(50% - 7px);
    min-height: 110px;
  `}
  width: 100%;
  height: 100%;
  border-radius: 0px;
  min-height: 75px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-color: ${theme('colors.lightGrey')};
  cursor: progress;
  content: ' ';
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #eee;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: loading 2s infinite;

  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
`
const SkeletonStoreList = styled.div`
  display: flex;
  position: relative;
  flex-flow: wrap;
  text-decoration: none;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
`

const fetchSkeletonData = () => ['Skeleton 1','Skeleton 2','Skeleton 3','Skeleton 4','Skeleton 5', 'Skeleton 6'];

const StoreList = (props) => {
  const {
    pageContent,
    selectedAddress,
    selectedStore,
    stores,
    currentTab,
    loading,
    error,
    isMobile,
    toggleAddressChange,
    onChange,
  } = props
  const search = useLocation().search
  const showOnlyFakeStores = new URLSearchParams(search).get('fake')

  const [badaStores, setFilteredBadaStores] = useState(null)

  useEffect(() => {
    const allStores = stores.filter((s) => !s.hideInBada)

    const allStoresWithoutFake = allStores.filter(
      (s) =>
        s.name &&
        !s.name.toLowerCase().includes('fake') &&
        parseInt(s.id, 10) > 0
    )

    const fakeStores = allStores.filter(
      (s) =>
        s.name &&
        s.name.toLowerCase().includes('fake') &&
        parseInt(s.id, 10) < 0
    )

    if (fakeStores.length && !!showOnlyFakeStores) {
      setFilteredBadaStores(fakeStores)
    } else {
      setFilteredBadaStores(allStoresWithoutFake)
    }
  }, [stores, selectedAddress])

  const noStoresFound =
    badaStores &&
    badaStores.length === 0 &&
    selectedAddress &&
    !error &&
    !loading

  const displayData = getDisplayData(pageContent)

  const t = useTranslation('app')

  return (
    <div id="store-list" data-cy="store-results">
      {!toggleAddressChange && badaStores && !loading && !error && (
        <>
          {currentTab === 'near' && (
            <StoreItems
              onChange={(store) =>
                onChange({
                  selectedStore: store,
                  selectedService: null,
                  selectedSlot: null,
                })
              }
              selectedStore={selectedStore}
              stores={badaStores.sort(
                (a, b) => a.distanceInKilometers - b.distanceInKilometers
              )}
              showMoreLink={t('store-list.show-more')}
              pageSize={isMobile ? 4 : 6}
            />
          )}
          {currentTab === 'all' && (
            <StoreItemsFull
              selectedStore={selectedStore}
              stores={badaStores}
              isMobile={isMobile}
              brandStoresHeading={t('store-list.brand-stores-heading', 'Brand')}
              otherStoresHeading={t('store-list.other-stores-heading', 'Other')}
              onChange={(store) =>
                onChange({
                  selectedStore: store,
                  selectedService: null,
                  selectedSlot: null,
                })
              }
            />
          )}
        </>
      )}

      {loading && (
        <SkeletonStoreList>
          {fetchSkeletonData().map((index) => (
          <SkeletonStoreItem key={index} />
          ))}
        </SkeletonStoreList>
      )}
        

      {error && (
        <Preamble>
          {displayData.storeSelectionErrorMessage || 'Unknown error'}
        </Preamble>
      )}
      {noStoresFound && (
        <Preamble>
          {displayData.storeSelectionNoStores || 'No stores found'}
        </Preamble>
      )}
    </div>
  )
}

const enhance = compose(
  withState('isMobile', 'setIsMobileState'),
  withHandlers({
    setIsMobile:
      ({ setIsMobileState }) =>
      () =>
        setIsMobileState(mobileCheck()),
  }),
  lifecycle({
    componentDidMount() {
      const { setIsMobile } = this.props
      setIsMobile()
    },
  })
)

export default enhance(StoreList)
