import styled from 'styled-components'
import { prop } from 'styled-tools'
import { Container } from '../grid'

const FullscreenOverlayContainer = styled(Container).attrs({ fluid: true })`
  position: fixed;
  z-index: ${prop('theme.overlay.lightbox.zIndex', 100)};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${prop('theme.overlay.container.background')};
`

export default FullscreenOverlayContainer
