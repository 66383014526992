import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import Title from '../../../../typography/title'
import RadioButtonRich from '../../../../toggle'
import { mediaMax } from '../../../../../../styles/media'
import TemporarilyClosed from './TemporarilyClosed'

const StoreItemLongContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border-color: ${theme('colors.lightGrey')};

  ${mediaMax(
    'sm',
    css`
      flex-direction: column;
    `
  )};
`

const StyledStoreItemLong = styled.div`
  width: 100%;
  padding: ${theme('typography.storeAddressLong.paddingDesktop')};
  background-color: ${theme('colors.buttonBackgroundColor')};
  background-color: ${theme('colors.transparent')};

  ${(props) => props.theme.media.min.md`
    padding: 12px 8px 12px 12px;
    padding: ${theme('typography.storeAddressLong.padding')};
  `}
`

const StoreHeader = styled.div`
  display: flex;
  justify-content: space-between;

  ${mediaMax(
    'sm',
    css`
      flex-direction: column;
    `
  )};
`
const StoreDetail = styled.div`
  display: flex;
  ${(props) => props.theme.media.min.md`
    flex-basis: 50%;
  `}
`
const StoreCity = styled(Title)`
  font-family: ${theme('typography.storeName.fontFamily')};
  font-size: ${theme('typography.storeName.fontSize')};
  font-weight: bold;
  font-weight: ${theme('typography.storeName.fontWeight')};
  text-transform: ${theme('typography.storeName.textTransform')};
  letter-spacing: ${theme('typography.storeName.letterSpacing')};

  margin: 0;
  color: ${theme('colors.link')};
  padding-right: 16px;
`

const StoreAddress = styled.div`
  text-align: right;
  font-size: 16px;
  font-size: ${theme('typography.storeAddress.fontSize' || '16px')};
  color: ${theme('typography.storeAddressLong.color')};
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-weight: ${theme('typography.storeAddress.fontWeight')};

  ${mediaMax(
    'sm',
    css`
      text-align: left;
    `
  )};
  display: flex;
  align-items: center;
`

const storeName = (city, name) =>
  !city ? (
    name
  ) : (
    <span>
      {city} &middot; {name}
    </span>
  )

const StoreItemLong = ({ store, onChange, checked }) => (
  <StoreItemLongContainer key={store.id}>
    <RadioButtonRich
      name="store"
      value={store.id}
      checked={checked}
      onChange={() => onChange(store)}
    >
      <StyledStoreItemLong key={`${store.id}${Math.random()}`}>
        <StoreHeader>
          <StoreDetail>
            <StoreCity>{storeName(store.city, store.name)}</StoreCity>
          </StoreDetail>
          <StoreAddress>{store.address}</StoreAddress>
        </StoreHeader>
        {store.isTemporarilyClosed && store.temporarilyClosedInformation && (
          <TemporarilyClosed text={store.temporarilyClosedInformation} />
        )}
      </StyledStoreItemLong>
    </RadioButtonRich>
  </StoreItemLongContainer>
)

StoreItemLong.propTypes = {
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    distanceInKilometers: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
}

StoreItemLong.defaultProps = {
  store: null,
  checked: null,
}

export default StoreItemLong
