import FineUploaderAzure from 'fine-uploader-wrappers/azure'
import getRequestEndpoint from './getRequestEndpoint'
import getSignatureEndpoint from './getSignatureEndpoint'
import getSuccessEndpoint from './getSuccessEndpoint'
import { messages } from '../../i18n'

const uploadExtensionErrorKey = 'app.prep-form.upload-extension-error'

const createUploader = (blobContainer, options = {}) =>
  new FineUploaderAzure({
    options: {
      debug: !!options.debug,
      cors: {
        expected: true,
      },
      request: {
        endpoint: getRequestEndpoint(blobContainer, options.requestUrl),
        customHeaders: options.requestHeaders,
      },
      signature: {
        endpoint: getSignatureEndpoint(options.signatureUrl),
        customHeaders: options.signatureHeaders,
      },
      uploadSuccess: {
        endpoint: getSuccessEndpoint(options.successUrl),
        customHeaders: options.successHeaders,
      },
      chunking: {
        enabled: true, // Chunk size defaults to 4 mb
      },
      deleteFile: {
        enabled: true,
      },
      validation: {
        allowedExtensions: options.allowedExtensions || [
          'jpg',
          'jpeg',
          'png',
          'gif',
          'pdf',
        ],
        sizeLimit: 20000000, // 20 mb is what Cloudinary allows,
        itemLimit: options.itemLimit || 0,
      },
      messages: {
        typeError:
          options.typeError ||
          messages[uploadExtensionErrorKey] ||
          '{file} has an invalid extension. Valid extension(s): {extensions}.',
      },
    },
  })

export default createUploader
