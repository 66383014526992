import React from 'react'

const ArrowLeftIcon = ({ width, height, color }) => (
  <svg viewBox="0 0 96 96" width={width} height={height} color={color}>
    <path
      d="M76.097 88.855l-4.194 4.29a2 2 0 0 1-2.829.032L24.334 49.43a2 2 0 0 1 0-2.86L69.073 2.823a2 2 0 0 1 2.829.032l4.194 4.29a2 2 0 0 1-.031 2.828L38.637 46.57a2 2 0 0 0 0 2.86l37.429 36.597a2 2 0 0 1 .031 2.828z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowLeftIcon
