import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import saved from '../../../assets/saved.png'

const withSplash = (SplashedComp) => {
  const Splash = styled.span`
    ${(props) => props.theme.media.max.md`
    display:none;
  `}
    position: ${theme('splash.position')};
    display: inline-block;
    text-align: center;
    transform: ${theme('splash.transform')};
    right: ${theme('splash.right')};
    bottom: ${theme('splash.bottom.start')};
    opacity: 0;
    line-height: 1;
    font-weight: 600;
    z-index: 1000;
    transition: opacity ease-out 0.2s, bottom ease-out 1.5s;
    color: ${(props) => props.theme.colors.link};

    ${(props) =>
      props.animating &&
      css`
        bottom: ${theme('splash.bottom.end')};
        transition: bottom ease-out 0.15s;
        opacity: 0.9;
      `}
  `
  const SplashWrapper = styled.div`
    ${(props) => props.theme.media.min.lg`
      position:relative;
  `}
  `

  class Splashed extends Component {
    state = {
      active: false,
    }

    toggleSplash() {
      this.setState({
        active: true,
      })
      setTimeout(() => {
        this.setState({
          animating: true,
        })
      }, 5)
      setTimeout(() => {
        this.setState({
          animating: false,
        })
      }, 700)
      setTimeout(() => {
        this.setState({
          active: false,
        })
      }, 1500)
    }

    render() {
      return (
        <SplashWrapper>
          <SplashedComp {...this.props} splash={() => this.toggleSplash()} />
          {this.state.active && (
            <Splash
              active={this.state.active}
              animating={this.state.animating}
              onClick={() => this.setState({ active: false })}
            >
              <img src={saved} />
              <br />
              <FormattedMessage id="app.prep-form.saved" />
            </Splash>
          )}
        </SplashWrapper>
      )
    }
  }

  return Splashed
}

export default withSplash
