import settings from '../app/components/settings'

const { parsePhoneNumberFromString } = require('libphonenumber-js')

const { countryCodes } = require('../../config/country')

const isNumber = (str) => /^\d+$/.test(str)

const validationRules = {
  noConsecutiveDots: (value) => {
    const noConsecutiveDots = value.indexOf('..') === -1
    return noConsecutiveDots
  },
  noDotsEnding: (value) => {
    const noDotsEnd = value[value.length - 1] !== '.'
    return noDotsEnd
  },
  noNumericEnding: (value) => {
    const noNumericEnd = !isNumber(value[value.length - 1])
    return noNumericEnd
  },
  noSpaces: (value) => {
    const noSpaces = value.indexOf(' ') === -1
    return noSpaces
  },
  noDisallowedDomains: (value) => {
    const domains = settings.disallowedDomains
    if (!domains) {
      return true
    }
    const groups = value.match(/^([a-zA-Z0-9-._+]+)([@]{1})([a-zA-Z0-9-.+]+)$/)
    if (groups && groups.length === 4) {
      return (
        domains.find((d) => d === groups[3].toLowerCase()) === undefined ||
        false
      )
    }
    return false
  },
}

/**
 * Validates postal code using country specific validation rules.
 * @param {string} postalCode Postal code to validate.
 * @param {string} countryCode
 * ISO 3166-1 alpha-2 country code of the postal code.
 * @returns {boolean}
 * Value indicating whether the postal code is valid for the country.
 */
const validatePostalCode = (postalCode, countryCode) => {
  const trimmed = (postalCode || '').trim()
  if (!trimmed) {
    return false
  }

  const countries = countryCodes()

  switch (countryCode) {
    case countries.denmark:
    case countries.norway:
      return trimmed.length === 4 && isNumber(trimmed)
    case countries.finland:
      return trimmed.length === 5 && isNumber(trimmed)
    case countries.greatBritain:
      return trimmed.length <= 8
    case countries.sweden:
      if (trimmed.length === 6) {
        return (
          isNumber(trimmed.substring(0, 3)) &&
          trimmed.charAt(3) === ' ' &&
          isNumber(trimmed.substring(4))
        )
      }

      if (trimmed.length === 5) {
        return isNumber(trimmed)
      }

      return false
    case countries.netherlands:
    default:
      return true
  }
}

const validateEmail = (value) => {
  const trimmedValue = value.trim()
  const patternValid =
    /^([a-zA-Z0-9-._+]+)([@]{1})([a-zA-Z0-9-.]+)([.]{1})([a-zA-Z0-9]{2,24})$/.test(
      trimmedValue
    )

  const rulesValid = Object.values(validationRules).every((validation) =>
    validation(trimmedValue)
  )

  return patternValid && rulesValid
}

const cache = new Map();

const validateEmailDomain = async (value) => {
  const domain = value.trim().split('@').slice(-1)[0] || ''
  if (!domain || !domain.includes('.')) {
    return false
  }
  if (cache.has(domain)) {
    return cache.get(domain);
  }
  const uri = `${settings.badaAppointmentApiUrl}/validation/email-domain?domain=${domain}`
  try {
    const response = await fetch(uri)
    if(!response.ok){
      cache.set(domain, true);
      setTimeout(() => {
        cache.delete(domain);
      }, 10000)
      // return true for error to not block form if there is something wrong with api endpoint.
      return true;
    }
    const valid = await response.text()
    const isValid = valid === "true";
    cache.set(domain, isValid);
    if (isValid) {
      return true;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    // return true for error to not block form if there is something wrong with api endpoint.
    return true;
  }

  return false
}

const validateName = (value) => {
  const trimmedValue = value.trim()
  const names = trimmedValue.split(' ')
  const isValid = names.length > 1 && names.every((name) => name.length > 0)
  return isValid
}

const validateAddress = (value) => {
  if (!value || (value && value.length === 0)) {
    return false
  }

  const trimmedValue = value.trim()
  return (trimmedValue && trimmedValue.length > 0) || false
}

const validatePhoneNumber = (value, brandCountry) => {
  const trimmedValue = value.trim()
  const phoneNumber = parsePhoneNumberFromString(trimmedValue, brandCountry)

  if (!phoneNumber) {
    return false
  }
  return phoneNumber.isPossible()
}

export {
  validateEmail,
  validateEmailDomain,
  validateName,
  validateAddress,
  validatePostalCode,
  validatePhoneNumber,
}
