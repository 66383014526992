import React from 'react'

const Invita = () => (
  <svg width="110px" height="40px" viewBox="0 1 109 24">
    <defs>
      <polygon points="0 0 109 0 109 23.0834731 0 23.0834731" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="email-template-desktop"
        transform="translate(-26.000000, -28.000000)"
      >
        <g
          id="Invita_Wordmark_Black_PMS"
          transform="translate(26.000000, 28.000000)"
        >
          <g />
          <polyline
            fill="#31261D"
            mask="url(#mask-2)"
            points="87.6041798 0.0304682041 69.8383604 0.0304682041 69.8383604 3.36578442 76.89413 3.36578442 76.89413 23.1039439 80.56174 23.1039439 80.56174 3.36578442 87.6041798 3.36578442 87.6041798 0.0304682041"
          />
          <path
            d="M27.641326,0.0304682041 L27.641326,18.5003887 L27.3433089,18.5003887 C27.3433089,18.5003887 24.0727376,13.2922301 22.9549354,11.5560185 C21.0982792,8.67296471 15.4445231,0.0247554158 15.4445231,0.0247554158 L10.2992051,0.0342767296 L10.2992051,23.1039439 L13.9534853,23.1039439 L13.9534853,4.61688505 L14.2538828,4.61688505 C14.2538828,4.61688505 17.5330232,9.82123515 18.6541579,11.5560185 C20.5193833,14.4428809 26.1997991,23.1039439 26.1997991,23.1039439 L31.2532364,23.1039439 L31.2513321,0.0304682041 L27.641326,0.0304682041"
            fill="#31261D"
            mask="url(#mask-2)"
          />
          <polygon
            fill="#31261D"
            mask="url(#mask-2)"
            points="0 23.0834731 3.71474057 23.0834731 3.71474057 0 0 0"
          />
          <polygon
            fill="#31261D"
            mask="url(#mask-2)"
            points="61.3382075 23.0834731 65.0600891 23.0834731 65.0600891 0.0304682041 61.3382075 0.0304682041"
          />
          <path
            d="M93.2012841,16.3985587 L97.8324511,3.37720999 L98.252341,3.37720999 L102.902075,16.400463 L93.2012841,16.3985587 Z M100.5346,0.0199947589 L95.5892296,0.0199947589 L87.0871724,23.0934705 L90.9299747,23.112037 L92.1453704,19.7400638 L103.993693,19.7552979 L105.157674,23.0934705 L109.006189,23.0934705 L100.5346,0.0199947589 L100.5346,0.0199947589 Z"
            fill="#31261D"
            mask="url(#mask-2)"
          />
          <polyline
            fill="#31261D"
            mask="url(#mask-2)"
            points="46.3659416 19.1640243 46.0655442 19.1640243 39.1035596 0.0147580363 35.0965147 0.0147580363 43.5904787 23.1077524 48.7624563 23.1077524 57.2726066 0.022851153 53.2746069 0.0166622991 46.3659416 19.1640243"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Invita
