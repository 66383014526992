const allowedExtensions = ['jpg', 'jpeg', 'gif', 'png', 'heif', 'heic', 'pdf']
const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'heif', 'heic']

const getFileEnding = (url) => url.split('.').pop().toLowerCase()

const isImageFileFormat = (url) =>
  imageExtensions.some((format) => format === getFileEnding(url))

const heicToJpeg = (cloudinaryUploadUrl, url) => {
  if (['heic', 'heif'].includes(getFileEnding(url))) {
    const baseLength = cloudinaryUploadUrl.length
    const firstPart = url.substr(0, baseLength)
    const secondPart = url.substr(baseLength)
    const divider = secondPart.startsWith('v1') ? '/' : ','
    return `${firstPart}f_jpg${divider}${secondPart}`
  }
  return url
}

const regexp = new RegExp(
  /^https?:\/\/[^/]+\/(dgg9enyjv\/)?((image|video|raw)\/)((upload|fetch)\/)(((((a|ac|af|ar|b|bl|bo|br|c|c|co|cd|d|dl|dn|dpr|du|e|eo|f|fl|fn|fps|g|h|if|ki|l|o|p|pg|q|r|so|sp|t|u|vc|vs|w|x|y|z)_-?[a-zA-Z0-9_.]+,?)+)\/)*)((v\d+)\/)?([^.]+)(\.([a-z0-9]+))?$/
)

const extractUrlSettings = (url) => {
  const parts = regexp.exec(url)
  const cloudName = parts[1]
  const assetType = parts[3]
  const deliveryType = parts[5]
  const transformations = (parts[6] || '').split('/').filter(Boolean)
  const version = parts[12]
  const publicId = parts[13]
  const extension = parts[15]
  return {
    cloudName,
    assetType,
    deliveryType,
    transformations,
    version,
    extension,
    publicId,
  }
}

exports.allowedExtensions = allowedExtensions
exports.imageExtensions = imageExtensions
exports.isImageFileFormat = isImageFileFormat
exports.heicToJpeg = heicToJpeg
exports.extractUrlSettings = extractUrlSettings
