import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

const getComponentDisplayName = (Component) =>
  Component.displayName || Component.name || 'Unknown'

export default (propsOrFn) => (ComposedComponent) =>
  // eslint-disable-next-line react/prefer-stateless-function
  class WithHelmet extends React.Component {
    static displayName = `WithHelmet(${getComponentDisplayName(
      ComposedComponent
    )})`

    render() {
      const props =
        typeof propsOrFn === 'function'
          ? propsOrFn(this.props)
          : propsOrFn || {}
      const {
        title,
        description,
        image,
        url,
        type,
        facebookId,
        twitterId,
        twitterCard,
        robots,
        keywords,
        metaTitle,
      } = props
      const helmetProps = {
        title,
        meta: [
          {
            name: 'description',
            property: 'og:description',
            content: description,
          },
          {
            property: 'fb:app_id',
            content: facebookId,
          },
          {
            property: 'og:url',
            content: url,
          },
          {
            property: 'og:image',
            content: image,
          },
          {
            property: 'og:type',
            content: type,
          },
          {
            name: 'twitter:card',
            content: twitterCard,
          },
          {
            name: 'twitter:site',
            content: twitterId,
          },
          {
            name: 'robots',
            property: 'robots',
            content: robots,
          },
          {
            name: 'keywords',
            content: keywords,
          },
          {
            name: 'title',
            content: metaTitle,
          },
        ].filter(({ content }) => content),
        link: [
          {
            href: url,
          },
        ].filter(({ href }) => href),
      }
      return (
        <Fragment>
          <Helmet {...helmetProps} />
          <ComposedComponent {...this.props} />
        </Fragment>
      )
    }
  }
