import { appBasePath } from './appBasePath'
import tracker from '../utils/tracker'

function saveStateToLocalStorage(state, brand) {
  return window.localStorage.setItem(
    `bada-${brand}-${appBasePath}`,
    JSON.stringify(state)
  )
}

function removeStateFromLocalStorage(brand) {
  return window.localStorage.removeItem(`bada-${brand}-${appBasePath}`)
}

const getSimpleMeetingType = (meetingType) => ({
  id: meetingType.code,
  serviceId: meetingType.id,
  name: meetingType.name,
  type: meetingType.serviceType,
})

function getStateFromLocalStorage(brand) {
  const stateFromStorage = window.localStorage.getItem(
    `bada-${brand}-${appBasePath}`
  )
  let state = null
  if (stateFromStorage) {
    try {
      const {
        selectedAddress,
        selectedService,
        selectedStore,
        visitorId,
        customerAddress,
        overrideMeetingType,
        preSelectedMeetingTypeId,
      } = JSON.parse(stateFromStorage)
      state = {
        selectedAddress,
        selectedService: overrideMeetingType
          ? getSimpleMeetingType(overrideMeetingType)
          : selectedService,
        selectedStore,
        visitorId,
        customerAddress,
        storeId: selectedStore ? selectedStore.id : null,
        overrideMeetingType,
        preSelectedMeetingTypeId,
      }
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error(e)
    }
  }
  return state
}

const clearState = (brand) => {
  removeStateFromLocalStorage(brand)
  return 'Ok'
}

const saveState = (state, brand) => {
  tracker.trackState(state)
  if (state.bookingCompleted) {
    clearState(brand)
    return
  }

  saveStateToLocalStorage(state, brand)
}

const getState = (brand) => getStateFromLocalStorage(brand)

export { saveState, getState, clearState }
