import { useQuery } from 'react-query'
import axios from 'axios'
import settings from '../../components/settings'

const useAppointment = (id) =>
  useQuery(
    id && ['appointment', id],
    () =>
      axios
        .get(
          `${settings.badaAppointmentApiUrl}/appointment/${id}/?brand=${settings.brand}`
        )
        .then((res) => res.data),
    { retry: 2 }
  )

export default useAppointment
