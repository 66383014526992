import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react'
import {
  cleanGoogleRecaptcha,
  injectGoogleReCaptchaScript,
  logWarningMessage,
} from './utils'
import features from '../features'
import appInsights, { SeverityLevel } from '../logging/appInsights'

const GoogleReCaptchaContext = createContext({
  executeRecaptcha: () => {
    // This default context function is not supposed to be called
    appInsights.trackException({
      exception: new Error(
        'GoogleReCaptcha Context has not yet been implemented, if you are using useGoogleReCaptcha hook, make sure the hook is called inside component wrapped by GoogleRecaptchaProvider'
      ),
      severityLevel: SeverityLevel.Error,
    })
  },
})

const { Consumer: GoogleReCaptchaConsumer } = GoogleReCaptchaContext

export function GoogleReCaptchaProvider({
  reCaptchaKey,
  useEnterprise = false,
  useRecaptchaNet = false,
  scriptProps,
  language,
  container,
  children,
}) {
  if (!features.useRecaptcha) {
    return children
  }

  const [greCaptchaInstance, setGreCaptchaInstance] = useState(null)
  const clientId = useRef(reCaptchaKey)

  const scriptPropsJson = JSON.stringify(scriptProps)
  const parametersJson = JSON.stringify(container?.parameters)

  useEffect(() => {
    if (!reCaptchaKey) {
      logWarningMessage(
        '<GoogleReCaptchaProvider /> recaptcha key not provided'
      )

      return () => {}
    }

    const scriptId = scriptProps?.id || 'google-recaptcha-v3'
    const onLoadCallbackName =
      scriptProps?.onLoadCallbackName || 'onRecaptchaLoadCallback'

    window.onLoadCallbackName = () => {
      const grecaptcha = useEnterprise
        ? window.grecaptcha.enterprise
        : window.grecaptcha

      const params = {
        badge: 'inline',
        size: 'invisible',
        sitekey: reCaptchaKey,
        ...(container?.parameters || {}),
      }
      clientId.current = grecaptcha.render(container?.element, params)
    }

    const onLoad = () => {
      if (!window || !window.grecaptcha) {
        logWarningMessage(
          `<GoogleRecaptchaProvider /> Recaptcha script is not available`
        )

        return
      }

      const grecaptcha = useEnterprise
        ? window.grecaptcha.enterprise
        : window.grecaptcha

      grecaptcha.ready(() => {
        setGreCaptchaInstance(grecaptcha)
      })
    }

    const onError = () => {
      logWarningMessage('Error loading google recaptcha script')
    }

    injectGoogleReCaptchaScript({
      render: container?.element ? 'explicit' : reCaptchaKey,
      onLoadCallbackName,
      useEnterprise,
      useRecaptchaNet,
      scriptProps,
      language,
      onLoad,
      onError,
    })

    return () => {
      cleanGoogleRecaptcha(scriptId, container?.element)
    }
  }, [
    useEnterprise,
    useRecaptchaNet,
    scriptPropsJson,
    parametersJson,
    language,
    reCaptchaKey,
    container?.element,
  ])

  const executeRecaptcha = useCallback(
    (action) => {
      if (!greCaptchaInstance || !greCaptchaInstance.execute) {
        appInsights.trackException({
          exception: new Error('Recaptcha instance is not available'),
          severityLevel: SeverityLevel.Error,
        })
      }

      return greCaptchaInstance.execute(clientId.current, { action })
    },
    [greCaptchaInstance, clientId]
  )

  const googleReCaptchaContextValue = useMemo(
    () => ({
      executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
      container: container?.element,
    }),
    [executeRecaptcha, greCaptchaInstance, container?.element]
  )

  return (
    <GoogleReCaptchaContext.Provider value={googleReCaptchaContextValue}>
      {children}
    </GoogleReCaptchaContext.Provider>
  )
}

export { GoogleReCaptchaConsumer, GoogleReCaptchaContext }
