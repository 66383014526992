import React from 'react'
import { compose, lifecycle } from 'recompose'
import settings from '../settings'
import tracker from '../../../utils/tracker'
import FullSelection from './FullSelection'
import PreSelection from './PreSelection'

const StoreSelection = ({
  pageContent,
  storeId,
  store,
  checked,
  stores,
  loading,
  error,
  onChange,
  selectedAddress,
  selectedCity,
  selectedStore,
  customerAddress,
  storeIsPreSelectable,
}) =>
  storeId && storeIsPreSelectable ? (
    <PreSelection
      pageContent={pageContent}
      store={store}
      checked={checked}
      onChange={onChange}
    />
  ) : (
    <FullSelection
      pageContent={pageContent}
      stores={stores}
      loading={loading}
      error={error}
      onChange={onChange}
      selectedAddress={selectedAddress}
      selectedCity={selectedCity}
      selectedStore={selectedStore}
      customerAddress={customerAddress}
    />
  )

const enhance = compose(
  lifecycle({
    componentDidUpdate(oldProps) {
      if (this.props.store && !oldProps.store) {
        const isSelectable =
          settings.excludedStudioTypes.indexOf(this.props.store.storeType) ===
            -1 && !this.props.store.hideInBada

        if (isSelectable) {
          tracker.trackAuto(
            'BADA 2.0 pick a store',
            `${this.props.store.name} - ${this.props.store.id} - ${this.props.store.distanceInKilometers}km`
          )

          this.props.onChange({
            selectedStore: this.props.store,
            preselected: true,
          })
        } else {
          this.props.onChange({
            selectedStore: null,
            preselected: false,
          })
        }
      }
    },
  })
)

export default enhance(StoreSelection)
