import invariant from 'invariant'

const returnState = state => state

const reducerMap = (map = {}, initialState = {}) => (
  state = initialState,
  action
) => {
  invariant(
    typeof action === 'object' && action.type != null,
    'Invalid action, `type` is required.'
  )

  const reducer =
    typeof map[action.type] === 'function' ? map[action.type] : returnState

  return reducer(state, action)
}

export default reducerMap
