import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import SubHeadline from '../../typography/subHeadline'

const StyledItem = styled.li`
  &:first-child {
    border-left: 1px solid ${theme('calendar.border.color')};
  }
  border-right: 1px solid ${theme('calendar.border.color')};

  min-height: 14px;
  flex: 0 0 33.33%;
  border-top: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  
  ${(props) => props.theme.media.min.sm`
    flex: 0 0 25%;
  `}

  ${(props) => props.theme.media.min.md`
    flex: 0 0 ${100 / 6}%;
  `}

  ${(props) => props.theme.media.min.lg`
    flex: 0 0 151px;
  `}
`

const StyledList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 10px 14px;
`

const StyledHeading = styled(SubHeadline)`
  margin: 0;
  padding: 18px 0;
  text-align: center;
  text-transform: inherit;
  text-transform: ${theme('slots.headers.textTransform')};

  ::first-letter {
    text-transform: uppercase;
  }
  span {
    font-size: ${theme('calendar.heading.fontSize')};
  }

  &::first-child {
  }

  ${(props) => props.theme.media.min.sm`
    border-bottom: 1px solid #d5d5d5;
  `}
`

const StyledDay = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  display: flex;
  flex-direction: column;
  font-size: 16px;
`

const StyledDate = styled.div`
  font-size: 22px;
`

const StyledMonth = styled.div`
  text-transform: uppercase;
  font-weight: 200;
  font-size: 14px;
`
const label = (datetime, todayLabel, locale) => {
  const now = new Date()
  const current = new Date(datetime)

  if (
    now.getDate() === current.getDate() &&
    now.getMonth() === current.getMonth() &&
    now.getYear() === current.getYear()
  )
  return <StyledDay>
          <div>{todayLabel}</div>
          <StyledDate>{current.getDate()}</StyledDate>
          <StyledMonth>{current.toLocaleString('default', { month: 'short' })}</StyledMonth>
        </StyledDay>

  return <StyledDay>
            <div>{current.toLocaleDateString(locale, { weekday: 'short' })}</div>
            <StyledDate>{current.getDate()}</StyledDate>
            <StyledMonth>{current.toLocaleString('default', { month: 'short' })}</StyledMonth>
          </StyledDay>
}

/**
 * @param {{
 *  day: Date,
 *  children: any | null,
 *  today: string | null,
 *  tomorrow: string | null,
 *  locale: string}} props
 */
const Day = (props) => (
  <StyledItem>
    <StyledHeading>
      {label(props.day, props.today, props.locale)}
    </StyledHeading>
    <StyledList>{props.children}</StyledList>
  </StyledItem>
)

export default Day
