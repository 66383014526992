const SIMPLY_BOOK_ME = 'simplybookme'
const MS_BOOKINGS = 'microsoftbookings'
const CUSTOMER_FORM = 'customerform'

const getStoreBookingSystem = (storeBookingSystem, storeHasSimplybookme) => {
  if (storeBookingSystem === MS_BOOKINGS) return MS_BOOKINGS
  if (storeBookingSystem === SIMPLY_BOOK_ME) return SIMPLY_BOOK_ME
  if (!storeBookingSystem && storeHasSimplybookme) return SIMPLY_BOOK_ME

  return CUSTOMER_FORM
}

const getMeetingTypeBookingSystem = (meetingTypeBookingSystem) => {
  const TEMP_SERVICETYPE = 'bookings' // TODO: remove when fixed in HERCULES

  if (meetingTypeBookingSystem === SIMPLY_BOOK_ME) return SIMPLY_BOOK_ME
  if (
    meetingTypeBookingSystem === MS_BOOKINGS ||
    meetingTypeBookingSystem === TEMP_SERVICETYPE
  )
    return MS_BOOKINGS

  return CUSTOMER_FORM
}

/**
 * Checks if meetingtype is disabled for the selected store
 * @param {number} meetingTypeId
 * @param {{id:number, disabled: boolean}[]} storeServices storeservices containing meetingtype disable settings
 * @returns
 */
const isDisabled = (meetingTypeId, storeServices) => {
  const selectedStoreServiceSettings =
    storeServices && storeServices.find((f) => f.id === meetingTypeId)

  if (!selectedStoreServiceSettings) return false

  return !!selectedStoreServiceSettings.disabled
}

/**
 * Gets the meetingtype matching the configured slot
 * @param {string} meetingTypeBookingsystem
 * @param {string} storeBookingSystem
 * * @param {boolean} storeHasSimplybookme
 * @returns
 */
const matchingSlotType = (
  meetingTypeBookingsystem,
  bookingSystem,
  hasSimplybookme
) => {
  const storeBookingSystem = getStoreBookingSystem(
    bookingSystem,
    hasSimplybookme
  )
  const meetingTypeBookingSystem = getMeetingTypeBookingSystem(
    meetingTypeBookingsystem
  )

  const storeHasSlotConfigured = storeBookingSystem !== CUSTOMER_FORM
  const meetingTypeHasSlot = meetingTypeBookingSystem !== CUSTOMER_FORM

  if (storeHasSlotConfigured && meetingTypeHasSlot) return true
  if (storeHasSlotConfigured && !meetingTypeHasSlot) return true
  if (!storeHasSlotConfigured && !meetingTypeHasSlot) return true

  return false
}

/**
 *  Hides the linked customerform meetingtype if slot is available
 * @param {string} meetingTypeBookingSystem
 * @param {number} meetingTypeServiceId
 * @param {[{id: number, serviceType: string}]} list
 * @returns true or false
 */
const hideFormMeetingTypeIfMatchingSlot = (
  meetingTypeBookingSystem,
  meetingTypeServiceId,
  list
) => {
  if (meetingTypeBookingSystem !== CUSTOMER_FORM) return false

  const filteredList = list.filter((item) => item.id === meetingTypeServiceId)

  return filteredList.length > 1
}

const KM_IN_STORE_APPOINTMENT_ID = 7
/**
 * Extra validation added by visibility on the meetingtype
 * @param {number} meetingTypeId
 * @param {string} meetingTypeVisibility prepform | no-simplybookme | requires-simplybookme
 * @param {string} storeBookingSystem null | microsoftbookings | simplybookme
 * @param {boolean} storeHasSimplyBookme
 * @param {boolean} storeAcceptHomeVisits
 * @param {boolean} prepFormOnly setting that comes from the querystring that will override all other settings
 * @param {boolean?} featureAllowDisableHomeVisits
 * @returns
 */
const hideByVisibility = (
  meetingTypeId,
  meetingTypeVisibility,
  storeBookingSystem,
  storeHasSimplyBookme,
  storeAcceptHomeVisits,
  prepFormOnly,
  featureAllowDisableHomeVisits
) => {
  if (prepFormOnly && meetingTypeVisibility !== 'prepformonly') {
    // hide all services that do not have the visibility "prepformonly" when querystring contains prepform=true
    return true
  }
  if (!prepFormOnly && meetingTypeVisibility === 'prepformonly') {
    // hide services that have the visibility "prepformonly" when querystring does NOT contains prepform=true
    return true
  }

  if (
    featureAllowDisableHomeVisits &&
    !storeAcceptHomeVisits &&
    meetingTypeId === KM_IN_STORE_APPOINTMENT_ID
  )
    return false

  // if default, dont hide
  if (!meetingTypeVisibility) return false

  const bookingSystemOnStore = getStoreBookingSystem(
    storeBookingSystem,
    storeHasSimplyBookme
  )

  // hide simplybookme services if the selected store is a simplybookme store, but visibility is set to "no-simplybookme"
  if (
    meetingTypeVisibility === 'no-simplybookme' &&
    bookingSystemOnStore === SIMPLY_BOOK_ME
  )
    return true

  // hide simplybookme services if the selected store is not a simplybookme store, but visibility is set to "requires-simplybookme"
  if (
    meetingTypeVisibility === 'requires-simplybookme' &&
    bookingSystemOnStore !== SIMPLY_BOOK_ME
  )
    return true

  return false
}

const ensureSubsystem = (meetingType, storeSubsystem, serviceMappings) => {
  if (!serviceMappings) return true

  if (storeSubsystem) {
    const subsystem = serviceMappings.find(
      (s) => s.subsystem === storeSubsystem
    )

    if (subsystem) {
      const meetingTypeExist = subsystem?.services.find(
        (s) => s.to === meetingType.id && s.code === meetingType.code
      )

      return !!meetingTypeExist
    }
  }

  const meetingTypeExist = serviceMappings[0]?.services.find(
    (s) => s.from === meetingType.id && s.code === meetingType.code
  )

  return !!meetingTypeExist
}

exports.isDisabled = isDisabled
exports.matchingSlotType = matchingSlotType
exports.hideFormMeetingTypeIfMatchingSlot = hideFormMeetingTypeIfMatchingSlot
exports.hideByVisibility = hideByVisibility
exports.ensureSubsystem = ensureSubsystem
