import {
  AUTH_REQUEST_ACTION_TYPE,
  AUTH_REQUEST_SUCCESS_ACTION_TYPE,
  AUTH_REQUEST_ERROR_ACTION_TYPE,
  AUTH_REFRESH_SUCCESS_ACTION_TYPE,
} from './constants'

export const authRequest = () => ({
  type: AUTH_REQUEST_ACTION_TYPE,
})

export const authRefreshSuccess = (payload) => ({
  type: AUTH_REFRESH_SUCCESS_ACTION_TYPE,
  payload,
})

export const authRequestSuccess = (payload) => ({
  type: AUTH_REQUEST_SUCCESS_ACTION_TYPE,
  payload,
})

export const authRequestError = (payload) => ({
  type: AUTH_REQUEST_ERROR_ACTION_TYPE,
  payload,
})
