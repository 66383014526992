import React from 'react'
import styled from 'styled-components'
import {
  SectionItem,
  SectionItemHeading,
  SectionItemText as SectionItemTextUI,
} from '../sharedUI'
import Select from './Select'
import useTranslation from '../../../../utils/translation'

const SectionItemText = styled(SectionItemTextUI)`
  font-weight: 300;
  padding: 11px 0;

  ${(p) =>
    p.canceled &&
    `
    padding-top: 0;
  `}
`

const TimeEditor = ({
  onChangeTime,
  onChangeLength,
  timeOptions,
  lengthOptions,
  selectedTime,
  selectedLength,
  canceled,
  closed,
  available,
}) => {
  const t = useTranslation('appointment-details')
  return (
    <>
      <SectionItem>
        <SectionItemHeading>{t('chosen-start-time')}</SectionItemHeading>
        {!canceled && !closed && available && (
          <Select
            id="times"
            name="time"
            label="Select time"
            options={timeOptions}
            value={selectedTime}
            onChange={onChangeTime}
            hideLabel
          />
        )}
        {canceled && <SectionItemText canceled>{selectedTime}</SectionItemText>}
        {!canceled && !available && (
          <SectionItemText>{selectedTime}</SectionItemText>
        )}
        {closed && !canceled && available && (
          <SectionItemText>Closed</SectionItemText>
        )}
      </SectionItem>
      <SectionItem>
        <SectionItemHeading>{t('chosen-end-time')}</SectionItemHeading>
        {!canceled && !closed && available && (
          <Select
            id="times"
            name="time"
            label="Select duration"
            options={lengthOptions}
            value={`${selectedLength}`}
            onChange={onChangeLength}
            hideLabel
          />
        )}
        {canceled && (
          <SectionItemText canceled>{selectedLength}</SectionItemText>
        )}
        {!canceled && !available && (
          <SectionItemText>{selectedLength} min</SectionItemText>
        )}
        {closed && !canceled && available && (
          <SectionItemText>Closed</SectionItemText>
        )}
      </SectionItem>
    </>
  )
}

export default TimeEditor
