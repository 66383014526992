import warning from 'warning'
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import { mediaMin } from '../../../styles/media'
import createGridComponent from './createGridComponent'

const getWidthValue = (column, columns, propName) => {
  if (
    typeof column === 'string' &&
    (column === 'auto' || column === 'fluid' || column.endsWith('%'))
  ) {
    // pass literal string 'auto', 'fluid',
    // or a string that ends with '%' as-is.
    return column
  }

  const intColumn = parseFloat(column)

  if (!isNaN(intColumn)) {
    if (intColumn === 0) {
      // Return 0 as-is
      return intColumn
    }

    if (intColumn > 0 && intColumn < 1) {
      // between 0 < 1 convert to percentage
      return `${intColumn * 100}%`
    }

    if (intColumn >= 1 && intColumn <= columns) {
      // from 1 up to or equal to column count, calculate
      // percentage based on amount of columns.
      return `${(100 / columns) * intColumn}%`
    }
  }

  warning(
    column == null,
    `[Column] Invalid value '${String(column)}' passed to '${propName}'. `
  )

  return undefined
}

const getWidth = (bp, initial) => (props) => {
  const propName = initial && props.size != null ? 'size' : bp
  const column = props[propName]
  const columns = props.theme.grid.columns

  if (column != null) {
    const width = getWidthValue(column, columns, propName)

    if (width != null) {
      switch (width) {
        case 'auto':
          return css`
            flex: 0 0 ${width};
            width: ${width};
            max-width: none;
          `
        case 'fluid':
          return css`
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
          `
        default:
          return css`
            flex: 0 0 ${width};
            max-width: ${width};
          `
      }
    }
  }

  return undefined
}

const getOffset = (bp, initial) => (props) => {
  const propName = initial && props.offset != null ? 'offset' : `${bp}Offset`
  const column = props[propName]
  const columns = props.theme.grid.columns

  if (column != null) {
    const width = getWidthValue(column, columns, propName)

    if (width != null) {
      return css`
        margin-left: ${width};
      `
    }
  }

  return undefined
}

const getOrder = (bp, initial) => (props) => {
  const order =
    initial && props.order != null ? props.order : props[`${bp}Order`]

  return order != null
    ? css`
        order: ${order};
      `
    : undefined
}

const filteredProps = [
  'size',
  'offset',
  'alignSelf',
  'textAlign',
  'order',
  'fluid',
]

const GridComponent = createGridComponent('Column', filteredProps)

const Column = styled(GridComponent)`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: ${prop('theme.grid.gutter')};
  padding-right: ${prop('theme.grid.gutter')};
  align-self: ${prop('alignSelf', 'auto')};
  text-align: ${prop('textAlign', 'inherit')};

  ${ifProp(
    'debug',
    css`
      padding-top: ${prop('theme.grid.gutter')};
      padding-bottom: ${prop('theme.grid.gutter')};
      border: 1px solid rgba(86, 61, 124, 0.2);
      background-color: rgba(86, 61, 124, 0.15);
    `
  )};

  ${ifProp(
    'fluid',
    css`
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    `
  )};

  ${(props) => {
    const { keys } = props.theme.media
    const minKey = keys[0]

    return keys.map((bp) => {
      const initial = bp === minKey
      const style = css`
        ${getWidth(bp, initial)};
        ${getOffset(bp, initial)};
        ${getOrder(bp, initial)};
      `

      return initial ? style : mediaMin(bp, style)
    })
  }};
`

Column.displayName = 'Column'

export default Column
