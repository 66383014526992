import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import settings from '../settings'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: settings.appInsights.instrumentationKey,
    // Disable most tracking to not flood the log
    enableAutoRouteTracking: false,
    autoTrackPageVisitTime: false,
    disableFetchTracking: true,
    disableAjaxTracking: true,
    disableExceptionTracking: false, // Track all exceptions automatically
    disableTelemetry: false,
  },
})
/* eslint-disable no-param-reassign */
const telemetryInitializer = (envelope) => {
  envelope.tags['ai.cloud.role'] = `bada-${settings.brand}`
}
appInsights.addTelemetryInitializer(telemetryInitializer)
appInsights.loadAppInsights()

export { SeverityLevel } from '@microsoft/applicationinsights-web'
export default appInsights
