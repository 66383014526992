import { toNumber } from 'lodash'
import { ensureSubsystem } from '../../../utils/meetingType'
import { getDisplayData } from '../../containers/bada/content'
import settings from '../../components/settings'

const getServiceById = (pageContent, serviceId, storeSubsystem) => {
  const displayData = getDisplayData(pageContent)

  const services = displayData.services.filter((service) =>
    ensureSubsystem(service, storeSubsystem, settings.serviceMappings)
  )

  const service = services && services.find((s) => s.id === toNumber(serviceId))

  return service
}

export default getServiceById
