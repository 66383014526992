import React from 'react'

const InformationInputIcon = (props) => (
  <svg viewBox="0 0 26 26" {...props}>
    <circle fill="currentColor" cx="13.4102787" cy="13.0962538" r="12.5" />
    <g
      transform="translate(9.434814, 11.714111)"
      stroke="#FFFFFF"
      strokeLinecap="square"
      strokeWidth="2.5"
    >
      <path
        d="M1.9492177,1.55491305 L6.3127173,5.91841265"
        id="Line-2"
        transform="translate(4.020508, 3.878156) rotate(-315.000000) translate(-4.020508, -3.878156) "
      />
    </g>
    <circle fill="#FFFFFF" cx="13.5605469" cy="7.95361328" r="2" />
  </svg>
)

export default InformationInputIcon
