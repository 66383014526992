import styled from 'styled-components'
import { prop } from 'styled-tools'

const Label = styled.label`
  font-family: ${prop('theme.forms.label.fontFamily')};
  font-size: ${prop('theme.forms.label.fontSize')};
  line-height: ${prop('theme.forms.label.lineHeight')};
  font-weight: ${prop('theme.forms.label.fontWeight')};
  color: ${prop('theme.colors.primary')};
  margin-bottom: 10px;

  ${(props) =>
    props.theme.media.min.sm`font-size:
      ${props.theme.forms.label.fontSizeDesktop};`};
`

const LabelText = styled.span`
  display: block;
  margin-bottom: ${prop('theme.forms.label.marginBottom')};
`
const ExplanationText = styled.span`
  display: block;
  font-size: ${prop('theme.forms.label.explanationFontSize')};
  margin-top: 2px;
  margin-bottom: ${prop('theme.forms.label.marginBottom')};
  ${(props) =>
    props.theme.media.min.sm`font-size:
    ${props.theme.forms.label.explanationFontSize};`};
`

export default Label
export { LabelText, ExplanationText }
