// awaitable timeout
// where delay is in miliseconds
const sleep = (delay) => new Promise(resolve => setTimeout(resolve, delay));

/**
 * wrapper function to make promises a bit more resilient
 * @param {Promise<T>} promise operation to run
 * @param {number} count times to retry
 * @param {number} count time in miliseconds to wait after each failed attempt
 * @returns {Promise<T>} operation result
 */
const promiseRetry = async (promise, count = 1, delay = 100) => {
    try {
        await promise
    } catch (err) {
        if (count <= 0) throw err
        if (delay > 0) await sleep(delay)
        await promiseRetry(promise, count - 1, delay)
    }
    return promise
}

export { promiseRetry }
