import styled from 'styled-components'
import { theme } from 'styled-tools'
import Title from '../typography/title'

export const MeetingTypeList = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  text-decoration: none;
  justify-content: space-between;
  margin: ${theme('meetingTypeSelection.margin')};
  ${(p) =>
    p.show &&
    `
    margin-bottom: 20px;
  `}
`

export const MeetingTypeItemContainer = styled.div`
  margin-bottom: 15px;
  width: 100%;
  ${(p) => p.theme.media.min.md`
    flex: 0 0 calc(50% - 8px);
    width: calc(50% - 8px);
  `}
  ${(p) =>
    p.hide &&
    `
    display: none;
  `}

  background-color: ${theme('colors.buttonBackgroundColor') || 'inherit'};

  ${(p) =>
    p.disabled &&
    `
    background-color: #fafafa;
  `}
`

export const Box = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  padding: 26px 15px;
  flex-direction: column;
  ${(p) => p.theme.media.min.sm`
    flex-direction: row;
  `}
`

export const TitleWrapper = styled.div`
  flex-basis: 100%;
  line-height: 22px;
  padding: ${theme('meetingTypeSelection.meetingTypeItem.padding')};
  text-align: ${theme('meetingTypeSelection.meetingTypeItem.textAlign')};
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.hasSubtitle ? 'center' : 'flex-start')};

  box-sizing: content-box;
  ${(p) => p.theme.media.min.sm`
    flex-basis: ${p.hasSubtitle ? '45%' : '35%'};
  `}
`

export const TitleUI = styled(Title)`
  margin: 0;
  font-family: ${theme('meetingType.name.fontFamily')};
  font-size: ${theme('meetingType.name.fontSize')};
  font-weight: ${theme('meetingType.name.fontWeight')};
  line-height: ${theme('meetingType.name.lineHeight')};
  letter-spacing: ${theme('meetingType.name.letterSpacing')};
  text-transform: ${theme('meetingType.name.textTransform')};
  color: ${theme('meetingType.name.color')};
  word-break: break-word;
  margin-top: ${theme('meetingType.name.marginTop.default')};
`

export const Subtitle = styled.p`
  margin: 0;
  font-size: 12px;
  color: #667785;
  font-family: ${theme('meetingType.text.fontFamily')};
`

export const Description = styled.p`
  text-align: center;
  color: ${theme('colors.darkGrey')};
  font-size: ${theme('meetingType.text.fontSize')};
  font-weight: ${theme('meetingType.text.fontWeight')};
  line-height: ${theme('meetingType.text.lineHeight')};
  font-family: ${theme('meetingType.text.fontFamily')};
  margin: 10px 0 0;
  flex-basis: 100%;
  ${(p) => p.theme.media.min.sm`
    text-align: left;
    flex-basis: 55%;
    margin: 0 0 0 10px;
    flex-basis: ${p.hasSubtitle ? '55%' : '65%'};
  `}
`
