import { createSelector } from 'reselect'
import { CUSTOM_CLAIM_NAMESPACE, PROFILE_CLAIM_WHITELIST } from './constants'

export const authSelector = createSelector(
  (state) => state.auth,
  (auth) => auth
)

export const statusSelector = createSelector(
  authSelector,
  (auth) => auth.status
)

export const errorSelector = createSelector(authSelector, (auth) => auth.error)

export const sessionSelector = createSelector(
  authSelector,
  (auth) => auth.session
)

export const accessTokenSelector = createSelector(sessionSelector, (session) =>
  session != null ? session.accessToken : null
)

export const profileSelector = createSelector(sessionSelector, (session) => {
  if (session && session.idTokenPayload) {
    return Object.entries(session.idTokenPayload).reduce(
      (acc, [key, value]) => {
        if (key.startsWith(CUSTOM_CLAIM_NAMESPACE)) {
          acc[key.replace(CUSTOM_CLAIM_NAMESPACE, '')] = value
        } else if (PROFILE_CLAIM_WHITELIST.includes(key)) {
          acc[key] = value
        }

        return acc
      },
      {}
    )
  }

  return null
})
