export const encodeState = (input) => btoa(JSON.stringify(input))

export const decodeState = (input) => JSON.parse(atob(input))

export const parseServerState = (input) => ({
  accessToken: input.access_token || null,
  appState: null,
  expiresAt: parseInt(input.expires_at, 10) * 1000 || null,
  expiresIn: null,
  idToken: input.id_token || null,
  idTokenPayload: input.payload || null,
  refreshToken: input.refresh_token || null,
  scope: null,
  state: input.state || null,
  tokenType: input.access_token ? 'Bearer' : null,
  provider: input.provider || null,
})

export const calculateExpiration = (result) => {
  const now = Date.now()

  return result.expiresIn != null ? now + result.expiresIn * 1000 : null
}

export const hasSessionExpired = (session) => {
  const now = Date.now()

  return session.expiresAt != null && now > session.expiresAt
}
