import { useQuery } from '@apollo/react-hooks'
import settings from '../../components/settings'
import getSbmCalendar from '../../../graphql/GetSbmCalendarQuery.graphql'

export const getDateRange = (meetingTypeId) => {
  const NOW = new Date()
  const TOMORROW = new Date(NOW.getTime() + 24 * 60 * 60 * 1000)
  const RANGE = 60 * 24 * 60 * 60 * 1000 // 60 days in milliseconds,

  let specialMeetingtype = false
  if (settings.brand === 'magnet') {
    switch (parseInt(meetingTypeId, 10)) {
      case 5: // 3 slot in-store TEST
      case 6: // discovery TEST
      case 7: // 3 slot in-store PROD
      case 8: // discovery PROD
      case 11: // 3 slot in-store PROD - magnet2 || 3 slot in-store TEST - magnettest2
      case 12: // discovery PROD - magnet2 || discovery TEST - magnettest2
        specialMeetingtype = true
        break
      default:
        break
    }
  }

  const startDate =
    settings.disableSameDayBooking && !specialMeetingtype ? TOMORROW : NOW

  const fromDate = startDate.toISOString().split('T')[0]
  const toDate = new Date(startDate.getTime() + RANGE)
    .toISOString()
    .split('T')[0]

  return { fromDate, toDate }
}

const useSbmCalendar = ({ meetingType, storeId, locationId, sbmInstance }) => {
  if (!storeId || !meetingType) return null

  const { fromDate, toDate } = getDateRange(meetingType)
  const { data, loading, error } = useQuery(getSbmCalendar, {
    fetchPolicy: 'no-cache',
    variables: {
      storeId,
      locationId,
      meetingType,
      fromDate,
      toDate,
      sbmInstance,
    },
  })

  if (!data)
    return {
      days: [],
      loading,
      error,
    }

  const days = (data && data.getSbmCalendar) || []

  return {
    days,
    loading,
    error,
  }
}

export default useSbmCalendar
