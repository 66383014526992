const DENMARK = 'DK'
const FINLAND = 'FI'
const GREAT_BRITAIN = 'GB'
const GUERNSEY = 'GG'
const ISLE_OF_MAN = 'IM'
const JERSEY = 'JE'
const NORWAY = 'NO'
const SWEDEN = 'SE'
const NETHERLANDS = 'NL'
const GERMANY = 'DE'

/**
 * Gets an object with ISO 3166-1 alpha-2 country codes.
 * @returns Country codes.
 */
const countryCodes = () => ({
  /**
   * Denmark country code.
   */
  denmark: DENMARK,

  /**
   * Finland country code.
   */
  finland: FINLAND,

  /**
   * Great Britain country code.
   */
  greatBritain: GREAT_BRITAIN,

  /**
   * Guernsey country code.
   */
  guernsey: GUERNSEY,

  /**
   * Isle of Man country code.
   */
  isleOfMan: ISLE_OF_MAN,

  /**
   * Jersey country code.
   */
  jersey: JERSEY,

  /**
   * Netherlands country code.
   */
  netherlands: NETHERLANDS,

  /**
   * Norway country code.
   */
  norway: NORWAY,

  /**
   * Sweden country code.
   */
  sweden: SWEDEN,

  /**
   * Germany country code.
   */
  germany: GERMANY,
})

exports.countryCodes = countryCodes
