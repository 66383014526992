const getFileType = (ext = '') => {
  switch (ext.toLowerCase()) {
    case 'pdf':
      return 'PDF'
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return 'IMAGE'
    default:
      return 'OTHER'
  }
}

export default getFileType
