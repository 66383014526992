import React from 'react'

const Close = (props) => (
  <svg viewBox="0 0 96 96" {...props}>
    <path
      d="M47.548 40.477L8.657 1.586a2 2 0 0 0-2.829 0L1.586 5.828a2 2 0 0 0 0 2.829l38.89 38.89L1.587 86.44a2 2 0 0 0 0 2.828l4.242 4.243a2 2 0 0 0 2.829 0l38.89-38.891L86.44 93.51a2 2 0 0 0 2.828 0l4.243-4.242a2 2 0 0 0 0-2.828L54.619 47.548l38.89-38.891a2 2 0 0 0 0-2.829l-4.242-4.242a2 2 0 0 0-2.828 0l-38.891 38.89z"
      fill="currentColor"
    />
  </svg>
)

export default Close
