const client = 'bada'
const brand = process.env.CONFIG_BRAND || 'marbodal'

const baseSettings = {
  mapUrl: 'https://www.google.com/maps/search/?api=1&query=',
  excludedStudioTypes: ['hidden'],
  cloudinaryFetchUrl: 'https://res.cloudinary.com/dgg9enyjv/image/fetch/',
  cloudinaryUploadUrl: 'https://res.cloudinary.com/dgg9enyjv/image/upload/',
  serviceDuration: 90,
  preferIplocation: true,
}

const baseDevSettings = {
  badaAppointmentApiUrl: `https://bada-appointment.dev.nobiadigital.com/api`,
  badaConfigApiUrl: 'https://bada-config.dev.nobiadigital.com',
  gatewayAPI: `https://bada-gateway-api.dev.nobiadigital.com/graphql?brand=${brand}&client=${client}`,
  ipLocationAPI: `https://bada-gateway-api.dev.nobiadigital.com/ip-location`,
  uploads: {
    requestUrl: 'https://omniuploadsdev.blob.core.windows.net',
    signatureUrl:
      'https://bada-gateway-api.dev.nobiadigital.com/bada-uploads/signature',
    successUrl:
      'https://bada-gateway-api.dev.nobiadigital.com/bada-uploads/success',
  },
  appInsights: {
    instrumentationKey: '59910790-af09-465e-8438-8a4ed5f51bca',
  },
}

const baseTestSettings = {
  badaConfigApiUrl: 'https://bada-config.test.nobiadigital.com',
  badaAppointmentApiUrl: `https://bada-appointment.test.nobiadigital.com/api`,
  gatewayAPI: `https://bada-gateway-api.test.nobiadigital.com/graphql?brand=${brand}&client=${client}`,
  ipLocationAPI: `https://bada-gateway-api.test.nobiadigital.com/ip-location`,
  uploads: {
    requestUrl: 'https://omniuploadstest.blob.core.windows.net',
    signatureUrl:
      'https://bada-gateway-api.test.nobiadigital.com/bada-uploads/signature',
    successUrl:
      'https://bada-gateway-api.test.nobiadigital.com/bada-uploads/success',
  },
  appInsights: {
    instrumentationKey: '0ed8f904-4062-49c1-a39a-dbe8f02bfba4',
  },
}

const baseStageSettings = {
  badaAppointmentApiUrl: `https://bada-appointment.stage.nobiadigital.com/api`,
  badaConfigApiUrl: 'https://bada-config.stage.nobiadigital.com',
  gatewayAPI: `https://bada-gateway-api.stage.nobiadigital.com/graphql?brand=${brand}&client=${client}`,
  ipLocationAPI: `https://bada-gateway-api.stage.nobiadigital.com/ip-location`,
  uploads: {
    requestUrl: 'https://omniuploadsstage.blob.core.windows.net',
    signatureUrl:
      'https://bada-gateway-api.stage.nobiadigital.com/bada-uploads/signature',
    successUrl:
      'https://bada-gateway-api.stage.nobiadigital.com/bada-uploads/success',
  },
  appInsights: {
    instrumentationKey: '2e05e239-2c73-41d6-97e5-a3ef0e785168',
  },
}

const baseProdSettings = {
  badaAppointmentApiUrl: `https://bada-appointment.nobiadigital.com/api`,
  badaConfigApiUrl: 'https://bada-config.nobiadigital.com',
  gatewayAPI: `https://bada-gateway-api.nobiadigital.com/graphql?brand=${brand}&client=${client}`,
  ipLocationAPI: `https://bada-gateway-api.nobiadigital.com/ip-location`,
  uploads: {
    requestUrl: 'https://omniuploadsprod.blob.core.windows.net',
    signatureUrl:
      'https://bada-gateway-api.nobiadigital.com/bada-uploads/signature',
    successUrl:
      'https://bada-gateway-api.nobiadigital.com/bada-uploads/success',
  },
  appInsights: {
    instrumentationKey: '78220050-9837-4fc7-926d-c70a062bb2e7',
  },
}

module.exports = {
  baseSettings,
  baseDevSettings,
  baseTestSettings,
  baseStageSettings,
  baseProdSettings,
  client,
  brand,
}
