import React from 'react'

const Norema = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={123}
    height={20}
    viewBox="0 0 123 20"
    version="1.1"
  >
    <title>Group 19</title>
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Group-19" fill="#0D5E66">
        <path
          d="M12.0526206,19.3023047 L5.06048886,8.86478676 C4.59190969,8.16524126 3.96464502,7.20814338 3.72287811,6.71048572 C3.72287811,7.42249343 3.76774208,9.82271545 3.77272696,10.8919732 L3.75860312,19.2923349 L0,19.2923349 L0.0224319815,0.19108725 L3.67635252,0.201057019 L10.4292098,10.2904638 C10.892804,11.0041331 11.5167455,11.9678775 11.7618356,12.4580579 C11.7618356,11.7443885 11.7011862,9.34499732 11.7128176,8.27740117 L11.7161408,0.210195975 L15.4838829,0.201057019 L15.4556352,19.3023047 L12.0526206,19.3023047"
          id="Fill-3"
        />
        <path
          d="M58.6072903,19.3529843 L54.965832,11.938799 C57.39264,10.9833628 58.6230758,9.18215776 58.6288915,6.31335657 C58.6338764,2.25649117 55.6637158,0.261706451 52.0629674,0.254229124 L43.174087,0.254229124 L43.1533167,19.3313832 L46.9675843,19.3413529 L46.9783849,12.4680276 L50.817577,12.475505 L54.2255766,19.3413529 L58.6072903,19.3529843 Z M54.7024639,6.39477635 C54.7024639,8.06305112 53.7412119,8.87641816 51.9724087,8.86894083 L46.982539,8.86894083 L46.9883547,3.96464502 L51.9815476,3.97378398 C53.6755776,3.97378398 54.7024639,4.67665273 54.7024639,6.39477635 Z"
          id="Fill-4"
        />
        <path
          d="M96.0977779,19.4044948 L96.1127325,12.394916 C96.1027628,11.35806 96.1625814,8.92709782 96.1625814,8.18601161 C95.8933976,8.89968095 95.3209667,10.2705243 94.8258014,11.3688605 L90.9409146,19.8431647 L87.0792905,11.3480902 C86.5824637,10.2514155 86.0224949,8.88804955 85.7366949,8.18601161 C85.7499879,8.90715828 85.8006676,11.3364588 85.794021,12.3749765 L85.794021,19.3936942 L82.0520342,19.3837244 L82.0761278,0.303247157 L85.758296,0.303247157 L89.7304184,8.94869899 C90.2172755,10.0179568 90.7963529,11.4486187 91.0364582,12.1415177 C91.2798867,11.4311716 91.8298857,10.0462044 92.3184044,8.94869899 L96.1791977,0.303247157 L99.93697,0.314878555 L99.914538,19.4111413 L96.0977779,19.4044948"
          id="Fill-5"
        />
        <path
          d="M122.467818,19.4352349 L115.232258,0.33398728 L111.524335,0.33398728 L104.186584,19.4128029 L108.287483,19.4227727 L109.785441,15.161527 L116.772587,15.1731584 L118.26556,19.4352349 L122.467818,19.4352349 Z M115.461563,11.5425007 L111.103112,11.5425007 L112.337702,8.07302089 C112.720707,6.96388403 113.102051,5.81403728 113.2948,5.1036912 C113.483394,5.81403728 113.871385,6.96388403 114.236112,8.07302089 L115.461563,11.5425007 Z"
          id="Fill-6"
        />
        <path
          d="M29.353494,3.74032521 C26.0161136,3.74032521 24.1185342,6.49696649 24.1185342,9.83351603 C24.1185342,13.1800353 25.9961741,15.9366766 29.3360469,15.9466464 C32.6634575,15.9466464 34.5842998,13.1900051 34.5901155,9.85428639 C34.600916,6.51773684 32.6900435,3.75195661 29.353494,3.74032521 M38.5364826,9.90413523 C38.5265128,15.3559375 34.7728946,19.707742 29.331062,19.698603 C23.8892295,19.6886332 20.1065327,15.2753486 20.1181641,9.83351603 C20.1273031,4.40165326 23.9208004,0 29.353494,0 C34.7936649,0 38.5414675,4.47227246 38.5364826,9.90413523"
          id="Fill-7"
        />
        <path
          d="M69.9387642,3.97378398 L77.0829349,3.97378398 L77.0929047,0.272507034 L69.8656526,0.261706451 C66.2665658,0.261706451 63.2872663,2.24652141 63.2872663,6.31335657 C63.2872663,6.40640775 63.2756349,13.2381923 63.27065,13.3403825 C63.27065,17.4005711 66.2424721,19.3936942 69.8365741,19.4044948 L77.0671494,19.4128029 L77.0671494,15.7032179 L69.9287944,15.6907556 C68.2297795,15.6907556 67.2028933,14.9795787 67.2078782,13.2597935 C67.2028933,13.230715 67.2078782,12.4372875 67.2078782,11.3996007 L73.797065,11.4095704 L73.7937418,11.3364588 L73.8020499,7.94175226 L73.7937418,7.80965281 L67.2112014,7.79968304 C67.2170171,6.97468461 67.2112014,6.40640775 67.2112014,6.39643798 C67.2170171,4.6749911 68.2497191,3.97378398 69.9387642,3.97378398"
          id="Fill-8"
        />
      </g>
    </g>
  </svg>
)

export default Norema
