import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

const GlobalStyles = createGlobalStyle`   
  html {
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll;
  }
`

export default {
  global: {
    fontSmoothing: 'antialiased',
    boxSizing: 'border-box',
    dl: {
      marginTop: '0px',
    },
  },
  colors: {
    darkGrey: '#111111',
    link: '#111111',
    primary: '#111111',
    accentShadow: '0px 0px 0px 5px rgba(247,239,233, 1)',
    secondaryBackgroundColor: '#f9f9f9',
    inputBorder: '#d6d6d6',
    blue: '#111111',
  },
  radioButtonRich: {
    borderRadius: '8px',
  },
  storesHeader: {
    header: {
      fontSize: '16px',
    },
  },
  typography: {
    pageTitle: {
      fontWeight: 700,
      fontSize: '44px',
    },
    usp: {
      mobile: {
        fontSize: '21px',
      },
    },
    button: {
      fontSize: '12px',
    },
    headline: {
      fontWeight: 700,
      fontSize: '26px',
      fontSizeDesktop: '26px',
      fontSizeLarge: '26px',
      textTransform: 'none',
      marginBottom: '5px',
      marginBottomDesktop: '5px',
      color: '#111111',
    },
    subHeadline: {
      fontWeight: 700,
      textTransform: 'none',
    },
    title: {
      fontWeight: 300,
    },
    paragraph: {
      fontSize: '16px',
      fontSizeLarge: '16px',
      fontWeight: 200,
      fontWeightLead: 200,
      lineHeight: '24px',
    },
    storeName: {
      fontWeight: 700,
      fontSize: '16px',
      marginTop: '0px',
      marginBottom: '10px',
    },
    storeDistance: {
      fontWeight: '200',
      marginTop: '3px',
    },
    storeAddress: {
      fontSize: '16px',
      fontWeight: '200',
      marginTop: '10px',
      marginBottom: '0px',
      color: '#111111',
      padding: '20px',
      paddingMobile: '20px',
    },
    storeAddressLong: {
      padding: '20px',
      paddingDesktop: '20px',
    },
  },
  header: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    color: theme('colors.link'),
    height: '74px',
    heightDesktop: '105px',
    fontSize: '16px',
    letterSpacing: '0px',
    logoMargin: '11px 0 0',
    fontWeight: '200',
    fontFamily: theme('typography.paragraph.fontFamily'),
  },
  contentHeader: {
    justifyContent: 'center',
    height: {
      desktop: '600px',
    },
    heading: {
      mobile: {
        fontSize: '30px',
        lineHeight: '38px',
        margin: '30px 0px 0px 0px',
      },
      desktop: {
        fontSize: '44px',
        lineHeight: '54px',
        textAlign: 'center',
        margin: '20px 0px 0px 0px',
      },
    },
    otherNeeds: {
      backgroundColor: '#F7EFE9',
      popDownBgColor: '#F7EFE9',
      color: '#111111',
      fontWeight: '200',
      strong: {
        fontWeight: '700',
        fontSize: '26px',
        lineHeight: '34px',
      },
      fontSize: {
        mobile: '13px',
        desktop: '16px',
      },
      desktop: {
        positionTop: '-68px',
        marginBottom: '-68px',
      },
      mobile: {
        positionTop: '-52px',
        marginBottom: '-52px',
      },
      padding: {
        desktop: '29px',
      },
    },
    usps: {
      textAlign: 'center',
      justifyContent: 'center',
      fontWeight: '200',
      mobile: {
        width: '100%',
        fontSize: '18px',
        lineHeight: '26px',
      },
      desktop: {
        width: 'auto',
        fontSize: '21px',
        lineHeight: '26px',
      },
    },
    mobile: {
      height: '460px',
    },
  },
  designer: {
    borderRadius: '8px',
    background: {
      color: '#F7EFE9',
    },
    minHeight: '200px',
    padding: {
      desktop: '0 35px',
      mobile: '0 20px',
    },
    margin: {
      desktop: '30px 0',
      mobile: '15px 0 30px 0',
    },
    headline: {
      fontSize: '18px',
      fontWeight: 700,
      textTransform: {
        normal: 'none',
      },
      letterSpacing: {
        normal: '0px',
      },
      margin: '25px 0 5px 0',
    },
    paragraph: {
      lineHeightDesktop: '24px',
      fontSize: {
        desktop: '16px',
      },
      lineHeight: {
        desktop: '26px',
      },
    },
    name: {
      fontWeight: 700,
      fontSize: '13px',
    },
    location: {
      fontWeight: 300,
      fontSize: '13px',
    },
    left: {
      margin: '0 0 10px 0',
    },
    right: {
      flexDirection: 'row-reverse',
    },
  },
  grid: {
    containerMaxWidth: '1112px',
  },
  button: {
    colors: {
      primaryColor: '#fff',
      primaryBackground: '#111111',
    },
    backgroundColor: 'transparent',
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '1.5px',
    border: '1px solid transparent',
    blockButtonWidth: 'auto',
    wideButtonWidth: 'auto',
    borderRadius: '25px',
    padding: '16px 50px 16px 50px',
    height: '50px',
  },
  tabs: {
    fontWeight: 300,
    activeFontWeight: 400,
    letterSpacing: '0.8px',
    fontSize: '13px',
    textTransform: 'uppercase',
    color: {
      default: '#111111 ',
      active: '#111111',
      bottomBorder: '#d7d7d7',
      activeBorder: '#111111',
    },
    borderHeight: {
      active: '1px',
    },
    padding: '0px 15px 22px 15px',
    header: {
      link: {
        borderBottom: '1px solid currentColor',
        textDecoration: 'none',
        fontWeight: 400,
      },
    },
  },
  showMore: {
    fontWeight: 700,
  },
  forms: {
    borderRadius: '25px',
    padding: '0px',
    paddingInput: '0px 20px 0px 20px',
    height: '50px',
    paragraph: {
      fontSize: '18px',
    },
    termsConditions: {
      fontWeight: '200',
      fontSize: '13px',
      lineHeight: '21px',
      letterSpacing: '1.5px',
      link: {
        textDecoration: 'underline',
      },
    },
    checkbox: {
      borderRadius: '3px',
    },
    position: {
      top: '1.6rem',
    },
    label: {
      marginBottom: '5px',
      fontSize: '16px',
      fontWeight: '200',
    },
    colors: {
      prefilledBackground: '#f1f1f1',
      valid: '#9be587',
      border: '#d6d6d6',
    },
    fields: {
      height: '50px',
    },
    customerForm: {
      fontWeight: '200',
    },
    button: {
      padding: '16px 50px 16px 50px',
    },
    prepForm: {
      headline: {
        mobile: {
          fontSize: '26px',
          fontWeight: 700,
          marginTop: '30px',
          lineHeight: '36px',
          textTransform: {
            normal: 'none',
          },
        },
        desktop: {
          fontSize: '42px',
          fontWeight: 700,
          marginTop: '60px',
          lineHeight: '54px',
          textTransform: {
            normal: 'none',
          },
        },
      },
      paragraph: {
        lineHeight: '20px',
        margin: {
          desktop: '10px 0 50px 0',
          mobile: '25px 0 30px 0',
        },
        fontSize: '16px',
      },
      clearButton: {
        color: '#111111',
        letterSpacing: '0px',
        fontSize: '13px',
        fontWeight: '200',
        textTransform: 'none',
      },
    },
  },
  postalCodeOverlay: {
    borderRadius: '8px',
    inputField: {
      padding: '18px 20px 19px 20px',
      height: '47px',
    },
  },
  stores: {
    distance: {
      fontSize: '13px',
    },
    changeStoreLink: {
      fontWeight: '200',
      fontSize: '16px',
    },
  },
  photoUpload: {
    preamble: {
      color: '#555555',
      fontSize: '17px',
      fontWeight: 300,
      lineHeight: '24px',
    },
    button: {
      color: '#000',
      backgroundColor: 'transparent',
      border: '1px solid #000',
      fontSize: '13px',
    },
    buttonHover: {
      color: '#fff',
      backgroundColor: '#4d4d4d',
      border: '1px solid #4d4d4d ',
    },
    section: {
      padding: `0 0 20px 0`,
      borderBottom: `none`,
    },
    container: {
      padding: `20px 0`,
      marginLeft: '0',
    },
    checkbox: {
      fontSize: '16px',
      letterSpacing: '0px',
      fontWeight: 200,
      text: {
        fontSize: '16px',
        letterSpacing: '0px',
        fontWeight: 200,
      },
    },
  },
  contactDetails: {
    dt: {
      fontSize: '12px',
    },
  },
  bookingDetails: {
    borderRadius: '8px',
    addressItem: {
      lineHeight: '26px',
    },
    border: {
      color: '#d6d6d6',
    },
    storeName: {
      display: 'none',
    },
    marginBottom: '28px',
    padding: '10px 20px 40px 20px',
    paddingDesktop: '20px 30px 40px 30px',
    divider: {
      color: '#ffffff',
    },
  },
  bookingSummary: {
    borderWidth: '0px',
    borderStyle: 'none',
    borderRadius: '8px',
    backgroundColor: '#F7EFE9',
    itemTitle: {
      fontSize: '16px',
      fontWeight: '700',
      marginBottom: '5px',
      marginTop: '10px',
    },
    itemText: {
      marginBottom: '20px',
      fontSize: '16px',
    },
    storeName: {
      display: 'none',
    },
  },
  confirmation: {
    header: {
      marginBottomDesktop: '18px',
      textTransform: 'uppercase',
      fontSize: '42px',
      fontSizeDesktop: '42px',
      textAlign: 'left',
      marginBottom: '40px',
    },
    button: {
      backgroundColor: 'transparent',
      fontSize: '12px',
    },
  },
  noTimeSuitable: {
    after: "content: ''",
  },
  quickQuestions: {
    container: {
      bottomBorder: 0,
      mobile: {
        padding: '0 20px',
        margin: '0 0 20px 0',
      },
      desktop: {
        padding: '10px 0',
        margin: '10px 0',
        flexDirection: 'column',
      },
    },
    heading: {
      style1: {
        color: theme('colors.link'),
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        fontWeight: 700,
        letterSpacing: '0px',
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '20px 0 10px 0',
          desktop: '20px 0 10px 0',
        },
        padding: 0,
        textTransform: 'none',
        width: '100%',
        minWidth: {
          desktop: '100%',
        },
      },
      style2: {
        color: theme('colors.link'),
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        fontWeight: 700,
        letterSpacing: 0,
        lineHeight: {
          mobile: '26px',
          desktop: '26px',
        },
        margin: {
          mobile: '20px 0 0 0',
          desktop: '20px 0 10px 0',
        },
        padding: 0,
        textTransform: 'none',
        width: '100%',
        minWidth: {
          desktop: '100%',
        },
      },
      style3: {
        color: theme('colors.link'),
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        fontWeight: 700,
        letterSpacing: 0,
        lineHeight: {
          mobile: '30px',
          desktop: '45px',
        },
        margin: {
          mobile: '20px 0 0 0',
          desktop: '20px 0 0 0',
        },
        padding: 0,
        textTransform: 'uppercase',
        width: '100%',
        minWidth: {
          desktop: '100%',
        },
      },
    },
  },

  questionBlock: {
    options: {
      margin: {
        mobile: '20px 0',
        desktop: '12px auto 0 0',
      },
    },
    anotherOption: {
      fontWeight: 400,
    },
    optionsItem: {
      activeBoxShadow: '0px 0px 0px 5px rgba(247,239,233, 1)',
      borderRadius: '8px',
      width: {
        mobile: '400px',
        desktop: '400px',
      },
      margin: {
        mobile: '0 10px 10px 0',
        desktop: '0 10px 10px 0',
      },
    },
    textArea: {
      width: '100%',
      borderRadius: '8px',
    },
  },
  imageOption: {
    wrap: {
      flexDirection: 'column',
      padding: '10px',
    },
    image: {
      mobile: {
        width: '400px',
        maxWidth: '100%',
      },
      desktop: {
        width: '100%',
        height: '260px',
      },
    },
    text: {
      fontSize: {
        desktop: '16px',
      },
      marginTop: '16px',
      marginBottom: '16px',
    },
  },
  textOption: {
    backgroundColor: 'transparent',
    wrap: {
      mobile: {
        padding: '0px 1.5rem',
        width: 'auto',
      },
      desktop: {
        padding: '0px 1.5rem',
      },
    },
    text: {
      minHeight: '60px',
      fontFamily: theme('typography.paragraph.fontFamily'),
      fontSize: {
        mobile: '16px',
        desktop: '16px',
      },
      fontWeight: 400,
      active: {
        fontWeight: 400,
      },
      textAlign: 'left',
    },
  },
  buttonHover: `
    color: #111!important;
    border-color: #111;
    background: transparent!important;
    border-radius: 25px;
  `,
  buttonHoverBefore: `
    background: transparent!important;
    transform: none!important;
    border-radius: 25px;
  `,
  meetingTypeSelection: {
    meetingTypeItem: {
      flexDirection: 'row',
      textAlign: 'center',
      padding: '0 20px 10px 20px',
      display: 'flex',
    },
    margin: '0 0 80px 0',
  },
  meetingType: {
    name: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
      maxWidth: '100%',
    },
    text: {
      fontSize: '13px',
      fontWeight: 200,
      lineHeight: '21px',
      padding: '0 10px',
    },
    home: {
      marginTop: '10px',
      position: 'relative',
      top: '0',
    },
    video: {
      marginTop: 0,
      position: 'relative',
      top: '0',
    },
    store: {
      marginTop: '10px',
      position: 'relative',
      top: '0',
    },
  },
  GlobalStyles,
}
