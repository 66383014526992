import PropTypes from 'prop-types'

export const storeType = PropTypes.shape({
  address: PropTypes.string,
  city: PropTypes.string,
  id: PropTypes.string,
  storeManagerId: PropTypes.string,
  storeName: PropTypes.string,
  name: PropTypes.string,
  simplyBookMeId: PropTypes.number,
  storeType: PropTypes.string,
  phoneNumber: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  email: PropTypes.string,
  hideInBada: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape()),
})

export const storeDefaultProps = {}
