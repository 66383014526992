import React from 'react'
import PropTypes from 'prop-types'

const Home = ({ width, height }) => (
  <svg viewBox="0 0 30 28" width={width} height={height}>
    <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M25.723 12.24v12.24c0 .902-.887 1.633-1.982 1.633H6.46c-1.095 0-1.982-.731-1.982-1.633V12.24" />
      <path
        d="M14.999 1.398L28.242 14.64M15.014 1.38L1.14 15.252"
        strokeLinecap="round"
      />
      <path d="M12.136 25.792h5.928v-8.9h-5.928v8.9z" />
    </g>
  </svg>
)

Home.defaultProps = {
  width: 50,
  height: 50,
}

Home.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Home
