import React from 'react'

const Marbodal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 573.27 72.74"
    width={150}
  >
    <path
      fill="#30261d"
      d="M36.07 54.49h-.39L14.57 22.87v48.21H2.91V3.58h12.63l20.54 31.91L56.61 3.58h12.63v67.5H57.38V22.67L36.07 54.49ZM149.94 71.08h-12.53l-6.85-16.29H98.65l-6.94 16.29H79.56l29.7-67.98h10.99l29.7 67.97Zm-35.38-53.8-11.57 27h23.24l-11.67-27ZM203.84 71.08l-16.58-23.53h-14.85v23.53h-11.86V3.58h30.08c15.52 0 25.07 8.2 25.07 21.4 0 11.18-6.65 17.93-16.1 20.54l18.22 25.55h-13.98Zm-14.17-56.69h-17.26v22.66h17.36c8.48 0 13.88-4.43 13.88-11.38 0-7.42-5.21-11.28-13.98-11.28ZM283.85 20.84c0 8.29-4.63 12.53-9.64 15.14 7.91 2.7 13.4 7.23 13.4 16.49 0 12.15-9.93 18.61-24.97 18.61H231.4V3.58h30.27c13.6 0 22.18 6.65 22.18 17.26Zm-11.86 1.93c0-5.5-4.24-8.68-11.86-8.68h-17.07v17.93h16.2c7.62 0 12.73-2.99 12.73-9.26Zm3.76 28.44c0-5.78-4.53-9.16-13.88-9.16h-18.8v18.51h19.67c8.1 0 13.02-3.18 13.02-9.35ZM331.77 72.23c-20.73 0-35.19-15.72-35.19-34.81s14.66-34.99 35.39-34.99 35.19 15.72 35.19 34.81-14.66 35-35.39 35Zm0-58.91c-13.4 0-22.75 10.7-22.75 23.91s9.55 24.1 22.95 24.1 22.75-10.7 22.75-23.91-9.55-24.1-22.95-24.1ZM404.28 71.08h-25.16V3.58h25.16c21.21 0 35.87 14.56 35.87 33.65s-14.66 33.84-35.87 33.84Zm0-56.69h-13.31v45.89h13.31c14.17 0 23.43-9.55 23.43-22.85s-9.26-23.04-23.43-23.04ZM511.89 71.08h-12.53l-6.85-16.29H460.6l-6.94 16.29h-12.15L471.2 3.1h10.99l29.7 67.97Zm-35.38-53.8-11.57 27h23.24l-11.67-27ZM522.49 3.58h11.86v56.7h35.48v10.8h-47.34V3.58Z"
    />
  </svg>
)

export default Marbodal
