import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useHistory } from 'react-router-dom'
import settings from '../../components/settings'
import { navigateToStep } from '../utils'
import useTranslation from '../../../utils/translation'
import PenIcon from '../../components/icons/pen'
import PageTitle from '../../components/typography/pageTitle'
import SubHeadline from '../../components/typography/subHeadline'
import Paragraph from '../../components/typography/paragraph'
import useBrandConfig from '../../hooks/useBrandConfig'

const Heading = styled(PageTitle)`
  text-align: center;
  margin: ${theme('amend.step1.heading.margin')};
  color: ${(props) => theme('amend.step1.heading.color')(props)};
`

const Preamble = styled.p`
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  font-size: 18px;
  max-width: 560px;
  margin: 0px auto 50px auto;
  display: block;
  line-height: ${theme('amend.step1.preamble.lineHeight')};
`

const AppointmentBox = styled.div`
  width: 100%;
  max-width: 520px;
  margin: 0px auto;
  background-color: ${theme('amend.step1.appointmentBox.backgroundColor')};
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 5px;
  flex-direction: column;
  ${(props) => props.theme.media.min.sm`
  flex-direction: row;
  align-items: center;
  `}
`

const SubHeading = styled(SubHeadline)`
  margin: 0;
  margin-bottom: 7px;
  font-size: 21px;
  color: ${(props) => theme('amend.step1.subHeading.color')(props)};
`
const InfoItem = styled(Paragraph)`
  color: ${(props) => theme('amend.step1.subHeading.color')(props)};
  span {
    display: block;
  }
`
const Details = styled.div``

const Button = styled.a`
  background-color: ${theme('amend.step1.button.backgroundColor')};
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: #ffffff;
  text-decoration: none;
  padding: 15px 20px;
  font-size: 16px;
  border-radius: ${theme('amend.step1.button.borderRadius')};
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 26px;
  font-weight: 500;
  transition: opacity 0.5s;
  :hover {
    opacity: 0.9;
  }
  svg {
    margin-top: -2px;
    padding-right: 7px;
    box-sizing: content-box;
    display: inline-block;
  }
`

const ButtonContainer = styled.div`
  max-width: 520px;
  margin: 25px auto;
`

const Spacer = styled.div`
  height: 20px;
`

const StyledPenIcon = styled(PenIcon)``

const getAppointmentDate = (
  selectedDate,
  selectedTime,
  selectedEndTime,
  languageCode
) => {
  moment.locale(languageCode)
  const parsedDate = moment(`${selectedDate} ${selectedTime}`)
  return `${parsedDate.format('DD MMM YYYY, HH:mm')}-${selectedEndTime}`
}

const getFirstName = (fullName) => {
  if (fullName.indexOf(' ') > -1) {
    return fullName.split(' ')[0]
  }
  return fullName
}

const Amend = ({ appointment, store }) => {
  const t = useTranslation('amend')
  const brandConfig = useBrandConfig(settings.brand).data
  const history = useHistory()

  const isHTH = settings.brand === 'hthdk' ||
  settings.brand === 'hthse' ||
  settings.brand === 'hthfi' ||
  settings.brand === 'hthno' ||
  settings.brand === 'hthde'

  const isOnlineMeeting =
    appointment.externalMeetingProvider === 'videoslot-appointment' ||
    appointment.externalMeetingProvider === 'discoverslot-appointment'

  return (
    <>
      <Heading>
        {appointment &&
          t('heading', { name: getFirstName(appointment.customerName) })}
      </Heading>
      <Preamble>{t('preamble')}</Preamble>
      <AppointmentBox>
        <Details>
          <SubHeading>{t('sub-heading')}</SubHeading>
          {(store && (
            <>
              <InfoItem>
                {`${store.storeName}`}

                {isOnlineMeeting && <span>{t('online')}</span>}
                <span>
                  {getAppointmentDate(
                    appointment.selectedDate,
                    appointment.selectedTime,
                    appointment.selectedEndTime,
                    brandConfig?.languageCode
                  )}
                </span>
                {!isHTH && (
                  <span>
                    {appointment.serviceProviderName} ({t('your-designer')})
                  </span>
                )}
              </InfoItem>
            </>
          )) || <Spacer />}
        </Details>
      </AppointmentBox>
      <ButtonContainer>
        <Button onClick={() => navigateToStep(2, history)}>
          <StyledPenIcon width="18px" />
          <span>{t('button-text')}</span>
        </Button>
      </ButtonContainer>
    </>
  )
}

export default Amend
