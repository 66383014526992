import React from 'react'

const ErrorIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 29 29" color={color}>
    <g fill="currentColor" fillRule="evenodd">
      <g transform="translate(-1058.000000, -71.000000)" fillRule="nonzero">
        <g transform="translate(40.000000, 41.000000)">
          <g transform="translate(1018.000000, 30.000000)">
            <path d="M20.5062222,0 L8.49377778,0 L0,8.49377778 L0,20.5062222 L8.49377778,29 L20.5062222,29 L29,20.5062222 L29,8.49377778 L20.5062222,0 Z M16.1111111,22.5555556 L12.8888889,22.5555556 L12.8888889,19.3333333 L16.1111111,19.3333333 L16.1111111,22.5555556 Z M16.1111111,16.1111111 L12.8888889,16.1111111 L12.8888889,6.44444444 L16.1111111,6.44444444 L16.1111111,16.1111111 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ErrorIcon
