import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { Row, Column } from '../grid'

const StyledRow = styled(Row).attrs({ component: 'section' })`
  height: calc(100% - ${prop('theme.overlay.header.height')});
`

const StyledColumn = styled(Column)`
  height: 100%;
  padding-top: ${prop('theme.grid.gutter')};
  padding-bottom: ${prop('theme.grid.gutter')};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const FullscreenOverlayContent = ({ children, ...props }) => (
  <StyledRow {...props}>
    <StyledColumn>{children}</StyledColumn>
  </StyledRow>
)

FullscreenOverlayContent.defaultProps = {
  children: null,
}

export default FullscreenOverlayContent
