import React from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { StepSubmit } from './Components'
import { badaPropTypes, badaDefaultProps } from '../../../../types'
import tracker from '../../../../utils/tracker'

const SubmitStep = ({
  props: {
    badaProps: { bookings, overrideMeetingType },
    pageContent,
    wizard: {
      step: { two, three, four },
    },
    selectedStore,
    selectedSlot,
    selectedService,
    updateUiState,
    values,
    history,
  },
}) => (
  <StepSubmit
    show={four}
    props={{
      bookings,
      selectedSlot,
      selectedStore,
      selectedService,
      pageContent,
      values,
      updateUiState,
      overrideMeetingType,
      onUpdate: () => {},
      onMount: () => {
        tracker.trackPageview('BADA 2.0 dataform', '/bada/step-3')
        if (!two && !three) {
          tracker.trackAuto(
            'BADA 2.0 meeting type',
            selectedStore.acceptMeetings
              ? `singletype store - ${selectedStore.name}`
              : `singletype home - ${selectedStore.name}`
          )
        }
      },
      addHistory: (id, storeid) => {
        const separator = window.location.search ? '&' : '?'
        const notRepeatVisitByEmail = 'visit=first'
        history.push(
          `${window.location.search}${separator}appointment=${id}&studioid=${storeid}&${notRepeatVisitByEmail}#complete`,
          { bookingCompleted: false }
        )
      },
    }}
  />
)

SubmitStep.propTypes = {
  ...badaPropTypes,
}

SubmitStep.defaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(withApollo)

export default enhance(SubmitStep)
