import queryString from 'query-string'
import { v4 } from 'uuid'
import { sessionSelector } from './authSelectors'
import {
  authRequest,
  authRequestSuccess,
  authRequestError,
  authRefreshSuccess,
} from './authActions'
import LocalAuthStore from './LocalAuthStore'
import { calculateExpiration, hasSessionExpired } from './helpers'

/* eslint-disable no-shadow */
const Client = (options) => {
  const refreshToken = ({ refreshToken }, callback) => {
    if (!refreshToken || !options.provider) {
      callback(new Error('missing refresh token or provider'), null)
    } else {
      const url = `https://${options.domain}/refresh`
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          refreshToken,
          provider: options.provider,
          usetokens: true,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => ({
          accessToken: json.access_token,
          refreshToken: json.refresh_token,
          idToken: json.id_token,
          expiresIn: json.expires_in,
        }))
        .then((json) => callback(null, json))
        .catch((err) => callback(err, null))
    }
  }

  const login = () => {
    const state = v4()
    localStorage.setItem('login-state', state)
    const query = {
      provider: options.provider,
      usetokens: true,
      state,
      callbackUri: options.redirectUri,
      brand: options.brand,
    }
    const url = `https://${options.domain}/authorize?${queryString.stringify(
      query
    )}`
    window.location.href = url
  }

  const callback = (callback) => {
    const { state, code } = queryString.parse(window.location.search)
    if (!code) {
      callback(new Error('code missing'), null)
      return
    }

    const storedState = localStorage.getItem('login-state')
    if (storedState !== state) {
      callback(new Error('stored state mismatch'), null)
      return
    }

    window.history.replaceState(
      null,
      '',
      window.location.origin + window.location.pathname
    )
    const url = `https://${options.domain}/authorize-code`
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        code,
        provider: options.provider,
        usetokens: true,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => ({
        accessToken: json.access_token,
        refreshToken: json.refresh_token,
        idToken: json.id_token,
        expiresIn: json.expires_in,
      }))
      .then((json) => callback(null, json))
      .catch((err) => callback(err, null))
  }

  return { refreshToken, login, callback }
}

export default class AuthNobiaClient {
  constructor(options, { store, preserveState = true } = {}) {
    this.client = Client(options)
    this.localStore = new LocalAuthStore(options.clientID)
    this.store = store
    this.session = sessionSelector(store.getState())
    this.preserveState = preserveState
    this.onStateChange = this.onStateChange.bind(this)
  }

  subscribe() {
    if (this.unsubscribeFromStore == null) {
      const session = sessionSelector(this.store.getState())

      if (session != null) {
        this.setSession(session)

        if (this.preserveState) {
          this.setLocalSession(session)
        }
      }

      this.unsubscribeFromStore = this.store.subscribe(this.onStateChange)
    }
  }

  unsubscribe() {
    if (this.unsubscribeFromStore != null) {
      this.unsubscribeFromStore()
      this.unsubscribeFromStore = null
    }
  }

  onStateChange() {
    const oldSession = this.session
    const newSession = sessionSelector(this.store.getState())

    if (oldSession !== newSession) {
      this.setSession(newSession)

      if (this.preserveState) {
        this.setLocalSession(newSession)
      }
    }
  }

  getSession() {
    return this.session
  }

  setSession(session) {
    this.session = session
  }

  getLocalSession() {
    return this.localStore.get('session')
  }

  setLocalSession(session) {
    if (session == null) {
      this.localStore.delete('session')
    } else {
      this.localStore.set('session', session)
    }
  }

  isAuthenticated() {
    const session = this.getSession()

    return session != null && hasSessionExpired(session) === false
  }

  restoreLocalSession() {
    const localSession = this.getLocalSession()

    if (localSession != null && !hasSessionExpired(localSession)) {
      this.store.dispatch(authRequestSuccess(localSession))
    }
  }

  handleAuthentication(callback) {
    this.store.dispatch(authRequest())

    this.client.callback((err, result) => {
      if (err) {
        this.store.dispatch(authRequestError(err))
      } else {
        this.store.dispatch(
          authRequestSuccess({
            ...result,
            expiresAt: calculateExpiration(result),
          })
        )
      }

      if (callback != null) {
        callback(err, result)
      }
    })
  }

  login() {
    this.client.login()
  }

  logout() {
    this.localStore.clear()
    this.store.dispatch(authRequestError({}))
  }

  renewToken(callback) {
    const { refreshToken } = sessionSelector(this.store.getState())
    this.client.refreshToken({ refreshToken }, (err, result) => {
      if (err) {
        this.store.dispatch(authRequestError(err))
      } else {
        this.store.dispatch(
          authRefreshSuccess({
            ...result,
            expiresAt: calculateExpiration(result),
          })
        )
      }

      if (callback != null) {
        callback(err, result)
      }
    })
  }
}
