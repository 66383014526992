import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Link from '../../components/typography/link'
import { SectionHeader } from './sharedUI'
import useTranslation from '../../../utils/translation'

const PrepImages = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0px;
`

const StyledImage = styled.img`
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  ${(props) => props.theme.media.min.sm`
    max-width: 300px;
  `}
`

const WillBringImage = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-weight: 300;
`

const checkIsImage = (url) => {
  const fileType = url.toLowerCase().substring(url.lastIndexOf('.'))
  return {
    isImage:
      fileType === '.png' ||
      fileType === '.jpg' ||
      fileType === '.jpeg' ||
      fileType === '.tiff' ||
      fileType === '.tif' ||
      fileType === '.heif' ||
      fileType === '.webp',
    fileType,
  }
}

const Images = ({ appointmentPreparations: ap, features: f }) => {
  const { kitchenScetchImagesUrls, currentKitchenImagesUrls } = ap
  const t = useTranslation('appointment-details')

  return (
    <>
      {ap &&
        ((currentKitchenImagesUrls && currentKitchenImagesUrls.length > 0) ||
          ap.willBringCurrentKitchenImages) && (
          <>
            <SectionHeader>{t('prep-form.images')}</SectionHeader>
            <PrepImages>
              {currentKitchenImagesUrls
                .filter((url) => url.startsWith('http'))
                .map((url, index) => {
                  const { isImage, fileType } = checkIsImage(url)
                  return isImage ? (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      <StyledImage key={url} src={url} />
                    </a>
                  ) : (
                    <Link href={url} target="_blank" rel="noopener noreferrer">
                      {t('prep-form.upload')}-{index + 1}
                      {fileType}
                    </Link>
                  )
                })}
              {ap && ap.willBringCurrentKitchenImages && (
                <WillBringImage>
                  {t('prep-form.will-bring-images')}
                </WillBringImage>
              )}
            </PrepImages>
          </>
        )}
      {!f.hidePhotoUploadSketch &&
        ((kitchenScetchImagesUrls && kitchenScetchImagesUrls.length > 0) ||
          ap.willBringKitchenScetchImages) && (
          <>
            <SectionHeader>{t('prep-form.sketches')}</SectionHeader>
            <PrepImages>
              {kitchenScetchImagesUrls.map((url) => (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={url}
                >
                  <StyledImage src={url} />
                </a>
              ))}
              {ap && ap.willBringKitchenScetchImages && (
                <WillBringImage>
                  {this('prep-form.will-bring-sketch')}
                </WillBringImage>
              )}
            </PrepImages>
          </>
        )}
    </>
  )
}

Images.propTypes = {
  appointmentPreparations: PropTypes.shape(),
  features: PropTypes.shape(),
}

Images.defaultProps = {
  appointmentPreparations: {},
  imagesAndUploadsUrls: [],
  features: {},
}

export default Images
