import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { useMutation } from '@apollo/react-hooks'
import UpdateBookingDetailsMutation from '../../../graphql/UpdateBookingDetailsAndStayIfPossible.graphql'

import useSbmCalendar from '../../hooks/appointment/useSbmCalendar'
import useListDesignerAppointments from '../../hooks/appointment/useDesignerAppointments'
import useTranslation from '../../../utils/translation'

import settings from '../../components/settings'
import Loader from '../../components/loader/Loader'

import { getDisplayData, findServiceData } from '../../containers/bada/content'

import { SIMPLY_BOOK_ME } from '../bada/reducer/Wizard'
import { navigateToStep } from '../utils'
import MeetingTypes from './MeetingTypes'
import getServiceById from './utils'
import Calendar from '../../components/calendar/Calendar'
import { filterMeetingTypes } from '../../components/meeting-type-selection'
import PageTitle from '../../components/typography/pageTitle'
import useBrandConfig from '../../hooks/useBrandConfig'
import features from '../../components/features'
import CalendarSlim from '../../components/calendar2/calendar-slim/CalendarSlim'

const Heading = styled(PageTitle)`
  text-align: center;
  margin: ${theme('amend.step2.heading.margin')};
  color: ${(props) => theme('amend.step2.heading.color')(props)};
`

const Preamble = styled.p`
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  font-size: 18px;
  max-width: 600px;
  margin: 0px auto 50px auto;
  display: block;
`

const Button = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  background-color: ${theme('amend.step2.button.backgroundColor')};
  color: #ffffff;
  text-decoration: none;
  font-size: ${(props) =>
    theme('amend.step2.button.fontSize')(props) || '16px'};
  text-transform: ${(props) =>
    theme('amend.step2.button.textTransform')(props) || 'none'};
  border-radius: ${theme('amend.step2.button.borderRadius')};
  height: ${(props) => theme('amend.step2.button.height')(props) || '48px'};
  text-align: center;
  width: 206px;
  padding: ${theme('amend.step2.button.padding')};
  font-weight: ${(props) =>
    theme('amend.step2.button.fontWeight')(props) || '400'};
  margin-right: 20px;
  cursor: pointer;
  ${(props) => props.theme.media.min.sm`
    margin-right: 0px;
  `}
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
  transition: opacity 0.5s;
  :hover {
    opacity: 0.9;
  }
`

const WhiteButton = styled(Button)`
  background-color: ${theme('amend.step2.whiteButton.backgroundColor')};
  border-radius: ${theme('amend.step2.whiteButton.borderRadius')};
  padding: ${theme('amend.step2.button.padding')};
  border: ${theme('amend.step2.button.border')};
  color: ${theme('amend.step2.button.color')};
  width: 120px;
  margin-left: 20px;
  transition: opacity 0.5s;
  :hover {
    opacity: 0.9;
  }
  ${(props) => props.theme.media.min.sm`
    margin-left: ${theme('amend.step2.button.marginLeft')};
  `}
`

const StickyNav = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: ${theme('amend.step2.stickyNav.backgroundColor')};
  width: 100vw;
  height: 100px;
  border-top: 1px solid #cecfce;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 25px auto 0px;
  padding: ${theme('amend.step2.buttonContainer.padding')};
  max-width: 1100px;
`

const CalenderContainer = styled.div`
  position: relative;
  width: calc(100vw - 40px);
  margin: 0px auto;
  ${(props) => props.theme.media.min.sm`
    width: 100%;
  `}
`

const CalendarHeading = styled.h2`
  font-family: ${theme('amend.step2.calendarHeading.fontFamily')};
  font-size: ${theme('amend.step2.calendarHeading.fontSize.mobile')};
  font-weight: ${theme('amend.step2.calendarHeading.fontWeight')};
  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('amend.step2.calendarHeading.fontSize.desktop')};
  `}
`
const Thin = styled.span`
  font-family: ${theme('amend.step2.calendarHeading.fontFamily')};
  font-weight: ${theme('amend.step2.thin.fontWeight')};
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 5px;
`
const LinkLabel = styled.label`
  font-weight: 700;
`
const LinkButton = styled.button`
  background-color: inherit;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  color: ${theme('amend.step2.linkButton.color')};
`
const LoaderCover = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
`

const LoaderContainer = styled.div`
  margin-top: 40vh;
`

const useAppointments = ({
  useDesigner,
  appointment,
  storeId,
  locationId,
  serviceId,
}) => {
  if (useDesigner) {
    const { data: appointmentsList, loading: appointmentsLoading } =
      useListDesignerAppointments({
        storeId,
        locationId,
        serviceId,
        appointmentId: appointment.id,
      })

    const appointments = appointmentsList && appointmentsList?.designerBookings

    return {
      appointments,
      appointmentsLoading,
    }
  }

  const { days, loading } = useSbmCalendar({
    meetingType: serviceId.toString(),
    storeId,
    locationId,
    sbmInstance: appointment.simplyBookMeSubsystem,
  })

  return {
    appointments: days,
    appointmentsLoading: loading,
  }
}

const AmendStep2 = ({
  appointment,
  store,
  pageContent,
  refetchAppointment,
}) => {
  const t = useTranslation('amend.step2')
  const history = useHistory()

  const [selectionMade, setSelectionMade] = useState(false)
  const [selectedDate, setSelectedDate] = useState(appointment.selectedDate)
  const [selectedTime, setSelectedTime] = useState(appointment.selectedTime)
  const [useDesigner, setUseDesigner] = useState(true)

  // Create a mapped store to support the filterMeetingtypes - method.
  const mappedStore = {
    id: store.id,
    simplyBookMeSubsystem:
      store?.externalMeetingData?.meetingData?.simplyBookMeSubsystem,
    services: store.services,
    bookingSystem: SIMPLY_BOOK_ME,
    hasSimplyBookMe: store?.externalMeetingData?.meetingData?.hasSimplyBookMe,
    acceptHomeVisits: store?.externalMeetingData?.meetingData?.acceptHomeVisits,
  }

  const PAGE_SIZE = 10
  const END_RANGE = moment()
    .add(PAGE_SIZE - 1, 'days')
    .toISOString()
    .split('T')[0]

  const brandConfig = useBrandConfig(settings.brand).data
  const displayData = getDisplayData(pageContent)
  const filteredMeetingTypes = filterMeetingTypes(
    displayData.services,
    mappedStore,
    false,
    appointment.categoryId,
    appointment.client
  )

  const service = filteredMeetingTypes.find(
    (c) => c.id === parseInt(appointment.serviceId, 10)
  )

  const serviceData = findServiceData(displayData, service)

  const startDate =
    brandConfig &&
    brandConfig.ianaTimeZoneId &&
    moment.tz(brandConfig.ianaTimeZoneId).format().split('T')[0]

  const [selectedMeetingType, setSelectedMeetingType] = useState(service)

  const [updateAppointment, { loading: updateAppointmentLoading }] =
    useMutation(UpdateBookingDetailsMutation, {
      variables: {
        input: {
          subsystem: appointment.simplyBookMeSubsystem,
          appointmentId: appointment.id,
          storeId: parseInt(appointment.storeId, 10),
          bookingId: parseInt(appointment.simplyBookMeId, 10),
          locationId: parseInt(appointment.externalStoreId, 10),
          serviceId: selectedMeetingType.id,
          date: selectedDate,
          time: selectedTime,
          updatedBy: 'Customer',
        },
      },
      onCompleted: () => {
        refetchAppointment()
        navigateToStep(3, history, false)
      },
    })

  const { appointments, appointmentsLoading } = useAppointments({
    useDesigner,
    appointment,
    storeId: appointment.storeId,
    locationId: appointment.externalStoreId,
    serviceId: selectedMeetingType.id,
  })

  const handleMeetingTypeChange = () => (newServiceId) => {
    setSelectedMeetingType(
      getServiceById(
        pageContent,
        newServiceId,
        appointment.simplyBookMeSubsystem
      )
    )
    setSelectedDate(undefined)
    setSelectedTime(undefined)
    setSelectionMade(false)
  }

  const handleUseDesignerChange = () => {
    setUseDesigner(!useDesigner)
    setSelectedDate(undefined)
    setSelectedTime(undefined)
    setSelectionMade(false)
  }

  const handleCalendarChange = () => (calendar) => {
    setSelectedDate(calendar.selectedSlot.from.substr(0, 10))
    setSelectedTime(calendar.selectedSlot.from.substr(11, 5))
    setSelectionMade(true)
  }

  const filteredDays = settings.disableSameDayBooking
    ? appointments?.filter((d) => moment(d.day) > moment())
    : appointments

  return (
    <>
      <Heading>{t('heading')}</Heading>
      <Preamble>{t('preamble')}</Preamble>
      {filteredMeetingTypes && (
        <MeetingTypes
          meetingTypes={filteredMeetingTypes}
          selectedMeetingType={selectedMeetingType}
          selectedStore={mappedStore}
          onChange={handleMeetingTypeChange()}
        />
      )}
      <CalendarHeading>
        {t('calendar.heading')}
        <Thin>
          {useDesigner
            ? ` (${t('calendar.with-designated-designer')} ${
                appointment.serviceProviderName
              })`
            : ` (${t('calendar.with-any-designer')})`}
        </Thin>
      </CalendarHeading>
      <CalenderContainer>
        {appointmentsLoading ? (
          <Loader duration={1} />
        ) : (
          <>
            {features.newStyleCalendar ? (
              <CalendarSlim
                loading={appointmentsLoading}
                allDataLoaded={!appointmentsLoading}
                days={filteredDays || []}
                allDays={filteredDays || []}
                startDate={startDate}
                endDate={END_RANGE}
                onChange={handleCalendarChange()}
                serviceData={serviceData}
                meetingTypeId={service.id}
                brandConfig={brandConfig}
              />
            ) : (
              <Calendar
                days={filteredDays}
                onChange={handleCalendarChange()}
                service={service}
              />
            )}
          </>
        )}
      </CalenderContainer>
      {!appointmentsLoading && (
        <LinkWrapper>
          {useDesigner && (
            <LinkLabel>{t('calendar.all-designers-link.header')}</LinkLabel>
          )}
          <LinkButton onClick={handleUseDesignerChange}>
            {useDesigner
              ? t('calendar.all-designers-link.label')
              : t('calendar.designated-designer-link.label')}
          </LinkButton>
        </LinkWrapper>
      )}
      <StickyNav>
        <ButtonContainer>
          <WhiteButton onClick={() => navigateToStep(1, history)}>
            {t('prev')}
          </WhiteButton>
          <Button
            onClick={() => {
              if (selectionMade) {
                updateAppointment()
              }
            }}
            disabled={!selectionMade}
          >
            {t('next')}
          </Button>
        </ButtonContainer>
      </StickyNav>
      {updateAppointmentLoading && (
        <LoaderCover>
          <LoaderContainer>
            <Loader duration={1} />
          </LoaderContainer>
        </LoaderCover>
      )}
    </>
  )
}

export default AmendStep2
