import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import Label, { LabelText, ExplanationText } from './label'
import InformationInputIcon from '../icons/informationInput'
import CheckmarkFilled from '../icons/checkmarkFilled'
import Checkmark from '../icons/checkmark'

const StyledInformationInputIcon = styled(InformationInputIcon)`
  position: absolute;
  top: 21px;
  top: ${theme('forms.position.top')};
  transform: translateY(-50%);
  right: 13px;
  z-index: 5;
  color: ${theme('forms.colors.invalid')};
`

const ValidLabelIcon = styled(Checkmark)`
  position: absolute;
  top: 50%;
  top: ${theme('forms.position.top')};
  transform: translateY(-50%);
  right: 15px;
  z-index: 5;
`

const ValidIcon = styled(CheckmarkFilled)`
  position: absolute;
  top: 50%;
  top: ${theme('forms.position.top')};
  transform: translateY(-50%);
  right: 13px;
  z-index: 5;
  color: ${(p) => p.theme.forms.colors.valid};
`

const StyledInput = styled.input`
  border: 1px solid ${theme('forms.colors.border')};
  padding: 0 ${theme('forms.padding')};
  padding: ${theme('forms.paddingInput')};
  width: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px ${theme('forms.colors.prefilledBackground')}
      inset !important;
  }
  &::placeholder {
    color: ${theme('forms.colors.placeholder')};
  }

  position: relative;
  box-sizing: border-box;
  min-width: 120px;
  height: ${theme('forms.height')};
  border-radius: ${theme('forms.borderRadius')};
  outline: none;
  font-family: ${theme('forms.fontFamily')};
  font-weight: ${theme('forms.fontWeight')};
  font-size: ${theme('forms.fontSize')};
  line-height: ${theme('forms.height')};
  color: ${theme('forms.colors.color')};
  background-color: ${theme('forms.colors.background')};
  z-index: 1;

  &[data-valid='true'] {
    padding-right: 45px;
    border: 1px solid ${theme('forms.colors.border')};
  }
  &[data-valid='true']:focus {
    border: 1px solid ${theme('forms.colors.border')};
  }

  &[data-valid='false'] {
    border: 1px solid ${theme('forms.colors.invalidBorder')};
  }

  &[data-valid='false']:focus {
    border: 1px solid ${theme('forms.colors.invalidBorder')};
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${theme('forms.colors.activeBorder')};
      color: ${props.theme.forms.colors.active};
    `};

  &:focus {
    outline: none;
    border: 1px solid ${theme('forms.colors.activeBorder')};
    box-shadow: ${theme('colors.accentShadow')};
  }

  ${(props) =>
    props.theme.media.min.sm`
    font-size: ${theme('forms.fontSizeDesktop')};`};
`
const ErrorMessage = styled.span`
  color: ${theme('forms.colors.invalid')};
  font-size: ${theme('forms.errorMessage.fontSize')};
  text-align: left;
  display: block;
  padding-top: 7px;
`

const InputWrapper = styled.span`
  position: relative;
  display: block;
  min-height: 75px;
  min-height: ${theme('forms.inputWrapper.minHeight')};
`

const InputField = (props) => {
  const { label, valid, error, type, inputRef, explanation, ...rest } = props
  const showLabel = label !== ''

  const isValid = !!(valid === undefined || valid === true)

  const input = (
    <StyledInput
      type={type}
      ref={inputRef}
      isValid={isValid}
      data-valid={`${isValid}`}
      {...rest}
    />
  )

  const errorMsg =
    !isValid && error !== '' ? <ErrorMessage>{error}</ErrorMessage> : null

  return showLabel ? (
    <InputWrapper>
      <Label>
        <LabelText>{label}</LabelText>
        {input}
        {errorMsg}

        <ExplanationText>{explanation}</ExplanationText>
      </Label>
      {valid && <ValidLabelIcon width={23} height={23} />}
    </InputWrapper>
  ) : (
    <InputWrapper>
      {input}
      {errorMsg}
      {isValid && valid !== undefined && <ValidIcon width={23} height={23} />}
      {errorMsg && !isValid && valid !== undefined && (
        <StyledInformationInputIcon width={23} height={23} />
      )}
    </InputWrapper>
  )
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  explanation: PropTypes.string,
  valid: PropTypes.bool,
  error: PropTypes.string,
  inputRef: PropTypes.shape(),
}

InputField.defaultProps = {
  type: 'text',
  label: '',
  explanation: '',
  valid: undefined,
  error: '',
  inputRef: undefined,
}

export default InputField
