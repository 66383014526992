import React from 'react'
import getTheme from '../themes/getTheme'
import createMediaQueries from './createMediaQueries'

const { breakpoints } = getTheme()
const mediaQueries = createMediaQueries(breakpoints)

const BreakpointContext = React.createContext({
  breakpoints,
  mediaQueries,
})

export default BreakpointContext
