import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ value, sizes, className, loading }) => {
  const { srcSet, url, alt } = value
  const srcSetValue =
    srcSet && srcSet.map(elem => `${elem.url} ${elem.size}`).join(', ')

  return (
    <img
      className={className}
      srcSet={srcSetValue}
      src={url}
      alt={alt}
      sizes={sizes}
      loading={loading}
    />
  )
}

Image.defaultProps = {
  sizes:
    '(max-width: 390px) 390px, (max-width: 690px) 690px, (max-width: 990px) 990px, (max-width: 1290px) 1290px, (max-width: 2580px) 2580px, 100vw',
  value: { srcSet: [], url: '', alt: '' },
  className: null,
  loading: 'auto',
}

Image.propTypes = {
  value: PropTypes.shape({
    srcSet: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        size: PropTypes.string,
      })
    ),
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  sizes: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string,
}

export default Image
