import { useSelector } from '../redux'
import { useAuthentication } from './AuthProvider'
import {
  statusSelector,
  errorSelector,
  sessionSelector,
  profileSelector,
} from './authSelectors'

function useAuth() {
  const { client } = useAuthentication()
  const status = useSelector((state) => statusSelector(state))
  const error = useSelector((state) => errorSelector(state))
  const session = useSelector((state) => sessionSelector(state))
  const profile = useSelector((state) => profileSelector(state))

  return { status, error, session, profile, client }
}

export default useAuth
