import React from 'react'
import PropTypes from 'prop-types'

const Upgrade = ({ width, height }) => (
  <svg viewBox="0 0 96 96" width={width} height={height}>
    <path
      d="M7 89h82V60H7v29zM2 53h92a2 2 0 0 1 2 2v39a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V55a2 2 0 0 1 2-2zm55 20h25a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H57a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2zm0-8h3a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2zm11 0h3a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2zm11 0h3a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2zM6 6v24h31V6H6zM2 0h39a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm11 20h3a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2zM63 0h14a2 2 0 0 1 2 2v3H61V2a2 2 0 0 1 2-2zm19.703 27H58.297l-1.714 4h27.834l-1.714-4zM79 22h5.681a2 2 0 0 1 1.838 1.212l4.286 10A2 2 0 0 1 88.967 36H52.033a2 2 0 0 1-1.838-2.788l4.286-10A2 2 0 0 1 56.319 22H61V2.5h5V22h8V2.5h5V22zM43 56h7v38.064h-7V56zm-29 9h3a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2z"
      fill="currentColor"
    />
  </svg>
)

Upgrade.defaultProps = {
  width: 50,
  height: 50,
}

Upgrade.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Upgrade
