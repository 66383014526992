import React from 'react'
import { compose, withState } from 'recompose'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import settings from '../settings'
import populateVariables from '../../../utils/cms'
import useTranslation from '../../../utils/translation'
import ArrowDownIcon from '../icons/arrow-down'

const Container = styled.div`
  position: relative;

  top: ${theme('contentHeader.otherNeeds.mobile.positionTop')};
  margin-bottom: ${theme('contentHeader.otherNeeds.mobile.marginBottom')};

  ${(props) => props.theme.media.min.sm`
    top: ${theme('contentHeader.otherNeeds.desktop.positionTop')};
    margin-bottom: ${theme('contentHeader.otherNeeds.desktop.marginBottom')};
  `}
`

const Link = styled.div`
  background-color: ${theme('contentHeader.otherNeeds.backgroundColor')};
  cursor: pointer;
  height: ${theme('contentHeader.otherNeeds.height')};

  a {
    display: block;
    margin: 0px auto;
    text-align: center;

    padding: ${theme('contentHeader.otherNeeds.padding.mobile')};
    ${(props) => props.theme.media.min.sm`
      padding: ${theme('contentHeader.otherNeeds.padding.desktop')};
    `}

    span {
      font-family: ${theme('typography.paragraph.accentFontFamily')};
      font-size: ${theme('contentHeader.otherNeeds.fontSize.mobile')};
      line-height: ${theme('contentHeader.otherNeeds.lineHeight')};
      color: ${theme('contentHeader.otherNeeds.color')};
      text-decoration: ${theme('contentHeader.otherNeeds.textDecoration')};
      font-weight: ${theme('contentHeader.otherNeeds.fontWeight')};
      padding-left: 10px;

      ${(props) => props.theme.media.min.sm`
        font-size: ${theme('contentHeader.otherNeeds.fontSize.desktop')};
      `}
    }
    svg {
      transition: transform cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.5s;
      color: ${theme('contentHeader.otherNeeds.color')};
    }
    ${(props) =>
      props.active &&
      css`
        svg {
          transform: rotate(180deg);
        }
      `}
  }
`

const PopDown = styled.div`
  display: none;
  ${(props) =>
    props.active &&
    css`
      display: block;
    `}
  background-color: #f4f2f0;
  background-color: ${theme('contentHeader.otherNeeds.popDownBgColor')};
  padding: 25px;
  text-align: center;
  ${(props) => props.theme.media.min.sm`
    padding: 45px;
  `}
`

const Item = styled.div`
  max-width: 600px;
  margin: 0px auto;
`

const Text = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 17px;
  line-height: 26px;
  a {
    color: ${theme('colors.blue')};
  }
`

const TextSpecial = styled(Text)`
  font-weight: ${theme('contentHeader.otherNeeds.fontWeight')};
  font-size: ${theme('contentHeader.otherNeeds.fontSize.mobile')};
  line-height: ${theme('contentHeader.otherNeeds.lineHeight')};

  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('contentHeader.otherNeeds.fontSize.desktop')};
  `}

  strong {
    display: block;
    font-weight: ${theme('contentHeader.otherNeeds.mobile.fontWeight')};
    font-weight: ${theme('contentHeader.otherNeeds.strong.fontWeight')};

    font-size: ${theme('contentHeader.otherNeeds.mobile.fontSize')};
    font-size: ${theme('contentHeader.otherNeeds.strong.fontSize')};
    font-family: ${theme('contentHeader.otherNeeds.strong.fontFamily')};
    line-height: ${theme('contentHeader.otherNeeds.strong.lineHeight.mobile')};

    ${(props) => props.theme.media.min.sm`
      font-size: ${theme('contentHeader.otherNeeds.fontSize.desktop')};      
      font-size: ${theme('contentHeader.otherNeeds.strong.fontSize')};
      font-size: ${theme('contentHeader.otherNeeds.strong.fontSizeDesktop')};
      font-weight: ${theme('contentHeader.otherNeeds.fontWeight')};
      font-weight: ${theme('contentHeader.otherNeeds.strong.fontWeight')};
      line-height: ${theme('contentHeader.otherNeeds.lineHeight')};
      line-height: ${theme(
        'contentHeader.otherNeeds.strong.lineHeight.desktop'
      )};
    `}
  }

  a {
    color: ${theme('contentHeader.otherNeeds.linkColor')};
  }
`

const makeStoreLink = (url, text) => `<a href='${url}'>${text}</a>`

const OtherNeeds = ({ otherNeeds, toggleDetails, detailsActive }) => {
  const t = useTranslation('app.header')
  return (
    <>
      <Container>
        <Link active={detailsActive}>
          <a onClick={() => toggleDetails(!detailsActive)}>
            <ArrowDownIcon width={10} height={10} />
            <span>{otherNeeds.heading}</span>
          </a>
        </Link>
        <PopDown active={detailsActive}>
          <Item key={otherNeeds.heading}>
            <TextSpecial
              dangerouslySetInnerHTML={{
                __html: populateVariables(otherNeeds.text, [
                  {
                    variable: '{storeLink}',
                    value: makeStoreLink(
                      settings.findStoreUrl,
                      t('store-link')
                    ),
                  },
                ]),
              }}
            />
          </Item>
        </PopDown>
      </Container>
    </>
  )
}

OtherNeeds.defaultProps = {
  detailsActive: false,
}

OtherNeeds.propTypes = {
  otherNeeds: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  toggleDetails: PropTypes.func.isRequired,
  detailsActive: PropTypes.bool,
}

const enhance = compose(withState('detailsActive', 'toggleDetails', false))

export default enhance(OtherNeeds)
