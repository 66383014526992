import moment from 'moment'
import { getLocationId, endTimeFromLength } from '../utils'
import appInsights, {
  SeverityLevel,
} from '../../../components/logging/appInsights'

export const isValid = (formData, timeOptions, lengthOptions) => {
  let valid = true
  let reason = null
  if (!formData.newSelectedTime) {
    valid = false
    reason = 'Please select time.'
  }
  if (!formData.newStoreId) {
    valid = false
    reason = 'Please select store.'
  }
  if (!formData.newServiceProviderId) {
    valid = false
    reason = 'Please select schedule.'
  }

  if (!formData.newSelectedDate) {
    valid = false
    reason = 'Please select date.'
  }
  if (!formData.newSelectedTime) {
    valid = false
    reason = 'Please select valid start time.'
  }
  if (!formData.newSelectedEndTime) {
    valid = false
    reason = 'Please select end time.'
  }
  if (
    moment(`${formData.newSelectedDate} ${formData.newSelectedTime}`) < moment()
  ) {
    valid = false
    reason = 'Date must be in the future.'
  }
  if (
    moment(`1970-01-01 ${formData.newSelectedTime}`) >=
    moment(
      `1970-01-01 ${endTimeFromLength(
        formData.newSelectedTime,
        formData.newSelectedLength
      )}`
    )
  ) {
    valid = false
    reason = 'End time must be after start time.'
  }
  const timeOption = timeOptions.find(
    (t) => t.value === formData.newSelectedTime
  )
  const lengthOption = lengthOptions.find(
    (t) => t.value === formData.newSelectedLength
  )
  const validTime = timeOption && !timeOption.disabled
  const validDuration = lengthOption && !lengthOption.disabled

  if (!validTime) {
    valid = false
    reason = 'Invalid time selected'
  }
  if (!validDuration) {
    valid = false
    reason = 'Invalid duration selected'
  }
  return { valid, reason }
}

export const update = (
  simplyBookMeSubsystem,
  appointmentId,
  simplyBookMeId,
  formData,
  storesData,
  updateBookingDetailsMutation,
  setLoading,
  setError,
  onUpdate,
  timeOptions,
  lengthOptions
) => {
  const validation = isValid(formData, timeOptions, lengthOptions)
  if (!validation.valid) {
    /* eslint-disable no-alert */
    alert(JSON.stringify(formData))
    return
  }

  setLoading(true)
  setError(null)

  const newLocationId = getLocationId(formData.newStoreId, storesData)
  updateBookingDetailsMutation({
    variables: {
      input: {
        subsystem: simplyBookMeSubsystem,
        appointmentId,
        storeId: formData.newStoreId,
        bookingId: parseInt(simplyBookMeId, 10),
        locationId: parseInt(newLocationId, 10),
        serviceId: parseInt(formData.newServiceId, 10),
        serviceProviderId: parseInt(formData.newServiceProviderId, 10),
        date: formData.newSelectedDate,
        time: formData.newSelectedTime,
        endTime: endTimeFromLength(
          formData.newSelectedTime,
          formData.newSelectedLength
        ),
      },
    },
  })
    .then(() => {
      onUpdate()
    })
    .catch((error) => {
      const message = error && error.message
      appInsights.trackException({
        exception: new Error('Failed to update appointment details.'),
        severityLevel: SeverityLevel.Error,
      })
      setError(
        message || 'Failed to update appointment. Please try again later.'
      )
    })
    .finally(() => {
      setLoading(false)
    })
}

export const cancel = (
  externalBookingSystem,
  storeId,
  appointmentId,
  simplyBookMeSubsystem,
  externalAppointmentId,
  cancelAppointmentMutation,
  setLoading,
  onCancel
) => {
  setLoading(true)
  /* eslint-disable no-alert */
  if (confirm('Are you sure you want to cancel the appointment?')) {
    cancelAppointmentMutation({
      variables: {
        input: {
          bookingSystem: externalBookingSystem,
          storeId,
          appointmentId,
          externalAppointmentId,
          subsystem: simplyBookMeSubsystem,
        },
      },
    }).then(() => {
      setLoading(false)
      onCancel()
    })
  } else {
    setLoading(false)
  }
}
