export const actionTypes = {
  ADD_KITCHEN_CONFIGURATION: 'ADD_KITCHEN_CONFIGURATION',
  CLEAR_KITCHEN_CONFIGURATIONS: 'CLEAR_KITCHEN_CONFIGURATIONS',
  ADD_WISHLIST: 'ADD_WISHLIST',
  REMOVE_WISHLIST: 'REMOVE_WISHLIST',
  CLEAR_WISHLIST: 'CLEAR_WISHLIST',
  VIEW_PRODUCT: 'VIEW_PRODUCT',
  SET_ONBOARDING_RESULT: 'SET_ONBOARDING_RESULT',
  CLEAR_ONBOARDING_RESULT: 'CLEAR_ONBOARDING_RESULT',
  RESET_NOTIFICATIONS: 'RESET_NOTIFICATIONS',
  UPDATED_SHARED_STATE: 'UPDATED_SHARED_STATE',
}

// Wishlist
export const addWishlist = (product) => ({
  type: actionTypes.ADD_WISHLIST,
  product: {
    ...product,
    favoritedAt: new Date().toISOString(),
  },
})

export const removeWishlist = (productId) => ({
  type: actionTypes.REMOVE_WISHLIST,
  productId,
})

export const clearWishlist = () => ({
  type: actionTypes.CLEAR_WISHLIST,
})

// Latest viewed products
export const viewProduct = (product) => ({
  type: actionTypes.VIEW_PRODUCT,
  product,
})

// Kitchen Configurations
export const addKitchenConfiguration = (kitchenRangeName, config) => ({
  type: actionTypes.ADD_KITCHEN_CONFIGURATION,
  kitchenRangeName,
  config: {
    ...config,
    createdAt: new Date().toISOString(),
  },
})

export const clearKitchenConfigurations = () => ({
  type: actionTypes.CLEAR_KITCHEN_CONFIGURATIONS,
})

// Onboarding result
export const setOnboardingResult = (result) => ({
  type: actionTypes.SET_ONBOARDING_RESULT,
  result: {
    ...result,
    createdAt: new Date().toISOString(),
  },
})

export const clearOnboardingResult = () => ({
  type: actionTypes.CLEAR_ONBOARDING_RESULT,
})

// Notification state

export const resetNotifications = () => ({
  type: actionTypes.RESET_NOTIFICATIONS,
})

export const updatedSharedState = (source) => ({
  type: actionTypes.UPDATED_SHARED_STATE,
  source,
})

// Reset all shared state

export const clearSharedState = () => (dispatch) => {
  dispatch(clearKitchenConfigurations)
  dispatch(clearOnboardingResult)
  dispatch(clearWishlist)
  dispatch(resetNotifications)
}
