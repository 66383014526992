import { css } from 'styled-components'

const fontUrlCloudinary =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1651582419/Invita/fonts/'

const invitaFonts = css`
  @font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'Reckless';
    src: url(${fontUrlCloudinary}reckless/RecklessNeue-Regular.woff2)
        format('woff2'),
      url(${fontUrlCloudinary}reckless/RecklessNeue-Regular.woff) format('woff');
    font-display: swap;
  }

  @font-face {
    font-weight: 250;
    font-style: normal;
    font-family: 'Lausanne';
    src: url(${fontUrlCloudinary}lausanne/TWKLausanne-250.woff2) format('woff2'),
      url(${fontUrlCloudinary}lausanne/TWKLausanne-250.woff) format('woff');
    font-display: swap;
  }

  @font-face {
    font-weight: 350;
    font-style: normal;
    font-family: 'Lausanne';
    src: url(${fontUrlCloudinary}lausanne/TWKLausanne-350.woff2) format('woff2'),
      url(${fontUrlCloudinary}lausanne/TWKLausanne-350.woff) format('woff');
    font-display: swap;
  }

  @font-face {
    font-weight: 650;
    font-style: normal;
    font-family: 'Lausanne';
    src: url(${fontUrlCloudinary}lausanne/TWKLausanne-650.woff2) format('woff2'),
      url(${fontUrlCloudinary}lausanne/TWKLausanne-650.woff) format('woff');
    font-display: swap;
  }
`

export default invitaFonts
