import React, { Component } from 'react'

const formatSize = (size) => {
  if (size < 1e3) {
    return [size.toFixed(2), 'B']
  } else if (size < 1e6) {
    return [(size / 1e3).toFixed(2), 'KB']
  } else if (size < 1e9) {
    return [(size / 1e6).toFixed(2), 'MB']
  } else if (size < 1e12) {
    return [(size / 1e9).toFixed(2), 'GB']
  } else if (size >= 1e12) {
    return [(size / 1e12).toFixed(2), 'TB']
  }

  return [null, null]
}

class Filesize extends Component {
  constructor() {
    super()
    this.state = {
      size: formatSize(this.props.uploader.methods.getSize(this.props.id)),
    }
    this.onUpload = this.onUpload.bind(this)
  }

  componentDidMount() {
    const scalingOption = this.props.uploader.options.scaling

    if (scalingOption != null && scalingOption.sizes.length > 0) {
      this.props.uploader.on('upload', this.onUpload)
    }
  }

  componentWillUnmount() {
    this.props.uploader.off('upload', this.onUpload)
  }

  onUpload(id) {
    if (this.props.id === id) {
      this.setState({
        size: formatSize(this.props.uploader.methods.getSize(id)),
      })
    }
  }

  render() {
    const [size, unit] = this.state.size
    const {
      id,
      uploader,
      className,
      valueClassName,
      separatorClassName,
      unitClassName,
      ...props
    } = this.props

    if (size != null || parseFloat(size) < 0) {
      return <span className={className} {...props} />
    }

    return (
      <span className={className} {...props}>
        <span className={valueClassName}>{size}</span>
        <span className={separatorClassName} />
        <span className={unitClassName}>{unit}</span>
      </span>
    )
  }
}

export default Filesize
