import { onError } from '@apollo/client/link/error'
import { fromPromise } from '@apollo/client'
import { authRefreshSuccess, authRequestError } from '../auth/authActions'
import { sessionSelector } from '../auth/authSelectors'

const createRefreshLink = (store) => {
  let isRefreshing = false
  let pendingRequests = []

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
  }
  const getNewToken = () => {
    const session = sessionSelector(store.getState())
    if (!session || !session.refreshToken || !session.provider) {
      return Promise.reject()
    }
    const body = {
      refreshToken: session.refreshToken,
      provider: session.provider,
    }
    const url = `/login/refresh`
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
  }
  return onError((errors) => {
    const { graphQLErrors, operation, forward } = errors

    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        let forward$
        if (
          !err.extensions ||
          err.extensions.code !== 'AUTH_NOT_AUTHENTICATED'
        ) {
          return null
        }

        if (!isRefreshing) {
          isRefreshing = true
          forward$ = fromPromise(
            getNewToken()
              .then((json) => {
                const payload = {
                  accessToken: json.access_token,
                  refreshToken: json.refresh_token,
                  idToken: json.id_token,
                  expiresIn: json.expires_in,
                }
                store.dispatch(authRefreshSuccess(payload))
                resolvePendingRequests()
                return json.access_token
              })
              .catch(() => {
                store.dispatch(authRequestError({}))
              })
              .finally(() => {
                isRefreshing = false
              })
          ).filter((value) => Boolean(value))
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve())
            })
          )
        }

        return forward$.flatMap(() => forward(operation))
      })
    }
  })
}

export default createRefreshLink
