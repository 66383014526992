import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import AppointmentInformation from './AppointmentInformation'
import useTranslation from '../../../utils/translation'
import { useAmendPageData } from './AmendPage'
import PageTitle from '../../components/typography/pageTitle'

const Heading = styled(PageTitle)`
  text-align: center;
  margin: ${theme('amend.step3.heading.margin')};
  max-width: ${theme('amend.step3.heading.maxWidth')};
  color: ${(props) => theme('amend.step3.heading.color')(props)};
`

const Preamble = styled.p`
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  font-size: 18px;
  max-width: 600px;
  max-width: 475px;
  margin: 0px auto 50px auto;
  display: block;
  line-height: 27px;
`

const Link = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: ${theme('amend.step3.link.color')};
  font-size: ${theme('amend.step3.link.fontSize')};
  text-align: center;
  display: block;
  margin-top: 40px;
`

const AmendStep3 = () => {
  const t = useTranslation('amend.step3')
  const { appointmentId } = useParams()
  const { appointment, store, pageContent, isLoading } =
    useAmendPageData(appointmentId)

  if (isLoading) return null

  return (
    <>
      <Heading>{t('heading')}</Heading>
      <Preamble>{t('preamble')}</Preamble>
      <AppointmentInformation
        appointment={appointment}
        store={store}
        pageContent={pageContent}
      />
      <Link href={window.location.origin}>{t('link')}</Link>
    </>
  )
}

export default AmendStep3
