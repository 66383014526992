import React from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { StepConfirmation } from './Components'
import { badaPropTypes, badaDefaultProps } from '../../../../types'
import tracker from '../../../../utils/tracker'

const ConfirmationStep = ({
  props: {
    pageContent,
    wizard: {
      step: { six },
    },
    staff,
    selectedSlot,
    selectedService,
    booking,
    values,
    appointmentId,
    appointment,
    returningVisitor,
  },
  selectedStore,
}) =>
  six && (
    <StepConfirmation
      show={six}
      props={{
        selectedSlot,
        selectedStore,
        selectedService,
        booking,
        staff,
        onMount: () => {
          if (selectedStore && selectedStore.storeName) {
            const name =
              selectedStore &&
              selectedStore.storeName.toLowerCase().replace(/ /g, '-')
            const service = (selectedService && selectedService.id) || 'unknown'
            const slot = selectedSlot && selectedSlot.from ? 'slot' : 'no-slot'
            tracker.trackPageview(
              'BADA 2.0 thank you',
              `/bada/step-4/${name}/${service}/${slot}`
            )
          }
        },
        pageContent,
        values,
        appointmentId,
        appointment,
        returningVisitor,
      }}
    />
  )

ConfirmationStep.propTypes = {
  ...badaPropTypes,
}

ConfirmationStep.defaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(withApollo)

export default enhance(ConfirmationStep)
