import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import settings from '../../settings'
import Link from '../../typography/link'
import { Overlay } from '../../overlay'
import PostalCodeOverlay from '../postalCodeSelector/PostalCodeOverlay'
import useTranslation from '../../../../utils/translation'
import useBrandConfig from '../../../hooks/useBrandConfig'

const StyledHeader = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-weight: ${theme('typography.paragraph.fontWeightLead')};
  line-height: 21px;
  line-height: ${theme('typography.paragraph.lineHeightLead')};
  color: ${theme('typography.paragraph.color')};
  margin-bottom: 20px;
  min-height: 22px;

  & span {
    font-size: ${theme('typography.paragraph.fontSize')};
    ${(p) => p.theme.media.min.md`
      font-size: ${theme('typography.paragraph.fontSizeLarge')};
    `}
  }
`

const ChangeLocationLink = styled(Link)`
  cursor: pointer;
  font-weight: ${theme('stores.changeStoreLink.fontWeight')};
  font-size: ${theme('stores.changeStoreLink.fontSize.mobile')};
  color: ${theme('tabs.header.link.color')};
  white-space: nowrap;
  ${(p) => p.theme.media.min.md`
    font-size: ${theme('stores.changeStoreLink.fontSize')};
  `}
`

const ChangeLocation = ({
  selectedCity,
  onAddressChange,
  toggleAddressChange,
  toggleAddress,
}) => {
  const t = useTranslation('app.store-selection')
  const brandConfig = useBrandConfig(settings.brand).data
  return (
    <>
      <StyledHeader>
        {selectedCity && (
          <>
            <span>
              {t('closest-header')} <span>{`"${selectedCity}"`} </span>
            </span>
            <ChangeLocationLink
              data-cy="ChangeLocationLink"
              onClick={toggleAddress}
            >
              {t('change')}
            </ChangeLocationLink>
          </>
        )}
      </StyledHeader>

      {brandConfig && (
        <Overlay
          open={toggleAddressChange}
          setClosed={toggleAddress}
          handleChange={toggleAddress}
          render={(overlayProps) => (
            <PostalCodeOverlay
              {...overlayProps}
              onChange={onAddressChange}
              id="address"
              buttonText={t('address-lookup.button')}
              cancelText={t('address-lookup.cancel')}
              label={t('address-lookup.label')}
              headline={t('address-lookup.headline')}
              placeholder={t('address-lookup.placeholder')}
              error={t('address-lookup.error')}
              brandCountry={brandConfig.countryCode}
            />
          )}
        />
      )}
    </>
  )
}

ChangeLocation.propTypes = {
  selectedAddress: PropTypes.shape(),
  selectedCity: PropTypes.string,
  onAddressChange: PropTypes.func.isRequired,
  toggleAddressChange: PropTypes.bool.isRequired,
  toggleAddress: PropTypes.func.isRequired,
}

ChangeLocation.defaultProps = {
  selectedAddress: null,
  selectedCity: '',
}

export default ChangeLocation
