import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Headline from '../typography/headline'
import Paragraph from '../typography/paragraph'
import {
  findServiceData,
  getDisplayData,
  getMeetingTypeContent,
} from '../../containers/bada/content'
import { CUSTOMER_FORM } from '../../containers/bada/reducer/Wizard'
import CalendarHOC2 from '../calendar2'

const StyledHeadlineDesktop = styled(Headline)`
  display: none;
  margin-bottom: 22px;
  ${(props) => props.theme.media.min.sm`
    display: block;
  `}
  text-transform:inherit;
  text-transform: ${theme('slots.headers.textTransform')};
  line-height: ${theme('slots.headers.lineHeight')};
`

const StyledHeadlineMobile = styled(Headline)`
  display: block;
  margin-bottom: 22px;
  ${(props) => props.theme.media.min.sm`
    display: none;
  `}
  text-transform:inherit;
  text-transform: ${theme('slots.headers.textTransform')};
`

const StyledParagraphDesktop = styled(Paragraph)`
  display: none;
  font-size: 16px;
  ${(props) => props.theme.media.min.sm`
    display: block;
  `}
`

const StyledParagraphMobile = styled(Paragraph)`
  display: block;
  font-size: 16px;
  ${(props) => props.theme.media.min.sm`
    display: none;
  `}
`

const StyledNoteDesktop = styled(Paragraph)`
  display: none;
  ${(props) => props.theme.media.min.sm`
    display: block;
  `}
  font-size: 16px;
  background-color: #ebd58b;
  padding: 16px;
  border-radius: 4px;
  > strong {
    font-weight: 700;
  }
`

const StyledNoteMobile = styled(Paragraph)`
  display: block;
  ${(props) => props.theme.media.min.sm`
    display: none;
  `}
  font-size: 16px;
  background-color: #ebd58b;
  padding: 16px;
  border-radius: 4px;
  > strong {
    font-weight: 700;
  }
`

const CalenderContainer = styled(CalendarHOC2)`
  position: relative;
`

const renderHeading = (serviceData) => (
  <>
    {serviceData.slotSelectionHeadingDesktop && (
      <StyledHeadlineDesktop>
        {serviceData.slotSelectionHeadingDesktop}
      </StyledHeadlineDesktop>
    )}
    {serviceData.slotSelectionHeadingMobile && (
      <StyledHeadlineMobile>
        {serviceData.slotSelectionHeadingMobile}
      </StyledHeadlineMobile>
    )}
  </>
)

const renderPreamble = (serviceData) => (
  <>
    {serviceData.slotSelectionTextDesktop && (
      <StyledParagraphDesktop
        dangerouslySetInnerHTML={{
          __html: serviceData.slotSelectionTextDesktop,
        }}
      />
    )}
    {serviceData.slotSelectionTextMobile && (
      <StyledParagraphMobile
        dangerouslySetInnerHTML={{
          __html: serviceData.slotSelectionTextMobile,
        }}
      />
    )}
    {serviceData.slotSelectionNoteDesktop && (
      <StyledNoteDesktop
        dangerouslySetInnerHTML={{
          __html: serviceData.slotSelectionNoteDesktop,
        }}
      />
    )}
    {serviceData.slotSelectionNoteMobile && (
      <StyledNoteMobile
        dangerouslySetInnerHTML={{
          __html: serviceData.slotSelectionNoteMobile,
        }}
      />
    )}
  </>
)

const SlotSelection = ({
  selectedStore,
  selectedService,
  onChange,
  onMount,
  pageContent,
}) => {
  useEffect(() => {
    onMount()
  }, [])

  if (
    !pageContent ||
    selectedStore.bookingSystem === CUSTOMER_FORM ||
    !selectedService
  )
    return null

  const displayData = getDisplayData(pageContent)
  const serviceData = findServiceData(displayData, selectedService)

  if (!serviceData) return null

  const noSlotMeetingType = getMeetingTypeContent({
    meetingTypes: displayData.services,
    currentMeetingTypeName: selectedService.id,
    bookingSystem: CUSTOMER_FORM,
    subSystem: selectedStore.simplyBookMeSubsystem,
    serviceId: selectedService.serviceId,
  })

  return (
    <>
      {renderHeading(serviceData)}
      {renderPreamble(serviceData)}
      <CalenderContainer
        onChange={onChange}
        storeId={selectedStore.id}
        locationId={selectedStore.simplyBookMeId.toString()}
        meetingTypeId={selectedService.serviceId.toString()}
        sbmInstance={selectedStore.simplyBookMeSubsystem}
        serviceData={serviceData}
        noSlotMeetingType={noSlotMeetingType}
      />
    </>
  )
}

export default SlotSelection
