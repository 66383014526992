import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import saved from '../../../assets/saved.png'

const withSmallScreenSplash = (SplashedComp) => {
  const Splash = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${theme('colors.link')};
    color: #fff;
    opacity: 0;
    text-align: center;
    box-shadow: 1px 1px 5px 0 ${theme('colors.darkGrey')};
    padding: 20px 0;
    font-weight: 600;
    width: 100%;
    line-height: 1;
    z-index: 1000;
    transition: opacity ease-out 0.3s;

    ${(props) =>
      props.animating &&
      css`
        opacity: 1;
      `}
    ${(props) => props.theme.media.min.lg`
      display: none;
  `}
  `

  class Splashed extends Component {
    state = {
      active: false,
    }

    toggleSplash() {
      this.setState({
        active: true,
      })
      setTimeout(() => {
        this.setState({
          animating: true,
        })
      }, 5)
      setTimeout(() => {
        this.setState({
          animating: false,
        })
      }, 1200)
      setTimeout(() => {
        this.setState({
          active: false,
        })
      }, 1500)
    }

    render() {
      return (
        <>
          <SplashedComp {...this.props} splash={() => this.toggleSplash()} />
          {this.state.active && (
            <Splash
              animating={this.state.animating}
              onClick={() => this.setState({ active: false })}
            >
              <img src={saved} />
              <br />
              <FormattedMessage id="app.prep-form.saved" />
            </Splash>
          )}
        </>
      )
    }
  }

  return Splashed
}

export default withSmallScreenSplash
