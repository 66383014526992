import styled from 'styled-components'
import { ifProp, prop, theme } from 'styled-tools'

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: ${ifProp('noBottomBorder', '0px', '1px solid #d8d8d8')};
  padding-bottom: 20px;
  margin-bottom: 20px;
`

export const SectionItem = styled.div`
  width: ${ifProp('mobileSize', prop('mobileSize'), '50%')};
  padding-right: 10px;
  ${(props) => props.theme.media.min.sm`
    width: ${ifProp('desktopSize', prop('desktopSize'), '25%')}
  `};
`

export const WideSectionItem = styled.div`
  width: ${ifProp('mobileSize', prop('mobileSize'), '100%')};
  padding-right: 10px;
  ${(props) => props.theme.media.min.sm`
    width: ${ifProp('desktopSize', prop('desktopSize'), '50%')}
  `};
`

export const SectionItemHeading = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 8px;
  text-transform: ${theme('appointmentDetails.heading.textTransform')};
  font-weight: ${theme('typography.subHeadline.fontWeight')};
`

export const SectionItemText = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 14px;
  margin-top: 0px;
  text-transform: ${theme('appointmentDetails.text.textTransform')};
  font-weight: ${theme('appointmentDetails.text.fontWeight')};
  word-wrap: break-word;
`

export const SectionSpacer = styled.div`
  padding: 20px;
  padding-bottom: ${ifProp('noBottomPadding', '0px', '20px')};
  padding-top: ${ifProp('noTopPadding', '0px', '20px')};
  ${(props) => props.theme.media.max.xs`
    padding: 15px;
    padding-bottom: ${ifProp('noBottomPadding', '0px', '20px')};
    padding-top: ${ifProp('noTopPadding', '0px', '20px')};
    `};
`

export const SectionHeader = styled.h3`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('typography.amend.headline.fontFamily')};
  font-size: 17px;
  margin: 0;
`
