import React from 'react'
import BadaIcon from '../icons/badaIcon'
import {
  Box,
  Description,
  MeetingTypeItemContainer,
  Subtitle,
  TitleUI,
  TitleWrapper,
} from '../meeting-types/MeetingTypes'
import RadioButtonRich from '../toggle/RadioButtonRich'
import useTranslation from '../../../utils/translation'

const transformServiceName = (serviceName) => {
  if (!serviceName) return serviceName
  return serviceName.replace('-', '').replace('/ ', '/')
}

const MeetingTypeItem = ({
  onChange,
  props: {
    id,
    name,
    icon,
    text,
    checked,
    serviceId,
    serviceType,
    serviceCode,
    serviceDuration,
    disabled,
  },
}) => {
  const t = useTranslation('app.service-selection')
  const subtitle =
    serviceDuration && t('subHeading', { duration: serviceDuration })
  return (
    <MeetingTypeItemContainer
      id="meeting-type-list"
      data-cy={`MeetingTypeItem-${id}`}
      data-serviceid={serviceId}
      disabled={disabled && !checked}
    >
      <RadioButtonRich
        name="MeetingType"
        value={id.toString()}
        onChange={() =>
          !disabled &&
          onChange({
            selectedService: {
              id,
              name: transformServiceName(name),
              serviceId,
              serviceType,
            },
            selectedSlot: null,
            overrideMeetingType: null,
          })
        }
        checked={checked}
      >
        <Box>
          <TitleWrapper hasSubtitle={!!subtitle}>
            <BadaIcon meetingTypeCode={icon || serviceCode} />
            <TitleUI>{name}</TitleUI>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </TitleWrapper>
          <Description hasSubtitle={!!subtitle}>{text}</Description>
        </Box>
      </RadioButtonRich>
    </MeetingTypeItemContainer>
  )
}

export default MeetingTypeItem
