const fontUrl =
  'https://www.intuokitchen.com/gui/styleguide/project/old/brands/intuo/fonts/'

const intuoFonts = `
	@font-face{
	font-family:"Neue Helvetica W06";
	src: url(${fontUrl}fonts.com/Fonts/0ee5a0a2-d066-4165-9d6a-bc5ef03378d4.woff) format("woff");
	font-weight: 200;
	font-style: normal;
	}
	
	@font-face{
	font-family:"Neue Helvetica W06";
	src: url(${fontUrl}fonts.com/Fonts/990dea59-fcdd-461d-863d-3517e48e8ed0.woff) format("woff");
	font-weight: 400;
	font-style: normal;
	}
	
	@font-face{
	font-family:"Neue Helvetica W06";
	src: url(${fontUrl}fonts.com/Fonts/133a7550-d5a8-4615-af3e-38e1702b7a95.woff) format("woff");
	font-weight: 400;
	font-style: italic;
	}
	
	@font-face{
	font-family:"Neue Helvetica W06";
	src: url(${fontUrl}fonts.com/Fonts/cb4a0bc9-8ebf-49d0-8141-f678590cdd7a.woff) format("woff");
	font-weight: 700;
	font-style: normal;
	}` 

export default intuoFonts
