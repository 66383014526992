import localeData, { defaultLocale } from './localeData'

const getLocale = (brand) => {
  if (!brand) {
    return defaultLocale
  }

  if (localeData[brand] == null) {
    throw new Error(`Missing locale data for brand '${brand}'`)
  }

  return localeData[brand]
}

export default getLocale
