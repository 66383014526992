import transformStore from './transform'
import settings from '../../../components/settings'
import { appBasePath } from '../../../../utils/appBasePath'
import { clearState } from '../../../../utils/state'

const reloadOnError = (storeData, allStoresList = []) => {
  if (storeData != null) {
    if (storeData.error != null) {
      /* eslint-disable no-console */
      console.warn(`Failed to fetch store. Clearing state.`)
      clearState(settings.brand)
      window.location = appBasePath
    } else if (storeData.getStoreWithDistance != null) {
      const storeId = storeData?.variables?.storeId || 0
      const selectableStores = []

      allStoresList.forEach((item) => {
        if (
          item.store.id === storeId &&
          !settings.excludedStudioTypes.includes(item.store.storeType)
        ) {
          selectableStores.push(item)
        }
      })
      const selectedStore =
        selectableStores && selectableStores.length && selectableStores[0]

      return selectedStore && transformStore(selectedStore)
    }
  }
  return undefined
}

const badaPropsChange = ({
  searchOpenAuthStoresByAddress,
  StoreWithDistance,
  sbmBookingsByStoreId,
  msBookingsByStoreId,
  pageContent,
  overrideMeetingType,
}) => ({
  store: reloadOnError(
    StoreWithDistance,
    searchOpenAuthStoresByAddress?.stores
  ),
  stores:
    searchOpenAuthStoresByAddress != null &&
    searchOpenAuthStoresByAddress.stores != null
      ? [...searchOpenAuthStoresByAddress.stores]
          .filter(
            (storeItem) => storeItem.store.externalMeetingData.acceptMeetings
          )
          .filter(
            (storeItem) =>
              !settings.excludedStudioTypes.includes(storeItem.store.storeType)
          )
          .map((storeItem) => transformStore(storeItem))
      : undefined,
  bookings:
    (sbmBookingsByStoreId && sbmBookingsByStoreId.getSbmCalendar) ||
    (msBookingsByStoreId && msBookingsByStoreId.getMsBookingsCalendar),
  pageContent: pageContent && pageContent.page,
  overrideMeetingType,
})

export default badaPropsChange
