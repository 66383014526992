import React, { Fragment } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import uuid from 'uuid'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import { PortalProvider } from './components/portal'
import { OverlayDestination } from './components/overlay'
import ErrorBoundary from './components/errorBoundary'
import features from './components/features'
import settings from './components/settings'
import Bada from './containers/bada'
import AppointmentDetailsPage from './containers/appointment-details'
import AmendPage from './containers/amend/AmendPage'
import CancelPage from './containers/cancel/CancelPage'
import GlobalStyles from '../styles/global'
import GlobalIndependentStyles from '../styles/globalIndependent'
import { getState } from '../utils/state'
import { appBasePath } from '../utils/appBasePath'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.savedState = getState(settings.brand) || { visitorId: uuid() }

    if (this.savedState && !this.savedState.visitorId) {
      this.savedState.visitorId = uuid()
    }

    const id = new URLSearchParams(window.location.search).get('appointment')
    if (id) {
      this.savedState.appointmentId = id
    }
  }

  static async getInitialProps(ctx) {
    const path = ctx.req ? ctx.req.url : ctx.location && ctx.location.pathname
    return { path }
  }

  render() {
    if (!window.location.pathname.startsWith(appBasePath)) {
      return <Redirect to="/" />
    }

    const queryCache = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          staleTime: 30000,
        },
      },
    })

    return (
      <QueryClientProvider client={queryCache}>
        <ErrorBoundary>
          <PortalProvider>
            <Fragment>
              {features.useIndependentStyling ? (
                <GlobalIndependentStyles />
              ) : (
                <GlobalStyles />
              )}
              <Switch>
                <Route
                  path={`/appointment-details/:appointmentId`}
                  component={AppointmentDetailsPage}
                  exact
                />
                <Route
                  path={[
                    '/amend/:appointmentId',
                    '/amend/:appointmentId/:step',
                  ]}
                  component={features.useAmendCancel ? AmendPage : null}
                  exact
                />
                <Route
                  path={[
                    '/cancel/:appointmentId',
                    '/cancel/:appointmentId/:step',
                  ]}
                  component={features.useAmendCancel ? CancelPage : null}
                  exact
                />
                <Route path="/" render={() => <Bada {...this.savedState} />} />
              </Switch>
              <OverlayDestination />
            </Fragment>
          </PortalProvider>
        </ErrorBoundary>
      </QueryClientProvider>
    )
  }
}

export default withRouter(App)
