import React from 'react'
/* eslint-disable no-mixed-spaces-and-tabs */
const Magnet = () => (
  <svg
    width={150}
    height={70}
    viewBox="0 0 740.2 400.9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          fill="#122126"
          d="M176.4,198.5h31.9V86.6h-45.1L147.9,146h-0.3l-16-59.4H86.8v111.9h25.8v-79.9h0.3l21,79.9h21.8l20.4-79.9
				h0.3V198.5z M272.4,161.2c0,9.1-0.2,19.4-12.4,19.4c-5.1,0-8.5-2.3-8.5-7.7c0-10.1,13-12.6,20.9-13.4V161.2z M303.6,180.2v-29.3
				c0-12,1.3-22.7-9.6-30.4c-8.1-5.9-20.7-7.2-30.4-7.2c-18.7,0-38.5,5.4-43.4,26.1l29.7,2.8c1-7,6.4-9.3,12.7-9.3
				c7.8,0,9.8,2.9,9.8,10.5v0.7c-10.9,0.7-22.2,2-32.8,5.2c-11.6,3.6-21.9,12.4-21.9,25.7c-0.1,16.8,13.5,25.2,29,25.2
				c11.4,0,20.7-3.5,27.2-13.2c0,3.8,0.3,7.8,1.1,11.5h31.2C303.8,192.6,303.6,186.4,303.6,180.2 M344.5,197.4l5.9,0.2l16,0.8
				c4.1,0.1,9.6-0.2,9.6,5.3c0,7.3-12.1,6.5-17,6.5c-5.4,0-10.9-0.2-16.2-1.4c-3.4-0.7-6.5-2.3-6.5-6.2
				C336.3,197.7,340.7,197.4,344.5,197.4 M346.7,139.9c0-6.1,3.4-10.4,9.6-10.4c6.7,0,9.9,4.1,9.9,10.5c0,5.7-3.2,10-9.3,10
				C350.3,150.1,346.7,146.7,346.7,139.9 M342.5,169.3c0-4.1,5.4-4.1,8.1-4.1l3.4,0.2l3.7,0.1c16.8,0,38.2-4.5,38.2-25.5
				c0-7.4-3.6-14.3-9.3-18.8c1.6-2.7,6.1-2.9,9.1-2.9l8,0.3l0.8-22.3l-2.1-0.2l-9.5-0.5c-12.9,0-18.9,7.5-20.7,19.6
				c-5.1-1.5-10.3-1.8-15.5-1.8c-17,0-40.8,4.6-40.8,26.2c0,9.7,4.9,15.7,13.5,19.3c-8.1,3.1-16,8.8-16,18.4c0,5.9,3.1,9.8,7.7,13.2
				c-7.4,2.6-12.1,8.2-12.1,16.1c0,19.5,31.3,20.5,45.2,20.5c9.8,0,22.7-0.3,31.6-3.6c10.6-3.6,18.6-11.1,18.6-23
				c0-7.8-3.2-16-9.6-20.7c-5.9-4.4-13.5-5.4-20.7-5.7l-22.8-1C348.5,173.2,342.5,173.5,342.5,169.3 M459.3,198.5h32.3v-51.4
				c0-8.6,0-18.1-5.2-25c-4.9-6.5-13.8-8.7-21.7-8.7c-10.1,0-19.7,3.9-23.6,13.3h-0.3v-11.6h-30.5v83.4h32.3v-48.1
				c0-6.9,0.8-14.5,9.8-14.5c7,0,7,7,7,12.1V198.5z M532.8,147c-0.3-7.8,3.1-14.6,11.6-14.6c9.1,0,11.7,6.3,11.7,14.6H532.8z
				 M533,163.3h55.8v-4.4c0-27.7-17-45.5-44.7-45.5c-11.9,0-24.6,4.1-33.1,12.8c-7.8,8-12.2,20.5-12.2,31.7
				c0,11.9,4.7,23.3,13.7,31.3c9.5,8.3,21.4,10.9,33.6,10.9c19.7,0,37.7-9.3,42.7-29.5l-30.5-2c-1.3,7.2-5.4,10.1-12.7,10.1
				C535.8,178.8,532.5,172.3,533,163.3 M637.1,165.1v-28.8h16.3v-21.2h-16.6V87.6l-28,1l-2.4,26.6h-13.5v21.2h12.1v31.5
				c0,9-0.2,17,6.2,23.9c6.4,6.9,14.7,9.6,23.3,8.5l19.1-2.4v-23.4c-3.1,0.8-6.2,1.5-9.3,1.5C636.5,175.9,637.1,171.1,637.1,165.1"
        />
        <path
          fill="#D39B6C"
          d="M155.2,301.9c0,5.2-4.2,8.3-10.9,8.3h-2.4c-4.4,0-5.5-0.6-5.5-3.6v-12c0-1.2,0.4-1.6,1.8-1.6h5.3
				C151.4,292.8,155.2,296.4,155.2,301.9 M152,282.1c0,4.6-3,6.9-8.4,6.9h-5.2c-1.5,0-1.9-0.4-1.9-1.5v-9.8c0-2.3,1.3-3,5-3h1.2
				C148.7,274.6,152,277.4,152,282.1 M164.1,301.8c0-6-4.1-9.8-9.8-11.3c4.4-1.6,6.5-5,6.5-8.9c0-7.2-6.7-11.1-16.5-11.1
				c-4.8,0-8.4,0.3-11.8,0.3c-2.7,0-4.7-0.2-5.9-0.2c-1.5,0-1.9,0.7-1.9,1.5c0,2.4,3.9,1,3.9,5.2v30c0,4.2-3.9,2.8-3.9,5.2
				c0,0.9,0.5,1.5,1.9,1.5c1.2,0,3.2-0.2,5.9-0.2c2.7,0,8.1,0.2,13.7,0.2C158.1,314.2,164.1,309.2,164.1,301.8 M175.5,298.9
				c-0.1-0.9-0.2-1.7-0.2-2.6c0-5.8,3.2-9.5,7.4-9.5c3.8,0,6.5,3.6,6.5,6.5c0,1.1-0.4,1.5-1.4,1.8L175.5,298.9z M197.6,308.7
				c0.4-0.6,0.6-1.1,0.6-1.5c0-0.7-0.5-1.5-1.4-1.5c-0.5,0-1,0.3-1.8,1c-2.1,2-4.9,3.4-8.2,3.4c-4.7,0-8.4-3.2-10.3-7.6l18.9-5.7
				c1-0.3,1.5-0.7,1.5-2.2c0-6-5.2-11.9-13.2-11.9c-8.5,0-15.9,6.6-15.9,16.4c0,10,7,16,15.9,16
				C190.1,315.1,195.5,311.9,197.6,308.7 M245.1,311.2c0.9-0.9,1.4-1.8,1.4-2.7c0-0.8-0.5-1.5-1.5-1.5c-0.6,0-1.2,0.5-1.5,0.9
				c-1.3,1-2.5,2.1-4.5,2.1c-2.2,0-3.9-1.3-3.9-5.8v-16.4h9.2c0.8,0,1.2-0.4,1.3-1.2c0.1-0.4,0.2-1,0.2-1.5c0-0.8-0.4-1.2-1.3-1.2
				h-9.4v-9.5c0-1.3-0.5-1.9-1.3-1.9c-1,0-1.5,0.9-2.5,2.7c-1.2,3.4-2.4,5.4-4.4,7c-1.8,1.4-4,1.6-5,1.7h-0.4c-0.1,0-0.1,0-0.1,0
				l0,0h-8.8v-9.5c0-1.3-0.5-1.9-1.3-1.9c-1,0-1.5,0.9-2.5,2.7c-1.7,4.9-3.5,6.9-7.3,8.7c-1.1,0.5-1.8,1.1-1.8,2.1
				c0,1,0.8,1.6,1.9,1.6h3.6v10.7c0,4.2-0.2,8.9-0.2,10.1c0,3.3,3.6,6.1,8.5,6.1c3.8,0,6.8-1.3,8.9-3.5c0.9-0.9,1.4-1.8,1.4-2.7
				c0-0.8-0.5-1.5-1.5-1.5c-0.6,0-1.2,0.5-1.5,0.9c-1.3,1-2.5,2.1-4.5,2.1c-2.2,0-3.9-1.3-3.9-5.8v-16.4h6.2l0,0h9l0,0h0v10.7
				c0,4.2-0.2,8.9-0.2,10.1c0,3.3,3.6,6.1,8.5,6.1C239.9,314.7,243,313.4,245.1,311.2 M256,298.9c-0.1-0.9-0.2-1.7-0.2-2.6
				c0-5.8,3.2-9.5,7.4-9.5c3.8,0,6.5,3.6,6.5,6.5c0,1.1-0.4,1.5-1.4,1.8L256,298.9z M278.1,308.7c0.4-0.6,0.6-1.1,0.6-1.5
				c0-0.7-0.5-1.5-1.4-1.5c-0.5,0-1,0.3-1.8,1c-2.1,2-4.9,3.4-8.2,3.4c-4.7,0-8.4-3.2-10.3-7.6l18.9-5.7c1-0.3,1.5-0.7,1.5-2.2
				c0-6-5.2-11.9-13.2-11.9c-8.5,0-15.9,6.6-15.9,16.4c0,10,7,16,15.9,16C270.6,315.1,276,311.9,278.1,308.7 M307.2,292.7
				c1-2.9,1.6-4.7,1.6-6.1c0-1.8-2-3.4-4.9-3.4c-4.4,0-8.7,3.5-10.6,8.3v-5.5c0-1.5-0.4-2.5-1.6-2.5c-0.5,0-1.3,0.2-4.8,1.8
				c-3.8,1.7-5,2.5-5,3.6c0,2.6,4.2,0.7,4.2,4.5v13.9c0,4.7-3.7,3.2-3.7,5.5c0,0.7,0.5,1.5,1.7,1.5c1.4,0,3-0.2,5.6-0.2
				c2.9,0,4.9,0.2,6.4,0.2c1.2,0,1.7-0.8,1.7-1.5c0-2.6-4.5-0.9-4.5-5.5v-5.6c0-8,4.4-11.9,7-11.9c1.5,0,2.4,1.1,3.3,3.1
				c0.5,1,0.9,1.8,1.8,1.8C306.3,294.5,306.8,293.8,307.2,292.7 M313.2,310c0-2.6-1.9-4.1-4.7-4.1c-3.1,0-5.3,2-5.3,4.5
				c0,2.6,1.9,4.1,4.7,4.1C310.9,314.5,313.2,312.5,313.2,310 M364.6,301.9c0,5.2-4.2,8.3-10.9,8.3h-2.4c-4.4,0-5.5-0.6-5.5-3.6v-12
				c0-1.2,0.4-1.6,1.8-1.6h5.3C360.8,292.8,364.6,296.4,364.6,301.9 M361.4,282.1c0,4.6-3,6.9-8.4,6.9h-5.2c-1.5,0-1.9-0.4-1.9-1.5
				v-9.8c0-2.3,1.3-3,5-3h1.2C358.2,274.6,361.4,277.4,361.4,282.1 M373.5,301.8c0-6-4.1-9.8-9.8-11.3c4.4-1.6,6.5-5,6.5-8.9
				c0-7.2-6.7-11.1-16.5-11.1c-4.8,0-8.4,0.3-11.8,0.3c-2.7,0-4.7-0.2-5.9-0.2c-1.5,0-1.9,0.7-1.9,1.5c0,2.4,3.9,1,3.9,5.2v30
				c0,4.2-3.9,2.8-3.9,5.2c0,0.9,0.5,1.5,1.9,1.5c1.2,0,3.2-0.2,5.9-0.2c2.7,0,8.1,0.2,13.7,0.2
				C367.5,314.2,373.5,309.2,373.5,301.8 M410.2,285.1c0-0.9-0.7-1.4-1.7-1.4c-1.4,0-2.9,0.2-4.6,0.2c-1.6,0-3.2-0.2-4.4-0.2
				c-1.2,0-1.9,0.5-1.9,1.5c0,2.1,3.4,1.6,3.4,4c0,1-0.4,2-1,3.8l-5.6,13.8l-6-13.8c-0.7-1.6-1-2.9-1-3.6c0-2.8,3.3-2.1,3.3-4.2
				c0-1-0.7-1.5-1.9-1.5c-1.5,0-3.3,0.2-6,0.2c-3,0-4.7-0.2-6.3-0.2c-1,0-1.7,0.5-1.7,1.4c0,2,2.9,1.4,4.9,5.6l10.9,23.5l-0.1,0.2
				c-1.5,3.6-3.2,5.9-5.2,5.9c-1.8,0-3.2-1.3-5.3-4.3c-0.5-0.8-1-1.1-1.6-1.1c-0.9,0-1.5,0.9-1.5,1.9c0,1.9,1.2,6.1,2.2,7.4
				c0.6,0.9,2.5,1.4,4.6,1.4c4.9,0,8.5-3.5,11.1-9.5l10.8-25.5C407.1,286.5,410.2,287.1,410.2,285.1 M448,300.7
				c0,5.7-3.9,8.9-7.9,8.9c-4.6,0-9.2-4.7-9.2-12.4c0-6.1,3.1-10.4,8.1-10.4c4.5,0,9.1,4.4,9.1,10.9V300.7z M459.6,310
				c0-2.4-4.3-1.2-4.3-4.9v-34.8c0-1.3-0.3-2.5-1.6-2.5c-0.5,0-1.6,0.2-5.1,1.7c-4.1,1.8-5.4,2.5-5.4,3.6c0,2.7,4.8,0.7,4.8,4.5v8.7
				c-2.2-2-5.2-3.3-9.2-3.3c-8.9,0-15.7,6.8-15.7,16.4c0,9.7,6.3,15.4,13.8,15.4c5.1,0,8.8-2.5,11.1-5.8v3.2c0,1,0.1,2.6,1.6,2.6
				c0.5,0,1.3-0.2,4.7-1.4C458.3,312,459.6,311.3,459.6,310 M468.9,298.9c-0.1-0.9-0.2-1.7-0.2-2.6c0-5.8,3.2-9.5,7.4-9.5
				c3.8,0,6.5,3.6,6.5,6.5c0,1.1-0.4,1.5-1.4,1.8L468.9,298.9z M491,308.7c0.4-0.6,0.6-1.1,0.6-1.5c0-0.7-0.5-1.5-1.4-1.5
				c-0.5,0-1,0.3-1.8,1c-2.1,2-4.9,3.4-8.2,3.4c-4.7,0-8.4-3.2-10.3-7.6l18.9-5.7c1-0.3,1.5-0.7,1.5-2.2c0-6-5.2-11.9-13.2-11.9
				c-8.5,0-15.9,6.6-15.9,16.4c0,10,7,16,15.9,16C483.4,315.1,488.8,311.9,491,308.7 M502.5,290.2c0-2,1.8-3.4,4.6-3.4
				c3.8,0,8.1,2.4,11.3,5.2c0.7,0.6,1.4,1.3,2.1,1.3c0.7,0,1.3-0.5,1.3-1.4c0-0.8-0.7-2.1-1.4-3.3l-2.1-3.4
				c-0.8-1.2-1.4-2.2-2.6-2.2c-1.3,0-1.3,1.2-2.4,1.2c-1,0-3.7-1.1-7.2-1.1c-6.4,0-10.9,3.8-10.9,8.8c0,6.2,6.7,8.4,12.9,9.8
				c5.2,1.2,8.6,1.8,8.6,4.9c0,2.3-2.2,4.3-5.8,4.3c-4.7,0-9.1-2.9-12.9-6.6c-0.8-0.7-1.5-1.3-2.1-1.3c-0.8,0-1.4,0.5-1.4,1.5
				c0,1,0.6,1.9,1.6,4l1.9,4.1c0.5,1,1,2.3,2.2,2.3c1.4,0,1.5-1.6,2.9-1.6c1,0,3.7,1.2,7.9,1.2c7.4,0,12.9-3.6,12.9-9.5
				c0-6.6-6.4-8.6-13-10.1C505.5,293.6,502.5,293,502.5,290.2 M539.8,272.5c0-2.2-1.8-3.6-4-3.6c-3.6,0-6.4,2.9-6.4,5.6
				c0,2.1,1.7,3.5,3.9,3.5C536.8,278,539.8,275.2,539.8,272.5 M542.6,312.7c0-2.4-3.8-0.9-3.8-5.5v-21.3c0-1.5-0.4-2.5-1.6-2.5
				c-0.5,0-1.3,0.2-4.8,1.8c-3.8,1.7-5,2.5-5,3.6c0,2.6,4.2,0.7,4.2,4.5v13.9c0,4.7-3.7,3.2-3.7,5.5c0,0.7,0.5,1.5,1.7,1.5
				c1.4,0,3-0.2,5.6-0.2c2.6,0,4.3,0.2,5.7,0.2C542.1,314.2,542.6,313.4,542.6,312.7 M572.7,317.8c0,2.2-3.5,4.4-10.1,4.4
				c-6.7,0-10.9-1.5-10.9-4.9c0-1.8,1.1-3.4,2.7-4.4c4.4,0.5,8.7,1,13,1.5C571,314.8,572.7,316,572.7,317.8 M553.9,292.7
				c0-3.9,3-6.4,7.2-6.4c4.3,0,7.7,2.7,7.7,7c0,3.9-3,6.4-7.2,6.4C557.4,299.7,553.9,297.1,553.9,292.7 M579.8,315.5
				c0-4.8-3.9-7.5-9.7-8c-2.9-0.2-8.2-0.7-13.7-1.3c-2.5-0.2-3.6-0.7-3.6-1.6c0-0.9,0.9-1.5,1.8-2.2c1.9,0.5,4,0.8,6.3,0.8
				c10.1,0,15.4-5,15.4-10.6c0-4.4-3.1-7.7-8.3-9.1c0.8-3.1,4.3-3.5,8.1-3.5c1.3,0,1.9-0.7,1.9-1.6c0-0.8-0.7-1.5-1.5-2.1
				c-2-1.5-3.5-2.1-5.2-2.1c-2.1,0-5.8,3.1-6.6,8.7c-0.9-0.1-1.7-0.1-2.6-0.1c-10,0-15.4,5-15.4,10.8c0,3.3,1.8,5.9,4.8,7.5
				c-2.4,1.6-4.5,3.8-4.5,6.2c0,2.1,1.3,3.8,3.4,4.7c-3,1.3-4.9,3.5-4.9,5.9c0,4.6,6.6,7.7,17.1,7.7
				C573,325.6,579.8,321.4,579.8,315.5 M618.2,312.7c0-2.4-3.5-0.6-4.2-6.2l-1.6-11.4c-0.7-5.7-3-12-9.8-12c-4.7,0-8,2.8-9.9,6v-3.2
				c0-1.5-0.4-2.5-1.6-2.5c-0.5,0-1.3,0.2-4.8,1.8c-3.8,1.7-5,2.5-5,3.6c0,2.6,4.2,0.7,4.2,4.5v13.9c0,4.7-3.7,3.2-3.7,5.5
				c0,0.7,0.5,1.5,1.7,1.5c1.4,0,3-0.2,5.6-0.2c2.6,0,4.3,0.2,5.7,0.2c1.2,0,1.7-0.8,1.7-1.5c0-2.4-3.8-0.9-3.8-5.5v-9.6
				c0-6.1,3.5-8.6,6.6-8.6c4.1,0,5.3,4.1,5.9,7.8l1.3,9.1c0.1,0.9,0.2,1.8,0.2,2.3c0,3.5-3.8,2.4-3.8,4.6c0,0.9,0.7,1.5,2,1.5
				c1.6,0,2.8-0.2,5.7-0.2c2.8,0,4.1,0.2,5.6,0.2C617.5,314.2,618.2,313.6,618.2,312.7"
        />
      </g>
    </g>
  </svg>
)

export default Magnet
