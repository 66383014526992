import React, { useEffect, useRef, useState } from 'react'
import { lighten } from 'polished'
import { theme, withProp } from 'styled-tools'
import styled from 'styled-components'
import moment from 'moment-timezone'
import settings from '../../settings'
import Paragraph from '../../typography/paragraph'
import Button from '../../button/baseButton'
import useTranslation from '../../../../utils/translation'
import RadioButtonRich from '../../toggle'
import Day from './day'
import Slot from './slot'
import ArrowRightIcon from '../../icons/arrow-right'
import ArrowLeftIcon from '../../icons/arrow-left'
import features from '../../features'
import tracker from '../../../../utils/tracker'


const ListWrapper = styled.div`
  position: relative;
  min-height: 400px;
  background-color: rgb(247,239,233);
  max-width: 1057px;
  ${(props) => props.theme.media.max.sm`
    margin-left: -15px;
    margin-right: -15px;
  `}
  margin-bottom: 40px;
`
const StyledList = styled.ol`
  position: relative;
  list-style-type: none;
  margin: 0px;
  display: flex;
  flex-flow: row;
  padding: 0px 0px 0px;
  width: 100%;
  max-width: 1057px;
  overflow-x: scroll;
  z-index: 1;
  scrollbar-width: none;
`

const SkeletonList = styled(StyledList)``
const SkeletonSlotAnimation = styled.span`
  display: block;
  padding: 10px 0;
  text-align: center;
  font-family: ${theme('typography.paragraph.fontFamily')};
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.3);
  cursor: progress;
  content: ' ';
  background: linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(#eee, #eee),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
  animation: loading 2s infinite;

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`
const StyledItem = styled.li`
  margin-bottom: ${(props) => (props.hideInactiveSlots ? '0' : '10px')};
  text-align: center;
  background-color: ${theme('button.colors.primaryBackground')};
  border-radius: 5px;
  color: ${theme('button.colors.primaryColor')};

  :hover {
    background: ${withProp(
      'theme.button.colors.primaryBackground',
      lighten(0.1)
    )};
  }
  
  :has(input:checked) {
    background: ${withProp(
      'theme.button.colors.primaryBackground',
      lighten(0.1)
    )};
  }
`
const Info = styled(Paragraph)`
  padding: 10px 0;
  text-align: center;
`

const ClosedMessageContainer = styled.div`
  background-color: #f7f5f1;
  border: 1px solid #d3d3d3;
  color: #000;

  padding: 14px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 3px;
`
const ClosedTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`
const ClosedSubtitle = styled.p`
  font-size: 16px;
  margin: 0;
`
const NextSlotButtonContainer = styled.div`
  background-color: #f7f5f1;
  border: 1px solid #d3d3d3;
  color: #000;

  display: flex;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;
  text-align: center;
  border-radius: 3px;
  margin-bottom: 10px;
  flex-direction: column;

  ${(p) => p.theme.media.min.lg`
    flex-direction: row;
    align-items: center;
  `}
`
const StyledNextButton = styled(Button)`
  font-size: 15px;
  font-weight: ${theme('typogrpahy.paragraph.fontWeight')};

  :before {
    border-radius: 3px;
  }
`
const TextInline = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 16px;
  color: #000;

  margin-bottom: 14px;
  ${(p) => p.theme.media.min.lg`
    margin-bottom: 0;
    margin-right: 14px;
  `}
`
const NavigationButtons = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
  background-color: rgb(247,239,233);
  max-width: 1057px;
  ${(props) => props.theme.media.max.sm`
    margin: 0 -15px;
  `}
`

const NavigationButton = styled.div`
    position: relative;
    padding: 5px 12px;
    -webkit-column-gap: 5px;
    -moz-column-gap: 5px;
    column-gap: 5px;
    font-size: 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px 7px;
    font-weight: 500;
    cursor: pointer;
`

const AltStore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${(props) => props.theme.media.max.sm`
    background-color: rgb(247, 239, 233);
    margin: 0 -15px;
    width: auto;
    padding: 20px 0 45px 0;
    margin-top: -40px;
  `}

`
const AltStoreParagraph = styled.p`
  font-weight: 600;
  font-size: 16px;

  ${(props) => props.theme.media.max.sm`
    font-size: 18px;
  `}
`


const NavigationButtonWithLoadingIndicator = styled(NavigationButton)`
  ${(props) => (props.isLoading ? `cursor: wait;` : ``)}
  ${(props) => (props.dimmed ? `opacity: 0.5; cursor: default;` : ``)}
  transition: opacity 0.5s;
`

const getDateFormat = (date, locale) =>
  new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
  }).format(new Date(date))

const getFirstSelectableDate = (node, days) => {
  const availableDays = days?.filter(
    (f) =>
      f.intervals &&
      f.intervals.length > 0 &&
      f.intervals.some((c) => c.type === 'available')
  )
  const date = availableDays && availableDays[0] && availableDays[0].day

  if (!node) {
    return { date, datePage: 0, dateIndexOf: 0 }
  }

  const itemWidth = Math.ceil(node.scrollWidth / days.length + 1) + 2
  const daysPerPage = node.offsetWidth / itemWidth

  const dateIndexOf = days.map((x) => x.day).indexOf(date) || 0
  const datePage = (date && Math.floor(dateIndexOf / daysPerPage)) || 0

  const indices = {
    date,
    datePage,
    dateIndexOf,
  }

  return indices
}

function scrollCalendar(node, direction, callback) {
  let diff = Math.ceil(node.scrollLeft % node.offsetWidth)
  let left
  if (direction === 'right') {
    if (node.offsetWidth - diff < 2) diff = -1
    left = node.scrollLeft + (node.offsetWidth - diff)
  }
  if (direction === 'left') {
    if (diff + 1 >= node.offsetWidth) diff = node.offsetWidth - diff
    left = node.scrollLeft - node.offsetWidth - diff
  }

  let scrollTimeout
  const onScroll = () => {
    clearTimeout(scrollTimeout)
    scrollTimeout = setTimeout(() => {
      callback()
      node.removeEventListener('scroll', onScroll)
    }, 200)
  }

  node.addEventListener('scroll', onScroll)
  node.scrollTo({
    left,
    top: 0,
    behavior: 'smooth',
  })
}

const getFirstLastVisibleIndices = (node, days) => {
  const itemWidth = Math.ceil(node.scrollWidth / days.length)
  const numberOfItemsScrolled = Math.ceil(node.scrollLeft / itemWidth)
  const daysPerPage = Math.ceil(node.offsetWidth / itemWidth)

  const firstLast = {
    first: numberOfItemsScrolled,
    last: numberOfItemsScrolled + daysPerPage - 1,
  }
  return firstLast
}

const scrollToFirstAvailableDate = (node, scrollTo) => {
  const left = scrollTo - node.offsetWidth

  node.scrollTo({
    left,
    top: 0,
    behavior: 'smooth',
  })
}

const scrollToStoreList = () => {
  const storeList = document.getElementById('store-list');
  const headerOffset = 250;
  const storeListPosition = storeList.getBoundingClientRect().top;
  const offsetPosition = storeListPosition + window.scrollY - headerOffset;

  tracker.trackClick(
    'BADA 2.0 calendar pick a different store',
    `Pick a different store`
  )

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
});
}

const Calendar = ({
  days,
  allDays,
  allDataLoaded,
  onChange,
  serviceData,
  loading,
  startDate,
  endDate,
  meetingTypeId,
  brandConfig,
  showAltStore
}) => {
  const t = useTranslation('app.slot-selection')
  const c = useTranslation('app.calendar')
  const calenderRef = useRef(null)
  const [gotoPage, setGotoPage] = useState(0)
  const [firstDate, setFirstDate] = useState(null)
  const [gotoPageIndex, setGotoPageIndex] = useState(0)
  const [disabledGotoButton, setDisabledGotoButton] = useState(false)
  const [showGotoButton, setShowGotoButton] = useState(true)
  const [scrolledToStart, setScrolledToStart] = useState(true)
  const [scrolledToEnd, setScrolledToEnd] = useState(false)

  function reset() {
    setGotoPage(0)
    setGotoPageIndex(0)
    setDisabledGotoButton(false)
    setShowGotoButton(true)
    setScrolledToStart(true)
    setScrolledToEnd(false)
  }

  useEffect(() => {
    reset()
  }, [meetingTypeId])

  useEffect(() => {
    if (allDays.length === 0) return
    const { date, datePage, dateIndexOf } = getFirstSelectableDate(
      calenderRef.current,
      allDays
    )
    setFirstDate(date)
    setGotoPage(datePage)
    setGotoPageIndex(dateIndexOf)
  }, [allDataLoaded])

  function updatePosition(leftPosition) {
    const { current } = calenderRef
    const DAY_COUNT = days.length + 1
    const itemWidth = Math.ceil(current.scrollWidth / DAY_COUNT) + 2
    const gotoLocation = itemWidth * (gotoPageIndex + 1)

    const left = leftPosition
    const right = left + current.offsetWidth

    const isInRange = left < gotoLocation && right > gotoLocation
    const showButton = left < gotoLocation && right < gotoLocation

    if (isInRange) {
      setShowGotoButton(false)
    } else {
      setShowGotoButton(showButton)
    }
  }

  function setLeftArrowState(leftPosition) {
    updatePosition(leftPosition)
  }

  function setRightArrowState(leftPosition) {
    updatePosition(leftPosition)
  }

  const fetchSkeletonData = (start, end) => {
    if (!start || !end) return []

    const startDateTz =
      brandConfig &&
      brandConfig.ianaTimeZoneId &&
      moment
        .tz(brandConfig.ianaTimeZoneId)
        .add(settings.disableSameDayBooking ? 1 : 0, 'days')
        .format()
        .split('T')[0]

    const datesBetween = []
    const startingMoment = moment(startDateTz)
    while (startingMoment <= moment(end)) {
      const item = {
        closed: false,
        day: startingMoment.clone().toISOString(true).split('T')[0],
        intervals: [
          {
            type: 'available',
            from: `01:00`,
            to: '01:00',
          },
          {
            type: 'available',
            from: `02:00`,
            to: '01:00',
          },
          {
            type: 'available',
            from: `03:00`,
            to: '01:00',
          },
          {
            type: 'available',
            from: `04:00`,
            to: '01:00',
          },
          {
            type: 'available',
            from: `05:00`,
            to: '01:00',
          },
        ],
      }

      datesBetween.push(item)
      startingMoment.add(1, 'days')
    }

    return datesBetween
  }

  useEffect(() => {
    fetchSkeletonData(startDate, endDate)
  }, [startDate, endDate])

  const allSlotsReserved =
    (allDays &&
      allDays.every((day) =>
        day.intervals.every((interval) => interval.type === 'reserved')
      )) ||
    false

  const daySlotsReserved = (day) => day.intervals.every((interval) => interval.type === 'reserved')

  const scrollToCustom = (target) => {
    const { current } = calenderRef
    const DAY_COUNT = days.length + 1
    const itemWidth = Math.ceil(current.scrollWidth / DAY_COUNT) + 2

    const gotoLocation = itemWidth * (gotoPageIndex + 1) + itemWidth

    scrollToFirstAvailableDate(target, gotoLocation)
    setDisabledGotoButton(true)
  }

  const renderNextAvailableSlotButton = (locale) => {
    const nextButtonLabel = getDateFormat(firstDate, locale)
    const label =
      nextButtonLabel.charAt(0).toUpperCase() + nextButtonLabel.slice(1)

    return (
      <NextSlotButtonContainer>
        <TextInline>{t('firstAvailableSlotLabel')}</TextInline>
        <StyledNextButton
          data-track="first-available-slot-button"
          size={'small'}
          onClick={() => scrollToCustom(calenderRef.current, gotoPage)}
        >
          {label}
        </StyledNextButton>
      </NextSlotButtonContainer>
    )
  }

  const setButtonsState = (calendarRef) => {
    const { first, last } = getFirstLastVisibleIndices(
      calendarRef.current,
      days
    )
    setScrolledToStart(first === 0)
    setScrolledToEnd(last === days.length - 1)
  }

  const noSlotsAvailable =
    ((allSlotsReserved && allDays.length > 0) || allDays.length === 0) &&
    allDataLoaded
  const showNextButton =
    !disabledGotoButton && gotoPage !== 0 && showGotoButton && !allSlotsReserved

  return (
    <>
      {showNextButton &&
        renderNextAvailableSlotButton(brandConfig && brandConfig.languageTag)}
      {noSlotsAvailable && (
        <ClosedMessageContainer>
          <ClosedTitle>{t('closed')}</ClosedTitle>
          <ClosedSubtitle>{t('closed.subline')}</ClosedSubtitle>
        </ClosedMessageContainer>
      )}
    {!noSlotsAvailable && (
      <>
      {days && days.length > 0 && (
        <NavigationButtons>
          <NavigationButtonWithLoadingIndicator
            left
            onClick={() => {
              if (allDataLoaded) {
                scrollCalendar(calenderRef.current, 'left', () => {
                  setButtonsState(calenderRef)
                })
              }
            }}
            dimmed={scrolledToStart}
            isLoading={!allDataLoaded}
          >
            <ArrowLeftIcon width={20} height={40} />
            <p>{c('earlier')}</p>
          </NavigationButtonWithLoadingIndicator>
          <NavigationButtonWithLoadingIndicator
            onClick={() => {
              if (allDataLoaded) {
                  scrollCalendar(calenderRef.current, 'right', () => {
                    setButtonsState(calenderRef)
                  })
                }
              }}
              dimmed={scrolledToEnd}
              isLoading={!allDataLoaded}
            >
            <p>{c('later')}</p>
            <ArrowRightIcon width={20} height={40} />
          </NavigationButtonWithLoadingIndicator>
        </NavigationButtons>
      )}
      <ListWrapper>
        {!loading ? (
          <StyledList
            ref={calenderRef}
            data-cy="Calender"
            onScroll={() => {
              const { current } = calenderRef
              setLeftArrowState(current.scrollLeft)
              setRightArrowState(
                current.scrollLeft,
                current.offsetWidth,
                current.scrollWidth
              )
              setButtonsState(calenderRef)
            }}
          >
            {brandConfig &&
              brandConfig.languageTag &&
              days.map((day) => (
                <Day
                  day={new Date(day.day)}
                  key={day.day}
                  today={t('today')}
                  tomorrow={t('tomorrow')}
                  locale={brandConfig.languageTag}
                >
                  {day.intervals.filter(
                    (interval) => interval.type === 'available'
                  ).length === 0 &&
                    !day.closed &&
                    day.intervals?.length !== 0 && (
                      <StyledItem
                        hideInactiveSlots={!features.hideInactiveSlots}
                      >
                        {features.hideInactiveSlots && (
                          <Info
                            dangerouslySetInnerHTML={{
                              __html: serviceData.slotSelectionClosed,
                            }}
                          />
                        )}
                      </StyledItem>
                    )}
                    
                  {// eslint-disable-next-line
                  day.intervals.map((interval) => {
                    if (interval.type === 'available')
                    return (
                      <StyledItem key={`${day.day}-${interval.from}`}>
                        <RadioButtonRich
                          name="timeslot"
                          value={`${day.day}T${interval.from}`}
                          onChange={() =>
                            onChange({
                              overrideMeetingType: null,
                              selectedSlot: {
                                from: `${day.day}T${interval.from}`,
                                to: `${day.day}T${interval.to}`,
                              },
                            })
                          }
                        >
                          <Slot {...interval} />
                        </RadioButtonRich>
                      </StyledItem>
                    )
                  })}

                  {!(day.closed || day.intervals?.length === 0) && daySlotsReserved(day) && (
                    <div>
                      <Info
                        dangerouslySetInnerHTML={{
                          __html: c('noappointments'),
                        }}
                      />
                    </div>
                  )}

                  {(day.closed || day.intervals?.length === 0) && serviceData && (
                    <div>
                      <Info
                        dangerouslySetInnerHTML={{
                          __html: serviceData.slotSelectionClosed,
                        }}
                      />
                    </div>
                  )}
                </Day>
              ))}
          </StyledList>
        ) : (
          <SkeletonList>
            {brandConfig &&
              brandConfig.languageTag &&
              fetchSkeletonData(startDate, endDate).map((day) => (
                <Day
                  day={new Date(day.day)}
                  key={day.day}
                  today={t('today')}
                  tomorrow={t('tomorrow')}
                  locale={brandConfig.languageTag}
                >
                  {day.intervals.map((interval) => (
                    <StyledItem key={`${day.day}-${interval.from}`}>
                      <SkeletonSlotAnimation>&nbsp;</SkeletonSlotAnimation>
                    </StyledItem>
                  ))}
                </Day>
              ))}
          </SkeletonList>
        )}
      </ListWrapper>
      </>
      )}
      {showAltStore && (
        <AltStore>
          <AltStoreParagraph>{c('altstore.paragraph')}</AltStoreParagraph>
          <Button onClick={() => scrollToStoreList()}>{c('altstore.button')}</Button>
        </AltStore>
      )}
    </>
  )
}

export default Calendar
