import React from 'react'
import styled from 'styled-components'
import FileInput from './FileInput'
import GalleryThumbnail from './GalleryThumbnail'
import ErrorMessage from './ErrorMessage'
import { Row, Column } from '../grid'
import Button from '../button/baseButton'

const StyledLabel = styled(Button).attrs({ as: 'label' })`
  position: relative;
  z-index: 1;
  margin: 0;
  text-align: center;
`

const GalleryThumbnailsRow = styled(Row)`
  margin: -5px;
  padding: 0px 0px 15px;
`

const GalleryThumbnailColumn = styled(Column)`
  padding: 5px;
`

const StyledFileInput = styled(FileInput)`
  display: block;
`

const SimpleGallery = ({
  uploader,
  files,
  errors,
  multiple,
  debug,
  onClickThumbnail,
  fileInputText,
  fileInputId,
}) => {
  const hasFiles = files.length > 0
  const hasErrors = errors.length > 0

  return (
    <>
      {hasFiles && (
        <GalleryThumbnailsRow debug={debug}>
          {files.map((file) => (
            <GalleryThumbnailColumn xs={6} sm={4} md={3} key={file.id}>
              <GalleryThumbnail
                id={file.id}
                fromServer={file.fromServer}
                uploader={uploader}
                status={file.status}
                metadata={file.metadata}
                onClick={onClickThumbnail}
                deleteButton={file.metadata && !!file.metadata.blobName}
              />
            </GalleryThumbnailColumn>
          ))}
        </GalleryThumbnailsRow>
      )}
      <StyledFileInput id={fileInputId} uploader={uploader} multiple={multiple}>
        <StyledLabel htmlFor={fileInputId} size="small" block>
          {fileInputText}
        </StyledLabel>
      </StyledFileInput>
      {hasErrors && (
        <Row>
          <Column>
            {errors.map((error) => (
              <ErrorMessage {...error} key={error.id} uploader={uploader} />
            ))}
          </Column>
        </Row>
      )}
    </>
  )
}

SimpleGallery.defaultProps = {
  multiple: true,
  files: [],
  errors: [],
  debug: false,
  onClickThumbnail: null,
  fileInputText: null,
  fileInputId: null,
}

export default SimpleGallery
