import React from 'react'
import { SectionItem, SectionItemText, SectionItemHeading } from '../sharedUI'
import Select from './Select'
import useTranslation from '../../../../utils/translation'

/**
 * @param {{
 *  onChange: (args: any) => any,
 *  serviceId: string,
 *  serviceName: string,
 *  serviceOptions: {
 *    value: any,
 *    label: string,
 *    disabled: boolean
 *  }[],
 *  canceled: boolean}} props
 */
const ServiceEditor = ({
  onChange,
  serviceId,
  serviceName,
  serviceOptions,
  canceled,
  available,
}) => {
  const t = useTranslation('appointment-details')
  const service = serviceOptions.find((o) => o.value.toString() === serviceId)
  if (!service) {
    serviceOptions.unshift({
      label: serviceName,
      value: serviceId,
      disabled: true,
    })
  }

  const showFormInput = !canceled && available

  return (
    <SectionItem>
      <SectionItemHeading>{t('meeting-type')}</SectionItemHeading>
      {showFormInput ? (
        <Select
          options={serviceOptions}
          value={`${serviceId}`}
          onChange={onChange}
        />
      ) : (
        <SectionItemText>{service && service.label}</SectionItemText>
      )}
    </SectionItem>
  )
}

export default ServiceEditor
