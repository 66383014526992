import * as constants from '../../constants'
import baseTheme from './baseTheme'
import magnetTheme from './magnetTheme'
import magnettradeTheme from './magnettradeTheme'
import hthgoTheme from './hthgoTheme'
import noremaTheme from './noremaTheme'
import novartTheme from './novartTheme'
import marbodalTheme from './marbodalTheme'
import unoformTheme from './unoformTheme'
import sigdalTheme from './sigdalTheme'
import eweTheme from './eweTheme'
import eweenTheme from './eweenTheme'
import invitaTheme from './invitaTheme'
import intuoTheme from './intuoTheme'

const themes = {
  [constants.BRAND_MAGNET]: magnetTheme,
  [constants.BRAND_MAGNETTRADE]: magnettradeTheme,
  [constants.BRAND_HTHGO]: hthgoTheme,
  [constants.BRAND_HTHDK]: hthgoTheme,
  [constants.BRAND_HTHNO]: hthgoTheme,
  [constants.BRAND_HTHSE]: hthgoTheme,
  [constants.BRAND_HTHFI]: hthgoTheme,
  [constants.BRAND_HTHDE]: hthgoTheme,
  [constants.BRAND_NOREMA]: noremaTheme,
  [constants.BRAND_NOVART]: novartTheme,
  [constants.BRAND_MARBODAL]: marbodalTheme,
  [constants.BRAND_UNOFORM]: unoformTheme,
  [constants.BRAND_UNOFORMDK]: unoformTheme,
  [constants.BRAND_UNOFORMNO]: unoformTheme,
  [constants.BRAND_UNOFORMSE]: unoformTheme,
  [constants.BRAND_UNOFORMBE]: unoformTheme,
  [constants.BRAND_SIGDAL]: sigdalTheme,
  [constants.BRAND_EWE]: eweTheme,
  [constants.BRAND_EWEEN]: eweenTheme,
  [constants.BRAND_INTUO]: intuoTheme,
  [constants.BRAND_INVITA]: invitaTheme,
}

export default themes
export { baseTheme }
