import { createElement } from 'react'
import { AppContainer as RHLContainer } from 'react-hot-loader'

const AppContainer = ({ rhl, children, ...props }) =>
  rhl ? createElement(RHLContainer, props, children) : children

AppContainer.defaultProps = {
  rhl: true,
}

export default AppContainer
