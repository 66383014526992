import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import settings from '../settings'
import Button from '../button/baseButton'
import Paragraph from '../typography/paragraph'
import SubHeadline from '../typography/subHeadline'
import Link from '../typography/link'
import useTranslation from '../../../utils/translation'
import RadioButtonRich from '../toggle'
import Day from './day'
import NavigationButton from '../calender/navigation-button'
import Slot from './slot'
import ArrowLeftIcon from '../icons/arrow-left'
import ArrowRightIcon from '../icons/arrow-right'
import { mobileCheck } from '../../containers/bada/utils'
import useBrandConfig from '../../hooks/useBrandConfig'
import features from '../../components/features'

const ListWrapper = styled.div`
  position: relative;
`
const StyledList = styled.ol`
  position: relative;
  list-style-type: none;
  margin: 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 0 40px;
  width: 100%;
  max-width: 1057px;
  overflow-x: scroll;
  scrolling-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  z-index: 1;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const StyledItem = styled.li`
  margin-bottom: ${(props) => (props.hideInactiveSlots ? '0' : '10px')};
  text-align: center;
`

const Info = styled(Paragraph)`
  padding: 10px 0px;
`

const NoTimeSuitable = styled.div`
  padding: 0;
  ${(props) => props.theme.media.min.sm`
    padding: 18px 0px;
  `}
`
const StyledSubHeadline = styled(SubHeadline)`
  margin-top: 0;
  margin-bottom: 4px;
  font-size: ${theme('calendar.subHeadline.fontSize')};
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 10px;
  font-size: ${theme('calendar.paragraph.fontSize')};
  font-weight: ${theme('typogrpahy.paragraph.fontWeight')};
`
const StyledLink = styled(Link)`
  cursor: pointer;
  border-bottom: ${theme('tabs.header.link.borderBottom')};
  text-decoration: ${theme('tabs.header.link.textDecoration')};
  font-weight: ${theme('skipSlots.fontWeight')};
  font-size: ${theme('calendar.paragraph.fontSize')};
  font-family: ${theme('typography.paragraph.fontFamily')};

  ::after {
    ${theme('noTimeSuitable.after')};
  }
`

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: 40px;
  position: relative;
  top: -20px;
  ${(p) => p.theme.media.min.lg`
    position: static;
    top: auto;
  `}
`
const ClosedMessageContainer = styled.div`
  background-color: #f7f5f1;
  border: 1px solid #d3d3d3;
  color: #000;

  padding: 14px;
  margin-bottom: 14px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`
const ClosedTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`
const ClosedSubtitle = styled.p`
  font-size: 16px;
  margin: 0;
`

const NextSlotButtonContainer = styled.div`
  background-color: #f7f5f1;
  border: 1px solid #d3d3d3;
  color: #000;

  display: flex;
  width: 100%;
  padding: 14px;
  box-sizing: border-box;
  margin-bottom: 14px;
  justify-content: center;
  align-content: center;
  text-align: center;
  border-radius: 3px;

  flex-direction: column;

  ${(p) => p.theme.media.min.lg`
    flex-direction: row;
    align-items: center;
  `}
`

const TextInline = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 16px;
  color: #000;

  margin-bottom: 14px;
  ${(p) => p.theme.media.min.lg`
    margin-bottom: 0;
    margin-right: 14px;
  `}
`
const StyledNextButton = styled(Button)`
  font-size: 15px;
  font-weight: ${theme('typogrpahy.paragraph.fontWeight')};

  :before {
    border-radius: 3px;
  }
`
const scrollRight = (node) => {
  let diff = Math.ceil(node.scrollLeft % node.offsetWidth)

  if (node.offsetWidth - diff < 2) diff = -1

  const left = node.scrollLeft + (node.offsetWidth - diff)

  node.scrollTo({
    left,
    top: 0,
    behavior: 'smooth',
  })
}

const scrollLeft = (node) => {
  let diff = Math.ceil(node.scrollLeft % node.offsetWidth)

  if (diff + 1 >= node.offsetWidth) diff = node.offsetWidth - diff

  const left = node.scrollLeft - node.offsetWidth - diff

  node.scrollTo({
    left,
    top: 0,
    behavior: 'smooth',
  })
}

const getDateFormat = (date, locale) =>
  new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
  }).format(new Date(date))

const DESKTOP_RANGE = 7 // 7 days
const MOBILE_RANGE = 2 // 2 days
const RANGE = mobileCheck() ? MOBILE_RANGE : DESKTOP_RANGE

const scrollToFirstAvailableDate = (node, scrollTo) => {
  const left = scrollTo - node.offsetWidth

  node.scrollTo({
    left,
    top: 0,
    behavior: 'smooth',
  })
}

const getFirstSelectableDate = (days) => {
  const availableDays = days?.filter(
    (f) =>
      f.intervals &&
      f.intervals.length > 0 &&
      f.intervals.some((c) => c.type === 'available')
  )

  const date = availableDays && availableDays[0] && availableDays[0].day
  const dateIndexOf = days.map((x) => x.day).indexOf(date) || 0
  const datePage = (date && Math.floor(dateIndexOf / RANGE)) || 0

  return {
    date,
    datePage,
    dateIndexOf,
  }
}

const Calendar = ({
  days,
  onChange,
  serviceData,
  noSlotMeetingType,
  service,
}) => {
  const t = useTranslation('app.slot-selection')
  const calenderRef = useRef(null)
  const [disableLeftArrow, setDisableLeftArrow] = useState(true)
  const [disableRightArrow, setDisableRightArrow] = useState(false)
  const [firstDate, setFirstDate] = useState(null)
  const [page, setPage] = useState(0)
  const [gotoPage, setGotoPage] = useState(0)
  const [gotoPageIndex, setGotoPageIndex] = useState(0)
  const [disabledGotoButton, setDisabledGotoButton] = useState(false)
  const [showGotoButton, setShowGotoButton] = useState(true)

  const allSlotsReserved = days.every((day) =>
    day.intervals.every((interval) => interval.type === 'reserved')
  )

  useEffect(() => {
    const { date, datePage, dateIndexOf } = getFirstSelectableDate(days)
    setFirstDate(date)
    setGotoPage(datePage)
    setGotoPageIndex(dateIndexOf)
  }, [days])

  function updatePosition(leftPosition) {
    const { current } = calenderRef
    const DAY_COUNT = days.length + 1
    const itemWidth = Math.ceil(current.scrollWidth / DAY_COUNT) + 2
    const gotoLocation = itemWidth * (gotoPageIndex + 1)

    const left = leftPosition
    const right = left + current.offsetWidth

    const isInRange = left < gotoLocation && right > gotoLocation
    const showButton = left < gotoLocation && right < gotoLocation

    if (isInRange) {
      setShowGotoButton(false)
    } else {
      setShowGotoButton(showButton)
    }
  }

  function setLeftArrowState(leftPosition) {
    updatePosition(leftPosition)
    setDisableLeftArrow(leftPosition < 2)
  }

  function setRightArrowState(leftPosition, offsetWidth, totalWidth) {
    updatePosition(leftPosition)
    setDisableRightArrow(leftPosition + offsetWidth >= totalWidth - 2)
  }

  const scrollToCustom = (target, firstDatePage) => {
    setPage(firstDatePage)

    const { current } = calenderRef
    const DAY_COUNT = days.length + 1
    const itemWidth = Math.ceil(current.scrollWidth / DAY_COUNT) + 2

    const gotoLocation = itemWidth * (gotoPageIndex + 1) + itemWidth

    scrollToFirstAvailableDate(target, gotoLocation)
    setDisabledGotoButton(true)
  }

  const renderNextAvailableSlotButton = (locale) => {
    const nextButtonLabel = getDateFormat(firstDate, locale)
    const label =
      nextButtonLabel.charAt(0).toUpperCase() + nextButtonLabel.slice(1)

    return (
      <NextSlotButtonContainer>
        <TextInline>{t('firstAvailableSlotLabel')}</TextInline>
        <StyledNextButton
          data-track="first-available-slot-button"
          size={'small'}
          onClick={() => scrollToCustom(calenderRef.current, gotoPage)}
        >
          {label}
        </StyledNextButton>
      </NextSlotButtonContainer>
    )
  }

  if (!days) return null

  const showNextButton =
    !disabledGotoButton && gotoPage !== 0 && showGotoButton && !allSlotsReserved

  const brandConfig = useBrandConfig(settings.brand).data

  return (
    <>
      {showNextButton &&
        renderNextAvailableSlotButton(brandConfig && brandConfig.languageTag)}
      {allSlotsReserved && (
        <ClosedMessageContainer>
          <ClosedTitle>{t('closed')}</ClosedTitle>
          <ClosedSubtitle>{t('closed.subline')}</ClosedSubtitle>
        </ClosedMessageContainer>
      )}
      <ListWrapper>
        <StyledList
          ref={calenderRef}
          data-cy="Calender"
          onScroll={() => {
            const { current } = calenderRef
            setLeftArrowState(current.scrollLeft)
            setRightArrowState(
              current.scrollLeft,
              current.offsetWidth,
              current.scrollWidth
            )
          }}
        >
          {brandConfig &&
            brandConfig.languageTag &&
            days.map((day) => (
              <Day
                day={new Date(day.day)}
                key={day.day}
                today={t('today')}
                tomorrow={t('tomorrow')}
                locale={brandConfig.languageTag}
              >
                {day.intervals.filter(
                  (interval) => interval.type === 'available'
                ).length === 0 &&
                  !day.closed && (
                    <StyledItem hideInactiveSlots={!features.hideInactiveSlots}>
                      {features.hideInactiveSlots && (
                        <Info
                          dangerouslySetInnerHTML={{
                            __html: service.slotSelectionClosed,
                          }}
                        />
                      )}
                    </StyledItem>
                  )}
                {day.intervals.map((interval) => {
                  if (interval.type === 'reserved')
                    return (
                      <StyledItem
                        key={`${day.day}-${interval.from}`}
                        hideInactiveSlots={features.hideInactiveSlots}
                      >
                        {!features.hideInactiveSlots && <Slot {...interval} />}
                      </StyledItem>
                    )
                  return (
                    <StyledItem key={`${day.day}-${interval.from}`}>
                      <RadioButtonRich
                        name="timeslot"
                        value={`${day.day}T${interval.from}`}
                        onChange={() =>
                          onChange({
                            overrideMeetingType: null,
                            selectedSlot: {
                              from: `${day.day}T${interval.from}`,
                              to: `${day.day}T${interval.to}`,
                            },
                          })
                        }
                      >
                        <Slot {...interval} />
                      </RadioButtonRich>
                    </StyledItem>
                  )
                })}
                {day.closed && service && (
                  <StyledItem>
                    <Info
                      dangerouslySetInnerHTML={{
                        __html: service.slotSelectionClosed,
                      }}
                    />
                  </StyledItem>
                )}
              </Day>
            ))}
        </StyledList>
      </ListWrapper>

      {days && days.length > 0 && (
        <NavigationButtons>
          <NavigationButton
            left
            onClick={() => {
              setPage(page - 1)
              scrollLeft(calenderRef.current)
            }}
            disabled={disableLeftArrow}
          >
            <ArrowLeftIcon width={20} height={20} />
          </NavigationButton>
          <NavigationButton
            onClick={() => {
              setPage(page + 1)
              scrollRight(calenderRef.current)
            }}
            disabled={disableRightArrow}
          >
            <ArrowRightIcon width={20} height={20} />
          </NavigationButton>
        </NavigationButtons>
      )}
      {serviceData && serviceData.showNoSuitableTime && (
        <NoTimeSuitable>
          <>
            <StyledSubHeadline>
              {serviceData.noSuitableTimeHeading}
            </StyledSubHeadline>
            <StyledParagraph>{serviceData.noSuitableTimeText}</StyledParagraph>
            <StyledLink
              onClick={() =>
                onChange({
                  selectedSlot: {},
                  overrideMeetingType: noSlotMeetingType,
                })
              }
            >
              {serviceData.noSuitableTimeLinkLabel}
            </StyledLink>
          </>
        </NoTimeSuitable>
      )}
    </>
  )
}

export default Calendar
