import uuidv5 from 'uuid/v5'

// UUID v5 customer id namespace.
// Used as a hash "salt" to generate a re-creatable UUID from the same input,
// while preserving a very high probability of uniqueness.
// The same input (name) and salt (namespace) will generate the same UUID.
const UUID_CUSTOMER_ID = '380c12db-ef00-4ae1-a7e4-48a3a0afcf3b'

const getContainerName = (blobContainer = '') =>
  uuidv5(blobContainer, UUID_CUSTOMER_ID)

export default getContainerName
