import { css } from 'styled-components'

const fontUrl =
  'https://res.cloudinary.com/dgg9enyjv/raw/upload/v1651582419/Norema/fonts'

const noremaFonts = css`
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("${fontUrl}/Inter/Inter-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("${fontUrl}/Inter/Inter-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("${fontUrl}/Inter/Inter-ExtraBold.woff2") format("woff2");
  }
`

export default noremaFonts
