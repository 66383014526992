import React from 'react'
import { withHandlers } from 'recompose'
import styled from 'styled-components'
import { prop } from 'styled-tools'

const Wrapper = styled.div`
  position: fixed;
  z-index: ${prop('theme.overlay.lightbox.zIndex', 100)};
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: ${prop('theme.overlay.lightbox.backdrop')};
`

const LightboxOverlay = (props) => (
  <Wrapper onClick={props.onClickWrapper} className={props.className}>
    {props.children}
  </Wrapper>
)

const enhance = withHandlers({
  onClickWrapper: (props) => (event) => {
    if (event.currentTarget === event.target) {
      props.setClosed()
    }
  },
})

export default enhance(LightboxOverlay)
