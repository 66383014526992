import React from 'react'
import PropTypes from 'prop-types'

const StoreMeeting = ({ width, height }) => (
  <svg viewBox="0 0 31 27" width={width} height={height}>
    <g
      transform="translate(.906 .125)"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    >
      <rect x="10.977" y="15.766" width="7.374" height="9.457" rx="1" />
      <rect x="1.047" y="7.411" width="27.135" height="17.789" rx="2" />
      <path
        fill="transparent"
        d="M1.091 9.638h27.046V2.223a1 1 0 0 0-1-1H2.091a1 1 0 0 0-1 1v7.415z"
      />
    </g>
  </svg>
)

StoreMeeting.defaultProps = {
  width: 50,
  height: 50,
}

StoreMeeting.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default StoreMeeting
