import axios from 'axios'
import settings from '../app/components/settings'

const CACHE_MAX_AGE_MS = 1000 * 60 * 5 // 5 mins
const cache = {}

/**
 * Brand configuration.
 * @typedef {{
 *  languageCode: string,
 *  countryCode: string,
 *  languageTag: string,
 *  ianaTimeZoneId?: string,
 *  defaultIanaTimeZoneId: string
 * }} BrandConfig
 */

/**
 * Gets the given brand's configurations.
 * @param {string} brand Brand name.
 * @returns {Promise<BrandConfig>} Brand configurations object.
 */
const getBrandConfig = async (brand) => {
  const cachedBrandConfig = cache[brand]
  const needsFetch =
    !cachedBrandConfig ||
    cachedBrandConfig.time + CACHE_MAX_AGE_MS < new Date().getTime()
  if (needsFetch) {
    const url = `${settings.badaConfigApiUrl}/api/brandconfig/?brand=${brand}`
    const brandConfig = await axios.get(url).then((response) => response.data)
    cache[brand] = {
      item: brandConfig,
      time: new Date().getTime(),
    }
  }

  return cache[brand].item
}

export default {
  getBrandConfig,
}
