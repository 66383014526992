import styled from 'styled-components'
import { theme } from 'styled-tools'
import React from 'react'
import PropTypes from 'prop-types'

export const navigateToRoute = (route) => {
  window.location.href = route
  window.setTimeout(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    })
  }, 2000)
}

export const navigateToStep = (step, history, isStore) => {
  history.push({ search: `?step=${step}${isStore ? '&store=true' : ''}` })
  window.scrollTo({
    left: 0,
    top: 0,
  })
}

export const ErrorMessage = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 36px;
  text-align: center;
  margin-top: 40px;
`
const getMessageKey = ({
  isSimplyBookmeAppointment,
  meetingHasPassed,
  simplyBookMeStatus,
  missingAppointmentId,
}) => {
  if (!isSimplyBookmeAppointment) return 'not-slot-booking'
  if (missingAppointmentId) return 'appointment-not-found'
  if (meetingHasPassed) return 'appointment-passed'
  if (simplyBookMeStatus) return 'appointment-canceled'
  return ''
}
export const showMessage = ({
  isSimplyBookmeAppointment,
  simplyBookMeStatus,
  meetingHasPassed,
  missingAppointmentId,
  translateFunc,
  step,
}) =>
  !isSimplyBookmeAppointment ||
  missingAppointmentId ||
  (simplyBookMeStatus === 'canceled' && step !== '3') ||
  meetingHasPassed ? (
    <ErrorMessage>
      {translateFunc(
        getMessageKey({
          isSimplyBookmeAppointment,
          meetingHasPassed,
          simplyBookMeStatus,
          missingAppointmentId,
        })
      )}
    </ErrorMessage>
  ) : undefined
showMessage.propTypes = {
  simplyBookMeStatus: PropTypes.bool.isRequired,
  meetingHasPassed: PropTypes.bool.isRequired,
  missingAppointmentId: PropTypes.bool.isRequired,
  translateFunc: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
}
