import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { transparentize } from 'polished'

const basePixelSize = '14'

const FONT_FAMILIES = {
  NewSpirit: '"NewSpirit", sans-serif',
  Campton: '"Campton", Arial, sans-serif',
}

const colors = {
  black: '#000',
  darkBlue: '#122126',
  blue: '#002b5e',
  red: '#d81f43',
  green: '#7fb940',
  white: '#fff',
  gray: '#babcbe',
  lightGray: '#dddedf',
  darkGray: '#7f8487',
  lightYellow: '#feffcc',
  warmWood: '#cc9c74',
  toneA: '#f7efe9',
  toneB: '#faf5f2',
}

const contextualColors = {
  primary: colors.darkBlue,
  secondary: colors.blue,
  link: colors.blue,
  active: colors.darkBlue,
  alert: colors.red,
  success: colors.green,
  error: transparentize(0.5, colors.red),
  valid: colors.green,
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.gray,
  borderPrimary: colors.darkGray,
}

const GlobalStyles = createGlobalStyle`
  :root {
    font: 300 ${basePixelSize}px/1.43 ${FONT_FAMILIES.Campton};
  }

  html {
      overflow: -moz-scrollbars-vertical;
      overflow-y: scroll;
  }

  body {
    font-family: ${FONT_FAMILIES.Campton};
  }

  strong{
    font-weight: 700
  }

  label{
    font-weight: 500;
    margin-bottom: 5px;
  }

`

export default {
  global: {
    fontSmoothing: 'antialiased',
  },
  header: {
    backgroundColor: '#fff',
    color: 'inherit',
    heightDesktop: '80px',
    fontFamily: theme('typography.paragraph.fontFamily'),
  },
  colors: {
    default: 'rgb(25, 34, 40)',
    link: 'rgb(25, 34, 40)',
    darkGrey: 'rgb(25, 34, 40)',
    magnetBlue: 'rgb(25, 34, 40)',
    inputBorder: '#d7d7d7',
    accent: 'rgb(25, 34, 40)',
    accentShadow: '0 0 0 4px rgba(0, 27, 51, 0.15)',
    secondaryBackgroundColor: '#f3f4f5',
    boxColor: 'rgb(247, 239, 233)',
  },
  typography: {
    pageTitle: {
      fontFamily: FONT_FAMILIES.NewSpirit,
      fontSize: '24px',
      fontSizeLarge: '32px',
    },

    title: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.428,
      fontSizeDesktop: '16px',
    },

    storeName: {
      fontWeight: 500,
      fontSize: '16px',
      mobile: {
        fontSize: '16px',
      },
    },
    storeAddress: {
      fontSize: '16px',
      padding: '20px 20px 16px 20px',
    },
    storeDistance: {
      fontWeight: 500,
    },
    usp: {
      desktop: {
        fontSize: '15px',
      },
      mobile: {
        fontSize: '15px',
      },
    },
    amend: {
      headline: {
        fontFamily: FONT_FAMILIES.NewSpirit,
      },
    },
    subHeadline: {
      textTransform: 'none',
    },
    headline: {
      fontSize: '22px',
      fontSizeDesktop: '36px',
      fontSizeLarge: '22px',
      textTransform: 'none',
    },
    paragraph: {
      fontFamily: FONT_FAMILIES.Campton,
      fontSize: '16px',
      fontSizeLarge: '20px',
    },
  },
  storeList: {
    storeName: {
      fontWeight: 500,
      fontSize: '16px',
    },
  },
  temporarilyClosed: {
    marginTop: '15px',
    padding: '10px',
    borderRadius: '3px',
    icon: {
      width: '24px',
    },
    text: {
      paddingLeft: '10px',
      p: {
        margin: '3px 0px 3px 0px',
      },
    },
  },
  contentHeader: {
    height: {
      desktop: '350px',
      small: '290px',
      mobile: '250px',
    },
    width: {
      maxWidth: '100%',
    },
    subtitle: {
      display: 'block',
      marginTop: '10px',
      marginRight: 'auto',
      marginBottom: '20px',
      marginLeft: 'auto',
      maxWidth: '1200px',
      fontSize: '14px',
      desktop: {
        marginBottom: '30px',
      },
    },
    imageHeader: {
      backgroundColor: '#f9f8f2',
    },
    backgroundColor: 'none',
    heading: {
      color: 'inherit',
      desktop: {
        textAlign: 'center',
        lineHeight: '45px',
      },
    },
    icons: {
      color: 'rgb(25, 34, 40)',
      marginBottom: '15px',
      desktop: {
        width: '45px',
        height: '45px',
      },
      mobile: {
        width: '20px',
        height: '20px',
      },
    },
    usps: {
      marginTop: 0,
      marginRight: 'auto',
      marginBottom: 0,
      marginLeft: 'auto',
      paddingLeft: 0,
      fontSize: '15px',
      color: 'rgb(25, 34, 40)',
      lineHeight: '20px',
      textAlign: 'center',
      maxWidth: '800px',
      item: {
        maxWidth: '225px',
      },
    },
    otherNeeds: {
      color: 'rgb(25, 34, 40)',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  tabs: {
    color: {
      default: 'rgb(25, 34, 40)',
      inactive: 'rgb(25, 34, 40)',
      bottomBorder: '#d7d7d7',
      activeBorder: 'rgb(25, 34, 40)',
    },
    fontWeight: {
      active: '500',
      inactive: '300',
    },
    header: {
      link: {
        color: theme('colors.link'),
        borderBottom: 0,
        fontWeight: 'bold',
      },
    },
  },
  meetingType: {
    iconsize: {
      default: '24px',
      videoMeeting: '35px',
    },

    text: {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 200,
    },
  },

  bookingSummary: {
    backgroundColor: '#F7EFE9',
    borderWidth: '0px',
    borderStyle: 'none',
    borderColor: 'transparent',
    itemTitle: {
      color: 'rgb(25, 34, 40)',
      textTransform: 'none',
      fontFamily: FONT_FAMILIES.Campton,
      fontWeight: 600,
      fontSize: '16px',
      marginBottom: '5px',
    },
    itemText: {
      marginBottom: '25px',
    },
  },
  designer: {
    minHeight: '225px',
    padding: {
      desktop: '0 15px',
      mobile: '0px 15px',
    },
    margin: {
      desktop: '30px 0',
      mobile: '15px 0 30px 0',
    },
    headline: {
      textTransform: { normal: 'none', embedded: 'none' },
      fontSize: '18px',
      margin: '15px 0 15px 0',
      letterSpacing: {
        normal: '1px',
        embedded: '0px',
      },
    },
    paragraph: {
      fontSize: {
        desktop: '15px',
        mobile: {
          normal: '15px',
          embedded: '14px',
        },
      },
      lineHeight: {
        desktop: '20px',
        mobile: '20px',
      },
      color: 'rgb(25, 34, 40)',
    },
    background: {
      color: '#F7EFE9',
    },
    name: {
      fontWeight: 500,
    },
    location: {
      fontWeight: 300,
    },
    image: {
      maxWidth: '230px',
      maxHeight: '250px',
    },
    left: {
      margin: '0 0 40px 0',
    },
    right: {
      flexDirection: 'row-reverse',
    },
  },
  quickQuestions: {
    container: {
      bottomBorder: '1px solid #dbdbdb',
      mobile: {
        padding: '0 0 5px 0',
        margin: '20px 0 0 0',
      },
      desktop: {
        flexDirection: 'row',
        padding: '0 0 10px 0',
        margin: '40px 0 0 0',
      },
    },
    heading: {
      style1: {
        color: 'rgb(25, 34, 40)',
        fontWeight: 700,
        letterSpacing: '.5px',
        fontFamily: FONT_FAMILIES.Campton,
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'none',
        minWidth: {
          desktop: '265px',
        },
      },
    },
  },
  forms: {
    customerForm: {
      margin: '18px',
      headings: {
        fontSize: '22px',
      },
    },
    inputWrapper: {
      minHeight: '75px',
    },
    position: {
      top: '21px',
    },
    optinText: {
      fontSize: '16px',
      fontWeight: 300,
    },
    prepForm: {
      detailsToggleButton: {
        fontWeight: 300,
      },
      headline: {
        color: 'rgb(25, 34, 40)',
        desktop: {
          fontSize: '22px',
          marginTop: '60px',
          lineHeight: '48px',
        },
        mobile: {
          fontSize: {
            normal: '22px',
            embedded: '28px',
          },
          marginTop: {
            normal: '60px',
            embedded: '25px',
          },
        },
        textAlign: { normal: 'center', embedded: 'left' },
        textTransform: { normal: 'none', embedded: 'none' },
        letterSpacing: { normal: '1px', embedded: '0px' },
        lineHeight: { normal: '', embedded: '32px' },
      },
      paragraph: {
        textAlign: 'center',
        mobile: {
          fontSize: {
            normal: '15px',
            embedded: '14px',
          },
        },
        desktop: {
          fontSize: {
            normal: '20px',
            embedded: '14px',
          },
        },
        color: 'rgb(25, 34, 40)',
        maxWidth: '550px',
        lineHeight: '20px',
        margin: {
          mobile: '0px auto 40px auto',
          desktop: '0px auto 40px auto',
          normal: '0px auto 40px auto',
          embedded: '25px 0px 30px 0px;',
        },
      },
    },
    borderRadius: 0,
    height: '47px',
    padding: '12px',
    fontFamily: FONT_FAMILIES.Campton,
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: 1,
    fontSizeDesktop: '14px',
    lineHeightDesktop: 1,
    marginBottom: '22px',
    addressResults: {
      height: '232px',
    },
    colors: {
      color: contextualColors.primary, // colors.primary
      background: colors.white, // colors.white
      border: contextualColors.borderPrimary, // colors.inputbBorder
      valid: contextualColors.valid, // colors.valid
      validBackground: contextualColors.valid, // colors.valid
      errorColor: contextualColors.alert, // colors.invalidBorder
      errorBackground: 'none', // colors.invalidBorder
      invalidBorder: contextualColors.alert, // colors.invalidBorder
      invalidBackground: colors.white, // colors.invalid
      activeBorder: contextualColors.active, // colors.black
      active: contextualColors.primary,
      selected: contextualColors.primary,
      radioButtonSelected: contextualColors.primary,
    },
    label: {
      fontFamily: FONT_FAMILIES.Campton,
      fontWeight: 500,
      explanationFontSize: '12px',
      lineHeight: '30px',
      fontSizeDesktop: '16px',
      lineHeightDesktop: 1,
      marginBottom: '8px',
    },
  },
  confirmation: {
    header: {
      fontSize: '24px',
      fontSizeDesktop: '32px',
      textAlign: 'center',
      lineHeightDesktop: '38px',
      marginBottomDesktop: '40px',
      textTransform: 'none',
    },
    row: {
      padding: '0 15px 0 15px',
    },
  },
  questionBlock: {
    options: {
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0 0 0 auto',
      },
    },
    optionsItem: {
      width: {
        mobile: '120px',
        desktop: '120px',
      },
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0 10px 10px 0',
      },
    },
  },
  imageOption: {
    wrap: {
      flexDirection: 'column',
      padding: '15px 0',
    },
    image: {
      mobile: {
        height: '100px',
      },
      desktop: {
        height: '80px',
      },
    },
    text: {
      fontSize: {
        desktop: '13px',
      },
      marginTop: '10px',
      marginBottom: '5px',
    },
  },
  textOption: {
    wrap: {
      mobile: {
        padding: '15px',
        width: '120px',
      },
      desktop: {
        padding: '25px 15px',
      },
    },
    text: {
      fontFamily: FONT_FAMILIES.Campton,
      fontSize: {
        mobile: '13px',
        desktop: '13px',
      },
      fontWeight: 300,
      active: {
        fontWeight: 700,
      },
    },
  },
  photoUpload: {
    headline: {
      fontSize: '18px',
    },
    section: {
      padding: `40px 0px`,
      borderBottom: `1px solid ${theme('colors.slate')}`,
    },
    container: {
      padding: `20px 0px`,
    },
    checkbox: {
      text: {
        fontSize: '13px',
        fontWeight: 300,
      },
    },
  },
  button: {
    wideButtonWidth: '320px',
  },
  amend: {
    step1: {
      appointmentBox: {
        backgroundColor: '#f7efe9',
      },
      button: {
        backgroundColor: '#001c33',
        borderRadius: '5px',
      },
    },
    step2: {
      calendarHeading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        fontSize: { desktop: '19px', mobile: '19px' },
        fontWeight: '700',
      },
      meetingTypes: {
        heading: {
          fontFamily: FONT_FAMILIES.NewSpirit,
          fontSize: { desktop: '19px', mobile: '19px' },
          fontWeight: '700',
        },
      },
      thin: {
        fontWeight: '300',
      },
      stickyNav: {
        backgroundColor: '#ffffff',
        borderRadius: '5px',
      },
      button: {
        backgroundColor: '#001c33',
        borderRadius: '5px',
        padding: '12px 0px 0px 0px',
        marginLeft: '0px',
        border: '1px solid #001c33',
        color: '#001c33',
      },
      linkButton: {
        color: '#192228',
      },
      buttonContainer: {
        padding: '0px 20px',
      },
      whiteButton: {
        backgroundColor: '#ffffff',
        borderRadius: '5px',
      },
    },
    step3: {
      link: {
        color: '#5f7ed2',
        fontSize: '13px',
      },
    },
    appointmentInformation: {
      link: {
        color: '#5f7ed2',
      },
      infoItem: {
        fontSize: '15px',
      },
      wrapper: {
        backgroundColor: '#f7efe9',
        borderRadius: '5px',
        padding: '15px 20px 30px 20px',
      },
      heading: {
        fontWeight: '500',
      },
      iconContainer: {
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
      },
    },
  },
  cancel: {
    step1: {
      heading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        margin: '40px auto 40px auto',
        fontSize: {
          mobile: '30px',
          desktop: '36px',
        },
        lineHeight: {
          mobile: '38px',
          desktop: '44px',
        },
      },
      subHeading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        fontSize: '16px',
        fontWeight: '500',
      },
      usps: {
        fontSize: '16px',
        li: {
          before: {
            top: '7px',
            backgroundColor: theme('colors.magnetBlue'),
          },
        },
      },
      confirmText: {
        fontSize: '16px',
        fontWeight: '500',
      },
      box: {
        backgroundColor: '#f7efe9',
        fontSize: '20px',
      },
      button: {
        backgroundColor: '#001c33',
        fontSize: '16px',
        borderRadius: '5px',
      },
      whiteLink: {
        backgroundColor: '#ffffff',
        border: '1px solid #001c33',
        color: '#001c33',
      },
    },
    step2: {
      tooltip: {
        top: {
          mobile: '33px',
          desktop: '-15px',
        },
        left: {
          mobile: '-16px',
          desktop: '230px',
        },
      },
      link: {
        backgroundColor: '#001c33',
        borderRadius: '5px',
      },
      backLink: {
        color: '#5f7ed2',
        fontSize: '13px',
      },
      heading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        margin: '40px auto 20px auto',
        fontSize: {
          desktop: '36px',
          mobile: '30px',
        },
        lineHeight: {
          desktop: '44px',
          mobile: '38px',
        },
      },
      subHeading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        fontSize: '16px',
        fontWeight: '500',
      },
      box: {
        backgroundColor: '#f7efe9',
      },
    },
    step3: {
      heading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        margin: '60px auto 20px auto',
        fontSize: {
          mobile: '30px',
          desktop: '36px',
        },
        lineHeight: {
          mobile: '38px',
          desktop: '44px',
        },
      },
      link: {
        backgroundColor: '#001c33',
        borderRadius: '5px',
      },
    },
    step4: {
      heading: {
        fontFamily: FONT_FAMILIES.NewSpirit,
        margin: '60px auto 20px auto',
        fontSize: {
          mobile: '30px',
          desktop: '36px',
        },
        lineHeight: {
          mobile: '38px',
          desktop: '44px',
        },
      },
      link: {
        backgroundColor: '#001c33',
        borderRadius: '5px',
      },
    },
  },
  GlobalStyles,
}
