const transformAddressPart = (address) => {
  let transformedAddress = address
  if (transformedAddress) {
    transformedAddress = transformedAddress.replace(/<\/?[^>]+(>|$)/gi, ' ')
    const entityRegEx =
      /&(nbsp|Ouml|ouml|Aring|aring|Auml|auml|Oslash|oslash|AElig|aelig|amp);/g
    const map = {
      nbsp: ' ',
      Aring: 'Å',
      aring: 'å',
      auml: 'ä',
      Auml: 'Ä',
      Ouml: 'Ö',
      ouml: 'ö',
      Oslash: 'Ø',
      oslash: 'ø',
      AElig: 'Æ',
      aelig: 'æ',
      amp: '&',
    }
    return transformedAddress.replace(entityRegEx, (a, b) => map[b])
  }
  return ''
}

export const transformStoreAddress = (
  address,
  addressLine1,
  addressLine2,
  city
) => {
  // If any of the new separated fields have not been set, use the old address field
  if (!addressLine1 && !addressLine2 && !city) {
    return transformAddressPart(address)
  }
  // Use the new separated fields
  const addressParts = [
    transformAddressPart(addressLine1),
    transformAddressPart(addressLine2),
    transformAddressPart(city),
  ]
  const filteredParts = addressParts.filter((part) => part && part.trim())
  return filteredParts.join(', ')
}

const transformStore = (object) => ({
  id: object.store.id,
  name: object.store.storeName.trim(),
  storeName: object.store.storeName.trim(),
  managerId: object.store.storeManagerId,
  city: object.store.region,
  compareName: (object.store.region || '') + object.store.storeName.trim(),
  storeType: object.store.storeType,
  address: transformStoreAddress(
    object.store.address,
    object.store.addressLine1,
    object.store.addressLine2,
    object.store.city
  ),
  phoneNumber: object.store.phoneNumber,
  email: object.store.email,
  latitude: object.store.latitude,
  longitude: object.store.longitude,
  distanceInKilometers: Math.round(object.distanceInKilometers),
  acceptMeetings: object.store.externalMeetingData.acceptMeetings,
  acceptHomeVisits:
    object.store.externalMeetingData.meetingData.acceptHomeVisits === 'true',
  simplyBookMeId: parseInt(
    object.store.externalMeetingData.meetingData.simplyBookMeId,
    10
  ),
  hasSimplyBookMe:
    object.store.externalMeetingData.meetingData.hasSimplyBookMe === 'true',
  simplyBookMeSubsystem:
    object.store.externalMeetingData.meetingData.simplyBookMeSubsystem,
  services: object.store.services,
  hideInBada: object.store.hideInBada,
  bookingSystem: object.store.bookingSystem,
  msBookingsStoreUniqueId: object.store.msBookingsStoreUniqueId,
  isTemporarilyClosed: object.store.isTemporarilyClosed,
  temporarilyClosedInformation: object.store.temporarilyClosedInformation,
})

export default transformStore
