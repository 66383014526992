import { useIntl } from 'react-intl'

const useTranslation = (prefix) => {
  const intl = useIntl()
  return (key, variables, skipPrefix) => {
    const id = !skipPrefix && prefix ? `${prefix}.${key}` : key
    const hasKey = Object.prototype.hasOwnProperty.call(intl.messages, id)
    return hasKey ? intl.formatMessage({ id }, variables) : ''
  }
}

const getTranslator = (intl, prefix) => (key, variables) =>
  intl.formatMessage({ id: prefix ? `${prefix}.${key}` : `${key}` }, variables)

export default useTranslation
export { getTranslator }
