import styled from 'styled-components'
import { theme } from 'styled-tools'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

const Margin = styled.div`
  margin: 0px 14px;
`

const Wrapper = styled.div`
  background-color: ${(p) => p.bgColor};
  color: ${(p) => p.color};
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  margin: -${(p) => p.height / 2}px auto 0;
  max-width: 520px;
  ${(props) => props.theme.media.min.md`
    margin: -${(p) => p.height / 2}px auto 0;
    max-width: 690px;
  `}
  ${(props) => props.theme.media.min.lg`
    max-width: 940px;
  `}
  ${(props) => props.theme.media.min.xl`
    max-width: 1070px;
  `}
`
const Text = styled.div`
  text-align: left;
  margin: 0px auto;
  font-weight: 300;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 14px;
  line-height: 18px;

  strong {
    font-size: inherit;
  }

  ${(props) => props.theme.media.min.sm`
    line-height: 22px;
    font-size: 17px;
    max-width: 680px;
    text-align: center;
  `}
`

const PromoBanner = ({ text, color, bgColor }) => {
  const [height, setHeight] = useState(40)
  const wrapper = useRef(null)
  useEffect(() => {
    setHeight(wrapper?.current?.getBoundingClientRect().height || 40)
  }, [])
  return (
    <Margin>
      <Wrapper ref={wrapper} height={height} bgColor={bgColor} color={color}>
        <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Wrapper>
    </Margin>
  )
}

PromoBanner.defaultProps = {
  bgColor: '#222222',
  color: '#ffffff',
}

PromoBanner.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

export default PromoBanner
