import PropTypes from 'prop-types'
import React from 'react'
import { getDisplayData } from '../../containers/bada/content'
import Content from './Content'
import Header from './Header'
import ImagesText from './ImagesText'
import OtherNeeds from './OtherNeeds'
import PromoBanner from './PromoBanner'

const ContentHeader = ({ show, content, toggleDetails, detailsActive }) => {
  const displayData = getDisplayData(content)
  if (!displayData || !show) return null
  const {
    backgroundImageDesktop,
    backgroundImageMobile,
    pageHeading,
    preambleDesktop,
    preambleMobile,
    uspsDesktop,
    uspsMobile,
    imagesDesktop,
    imagesMobile,
    imagesText,
    otherNeeds,
    showPromotionBanner,
    promotionBannerText,
    promotionBannerColor,
    promotionBannerTextColor,
  } = displayData

  const headerContent = {
    pageHeading,
    preambleDesktop,
    preambleMobile,
    uspsDesktop,
    uspsMobile,
    imagesDesktop,
    imagesMobile,
  }

  const showPromo =
    showPromotionBanner && promotionBannerText && promotionBannerText.length > 0
  const showOtherNeeds = otherNeeds && otherNeeds.length > 0
  const compact = !backgroundImageMobile && !backgroundImageDesktop && !showPromo;
  return (
    <>
      <Header
        mobileBackground={backgroundImageMobile}
        desktopBackground={backgroundImageDesktop}
        showPromo={showPromo}
      >
        <Content {...headerContent} compact={compact} />
      </Header>
      {imagesText && (
        <ImagesText
          imagesText={imagesText}
          imagesMobile={imagesMobile}
          imagesDesktop={imagesDesktop}
        />
      )}
      {showPromo && (
        <PromoBanner
          text={promotionBannerText}
          color={promotionBannerTextColor}
          bgColor={promotionBannerColor}
        />
      )}
      {showOtherNeeds && (
        <OtherNeeds
          otherNeeds={otherNeeds[0]}
          toggleDetails={toggleDetails}
          detailsActive={detailsActive}
        />
      )}
    </>
  )
}

ContentHeader.defaultProps = {
  content: null,
  detailsActive: false,
  toggleDetails: null,
}

ContentHeader.propTypes = {
  content: PropTypes.shape({}),
  show: PropTypes.bool.isRequired,
  toggleDetails: PropTypes.func,
  detailsActive: PropTypes.bool,
}

export default ContentHeader
