import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slider'

const Wrapper = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 10px;
`

const StyledRangeInput = styled(ReactSlider)`
  width: 100%;
  height: 3px;
  appearance: none;
  background: #dbdbdb;
  border-radius: 9999px;
  cursor: pointer;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #192228; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
  }
  &::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #192228; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
  }
`

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
    bottom: 10px;
    top: -11px;
`;

const Thumb = (props) => <StyledThumb {...props} />

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
    this.onAfterChange = this.onAfterChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onAfterChange(value) {
    this.setState({value});
    let newValue = `${this.props.prefix} ${value}`;
    if (value === this.props.maxValue && this.props.endSuffix) {
      newValue = `${newValue}${this.props.endSuffix}`;
    }
    this.props.onAfterChange(newValue);
  }

  onChange(value) {
    this.setState({value});
    let newValue = `${this.props.prefix} ${value}`
    if (value === this.props.maxValue && this.props.endSuffix) {
      newValue = `${newValue}${this.props.endSuffix}`;
    }
    this.props.onChange(newValue);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      const defaultNumber = parseInt(nextProps.defaultValue.replace(/^\D+/g, ''), 10);
      if(isNaN(defaultNumber)) {
        this.setState({value: this.props.minValue});
      } else {
        this.setState({value: defaultNumber});
      }
    }
  }

  render() {
    return (
      <Wrapper>
        <StyledRangeInput
          renderThumb={Thumb} 
          step={this.props.amountInterval} 
          value={this.state.value} 
          min={this.props.minValue} 
          max={this.props.maxValue} 
          onChange={this.onChange}
          onAfterChange={this.onAfterChange} />
      </Wrapper>
    );
  }
}

Slider.defaultProps = {
  maxValue: 0,
  minValue: 0,
  amountInterval: 0,
  prefix: "",
  endSuffix: "",
  defaultValue: "",
  onChange: () => {},
  onAfterChange: () => {},
}

Slider.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  amountInterval: PropTypes.number,
  prefix: PropTypes.string,
  endSuffix: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
}

export default Slider