import styled from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const TypographyFactory = (element, type) =>
  styled(element)`
    font-family: ${prop(`theme.typography.${type}.fontFamily`)};
    font-weight: ${prop(`theme.typography.${type}.fontWeight`)};
    font-size: ${prop(`theme.typography.${type}.fontSize`)};
    font-style: ${prop(`theme.typography.${type}.fontStyle`)};
    line-height: ${prop(`theme.typography.${type}.lineHeight`)};
    margin-top: ${prop(`theme.typography.${type}.marginTop`)};
    margin-bottom: ${prop(`theme.typography.${type}.marginBottom`)};
    text-transform: ${prop(`theme.typography.${type}.textTransform`)};
    letter-spacing: ${prop(`theme.typography.${type}.letterSpacing`)};

    color: ${prop(`theme.typography.${type}.color`, 'inherit')};
    text-align: ${prop('align', 'inherit')};

    ${ifProp('noMargins', `margin: 0;`)};

    ${(props) => props.theme.media.min.sm`
    font-size: ${prop(`theme.typography.${type}.fontSizeDesktop`)};
    line-height: ${prop(`theme.typography.${type}.lineHeightDesktop`)};
    text-shadow: ${
      type === 'imagePageTitle'
        ? prop(`theme.typography.${type}.textShadow`)
        : `none`
    };
  `};
  `

export default TypographyFactory
