import React from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  SectionItem,
  SectionItemHeading,
  SectionItemText,
} from './sharedUI'
import { checkEmpty } from './utils'
import useTranslation from '../../../utils/translation'

const returnEmptyString = true

const CustomerInfo = ({
  customerName,
  customerAddress,
  customerAddress2,
  customerAddress3,
  customerCity,
  customerPostCode,
  customerPhone,
  customerEmail,
}) => {
  const t = useTranslation('appointment-details')
  return (
    <Section>
      {customerName && (
        <SectionItem>
          <SectionItemHeading>{t('name')}</SectionItemHeading>
          <SectionItemText>{customerName}</SectionItemText>
        </SectionItem>
      )}
      {(customerAddress || customerAddress2 || customerAddress3) && (
        <SectionItem>
          <SectionItemHeading>{t('address')}</SectionItemHeading>
          <SectionItemText>
            {customerAddress && (
              <>
                {customerAddress}
                <br />
              </>
            )}
            {customerAddress2 && (
              <>
                {customerAddress2}
                <br />
              </>
            )}
            {customerAddress3 && (
              <>
                {customerAddress3}
                <br />
              </>
            )}
            {checkEmpty(customerPostCode, returnEmptyString)}{' '}
            {checkEmpty(customerCity, returnEmptyString)}
          </SectionItemText>
        </SectionItem>
      )}
      {customerPhone && (
        <SectionItem>
          <SectionItemHeading>{t('phone')}</SectionItemHeading>
          <SectionItemText>{customerPhone}</SectionItemText>
        </SectionItem>
      )}
      {customerEmail && (
        <SectionItem>
          <SectionItemHeading>{t('email')}</SectionItemHeading>
          <SectionItemText>{customerEmail}</SectionItemText>
        </SectionItem>
      )}
    </Section>
  )
}

CustomerInfo.propTypes = {
  customerName: PropTypes.string,
  customerAddress: PropTypes.string,
  customerAddress2: PropTypes.string,
  customerAddress3: PropTypes.string,
  customerCity: PropTypes.string,
  customerPostCode: PropTypes.string,
  customerPhone: PropTypes.string,
  customerEmail: PropTypes.string,
}

CustomerInfo.defaultProps = {
  customerName: null,
  customerAddress: null,
  customerAddress2: null,
  customerAddress3: null,
  customerCity: null,
  customerPostCode: null,
  customerPhone: null,
  customerEmail: null,
}

export default CustomerInfo
