import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

const Headline = styled.h2`
  font-family: ${prop('theme.typography.headline.fontFamily')};
  font-size: ${prop('theme.typography.headline.fontSize')};
  font-weight: ${prop('theme.typography.headline.fontWeight')};
  line-height: ${prop('theme.typography.headline.lineHeight')};
  margin-bottom: ${prop('theme.typography.headline.marginBottom')};
  text-transform: ${prop('theme.typography.headline.textTransform')};
  letter-spacing: ${prop('theme.typography.headline.letterSpacing')};

  color: ${prop('theme.colors.primary')};

  text-align: ${prop('align')};

  ${(props) =>
    props.noMargins &&
    css`
      margin: 0;
    `};

  ${(props) => props.theme.media.min.sm`
      font-size:
        ${prop('theme.typography.headline.fontSizeLarge')};
      line-height: 
        ${prop('theme.typography.headline.lineHeightLarge')};
    `};
`

export default Headline
