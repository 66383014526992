import React from 'react'

const Sigdal = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={47}
    width={100}
    viewBox="0 15 1000 430"
  >
    <g>
      <path
        fill="#31261D"
        d="M638.7,100.4c-13.4-18.6-33.2-29-56.4-29c-48.6,0-78.9,36.9-78.9,96.2v88.3c0,59.3,30.2,96.2,78.9,96.2
		c23.1,0,42.9-10.4,56.4-29l5.4,22h43.7V0h-49.1V100.4z M638.7,176.9v69.8c0,35.5-17.2,57.5-44.8,57.5c-27.8,0-41.3-18.8-41.3-57.5
		v-69.8c0-38.7,13.5-57.5,41.3-57.5C621.6,119.4,638.7,141.4,638.7,176.9z"
      />
      <path
        fill="#31261D"
        d="M859.5,100.4c-13.4-18.6-33.2-29-56.4-29c-48.6,0-78.9,36.9-78.9,96.2v88.3c0,59.3,30.2,96.2,78.9,96.2
		c23.1,0,42.9-10.4,56.4-29l5.4,22h43.7V78.4h-43.7L859.5,100.4z M859.5,176.9v69.8c0,35.5-17.2,57.5-44.8,57.5
		c-27.8,0-41.3-18.8-41.3-57.5v-69.8c0-38.7,13.5-57.5,41.3-57.5C842.3,119.4,859.5,141.4,859.5,176.9z"
      />
      <rect x="950.4" y="0" fill="#31261D" width="49.6" height="345.2" />
      <path
        fill="#31261D"
        d="M418,100.4c-13.4-18.6-33.2-29-56.4-29c-48.6,0-78.9,36.9-78.9,96.2v88.3c0,59.3,30.2,96.2,78.9,96.2
		c23.1,0,42.9-10.4,56.4-29V353c0,17.8-8.2,32.4-30.9,32.9h-86.3V430h84.8c58.9,0,81.5-36.6,81.5-81.5V78.4h-43.7L418,100.4z
		 M418,176.9v69.8c0,35.5-17.2,57.5-44.8,57.5c-27.8,0-41.3-18.8-41.3-57.5v-69.8c0-38.7,13.5-57.5,41.3-57.5
		C400.8,119.4,418,141.4,418,176.9z"
      />
      <g>
        <rect x="196.4" y="78.4" fill="#31261D" width="49.6" height="266.8" />
        <rect x="194.8" y="0" fill="#31261D" width="52.7" height="52.2" />
      </g>
      <path
        fill="#31261D"
        d="M0,275.8c0,41.2,26.8,76.3,81.1,76.3c58.9,0,81.1-36.2,81.1-70.8v-6.6c0-26.8-13.4-49.5-38.4-65.8
		c-18.8-12.3-34.6-22.7-49.6-32.7c-9.2-6.2-22.3-16.6-22.3-33.1c0-21.2,9-31.3,29.2-31.3c21.2,0,30.8,12.9,30.8,34.1v22.7h47.8V146
		c0-43.3-30.3-74.5-78.7-74.5c-49.6,0-77,29.7-77,67.7v6.7c0,30.7,17.1,50.3,35.9,63.1c15.2,10.3,33.3,21.8,50.7,33.3
		c13.8,9.1,23.7,20.2,23.7,36.5c0,22.5-11.6,33-33.2,33c-21.3,0-33.2-13.3-33.2-35.8v-26.6H0V275.8z"
      />
    </g>
  </svg>
)

export default Sigdal
