import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import CheckmarkCleanIcon from '../icons/checkmark-clean'

const Desktop = styled.div`
  display: none;
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${theme('typography.usp.desktop.fontSize')};
  font-weight: ${theme('typography.usp.desktop.fontWeight')};
  line-height: ${theme('contentHeader.usps.lineHeight')};
  text-align: ${theme('contentHeader.usps.textAlign')};
  margin-bottom: ${theme('contentHeader.usps.marginTop')};
  margin-top: ${theme('contentHeader.usps.marginTop')};
  margin-right: ${theme('contentHeader.usps.marginRight')};
  margin-left: ${theme('contentHeader.usps.marginLeft')};

  ${(props) => props.theme.media.min.sm`
    display: flex;    
  `}
`

const Mobile = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  font-size: ${theme('typography.usp.mobile.fontSize')};
  font-weight: ${theme('typography.usp.mobile.fontWeight')};
  line-height: ${theme('contentHeader.usps.lineHeight')};
  text-align: ${theme('contentHeader.usps.textAlign')};
  max-width: ${theme('contentHeader.usps.mobile.maxWidth')};
  margin-top: ${theme('contentHeader.usps.marginTop')};
  margin-right: ${theme('contentHeader.usps.marginRight')};
  margin-left: ${theme('contentHeader.usps.marginLeft')};
  ${(props) => props.theme.media.min.sm`
    display: none;
  `}
`

const Usp = styled.div`
  margin-bottom: ${theme('contentHeader.usps.mobile.marginBottom')};
  ${(props) => props.theme.media.min.sm`
    margin-bottom: 10px;
  `}
  display:flex;
  justify-content: ${theme('contentHeader.usps.justifyContent')};
`

const UspText = styled.span`
  padding-left: ${theme('contentHeader.usps.paddingLeft')};
  font-family: ${theme('typography.paragraph.fontFamily')};
  position: relative;
  top: 2px;
  color: ${theme('contentHeader.usps.color')};
  font-weight: ${theme('contentHeader.usps.fontWeight')};
  font-size: ${theme('contentHeader.usps.mobile.fontSize')};
  line-height: ${theme('contentHeader.usps.mobile.lineHeight')};
  width: ${theme('contentHeader.usps.mobile.width')};
  ${(props) => props.theme.media.min.sm`
    width: ${theme('contentHeader.usps.desktop.width')};;
    font-size: ${theme('contentHeader.usps.desktop.fontSize')};
    line-height: ${theme('contentHeader.usps.desktop.lineHeight')};
  `};
`

const IconContainer = styled.div`
  margin-bottom: ${theme('contentHeader.icons.marginBottom')};

  svg {
    color: ${theme('contentHeader.icons.color')};
    width: ${theme('contentHeader.icons.mobile.width')};
    height: ${theme('contentHeader.icons.mobile.height')};
  }
  ${(props) => props.theme.media.min.sm`
    svg {
      color: ${theme('contentHeader.icons.color')};
      width: ${theme('contentHeader.icons.desktop.width')};
      height: ${theme('contentHeader.icons.desktop.height')};
    }
  `}
`

const getUspText = (text) => {
  const regExp = /{.+}/
  if (regExp.test(text)) {
    return text.replace(regExp, '')
  }
  return text
}

const USP = ({ uspsDesktop, uspsMobile }) => (
  <>
    {uspsDesktop && uspsDesktop.length > 0 && (
      <Desktop>
        {uspsDesktop.map((block, index) => (
          <Usp key={`usp-${index.toString()}`}>
            <IconContainer>
              <CheckmarkCleanIcon />
            </IconContainer>
            <UspText>{getUspText(block.text)}</UspText>
          </Usp>
        ))}
      </Desktop>
    )}
    {uspsMobile && uspsMobile.length > 0 && (
      <Mobile>
        {uspsMobile.map((block, index) => (
          <Usp key={`usp-${index.toString()}`}>
            <IconContainer>
              <CheckmarkCleanIcon />
            </IconContainer>
            <UspText>{getUspText(block.text)}</UspText>
          </Usp>
        ))}
      </Mobile>
    )}
  </>
)

USP.defaultProps = {
  uspsDesktop: undefined,
  uspsMobile: undefined,
}

USP.propTypes = {
  uspsDesktop: PropTypes.arrayOf(PropTypes.shape({})),
  uspsMobile: PropTypes.arrayOf(PropTypes.shape({})),
}

export default USP
