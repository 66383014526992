import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools'
import { mediaMin } from '../../../styles/media'
import createGridComponent from './createGridComponent'
import Row from './Row'
import Column from './Column'

const filteredKeys = ['fluid', 'noGutters']
const GridComponent = createGridComponent('Container', filteredKeys)

const Container = styled(GridComponent)`
  width: 100%;
  max-width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${prop('theme.grid.gutter')};
  padding-right: ${prop('theme.grid.gutter')};

  ${ifProp(
    'debug',
    css`
      & > ${Row} {
        & + ${Row} {
          margin-top: ${prop('theme.grid.gutter')};
        }

        & > ${Column} {
          padding-top: ${prop('theme.grid.gutter')};
          padding-bottom: ${prop('theme.grid.gutter')};
          border: 1px solid rgba(86, 61, 124, 0.2);
          background-color: rgba(86, 61, 124, 0.15);
        }
      }
    `
  )};

  ${ifProp(
    'noGutters',
    css`
      padding-left: 0;
      padding-right: 0;

      & > ${Row} {
        margin-left: 0;
        margin-right: 0;

        & > ${Column} {
          padding-left: 0;
          padding-right: 0;
        }
      }
    `
  )};

  ${(props) => {
    if (props.fluid) {
      return undefined
    }

    if (props.width != null) {
      return css`
        width: ${props.width};
      `
    }

    const { container } = props.theme.grid

    if (typeof container === 'string') {
      return css`
        width: ${container};
      `
    }

    const { keys } = props.theme.media
    const minKey = keys[0]

    return keys.map((bp) => {
      if (container[bp] != null) {
        const initial = bp === minKey
        const style = css`
          width: ${container[bp]};
        `

        return initial ? style : mediaMin(bp, style)
      }

      return undefined
    })
  }};
`

Container.displayName = 'Container'

export default Container
