import React from 'react'
import PropTypes from 'prop-types'
import Slider from './Slider'

const SliderSection = ({
  maxValue,
  minValue,
  amountInterval,
  prefix,
  endSuffix,
  defaultValue,
  onAfterChange,
  onChange
}) => <Slider maxValue={maxValue} minValue={minValue} amountInterval={amountInterval} prefix={prefix} endSuffix={endSuffix} defaultValue={defaultValue} onAfterChange={onAfterChange} onChange={onChange}/>


SliderSection.propTypes = {
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  amountInterval: PropTypes.number,
  prefix: PropTypes.string,
  endSuffix: PropTypes.string,
  defaultValue: PropTypes.string,
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
}

SliderSection.defaultProps = {
  maxValue: 0,
  minValue: 0,
  amountInterval: 0,
  prefix: "",
  endSuffix: "",
  defaultValue: "",
  onAfterChange: () => {},
  onChange: () => {},
}

export default SliderSection