// eslint-disable-next-line no-unused-vars
import { ApolloClient } from '@apollo/client'

import RetrieveAddressQuery from '../graphql/RetrieveAddressQuery.graphql'
import SearchAddressQuery from '../graphql/SearchAddressQuery.graphql'

const POSTCODE_ITEM_TYPE = 'Postcode'
const ADDRESS_ITEM_TYPE = 'Address'

/**
 * Retrieves additional address data for a particular address.
 * @param {ApolloClient<any>} apolloClient
 * The GraphQL client carrying out the request.
 * @param {string} refinementId ID of an address type search response.
 * @returns {Promise<{
 *  id: string
 *  city: string
 *  line1: string
 *  line2: string
 *  line3: string
 *  postalCode: string
 *  countryCode: string
 *  countryName: string
 *  label: string
 * }>}
 */
const retrieveAddress = async (apolloClient, refinementId) => {
  const response = await apolloClient.query({
    query: RetrieveAddressQuery,
    variables: { id: refinementId },
  })

  if (!response || !response.data || !response.data.retrieveAddress) {
    throw new Error(`No address could be retrieved for ID '${refinementId}'!`)
  }

  return response.data.retrieveAddress
}

/**
 * Searches for address data matching given query.
 * @param {ApolloClient<any>} apolloClient
 * The GraphQL client carrying out the request.
 * @param {string} query Address search query.
 * @param {string?} refinementId ID of a non-address type search response.
 * @param {number} limit Max number of search results.
 * @param {string[]} countries Lists of countries to limit the search within.
 * Defaults to the brand's configured country.
 * @returns {Promise<{
 *  id: string,
 *  type: string,
 *  text: string,
 *  highlight: string,
 *  description: string
 * }[]>}
 */
const searchAddresses = (apolloClient, query, refinementId, limit, countries) =>
  apolloClient
    .query({
      query: SearchAddressQuery,
      variables: {
        input: {
          searchText: query,
          refinementId,
          countries,
          limit,
        },
      },
    })
    .then((response) => response.data.searchAddress.addresses)

export {
  retrieveAddress,
  searchAddresses,
  POSTCODE_ITEM_TYPE,
  ADDRESS_ITEM_TYPE,
}
