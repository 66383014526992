import { createGlobalStyle } from 'styled-components'
import { switchProp, ifProp } from 'styled-tools'
import { noremaFonts } from '../fonts'
import { basePixelSize } from './baseTheme'

const gutterWidth = '15px'

const GlobalStyles = createGlobalStyle`
  ${noremaFonts}
  :root {
    font: 200 ${basePixelSize}px/1.43 "Inter", sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif;
  }
`

const colors = {
  black: '#000000',
  turquoise: '#00B2A9',
  blue: '#002B5E',
  red: '#D81F43',
  green: '#00b2a9',
  white: '#FFFFFF',
  grey: '#EAE9EC',
  greyBlue: '#2F5562',
}

const contextualColors = {
  primary: colors.black,
  secondary: colors.black,
  link: colors.green,
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.grey,
  lightboxBackground: colors.white,
  overlayText: colors.greyBlue,
  overlayButton: colors.greyBlue,
}

const baseTypography = {
  fontFamily: '"Inter", sans-serif',
  inputFontFamily: '"Inter", sans-serif',
}

const typography = {
  imagePageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 900,
    fontSize: ifProp('small', '16px', '22px'),
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '50px', '63px'),
    lineHeightDesktop: ifProp('small', 1, 1.12),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'uppercase',
    textShadow: '0 0 30px rgba(0, 0, 0, 0.43)',
    letterSpacing: '2px',
  },
  pageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 900,
    fontSize: ifProp('small', '18px', '24px'),
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '24px', '32px'),
    lineHeightDesktop: ifProp('small', 1.125, 1.125),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: colors.black,
  },
  headline: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 900,
    fontSize: ifProp('small', '16px', '18px'),
    lineHeight: ifProp('small', 1.3, 1.375),
    fontSizeDesktop: ifProp('small', '15px', '19px'),
    lineHeightDesktop: ifProp('small', 1.33, 1.21),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: colors.turquoise,
  },

  subHeadline: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.375,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    color: colors.turquoise,
  },
  title: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.428,
    fontSizeDesktop: '16px',
    lineHeightDesktop: 1.428,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  preamble: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 200,
    fontSize: '16px',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.44,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 200,
    fontSize: switchProp('size', {
      small: '14px',
      large: '18px',
      default: '16px',
    }),
    lineHeight: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    fontSizeDesktop: switchProp('size', {
      small: '14px',
      large: '18px',
      default: '16px',
    }),
    lineHeightDesktop: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  link: {
    color: contextualColors.link,
    textDecoration: 'underline',
  },
}

const tooltip = {
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.turquoise,
  },
}

const button = {
  borderRadius: 0,
  fontFamily: baseTypography.fontFamily,
  fontSize: '14px',
  fontWeight: '400',
  textTransform: 'uppercase',
  letterSpacing: '1.2px',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.greyBlue,
    secondaryColor: colors.white,
    secondaryBackground: colors.blue,
    successColor: colors.white,
    successBackground: colors.green,
  },
  margin: '0',
  siblingMargin: gutterWidth,
}

const forms = {
  colors: {
    selected: colors.greyBlue,
  },
}

const theme = {
  basePixelSize,
  GlobalStyles,
  typography,
  tooltip,
  button,
  forms,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
