import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { LightboxOverlay, OverlayCloseButton } from '../overlay'
import { Container } from '../grid'
import useTranslation from '../../../utils/translation'
import BaseButton from '../button/baseButton'

const OverlayContent = styled(Container)`
  background: ${theme('colors.termsOverlayBackground')};
  padding: 42px 56px;
  max-height: calc(100vh - 40px);
  box-sizing: border-box;
  overflow-y: scroll;
  scrolling-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('typography.paragraph.fontSize')};
  font-weight: ${theme('typography.paragraph.fontWeightLead')};
  line-height: ${theme('typography.paragraph.lineHeightLead')};

  a {
    color: ${theme('colors.link')};
  }

  h2 {
    font-family: ${theme('typography.headline.fontFamily')};
    font-size: ${theme('typography.headline.fontSize')};
    font-weight: ${theme('typography.headline.fontWeight')};
    line-height: ${theme('typography.headline.lineHeight')};
    margin-bottom: ${theme('typography.headline.marginBottom')};
    text-transform: ${theme('typography.headline.textTransform')};
    letter-spacing: ${theme('typography.headline.letterSpacing')};
    color: ${theme('colors.primary')};

    ${(props) => props.theme.media.min.sm`
        font-size:
          ${theme('typography.headline.fontSizeLarge')};
        line-height: 
          ${theme('typography.headline.lineHeightLarge')};
      `};
  }
`

const StyledButton = styled(OverlayCloseButton)`
  position: absolute;
  top: 20px;
  color: #333;
  right: 20px;
`

const Button = styled(BaseButton)`
  font-size: ${theme('typography.button.fontSize')};
`

const TermsOverlay = ({ setClosed, pageContent }) => {
  const t = useTranslation('app.form.conditions')
  return (
    <LightboxOverlay setClosed={setClosed}>
      <OverlayContent>
        <div
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: pageContent.termsAndConditionsSummary,
          }}
        />
        <br />
        <br />
        <Button onClick={setClosed} kind="primary" size="large">
          {t('close')}
        </Button>
        <StyledButton onClick={setClosed} />
      </OverlayContent>
    </LightboxOverlay>
  )
}

TermsOverlay.propTypes = {
  setClosed: PropTypes.func.isRequired,
  pageContent: PropTypes.shape().isRequired,
}

export default TermsOverlay
