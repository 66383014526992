import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { MeetingTypeList } from '../../components/meeting-types/MeetingTypes'
import useTranslation from '../../../utils/translation'
import MeetingTypeItem from '../../components/meeting-type-selection/MeetingTypeItem'
import { fetchServices } from '../../containers/bada/actions'

const Heading = styled.h2`
  font-family: ${theme('amend.step2.meetingTypes.heading.fontFamily')};
  font-size: ${theme('amend.step2.meetingTypes.heading.fontSize.mobile')};
  font-weight: ${theme('amend.step2.meetingTypes.heading.fontWeight')};

  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('amend.step2.meetingTypes.heading.fontSize.desktop')};
  `}
`
/* eslint-disable no-unused-vars */
const MeetingTypes = ({
  meetingTypes,
  services,
  selectedMeetingType,
  selectedStore,
  onChange,
}) => {
  const t = useTranslation('amend.step2.meetingType')
  return (
    <>
      <Heading>{t('heading')}</Heading>
      <MeetingTypeList>
        {meetingTypes &&
          meetingTypes.map(
            ({ id, icon, serviceCode, heading, descriptionMobile }) => (
              <MeetingTypeItem
                key={`${id}-${serviceCode}`}
                name="MeetingType"
                value={id.toString()}
                checked={selectedMeetingType.id === id}
                onChange={() => onChange(id)}
                props={{
                  id: serviceCode,
                  name: heading,
                  text: descriptionMobile,
                  icon,
                  checked: selectedMeetingType.id === id,
                  serviceId: id,
                  serviceCode,
                  serviceDuration: services?.services?.find((s) => s.id === id)
                    ?.duration,
                }}
              />
            )
          )}
      </MeetingTypeList>
    </>
  )
}

const enhance = compose(fetchServices)

export default enhance(MeetingTypes)
