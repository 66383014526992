import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'

const PRIMARY_BG_COLOR = '#F2E9DB'
const SECONDARY_BG_COLOR = '#E4D5C4'

const FONT_FAMILIES = {
  Lausanne: `Lausanne, sans-serif`,
  Reckless: `Reckless, serif`,
}

const colors = {
  primary: '#FBF9F6',
  selectedTabUnderline: '#1E44CB',
  bodyTextBlack: '#31261D',
  border: '#D3CBBD',
  selectableOptionBackground: '#F8F2EC',
  selectedOption: '#FBF9F6',
  selectedOptionBorder: '#31261D',
  buttonPrimary: '#31261D',
}

const GlobalStyles = createGlobalStyle`
        html {
            overflow: -moz-scrollbars-vertical; 
            overflow-y: scroll;
        }
        body {
            font-family: ${FONT_FAMILIES.Lausanne};
            background-color: ${PRIMARY_BG_COLOR};
            color: ${colors.bodyTextBlack};
            padding: 0;
            margin: 0;
        }
        span {
          font-family: ${FONT_FAMILIES.Lausanne};
        }        
    `

export default {
  global: {
    fontSmoothing: 'antialiased',
    boxSizing: 'border-box',
    letterSpacing: '0',
  },
  colors: {
    link: colors.bodyTextBlack,
    inputBorder: colors.border,
    accent: colors.selectedOptionBorder,
    accentShadow: '0 0 0 5px RGBA(228, 213, 196, 0.5)',
    lightGrey: colors.border,
    darkGrey: colors.bodyTextBlack,
    buttonBackgroundColor: colors.selectableOptionBackground,
    selectedOption: colors.selectedOption,
    selectedOptionBorder: colors.selectedOptionBorder,
    termsOverlayBackground: PRIMARY_BG_COLOR,
    secondaryBackgroundColor: SECONDARY_BG_COLOR,
    activeBorder: colors.bodyTextBlack,
  },
  typography: {
    pageTitle: {
      fontWeight: 400,
      textTransform: 'none',
      fontSize: '38px',
      lineHeight: '40px',
      fontSizeLarge: '66px',
      lineHeightLarge: '71px',
      fontSizeDesktop: '52px',
      fontFamily: FONT_FAMILIES.Reckless,
      letterSpacing: '-0.25px',
    },
    headline: {
      fontWeight: 500,
      textTransform: 'none',
      fontSize: '28px',
      fontSizeDesktop: '46px',
      fontSizeLarge: '46px',
      fontFamily: FONT_FAMILIES.Reckless,
    },
    subHeadline: {
      fontWeight: 650,
      textTransform: 'none',
      fontFamily: FONT_FAMILIES.Lausanne,
    },
    title: {
      textTransform: 'none',
      fontFamily: FONT_FAMILIES.Reckless,
    },
    link: {
      color: colors.bodyTextBlack,
    },
    paragraph: {
      fontFamily: FONT_FAMILIES.Lausanne,
      fontWeight: 400,
      fontWeightLarge: 400,
      lineHeight: '24px',
      fontSize: '16px',
      fontSizeLarge: '18px',
      fontSizeLead: '18px',
      fontWeightNormal: '250',
      fontWeightLead: '250',
      lineHeightLead: '26px',
      fontSizeHeader: '36px',
      fontWeightHeader: '400',
    },
    storeAddressLong: {
      color: '#999999',
      fontSize: '16px',
      padding: '20px',
      paddingDesktop: '12px',
    },
    storeAddress: {
      color: '#999999',
      fontSize: '16px',
      padding: '20px',
    },
    storeName: {
      fontWeight: 650,
      fontSize: '16px',
      lineHeight: '26px',
      fontFamily: FONT_FAMILIES.Lausanne,
      mobile: {
        fontSize: '16px',
      },
    },
    usp: {
      desktop: {
        fontSize: '21px',
        fontWeight: '250',
      },
      mobile: {
        fontSize: '18px',
        fontWeight: '250',
      },
    },
    amend: {
      headline: {
        fontFamily: FONT_FAMILIES.Reckless,
      },
    },
  },
  confirmation: {
    button: {
      fontSize: '16px',
    },
    header: {
      textAlign: 'left',
      fontSizeDesktop: '66px',
    },
  },
  postalCodeOverlay: {
    backgroundColor: PRIMARY_BG_COLOR,
    text: {
      fontSize: '18px',
      fontWeight: '250',
      paddingTop: '0',
    },
  },
  button: {
    fontSize: '16px',
    fontWeight: '350',
    fontFamily: FONT_FAMILIES.Lausanne,
    blockButtonWidth: 'auto',
    backgroundColor: colors.buttonPrimary,
    borderRadius: '2px',
    border: 0,
    textTransform: 'none',
    lineHeight: '22px',
    letterSpacing: '0',
    padding: '13px 24px',
    height: '50px',
    colors: {
      primaryBackground: colors.buttonPrimary,
    },
  },
  header: {
    backgroundColor: PRIMARY_BG_COLOR,
    border: '1px solid #c3b5a7',
    height: '74px',
    heightDesktop: '74px',
    heightDesktopLarge: '82px',
    logoMargin: '0 0 0',
    color: colors.bodyTextBlack,
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '0',
  },
  contentHeader: {
    height: {
      desktop: '582px',
      small: '290px',
      mobile: '250px',
    },
    otherNeeds: {
      desktop: {
        positionTop: '-79px',
        marginBottom: '-79px',
      },
      mobile: {
        positionTop: '-40px',
        marginBottom: '-40px',
      },
      strong: {
        fontFamily: FONT_FAMILIES.Reckless,
        fontSize: '26px',
        fontSizeDesktop: '32px',
        fontWeight: '250',
        lineHeight: {
          mobile: '30px',
          desktop: '38px',
        },
      },
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      textDecoration: 'none',
      fontWeight: '250',
      lineHeight: '24px',
      fontSize: {
        mobile: '16px',
        desktop: '16px',
      },
      linkColor: colors.bodyTextBlack,
      popDownBgColor: SECONDARY_BG_COLOR,
      padding: {
        mobile: '8px',
        desktop: '28px 28px 28px 28px',
      },
    },
    heading: {
      color: '#FBF9F6',
      desktop: {
        fontSize: '66px',
        lineHeight: '71px',
        heading: '60px',
        letterSpacing: '0',
        fontWeight: '500',
      },
      mobile: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
    desktop: {
      alignItems: 'center',
    },
    mobile: {
      alignItems: 'center',
    },
    usps: {
      mobile: {
        fontSize: '20px',
      },
    },
  },
  stores: {
    headers: {
      fontSize: '36px',
      fontWeight: '500',
      lineHeight: '40px',
    },
    distance: {
      fontSize: '16px',
      fontWeight: '250',
    },
    changeStoreLink: {
      fontSize: '18px',
      fontWeight: '250',
      color: colors.bodyTextBlack,
      textDecoration: 'underline',
    },
  },
  tabs: {
    color: {
      inactive: theme('colors.darkGrey'),
      bottomBorder: colors.border,
      activeBorder: colors.selectedTabUnderline,
      default: colors.bodyTextBlack,
    },
    fontSize: '16px',
    fontWeight: {
      active: '650',
      inactive: '250',
    },
    header: {
      link: {
        color: '#31261D',
        textDecoration: 'underline',
        fontSize: 'inherit',
        fontWeight: '250',
      },
    },
  },
  calendar: {
    time: {
      fontWeight: '250',
    },
    heading: {
      fontSize: '14px',
    },
    border: {
      color: colors.border,
    },
  },
  forms: {
    position: {
      top: '21px',
    },
    height: '45px',
    fontSize: '14px',
    label: {
      fontSize: '16px',
      fontWeight: '350',
      lineHeight: '28px',
      link: {
        color: colors.bodyTextBlack,
        paddingBottom: '1px',
        borderBottom: '1px solid',
        borderBottomColor: colors.bodyTextBlack,
      },
    },
    customerForm: {
      fontFamily: FONT_FAMILIES.Lausanne,
      fontSize: '18px',
      fontWeight: '250',
      color: colors.bodyTextBlack,
    },
    colors: {
      checkboxBorder: colors.border,
      background: '#fff',
      border: colors.border,
      activeBorder: 'transparent',
      invalidBorder: 'transparent',
      invalid: '#d2282d',
      label: '#666',
      prefilledBackground: 'transparent',
      valid: '#9be587',
      placeholder: '#666',
      selected: '#fff',
      svgIconColor: `${colors.bodyTextBlack} !important`,
      radioButtonSelected: '#31261D',
    },
    border: '1',
    borderRadius: '3px',
    prepForm: {
      headline: {
        color: colors.bodyTextBlack,
        desktop: {
          fontSize: '66px',
          marginTop: '60px',
          lineHeight: '60px',
          fontWeight: '350',
        },
        mobile: {
          fontSize: {
            normal: '22px',
            embedded: '28px',
          },
          marginTop: {
            normal: '60px',
            embedded: '25px',
          },
        },
        textAlign: { normal: 'left', embedded: 'left' },
        textTransform: { normal: 'none', embedded: 'none' },
        letterSpacing: { normal: '0', embedded: '0' },
        lineHeight: { normal: '', embedded: '32px' },
      },
      paragraph: {
        textAlign: 'left',
        mobile: {
          fontSize: {
            normal: '15px',
            embedded: '14px',
          },
        },
        desktop: {
          fontSize: {
            normal: '18px',
            embedded: '14px',
          },
        },
        color: colors.bodyTextBlack,
        lineHeight: '20px',
        margin: {
          mobile: '0',
          desktop: '0',
          normal: '0',
          embedded: '0',
        },
        marginBottom: '60px',
      },
    },
  },
  bookingSummary: {
    backgroundColor: SECONDARY_BG_COLOR,
    borderWidth: 0,
    borderStyle: 'none',
    borderColor: '#borderWidth',
    borderRadius: '2px',
    itemTitle: {
      color: colors.bodyTextBlack,
      fontSize: '16px',
      fontWeight: '650',
    },
    itemText: {
      fontSize: '16px',
      fontWeight: '250',
      lineHeight: '24px',
    },
  },
  contactDetails: {
    dt: {
      fontSize: '16px',
    },
  },
  textOption: {
    backgroundColor: colors.selectableOptionBackground,
    activeBackgroundColor: colors.selectedOption,
    text: {
      fontSize: {
        mobile: '14px',
        desktop: '16px',
      },
    },
    wrap: {
      mobile: {
        padding: '15px',
        width: '140px',
      },
    },
  },
  imageOption: {
    backgroundColor: colors.selectableOptionBackground,
    activeBackgroundColor: colors.selectedOption,
    justifyContent: 'flex-end',
    wrap: {
      desktop: {
        width: '140px',
      },
    },
  },
  meetingType: {
    iconsize: {
      default: '30px',
      video: '40px',
      videoMeeting: '35px',
    },
    name: {
      fontSize: '16px',
      lineHeight: '18px',
      maxWidth: '100px',
      fontFamily: FONT_FAMILIES.Lausanne,
      fontWeight: 650,
      color: colors.bodyTextBlack,
    },
    text: {
      fontSize: '14px',
      lineHeight: '19px',
      fontFamily: FONT_FAMILIES.Lausanne,
      fontWeight: 250,
    },
    home: {
      marginTop: '10px',
    },
  },
  radioButtonRich: {
    borderRadius: '2px',
  },
  designer: {
    headline: {
      fontFamily: FONT_FAMILIES.Lausanne,
      fontWeight: '650',
      fontSize: '20px',
      textTransform: {
        normal: 'none',
        embedded: 'none',
      },
      letterSpacing: {
        normal: '0',
        embeded: '0',
      },
      marginTop: '34px',
    },
    paragraph: {
      fontWeight: '350',
      fontSize: {
        mobile: { normal: '16px', embedded: '14px' },
        desktop: { normal: '16px', embedded: '14px' },
      },
      lineHeight: '24px',
      textTransform: {
        normal: 'none',
        embedded: 'none',
      },
    },
    background: {
      color: SECONDARY_BG_COLOR,
    },
    name: {
      fontWeight: '650',
      lineHeight: '22px',
      fontSize: '14px',
    },
    location: {
      fontWeight: '250',
      lineHeight: '22px',
      fontSize: '14px',
    },
    margin: {
      mobile: '28px 0',
    },
  },
  quickQuestions: {
    heading: {
      style1: {
        color: '',
        fontWeight: '650',
        letterSpacing: '0',
        fontFamily: '',
        fontSize: { desktop: '16px', mobile: '16px' },
        lineHeight: { desktop: '22px', mobile: '20px' },
        margin: { desktop: '', mobile: '' },
        textTransform: 'none',
        padding: '',
      },
      style2: {
        color: '',
        fontWeight: '650',
        letterSpacing: '0',
        fontFamily: '',
        fontSize: { desktop: '20px', mobile: '16px' },
        lineHeight: { desktop: '22px', mobile: '20px' },
        margin: { desktop: '0px 0px 10px 0px', mobile: '' },
        textTransform: 'none',
        padding: '',
      },
      style3: {
        color: '',
        fontWeight: '650',
        letterSpacing: '0',
        fontFamily: '',
        fontSize: { desktop: '20px', mobile: '16px' },
        lineHeight: { desktop: '22px', mobile: '20px' },
        margin: { desktop: '0px 0px 10px 0px', mobile: '' },
        textTransform: 'none',
        padding: '',
      },
    },
    preamble: {
      color: colors.bodyTextBlack,
      fontSize: { desktop: '16px', mobile: '14px' },
    },
  },
  photoUpload: {
    button: {
      padding: '15px',
      fontSize: '16px',
    },
    checkbox: {
      fontSize: '16px',
      letterSpacing: '0',
    },
  },
  questionBlock: {
    optionsItem: {
      backgroundActive: colors.selectedOption,
      backgroundNormal: colors.selectableOptionBackground,
      borderActive: `1px solid ${colors.selectedOptionBorder}`,
      borderNormal: `1px solid ${colors.border}`,
      activeBoxShadow: '0 0 0 5px RGBA(228, 213, 196, 0.5)',
    },
    textArea: {
      fontSize: '16px',
    },
    heading: {
      container: {
        style1: {
          margin: '7px 0px 7px 0px',
        },
        style2: {
          margin: '7px 0px 7px 0px',
        },
        style3: {
          margin: '7px 0px 7px 0px',
        },
      },
    },
  },
  bookingDetails: {
    border: {
      color: colors.border,
    },
    marginTop: '40px',
    lineHeight: '22px',
    subHeadline: {
      color: colors.bodyTextBlack,
      letterSpacing: '0',
      fontSize: '16px',
    },
  },
  amend: {
    error: {
      fontWeight: '400',
    },
    step1: {
      heading: {
        margin: '40px 0px 40px 0px',
      },
      preamble: {
        lineHeight: '26px',
      },
      appointmentBox: {
        backgroundColor: '#e4d4c4',
      },
      button: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
      },
    },
    step2: {
      calendarHeading: {
        fontFamily: FONT_FAMILIES.Reckless,
        fontSize: { desktop: '32px', mobile: '26px' },
        fontWeight: '400',
      },
      meetingTypes: {
        heading: {
          fontFamily: FONT_FAMILIES.Reckless,
          fontSize: { desktop: '32px', mobile: '26px' },
          fontWeight: '400',
        },
      },
      thin: {
        fontWeight: '400',
      },
      heading: {
        margin: '40px 0px 20px 0px',
      },
      stickyNav: {
        backgroundColor: '#F2E9DB',
      },
      button: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
        padding: '14px 0px 0px 0px',
        marginLeft: '-5px',
        border: '1px solid #31261D',
        color: '#31261D',
      },
      buttonContainer: {
        padding: '0px 30px 0px 20px',
      },
      whiteButton: {
        backgroundColor: 'transparent',
        borderRadius: '2px',
        padding: '12px 0px 0px 0px',
      },
    },
    step3: {
      heading: {
        margin: '40px auto 30px auto',
        maxWidth: '520px',
      },
      link: {
        color: '#31261D',
      },
    },
    appointmentInformation: {
      link: {
        color: '#31261D',
      },
      wrapper: {
        backgroundColor: '#e4d4c4',
        borderRadius: '2px',
        padding: '20px 40px 60px 15px',
      },
      heading: {
        fontWeight: '800',
      },
      iconContainer: {
        display: {
          desktop: 'block',
          mobile: 'none',
        },
      },
    },
  },
  cancel: {
    step1: {
      box: {
        backgroundColor: '#e4d4c4',
      },
      heading: {
        fontFamily: FONT_FAMILIES.Reckless,
        margin: '40px auto 40px auto',
        fontWeight: '400',
        fontSize: {
          mobile: '38px',
          desktop: '66px',
        },
        lineHeight: {
          mobile: '40px',
          desktop: '71px',
        },
      },
      subHeading: {
        fontSize: '20px',
        fontWeight: '700',
      },
      confirmText: {
        fontSize: '20px',
        fontWeight: '700',
      },
      usps: {
        fontSize: '16px',
        li: {
          lineHeight: '24px',
          before: {
            top: '8px',
            backgroundColor: '#31261D',
          },
        },
      },
      button: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
        fontSize: '16px',
      },
      whiteLink: {
        backgroundColor: 'transparent',
        border: '1px solid #31261D',
        color: '#31261D',
      },
    },
    step2: {
      tooltip: {
        top: {
          mobile: '33px',
          desktop: '0px',
        },
        left: {
          mobile: '-16px',
          desktop: '240px',
        },
      },
      link: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
      },
      backLink: {
        color: '#31261D',
        fontSize: '16px',
      },
      heading: {
        fontFamily: FONT_FAMILIES.Reckless,
        margin: '40px auto 30px auto',
        fontWeight: '500',
        fontSize: {
          desktop: '66px',
          mobile: '38px',
        },
        lineHeight: {
          desktop: '71px',
          mobile: '40px',
        },
      },
      subHeading: {
        fontSize: '20px',
        fontWeight: '700',
      },
      box: {
        backgroundColor: '#e4d4c4',
      },
    },
    step3: {
      heading: {
        fontFamily: FONT_FAMILIES.Reckless,
        fontWeight: '500',
        margin: '80px auto 40px auto',
        fontSize: {
          mobile: '38px',
          desktop: '66px',
        },
        lineHeight: {
          mobile: '40px',
          desktop: '71px',
        },
      },
      link: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
      },
    },
    step4: {
      heading: {
        fontFamily: FONT_FAMILIES.Reckless,
        fontWeight: '500',
        margin: '80px auto 40px auto',
        fontSize: {
          mobile: '38px',
          desktop: '66px',
        },
        lineHeight: {
          mobile: '40px',
          desktop: '71px',
        },
      },
      link: {
        backgroundColor: '#31261D',
        borderRadius: '2px',
      },
    },
  },
  GlobalStyles,
}
