import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { prop, theme } from 'styled-tools'

import RadioGroup from '../../components/customer-form/radioGroup'
import Loader from '../../components/loader/Loader'
import Textarea from '../../components/customer-form/textarea'

import { SIMPLY_BOOK_ME } from '../../../app/containers/bada/reducer/Wizard'
import cancelAppointmentMutation from '../../../graphql/CancelAppointmentMutation.graphql'
import { navigateToRoute, navigateToStep } from '../utils'
import { appBasePath } from '../../../utils/appBasePath'
import useTranslation from '../../../utils/translation'

const Heading = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('cancel.step2.heading.fontFamily')};
  text-align: center;
  margin: ${theme('cancel.step2.heading.margin')};
  max-width: 550px;
  font-size: ${theme('cancel.step2.heading.fontSize.mobile')};
  line-height: ${theme('cancel.step2.heading.lineHeight.mobile')};
  font-weight: ${theme('cancel.step2.heading.fontWeight')};
  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('cancel.step2.heading.fontSize.desktop')};
    line-height: ${theme('cancel.step2.heading.lineHeight.desktop')};
  `}
`

const SubHeading = styled.h2`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-family: ${theme('cancel.step2.subHeading.fontFamily')};
  font-size: ${theme('cancel.step2.subHeading.fontSize')};
  font-weight: ${theme('cancel.step2.subHeading.fontWeight')};
  margin-top: 10px;
  margin-bottom: 26px;
`

const Box = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0px auto;
  background-color: ${theme('cancel.step2.box.backgroundColor')};
  padding: 20px 35px 25px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  ul {
    padding-left: 17px;
    li {
      margin-bottom: 15px;
    }
  }
`

const Details = styled.div`
  ${(props) => props.cancellationReason === 'OtherReason' && 'width: 100%;'}
`

const Link = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  background-color: ${theme('cancel.step2.link.backgroundColor')};
  color: #ffffff;
  text-decoration: none;
  padding: ${(props) =>
    theme('cancel.step2.link.padding')(props) || '13px 30px 13px 30px'};
  font-size: 16px;
  border-radius: ${theme('cancel.step2.link.borderRadius')};
  width: 100%;
  font-weight: ${(props) =>
    theme('cancel.step2.link.fontWeight')(props) || 500};
  text-transform: ${(props) =>
    theme('cancel.step2.link.textTransform')(props) || 'none'};
  display: block;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
const LoaderCover = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
`

const LoaderContainer = styled.div`
  margin-top: 40vh;
`

const ConfirmContainer = styled.div`
  text-align: center;
  margin: 30px auto;
  max-width: 550px;
`

const RadioGroupContainer = styled.div`
  display: block;
  position: relative;
  width: 100%;
  span {
    label {
      display: block;
      font-weight: 300;
      font-size: 16px;
      margin-bottom: 15px;
      span {
        font-family: ${theme('typography.paragraph.fontFamily')};
        color: ${(props) => theme('cancel.step2.radioButton.color')(props)};
      }
    }
  }
`

const Tooltip = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 13px;
  padding: 10px 15px 10px 15px;
  position: absolute;
  background-color: #fff;
  width: 270px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  top: ${theme('cancel.step2.tooltip.top.mobile')};
  left: ${theme('cancel.step2.tooltip.left.mobile')};
  ${(props) => props.theme.media.min.sm`
    top: ${theme('cancel.step2.tooltip.top.desktop')};
    left: ${theme('cancel.step2.tooltip.left.desktop')};
  `}
`

const TooltipHeading = styled.h4`
  font-size: 13px;
  font-weight: 700;
  margin: 0px;
`

const TooltipText = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  span {
    text-decoration: underline;
    cursor: pointer;
  }
`
const CaretContainer = styled.div`
  position: relative;
`
const Caret = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  display: inline-block;
  border: 10px solid transparent;
  border-right-color: #ffffff;
  top: -84px;
  left: -4px;
  transform: rotate(90deg);
  ${(props) => props.theme.media.min.sm`
    top: -49px;
    left: -34px;
    transform: none;
  `}
`
const BackLink = styled.a`
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: ${theme('cancel.step2.backLink.color')};
  font-size: ${theme('cancel.step2.backLink.fontSize')};
  text-align: center;
  display: block;
  margin-top: 30px;
`

const StyledTextarea = styled(Textarea)`
  margin-left: 29px;
  width: calc(100% - 29px);
  textarea& {
    border: 1px solid ${theme('forms.colors.border')};

    &:focus,
    &:active {
      outline: none;
      border: 1px solid ${prop('theme.forms.colors.activeBorder')};
      box-shadow: ${theme('colors.accentShadow')};
    }
    &::placeholder {
      color: ${theme('forms.colors.placeholder')};
    }
  }

  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: '16px';

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px
      ${prop('theme.forms.colors.prefilledBackground')} inset !important;
  }
`

const getFirstName = (fullName) => {
  if (fullName.indexOf(' ') > -1) {
    return fullName.split(' ')[0]
  }
  return fullName
}

const CancelStep2 = ({ appointment }) => {
  const t = useTranslation('cancel.step2')
  const history = useHistory()
  const store = new URLSearchParams(window.location.search).get('store')
  const isStore = !!store

  const [cancellationReason, setCancellationReason] = useState('')
  const [showTooltip, setShowtooltip] = useState(false)
  const [otherReasonText, setOtherReasonText] = useState('')

  const [cancelAppointment, { loading }] = useMutation(
    cancelAppointmentMutation,
    {
      variables: {
        input: {
          bookingSystem: appointment.externalBookingSystem || SIMPLY_BOOK_ME,
          storeId: appointment.storeId,
          appointmentId: appointment.id,
          externalAppointmentId:
            appointment.externalAppointmentId || appointment.simplyBookMeId,
          subsystem: appointment.simplyBookMeSubsystem,
          cancellationReason:
            cancellationReason === 'OtherReason' && otherReasonText !== ''
              ? otherReasonText
              : cancellationReason,
        },
      },
      onCompleted: () => {
        navigateToStep(3, history, isStore)
      },
    }
  )

  const onReasonChange = (e) => {
    setCancellationReason(e.target.value)
    setShowtooltip(e.target.value === 'NoLongerAvailable')
  }
  const onOtherReasonChange = (e) => {
    setOtherReasonText(e.target.value)
  }

  const generateOptions = () => {
    const reasons = [
      { key: 'reason1', value: 'NoLongerAvailable' },
      { key: 'reason2', value: 'ChangedMind' },
      { key: 'reason3', value: 'WentElsewhere' },
      { key: 'reason4', value: 'OtherReason' },
    ]

    return reasons.reduce((options, reason) => {
      const label = t(`${isStore ? 'store-' : ''}${reason.key}`)
      if (label) {
        options.push({ value: reason.value, label })
      }
      return options
    }, [])
  }

  return (
    <>
      <Heading>
        {appointment &&
          t('heading', { name: getFirstName(appointment.customerName) })}
      </Heading>
      <Box onClick={() => setShowtooltip(false)}>
        <Details cancellationReason={cancellationReason}>
          <SubHeading>{t('subHeading')}</SubHeading>
          <RadioGroupContainer>
            <RadioGroup options={generateOptions()} onChange={onReasonChange} />
            {cancellationReason === 'OtherReason' && (
              <StyledTextarea
                placeholder={t('otherReasonPlaceholder')}
                value={otherReasonText}
                onChange={onOtherReasonChange}
              />
            )}
            {showTooltip && (
              <Tooltip
                onClick={() =>
                  navigateToRoute(
                    `${appBasePath}amend/${appointment.id}`,
                    history
                  )
                }
              >
                <TooltipHeading>{t('tooltip-heading')}</TooltipHeading>
                <TooltipText
                  dangerouslySetInnerHTML={{
                    __html: t('tooltip-text'),
                  }}
                />
                <CaretContainer>
                  <Caret />
                </CaretContainer>
              </Tooltip>
            )}
          </RadioGroupContainer>
        </Details>
      </Box>
      <ConfirmContainer>
        <Link
          onClick={() => {
            if (cancellationReason) {
              cancelAppointment()
            }
          }}
          disabled={!cancellationReason}
        >
          {t('buttonText')}
        </Link>

        <BackLink href="/">{t('link')}</BackLink>
      </ConfirmContainer>
      {loading && (
        <LoaderCover>
          <LoaderContainer>
            <Loader duration={1} />
          </LoaderContainer>
        </LoaderCover>
      )}
    </>
  )
}

export default CancelStep2
