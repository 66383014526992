import { useQuery } from '@apollo/react-hooks'
import storeQuery from '../../graphql/StoreWithDistanceQuery.graphql'

const useStore = (storeId) =>
  useQuery(storeQuery, {
    variables: {
      storeId,
      input: {
        city: 'Stockholm',
        countryName: 'Sweden',
        postalCode: '118 58',
      },
    },
    skip: !storeId,
  })

export default useStore