import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import { compose, withProps, withState } from 'recompose'
import Textarea from '../customer-form/textarea'
import PhotoUpload from '../../components/photo-upload'
import Slider from '../slider'
import withSplash from '../with-splash'
import Option from './Options/index'
import useTranslation from '../../../utils/translation'
import CheckBox from '../customer-form/checkbox'
import InputField from '../customer-form/InputField'
import { extractUrlSettings } from '../../../utils/cloudinary'

const QuestionBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${theme('quickQuestions.container.mobile.margin')};
  padding: ${theme('quickQuestions.container.mobile.padding')};
  border: 1px solid transparent;
  border-bottom: ${theme('quickQuestions.container.bottomBorder')};

  ${(props) =>
    props.questionStyle === 'style2' &&
    `
    border-bottom: 0;
  `}

  ${(props) => props.theme.media.min.lg`
    flex-direction: ${theme('quickQuestions.container.desktop.flexDirection')};
  `}
  ${(props) => props.theme.media.min.sm`
    margin: ${theme('quickQuestions.container.desktop.margin')};
    padding: ${theme('quickQuestions.container.desktop.padding')};
  `}
  ${(props) =>
    props.requiresAnswer &&
    `
    border: 1px solid #ec8888;
    background-color: #fdf5f5;
  `}
`

const QuestionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${theme(`questionBlock.container.flexBasis`)};
  ${(props) => (props.hasOptions ? `flex-grow:0;` : `flex-grow:1;`)}
`

const QuestionHeading = styled.div`
  display: flex;
  color: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.color`)};
  font-weight: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.fontWeight`)};
  letter-spacing: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.letterSpacing`)};
  font-family: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.fontFamily`)};
  font-size: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.fontSize.mobile`)};
  line-height: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.lineHeight.mobile`)};
  margin: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.margin.mobile`)};
  text-transform: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.textTransform`)};
  padding: ${(props) =>
    theme(`quickQuestions.heading.${props.questionStyle}.padding`)};

  ${(props) => props.theme.media.min.sm`
    font-size: ${theme(
      `quickQuestions.heading.${props.questionStyle}.fontSize.desktop`
    )};
    line-height: ${theme(
      `quickQuestions.heading.${props.questionStyle}.lineHeight.desktop`
    )};
    margin: ${theme(
      `quickQuestions.heading.${props.questionStyle}.margin.desktop`
    )};
  `}
`

const QuestionPreamble = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('quickQuestions.preamble.fontSize.mobile')};
  font-weight: 300;
  line-height: ${theme('quickQuestions.preamble.lineHeight')};
  margin: 8px 0px 8px 0px;
  color: ${theme(`quickQuestions.preamble.color`)};
  margin-top: 0;

  a {
    color: ${theme(`colors.link`)};
  }

  p {
    padding-top: 0;
    margin-top: 0;
    font-size: ${theme('quickQuestions.preamble.fontSize.mobile')};
  }

  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 14px;
    margin-top: 0;
  }
  ul > li {
    font-weight: 700;
    list-style-type: disc;
    list-style-position: inside;
    font-size: ${theme('quickQuestions.preamble.fontSize.mobile')};
  }

  strong {
    font-weight: 700;
  }

  ${(props) => props.theme.media.min.sm`
    padding-right: 30px;
    font-size: ${theme('quickQuestions.preamble.fontSize.desktop')};

    p {
      font-size: ${theme('quickQuestions.preamble.fontSize.desktop')};
    }

    ul > li {
      font-size: ${theme('quickQuestions.preamble.fontSize.desktop')};
    }
  `};
`

const QuestionImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  ${(props) => props.theme.media.min.md`
    flex-basis: 30%;
  `};
`

const QuestionImage = styled.img`
  display: inline;
  height: auto;
  width: auto;
  max-width: 100%;
  margin: 10px 0;
`

const QuestionVideo = styled.video`
  display: inline;
  height: auto;
  width: auto;
  max-width: 100%;
  margin: 10px 0;
`

const TextAndImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.media.min.sm`
    flex-direction: row;
  `};
`

const HeadingAndPreambleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props) =>
    theme(`questionBlock.heading.container.${props.questionStyle}.margin`)};
  flex-basis: 100%;
  ${(props) => props.theme.media.min.md`
    flex-basis: ${theme(
      `questionBlock.heading.container.${props.questionStyle}.flexBasis`
    )};
  `};
`

const OptionsItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${theme('questionBlock.options.margin.mobile')};
  width: 100%;

  ${(props) => props.theme.media.min.md`
    min-width: 580px;
  `};

  ${(props) => props.theme.media.min.sm`

    margin: ${theme('questionBlock.options.margin.desktop')};
    flex-wrap: wrap;
    flex-direction: row;
  `};
`

const OptionsItem = styled.div`
  cursor: pointer;
  position: relative;
  border: ${theme('questionBlock.optionsItem.border')};

  border: ${theme('questionBlock.optionsItem.borderNormal')};
  background-color: ${theme('questionBlock.optionsItem.backgroundNormal')};

  border-radius: ${theme('questionBlock.optionsItem.borderRadius')};
  margin-bottom: 10px;
  white-space: nowrap;

  ${(props) =>
    props.type === 'TextOption'
      ? `
      text-align: center;

      `
      : `text-align: left;`}

  ${(props) => props.theme.media.min.sm`
      margin: ${theme('questionBlock.optionsItem.margin.desktop')};
    `}

    ${(props) =>
    props.type === 'ImageOption' &&
    props.theme.media.min.sm`
        max-width: ${theme('questionBlock.optionsItem.width.desktop')};
    `}

    ${(props) =>
    props.active &&
    css`
      border: ${theme('questionBlock.optionsItem.activeBorder')};
      box-shadow: ${theme('questionBlock.optionsItem.activeBoxShadow')};

      background-color: ${theme('questionBlock.optionsItem.backgroundActive')};
      border: ${theme('questionBlock.optionsItem.borderActive')};
    `}
`

const AnotherOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 400;
  width: 100%;
  margin: ${theme('questionBlock.options.margin.mobile')};
  ${(props) => props.theme.media.min.sm`
    margin:0;
    padding: 14px;
    box-sizing: border-box;
  `}
  ${(props) => props.theme.media.min.lg`
    width: auto;
    min-width: 160px;
    justify-content: flex-start;
    justify-content: ${theme('questionBlock.anotherOption.justifyContent')};
  `}
`

const AnotherOptionText = styled.p`
  cursor: pointer;
  color: ${theme('colors.darkSlate')};
  font-weight: 700;
  font-size: ${theme('questionBlock.anotherOption.text.fontSize')};
  text-align: center;
  display: block;
  margin: 0px auto;
  font-family: ${theme('typography.paragraph.fontFamily')};
  ${(props) => props.theme.media.min.sm`
      margin: 0px;
      text-align: right;
      display:inline;
  `}

  ${(props) =>
    props.active &&
    `
    color: ${props.theme.colors.link};
  `}
`

const StyledTextareaWrapper = styled.div`
  ${(props) => props.theme.media.min.sm`
    display: flex;
    align-items: flex-end;
  `};
  margin-bottom: 14px;

  & > div {
    margin-bottom: 0;
  }
`

const StyledTextarea = styled(Textarea)`
  border: 1px solid ${theme('forms.colors.border')};
  width: 100%;
  height: 140px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('questionBlock.textArea.fontSize')};
  border-radius: ${theme('questionBlock.textArea.borderRadius')};

  textarea& {
    border: 1px solid ${theme('forms.colors.border')};
  }
  textarea&:focus,
  textarea&:active {
    border: 1px solid ${theme('forms.colors.activeBorder')};
  }
  &::placeholder {
    color: ${theme('forms.colors.placeholder')};
  }

  ${(props) => props.theme.media.min.sm`
    width: 370px;
  `}
`

const StyledFormWrapper = styled.div`
  ${(props) => props.theme.media.min.sm`
    display: flex;
    flex-direction: column;
  `};
  margin-bottom: 14px;

  & > div {
    margin-bottom: 0;
  }
`

const FormItemWrapper = styled.div`
  width: 100%;

  ${(props) => props.theme.media.min.sm`
    width: 370px;
  `}
`

const QuestionValidation = styled.div`
  color: #ec8888;
  font-weight: 700;
  font-family: ${theme('typography.paragraph.fontFamily')};
  margin: 10px 0px;
`

const OptionsContainer = styled.div`
  display: ${theme('options.container.display')};
  flex-direction: ${theme('options.container.flexDirection')};
  justify-content: ${theme('options.container.justifyContent')};
  flex-basis: 70%;
`

const StyledSliderAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const StyledSliderOptionsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const isSelected = (questionText, answer, prepData) => {
  const match =
    prepData &&
    prepData.questions &&
    prepData.questions.find(
      (q) => q.question === questionText && q.answers.includes(answer)
    )
  if (!match) {
    return false
  }
  return true
}

const QuestionBlock = ({
  q,
  selectOption,
  onChange,
  updateUiState,
  prepData,
  splash,
  textAreaValue,
  setTextAreaValue,
  sliderDefaultValue,
  // eslint-disable-next-line
  setSliderDefaultValue,
  sliderValue,
  setSliderValue,
  hiddenPlaceholder,
  setHiddenPlaceholder,
  uploadedFiles,
  useSplash,
}) => {
  const questionType = useMemo(
    () => (q.optionType && q.optionType.value ? q.optionType.value : ''),
    [q]
  )
  const t = useTranslation('app.prep-form')

  const questionStyle = (
    (q.questionStyle && q.questionStyle.value) ||
    'style-1'
  ).replace('-', '')

  const sliderOptions =
    questionType === 'slider' ? q.options.expandedValue[0] : undefined

  const question = useMemo(
    () =>
      (prepData &&
        prepData.questions &&
        prepData.questions.find(
          (qu) => qu.question === q.questionText.value
        )) || { answers: [] },
    [q.questionText, prepData]
  )
  const [form, setForm] = useState({})

  useEffect(() => {
    setForm(
      questionType.startsWith('form') && question.answers
        ? JSON.parse(question.answers[0] || '{}')
        : {}
    )
  }, [question, questionType])

  const formCheckbox = useMemo(
    () => form.checkbox === q.otherAnswerText.value,
    [form, q.otherAnswerText]
  )
  const showForm = useMemo(
    () =>
      (questionType === 'form-checkbox' && formCheckbox) ||
      questionType !== 'form-checkbox',
    [questionType, formCheckbox]
  )

  const onChangeFormData = useCallback((e) => {
    const name = e.target.name
    const value = e.target.value
    setForm((f) => ({ ...f, [name]: value }))
  }, [])

  const onBlurFormData = useCallback(() => {
    const empty = Object.values(form).every((f) => !f)
    const nonEmpty = Object.fromEntries(
      Object.keys(form)
        .filter((k) => form[k])
        .map((k) => [k, form[k].trim()])
    )
    selectOption(
      onChange,
      updateUiState,
      prepData,
      { name: q.questionText.value },
      {
        ...q,
        inputValue: empty ? null : JSON.stringify(nonEmpty),
      }
    )
    if (useSplash) {
      splash()
    }
  }, [
    selectOption,
    onChange,
    updateUiState,
    prepData,
    q,
    form,
    useSplash,
    splash,
  ])

  const onCheckboxChange = useCallback(
    (e) => {
      const value = e.target.value
      const newForm = value ? { checkbox: value } : null
      setForm(newForm || {})
      selectOption(
        onChange,
        updateUiState,
        prepData,
        { name: q.questionText.value },
        {
          ...q,
          inputValue: newForm ? JSON.stringify(newForm) : null,
        }
      )
      if (useSplash) {
        splash()
      }
    },
    [selectOption, onChange, updateUiState, prepData, q, useSplash, splash]
  )
  const mediaUrl = useMemo(() => {
    if (
      !q.questionImage ||
      !q.questionImage.value ||
      !q.questionImage.value.url
    ) {
      return null
    }
    const url = q.questionImage.value.url
    const publicId = q.questionImage.value.publicId
    if (!publicId) {
      return url
    }

    let type = q.questionImage.value.type
    let resourceType = q.questionImage.value.resourceType
    if (!type || !resourceType) {
      const { deliveryType, assetType } = extractUrlSettings(url)

      type = type || deliveryType
      resourceType = resourceType || assetType
    }

    const width = resourceType === 'video' ? 'w_800' : 'w_480'

    return `https://assets.nobiadigital.com/${resourceType}/${type}/c_scale,${width}/q_auto/f_auto/${publicId}`
  }, [])

  if (!questionType) {
    return null
  }

  return (
    <QuestionBlockItem
      data-track={`section-${q.sortOrder}`}
      data-cy="question-block"
      questionStyle={questionStyle}
      requiresAnswer={q.required && q.notAnswered}
    >
      <QuestionTextWrapper
        hasOptions={questionType === 'single' || questionType === 'multi'}
      >
        <TextAndImageContainer>
          <HeadingAndPreambleContainer questionStyle={questionStyle}>
            {q.questionText && q.questionText.value && (
              <QuestionHeading
                questionStyle={questionStyle}
                dangerouslySetInnerHTML={{ __html: q.questionText.value }}
              />
            )}
            {q.questionDescription && q.questionDescription.value && (
              <QuestionPreamble
                dangerouslySetInnerHTML={{
                  __html: q.questionDescription.value,
                }}
              />
            )}
            {q.required &&
              q.notAnswered &&
              q.requiredText &&
              q.requiredText.value && (
                <QuestionValidation>{q.requiredText.value}</QuestionValidation>
              )}
            {!!mediaUrl && (
              <QuestionImageWrapper>
                {q.questionImage.value.resourceType === 'video' ? (
                  <QuestionVideo controls playsInline poster={`${mediaUrl}.jpg`}>
                    <source type="video/webm" src={`${mediaUrl}.webm`} />
                    <source type="video/mp4" src={`${mediaUrl}.mp4`} />
                  </QuestionVideo>
                ) : (
                  <QuestionImage src={mediaUrl} />
                )}
              </QuestionImageWrapper>
            )}
          </HeadingAndPreambleContainer>
        </TextAndImageContainer>
      </QuestionTextWrapper>

      {(questionType === 'single' || questionType === 'multi') &&
        q.options &&
        q.options.expandedValue &&
        q.options.expandedValue.length > 0 && (
          <OptionsContainer>
            <OptionsItemWrapper repeat={q.options.expandedValue.length}>
              {q.options.expandedValue.map((o) => (
                <OptionsItem
                  data-track={`option-${o.name}`.toLowerCase()}
                  key={o.name}
                  type={o.contentType[1]}
                  active={isSelected(q.questionText.value, o.name, prepData)}
                  onClick={() => {
                    selectOption(onChange, updateUiState, prepData, o, q)
                    if (useSplash) splash()
                  }}
                >
                  <Option
                    data={o}
                    active={isSelected(q.questionText.value, o.name, prepData)}
                    type={o.contentType}
                    updateUiState={updateUiState}
                    questionStyle={questionStyle}
                  />
                </OptionsItem>
              ))}
            </OptionsItemWrapper>
            {q.otherAnswerText.value && (
              <AnotherOption data-track={`not-sure-${q.sortOrder}`}>
                <AnotherOptionText
                  active={isSelected(
                    q.questionText.value,
                    q.otherAnswerText.value,
                    prepData
                  )}
                  onClick={() => {
                    selectOption(
                      onChange,
                      updateUiState,
                      prepData,
                      { name: q.otherAnswerText.value },
                      q
                    )
                    if (useSplash) splash()
                  }}
                >
                  {q.otherAnswerText.value}
                </AnotherOptionText>
              </AnotherOption>
            )}
          </OptionsContainer>
        )}

      {questionType === 'textarea' && (
        <StyledTextareaWrapper>
          <StyledTextarea
            data-track="info"
            placeholder={
              (q.otherAnswerText && q.otherAnswerText.value) ||
              t('placeholder-text')
            }
            onChange={(e) => {
              setTextAreaValue(e.target.value)
            }}
            onFocus={(e) => {
              selectOption(
                onChange,
                updateUiState,
                prepData,
                { name: q.questionText.value },
                { ...q, otherAnswerText: null, inputValue: e.target.value }
              )
            }}
            onBlur={(e) => {
              selectOption(
                onChange,
                updateUiState,
                prepData,
                { name: q.questionText.value },
                { ...q, otherAnswerText: null, inputValue: e.target.value }
              )
              if (useSplash) splash()
            }}
            value={textAreaValue}
          />
        </StyledTextareaWrapper>
      )}

      {(questionType === 'form' || questionType === 'form-checkbox') && (
        <StyledFormWrapper>
          {questionType === 'form-checkbox' && (
            <FormItemWrapper>
              <CheckBox
                label={q.otherAnswerText.value}
                checked={formCheckbox}
                name="checkbox"
                value={formCheckbox ? '' : q.otherAnswerText.value}
                onChange={onCheckboxChange}
              />
            </FormItemWrapper>
          )}
          {showForm &&
            q.options &&
            q.options.expandedValue &&
            q.options.expandedValue
              .filter((e) => e.contentType.includes('FormFieldOptionBlock'))
              .map((o) => (
                <FormItemWrapper key={o.name}>
                  {o.fieldType === 'textarea' ? (
                    <Textarea
                      label={o.label}
                      value={form[o.label] || ''}
                      name={o.label}
                      onChange={onChangeFormData}
                      onBlur={onBlurFormData}
                    />
                  ) : (
                    <InputField
                      label={o.label}
                      value={form[o.label] || ''}
                      name={o.label}
                      onChange={onChangeFormData}
                      onBlur={onBlurFormData}
                    />
                  )}
                </FormItemWrapper>
              ))}
        </StyledFormWrapper>
      )}

      {(questionType === 'uploadphotos' || questionType === 'uploadsketch') && (
        <PhotoUpload
          videoUrl={q.videoUrl && q.videoUrl.url}
          videoText={q.videoLinkText && q.videoLinkText.value}
          buttonText={t('upload-button', '')}
          checkboxText={q.otherAnswerText && q.otherAnswerText.value}
          question={q}
          updateUiState={updateUiState}
          prepData={prepData}
          uploadedFiles={uploadedFiles}
          onUpdate={(files) => {
            setTimeout(() => onChange(files), 500)
          }}
          fileInputId={`photoUpload${q.sortOrder}`}
          parameterName={q.questionText && q.questionText.value}
          optOutParameterName={`optout-${
            q.questionText && q.questionText.value
          }`}
          isImages={questionType === 'uploadphotos'}
          useSplash={useSplash}
        />
      )}

      {questionType === 'slider' && (
        <OptionsContainer>
          <StyledSliderAreaWrapper>
            <StyledSliderOptionsWrapper>
              <span>
                {`${sliderOptions.prefix.value} ${sliderOptions.minValue.value}`}
              </span>
              <span>
                {`${sliderOptions.prefix.value} ${sliderOptions.maxValue.value}${sliderOptions.endSuffix.value}`}
              </span>
            </StyledSliderOptionsWrapper>
            <StyledTextareaWrapper>
              <Slider
                minValue={sliderOptions.minValue.value}
                maxValue={sliderOptions.maxValue.value}
                prefix={sliderOptions.prefix.value}
                endSuffix={sliderOptions.endSuffix.value}
                defaultValue={sliderDefaultValue}
                amountInterval={sliderOptions.amountInterval.value}
                onChange={(value) => {
                  setSliderValue(value)
                  setHiddenPlaceholder(true)
                }}
                onAfterChange={(value) => {
                  selectOption(
                    onChange,
                    updateUiState,
                    prepData,
                    { name: q.questionText.value },
                    { ...q, otherAnswerText: null, inputValue: value }
                  )
                  if (useSplash) splash()
                }}
              />
            </StyledTextareaWrapper>
            <HeadingAndPreambleContainer>
              {hiddenPlaceholder ? (
                <div>
                  <QuestionPreamble
                    questionStyle={questionStyle}
                    dangerouslySetInnerHTML={{
                      __html: q.questionSupportingText.value,
                    }}
                  />
                  <QuestionHeading
                    questionStyle={questionStyle}
                    dangerouslySetInnerHTML={{ __html: sliderValue }}
                  />
                </div>
              ) : (
                <QuestionHeading
                  questionStyle={questionStyle}
                  dangerouslySetInnerHTML={{
                    __html: sliderOptions.placeholderText.value,
                  }}
                />
              )}
            </HeadingAndPreambleContainer>
          </StyledSliderAreaWrapper>
          {q.otherAnswerText.value && (
            <AnotherOption data-track={`not-sure-${q.sortOrder}`}>
              <AnotherOptionText
                active={isSelected(
                  q.questionText.value,
                  q.otherAnswerText.value,
                  prepData
                )}
                onClick={() => {
                  if (sliderValue === q.otherAnswerText.value) {
                    setSliderValue(sliderOptions.minValue.value)
                    setHiddenPlaceholder(false)
                  } else {
                    setSliderValue(q.otherAnswerText.value)
                    setHiddenPlaceholder(true)
                  }
                  selectOption(
                    onChange,
                    updateUiState,
                    prepData,
                    { name: q.otherAnswerText.value },
                    q
                  )
                  if (useSplash) splash()
                }}
              >
                {q.otherAnswerText.value}
              </AnotherOptionText>
            </AnotherOption>
          )}
        </OptionsContainer>
      )}
    </QuestionBlockItem>
  )
}

const isPrepDataEmpty = (prepData) => {
  if (!prepData) {
    return true
  }
  if (!prepData.questions) {
    return true
  }
  if (prepData.questions.length === 0) {
    return true
  }

  if (prepData.questions.find((q) => q.answers?.length > 0)) {
    return false
  }

  return true
}

const createFile = (url, filename) => {
  if (!url) return null

  const segments = url.split('/')
  if (segments.length < 2) return null

  const { deliveryType, extension, publicId } = extractUrlSettings(url)
  const [container, blobName] = url.split('/').slice(-2)
  const thumbnailUrl = `https://assets.nobiadigital.com/image/${deliveryType}/c_fill,w_240,ar_1/q_auto/f_auto/${publicId}.${extension}`
  return {
    url,
    thumbnailUrl,
    blobName,
    container,
    fileSize: 1,
    fileName: `${filename}.${extension}`,
    fileType: 'IMAGE',
  }
}

const enhance = compose(
  withSplash,
  withState('hiddenPlaceholder', 'setHiddenPlaceholder', false),
  withState('sliderValue', 'setSliderValue', '0'),
  withProps(
    ({ q, prepData, sliderValue, setSliderValue, setHiddenPlaceholder }) => {
      if (
        q &&
        q.optionType &&
        q.optionType.value &&
        q.optionType.value === 'slider'
      ) {
        if (sliderValue === '0') {
          if (
            q.questionText &&
            q.questionText.value &&
            isPrepDataEmpty(prepData) === false
          ) {
            const question = prepData.questions.find(
              (f) => f.question === q.questionText.value
            )

            if (question && question.answers && question.answers.length > 0) {
              setHiddenPlaceholder(true)
              setSliderValue(question.answers[0])
              return {}
            }
          }
        }
        return {}
      }
      return {}
    }
  ),
  withState('sliderDefaultValue', 'setSliderDefaultValue', undefined),
  withProps(
    ({
      q,
      prepData,
      sliderDefaultValue,
      setSliderDefaultValue,
      setHiddenPlaceholder,
    }) => {
      if (
        q &&
        q.optionType &&
        q.optionType.value &&
        q.optionType.value === 'slider'
      ) {
        if (isPrepDataEmpty(prepData) && sliderDefaultValue?.length > 0) {
          setSliderDefaultValue('')
        }
        if (sliderDefaultValue === undefined) {
          if (
            q.questionText &&
            q.questionText.value &&
            isPrepDataEmpty(prepData) === false
          ) {
            const question = prepData.questions.find(
              (f) => f.question === q.questionText.value
            )

            if (question && question.answers && question.answers.length > 0) {
              setHiddenPlaceholder(true)
              setSliderDefaultValue(question.answers[0])
              return {}
            }
          }
          setSliderDefaultValue('')
        }
        return {}
      }
      return {}
    }
  ),
  withState('textAreaValue', 'setTextAreaValue', undefined),
  withProps(({ q, prepData, textAreaValue, setTextAreaValue }) => {
    if (isPrepDataEmpty(prepData) && textAreaValue?.length > 0) {
      setTextAreaValue('')
    }
    if (textAreaValue === undefined) {
      if (
        q.questionText &&
        q.questionText.value &&
        isPrepDataEmpty(prepData) === false
      ) {
        const question = prepData.questions.find(
          (f) => f.question === q.questionText.value
        )

        if (question && question.answers && question.answers.length > 0) {
          setTextAreaValue(question.answers[0])
          return {}
        }
      }
      setTextAreaValue('')
    }
    return {}
  }),
  withState('uploadedFiles', 'updateUploadedFiles', null),
  withProps(({ q, prepData, uploadedFiles, updateUploadedFiles }) => {
    if (
      q &&
      q.optionType &&
      q.optionType.value &&
      (q.optionType.value === 'uploadphotos' ||
        q.optionType.value === 'uploadsketch') &&
      uploadedFiles === null
    ) {
      if (
        q.questionText &&
        q.questionText.value &&
        prepData &&
        prepData.questions &&
        prepData.questions.length > 0
      ) {
        const question = prepData.questions.find(
          (f) => f.question === q.questionText.value
        )

        if (
          question &&
          question.answers &&
          Array.isArray(question.answers) &&
          question.answers.length > 0
        ) {
          const files = question.answers.map((imageUrl, index) => {
            const imagefileName = `photo-${index}`
            return createFile(imageUrl, imagefileName)
          })

          updateUploadedFiles(files)

          return {}
        }
      }
      updateUploadedFiles([])
    }
    return {}
  })
)

export default enhance(QuestionBlock)
