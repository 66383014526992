import React from 'react'

const CheckmarkCleanIcon = (props) => (
  <svg {...props} viewBox="0 0 96 96">
    <path
      fill="currentColor"
      d="M2.417 57.284L29.22 84.088a2 2 0 0 0 2.829 0L94.19 21.946a2 2 0 0 0 .003-2.825l-4.169-4.19a2 2 0 0 0-2.842.008l-55.24 56.097a2 2 0 0 1-2.867-.017L9.301 50.467a2 2 0 0 0-2.853-.03l-4.03 4.016a2 2 0 0 0-.001 2.83z"
    />
  </svg>
)

export default CheckmarkCleanIcon
