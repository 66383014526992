import { graphql } from '@apollo/client/react/hoc'
import { compose, withProps } from 'recompose'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import React from 'react'
import features from '../../components/features'
import settings from '../../components/settings'
import withHelmet from '../../components/with-helmet/withHelmet'
import appointmentQuery from '../../../graphql/Appointment.graphql'
import CmsPageQuery from '../../../graphql/CmsPageQuery.graphql'
import searchOpenAuthStoresByAddressQuery from '../../../graphql/SearchOpenAuthStoresByAddressQuery.graphql'
import { appBaseUrl } from '../../../utils/appBaseUrl'
import Loader from '../../components/loader/Loader'
import { getDisplayData, getService } from '../bada/content'
import { SIMPLY_BOOK_ME, CUSTOMER_FORM } from '../bada/reducer/Wizard'
import AdditionalInfo from './AdditionalInfo'
import CustomerInfo from './CustomerInfo'
import Images from './Images'
import MeetingEditor from './meeting-editor/MeetingEditor'
import MeetingViewer from './meeting-viewer/MeetingViewer'
import Questions from './Questions'
import { Section, SectionHeader, SectionSpacer } from './sharedUI'
import { checkEmpty, checkMeetingHasPassed } from './utils'
import useTranslation from '../../../utils/translation'
import { ensureSubsystem } from '../../../utils/meetingType'

const MainContainer = styled.div`
  padding: 20px;
  ${(props) => props.theme.media.max.xs`
    padding: 5px
  `};
`

const StyledHeadline = styled.h1`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 32px;
  font-weight: 300;
  margin: 30px auto 40px auto;
  text-align: center;
  max-width: 500px;
  ${(props) => props.theme.media.min.sm`
    font-size: 32px;
    margin: 0px auto 20px auto;
  `}
`

const SectionsContainer = styled.div`
  padding: 0px;
  border: ${theme('appointmentDetails.sectionsContainer.border')};
  border-radius: ${theme('appointmentDetails.sectionsContainer.borderRadius')};
  max-width: 840px;
  margin: 0px auto;
  margin-bottom: 40px;
  background-color: #fff;
`

const PrepComment = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-weight: 300;
`

const NotFound = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  text-align: center;
  margin: 40px auto;
`

const AlertMessage = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding: 20px;
  background-color: ${theme('colors.default')};
  color: #fff;
  border-radius: 8px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: 24px;
`

const AppointmentDetailsPage = ({
  appointment,
  refetchAppointment,
  store,
  pageContent,
}) => {
  if (!appointment) return <Loader duration={3} />
  if (appointment && !appointment.id) {
    return <NotFound>The appointment was not found.</NotFound>
  }
  const t = useTranslation('appointment-details')
  const {
    id,
    selectedDate,
    selectedTime,
    selectedEndTime,
    simplyBookMeId,
    externalAppointmentId,
    simplyBookMeStatus,
    appointmentPreparations,
    storeId,
    serviceProviderId,
    serviceProviderName,
    simplyBookMeSubsystem,
    externalBookingSystem,
    externalMeetingProvider,
  } = appointment
  let storeName = null
  if (store) {
    storeName = store.storeName
  }
  const { questions } = appointmentPreparations || {}
  const meetingHasPassed = checkMeetingHasPassed(selectedDate, selectedTime)
  const displayData = getDisplayData(pageContent.page)
  const defaultBookingSystem = selectedTime ? SIMPLY_BOOK_ME : CUSTOMER_FORM

  const service = getService({
    appointment,
    pageContentPage: pageContent.page,
    bookingSystem: externalBookingSystem || defaultBookingSystem,
    storeSubSystem: simplyBookMeSubsystem,
  })

  const filteredMeetingTypesBySubsystem = displayData.services?.filter(
    (m) =>
      ensureSubsystem(m, simplyBookMeSubsystem, settings.serviceMappings) &&
      m.type === 'simplybookme'
  )

  const orgCmsServiceQuestions = (service && service.questions) || []
  const isContactAppointment = () => {
    if (
      externalMeetingProvider === 'contactemail-appointment' ||
      externalMeetingProvider === 'contactphone-appointment'
    ) {
      return true
    }
    return false
  }

  const getServiceName = () => {
    if (features.kratos && isContactAppointment()) {
      return t(externalMeetingProvider)
    }
    return service && service.heading
  }

  const isSlotBooking = selectedDate && selectedTime

  return (
    <MainContainer>
      <StyledHeadline>{t('heading')}</StyledHeadline>
      <SectionsContainer>
        <SectionSpacer noBottomPadding>
          <Section>
            {meetingHasPassed && (
              <AlertMessage>{t('appointment-passed')}</AlertMessage>
            )}
            {(isSlotBooking && features.editableAppointmentDetails && (
              <MeetingEditor
                externalBookingSystem={externalBookingSystem || SIMPLY_BOOK_ME}
                appointmentId={id}
                selectedDate={checkEmpty(selectedDate)}
                selectedTime={checkEmpty(selectedTime)}
                selectedEndTime={checkEmpty(selectedEndTime)}
                serviceId={service && service.id}
                services={filteredMeetingTypesBySubsystem}
                externalAppointmentId={externalAppointmentId || simplyBookMeId}
                simplyBookMeStatus={simplyBookMeStatus}
                simplyBookMeSubsystem={simplyBookMeSubsystem}
                serviceProviderId={serviceProviderId}
                serviceProviderName={serviceProviderName}
                storeId={storeId && parseInt(storeId, 10)}
                onCancel={refetchAppointment}
                onUpdate={refetchAppointment}
                canceled={simplyBookMeStatus === 'canceled'}
                meetingHasPassed={meetingHasPassed}
              />
            )) || (
              <MeetingViewer
                meetingHasPassed={meetingHasPassed}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                storeName={storeName}
                serviceName={getServiceName(appointment, service)}
              />
            )}
          </Section>
          <CustomerInfo {...appointment} />
          <AdditionalInfo {...appointment} />
        </SectionSpacer>
      </SectionsContainer>
      <SectionsContainer>
        <Questions
          questions={questions}
          orgQuestions={orgCmsServiceQuestions}
          {...appointment}
        />
        {appointmentPreparations &&
          (appointmentPreparations.kitchenScetchImagesUrls.length > 0 ||
            appointmentPreparations.currentKitchenImagesUrls.length > 0 ||
            appointmentPreparations.comment) && (
            <>
              <SectionSpacer>
                <Images
                  appointmentPreparations={appointmentPreparations}
                  features={features}
                />
              </SectionSpacer>
              {appointmentPreparations.comment && (
                <SectionSpacer>
                  <SectionHeader>{t('prep-form.comments')}</SectionHeader>
                  <PrepComment>
                    {appointmentPreparations.comment || '-'}
                  </PrepComment>
                </SectionSpacer>
              )}
            </>
          )}
      </SectionsContainer>
    </MainContainer>
  )
}

AppointmentDetailsPage.propTypes = {
  appointment: PropTypes.shape(),
  store: PropTypes.shape(),
  refetchAppointment: PropTypes.func,
  pageContent: PropTypes.shape().isRequired,
}

AppointmentDetailsPage.defaultProps = {
  appointment: null,
  store: null,
  refetchAppointment: null,
}

const enhance = compose(
  graphql(appointmentQuery, {
    options: ({ match }) => ({
      variables: {
        id: match.params.appointmentId,
      },
    }),
  }),
  withProps(({ data }) => ({
    appointment: data && data.appointment,
    refetchAppointment: data && data.refetch,
  })),
  withHelmet(() => ({
    robots: 'noindex,nofollow',
  })),
  graphql(searchOpenAuthStoresByAddressQuery, {
    options: () => ({
      variables: {
        input: {
          includeFakes: true,
          city: 'Stockholm',
          countryName: 'Sweden',
          postalCode: '118 58',
        },
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ appointment }) => !appointment || !appointment.storeId,
  }),
  withProps(({ data, appointment }) => ({
    store:
      data &&
      data.searchOpenAuthStoresByAddress &&
      data.searchOpenAuthStoresByAddress.stores.find(
        (item) =>
          item.store.id === appointment.storeId &&
          !settings.excludedStudioTypes.includes(item.store.storeType)
      )?.store,
  })),

  graphql(CmsPageQuery, {
    name: 'pageContent',
    options: () => ({
      variables: {
        url: appBaseUrl,
      },
    }),
  })
)

export default enhance(AppointmentDetailsPage)
