import styled from 'styled-components'
import { prop } from 'styled-tools'
import OverlayCloseButton from './OverlayCloseButton'

const LightboxOverlayCloseButton = styled(OverlayCloseButton)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  color: ${prop('theme.overlay.lightbox.closeButton.color')};
  background: ${prop('theme.overlay.lightbox.closeButton.background')};
  transition: background 0.2s;

  &:hover {
    background: ${prop('theme.overlay.lightbox.closeButton.hoverBackground')};
  }
`

export default LightboxOverlayCloseButton
