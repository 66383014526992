import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PageTitle from '../typography/pageTitle'

const Title = styled(PageTitle)`
  max-width: ${theme('contentHeader.width.maxWidth')};
  color: ${theme('contentHeader.heading.color')};
  font-size: ${theme('contentHeader.heading.mobile.fontSize')};
  line-height: ${theme('contentHeader.heading.mobile.lineHeight')};
  text-align: ${theme('contentHeader.heading.mobile.textAlign')};
  margin: ${theme('contentHeader.heading.mobile.margin')};

  ${(p) => p.theme.media.min.sm`
    text-align: ${theme('contentHeader.heading.desktop.textAlign')};
    line-height:  ${theme('contentHeader.heading.desktop.lineHeight')};
    font-size: ${theme('contentHeader.heading.desktop.fontSize')};
    letter-spacing: ${theme('contentHeader.heading.desktop.letterSpacing')};
    font-weight: ${theme('contentHeader.heading.desktop.fontWeight')};
    margin: ${theme('contentHeader.heading.desktop.margin')};
  `};
`
const SubTitle = styled.div`
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('contentHeader.subtitle.fontSize')};
  display: ${theme('contentHeader.subtitle.display')};
  margin-top: ${theme('contentHeader.subtitle.marginTop')};
  margin-right: ${theme('contentHeader.subtitle.marginRight')};
  margin-bottom: ${theme('contentHeader.subtitle.marginBottom')};
  margin-left: ${theme('contentHeader.subtitle.marginLeft')};
  text-align: center;
  max-width: ${theme('contentHeader.subtitle.maxWidth')};
  color: ${theme('contentHeader.subtitle.color')};

  ${(props) => props.theme.media.min.sm`
    margin-bottom: ${theme('contentHeader.subtitle.desktop.marginBottom')};
  `}
  strong {
    font-weight: bold;
  }
`

const SubTitleDesktop = styled.span`
  display: none;
  ${(props) => props.theme.media.min.sm`
      display:block;      
      font-size: ${theme('typography.paragraph.fontSizeLarge')};
  `}
`

const SubTitleMobile = styled.span`
  display: block;
  max-width: 290px;
  margin: 0px auto;
  font-size: ${theme('typography.paragraph.fontSize')};

  ${(props) => props.theme.media.min.sm`
      display:none;
  `}
`

const Titles = ({ pageHeading, preambleDesktop, preambleMobile }) => (
  <>
    {pageHeading && <Title>{pageHeading}</Title>}
    {(preambleDesktop || preambleMobile) && (
      <SubTitle>
        {preambleDesktop && (
          <SubTitleDesktop
            dangerouslySetInnerHTML={{
              __html: preambleDesktop,
            }}
          />
        )}
        {preambleMobile && (
          <SubTitleMobile
            dangerouslySetInnerHTML={{
              __html: preambleMobile,
            }}
          />
        )}
      </SubTitle>
    )}
  </>
)

export default Titles
