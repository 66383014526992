import { createGlobalStyle } from 'styled-components'
import { switchProp, ifProp } from 'styled-tools'
import { marbodalFonts } from '../fonts'
import { basePixelSize } from './baseTheme'

const gutterWidth = '15px'

const GlobalStyles = createGlobalStyle`
  ${marbodalFonts};
  :root {
    font: 400 ${basePixelSize}px/1.43 "Ginger", "Georgia", serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Ginger", "Georgia", serif;
  }
`
const colors = {
  gold: '#C4A476',
  blue: '#002554',
  red: '#9b364a',
  black: '#000',
  darkGrey: '#333',
  grey: '#f4f2f0',
  white: '#FFF',
}

const breakpoints = {
  xxs: 0,
  xs: 540,
  sm: 720,
  md: 960,
  lg: 1140,
  xl: 1600,
  xxl: 2560,
}

const contextualColors = {
  primary: colors.black,
  secondary: colors.black,
  link: colors.blue,
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.grey,
  lightboxBackground: colors.white,
  overlayText: colors.darkGrey,
  overlayButton: colors.blue,
  iconColor: colors.blue,
}

const baseTypography = {
  fontFamily: '"Ginger", "Georgia", serif',
  titleFontFamily: '"Ginger", "Georgia", serif',
  inputFontFamily: '"Ginger", "Georgia", serif',
}

const typography = {
  imagePageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 700,
    fontSize: ifProp('small', '16px', '22px'),
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '50px', '63px'),
    lineHeightDesktop: ifProp('small', 1, 1.12),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'uppercase',
    textShadow: '0 0 30px rgba(0, 0, 0, 0.43)',
    letterSpacing: '2px',
  },
  pageTitle: {
    fontFamily: baseTypography.titleFontFamily,
    fontWeight: 700,
    fontSize: ifProp('small', '40px', '60px'),
    fontStyle: 'normal',
    lineHeight: ifProp('small', '44px', '64px'),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    color: colors.darkGrey,
  },
  headline: {
    fontFamily: baseTypography.titleFontFamily,
    fontWeight: 700,
    fontSize: '18px',
    fontStyle: 'normal',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.375,
    marginTop: '10px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: colors.darkGrey,
  },
  subHeadline: {
    fontFamily: baseTypography.titleFontFamily,
    fontWeight: 700,
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.375,
    marginTop: '10px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: colors.darkGrey,
  },
  title: {
    fontFamily: baseTypography.titleFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.428,
    fontSizeDesktop: '16px',
    lineHeightDesktop: 1.428,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
  },
  preamble: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '22px',
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.44,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    color: colors.darkGrey,
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: switchProp('size', {
      small: '14px',
      large: '18px',
      default: '16px',
    }),
    lineHeight: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    fontSizeDesktop: switchProp('size', {
      small: '14px',
      large: '18px',
      default: '16px',
    }),
    lineHeightDesktop: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: 0,
    color: colors.darkGrey,
  },
  link: {
    color: contextualColors.link,
    textDecoration: 'underline',
  },
}

const tooltip = {
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.blue,
  },
}

const forms = {
  colors: {
    selected: colors.blue,
  },
}

const button = {
  borderRadius: 0,
  borderWidth: '0 0 1px 0',
  borderLeftPosition: '15%',
  borderRightPosition: '15%',
  fontFamily: baseTypography.fontFamily,
  fontSize: '13px',
  fontWeight: '400',
  textTransform: 'uppercase',
  letterSpacing: '1.5px',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.blue,
    secondaryColor: colors.white,
    secondaryBackground: colors.gold,
    successColor: colors.white,
    successBackground: colors.blue,
  },
  margin: '0',
  padding: switchProp('size', {
    small: ifProp({ kind: 'inverted' }, `10px 0px`, `10px 40px`),
    normal: ifProp({ kind: 'inverted' }, `10px 0px`, `15px 45px`),
    large: ifProp({ kind: 'inverted' }, `10px 0px`, `20px 50px`),
  }),
  siblingMargin: gutterWidth,
}

const theme = {
  basePixelSize,
  GlobalStyles,
  breakpoints,
  typography,
  tooltip,
  button,
  forms,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
