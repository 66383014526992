import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'

class RetryButton extends Component {
  constructor(props) {
    super(props)
    this.state = { retryable: false }
    this.mounted = false
    this.onComplete = this.onComplete.bind(this)
    this.onStatusChange = this.onStatusChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    this.props.uploader.on('complete', this.onComplete)
    this.props.uploader.on('statusChange', this.onStatusChange)
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.uploader.off('complete', this.onComplete)
    this.props.uploader.off('statusChange', this.onStatusChange)
  }

  isRetryForbidden(response) {
    const { options } = this.props.uploader
    const preventRetryResponseProperty =
      options.retry != null &&
      options.retry.preventRetryResponseProperty != null
        ? options.retry.preventRetryResponseProperty
        : 'preventRetry'

    return !!response[preventRetryResponseProperty]
  }

  onComplete(id, name, response) {
    if (this.mounted && id === this.props.id) {
      this.setState((state) => {
        const retryForbidden = this.isRetryForbidden(response)

        if (!response.success && !retryForbidden && !state.retryable) {
          return { retryable: true }
        }

        if (response.success && state.retryable) {
          return { retryable: false }
        }

        if (retryForbidden && state.retryable) {
          this.props.uploader.off('complete', this.onComplete)
          this.props.uploader.off('statusChange', this.onStatusChange)

          return { retryable: false }
        }

        return null
      })
    }
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (
      this.mounted &&
      id === this.props.id &&
      newStatus === qq.status.UPLOAD_RETRYING
    ) {
      this.setState((state) => {
        if (state.retryable) {
          return { retryable: false }
        }

        return null
      })
    }
  }

  onClick() {
    this.props.uploader.methods.retry(this.props.id)
  }

  render() {
    const { retryable } = this.state
    const {
      id,
      children,
      className,
      onlyRenderIfRetryable,
      uploader,
      ...props
    } = this.props

    if (retryable || !onlyRenderIfRetryable) {
      return (
        <button
          {...props}
          type="button"
          className={className}
          disabled={!retryable}
          onClick={retryable ? this.onClick : undefined}
        >
          {children}
        </button>
      )
    }

    return null
  }
}

export default RetryButton
