import { withHandlers } from 'recompose'
import { withFormik } from 'formik'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import features from '../features'
import settings from '../settings'
import { clearState, getState } from '../../../utils/state'
import {
  validateAddress,
  validateEmail,
  validateEmailDomain,
  validateName,
  validatePostalCode,
  validatePhoneNumber,
} from '../../../utils/validation'
import { findServiceData, getDisplayData } from '../../containers/bada/content'
import {
  CUSTOMER_FORM,
  isSimplyBookMe,
  SIMPLY_BOOK_ME,
} from '../../containers/bada/reducer/Wizard'
import { isEmbedded } from '../../containers/bada/utils'
import { countryCodes } from '../../../../config/country'
import appInsights, {
  SeverityLevel,
} from '../../components/logging/appInsights'

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  comments: '',
  address: '',
  zipcode: '',
  city: '',
  newsletterConsent: false,
}

let requiredFields = ['name', 'email', 'phone']

const getTrimmedValues = (values) => {
  const trimmedValues = { ...values }
  Object.entries(trimmedValues).forEach(([key, value]) => {
    if (typeof value === 'string') {
      trimmedValues[key] = value.trim()
    }
  })
  return trimmedValues
}

const parsePhone = (phoneInput, brandCountry) => {
  const sanitizedInput = phoneInput.replace(/[^0-9+()]/g, '')
  const parsed = parsePhoneNumberFromString(sanitizedInput, brandCountry)
  return parsed
}

const bookingForm = withFormik({
  mapPropsToValues: ({ values, auth: { profile, status } }) => {
    if (values == null) {
      return { ...defaultValues }
    }

    const isSignedInCustomer =
      features.useLoggedInCustomerData &&
      status === 'authenticated' &&
      profile &&
      !profile.roles?.includes('staff')

    const vals = {
      name: values.name || '',
      email: isSignedInCustomer ? profile.username : values.email || '',

      phone: values.phone || '',
      address: values.address || '',
      zipcode: values.zipcode || '',
      city: values.city || '',
      comments: values.comments || '',
      newsletterConsent: values.newsletterConsent || false,
      usePrepform: values.usePrepform || false,
    }

    return vals
  },
  validate: async (values, props) => {
    const errors = {}

    const displayData = getDisplayData(props.pageContent)
    const serviceData = findServiceData(displayData, props.selectedService)

    if (serviceData.showAddressField || features.requireCustomerAddress) {
      requiredFields.push('address')
      requiredFields.push('zipcode')
      requiredFields.push('city')
    } else {
      requiredFields = requiredFields.filter(
        (a) => a !== 'address' && a !== 'zipcode' && a !== 'city'
      )
    }

    requiredFields.forEach((val) => {
      if (!values[val].trim()) {
        errors[val] = 'required'
      }
    })

    if (values.name && !validateName(values.name)) {
      errors.name = 'name'
    }

    if (values.email) {
      const validEmail = validateEmail(values.email)
      if (validEmail) {
        const validEmailDomain = await validateEmailDomain(values.email)
        if (!validEmailDomain) {
          errors.email = 'email'
        }
      } else {
        errors.email = 'email'
      }
    }

    if (
      values.phone &&
      !validatePhoneNumber(values.phone, props.brandCountry)
    ) {
      errors.phone = 'invalid'
    }

    if (serviceData.showAddressField || features.requireCustomerAddress) {
      if (values.address && !validateAddress(values.address)) {
        errors.address = 'address'
      }

      if (
        values.zipcode &&
        !validatePostalCode(values.zipcode, props.brandCountry)
      ) {
        errors.zipcode = 'invalid'
      }
    }

    return errors
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    let id = null
    const trimmedValues = getTrimmedValues(values)
    const trackingCookieMatch = `; ${document.cookie}`.match(
      '; _mkto_trk=([^;]+)'
    )
    const trackingCookie = trackingCookieMatch ? trackingCookieMatch[1] : null

    const affiliateCookieMatch = `; ${document.cookie}`.match(
      '; cust-utm-term=([^;]+)'
    )
    const affiliateTag = affiliateCookieMatch ? affiliateCookieMatch[1] : null

    const sourceCookieMatch = `; ${document.cookie}`.match(
      '; cust-utm-source=([^;]+)'
    )
    const utmSource = sourceCookieMatch ? sourceCookieMatch[1] : null

    const transactionIdCookieMatch = `; ${document.cookie}`.match(
      '; cust-transaction-id=([^;]+)'
    )
    const transactionId = transactionIdCookieMatch
      ? transactionIdCookieMatch[1]
      : null

    let bookedByDesigner = false
    let userIdentifier = null
    let qdpDesign = null
    if (
      props.auth &&
      props.auth.status &&
      props.auth.status === 'authenticated' &&
      props.auth.profile &&
      props.auth.profile.username
    ) {
      userIdentifier = props.auth.profile.username
      bookedByDesigner = props.auth.profile.roles?.includes('staff')

      const qdpData = JSON.parse(window.localStorage.getItem('qdp-form-data'))
      if (qdpData) {
        qdpDesign = {
          collection: qdpData.Collection,
          name: qdpData.Name,
          roomId: qdpData.RoomID,
          range: qdpData.Range,
          worktop: qdpData.Worktop,
          appliances: qdpData.Appliances,
          price: parseInt(qdpData.Price, 2),
          userId: qdpData.UserID,
          origin: qdpData.Origin,
          designLink: qdpData.designLink,
          questionnaire: qdpData.Questionnaire,
        }
      }
    }

    const uiState = getState(settings.brand)
    let address = trimmedValues.address
    let address2 = trimmedValues.address2
    let address3 = trimmedValues.address3

    let postalCode = trimmedValues.zipcode || null
    let city = trimmedValues.city || null
    let country = trimmedValues.country || null

    if (uiState && uiState.customerAddress) {
      address = uiState.customerAddress.address
      address2 = uiState.customerAddress.address2
      address3 = uiState.customerAddress.address3
      postalCode = uiState.customerAddress.postalCode
      city = uiState.customerAddress.city
      country = uiState.customerAddress.country
    }

    if (
      postalCode &&
      props.brandCountry === countryCodes().sweden &&
      postalCode.length === 5
    ) {
      postalCode = `${postalCode.substring(0, 3)} ${postalCode.substring(3)}`
    }

    if (!country) {
      country = props.brandCountry
    }

    const isSlotSelected = !!(props.selectedSlot && props.selectedSlot.from)

    let bookingSystem = ''
    if (
      isSimplyBookMe(props.selectedStore) &&
      isSlotSelected &&
      !uiState.overrideMeetingType
    ) {
      bookingSystem = SIMPLY_BOOK_ME
    } else {
      bookingSystem = CUSTOMER_FORM
    }

    let recaptchaToken = null
    if (features.useRecaptcha) {
      const executeRecaptcha = props.googleReCaptchaProps?.executeRecaptcha
      if (typeof executeRecaptcha === 'function') {
        recaptchaToken = await executeRecaptcha('submit')
        if (!recaptchaToken) {
          appInsights.trackTrace({
            message: 'Recaptcha token is null.',
            severityLevel: SeverityLevel.Warning,
          })
        }
      } else {
        appInsights.trackTrace({
          message: 'Failed to submit recaptcha because it is not initialized.',
          severityLevel: SeverityLevel.Warning,
        })
      }
    }

    const input = {
      brand: settings.brand,
      slotSelected: !!isSlotSelected,
      simplyBookMeStoreId: isSimplyBookMe(props.selectedStore)
        ? props.selectedStore.simplyBookMeId
        : null,
      subsystem: isSimplyBookMe(props.selectedStore)
        ? props.selectedStore.simplyBookMeSubsystem
        : null,
      service: props.selectedService.id, // e.g. "video"/"store"
      date: isSlotSelected ? props.selectedSlot.from.substring(0, 10) : null,
      time: isSlotSelected ? props.selectedSlot.from.substring(11) : null,
      email: trimmedValues.email,
      name: trimmedValues.name,
      address,
      address2,
      address3,
      phone: parsePhone(trimmedValues.phone, props.brandCountry).number,
      postCode: postalCode,
      city,
      country,
      comments: trimmedValues.comments,
      newsletterConsent: trimmedValues.newsletterConsent,
      trackingCookie,
      serviceId: props.selectedService.serviceId,
      userIdentifier,
      bookedByDesigner,
      qdpDesign,
      bookingSource: isEmbedded() ? 'MyProject' : 'Web',
      bookingSystem: bookingSystem || '',
      storeId: props.selectedStore.id,
      recaptchaToken,
      affiliateTag,
      transactionId,
      utmSource,
    }

    props.updateUiState({
      values: trimmedValues,
      staff: props.staff,
      customerFormInput: input,
    })

    if (
      !features.createAppointmentAfterPrepForm.includes(
        props.selectedService.id
      ) ||
      !values.usePrepform
    ) {
      return props
        .mutate({
          variables: { input },
        })
        .then((response) => {
          const { data } = response

          id = data.createAppointment.appointmentId

          props.updateUiState({
            booking: { loading: false },
            bookingCompleted: true,
            preSelectedMeetingTypeId: null,
            appointmentId: id,
          })
          clearState(settings.brand)
          window.localStorage.removeItem('qdp-form-data')
          window.localStorage.removeItem('herculesBadaBlock')
        })
        .catch((err) => {
          /* eslint-disable no-console */
          console.error(`Error occurred during createAppointment: ${err}`)
          appInsights.trackException({
            exception: err,
            severityLevel: SeverityLevel.Error,
          })
          props.updateUiState({
            booking: { error: { err } },
            bookingCompleted: true,
          })
        })
        .finally(() => {
          props.addHistory(id, props.selectedStore.id)
          setSubmitting(false)
          window.scrollTo({
            left: 0,
            top: 0,
          })
        })
    }
    // Go directly to PrepForm, do not submit
    props.updateUiState({
      booking: { loading: false },
      bookingCompleted: true,
      preSelectedMeetingTypeId: null,
      appointmentId: null, // PrepForm will submit appointment if appointmentId = null
    })
    window.scrollTo({
      left: 0,
      top: 0,
    })
    return Promise.resolve()
  },
})

const formHandlers = withHandlers({
  handleTextChange:
    ({ setFieldValue }) =>
    (event) =>
      setFieldValue(event.target.name || event.target.id, event.target.value),
  handleCheckboxChange:
    ({ setFieldValue }) =>
    (event) =>
      setFieldValue(event.target.name || event.target.id, event.target.checked),
})

export { bookingForm, formHandlers }
