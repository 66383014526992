import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'
import { switchProp, ifProp } from 'styled-tools'
import { hthgoFonts } from '../fonts'
import { basePixelSize } from './baseTheme'

const colors = {
  charcoal: '#333',
  black: '#000',
  blue: '#37559C',
  beige: '#F1F0EF',
  green: '#57CBA1',
  borderGrey: '#D9D9D9',
  darkGrey: '#4F4F4F',
  fbBlue: '#37559C',
  grey: '#E2DCD6',
  lightGrey: '#F2EFEB',
  red: '#E2000F',
  white: '#fff',
}

const contextualColors = {
  primary: colors.charcoal,
  secondary: colors.charcoal,
  backgroundPrimary: colors.white,
  backgroundSecondary: colors.lightGrey,
  backgroundDark: colors.black,
  tooltip: colors.charcoal,
  boxText: colors.charcoal,
  labelText: colors.charcoal,
  wishlistIcon: colors.charcoal,
  overlayClose: colors.charcoal,
  overlayText: colors.charcoal,
  overlayButton: colors.charcoal,
  checkboxIcon: colors.white,
  inputColor: colors.charcoal,
  link: colors.charcoal,
  lightboxBackdrop: transparentize(0.5, colors.black),
}

const baseTypography = {
  fontFamily: '"Clan", Arial, sans-serif',
  lineHeight: 1.5,
  marginBottom: '21px',
}

const typography = {
  imagePageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 700,
    fontSize: ifProp('small', '16px', '22px'),
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '50px', '63px'),
    lineHeightDesktop: ifProp('small', 1, 1.12),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    textShadow: '0 0 30px rgba(0, 0, 0, 0.43)',
  },
  pageTitle: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 700,
    fontSize: ifProp('small', '16px', '22px'),
    lineHeight: ifProp('small', 1.18, 1.18),
    fontSizeDesktop: ifProp('small', '24px', '32px'),
    lineHeightDesktop: ifProp('small', 1.125, 1.125),
    marginTop: 0,
    marginBottom: '15px',
    textTransform: 'none',
  },
  headline: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 700,
    fontSize: ifProp('small', '13px', '16px'),
    lineHeight: ifProp('small', 1.3, 1.375),
    fontSizeDesktop: ifProp('small', '15px', '19px'),
    lineHeightDesktop: ifProp('small', 1.33, 1.21),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
    letterSpacing: '0',
  },
  subHeadline: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.375,
    fontSizeDesktop: '16px',
    lineHeightDesktop: 1.375,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
  },
  title: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.428,
    fontSizeDesktop: '14px',
    lineHeightDesktop: 1.428,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
  },
  preamble: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.375,
    fontSizeDesktop: '18px',
    lineHeightDesktop: 1.44,
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
  },
  paragraph: {
    fontFamily: baseTypography.fontFamily,
    fontWeight: 400,
    fontSize: switchProp('size', {
      small: '12px',
      large: '16px',
      default: '14px',
    }),
    lineHeight: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    fontSizeDesktop: switchProp('size', {
      small: '12px',
      large: '16px',
      default: '14px',
    }),
    lineHeightDesktop: switchProp('size', {
      small: 1.5,
      large: 1.375,
      default: 1.428,
    }),
    marginTop: 0,
    marginBottom: '30px',
    textTransform: 'none',
  },
  link: {
    color: contextualColors.link,
    textDecoration: 'underline',
  },
}

const button = {
  borderRadius: 0,
  fontFamily: baseTypography.fontFamily,
  fontSize: '14px',
  fontWeight: '600',
  textTransform: 'none',
  colors: {
    primaryColor: colors.white,
    primaryBackground: colors.charcoal,
    secondaryColor: colors.white,
    secondaryBackground: colors.black,
  },
}

const forms = {
  borderRadius: 0,
  height: '42px',
  padding: '12px',
  fontFamily: baseTypography.fontFamily,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: 1,
  fontSizeDesktop: '14px',
  lineHeightDesktop: 1,
  marginBottom: '28px',
  colors: {
    selected: colors.black,
  },
}

const icons = {
  colors: {
    primaryColor: contextualColors.primary,
    secondaryColor: colors.white,
  },
}

const overlay = {
  lightbox: {
    backdrop: contextualColors.lightboxBackdrop,
    loader: colors.black,
  },
}

const tooltip = {
  colors: {
    primaryColor: colors.black,
    primaryBackground: colors.lightGrey,
  },
}

const GlobalStyles = createGlobalStyle`
  ${hthgoFonts}
  :root {
    font: 400 ${basePixelSize}px/1.43 "Clan", Arial, sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "Clan", Arial, sans-serif;
  }
`

const theme = {
  basePixelSize,
  GlobalStyles,
  forms,
  button,
  typography,
  overlay,
  tooltip,
  icons,
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
