import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import QuestionBlock from './QuestionBlock'

const QuestionBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme('colors.link')};
  font-size: 14px;
  @media (min-width: 720px) {
    font-size: 16px;
  }
  margin-top: 30px;
`

const selectOption = (
  onChange,
  updateUiState,
  prepData,
  { name },
  { questionText, optionType, otherAnswerText, inputValue }
) => {
  const questions = prepData.questions || []
  const initQuestion = {
    question: questionText.value,
    answers: [
      optionType.value === 'textarea' ||
      optionType.value.startsWith('form') ||
      (optionType.value === 'slider' && inputValue !== undefined)
        ? inputValue
        : name,
    ].filter(Boolean),
  }

  const index = questions.findIndex((p) => p.question === questionText.value)
  if (index === -1) {
    questions.push(initQuestion)
    onChange()
    return updateUiState({ prepData: { ...prepData, questions } })
  }

  const question = questions[index]

  if (!question) {
    onChange()
    return updateUiState({ prepData: { ...prepData, questions } })
  }

  if (otherAnswerText && name === otherAnswerText.value) {
    if (question.answers.find((a) => a === otherAnswerText.value)) {
      question.answers = []
    } else {
      question.answers = [name]
    }
    onChange()
    return updateUiState({ prepData: { ...prepData, questions } })
  }

  switch (optionType.value) {
    case 'single':
      if (question.answers.find((a) => a === name)) {
        question.answers = []
      } else {
        question.answers = [name]
      }
      break
    case 'multi':
      if (question.answers.find((a) => a === name)) {
        question.answers = question.answers.filter((a) => a !== name)
      } else {
        question.answers = [...question.answers, name]
      }
      break
    case 'form':
    case 'form-checkbox':
      question.answers = inputValue ? [inputValue] : []
      break
    case 'slider':
    case 'textarea':
      question.answers = [inputValue]
      break
    default:
      break
  }

  onChange()
  return updateUiState({ prepData: { ...prepData, questions } })
}

/**
 * @param {{
 *  questions: any[],
 *  updateUiState: (params: any[]) => any,
 *  prepData: any,
 *  onChange: (loading: boolean) => void
 *  serviceContent: any
 * }} params
 */
const QuickQuestions = ({
  questions,
  updateUiState,
  prepData,
  onChange,
  serviceContent,
  useSplash,
}) => {
  /**
   * loading: { [key: string]: boolean }
   */
  const [loading, setLoading] = useState({})

  useEffect(() => {
    const isLoading = !!Object.keys(loading).find((i) => loading[i] === true)
    onChange(isLoading)
  }, [loading])

  return (
    <QuestionBlockWrapper data-track="questions">
      {questions &&
        questions.map((question, index) => {
          const mutatedQuestion = {
            ...question,
            sortOrder: questions.findIndex(
              (e) => e.questionText.value === question.questionText.value
            ),
          }

          return (
            <QuestionBlock
              key={
                mutatedQuestion.questionText &&
                mutatedQuestion.questionText.value
              }
              q={mutatedQuestion}
              updateUiState={updateUiState}
              selectOption={selectOption}
              prepData={prepData}
              onChange={(emittedValue) => {
                if (emittedValue && emittedValue.pending) {
                  const isLoading = !!emittedValue.pending.length
                  const componentLoading = {}
                  componentLoading[index] = isLoading
                  setLoading((prevState) => ({
                    ...prevState,
                    ...componentLoading,
                  }))
                } else if (
                  Object.values(loading).filter((v) => v).length === 0
                ) {
                  onChange()
                }
              }}
              serviceContent={serviceContent}
              useSplash={useSplash}
            />
          )
        })}
    </QuestionBlockWrapper>
  )
}

export default QuickQuestions
