import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { ApolloLink, ApolloClient, HttpLink } from '@apollo/client'
import fetch from 'isomorphic-fetch'
import { InMemoryCache } from '@apollo/client/cache'
import * as globals from '../sharedState/globals'
import settings from '../settings'

import createAuthLink from './createAuthLink'
import errorLink from './errorLink'

// Generated by scripts/extractGraphQLUnionTypes
import possibleTypes from './fragmentTypes.json'
import createRefreshLink from './refreshLink'

const customFetch = (uri, options) => {
  const operations = JSON.parse(options.body)

  // The likelyhood of requests coming from `hercules` being batched together
  // with other requests is slim to none. The following heuristics should be
  // sufficient for our needs.
  const isHerculesRequest = Array.isArray(operations)
    ? operations.some((operation) =>
        operation.operationName.startsWith('Hercules')
      )
    : operations.operationName.startsWith('Hercules')

  if (isHerculesRequest) {
    const newUrl = new URL(uri)
    const searchParams = new URLSearchParams(newUrl.search)

    searchParams.set('client', 'hercules')
    newUrl.search = searchParams.toString()

    return fetch(newUrl.href, options)
  }

  return fetch(uri, options)
}

const createClient = (options) => {
  if (globals.nobia != null && globals.nobia.sharedClient != null) {
    return globals.nobia.sharedClient
  }

  const { terminatingLink } = options
  const { clientOptions = {}, extraLinks = [], cacheOptions = {} } = options

  const linksMiddleware = [
    ...extraLinks,
    createAuthLink(options.store),
    errorLink,
  ]

  if (settings.useRefreshLink) {
    linksMiddleware.push(createRefreshLink(options.store))
  }

  if (terminatingLink) {
    linksMiddleware.push(terminatingLink)
  } else if (!settings.disableApolloBatching) {
    linksMiddleware.push(
      new BatchHttpLink({
        uri: options.uri,
        fetch: customFetch,
        credentials: settings.clientCredentials,
        batchInterval: 10,
        batchKey: (operation) =>
          operation.operationName.startsWith('Hercules') ? 'hercules' : 'omni',
      })
    )
  } else {
    linksMiddleware.push(
      new HttpLink({
        uri: options.uri,
        fetch: customFetch,
        credentials: settings.clientCredentials,
      })
    )
  }

  const link = ApolloLink.from(linksMiddleware)

  const ssrCache =
    // eslint-disable-next-line no-underscore-dangle
    (typeof window !== 'undefined' && window.__APOLLO_STATE__) || {}
  const cache = new InMemoryCache({ possibleTypes, ...cacheOptions }).restore(
    ssrCache
  )

  const client = new ApolloClient({
    connectToDevTools: true,
    queryDeduplication: true,
    ssrMode: typeof window === 'undefined',
    // ssrForceFetchDelay: 100,
    link,
    cache,
    ...clientOptions,
  })

  if (globals.nobia != null) {
    globals.nobia.sharedClient = client
  }

  return client
}

export default createClient
