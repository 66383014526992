import React from 'react'

const ArrowBackIcon = ({ width, height, color }) => (
  <svg width={width} height={height} color={color} viewBox="0 0 25 14">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        transform="translate(12.651421, 6.871258) scale(-1, 1) translate(-12.651421, -6.871258) translate(1.000000, 1.000000)"
        stroke={color}
        strokeWidth="1.5"
      >
        <line x1="0.506271838" y1="5.6737425" x2="22.7822327" y2="5.6737425" />
        <line
          x1="17.5032116"
          y1="0.662133358"
          x2="22.7965705"
          y2="5.56899021"
        />
        <line x1="22.7945928" y1="5.66725342" x2="17.5155718" y2="11.5200327" />
      </g>
    </g>
  </svg>
)

export default ArrowBackIcon
