import get from 'lodash/get'

const media =
  (condition, size, ...args) =>
  (props) => {
    const conditionValue =
      typeof condition === 'function' ? condition(props) : condition
    const queryGroup = get(props, `theme.media.${conditionValue}`)
    const hasQueryGroup = queryGroup != null

    if (hasQueryGroup) {
      const sizeValue = typeof size === 'function' ? size(props) : size
      const query = queryGroup[sizeValue]
      const hasQuery = query != null

      if (hasQuery) {
        return query(
          ...args.map((arg) => (typeof arg === 'function' ? arg(props) : args))
        )
      }
    }

    return undefined
  }

const mediaMin = (size, ...args) => media('min', size, ...args)

const mediaMax = (size, ...args) => media('max', size, ...args)

const mediaOnly = (size, ...args) => media('only', size, ...args)

export { media, mediaMin, mediaMax, mediaOnly }
