import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { scrollTo } from '../utils'
import { StepMeetingType } from './Components'
import { badaPropTypes, badaDefaultProps } from '../../../../types'
import tracker from '../../../../utils/tracker'

class MeetingTypeStep extends Component {
  render() {
    const {
      props: {
        pageContent,
        appointmentType,
        wizard: {
          step: { two },
        },
        selectedStore,
        selectedService,
        updateUiState,
        history,
      },
    } = this.props
    if (!selectedStore) return null
    return (
      <>
        <StepMeetingType
          show={two}
          props={{
            pageContent: pageContent && pageContent.page,
            appointmentType,
            selectedService,
            selectedStore,
            onChange: (state) => {
              const isServiceChanged =
                parseInt(state.preSelectedMeetingTypeId, 10) !==
                parseInt(state.selectedService?.serviceId, 10)

              if (isServiceChanged) {
                history.push({
                  ...window.location.pathname,
                  ...state,
                  search: window.location.search.includes('fake')
                    ? '?fake=true'
                    : null,
                  hash: 'meeting',
                  bookingCompleted: false,
                })
                updateUiState({
                  ...state,
                  selectedStore,
                  preSelectedMeetingTypeId: null,
                })
              } else {
                history.push(`${window.location.search}#meeting`, {
                  ...state,
                  bookingCompleted: false,
                })
                updateUiState({
                  ...state,
                  selectedStore,
                })
              }
              scrollTo(this.ref)
              tracker.trackClick(
                'BADA 2.0 meeting type',
                `multitype ${state.selectedService.id} - ${selectedStore.name}`
              )
            },
          }}
        />
        <div
          ref={(ref) => {
            this.ref = ref
          }}
        />
      </>
    )
  }
}

MeetingTypeStep.propTypes = {
  ...badaPropTypes,
}

MeetingTypeStep.badaDefaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(withApollo)

export default enhance(MeetingTypeStep)
