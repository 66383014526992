/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react'
import { compose, withStateHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { withAuth } from '../../components/auth'
import ErrorBoundary from '../../components/errorBoundary'
import features from '../../components/features'
import settings from '../../components/settings'
import { Container as ContainerUI } from '../../components/grid'
import withHelmet from '../../components/with-helmet/withHelmet'
import Link from '../../components/typography/link'
import Preamble from '../../components/typography/preamble'
import PageTitle from '../../components/typography/pageTitle'
import tracker from '../../../utils/tracker'
import { badaDefaultProps } from '../../../types'
import { clearState, saveState } from '../../../utils/state'
import Alert from '../../components/alert'
import ContentHeader from '../../components/content-header'
import Header from '../../components/header'
import Loader from '../../components/loader/Loader'
import {
  fetchPageContent,
  fetchStores,
  fetchStoreWithDistance,
  retrieveAddressQuery,
  searchAddressQuery,
  // eslint-disable-next-line no-unused-vars
  getBooking, // inits the query, necessary as long as HOC injection is used for this resource
} from './actions'
import ConfirmationPage from './pages/ConfirmationPage'
import LandingPage from './pages/landingPage/LandingPage'
import PrepFormPage from './pages/PrepFormPage'
import stateReducer from './reducer'
import { isEmbedded } from './utils'
import { brand } from '../../../../config/baseSettings'
import useTranslation from '../../../utils/translation'
import { appBaseUrl } from '../../../utils/appBaseUrl'

const Container = styled(ContainerUI)`
  width: 100%;
  padding-bottom: 300px;
  max-width: ${theme('grid.containerMaxWidth')};
  display: flex;
  flex-direction: column;
`

const StyledContainer = styled.div`
  margin: 30px 0px 30px 0px;
  text-align: center;
  ${(props) => props.theme.media.min.sm`
    margin: 60px 0px 30px 0px;
  `}
`

const ErrorTitle = styled(PageTitle)`
  color: #dc4d4d;
`

const Bada = (props) => {
  const [currentHash, setCurrentHash] = useState(null)
  const embedded = isEmbedded()

  const t = useTranslation('app')

  useEffect(() => {
    const {
      history,
      updateUiState,
      visitorId,
      appointmentId,
      selectedAddress,
      auth,
    } = props

    if (appointmentId) {
      clearState(brand)
      updateUiState({
        returningVisitor: true,
        appointmentFromQuery: true,
        appointmentId,
        bookingCompleted: true,
        prepCompleted: false,
        booking: {
          bookingCompleted: true,
          loading: false,
        },
      })
    }

    const meetingTypeIdFromQuery = new URLSearchParams(
      window.location.search
    ).get('meetingtypeid')

    if (meetingTypeIdFromQuery) {
      updateUiState({ preSelectedMeetingTypeId: meetingTypeIdFromQuery })
    }

    const storeIdFromQuery = new URLSearchParams(window.location.search).get(
      'studioid'
    )

    if (storeIdFromQuery) {
      updateUiState({
        storeId: storeIdFromQuery,
        selectedService: null,
        selectedSlot: null,
      })
    }

    if (visitorId) updateUiState({ visitorId })

    let geolocationLatitude
    let geolocationLongitude

    function getSelectedAddress() {
      const url = `${settings.ipLocationAPI}`
      if (
        settings.preferIplocation ||
        !selectedAddress ||
        !selectedAddress.city
      ) {
        fetch(url)
          .then((response) => response.json())
          .then((location) => {
            let address = settings.fallbackAddress
            if (
              location &&
              location.city &&
              location.country_name &&
              location.zip
            ) {
              address = {
                city: location.city,
                countryName: location.country_name,
                postalCode: location.zip,
                latitude: geolocationLatitude || location.latitude,
                longitude: geolocationLongitude || location.longitude,
              }
            }
            updateUiState({ selectedAddress: address })
          })
          .catch(() =>
            updateUiState({ selectedAddress: settings.fallbackAddress })
          )
      }
    }

    const getCurrentGeoPositionSuccess = (position) => {
      geolocationLatitude = position.coords.latitude
      geolocationLongitude = position.coords.longitude
      getSelectedAddress()
    }

    function getCurrentGeoPositionError() {
      geolocationLatitude = null
      geolocationLongitude = null
      getSelectedAddress()
    }

    if (features.useBrowserGeolocation && 'geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        getCurrentGeoPositionSuccess,
        getCurrentGeoPositionError
      )
    } else {
      getSelectedAddress()
    }

    window.zeus = window.zeus || {}
    window.zeus.bada_api = {
      setState: updateUiState,
    }

    history.listen((newLocation, action) => {
      if (action === 'POP') {
        if (currentHash === '#details-submit' || currentHash === '#complete') {
          history.goForward()
        }
      } else if (action === 'PUSH') {
        setCurrentHash(newLocation.hash)
      }
    })

    tracker.trackInitVisitor('BADA 2.0 init visitor', visitorId, auth)

    return () => delete window.zeus.bada_api
  }, [])

  const { minimalHeader, showAlert } = features
  const {
    badaProps: { pageContent },
    bookingCompleted,
    wizard: { loading },
    booking,
  } = props

  const hasError = booking && !!booking.error

  const content = () => (
    <>
      {showAlert && <Alert wizard={props.wizard} />}
      {isEmbedded(true) === false && <LandingPage props={props} />}
      <PrepFormPage props={props} />
      <ConfirmationPage props={props} />
      {loading && <Loader duration={3} text={t('loader.text.content')} />}
    </>
  )

  const error = () => (
    <StyledContainer>
      <ErrorTitle>{t('error.title')}</ErrorTitle>
      <Preamble>
        <Link href={`${appBaseUrl}`}>Please try again &raquo;</Link>
      </Preamble>
    </StyledContainer>
  )

  return (
    <>
      <ErrorBoundary>
        {minimalHeader && !embedded && <Header />}
        {isEmbedded(true) === false && (
          <ContentHeader show={!bookingCompleted} content={pageContent} />
        )}
        <Container data-track="container">
          {hasError ? error() : content()}
        </Container>
      </ErrorBoundary>
    </>
  )
}

Bada.defaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(
  withHelmet(() => ({
    url: `${appBaseUrl}`,
  })),
  withStateHandlers(
    (props) => ({
      storeId: null,
      selectedAddress: null,
      searchAddressId: null,
      selectedStore: null,
      selectedService: null,
      selectedSlot: null,
      storesByAddressData: {},
      booking: null,
      bookingCompleted: false,
      customerForm: null,
      prepCompleted: false,
      prepData: {
        questions: [],
      },
      appointmentId: null,
      visitorId: null,
      ...props,
    }),
    {
      updateUiState: (props) => (newProp) => {
        const state = { ...props, ...newProp }
        setTimeout(() => saveState(state, settings.brand), 100)
        return state
      },
    }
  ),
  fetchPageContent,
  fetchStores,
  fetchStoreWithDistance,
  searchAddressQuery,
  retrieveAddressQuery,
  getBooking,
  stateReducer,
  withAuth
)

export default enhance(withRouter(Bada))
