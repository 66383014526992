import { css } from 'styled-components'
import { minBreakpoints, maxBreakpoints } from './breakpoints'

// Define only queries that spans between min-width and max-width
const mediaOnly = {
  xxs: (...args) => css`
    @media (max-width: ${maxBreakpoints.xs}px) {
      ${css(...args)};
    }
  `,
  xs: (...args) => css`
    @media (min-width: ${minBreakpoints.xs}px) and (max-width: ${maxBreakpoints.sm}px) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (min-width: ${minBreakpoints.sm}px) and (max-width: ${maxBreakpoints.md}px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: ${minBreakpoints.md}px) and (max-width: ${maxBreakpoints.lg}px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: ${minBreakpoints.lg}px) and (max-width: ${maxBreakpoints.xl}px) {
      ${css(...args)};
    }
  `,
  xl: (...args) => css`
    @media (min-width: ${minBreakpoints.xl}px) and (max-width: ${maxBreakpoints.xxl}px) {
      ${css(...args)};
    }
  `,
  xxl: (...args) => css`
    @media (min-width: ${minBreakpoints.xxl}px) {
      ${css(...args)};
    }
  `,
}

// Iterate through the sizes and create a media template for min-width queries
const mediaMin = Object.keys(minBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${minBreakpoints[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

// Iterate through the sizes and create a media template for max-width queries
const mediaMax = Object.keys(maxBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${maxBreakpoints[label]}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})

const mediaQueries = {
  only: mediaOnly,
  min: mediaMin,
  max: mediaMax,
}

export { mediaOnly, mediaMin, mediaMax }
export default mediaQueries
