import React, { Component } from 'react'
import { DragAndDrop } from 'fine-uploader/lib/dnd'

class DropzoneElement extends Component {
  constructor(props) {
    super(props)
    this.dropzone = null
    this.element = null
    this.onDropError = this.onDropError.bind(this)
    this.onProcessingDroppedFiles = this.onProcessingDroppedFiles.bind(this)
    this.onProcessingDroppedFilesComplete =
      this.onProcessingDroppedFilesComplete.bind(this)
  }

  componentDidMount() {
    this.registerDropzone()
  }

  componentDidUpdate() {
    this.registerDropzone()
  }

  componentWillUnmount() {
    if (this.dropzone != null) {
      this.dropzone.dispose()
    }
  }

  registerDropzone() {
    if (this.dropzone != null) {
      this.dropzone.dispose()
    }

    this.dropzone = new DragAndDrop({
      allowMultipleItems: this.props.multiple,
      callbacks: {
        dropError: this.onDropError,
        processingDroppedFiles: this.onProcessingDroppedFiles,
        processingDroppedFilesComplete: this.onProcessingDroppedFilesComplete,
      },
      classes: {
        dropActive: this.props.dropActiveClassName || '',
      },
      dropZoneElements: [this.props.element || this.element],
    })
  }

  onDropError(errorCode, errorRelatedData) {
    // eslint-disable-next-line no-console
    console.error(errorCode, errorRelatedData)

    if (this.props.onDropError != null) {
      this.props.onDropError(errorCode, errorRelatedData)
    }
  }

  onProcessingDroppedFiles() {
    if (this.props.onProcessingDroppedFiles != null) {
      this.props.onProcessingDroppedFiles()
    }
  }

  onProcessingDroppedFilesComplete(files, dropTarget) {
    this.props.uploader.methods.addFiles(files)

    if (this.props.onProcessingDroppedFilesComplete) {
      this.props.onProcessingDroppedFilesComplete(files, dropTarget)
    }
  }

  setRef(ref) {
    this.element = ref
  }

  render() {
    const {
      children,
      className,
      element,
      dropActiveClassName,
      multiple,
      onDropError,
      onProcessingDroppedFiles,
      onProcessingDroppedFilesComplete,
      uploader,
      ...props
    } = this.props

    return (
      <div {...props} className={className} ref={this.setRef}>
        {children}
      </div>
    )
  }
}

export default DropzoneElement
