import React from 'react'
import {
  StoresStep,
  MeetingTypeStep,
  BookingSlotStep,
  SubmitStep,
} from '../../wizard'
import settings from '../../../../components/settings'
import { badaDefaultProps, badaPropTypes } from '../../../../../types'
import { GoogleReCaptchaProvider } from '../../../../components/recaptcha'

const LandingPage = ({ props }) => (
  <GoogleReCaptchaProvider reCaptchaKey={settings.recaptcha?.key}>
    <StoresStep props={props} />
    <MeetingTypeStep props={props} />
    <BookingSlotStep props={props} />
    <SubmitStep props={props} />
  </GoogleReCaptchaProvider>
)

LandingPage.defaultProps = {
  ...badaDefaultProps,
}

LandingPage.propTypes = {
  ...badaPropTypes,
}

export default LandingPage
