import { theme } from 'styled-tools'

export default {
  colors: {
    default: '#001b33',
    darkGrey: '#555555',
    salmon: '#E6736E',
    accent: theme('colors.black'),
    slate: '#d5d5d5',
    darkSlate: '#c0c0c0',
    accentShadow: 'none',
    secondaryBackgroundColor: '#f7f5f1',
    activeBorder: theme('button.colors.primaryBackground'),
    error: '#C27171',
    termsOverlayBackground: '#fff',
  },
  box: {
    radius: '3px',
  },
  header: {
    backgroundColor: theme('colors.link'),
    border: '0',
    color: '#fff',
    shadow: 'none',
    height: '51px',
    heightDesktop: '59px',
    logoWidth: '155px',
    logoMargin: '10px 0 0',
    fontSize: '12px',
    fontWeight: 400,
    letterSpacing: '1.5px',
    arrowContainer: {
      paddingRight: '12px',
    },
  },
  contentHeader: {
    height: {
      desktop: '500px',
      mobile: '320px',
    },
    width: {
      maxWidth: '800px',
    },
    subtitle: {
      display: 'none',
      fontSize: '20px',
    },
    icons: {
      color: '#ffffff',
      desktop: {
        width: '25px',
        height: '25px',
      },
      mobile: {
        width: '15px',
        height: '15px',
      },
    },
    usps: {
      paddingLeft: '15px',
      color: '#ffffff',
      marginTop: '20px',
      mobile: {
        fontSize: '22px',
      },
      desktop: {
        fontSize: '22px',
      },
    },
    otherNeeds: {
      color: '#ffffff',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      desktop: {
        positionTop: '-64px',
        marginBottom: '-64px',
      },
      mobile: {
        positionTop: '-54px',
        marginBottom: '-54px',
      },
      textDecoration: 'underline',
      fontWeight: 'normal',
      fontSize: {
        mobile: '13px',
        desktop: '17px',
      },
      padding: {
        mobile: '17px',
        desktop: '20px',
      },
    },
    heading: {
      color: '#ffffff',
      desktop: {
        textAlign: 'left',
        lineHeight: '45px',
        fontSize: '40px',
      },
      mobile: {
        textAlign: 'center',
        lineHeight: '32px',
        fontSize: '26px',
      },
    },
    desktop: {
      height: '500px',
      alignItems: 'center',
    },
    mobile: {
      height: '400px',
      alignItems: 'flex-start',
      paddingBottom: '60px',
    },
  },
  stores: {
    headers: {
      marginTop: 0,
    },
    changeStoreLink: {
      fontSize: { desktop: '16px', mobile: '16px' },
      fontWeight: '700',
      textDecoration: 'none',
    },
    distance: {
      fontSize: '16px',
    },
  },
  storeList: {
    row: {
      marginBottom: '42px',
    },
    storeName: {
      fontSize: '16px',
      color: theme('colors.link'),
    },
    storeAddress: {
      fontWeight: theme('typography.paragraph.fontWeight'),
    },
    heading: {
      fontSize: '20px',
      color: theme('colors.accent'),
    },
  },
  tabs: {
    textTransform: 'none',
    padding: '10px 15px',
    color: {
      inactive: theme('colors.link'),
      bottomBorder: '#d7d7d7',
      activeBorder: theme('colors.accent'),
    },
    fontWeight: {
      active: '700',
      inactive: 'normal',
    },
    header: {
      link: {
        color: theme('colors.link'),
        borderBottom: 0,
        fontWeight: 'bold',
      },
    },
    fontSize: '16px',
    borderHeight: {
      active: '4px',
    },
  },
  button: {
    border: 0,
    blockButtonWidth: '100%',
    wideButtonWidth: '270px',
  },
  calendar: {
    heading: {
      fontSize: '14px',
    },
    paragraph: {
      fontSize: '16px',
    },
    subHeadline: {
      fontSize: '16px',
    },
    border: {
      color: '#e5e5e5',
    },
  },
  typography: {
    pageTitle: {
      color: theme('colors.darkGrey'),
    },
    headline: {
      fontSize: '22px',
      fontSizeDesktop: '22px',
      textTransform: 'uppercase',
      letterSpacing: 0,
      color: theme('colors.gold'),
    },
    subHeadline: {
      textTransform: 'uppercase',
      fontSize: '22px',
      fontSizeDesktop: '16px',
      letterSpacing: 0,
      color: theme('colors.darkGrey'),
    },
    paragraph: {
      color: theme('colors.darkGrey'),
      accentFontFamily: theme('typography.paragraph.fontFamily'),
      fontSize: '16px',
      fontSizeLarge: '20px',
      fontSizeLead: '20px',
    },
    preamble: {
      color: theme('colors.darkGrey'),
    },
    usp: {
      desktop: {
        fontSize: '22px',
      },
      mobile: {
        fontSize: '22px',
      },
    },
    storeAddress: {
      color: '#999999',
      fontWeight: theme('typography.paragraph.fontWeight'),
      paddingMobile: '12px',
    },
    storeAddressLong: {
      color: theme('colors.darkGrey'),
    },
    storeName: {
      fontSize: '16px',
      fontWeight: 700,
      color: theme('colors.link'),
      marginBottom: '4px',
      mobile: {
        fontSize: '16px',
      },
    },
    stores: {
      changeStoreLink: {
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
  },
  showMore: {
    color: 'inherit',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  meetingTypeSelection: {
    meetingTypeItem: {
      flexDirection: 'row',
      textAlign: 'center',
      padding: '0px 10px 0px 10px',
      display: 'flex',
    },
    margin: '0 0 20px 0',
  },
  meetingType: {
    iconsize: {
      default: '40px',
      videoMeeting: '45px',
    },
    name: {
      fontSize: '14px',
      lineHeight: '18px',
      maxWidth: '100px',
      fontFamily: theme('typography.paragraph.fontFamily'),
      marginTop: {
        default: '10px',
        video: 0,
      },
      color: theme('colors.link'),
    },
    text: {
      fontSize: '14px',
      lineHeight: '18px',
      fontFamily: theme('typography.paragraph.fontFamily'),
    },
    home: {
      position: 'static',
      top: '0',
    },
    video: {
      position: 'static',
      top: '0',
    },
    store: {
      position: 'static',
      top: '0',
    },
    photoUpload: {
      container: {
        marginLeft: 'auto',
      },
    },
  },
  radioButtonRich: {
    borderRadius: '0px',
  },
  calender: {
    backgroundColor: '#f7f5f1',
  },
  skipSlots: {
    fontWeight: theme('typography.paragraph.fontWeight'),
    fontSize: '16px',
  },
  splash: {
    position: 'absolute',
    transform: 'translate(100%, 0)',
    bottom: {
      start: 'calc(40% - 20px)',
      end: 'calc(10px + 40%)',
    },
    right: '-30px',
  },
  forms: {
    label: {
      fontSize: '16px',
      lineHeight: '30px',
      link: {
        color: theme('button.colors.primaryBackground'),
        paddingBottom: '5px',
        borderBottom: '0.5px solid',
        borderBottomColor: theme('button.colors.primaryBackground'),
      },
    },
    optinText: {
      fontWeight: theme('forms.label.fontWeight'),
      fontSize: theme('forms.label.fontSize'),
    },
    colors: {
      checkboxBorder: theme('forms.colors.border'),
      invalidBorder: theme('colors.salmon'),
      invalid: theme('colors.salmon'),
      prefilledBackground: 'transparent',
      placeholder: '#999',
    },
    customerForm: {
      margin: '42px',
    },
    button: {
      padding: '14px 28px',
    },
    prepForm: {
      headline: {
        color: theme('colors.accent'),
        desktop: {
          fontSize: '31px',
          marginTop: '60px',
          lineHeight: '48px',
        },
        mobile: {
          fontSize: {
            normal: '22px',
            embedded: '28px',
          },
          marginTop: {
            normal: '60px',
            embedded: '25px',
          },
        },
        textAlign: { normal: 'left', embedded: 'left' },
        textTransform: { normal: 'uppercase', embedded: 'none' },
        letterSpacing: { normal: '1px', embedded: '0px' },
        lineHeight: { normal: '', embedded: '32px' },
      },
      paragraph: {
        textAlign: 'left',
        mobile: {
          fontSize: {
            normal: '15px',
            embedded: '14px',
          },
        },
        color: '#000',
        lineHeight: '20px',
        margin: {
          mobile: '0',
          desktop: '0',
          normal: '0',
          embedded: '0',
        },
      },
      clearButton: {
        color: '#c1c1c1',
        letterSpacing: theme('button.letterSpacing'),
        fontSize: theme('button.fontSize'),
        fontWeight: theme('button.fontWeight'),
        textTransform: theme('button.textTransform'),
      },
    },
  },
  grid: {
    containerMaxWidth: '1097px',
  },
  designer: {
    minHeight: '175px',
    padding: {
      desktop: '0px 35px 0px 35px',
      mobile: '0px 35px 0px 35px',
    },
    headline: {
      textTransform: {
        normal: 'uppercase',
        embedded: 'none',
      },
      fontSize: '18px',
      margin: '15px 0 15px 0',
      letterSpacing: {
        normal: '1px',
        embedded: '0px',
      },
    },
    paragraph: {
      fontSize: {
        desktop: '15px',
        mobile: {
          normal: '15px',
          embedded: '14px',
        },
      },
      lineHeight: {
        desktop: '26px',
        mobile: '22px',
      },
      color: '#001b33',
    },
    background: {
      color: '#f4f2f0',
    },
    name: {
      fontWeight: 700,
      marginTop: '20px',
    },
    location: {
      marginBottom: '0px',
    },
    image: {
      maxWidth: '235px',
      maxHeight: '190px',
    },
  },
  bookingDetails: {
    backgroundColor: 'transparent',
    border: {
      color: theme('colors.accent'),
    },
    borderRadius: 0,
    subHeadline: {
      fontSize: '16px',
      textTransform: theme('typography.subHeadline.textTransform'),
    },
    storeName: {
      display: 'block',
    },
    image: {
      margin: {
        top: '60px',
        left: '30px',
        right: '30px',
      },
    },
    marginBottom: '28px',
    padding: '10px 20px 20px 20px',
    paddingDesktop: '20px 30px',
    divider: {
      color: theme('colors.accent'),
    },
  },
  bookingSummary: {
    storeName: {
      display: 'block',
    },
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '0px',
    borderColor: theme('colors.accent'),
    itemTitle: {
      color: theme('colors.accent'),
      textTransform: theme('typography.subHeadline.textTransform'),
      fontFamily: theme('typography.paragraph.fontFamily'),
      fontWeight: theme('typography.subHeadline.fontWeight'),
      fontSize: theme('contactDetails.dt.fontSize'),
      marginBottom: '5px',
    },
    itemText: {
      marginBottom: '13px',
      fontSize: '15px',
    },
  },
  quickQuestions: {
    container: {
      bottomBorder: '1px solid #dbdbdb',
      mobile: {
        padding: '0 0 5px 0',
        margin: '20px 0 0 0',
      },
      desktop: {
        flexDirection: 'row',
        padding: '0 0 10px 0',
        margin: '40px 0 0 0',
      },
    },
    optionsLabel: {
      color: '#555',
      fontWeight: 400,
      letterSpacing: '.5px',
    },
    heading: {
      style1: {
        color: '#001b33',
        fontWeight: 700,
        letterSpacing: '.5px',
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'uppercase',
        minWidth: {
          desktop: '265px',
        },
      },
      style2: {
        color: '#001b33',
        fontWeight: 700,
        letterSpacing: '.5px',
        fontFamily: theme('typography.paragraph.fontFamily'),
        fontSize: {
          mobile: '18px',
          desktop: '18px',
        },
        lineHeight: {
          mobile: '22px',
          desktop: '22px',
        },
        margin: {
          mobile: '0 30px 18px 0',
          desktop: '0 30px 18px 0',
        },
        width: '265px',
        textTransform: 'uppercase',
        minWidth: {
          desktop: '265px',
        },
        container: {
          margin: '7px',
        },
      },
    },
    preamble: {
      color: '#555',
      fontSize: { desktop: '16px', mobile: '16px' },
      lineHeight: '23px',
    },
    image: {
      style1: {
        width: 'auto',
        height: '80px',
      },
      style2: {
        width: 'auto',
        height: '80px',
      },
      style3: {
        width: '100%',
        height: 'auto',
      },
    },
  },
  contactDetails: {
    dt: {
      fontSize: 'inherit',
    },
  },
  noTimeSuitable: {
    after: "content: ' >'",
  },
  confirmation: {
    header: {
      fontSize: '26px',
      fontSizeDesktop: '40px',
      lineHeightDesktop: '48px',
      letterSpacing: '1px',
      color: theme('colors.accent'),
      textAlign: 'center',
    },
    row: {
      padding: '0 20px 0 20px',
    },
  },
  questionBlock: {
    container: {
      flexBasis: '30%',
    },
    heading: {
      container: {
        style1: {
          flexBasis: '70%',
          margin: '7px',
        },
        style2: {
          flexBasis: '100%',
          margin: '7px',
        },
        style3: {
          flexBasis: '70%',
          margin: '7px',
        },
      },
    },
    options: {
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0 0 0 auto',
      },
    },
    anotherOption: {
      text: {
        fontSize: '14px',
      },
    },
    optionsItem: {
      border: '1px solid #d5d5d5',
      activeBorder: '1px solid #333',
      borderRadius: '2px',
      wrapper: {
        justifyContent: 'flex-end',
      },
      width: {
        mobile: '120px',
        desktop: '160px',
      },
      margin: {
        mobile: '0 0 10px 0',
        desktop: '0 10px 10px 0',
      },
    },
    textArea: {
      fontSize: '16px',
      width: '370px',
    },
  },
  imageOption: {
    backgroundColor: '#fff',
    desktop: {
      minWidth: 'auto',
    },
    wrap: {
      flexDirection: 'column',
      padding: '15px 0',
    },
    image: {
      mobile: {
        height: '100px',
      },
      desktop: {
        height: '80px',
      },
    },
    text: {
      fontSize: {
        desktop: '13px',
        mobile: '13px',
      },
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  textOption: {
    backgroundColor: '#fff',
    wrap: {
      mobile: {
        padding: '15px',
        width: '120px',
      },
      desktop: {
        padding: '25px 15px',
      },
    },
    text: {
      minHeight: '80px',
      minWidth: '80px',
      fontFamily: theme('typography.paragraph.fontFamily'),
      fontSize: {
        mobile: '13px',
        desktop: '16px',
        fontWeight: 700,
      },
      fontWeight: 300,
      active: {
        fontWeight: 700,
      },
    },
  },
  photoUpload: {
    button: {
      padding: '15px',
    },
    checkbox: {
      fontSize: '12px',
      letterSpacing: '1px',
    },
  },
  appointmentDetails: {
    sectionsContainer: {
      border: '1px solid #d8d8d8',
    },
  },
  postalCodeOverlay: {
    backgroundColor: '#fff',
    text: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
}
