import React, { Component, Fragment } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from 'styled-components'
import { BreakpointProvider } from '../grid'
import { getTheme, getLocalTheme } from '../themes'
import { IntlProvider, getLocale, messages as globalMessages } from '../i18n'
import AppContainer from './AppContainer'
import initState from './initState'

const createApolloState = (props) => {
  if (props.apiUrl != null) {
    if (props.client != null || props.store != null) {
      throw new Error(
        'Props `client` and `store` are incompatible with `apiUrl`'
      )
    }

    return initState({
      apiUrl: props.apiUrl,
      persistSharedState: props.persistSharedState,
      storeConfig: props.storeConfig,
      cacheConfig: props.cacheConfig,
    })
  }

  if (props.client == null || props.store == null) {
    throw new Error(
      'Props `client` and `store` are required when `apiUrl` is omitted'
    )
  }

  return {
    client: props.client,
    store: props.store,
  }
}

const createThemeState = (props) => ({
  theme: props.theme || getTheme(props.brand, getLocalTheme(props.brand)),
})

const createLocaleState = (props) => {
  if (props.locale == null) {
    const brandLocale = getLocale(props.brand)

    return { locale: brandLocale.locale }
  }

  return { locale: props.locale }
}

class Root extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ...createApolloState(this.props),
      ...createThemeState(this.props),
      ...createLocaleState(this.props),
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.brand !== this.props.brand ||
      nextProps.theme !== this.props.theme
    ) {
      this.setState(createThemeState(nextProps))
    }

    if (
      nextProps.brand !== this.props.brand ||
      nextProps.locale !== this.props.locale
    ) {
      this.setState(createLocaleState(nextProps))
    }

    if (
      nextProps.client !== this.props.client ||
      nextProps.store !== this.props.store ||
      nextProps.apiUrl !== this.props.apiUrl
    ) {
      this.setState(createApolloState(nextProps))
    }
  }

  render() {
    const { children, messages, basename, rhl } = this.props
    const { client, store, theme, locale } = this.state
    const { GlobalStyles } = theme

    return (
      <AppContainer rhl={rhl} warnings={false}>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          <Router basename={basename}>
            <ReduxProvider store={store}>
              <ApolloProvider client={client}>
                <ThemeProvider theme={theme}>
                  <BreakpointProvider>
                    <Fragment>
                      <GlobalStyles />
                      {children}
                    </Fragment>
                  </BreakpointProvider>
                </ThemeProvider>
              </ApolloProvider>
            </ReduxProvider>
          </Router>
        </IntlProvider>
      </AppContainer>
    )
  }
}

Root.defaultProps = {
  apiUrl: null,
  client: null,
  store: null,
  theme: null,
  brand: null,
  messages: globalMessages,
  basename: '',
  rhl: true,
}

export default Root
