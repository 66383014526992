import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import features from '../features'
import SubHeadline from '../typography/subHeadline'
import Preamble from '../typography/preamble'
import PageTitle from '../typography/pageTitle'
import BookingDetails from '../booking-details/BookingDetails'

const StyledContainer = styled.div`
  margin: 30px 0 30px 0;
  ${(props) => props.theme.media.min.sm`    
    margin: 60px 0 30px 0;
  `}
`

const AlignedContainer = styled.div`
  text-align: ${theme('confirmation.header.textAlign')};
`

const ErrorTitle = styled(PageTitle)`
  color: #dc4d4d;
`

const StyledPageTitle = styled(PageTitle)`
  font-size: ${theme('confirmation.header.fontSize')};
  text-transform: ${theme('confirmation.header.textTransform')};
  margin-bottom: ${theme('confirmation.header.marginBottom')};
  ${(props) => props.theme.media.min.sm`    
    font-size: ${theme('confirmation.header.fontSizeDesktop')};
  `}
`

const ConfirmationHeader = ({
  selectedStore,
  selectedSlot,
  booking,
  pageContent,
  serviceContent,
}) => {
  if (!pageContent || !serviceContent) return null
  return (
    <>
      {booking && !booking.error && (
        <StyledContainer>
          <AlignedContainer>
            <StyledPageTitle>
              {serviceContent.confirmationHeading}
            </StyledPageTitle>
            {features.showConfirmationSubHeadline && (
              <SubHeadline>{serviceContent.confirmationPreamble}</SubHeadline>
            )}
          </AlignedContainer>
          {selectedStore && (
            <BookingDetails
              display="expanded"
              selectedStore={selectedStore}
              selectedSlot={selectedSlot}
              selectedServiceName={serviceContent.heading}
            />
          )}
        </StyledContainer>
      )}

      {booking && booking.error && (
        <StyledContainer>
          <ErrorTitle>{pageContent.errorHeading}</ErrorTitle>
          <Preamble>{pageContent.errorText}</Preamble>
        </StyledContainer>
      )}
    </>
  )
}

export default ConfirmationHeader
