import React, { createElement } from 'react'
import invariant from 'invariant'
import { connect } from '../redux'
import { AuthContext } from './AuthProvider'
import {
  statusSelector,
  errorSelector,
  sessionSelector,
  profileSelector,
} from './authSelectors'

const Auth = (props) => {
  const renderProps = {
    client: props.context.client,
    status: props.status,
    error: props.error,
    session: props.session,
    profile: props.profile,
  }

  if (props.component) {
    return createElement(props.component, renderProps)
  }

  if (props.render) {
    return props.render(renderProps)
  }

  if (typeof props.children === 'function') {
    return props.children(renderProps)
  }

  return invariant(false, 'No render prop provided to Auth component.')
}

const AuthConsumer = (props) => (
  <AuthContext.Consumer>
    {(context) => <Auth {...props} context={context} />}
  </AuthContext.Consumer>
)

const mapStateToProps = (state) => ({
  status: statusSelector(state),
  error: errorSelector(state),
  profile: profileSelector(state),
  session: sessionSelector(state),
})

export default connect(mapStateToProps)(AuthConsumer)
