import styled from 'styled-components'
import { prop } from 'styled-tools'

const ErrorMessage = styled.span`
  position: relative;
  box-sizing: border-box;
  display: block;
  min-height: 30px;
  margin-top: -6px;
  border-radius: ${prop('theme.forms.borderRadius')};
  padding: 8px 10px;
  font-family: ${prop('theme.forms.fontFamily')};
  font-weight: ${prop('theme.forms.fontWeight')};
  font-size: ${prop('theme.forms.fontSize')};
  line-height: ${prop('theme.forms.lineHeight')};
  color: ${prop('theme.forms.colors.errorColor')};
  background-color: ${prop('theme.forms.colors.errorBackground')};
  z-index: 5;

  ${(props) =>
    props.theme.media.min.sm`font-size:
    ${props.theme.forms.fontSizeDesktop};`};
`

export default ErrorMessage
