import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import StoreMeeting from './storeMeeting'
import Video from './video'
import Discover from './discover'
import Doors from './doors'
import Home from './home'
import Upgrade from './upgrade'
import TwentyFourHour from './24hour'
import Lightbulb from './lightbulb'
import Chat from './chat'
import Store from './store'

const mapper = (meetingTypeCode, width = 40, height = 40) => {
  const widthVideo = width * 1.3

  switch (meetingTypeCode) {
    case 'store':
      return <Store width={width} height={height} />
    case 'store-meeting':
      return <StoreMeeting width={width} height={height} />
    case 'video':
    case 'video-meeting':
      return <Video width={widthVideo} height={height} />
    case 'discover':
    case 'airplay':
      return <Discover width={width} height={height} />
    case 'lightbulb':
      return <Lightbulb width={width} height={height} />
    case 'chat':
      return <Chat width={width} height={height} />
    case 'doors':
    case 'frontalchange':
      return <Doors width={width} height={height} />
    case 'home':
    case 'home-visit':
      return <Home width={width} height={height} />
    case 'upgrade':
    case 'kitchen':
      return <Upgrade width={width} height={height} />
    case '24hour':
      return <TwentyFourHour width={width} height={height} />
    default:
      return <Store width={width} height={height} />
  }
}

function BadaIcon({ meetingTypeCode }) {
  return <IconContainer>{mapper(meetingTypeCode)}</IconContainer>
}

BadaIcon.propTypes = {
  meetingTypeCode: PropTypes.string.isRequired,
}

export default BadaIcon

const IconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 50px;
  box-sizing: border-box;

  svg {
    color: ${theme(`meetingType.iconcolor`)};
  }
  svg g {
    stroke: ${theme('colors.link')};
    stroke-width: 2.1px;
  }
`
