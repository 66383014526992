import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import useTranslation from '../../../utils/translation'

const Container = styled.div`
  display: block;
  color: ${theme('colors.darkGrey')};
  background-color: #f9f6f3;
  border: 1px solid #c3a56d;
  border-radius: 3px;
  font-weight: 400;
  padding: 20px;
  margin: 15px 0px 0px 0px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  ${(props) => props.theme.media.min.sm`
    padding: 20px;
    margin: 40px 0px 40px 0px;
  `}
  a {
    color: ${theme('colors.darkGrey')};
  }
`

const Alert = ({ wizard }) => {
  const t = useTranslation('app.alert')
  return (
    wizard &&
    !wizard.step.five &&
    !wizard.step.six && (
      <Container
        dangerouslySetInnerHTML={{
          __html: t('text'),
        }}
      />
    )
  )
}

Alert.propTypes = {
  wizard: PropTypes.shape(),
}

Alert.defaultProps = {
  wizard: null,
}

export default Alert
