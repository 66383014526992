import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import { withProps, withHandlers, compose } from 'recompose'
import CloseIcon from '../icons/close'
import { isCancelable } from './CancelButton'
import { isDeletable } from './DeleteButton'

const Container = styled.div`
  position: relative;
  padding: 8px 10px;
  padding-right: calc(18px + 1em);
  margin-bottom: 15px;
  color: ${prop('theme.uploads.errorMessage.color')};
  background: ${prop('theme.uploads.errorMessage.background')};
  border: 1px solid ${prop('theme.uploads.errorMessage.border')};
`

const Button = styled.button`
  position: absolute;
  top: 50%;
  right: 8px;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transform: translateY(-50%);

  &:focus {
    outline: none;
  }
`

const ButtonIcon = styled(CloseIcon)`
  display: block;
`

const ErrorMessage = ({ id, reason, uploader, file, onClick, ...props }) => (
  <Container {...props}>
    <span>{reason}</span>
    {file != null && (
      <Button onClick={onClick}>
        <ButtonIcon type="close" />
      </Button>
    )}
  </Container>
)

ErrorMessage.defaultProps = {
  file: null,
  onClick: null,
}

const enhance = compose(
  withProps((props) => ({
    file: props.uploader.methods.getUploads({ id: props.id }),
  })),
  withHandlers({
    onClick: (props) => () => {
      if (props.file != null) {
        if (isCancelable(props.file.status)) {
          props.uploader.methods.cancel(props.file.id)
        } else if (isDeletable(props.file.status)) {
          props.uploader.methods.deleteFile(props.file.id)
        }
      }
    },
  })
)

export default enhance(ErrorMessage)
