import React from 'react'
import { injectIntl } from 'react-intl'
import { compose, withHandlers, withState } from 'recompose'
import styled, { css } from 'styled-components'
import { prop, theme } from 'styled-tools'
import { graphql } from '@apollo/client/react/hoc'

import LoadingIndicator from '../../../components/loadingIndicator'
import features from '../../../components/features'
import CreateAppointmentMutation from '../../../../graphql/CreateAppointmentMutation.graphql'
import UpdateBookingMutation from '../../../../graphql/UpdateBookingMutation.graphql'
import CmsPageQuery from '../../../../graphql/CmsPageQuery.graphql'
import { badaDefaultProps } from '../../../../types'
import settings from '../../../components/settings'
import Designer from '../../../components/designer/Designer'
import Button from '../../../components/button/baseButton'
import PrepHeader from '../../../components/prep-header/PrepHeader'
import QuickQuestions from '../../../components/quick-questions'
import { withSmallScreenSplash } from '../../../components/with-splash'
import { isEmbedded } from '../../bada/utils'
import {
  getDisplayData,
  getMeetingTypeContent,
  getMeetingTypeName,
  getMeetingTypeMeta,
} from '../content'
import { CUSTOMER_FORM, SIMPLY_BOOK_ME } from '../reducer/Wizard'
import { getTranslator } from '../../../../utils/translation'
import tracker from '../../../../utils/tracker'
import { appBaseUrl } from '../../../../utils/appBaseUrl'
import appInsights, {
  SeverityLevel,
} from '../../../components/logging/appInsights'

const Error = styled.div`
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: #ec8888;
  background-color: #fdf5f5;
  padding: 20px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: #e7696a;
  font-weight: bold;
`

const PaperformError = styled(Error)`
  margin: 28px 56px;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  font-size: ${prop('theme.button.fontSize', '12px')};
  border: ${theme('button.border')};
  letter-spacing: ${theme('button.letterSpacing')};
  height: ${theme('button.height')};
  position: relative;
  background-color: transparent;

  &:before {
    height: ${theme('button.height')};
  }

  ${(props) => props.theme.media.min.sm`
    width: ${theme('button.wideButtonWidth')};
    margin-top: 40px;
  `}

  &:not([disabled]):hover {
    ${css(theme('buttonHover'))}
    &:before {
      ${css(theme('buttonHoverBefore'))}
    }
  }

  > div {
    border-radius: ${prop('theme.button.borderRadius')};
    background-color: ${prop('theme.button.colors.primaryBackground')};
  }
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme('colors.activeBorder')};
`

const SecondaryButton = styled(Button)`
  color: ${theme('forms.prepForm.clearButton.color')};
  letter-spacing: ${theme('forms.prepForm.clearButton.letterSpacing')};
  font-size: ${theme('forms.prepForm.clearButton.fontSize')};
  font-weight: ${theme('forms.prepForm.clearButton.fontWeight')};
  text-transform: ${theme('forms.prepForm.clearButton.textTransform')};
  margin-top: 20px;
  text-decoration: underline;
  background: transparent;

  &:before {
    background: transparent;
  }
  &:hover,
  &:focus {
    color: #c1c1c1 !important;
    &:before {
      background: transparent !important;
    }
  }
  ${(props) => props.theme.media.max.sm`
  margin: 0 !important;
  margin-top: 1.5rem !important;
  width: 100%;
`}

  ${(props) => props.theme.media.min.sm`
  float: right;

  margin-top: 40px;
`}
`

const Wrapper = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`

function getBookingSystem(appointmentWrapper) {
  const appointment = appointmentWrapper?.appointment

  const defaultBookingSystem = appointment?.selectedTime
    ? SIMPLY_BOOK_ME
    : CUSTOMER_FORM

  return appointment?.externalBookingSystem || defaultBookingSystem
}

class PrepFormStep extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.appointmentFromQuery) {
      if (!this.props.appointment || !this.props.appointment.appointment) {
        return
      }
      const { appointment } = this.props.appointment
      const {
        questions,
        willBringCurrentKitchenImages,
        willBringKitchenScetchImages,
      } = (appointment && appointment.appointmentPreparations) || {}

      const bookingSystem = getBookingSystem(this.props.appointment)
      const content = getDisplayData(this.props.pageContent.page)
      const meetingTypeName = getMeetingTypeName(appointment)
      const service = getMeetingTypeContent({
        meetingTypes: content.services,
        currentMeetingTypeName: meetingTypeName,
        bookingSystem,
        storeSubSystem:
          this.props.appointment.appointment.simplyBookMeSubsystem,
        serviceId: appointment.serviceId,
      })

      let selectedSlot = null
      if (appointment.selectedDate && appointment.selectedTime) {
        const duration = appointment.duration
          ? parseInt(appointment.duration, 10)
          : settings.serviceDuration

        const from = `${appointment.selectedDate}T${appointment.selectedTime}:00`
        const toDate = new Date(`${from}.000Z`).getTime() + duration * 60 * 1000

        selectedSlot = {
          from,
          to: new Date(toDate).toISOString(),
        }
      }
      this.props.updateUiState({
        appointmentFromQuery: false,
        selectedStore: { ...this.props.badaProps.store },
        selectedService: service,
        values: {
          name: appointment.customerName,
          email: appointment.customerEmail,
          phone: appointment.customerPhone,
          address: appointment.customerAddress,
        },
        selectedSlot,
        prepData: {
          willBringKitchenImages: willBringCurrentKitchenImages,
          willBringKitchenSketchImages: willBringKitchenScetchImages,
          questions:
            questions &&
            questions
              .filter((f) => f)
              .map((q) => ({
                question: q.question,
                answers: q.answers,
              })),
        },
      })

      if (features.kratos && appointment?.client) {
        if (appointment.client === 'kratos' && settings.kratos) {
          if (isEmbedded()) {
            document.location = `${settings.kratos.path}/${appointment.id}/embedded/`
          } else {
            document.location = `${settings.kratos.path}/${appointment.id}/`
          }
        }
      }

      if (
        appointment &&
        appointment.externalMeetingProvider &&
        appointment.externalMeetingProvider.indexOf('prepformonly') === 0
      ) {
        /*
        This is for keittiomaailma/novart.
        The meetingtypes 'prepformonly-store' & 'prepformonly-call' are placed by sales-rep in stores.
        When the customer accesses the prep form from their own browser we need to re-associate the lead in marketo using the customers cookie.
        */
        const trackingCookieMatch = `; ${document.cookie}`.match(
          '; _mkto_trk=([^;]+)'
        )
        const trackingCookie = trackingCookieMatch
          ? trackingCookieMatch[1]
          : null
        this.props.updateUiState({
          trackingCookie,
        })
      }
    }

    if (!prevProps.badaProps.store && this.props.badaProps.store) {
      this.props.updateUiState({
        selectedStore: { ...this.props.badaProps.store },
      })
    }
  }

  render() {
    const {
      wizard: {
        step: { five },
      },
      updateUiState,
      setLoading,
      loading,
      prepData,
      staff,
      selectedStore,
      submitPrepForm,
      clearPrepForm,
      returningVisitor,
      appointment,
      pageContent,
      valid,
      showValidation,
      resetValidation,
      validatedQuestions,
      customerFormInput,
      intl,
    } = this.props

    const isPaperform =
      appointment?.appointment?.source?.toLowerCase() === 'paperform'

    if (isPaperform) {
      appInsights.trackTrace({
        message: `Attempt to load prepform of PaperForm appointment ${appointment?.appointment?.id}`,
        severityLevel: SeverityLevel.Warning,
      })
      return <PaperformError>The form can`t be displayed.</PaperformError>
    }

    let checkMeetingTypeName = ''
    if (appointment?.appointment?.externalMeetingProvider) {
      checkMeetingTypeName =
        appointment.appointment.externalMeetingProvider.replace(
          '-appointment',
          ''
        )
    } else {
      checkMeetingTypeName = customerFormInput?.service
    }

    const hasAppointment = !features.createAppointmentAfterPrepForm.includes(
      checkMeetingTypeName
    )
      ? !!(appointment && appointment.appointment)
      : true

    if (five && pageContent && pageContent.page && hasAppointment) {
      const { meetingTypeName, storeSubSystem, serviceId } = getMeetingTypeMeta(
        appointment,
        customerFormInput
      )
      const bookingSystem = getBookingSystem(appointment)
      const content = getDisplayData(pageContent.page)
      const serviceContent = getMeetingTypeContent({
        meetingTypes: content.services,
        currentMeetingTypeName: meetingTypeName,
        bookingSystem,
        storeSubSystem,
        serviceId,
      })

      const t = getTranslator(intl, 'app.prep-form')

      return (
        <Wrapper data-track="block-preferences">
          <PrepHeader {...this.props} content={serviceContent} />
          <Designer
            heading={
              returningVisitor
                ? serviceContent.preparationDesignerHeadingReturning
                : serviceContent.preparationDesignerHeading
            }
            text={
              returningVisitor
                ? serviceContent.preparationDesignerTextReturning
                : serviceContent.preparationDesignerText
            }
            image={serviceContent.preparationDesignerImage}
            name={serviceContent.preparationDesignerName}
            location={serviceContent.preparationDesignerLocation}
            staff={staff}
            selectedStore={selectedStore}
          />

          <QuickQuestions
            serviceContent={serviceContent}
            questions={validatedQuestions || serviceContent.questions}
            updateUiState={updateUiState}
            prepData={prepData}
            onChange={(isLoading) => {
              setLoading(isLoading)

              if (isLoading) {
                return
              }

              if (!features.disablePrepFormAutosave.includes(meetingTypeName)) {
                submitPrepForm()
              } else {
                resetValidation()
              }
            }}
            useSplash={
              !features.createAppointmentAfterPrepForm.includes(
                checkMeetingTypeName
              )
            }
          />

          {!valid && showValidation && <Error>{t('error.summary')}</Error>}

          <StyledButton
            data-track="btn-submit"
            onClick={() => !loading && submitPrepForm(true)}
            disabled={loading}
          >
            {loading && (
              <StyledLoadingIndicator
                color={theme('color.secondary')}
                duration={3}
                size="small"
              />
            )}
            {(isEmbedded(true) && t('embedded-prepform-update')) || t('submit')}
          </StyledButton>

          {features.allowClearPrepForm && (
            <SecondaryButton data-track="btn-clear" onClick={clearPrepForm}>
              {t('clear')}
            </SecondaryButton>
          )}
        </Wrapper>
      )
    }
    return null
  }
}

PrepFormStep.defaultProps = {
  ...badaDefaultProps,
  validatedQuestions: null,
}

const finalize = ({ updateUiState, selectedStore, history }) => {
  updateUiState({ prepCompleted: true })
  tracker.trackClick(
    'BADA 2.0 details form submitted',
    selectedStore ? selectedStore.name : 'unknown store'
  )
  window.scrollTo({
    left: 0,
    top: 0,
  })
  history.push('#details-submit')
}

const updateAppointment = ({
  mutation,
  input,
  updateUiState,
  selectedStore,
  splash,
  confirm,
  history,
  meetingTypeName,
}) => {
  mutation({ variables: { input } }).then(() => {
    const parent = window.parent
    if (parent && confirm) {
      parent.postMessage('prepform-completed', '*')
    }
  })
  if (
    (features.disablePrepFormAutosave.includes(meetingTypeName) || confirm) &&
    !isEmbedded()
  ) {
    finalize({ updateUiState, selectedStore, history })
  } else {
    splash()
  }
}

const reset =
  ({ updateShowValidation, validatedQuestions, updateUiState, prepData }) =>
  () => {
    if (validatedQuestions) {
      const updatedValidatedQuestions = validatedQuestions.map((q) => {
        const answer = prepData.questions.find(
          (f) => f.question === q.questionText.value
        )
        if (answer && answer.answers && answer.answers.length > 0) {
          return {
            ...q,
            notAnswered: false,
          }
        }
        return q
      })
      updateUiState({
        validatedQuestions: updatedValidatedQuestions,
      })
    }
    updateShowValidation(false)
  }

const validate = ({
  prepData,
  updateValid,
  updateShowValidation,
  pageContent,
  customerFormInput,
  appointment,
  updateUiState,
}) => {
  let valid = true
  const content = getDisplayData(pageContent.page)

  const { storeSubSystem, serviceId, bookingSystem } = getMeetingTypeMeta(
    appointment,
    customerFormInput
  )

  const meetingTypeContent = getMeetingTypeContent({
    meetingTypes: content.services,
    currentMeetingTypeName: customerFormInput.service,
    bookingSystem,
    storeSubSystem,
    serviceId,
  })

  const questions = meetingTypeContent && meetingTypeContent.questions
  if (!questions || (questions && questions.length === 0)) {
    valid = true
    updateValid(valid)
    updateShowValidation(!valid)
    return valid
  }

  if (!prepData || !prepData.questions || prepData.questions.length === 0) {
    valid = false
    updateValid(valid)
    updateShowValidation(!valid)
    return valid
  }

  const validatedQuestions = questions.map((question) => {
    if (question.required) {
      const answeredQuestion = prepData.questions.find(
        (f) => f.question === question.questionText.value
      )
      if (
        !answeredQuestion ||
        !answeredQuestion.answers ||
        answeredQuestion.answers.length === 0 ||
        answeredQuestion.answers[0] === ''
      ) {
        valid = false
        return {
          ...question,
          notAnswered: true,
        }
      }
    }
    return {
      ...question,
      notAnswered: false,
    }
  })

  updateUiState({
    validatedQuestions,
  })
  updateValid(valid)
  updateShowValidation(!valid)

  return valid
}

const submit =
  ({
    prepData,
    appointment,
    appointmentId,
    updateUiState,
    selectedStore,
    history,
    splash,
    createAppointmentMutation,
    updateBookingMutation,
    customerFormInput,
    pageContent,
    trackingCookie,
    updateValid,
    updateShowValidation,
  }) =>
  (confirm) => {
    const bookingSystem = getBookingSystem(appointment)
    const content = getDisplayData(pageContent.page)

    const { meetingTypeName, storeSubSystem, serviceId } = getMeetingTypeMeta(
      appointment,
      customerFormInput
    )

    const isValid = features.validatePrepForm.includes(meetingTypeName)
      ? validate({
          prepData,
          updateValid,
          updateShowValidation,
          pageContent,
          customerFormInput,
          appointment,
          updateUiState,
        })
      : true
    if (!isValid) {
      return
    }

    const serviceContent = getMeetingTypeContent({
      meetingTypes: content.services,
      currentMeetingTypeName: meetingTypeName,
      bookingSystem,
      storeSubSystem,
      serviceId,
    })

    const contentQuestions = (serviceContent && serviceContent.questions) || []
    const questions = []
    contentQuestions.forEach((contentQuestion) => {
      const q =
        prepData &&
        prepData.questions &&
        prepData.questions.find(
          (f) => f.question === contentQuestion.questionText.value
        )
      if (q) {
        questions.push(q)
      } else {
        questions.push({
          question: contentQuestion.questionText.value,
          answers: [],
        })
      }
    })

    const notRepeatVisitByEmail = { key: 'visit', value: 'first' }
    const visit = new URLSearchParams(window.location.search).get(
      notRepeatVisitByEmail.key
    )
    const willSendEmailUpdate =
      visit !== notRepeatVisitByEmail.value &&
      (features.disablePrepFormAutosave.includes(meetingTypeName) || confirm)

    const { willBringKitchenImages, willBringKitchenSketchImages } = prepData
    const input = {
      brand: settings.brand,
      appointmentId,
      questions,
      willSendEmailUpdate,
      trackingCookie,
      willBringKitchenImages,
      willBringKitchenSketchImages,
    }

    if (!appointmentId) {
      // Appointment has not yet been submitted. Submit it.
      createAppointmentMutation({
        variables: { input: { ...customerFormInput } },
      }).then((result) => {
        const newAppointmentId = result.data.createAppointment.appointmentId
        updateAppointment({
          mutation: updateBookingMutation,
          input: { ...input, appointmentId: newAppointmentId },
          updateUiState,
          selectedStore,
          splash,
          confirm,
          history,
          meetingTypeName,
        })
        const separator = window.location.search ? '&' : '?'
        history.push(
          `${window.location.search}${separator}appointment=${newAppointmentId}&studioid=${customerFormInput.storeId}#complete`
        )
        updateUiState({ appointmentId: newAppointmentId })
      })
    } else if (
      appointment?.appointment &&
      appointment.appointment.client !== 'kratos'
    ) {
      updateAppointment({
        mutation: updateBookingMutation,
        input,
        updateUiState,
        selectedStore,
        splash,
        confirm,
        history,
        meetingTypeName,
      })
    }
  }

const clear =
  ({
    updateUiState,
    appointmentId,
    updateBookingMutation,
    pageContent,
    appointment,
    customerFormInput,
  }) =>
  () => {
    const content = getDisplayData(pageContent.page)
    const { meetingTypeName, storeSubSystem, serviceId } = getMeetingTypeMeta(
      appointment,
      customerFormInput
    )
    const bookingSystem = getBookingSystem(appointment)
    const serviceContent = getMeetingTypeContent({
      meetingTypes: content.services,
      currentMeetingTypeName: meetingTypeName,
      bookingSystem,
      storeSubSystem,
      serviceId,
    })
    const contentQuestions = (serviceContent && serviceContent.questions) || []

    const emptyPrepData = {
      questions: contentQuestions.map((q) => ({
        question: q.questionText.value,
        answers: [],
      })),
    }

    const input = {
      brand: settings.brand,
      appointmentId,
      ...emptyPrepData,
    }

    updateUiState({ prepData: { ...emptyPrepData } })
    updateBookingMutation({ variables: { input } })
  }

const enhance = compose(
  injectIntl,
  graphql(CreateAppointmentMutation, { name: 'createAppointmentMutation' }),
  graphql(UpdateBookingMutation, { name: 'updateBookingMutation' }),
  withSmallScreenSplash,
  withState('showValidation', 'updateShowValidation', false),
  withState('valid', 'updateValid', (props) => {
    const { appointment, customerFormInput } = props
    const { meetingTypeName } = getMeetingTypeMeta(
      appointment,
      customerFormInput
    )
    return !features.validatePrepForm.includes(meetingTypeName)
  }),
  withState('loading', 'setLoading', false),
  withHandlers({
    resetValidation: reset,
    submitPrepForm: (props) => (confirm) => submit(props)(confirm),
    clearPrepForm: clear,
  }),
  graphql(CmsPageQuery, {
    name: 'pageContent',
    options: () => ({
      variables: {
        url: appBaseUrl,
      },
    }),
  })
)

export default enhance(PrepFormStep)
