const breakpoints = {
  xs: 540,
  sm: 720,
  md: 960,
  lg: 1140,
  xl: 1920,
  xxl: 2560,
}

const SIZE_OFFSET = 1

const minBreakpoints = {
  ...breakpoints,
}

const maxBreakpoints = {
  xs: breakpoints.xs - SIZE_OFFSET,
  sm: breakpoints.sm - SIZE_OFFSET,
  md: breakpoints.md - SIZE_OFFSET,
  lg: breakpoints.lg - SIZE_OFFSET,
  xl: breakpoints.xl - SIZE_OFFSET,
  xxl: breakpoints.xxl - SIZE_OFFSET,
}

export { minBreakpoints, maxBreakpoints }
export default breakpoints
