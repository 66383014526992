import { Component, createElement } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'
import createUploader from './lib/createUploader'
import getContainerName from './lib/getContainerName'
import getRequestEndpoint from './lib/getRequestEndpoint'
import getFileType from './lib/getFileType'

const mapInitialFiles = ({
  blobName,
  fileName,
  fileSize,
  url,
  thumbnailUrl,
}) => {
  const [uuid] = blobName.split('.')

  return {
    uuid,
    blobName,
    name: fileName,
    size: fileSize,
    thumbnailUrl: thumbnailUrl || url,
  }
}

class Uploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploader:
        this.props.uploader ||
        createUploader(getContainerName(this.props.userId), {
          requestUrl: this.props.requestUrl,
          signatureUrl: this.props.signatureUrl,
          signatureHeaders: () => this.getAuthorizationHeader(),
          successUrl: this.props.successUrl,
          successHeaders: () => this.getAuthorizationHeader(),
          debug: this.props.debug,
          allowedExtensions: this.props.allowedExtensions,
          typeError: this.props.typeError,
          itemLimit: this.props.itemLimit,
        }),
      files: [],
      errors: [],
    }
    this.onStatusChange = this.onStatusChange.bind(this)
    this.onUploadError = this.onUploadError.bind(this)
    this.onComplete = this.onComplete.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onSubmitDelete = this.onSubmitDelete.bind(this)
  }

  componentDidMount() {
    this.state.uploader.on('statusChange', this.onStatusChange)
    this.state.uploader.on('error', this.onUploadError)
    this.state.uploader.on('complete', this.onComplete)
    this.state.uploader.on('delete', this.onDelete)
    this.state.uploader.on('submitDelete', this.onSubmitDelete)

    if (this.props.initialFiles != null) {
      this.addInitialFiles(this.props.initialFiles)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userId !== this.props.userId ||
      nextProps.requestUrl !== this.props.requestUrl
    ) {
      this.setRequestEndpoint(nextProps)
    }

    if (
      nextProps.initialFiles != null &&
      nextProps.initialFiles !== this.props.initialFiles
    ) {
      this.updateInitialFiles(nextProps.initialFiles)
    }
  }

  componentWillUnmount() {
    this.state.uploader.off('statusChange', this.onStatusChange)
    this.state.uploader.off('error', this.onUploadError)
    this.state.uploader.off('complete', this.onComplete)
    this.state.uploader.off('delete', this.onDelete)
    this.state.uploader.off('submitDelete', this.onSubmitDelete)
  }

  setRequestEndpoint(props) {
    const blobContainer = getContainerName(props.userId)
    const requestUrl = getRequestEndpoint(blobContainer, props.requestUrl)

    this.state.uploader.methods.setEndpoint(requestUrl)
  }

  getAuthorizationHeader() {
    return {
      Authorization: this.props.accessToken
        ? `Bearer ${this.props.accessToken}`
        : undefined,
    }
  }

  onStatusChange(id, prevStatus, newStatus) {
    switch (newStatus) {
      case qq.status.SUBMITTING:
        this.addFile(id, newStatus)
        break
      case qq.status.CANCELED:
      case qq.status.DELETED:
      case qq.status.REJECTED:
        this.removeFile(id)
        break
      case qq.status.UPLOAD_SUCCESSFUL:
      case qq.status.UPLOAD_FAILED:
        this.updateFileStatus(id, newStatus)
        break
      // no default
    }
  }

  addInitialFiles(metaFiles) {
    const initialFiles = metaFiles.map(mapInitialFiles)
    this.state.uploader.methods.addInitialFiles(initialFiles)
  }

  updateInitialFiles(metaFiles) {
    const addedFiles = metaFiles
      .map(mapInitialFiles)
      .filter(
        (file) =>
          this.state.uploader.methods.getUploads({ uuid: file.uuid }) == null
      )

    if (addedFiles.length > 0 || this.props.disableServerDelete) {
      this.state.uploader.methods.addInitialFiles(addedFiles)
    }
  }

  addFile(id, status) {
    const meta = this.getFileMetadata(id)
    if (!meta || meta.fileSize < 30000000) {
      this.setState(
        (state) => ({
          files: [
            ...state.files,
            {
              id,
              status,
              metadata: meta,
            },
          ],
        }),
        this.handleChange
      )
    } else {
      this.onUploadError(id, meta.fileName, 'File is too large')
    }
  }

  removeFile(id) {
    this.setState(
      (state) => ({
        files: state.files.filter((file) => file.id !== id),
        errors: state.errors.filter((error) => error.id !== id),
      }),
      () => {
        this.handleChange()
        this.handleError()
      }
    )
  }

  getFileMetadata(id) {
    const { uploader } = this.state
    const { userId } = this.props
    const fileName = uploader.methods.getName(id)
    const fileSize = uploader.methods.getSize(id)
    const blobName = uploader.methods.getBlobName(id)
    const fileNameParts = (blobName || fileName || '').split('.')
    const fileExtension = fileNameParts[fileNameParts.length - 1]
    const metadata =
      this.props.initialFiles &&
      this.props.initialFiles.find((file) => file.blobName === blobName)

    return {
      ...metadata,
      blobName,
      fileSize,
      fileName,
      container: getContainerName(userId),
      fileType: getFileType(fileExtension),
    }
  }

  getLastFileIdByName(name) {
    const files = this.state.uploader.methods
      .getUploads()
      .filter((file) => file.name === name)

    if (files.length > 0) {
      return files[files.length - 1].id
    }

    return null
  }

  updateFileStatus(id, status) {
    this.setState((state) => {
      const index = state.files.findIndex((file) => file.id === id)
      const prevFile = state.files[index]
      const metadata = this.getFileMetadata(id)

      if (prevFile) {
        const files = [...state.files]

        files[index] = {
          ...prevFile,
          status,
          metadata,
        }

        return { files }
      }

      const newFile = { id, status, metadata }

      if (status === qq.status.UPLOAD_SUCCESSFUL) {
        newFile.fromServer = true
      }

      return {
        files: [...state.files, newFile],
      }
    })
  }

  onUploadError(id, name, reason) {
    this.setState((state) => {
      const errors = state.errors.filter((error) => error.id !== id)
      errors.push({ id, name, reason })

      return { errors }
    }, this.handleError)
  }

  onComplete(id, name, response) {
    this.setState((s) => ({
      ...s,
      errors: s.errors.filter((e) => e.id != null),
    }))
    this.setState(
      (state) => {
        const index = state.files.findIndex((file) => file.id === id)
        const prevFile = state.files[index]

        if (prevFile) {
          const files = [...state.files]
          const newFile = {
            ...prevFile,
            metadata: {
              ...prevFile.metadata,
              url: response.url,
              thumbnailUrl: response.thumbnailUrl,
            },
          }

          files[index] = newFile

          return { files }
        }

        return null
      },
      () => {
        if (this.props.onComplete != null) {
          const { onComplete } = this.props // preserve flow's type refinement
          const file = this.state.files.find((item) => item.id === id)

          onComplete(file != null ? file.metadata : this.getFileMetadata(id))
        }
        
        this.handleChange()
      }
    )
  }

  onDelete(id) {
    this.setState((s) => ({
      ...s,
      errors: s.errors.filter((e) => e.id != null),
    }))
    if (this.props.onDelete != null) {
      const { onDelete } = this.props // preserve flow's type refinement
      const file = this.state.files.find((item) => item.id === id)

      onDelete(file != null ? file.metadata : this.getFileMetadata(id))
    }
  }

  onSubmitDelete(id) {
    if (this.props.onSubmitDelete != null) {
      const { onSubmitDelete } = this.props // preserve flow's type refinement
      const file = this.state.files.find((item) => item.id === id)

      if (this.props.disableServerDelete) {
        this.removeFile(id)
      }
      return onSubmitDelete(
        file != null ? file.metadata : this.getFileMetadata(id)
      )
    }
    return true
  }

  handleError() {
    if (this.props.handleError) {
      const files = this.state.errors.map((e) => ({
        file: this.state.files.find((item) => item.id === e.id),
      }))
      this.props.handleError(this.state.errors, files)
    }
  }

  handleChange() {
    if (this.props.handleChange != null) {
      const { handleChange } = this.props // preserve flow's type refinement
      const fileStatusGroups = this.state.files.reduce(
        (acc, file) => {
          switch (file.status) {
            case qq.status.SUBMITTING:
              acc.pending.push(file.metadata)
              break
            case qq.status.UPLOAD_SUCCESSFUL:
              acc.completed.push(file.metadata)
              break
            case qq.status.UPLOAD_FAILED:
            case qq.status.REJECTED:
              acc.failed.push(file.metadata)
              break
            // no default
          }

          return acc
        },
        { pending: [], completed: [], failed: [] }
      )

      handleChange(fileStatusGroups)
    }
  }

  render() {
    const renderProps = {
      uploader: this.state.uploader,
      files: this.state.files,
      errors: this.state.errors,
    }

    if (this.props.component) {
      return createElement(this.props.component, renderProps)
    }

    if (this.props.render) {
      return this.props.render(renderProps)
    }

    if (typeof this.props.children === 'function') {
      return this.props.children(renderProps)
    }

    return null
  }
}

export default Uploader
