import React from 'react'
import { qq } from 'fine-uploader-wrappers/azure'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { prop, withProp, ifProp } from 'styled-tools'
import CloseIcon from '../icons/close'
import ErrorIcon from '../icons/error'
import BaseProgressBar from './ProgressBar'
import BaseCancelButton from './CancelButton'
import BaseDeleteButton from './DeleteButton'
import BaseThumbnail from './Thumbnail'

const ThumbnailWrapper = styled.div`
  position: relative;
`

const buttonStyles = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 34px;
  border: 0;
  cursor: pointer;
  background: ${withProp(
    'theme.uploads.galleryThumbnail.button.background',
    transparentize(0.5)
  )};
  color: ${prop('theme.uploads.galleryThumbnail.button.color')};
  transition: background 0.2s;

  &:hover {
    background: ${prop('theme.uploads.galleryThumbnail.button.background')};
  }
`

const StyledCancelButton = styled(BaseCancelButton)`
  ${buttonStyles};
`

const StyledDeleteButton = styled(BaseDeleteButton)`
  ${buttonStyles};
`

const Thumbnail = styled(BaseThumbnail)`
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  background-color: ${prop('theme.uploads.galleryThumbnail.background')};
  overflow: hidden;
  opacity: ${ifProp(
    { saving: true },
    0.5,
    ifProp({ status: qq.status.UPLOAD_SUCCESSFUL }, 1, 0.5)
  )};
  transition: opacity 0.2s;

  .react-fine-uploader-thumbnail-image {
    display: none;
  }

  .react-fine-uploader-thumbnail-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`

const ThumbnailLinkOverlay = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: none;
  text-decoration: none;
`

const ErrorOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const ProgressBar = styled(BaseProgressBar)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 20px;
  transform: translate(-50%, -50%);
  background: ${prop('theme.uploads.galleryThumbnail.progressBar.background')};
  border-radius: 3px;

  &[hidden] {
    display: none;
  }

  .react-fine-uploader-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: ${prop('theme.uploads.galleryThumbnail.progressBar.color')};
    border-radius: 3px;
  }
`

ProgressBar.defaultProps = {
  hideBeforeStart: true,
  hideOnComplete: true,
}

const CancelButton = (props) => (
  <StyledCancelButton {...props}>
    <CloseIcon type="close" width={12} height={12} />
  </StyledCancelButton>
)

CancelButton.defaultProps = {
  onlyRenderIfCancelable: true,
}

const DeleteButton = (props) => (
  <StyledDeleteButton {...props}>
    <CloseIcon type="close" width={12} height={12} />
  </StyledDeleteButton>
)

DeleteButton.defaultProps = {
  onlyRenderIfDeletable: true,
}

const GalleryThumbnail = (props) => {
  const url = props.metadata != null ? props.metadata.url : null
  const onClick =
    props.metadata != null && typeof props.onClick === 'function'
      ? props.onClick.bind(null, props.metadata)
      : undefined

  return (
    <ThumbnailWrapper>
      <Thumbnail
        customResizer={props.customResizer}
        fromServer={props.fromServer}
        id={props.id}
        maxSize={props.maxSize}
        uploader={props.uploader}
        status={props.status}
        saving={props.saving}
      />
      {url != null && (
        // eslint-disable-next-line react/jsx-no-bind
        <ThumbnailLinkOverlay href={url} target="_blank" onClick={onClick} />
      )}
      {(props.status === qq.status.UPLOAD_FAILED ||
        props.status === qq.status.REJECTED) && (
        <ErrorOverlay>
          <ErrorIcon type="error" width={45} height={45} />
        </ErrorOverlay>
      )}
      <ProgressBar
        id={props.id}
        hideBeforeStart={props.hideProgressBarBeforeStart}
        hideOnComplete={props.hideProgressBarOnComplete}
        uploader={props.uploader}
      />
      {props.cancelButton && (
        <CancelButton
          id={props.id}
          uploader={props.uploader}
          onlyRenderIfCancelable={props.onlyRenderButtonIfCancelable}
        />
      )}
      {props.deleteButton && (
        <DeleteButton
          id={props.id}
          uploader={props.uploader}
          onlyRenderIfDeletable={props.onlyRenderButtonIfDeletable}
        />
      )}
    </ThumbnailWrapper>
  )
}

GalleryThumbnail.defaultProps = {
  customResizer: undefined,
  fromServer: false,
  status: undefined,
  saving: false,
  maxSize: 400,
  cancelButton: true,
  deleteButton: true,
  onlyRenderButtonIfCancelable: true,
  onlyRenderButtonIfDeletable: true,
  hideProgressBarBeforeStart: true,
  hideProgressBarOnComplete: true,
  metadata: null,
  onClick: null,
}

export default GalleryThumbnail
