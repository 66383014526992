/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, withHandlers, withState } from 'recompose'
import features from '../features'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import PhotoUpload from './PhotoUpload'

const Wrapper = styled.div`
  font-family: ${prop('theme.typography.paragraph.fontFamily')};
  font-size: 16px;
  font-weight: ${prop('theme.typography.paragraph.fontFamily')};
`

const PhotoUploaderSection = ({
  buttonText,
  checkboxText,
  videoUrl,
  videoText,
  updateUiState,
  prepData,
  handleUploadsChange,
  uploadedFiles,
  onUpdate,
  fileInputId,
  parameterName,
  optOutParameterName,
  handleOptedOut,
  isImages,
}) => {
  return (
    <>
      <Wrapper>
        <PhotoUpload
          buttonText={buttonText}
          checkboxText={checkboxText}
          videoUrl={videoUrl}
          videoText={videoText}
          parameterName={parameterName}
          optOutParameterName={optOutParameterName}
          fileInputId={fileInputId}
          updateUiState={updateUiState}
          prepData={prepData}
          handleUploadsChange={handleUploadsChange}
          uploadedFiles={uploadedFiles}
          onUpdate={onUpdate}
          allowOptOut={features.allowFileUploadOptOut}
          optedOut={
            isImages
              ? prepData.willBringKitchenImages
              : prepData.willBringKitchenSketchImages
          }
          setOptedOut={handleOptedOut}
          isImages={isImages}
        />
      </Wrapper>
    </>
  )
}

PhotoUploaderSection.propTypes = {
  updateUiState: PropTypes.func.isRequired,
  prepData: PropTypes.shape().isRequired,
  handleUploadsChange: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape()),
  onUpdate: PropTypes.func.isRequired,
  fileInputId: PropTypes.string.isRequired,
  parameterName: PropTypes.string.isRequired,
  optOutParameterName: PropTypes.string.isRequired,
  question: PropTypes.shape(),
}

PhotoUploaderSection.defaultProps = {
  uploadedFiles: null,
}

const enhance = compose(
  withState('optedOut', 'setOptedOut', false),

  withHandlers({
    handleUploadsChange:
      ({ updateUiState, prepData, onUpdate, parameterName }) =>
      (files) => {
        const questions = prepData.questions || []
        const question = questions.find((f) => f.question === parameterName)
        let q = {}
        const uploadedFiles = files.completed
          .filter((f) => f.url)
          .map((file) => file.url)

        if (!question) {
          q = {
            question: parameterName,
            answers: uploadedFiles,
          }
        } else {
          const filteredAnswers = question.answers.filter(
            (a) => !a.includes('http')
          )
          q = {
            question: parameterName,
            answers: [...filteredAnswers, ...uploadedFiles],
          }
        }

        updateUiState({
          uploadedFiles: files.completed.filter((f) => f.url),
          prepData: {
            ...prepData,
            questions: [
              ...questions.filter((q) => q.question !== parameterName),
              q,
            ],
          },
        })
        onUpdate(files)
      },
  }),
  withHandlers({
    handleOptedOut:
      ({ setOptedOut }) =>
      (value) =>
        setOptedOut(value),
  })
)

export default enhance(PhotoUploaderSection)
