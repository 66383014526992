import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Image from '../../image'
import { extractUrlSettings } from '../../../../utils/cloudinary'

const ImageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: content-box;
  overflow: hidden;
  justify-content: space-between;
  justify-content: ${theme('imageOption.justifyContent')};
  min-width: ${theme('imageOption.desktop.minWidth')};
  height: 100%;
  box-sizing: border-box;
  background-color: ${theme('textOption.backgroundColor')};

  ${(props) => props.theme.media.min.sm`
    width: ${theme('imageOption.wrap.desktop.width')};
  `}
`

const ImageContainer = styled.div`
  display: block;
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledImage = styled(Image)`
  width: ${(p) => theme(`quickQuestions.image.${p.questionStyle}.width`)};
  height: ${(p) => theme(`quickQuestions.image.${p.questionStyle}.height`)};
`

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 40px;
  min-width: 120px;
  margin-top: ${theme('imageOption.text.marginTop')};
  margin-bottom: ${theme('imageOption.text.marginBottom')};
  font-family: ${theme('textOption.text.fontFamily')};
  font-size: ${theme('textOption.text.fontSize.mobile')};
  font-weight: ${(props) =>
    props.active
      ? theme('textOption.text.active.fontWeight')
      : theme('textOption.text.fontWeight')};
  color: ${theme('colors.link')};
  white-space: pre-wrap;
  white-space: ${theme('textOption.text.whiteSpace')};
  text-align: center;
  text-align: ${theme('textOption.text.textAlign')};
  font-style: ${theme('textOption.text.fontStyle')};

  em {
    font-weight: 400;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${(props) => props.theme.media.min.sm`
    font-size: ${theme('textOption.text.fontSize.desktop')};
  `}
`

const ImageOption = ({ name, image, text, active, questionStyle }) => {
  const imageUrl = useMemo(() => {
    if (!image || !image.value || !image.value.url) {
      return null
    }
    const url = image.value.url
    const publicId = image.value.publicId
    if (!publicId) {
      return url
    }

    let type = image.value.type
    if (!type) {
      const { deliveryType } = extractUrlSettings(url)
      type = deliveryType
    }

    return `https://assets.nobiadigital.com/image/${type}/c_scale,w_480/q_auto/f_auto/${publicId}`
  }, [])

  return (
    <ImageWrap>
      {image && (
        <ImageContainer>
          <ImageWrapper>
            {image.value && (
              <StyledImage
                value={{ url: imageUrl, alt: name }}
                sizes="40px"
                questionStyle={questionStyle}
              />
            )}
          </ImageWrapper>
        </ImageContainer>
      )}
      {text && text.value && (
        <Text
          image={image && image.value}
          active={active}
          dangerouslySetInnerHTML={{ __html: text.value }}
        />
      )}
    </ImageWrap>
  )
}

ImageOption.defaultProps = {
  text: null,
  image: null,
  active: null,
  questionStyle: 'style1',
}

ImageOption.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.shape(),
  image: PropTypes.shape({ value: PropTypes.shape() }),
  active: PropTypes.bool.isRequired,
  questionStyle: PropTypes.string,
}

export default ImageOption
