// Namespace for custom claims
export const CUSTOM_CLAIM_NAMESPACE = 'https://nobiadigital.com/'
// Whitelist of claims to consider part of user "profile"
export const PROFILE_CLAIM_WHITELIST = ['given_name', 'family_name']

// Enum values for authetication status
export const AUTH_STATUS_UNAUTHENTICATED = 'unauthenticated'
export const AUTH_STATUS_AUTHENTICATING = 'authenticating'
export const AUTH_STATUS_AUTHENTICATED = 'authenticated'

// action types
export const AUTH_REQUEST_ACTION_TYPE = 'auth/AUTH_REQUEST'
export const AUTH_REQUEST_SUCCESS_ACTION_TYPE = 'auth/AUTH_REQUEST_SUCCESS'
export const AUTH_REQUEST_ERROR_ACTION_TYPE = 'auth/AUTH_REQUEST_ERROR'

export const AUTH_REFRESH_SUCCESS_ACTION_TYPE = 'auth/AUTH_REFRESH_SUCCESS'
