import {
  AUTH_STATUS_AUTHENTICATED,
  AUTH_STATUS_AUTHENTICATING,
  AUTH_STATUS_UNAUTHENTICATED,
  AUTH_REQUEST_ACTION_TYPE,
  AUTH_REQUEST_SUCCESS_ACTION_TYPE,
  AUTH_REQUEST_ERROR_ACTION_TYPE,
  AUTH_REFRESH_SUCCESS_ACTION_TYPE,
} from './constants'

const authReducer = (state, action) => {
  switch (action.type) {
    case AUTH_REQUEST_ACTION_TYPE:
      return {
        ...state,
        previousStatus: state.status,
        status: AUTH_STATUS_AUTHENTICATING,
        error: null,
      }
    case AUTH_REQUEST_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        previousStatus: state.status,
        status: AUTH_STATUS_AUTHENTICATED,
        session: action.payload,
        error: null,
      }
    case AUTH_REQUEST_ERROR_ACTION_TYPE:
      return {
        ...state,
        previousStatus: state.status,
        status: AUTH_STATUS_UNAUTHENTICATED,
        error: action.payload,
        session: null,
      }
    case AUTH_REFRESH_SUCCESS_ACTION_TYPE:
      return {
        ...state,
        previousStatus: state.status,
        status: AUTH_STATUS_AUTHENTICATED,
        session: {
          ...state.session,
          ...action.payload,
        },
        error: null,
      }
    default:
      return {
        previousStatus: null,
        status: AUTH_STATUS_UNAUTHENTICATED,
        error: null,
        session: null,
      }
  }
}

export default authReducer
