import React from 'react'
import styled from 'styled-components'
import { badaPropTypes, badaDefaultProps } from '../../../../types'

const Wizard = styled.div`
  padding: 20px;
`

const WizardBlock = styled.div`
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
`

const WizardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const WizardStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
  position: relative;

  &::before {
    content: '';
    z-index: 2;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 1px solid #bfbfbf;
    background: white;
    ${(props) =>
      props.activeKey === props.index &&
      `
      border: 1px solid #222;
    `}
    ${(props) =>
      props.activeKey >= props.index &&
      `
      background-color: #bfbfbf;
    `}
  }

  &::after {
    content: '';
    ${(props) =>
      props.index === 0 &&
      `
      content: none;
    `}
    ${(props) =>
      props.index <= props.activeKey &&
      `
      background-color: #bfbfbf;
    `}
    position: absolute;
    z-index: 1;
    border: 1px solid #bfbfbf;
    top: 20px;
    left: -50%;
    width: 100%;
    height: 10px;
  }
`

const CircleLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const StepWizard = ({ wizard: { stage } }) => (
  <Wizard>
    <WizardBlock>
      <WizardRow>
        {stage.map((s) => (
          <CircleLabel
            key={s.key}
            index={s.key}
            activeKey={stage.find((i) => i.active).key}
          >
            {s.label}
          </CircleLabel>
        ))}
      </WizardRow>
    </WizardBlock>
    <WizardBlock>
      <WizardRow>
        {stage.map((s) => (
          <WizardStep
            key={s.key}
            index={s.key}
            activeKey={stage.find((i) => i.active).key}
          />
        ))}
      </WizardRow>
    </WizardBlock>
  </Wizard>
)

StepWizard.propTypes = {
  ...badaPropTypes,
}

StepWizard.defaultProps = {
  ...badaDefaultProps,
}

export default StepWizard
