import * as React from 'react'
import get from 'lodash/get'
import memoizeOne from 'memoize-one'
import filterProps from '../../../utils/filterProps'
import withBreakpoints from './withBreakpoints'

const globalFilteredKeys = [
  'debug',
  'breakpoints',
  'mediaQueries',
  'filteredKeys',
]

const breakpointPropTypes = ['Offset', 'Order']

const createBlacklist = (filteredKeys) => (props) => {
  const blacklist = [...globalFilteredKeys, ...filteredKeys]

  if (get(props, 'mediaQueries.keys') == null) {
    return blacklist
  }

  return [
    ...blacklist,
    ...props.mediaQueries.keys.reduce((acc, key) => {
      acc.push(key)
      breakpointPropTypes.forEach((type) => acc.push(`${key}${type}`))
      return acc
    }, []),
  ]
}

const compareKeys = (a, b) =>
  Object.is(get(a, 'mediaQueries.keys'), get(b, 'mediaQueries.keys'))

const createGridComponent = (id, filteredKeys) => {
  const getBlacklist = memoizeOne(createBlacklist(filteredKeys), compareKeys)

  const GridComponent = React.forwardRef(({ component, ...props }, ref) =>
    React.createElement(component, {
      ...filterProps(props, getBlacklist(props)),
      ref,
    })
  )

  GridComponent.defaultProps = {
    component: 'div',
  }

  return withBreakpoints(GridComponent)
}

export default createGridComponent
