import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

const PageTitle = styled.h1`
  font-family: ${prop('theme.typography.pageTitle.fontFamily')};
  font-size: ${prop('theme.typography.pageTitle.fontSize')};
  font-weight: ${prop('theme.typography.pageTitle.fontWeight')};
  line-height: ${prop('theme.typography.pageTitle.lineHeight')};
  margin-bottom: ${prop('theme.typography.pageTitle.marginBottom')};
  margin-top: ${prop('theme.typography.pageTitle.marginTop')};
  text-transform: ${prop('theme.typography.pageTitle.textTransform')};
  letter-spacing: ${prop('theme.typography.pageTitle.letterSpacing')};

  color: ${prop('theme.colors.primary')};

  text-align: ${prop('align')};

  ${(props) =>
    props.noMargins &&
    css`
      margin: 0;
    `};

  ${(props) =>
    props.theme.media.min.sm`
      font-size: ${props.theme.typography.pageTitle.fontSizeLarge};
      line-height: ${props.theme.typography.pageTitle.lineHeightLarge};
    `};
`

export default PageTitle
