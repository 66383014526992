import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'

import Label from './label'

const OuterDot = styled.span`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid ${prop('theme.forms.colors.border')};
  background-color: ${prop('theme.forms.colors.background')};
  vertical-align: middle;
`

const InnerDot = styled.span`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${prop('theme.forms.colors.radioButtonSelected')};
  transform: scale(0, 0);
  transition: opacity, transform 0.1s ease-in;
`

const StyledInput = styled.input.attrs({ type: 'radio' })`
  display: none;
  &:checked + ${OuterDot} ${InnerDot} {
    transform: scale(1, 1);
  }
`

const RadioLabel = styled(Label)`
  display: inline-block;
  margin-right: 24px;
  white-space: nowrap;
`

const StyledSpan = styled.span`
  display: inline-block;
  vertical-align: middle;
`

const RadioButton = (props) => {
  const { name, value, label, ...rest } = props
  const input = (
    <StyledInput name={props.name} value={props.value} {...rest} data-cs-mask />
  )

  return (
    <RadioLabel>
      {input}
      <OuterDot>
        <InnerDot />
      </OuterDot>
      <StyledSpan>{props.label}</StyledSpan>
    </RadioLabel>
  )
}

RadioButton.defaultProps = {
  label: '',
}

export default RadioButton
