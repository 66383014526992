import React, { Component } from 'react'
import { qq } from 'fine-uploader-wrappers/azure'

const isUploadComplete = (status) =>
  [
    qq.status.UPLOAD_FAILED,
    qq.status.UPLOAD_SUCCESSFUL,
    qq.status.CANCELED,
  ].includes(status)

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedBytes: null,
      totalBytes: null,
      hidden: this.props.hideBeforeStart,
    }
    this.mounted = false
    this.onTotalProgress = this.onTotalProgress.bind(this)
    this.onProgress = this.onProgress.bind(this)
    this.onStatusChange = this.onStatusChange.bind(this)
  }

  componentDidMount() {
    this.mounted = true

    if (this.props.id == null) {
      this.props.uploader.on('totalProgress', this.onTotalProgress)
    } else {
      this.props.uploader.on('progress', this.onProgress)
    }

    this.props.uploader.on('statusChange', this.onStatusChange)
  }

  componentWillUnmount() {
    this.mounted = false

    this.props.uploader.off('totalProgress', this.onTotalProgress)
    this.props.uploader.off('progress', this.onProgress)
    this.props.uploader.off('statusChange', this.onStatusChange)
  }

  onTotalProgress(uploadedBytes, totalBytes) {
    this.setState({ uploadedBytes, totalBytes })
  }

  onProgress(id, name, uploadedBytes, totalBytes) {
    if (id === this.props.id) {
      this.setState({ uploadedBytes, totalBytes })
    }
  }

  onStatusChange(id, oldStatus, newStatus) {
    if (this.mounted) {
      this.setState((state) => {
        const { hideOnComplete } = this.props
        const isComplete = isUploadComplete(newStatus)

        if (this.props.id == null) {
          const isInProgress = this.props.uploader.methods.getInProgress()

          if (isComplete && hideOnComplete && !state.hidden && !isInProgress) {
            return { hidden: true }
          }

          if (state.hidden && isInProgress) {
            return { hidden: false }
          }
        } else if (this.props.id === id) {
          const isUploading = newStatus === qq.status.UPLOADING

          if (isUploading && state.hidden) {
            return { hidden: false }
          }

          if (isComplete && hideOnComplete && !state.hidden) {
            return { hidden: true }
          }
        }

        return null
      })
    }
  }

  render() {
    const { uploadedBytes, totalBytes, hidden } = this.state
    const {
      className,
      progressBarClassName,
      hideBeforeStart,
      hideOnComplete,
      uploader,
      ...props
    } = this.props

    const percentWidth =
      uploadedBytes != null && totalBytes != null
        ? (uploadedBytes / totalBytes) * 100 || 0
        : 0

    return (
      <div className={className} hidden={hidden} {...props}>
        <div
          role="progressbar"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-valuenow={percentWidth}
          className={progressBarClassName}
          style={{ width: `${percentWidth}%` }}
        />
      </div>
    )
  }
}

export default ProgressBar
