import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import PropTypes from 'prop-types'
import settings from '../settings'
import Link from '../typography/link'
import { mobileCheck } from '../../containers/bada/utils'
import { storeType } from '../../../types'
import useTranslation from '../../../utils/translation'
import useBrandConfig from '../../hooks/useBrandConfig'

const StyledWrapper = styled.div`
  border-width: ${theme('bookingSummary.borderWidth')};
  border-style: ${theme('bookingSummary.borderStyle')};
  border-color: ${theme('bookingSummary.borderColor')};
  border-radius: ${theme('bookingSummary.borderRadius')};
  margin-top: 20px;
  padding: 22px 30px;
  padding: ${theme('bookingSummary.padding')};
  color: ${theme('colors.darkGrey')};
  background-color: ${theme('bookingSummary.backgroundColor')};
`

const StyledDt = styled.dt`
  color: ${theme('bookingSummary.itemTitle.color')};
  text-transform: ${theme('bookingSummary.itemTitle.textTransform')};
  font-family: ${theme('bookingSummary.itemTitle.fontFamily')};
  font-weight: ${theme('bookingSummary.itemTitle.fontWeight')};
  font-size: ${theme('bookingSummary.itemTitle.fontSize')};
  margin-bottom: ${theme('bookingSummary.itemTitle.marginBottom')};
  margin-top: ${theme('bookingSummary.itemTitle.marginTop')};
  text-transform: ${theme('bookingSummary.itemTitle.textTransform')};
  letter-spacing: ${theme('bookingSummary.itemTitle.letterSpacing')};
  display: block;
`

const StyledDd = styled.dd`
  margin-left: 0;
  margin-right: 0;
  margin-bottom: ${theme('bookingSummary.itemText.marginBottom')};
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-weight: ${theme('typography.paragraph.fontWeight')};
  color: ${theme('colors.darkGrey')};
  font-size: ${theme('bookingSummary.itemText.fontSize')};
  line-height: ${theme('bookingSummary.itemText.lineHeight')};
`

const StyledSpan = styled.span`
  text-transform: capitalize;
  font-family: ${theme('typography.paragraph.fontFamily')};
  font-size: ${theme('bookingSummary.itemText.fontSize')};
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StoreNameContainer = styled.div`
  display: ${theme('bookingSummary.storeName.display')};
  font-size: ${theme('bookingSummary.itemText.fontSize')};
  margin-bottom: ${theme('bookingSummary.storeName.marginBottom')};
  line-height: ${theme('bookingSummary.itemText.lineHeight')};
`

const ContactDetails = ({ store }) => {
  const { email, phoneNumber, latitude, longitude } = store
  const t = useTranslation('app.summary')
  return (
    <>
      <StyledLink
        href={`${settings.mapUrl}${latitude},${longitude}`}
        target="_blank"
      >
        {t('store.map')}
      </StyledLink>
      <br />
      {mobileCheck() ? (
        <Link href={`tel:${phoneNumber.replace(/[^0-9.]+/g, '')}`}>
          {phoneNumber}
        </Link>
      ) : (
        phoneNumber
      )}
      <br />
      <StyledLink href={`mailto:${email}`}>{email}</StyledLink>
    </>
  )
}

const timeString = (dateTime) => {
  const time = dateTime.split('T')[1].split(':')
  return `${time[0]}:${time[1]}`
}

const getFormattedDate = (date, locale) => {
  if (!date) return null
  const currentDate = new Date(date)
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  return new Intl.DateTimeFormat(locale, options).format(currentDate)
}

const BookingSummary = ({
  store,
  meetingType,
  selectedSlot,
  customerComment,
  booking,
  serviceContent,
}) => {
  const isSlotSelected = selectedSlot && selectedSlot.from
  const showBookingDetails = !booking || (booking && !booking.error)
  const showStoreContactDetails = booking
  const showPreConfirmationInfo = !booking && isSlotSelected
  const t = useTranslation('app.summary')
  const brandConfig = useBrandConfig(settings.brand).data
  return (
    <StyledWrapper>
      <dl>
        {store && (
          <>
            <StyledDt>{t('store')}</StyledDt>
            <StyledDd>
              <StoreNameContainer>
                {store.name}
                <br />
              </StoreNameContainer>
              {store.address}
              <br />
              {showStoreContactDetails && <ContactDetails store={store} />}
            </StyledDd>
          </>
        )}

        {showBookingDetails && meetingType && (
          <>
            <StyledDt>{t('type')}</StyledDt>
            <StyledDd>{meetingType.name}</StyledDd>
          </>
        )}

        {showBookingDetails && isSlotSelected && (
          <>
            <StyledDt>{t('date')}</StyledDt>
            <StyledDd>
              <StyledSpan>
                {selectedSlot &&
                  selectedSlot.from &&
                  brandConfig &&
                  brandConfig.languageTag &&
                  getFormattedDate(selectedSlot.from, brandConfig.languageTag)}
              </StyledSpan>
            </StyledDd>
            <StyledDt>{t('time')}</StyledDt>
            <StyledDd>
              {`${timeString(selectedSlot.from)} - ${timeString(
                selectedSlot.to
              )}`}
            </StyledDd>
          </>
        )}
      </dl>
      {showBookingDetails && customerComment && (
        <>
          <StyledDt>{t('comment')}</StyledDt>
          <StyledDd>{customerComment}</StyledDd>
        </>
      )}
      {showPreConfirmationInfo && (
        <>
          <StyledDd>{serviceContent.confirmationInfo}</StyledDd>
        </>
      )}
    </StyledWrapper>
  )
}

ContactDetails.propTypes = {
  store: storeType,
}

ContactDetails.defaultProps = {
  store: null,
}

BookingSummary.propTypes = {
  store: storeType,
  meetingType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  booking: PropTypes.shape({
    error: PropTypes.shape(),
  }),
  selectedSlot: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  customerComment: PropTypes.string,
  serviceContent: PropTypes.shape(),
}

BookingSummary.defaultProps = {
  selectedSlot: null,
  store: null,
  meetingType: null,
  customerComment: null,
  booking: null,
  serviceContent: null,
}

export default BookingSummary
