import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { theme } from 'styled-tools'

const StyledLabel = styled.label`
  width: 100%;
  height: 100%;
  position: relative;
`

const StyledBg = styled.div`
  bottom: -1px;
  left: -1px;
  top: -1px;
  position: absolute;
  right: -1px;
  border-radius: 3px;
`

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  border: 1px solid ${theme('colors.inputBorder')};
  border-radius: ${theme('radioButtonRich.borderRadius')};
  min-height: 100%;
  transition: border-color 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme('colors.buttonBackgroundColor')};
  :hover {
    cursor: pointer;
    border-color: ${theme('colors.activeBorder')};
  }
`

const StyledInput = styled.input.attrs({ type: 'radio' })`
  display: none;
  &:checked + ${StyledWrapper} {
    padding: 0;
    border-color: ${theme('colors.accent')};
    border-color: ${theme('colors.selectedOptionBorder')};
    box-shadow: ${theme('colors.accentShadow')};
    background-color: ${theme('colors.selectedOption')};
  }
`

const RadioButtonRich = ({ name, value, checked, onChange, children }) => (
  <StyledLabel>
    <StyledInput
      name={name}
      value={value}
      checked={checked}
      onChange={() => onChange(value)}
    />
    <StyledWrapper>
      <StyledBg />
      {children}
    </StyledWrapper>
  </StyledLabel>
)

RadioButtonRich.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
}

RadioButtonRich.defaultProps = {
  children: null,
  checked: null,
}

export default RadioButtonRich
