import React from 'react'
import styled from 'styled-components'
import settings from '../../../components/settings'
import StoreSelection from '../../../components/store-selection/StoreSelection'
import MeetingTypeSelection from '../../../components/meeting-type-selection'
import SlotSelection from '../../../components/slot-selection'
import CustomerForm from '../../../components/customer-form'
import Confirmation from '../../../components/confirmation'
import Loader from '../../../components/loader/Loader'

const Row = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  position: relative;
  ${(p) =>
    p.show &&
    `
    margin-bottom: 100px;
  `}
`

const SelectLoader = styled.div`
  height: 42px;
  padding: 26px;
`

const LoaderWithProps = (props) =>
  props.loading && (
    <SelectLoader>
      <Loader duration={3} />
    </SelectLoader>
  )

const StepStores = ({
  show,
  props: {
    pageContent,
    storeId,
    store,
    stores,
    selectedAddress,
    selectedCity,
    selectedStore,
    checked,
    onChange,
    storesByAddressData,
    customerAddress,
    storeIsPreSelectable,
  },
}) =>
  show && (
    <StoreSelection
      storeIsPreSelectable={storeIsPreSelectable}
      pageContent={pageContent && pageContent.page}
      storeId={storeId}
      store={store}
      checked={checked}
      stores={
        stores &&
        stores.filter(
          (item) =>
            settings.excludedStudioTypes.indexOf(item.storeType) === -1 &&
            !item.hideInBada
        )
      }
      onChange={onChange}
      selectedAddress={selectedAddress}
      selectedCity={selectedCity}
      selectedStore={selectedStore}
      customerAddress={customerAddress}
      loading={storesByAddressData.loading}
      error={storesByAddressData.error != null}
    />
  )

const StepMeetingType = ({ show, refProp, props }) => {
  if (!show) return null
  return (
    <Row ref={refProp}>
      <MeetingTypeSelection {...props} />
    </Row>
  )
}

const StepBookingSlot = ({
  show,
  props: {
    pageContent,
    selectedStore,
    selectedService,
    onChange,
    onContinue,
    onMount,
    updateUiState,
  },
}) =>
  show && (
    <>
      <Row>
        <SlotSelection
          pageContent={pageContent && pageContent.page}
          selectedStore={selectedStore}
          selectedService={selectedService}
          onChange={onChange}
          onContinue={onContinue}
          onMount={onMount}
          updateUiState={updateUiState}
        />
      </Row>
    </>
  )

const StepSubmit = ({
  show,
  props: {
    selectedStore,
    selectedSlot,
    selectedService,
    pageContent,
    onUpdate,
    onMount,
    values,
    addHistory,
    updateUiState,
    overrideMeetingType,
  },
}) =>
  show && (
    <CustomerForm
      selectedService={selectedService}
      selectedSlot={selectedSlot}
      selectedStore={selectedStore}
      updateUiState={updateUiState}
      addHistory={addHistory}
      onUpdate={onUpdate}
      values={values}
      onMount={onMount}
      pageContent={pageContent && pageContent.page}
      overrideMeetingType={overrideMeetingType}
    />
  )

const StepConfirmation = ({
  show,
  props: {
    selectedStore,
    selectedSlot,
    selectedService,
    booking,
    pageContent,
    values,
    onMount,
    staff,
    appointmentId,
    appointment,
    returningVisitor,
  },
}) =>
  show && (
    <Confirmation
      selectedStore={selectedStore}
      selectedService={selectedService}
      selectedSlot={selectedSlot}
      booking={booking}
      pageContent={pageContent && pageContent.page}
      customerValues={values}
      onMount={onMount}
      staff={staff}
      appointmentId={appointmentId}
      appointment={appointment}
      returningVisitor={returningVisitor}
    />
  )

export {
  StepStores,
  StepMeetingType,
  StepBookingSlot,
  StepSubmit,
  StepConfirmation,
  LoaderWithProps,
}
