import React from 'react'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'

const icon = (
  <svg
    width="11px"
    height="8px"
    viewBox="0 0 11 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      id="Triangle-3"
      fill="#000000"
      points="5.5625 7.11853966 0.583984375 0.225210337 10.5410156 0.225210337"
    />
  </svg>
)

const SelectWrapper = styled.span`
  position: relative;
  display: block;
  background-color: ${prop('theme.forms.colors.background')};
  border-radius: ${prop('theme.forms.borderRadius')};
  border: 1px solid ${prop('theme.forms.colors.border')};
  min-width: 100px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
    `};
  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${props.theme.forms.colors.activeBorder};
      color: ${props.theme.forms.colors.active};
    `};
  > svg {
    position: absolute;
    top: 18px;
    right: 10px;
    z-index: 5;
    pointer-events: none;
  }
`
const getSelectHeight = (props) =>
  props.theme.forms.select?.height || props.theme.forms.height
const getSelectLineHeight = (props) =>
  props.theme.forms.select?.lineHeight || props.theme.forms.height

const SelectUI = styled.select`
  appearance: none;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 100px;
  height: ${getSelectHeight};
  outline: none;
  border: 0;
  padding: 0px 26px 0px 12px;
  font-family: ${prop('theme.forms.fontFamily')};
  font-size: ${prop('theme.forms.fontSize')};
  font-weight: ${prop('theme.forms.fontWeight')};
  line-height: ${getSelectLineHeight};
  color: ${prop('theme.forms.colors.color')};
  background-color: ${prop('theme.forms.colors.background')};
  z-index: 1;
  border-radius: ${prop('theme.forms.select.borderRadius')};
  ${(props) =>
    props.theme.media.min.sm`font-size:
      ${props.theme.forms.fontSizeDesktop};`};
  ::-ms-expand {
    display: none;
  }
  > option {
    font-weight: ${prop('theme.forms.fontWeight')};
  }
`

/**
 * @param {{
 *  options: {value: any, label: string, disabled: boolean}[],
 *  value: string,
 *  onChange: (event: any) => void}} props Component properties.
 */
const Select = ({ options, onChange, value }) => (
  <SelectWrapper>
    <SelectUI onChange={onChange} defaultValue={value}>
      {options
        .filter((option) => option.value)
        .map((option) => (
          <option key={option.value} value={option.value}>
            {option.disabled ? `${option.label} - Not available` : option.label}
          </option>
        ))}
    </SelectUI>
    {icon}
  </SelectWrapper>
)

export default Select
