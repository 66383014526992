import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { prop, theme } from 'styled-tools'
import settings from '../../../../settings'
import Title from '../../../../typography/title'
import RadioButtonRich from '../../../../toggle'
import Loader from '../../../../loader/Loader'
import useTranslation from '../../../../../../utils/translation'
import TemporarilyClosed from './TemporarilyClosed'

const StoreItemContainer = styled.div`
  margin-bottom: 10px;
  flex-basis: 100%;

  ${(props) => props.theme.media.min.md`
    flex-basis: calc(50% - 7px);
  `}
  background-color: ${theme('colors.buttonBackgroundColor') || 'inherit'};
  border-color: ${theme('colors.lightGrey')};
`

const StyledStoreItem = styled.div`
  width: 100%;
  height: 100%;
  padding: ${theme('typography.storeAddress.paddingMobile')};
  ${(props) => props.theme.media.min.md`
    padding: 20px 20px 16px 20px;
    padding: ${theme('typography.storeAddress.padding')};
  `}
`
const StoreHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme('typography.storeHeader.marginTop')};
  margin-bottom: ${theme('typography.storeHeader.marginBottom')};
`

const StoreName = styled(Title)`
  font-size: ${theme('typography.storeName.mobile.fontSize')};
  font-family: ${theme('typography.storeName.fontFamily')};
  font-weight: ${theme('typography.storeName.fontWeight')};
  text-transform: ${theme('typography.storeName.textTransform')};
  letter-spacing: ${theme('typography.storeName.letterSpacing')};
  margin: 0;
  margin-bottom: ${theme('typography.storeName.marginBottom')};
  margin-top: ${theme('typography.storeName.marginTop')};
  color: ${(p) => (p.inactive ? '#ccc' : theme('typography.storeName.color'))};

  padding-right: 16px;

  ${(props) => props.theme.media.min.md`
    font-size: ${theme('typography.storeName.fontSize')};
  `}
`

const StoreDistance = styled.div`
  font-size: ${theme('stores.distance.fontSize')};
  color: ${theme('colors.darkGrey')};
  font-family: ${prop('theme.typography.paragraph.fontFamily')};
  font-weight: ${prop('theme.typography.storeDistance.fontWeight')};
  margin-top: ${prop('theme.typography.storeDistance.marginTop')};
  margin-bottom: ${prop('theme.typography.storeDistance.marginBottom')};
  white-space: nowrap;
`
const StoreAddress = styled.div`
  color: ${theme('typography.storeAddress.color')};
  font-family: ${prop('theme.typography.paragraph.fontFamily')};
  font-size: ${theme('typography.storeAddress.fontSize' || '16px')};
  font-weight: ${theme('typography.storeAddress.fontWeight')};
  margin-bottom: ${theme('typography.storeAddress.marginBottom')};
  margin-top: ${theme('typography.storeAddress.top')};
`

const getDistance = (distance) => {
  if (settings.distanceUnit === 'mi') {
    const miles = Math.round(Number(distance) * 0.621371)
    return `${miles} ${settings.distanceUnit}`
  }
  return `${distance} km`
}

const StoreItem = ({ store, onChange, checked }) => {
  const t = useTranslation('app.loader.text')
  return (
    <StoreItemContainer key={store.id} data-cy={`StoreItem-${store.id}`}>
      <RadioButtonRich
        name="store"
        value={store.id}
        checked={checked}
        onChange={() => onChange(store)}
      >
        {store.id !== 'store-loading' ? (
          <StyledStoreItem key={`${store.id}${Math.random()}`}>
            <StoreHeader>
              <StoreName active={store.id}>{store.name || 'loading'}</StoreName>
              <StoreDistance>
                {`${getDistance(store.distanceInKilometers)}`}
              </StoreDistance>
            </StoreHeader>
            <StoreAddress>{store.address}</StoreAddress>
            {store.isTemporarilyClosed &&
              store.temporarilyClosedInformation && (
                <TemporarilyClosed text={store.temporarilyClosedInformation} />
              )}
          </StyledStoreItem>
        ) : (
          <StyledStoreItem inactive key={`${Math.random()}`}>
            <StoreHeader>
              <StoreName>
                <Loader
                  inline={'true'}
                  size="small"
                  duration={2}
                  text={t('store')}
                />
              </StoreName>
              <StoreDistance>&nbsp;</StoreDistance>
            </StoreHeader>
            <StoreAddress>&nbsp;</StoreAddress>
          </StyledStoreItem>
        )}
      </RadioButtonRich>
    </StoreItemContainer>
  )
}

StoreItem.propTypes = {
  store: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    distanceInKilometers: PropTypes.number,
  }),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

StoreItem.defaultProps = {
  store: null,
  checked: null,
  onChange: () => null,
}

export default StoreItem
