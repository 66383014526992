import React from 'react'
import { SectionItem, SectionItemText, SectionItemHeading } from '../sharedUI'
import Select from './Select'
import useTranslation from '../../../../utils/translation'

/**
 * @param {{
 *  onChange: (args: any) => any,
 *  serviceProviderId: string,
 *  serviceProviderName: string,
 *  serviceProviderOptions: {
 *    value: any,
 *    label: string,
 *    disabled: boolean
 *  }[],
 *  canceled: boolean}} props
 */
const ServiceProviderEditor = ({
  onChange,
  serviceProviderId,
  serviceProviderName,
  serviceProviderOptions,
  canceled,
}) => {
  const t = useTranslation('appointment-details')
  const designer = serviceProviderOptions.find(
    (option) => `${option.value}` === `${serviceProviderId}`
  )
  if (!designer) {
    serviceProviderOptions.unshift({
      label: serviceProviderName,
      value: serviceProviderId,
      disabled: true,
    })
  }

  return (
    <SectionItem>
      <SectionItemHeading>{t('chosen-service-provider')}</SectionItemHeading>
      {!canceled && (
        <Select
          options={serviceProviderOptions}
          value={serviceProviderId}
          onChange={onChange}
        />
      )}
      {canceled && <SectionItemText>N/A (cancelled)</SectionItemText>}
    </SectionItem>
  )
}

export default ServiceProviderEditor
