import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { prop, ifProp } from 'styled-tools'

const bouncyAnimation = keyframes`
  0%, 80%, 100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 20px);
  }
`

const sizeStyle = (props) => {
  switch (props.size) {
    case 'small':
      return '0.25em'
    case 'large':
      return '1.25em'
    default:
      return '0.75em'
  }
}

const BouncyWrapper = styled.div`
  margin: 0 auto;
  width: 11.5em;
  height: 2.5em;
  text-align: center;
  font-size: ${sizeStyle};
  color: ${prop('color')};
  opacity: 0.7;
  ${ifProp(
    'center',
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `
  )}
`

const Bubble1 = styled.div`
  animation: ${(props) =>
    css`
      ${props.duration}s ${bouncyAnimation} ease-in-out infinite both
    `};
  animation-delay: ${(props) => `${props.duration * -0.32}s`};
  width: 1.5em;
  height: 1.5em;
  background-color: currentColor;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1.5em;
`

const Bubble2 = styled(Bubble1)`
  animation-delay: ${(props) =>
    css`
      ${props.duration * -0.16}s
    `};
`

const Bubble3 = styled(Bubble1)`
  animation-delay: 0s;
  margin-right: 0em;
`

const BubbleLoader = ({ duration, color, size, ...rest }) => (
  <BouncyWrapper color={color} size={size} {...rest}>
    <Bubble1 duration={duration} />
    <Bubble2 duration={duration} />
    <Bubble3 duration={duration} />
  </BouncyWrapper>
)

BubbleLoader.defaultProps = {
  duration: 0.7,
  color: '#000',
  size: null,
}

export default BubbleLoader
