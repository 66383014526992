import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import features from '../features'
import settings from '../settings'
import Headline from '../typography/headline'
import {
  ensureSubsystem,
  hideByVisibility,
  hideFormMeetingTypeIfMatchingSlot,
  isDisabled,
  matchingSlotType,
} from '../../../utils/meetingType'
import { getState } from '../../../utils/state'
import { getDisplayData } from '../../containers/bada/content'
import { mobileCheck, toSimpleService } from '../../containers/bada/utils'
import { MeetingTypeList } from '../meeting-types/MeetingTypes'
import MeetingTypeItem from './MeetingTypeItem'
import { SIMPLY_BOOK_ME } from '../../containers/bada/reducer/Wizard'
import { fetchServices } from '../../containers/bada/actions'

const HeadlineDesktop = styled(Headline)`
  display: none;
  margin-bottom: 20px;
  text-transform: ${theme('typography.subHeadline.textTransform')};
  line-height: ${theme('typography.subHeadline.lineHeightDesktop')};
  ${(p) => p.theme.media.min.md`
    display: block;
  `};
`

const HeadlineMobile = styled(Headline)`
  margin-bottom: 20px;
  text-transform: ${theme('typography.subHeadline.textTransform')};
  ${(p) => p.theme.media.min.md`
    display: none;
  `}
`
export const filterMeetingTypes = (meetingtypes, selectedStore, prepformOnly, categoryId, client) =>
  meetingtypes
    .filter(
      (meetingType) => !isDisabled(meetingType.id, selectedStore.services)
    )
    .filter((meetingType) =>
      ensureSubsystem(
        meetingType,
        selectedStore.simplyBookMeSubsystem,
        settings.serviceMappings
      )
    )
    .filter((meetingType) =>
      matchingSlotType(
        meetingType.serviceType,
        selectedStore.bookingSystem,
        selectedStore.hasSimplyBookMe
      )
    )
    .filter(
      (meetingType, index, result) =>
        !hideFormMeetingTypeIfMatchingSlot(
          meetingType.serviceType,
          meetingType.id,
          result
        )
    )
    .filter(
      (meetingType) =>
        !hideByVisibility(
          meetingType.id,
          meetingType.visibility,
          selectedStore.bookingSystem,
          selectedStore.hasSimplyBookMe,
          selectedStore.acceptHomeVisits,
          !!prepformOnly,
          features.allowDisableHomeVisits
        )
    )
    .filter(meetingType => {
      if (categoryId && client === 'kratos') {
        return meetingType.categoryId === `${categoryId}`
      }
      return true
    })

const renderHeading = (displayData) => (
  <>
    {displayData.serviceSelectionHeadingDesktop && (
      <HeadlineDesktop>
        {displayData.serviceSelectionHeadingDesktop}
      </HeadlineDesktop>
    )}
    {displayData.serviceSelectionHeadingMobile && (
      <HeadlineMobile>
        {displayData.serviceSelectionHeadingMobile}
      </HeadlineMobile>
    )}
  </>
)

const MeetingTypeSelection = ({
  onChange,
  pageContent,
  selectedService,
  selectedStore,
  appointmentType,
  services,
}) => {
  if (!pageContent || !pageContent.id || !selectedStore) {
    return null
  }

  const displayData = getDisplayData(pageContent)

  if (!displayData.services || displayData.services.length < 1) {
    return <h1>No services setup in CMS</h1>
  }

  if (selectedStore.hideInBada && selectedStore.hideInBada === true) {
    return null
  }

  const [meetingTypes, setMeetingTypes] = useState([])
  const [preSelectedId, setPreSelectedId] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const params = new URLSearchParams(window.location.search)
  const usePrepFormOnly = params.get('prepform')

  useEffect(() => {
    const fetchedMeetingTypes = filterMeetingTypes(
      displayData.services,
      selectedStore,
      !!usePrepFormOnly
    )

    setMeetingTypes(fetchedMeetingTypes)

    const { preSelectedMeetingTypeId } = getState(settings.brand)

    setPreSelectedId(preSelectedMeetingTypeId)
  }, [selectedStore])

  useEffect(() => {
    const preselectedMeetingType =
      meetingTypes &&
      meetingTypes.find((c) => c.id === parseInt(preSelectedId, 10))

    if (preselectedMeetingType) {
      onChange({
        selectedService: toSimpleService(preselectedMeetingType),
        selectedSlot: null,
        overrideMeetingType: null,
        preSelectedMeetingTypeId: preSelectedId,
      })
    }
  }, [meetingTypes, preSelectedId])

  function meetingTypeChanged(service) {
    if (service.serviceType === SIMPLY_BOOK_ME) {
      setDisabled(true)
    }
    onChange({
      selectedService: toSimpleService(service),
      selectedSlot: null,
      overrideMeetingType: null,
      preSelectedMeetingTypeId: null,
      bookingCompleted: false,
    })

    const timeout = setTimeout(() => {
      setDisabled(false)
      clearTimeout(timeout)
    }, 2500)
  }

  return (
    <>
      {renderHeading(displayData)}

      <MeetingTypeList>
        {meetingTypes &&
          meetingTypes.map((service) => (
            <MeetingTypeItem
              key={`${service.id}-${service.serviceType}`}
              onChange={() => meetingTypeChanged(service)}
              props={{
                id: service.code,
                name: service.heading,
                text: mobileCheck()
                  ? service.descriptionMobile
                  : service.descriptionDesktop,
                icon: service.icon,
                checked:
                  !!selectedService &&
                  selectedService.id === service.code &&
                  selectedService.serviceId === service.id,
                serviceId: service.id,
                serviceCode: service.serviceCode,
                serviceType: service.serviceType,
                serviceDuration: services?.services?.find(
                  (s) => s.id === service.id
                )?.duration,
                appointmentType,
                disabled,
              }}
            />
          ))}
      </MeetingTypeList>
    </>
  )
}

const enhance = compose(fetchServices)

export default enhance(MeetingTypeSelection)
