import React from 'react'
import PropTypes from 'prop-types'

const Information = ({ width, height }) => (
  <svg width={width} height={height} viewBox="-2 -2 28 28">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Icons-/-Better-Blue-/-info"
        stroke="#122126"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <circle id="Oval" cx="12" cy="12" r="12" />
        <line x1="12" y1="16.8" x2="12" y2="12" id="Shape" />
        <line x1="12" y1="7" x2="12" y2="6" id="Shape-Copy-3" />
      </g>
    </g>
  </svg>
)

Information.defaultProps = {
  width: 50,
  height: 50,
}

Information.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Information
