import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Calendar from './Calendar'
import CalendarSlim from './calendar-slim/CalendarSlim'
import useTranslation from '../../../utils/translation'
import getSbmCalendar from '../../../graphql/GetSbmCalendarQuery.graphql'
import Error from '../error/Error'
import features from '../features'
import settings from '../settings'
import useBrandConfig from '../../hooks/useBrandConfig'
import appInsights, { SeverityLevel } from '../logging/appInsights'

const MAX_DAYS = 62
const PAGE_SIZE = 10
const END_RANGE = moment()
  .add(PAGE_SIZE - 1, 'days')
  .toISOString()
  .split('T')[0]

export default function CalendarHOC2({
  meetingTypeId,
  storeId,
  locationId,
  sbmInstance,
  onChange,
  serviceData,
  noSlotMeetingType,
}) {
  const t = useTranslation('app.slot-selection')
  const [calendarDays, setCalendarDays] = useState([])
  const [moreCalendarDays, setMoreCalendarDays] = useState([])
  const [initialDays, setInitialDays] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [allFetched, setAllFetched] = useState(false)
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const [noDataToShow, setNoDataToShow] = useState(false)
  const { data, loading, error, refetch } = useQuery(getSbmCalendar, {
    fetchPolicy: 'no-cache',
    variables: {
      storeId,
      locationId,
      meetingType: meetingTypeId,
      fromDate: startDate,
      toDate: END_RANGE,
      sbmInstance,
    },
    skip: allFetched || !startDate,
  })

  const brandConfig = useBrandConfig(settings.brand).data

  function reset() {
    setCalendarDays([])
    setInitialDays([])

    const startDateTz =
      brandConfig &&
      brandConfig.ianaTimeZoneId &&
      moment.tz(brandConfig.ianaTimeZoneId).format().split('T')[0]

    setStartDate(startDateTz)
    setAllFetched(false)
    setAllDataLoaded(false)
    setNoDataToShow(false)
  }

  useEffect(() => {
    reset()
  }, [meetingTypeId, brandConfig])

  const loadMoreData = async () => {
    const start = moment().add(PAGE_SIZE, 'days').toISOString().split('T')[0]
    const end = moment().add(MAX_DAYS, 'days').toISOString().split('T')[0]

    const { data: moreData } = await refetch({
      locationId,
      storeId,
      meetingType: meetingTypeId,
      fromDate: start,
      toDate: end,
      sbmInstance,
    })

    if (moreData && moreData.getSbmCalendar) {
      setMoreCalendarDays(moreData.getSbmCalendar)
      setAllDataLoaded(true)
    }
  }

  useEffect(() => {
    setCalendarDays([...calendarDays, ...moreCalendarDays])
  }, [moreCalendarDays])

  useEffect(() => {
    if (data && data.getSbmCalendar) {
      if (data.getSbmCalendar.length === 0) {
        setNoDataToShow(true)
        setAllDataLoaded(true)
        setAllFetched(true)
      } else {
        setCalendarDays(data.getSbmCalendar)

        if (!allFetched) {
          loadMoreData()
          setAllFetched(true)
        }
      }
    }
  }, [data])

  useEffect(() => {
    setInitialDays(calendarDays)
  }, [calendarDays])

  if (error || (!loading && !calendarDays)) {
    appInsights.trackException({
      exception: new Error(
        `Error in calendar2 for brand ${settings.brand}, meetingTypeId ${meetingTypeId}, storeId ${storeId}, locationId ${locationId}, sbmInstance ${sbmInstance}, error: ${error}`
      ),
      severityLevel: SeverityLevel.Error,
    })
    return <Error header={t('error.title')} message={t('error.message')} />
  }

  const filteredDays = settings.disableSameDayBooking
    ? calendarDays.filter((d) => moment(d.day) > moment())
    : calendarDays

  return (
    <>
      {features.newStyleCalendar ? (
        <CalendarSlim
          loading={initialDays.length === 0 && !noDataToShow}
          allDataLoaded={allDataLoaded}
          days={filteredDays || []}
          allDays={filteredDays || []}
          startDate={startDate}
          endDate={END_RANGE}
          onChange={onChange}
          serviceData={serviceData}
          meetingTypeId={meetingTypeId}
          brandConfig={brandConfig}
          showAltStore
        />
      ) : (
          <Calendar
            loading={initialDays.length === 0 && !noDataToShow}
            allDataLoaded={allDataLoaded}
            days={filteredDays || []}
            allDays={filteredDays || []}
            startDate={startDate}
            endDate={END_RANGE}
            onChange={onChange}
            serviceData={serviceData}
            noSlotMeetingType={noSlotMeetingType}
            meetingTypeId={meetingTypeId}
            brandConfig={brandConfig}
          />
      )}
    </>
  )
}
