import React from 'react'

const ArrowDownIcon = ({ width, height, color = '#fff' }) => (
  <svg viewBox="0 0 96 96" width={width} height={height} color={color}>
    <path
      d="M7.145 21.903l-4.29 4.194a2 2 0 0 0-.032 2.829l43.747 44.74a2 2 0 0 0 2.86 0l43.747-44.739a2 2 0 0 0-.032-2.829l-4.29-4.194a2 2 0 0 0-2.828.031L49.43 59.363a2 2 0 0 1-2.86 0L9.973 21.934a2 2 0 0 0-2.828-.031z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowDownIcon
