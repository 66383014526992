import React from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { GoogleReCaptchaConsumer } from './RecaptchaProvider'
import features from '../features'

export const withGoogleReCaptcha = (Component) => {
  if (!features.useRecaptcha) {
    return (props) => <Component {...props} />
  }

  const WithGoogleReCaptchaComponent = (props) => (
    <GoogleReCaptchaConsumer>
      {(googleReCaptchaValues) => (
        <Component {...props} googleReCaptchaProps={googleReCaptchaValues} />
      )}
    </GoogleReCaptchaConsumer>
  )

  WithGoogleReCaptchaComponent.displayName = `withGoogleReCaptcha(${
    Component.displayName || Component.name || 'Component'
  })`

  hoistNonReactStatics(WithGoogleReCaptchaComponent, Component)

  return WithGoogleReCaptchaComponent
}
