import React from 'react'
import styled from 'styled-components'
import { prop } from 'styled-tools'
import warning from 'warning'
import BubbleLoader from './bubbleLoader'
import BouncyLoader from './bouncyLoader'

const LoadingWrapper = styled.div`
  background: ${prop('background')};
`

const LoadingIndicator = ({
  background,
  duration,
  color,
  size,
  small,
  large,
  center,
  type,
  ...props
}) => {
  warning(
    !small,
    '[LoadingIndicator] `small` is deprecated. Use `size="small"` instead.'
  )
  warning(
    !large,
    '[LoadingIndicator] `large` is deprecated. Use `size="large"` instead.'
  )

  let useType = 'bubble'
  if (type) {
    useType = 'bouncy'
  }

  return (
    <LoadingWrapper background={background} {...props}>
      {useType === 'bubble' && (
        <BubbleLoader
          duration={duration}
          color={color}
          center={center}
          size={size || (small && 'small') || (large && 'large') || null}
        />
      )}
      {useType === 'bouncy' && (
        <BouncyLoader
          duration={duration}
          color={color}
          center={center}
          size={size || (small && 'small') || (large && 'large') || null}
        />
      )}
    </LoadingWrapper>
  )
}

LoadingIndicator.defaultProps = {
  ...BubbleLoader.defaultProps,
  background: 'none',
  small: null,
  large: null,
  center: null,
  type: null,
}

export default LoadingIndicator
