import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'
import Headline from '../../typography/headline'
import tracker from '../../../../utils/tracker'
import { getDisplayData } from '../../../containers/bada/content'
import { mediaMax } from '../../../../styles/media'
import useTranslation from '../../../../utils/translation'

import ChangeLocation from './ChangeLocation'
import StoresTabButton from './StoresTabButton'

const StyledHeader = styled.div`
  font-size: 20px;
  font-size: ${theme('typography.paragraph.fontSizeLead')};
  font-size: ${theme('storesHeader.header.fontSize')};
  line-height: 21px;
  line-height: ${theme('typography.paragraph.lineHeightLead')};
  font-weight: ${theme('typography.paragraph.fontWeightLead')};
  margin-bottom: 20px;
  font-family: ${theme('typography.paragraph.fontFamily')};
  color: ${theme('colors.darkGrey')};
  min-height: 22px;

  ${mediaMax(
    'sm',
    css`
      font-size: 16px;
    `
  )};
`

const HeaderContainer = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 14px;

  flex-direction: column;

  ${(props) => props.theme.media.min.sm`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const StyledHeadlineDesktop = styled(Headline)`
  display: none;
  ${(p) => p.theme.media.min.sm`
    display: block;
  `}
  text-transform:inherit;
  text-transform: ${theme('stores.headers.textTransform')};
  margin: 0;
  font-weight: ${theme('stores.headers.fontWeight')};
  line-height: ${theme('stores.headers.lineHeight')};
`

const StyledHeadlineMobile = styled(Headline)`
  ${(p) => p.theme.media.min.sm`
    display: none;
  `}
  text-transform:inherit;
  text-transform: ${theme('stores.headers.textTransform')};
  margin: 0;
`

const TabContainer = styled.dl.attrs({ role: 'presentation' })`
  display: flex;
  flex: 0 0 auto;
  margin: 0;
  margin-bottom: 10px;
  justify-content: flex-start;

  ${(p) => p.theme.media.min.md`
    justify-content: flex-end;
  `}
`

const ChangeLocationContainer = styled.div`
  margin-bottom: 10px;
`

const trackTab = (tab) => {
  tracker.trackClick('BADA 2.0 change store list', tab)
}

/**
 * Gets the `StoresHeader` component markup.
 * @param {{
 *   pageContent: any,
 *   currentTab: "near" | "all",
 *   changeTab: (id: string) => void),
 *   selectedAddress: {
 *     postalCode: string,
 *     addressLine1: string,
 *     addressLine2: string,
 *     addressLine3: string,
 *     city: string,
 *     countryName: string,
 *   },
 *   selectedCity: string,
 *   onAddressChange: (address: any) => void),
 *   toggleAddressChange: boolean,
 *   toggleAddress: () => void),
 *   hasSelectedAddress: boolean,
 * }} props The component properties.
 */
const StoresHeader = (props) => {
  const displayData = getDisplayData(props.pageContent)
  const t = useTranslation()
  return (
    props.selectedAddress && (
      <>
        <HeaderContainer>
          <div>
            {displayData.storeSelectionHeadingDesktop && (
              <StyledHeadlineDesktop>
                {displayData.storeSelectionHeadingDesktop}
              </StyledHeadlineDesktop>
            )}
            {displayData.storeSelectionHeadingMobile && (
              <StyledHeadlineMobile>
                {displayData.storeSelectionHeadingMobile}
              </StyledHeadlineMobile>
            )}
          </div>
        </HeaderContainer>
        {
          <ChangeLocationContainer>
            {props.currentTab === 'near' && (
              <ChangeLocation
                selectedCity={props.selectedCity}
                onAddressChange={props.onAddressChange}
                toggleAddressChange={props.toggleAddressChange}
                toggleAddress={props.toggleAddress}
              />
            )}
            {props.currentTab === 'all' && (
              <StyledHeader>
                {t('app.store-selection.tab.all-header')}
              </StyledHeader>
            )}
          </ChangeLocationContainer>
        }
        {props.hasSelectedAddress && (
          <TabContainer>
            <StoresTabButton
              id="near"
              currentTab={props.currentTab}
              title={t('app.store-selection.tab.nearest')}
              changeTab={(id) => {
                trackTab('closest')
                props.changeTab(id)
              }}
            />
            <StoresTabButton
              id="all"
              currentTab={props.currentTab}
              title={t('app.store-selection.tab.all')}
              changeTab={(id) => {
                trackTab('all stores')
                props.changeTab(id)
              }}
            />
          </TabContainer>
        )}
      </>
    )
  )
}

export default StoresHeader
