import React from 'react'
import styled from 'styled-components'
import settings from '../../components/settings'
import { SectionSpacer, SectionHeader } from './sharedUI'
import {
  isImageFileFormat,
  getFileEnding,
  heicToJpeg,
} from '../../../utils/cloudinary'
import useTranslation from '../../../utils/translation'

const Question = styled.div`
  font-weight: 700;
  font-weight: ${(p) => p.theme.typography.subHeadline.fontWeight};
`

const StyledAnswer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-top: 14px;
  flex-wrap: wrap;
`

const AnswerContainer = styled.div`
  display: inline-block;
  vertical-align: top;
`

const OtherAnswers = styled.div`
  white-space: pre-line;
`

const FileLink = styled.a`
  position: relative;
  width: 150px;
  height: 120px;
  border-radius: 12px;
  text-decoration: none;
  color: black;
  margin: 5px;
  opacity: 1;
  :hover {
    opacity: 0.9;
  }
`

const File = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  background-color: #222;
  color: #fff;
`

const Image = styled.div`
  background: ${(p) => `url(${p.src})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  margin-bottom: 7px;
`

const FormOptionImage = styled(Image)`
  height: 100px;
`

const FormOption = styled.div`
  margin: 4px;
  width: 100px;
  text-align: center;
`

const PrepQuestions = styled.div`
  & > :nth-child(even) {
    background-color: #f5f5f5;
  }
`

const PrepEmptyData = styled.div`
  font-family: ${(p) => p.theme.typography.paragraph.fontFamily};
  font-size: 15px;
  word-wrap: break-word;
  text-align: center;
`

const PrepQuestion = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${(p) => p.theme.typography.paragraph.fontFamily};
  padding: 15px 20px;
`

const FormDataRow = styled.div`
  display: flex;
  margin: 8px 0;
`

const FormKey = styled.div`
  width: 200px;
`
const FormValue = styled.div`
  white-space: pre;
`

const mapQuestionToCmsServiceToGetImageFromOptions = (
  orgQuestions,
  question,
  selectedOptions
) => {
  const orgQuestion =
    orgQuestions &&
    orgQuestions.find(
      (c) => c.questionText.value.toLowerCase() === question.toLowerCase()
    )

  const options =
    orgQuestion &&
    orgQuestion.options &&
    orgQuestion.options.expandedValue &&
    orgQuestion.options.expandedValue.filter((c) =>
      c.contentType.includes('ImageOption')
    )
  const orgAnswerOptions =
    options &&
    options.filter((c) => {
      if (selectedOptions.includes(c.name)) {
        return c
      }
      return null
    })

  return orgAnswerOptions
}

function getAnswers(answers) {
  const images = []
  const files = []
  const other = []
  const forms = []
  answers.forEach((answer) => {
    if (!answer) {
      return
    }

    if (answer.startsWith('https://')) {
      if (isImageFileFormat(answer)) {
        images.push(answer)
      } else {
        files.push(answer)
      }
    } else if (answer.startsWith('{')) {
      const form = JSON.parse(answer)
      const data = Object.fromEntries(
        Object.entries(form).filter(([key]) => key !== 'checkbox')
      )
      const empty = Object.values(data).every((v) => !v)
      if (!empty) {
        forms.push(data)
      }
    } else if (answer) {
      other.push(answer)
    }
  })

  return {
    images,
    files,
    other,
    forms,
  }
}

/**
 * Gets the component for displaying an overview of a prep form's answers.
 * @param {{
 *  customerName: string?,
 *  questions: { question: string, answers: (string | null)[] }[],
 *  orgQuestions: any[]
 * }} params Component parameters.
 */
const Questions = ({ customerName, questions, orgQuestions }) => {
  const t = useTranslation('appointment-details')
  if (!questions || questions.length === 0) {
    return (
      <SectionSpacer>
        <PrepEmptyData>{customerName + t('prep-form.emptydata')}</PrepEmptyData>
      </SectionSpacer>
    )
  }

  return (
    <>
      <SectionSpacer>
        <SectionHeader>{t('prep-form.questions')}</SectionHeader>
      </SectionSpacer>
      <PrepQuestions>
        {questions.map((q) => {
          const { images, files, other, forms } = getAnswers(q.answers)

          const orgAnswerOptions =
            mapQuestionToCmsServiceToGetImageFromOptions(
              orgQuestions,
              q.question,
              other
            ) || []

          if (
            !other.length &&
            !images.length &&
            !files.length &&
            !forms.length
          ) {
            return null
          }

          return (
            <PrepQuestion key={q.question}>
              <Question>{q.question}</Question>
              <StyledAnswer>
                {files.map((file, index) => (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <FileLink key={file} href={file} target="_blank">
                    <File>
                      Upload-{index + 1}.{() => getFileEnding(file)}
                    </File>
                  </FileLink>
                ))}
                {images.map((url) => (
                  // eslint-disable-next-line react/jsx-no-target-blank
                  <FileLink
                    key={url}
                    href={heicToJpeg(settings.cloudinaryUploadUrl, url)}
                    target="_blank"
                  >
                    <Image
                      src={heicToJpeg(settings.cloudinaryUploadUrl, url)}
                      alt={url}
                    />
                  </FileLink>
                ))}
                {forms.map((form) => (
                  <AnswerContainer key={JSON.stringify(form)}>
                    <div>
                      {Object.entries(form).map(([key, value]) => (
                        <FormDataRow key={key}>
                          <FormKey>{key}:</FormKey>
                          <FormValue>{value}</FormValue>
                        </FormDataRow>
                      ))}
                    </div>
                  </AnswerContainer>
                ))}
                {!images.length && !forms.length && other.length && (
                  <OtherAnswers>
                    {other.map((answer, index) => {
                      const orgAnswer = orgAnswerOptions.find(
                        (a) => a.name === answer
                      )

                      return (
                        <AnswerContainer key={answer}>
                          {orgAnswer ? (
                            <FormOption>
                              <FormOptionImage
                                src={orgAnswer.image.value.url}
                              />
                              <div>{answer}</div>
                            </FormOption>
                          ) : (
                            <div>
                              {answer}
                              {!files.length &&
                                !images.length &&
                                index !== q.answers.length - 1 &&
                                !orgAnswer?.image && <>,&nbsp;</>}
                            </div>
                          )}
                        </AnswerContainer>
                      )
                    })}
                  </OtherAnswers>
                )}
              </StyledAnswer>
            </PrepQuestion>
          )
        })}
      </PrepQuestions>
    </>
  )
}

export default Questions
