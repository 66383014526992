import styled from 'styled-components'
import { theme } from 'styled-tools'
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Column } from '../grid'

const AlertContainer = styled.div`
  background-color: #fdb1b2;
  border-radius: 5px;
  max-width: 280px;
  margin: 20px auto 30px auto;
  padding: 10px 20px 5px 20px;
  ${(props) => props.theme.media.min.sm`
    max-width: 550px;
    padding: 10px 20px 10px 20px;
    margin: 40px auto 10px auto;
  `}
`
const AlertHeading = styled.h2`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  font-family: ${theme('typography.paragraph.fontFamily')};
  letter-spacing: 0px;
  margin-bottom: 8px;
  margin-top: 10px;
  ${(props) => props.theme.media.min.sm`
    letter-spacing: 0.3px;
  `}
`
const AlertPreamble = styled.p`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  font-family: ${theme('typography.paragraph.fontFamily')};
  max-width: 480px;
  margin: 0px auto 15px auto;
  ${(props) => props.theme.media.min.sm`
    font-size: 14px;
  `}
`

const Alert = ({ alertHeading, alertPreamble }) => (
  <Row>
    <Column>
      <AlertContainer>
        <AlertHeading>{alertHeading}</AlertHeading>
        <AlertPreamble>{alertPreamble}</AlertPreamble>
      </AlertContainer>
    </Column>
  </Row>
)

Alert.defaultProps = {
  alertHeading: '',
  alertPreamble: '',
}

Alert.propTypes = {
  alertHeading: PropTypes.string,
  alertPreamble: PropTypes.string,
}

export default Alert
