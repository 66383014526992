import { useQuery } from '@apollo/react-hooks'
import cmsPageContentQuery from '../../graphql/CmsPageQuery.graphql'
import { appBaseUrl } from '../../utils/appBaseUrl'

const useCmsContent = () =>
  useQuery(cmsPageContentQuery, {
    variables: {
      url: appBaseUrl,
    },
  })

export default useCmsContent
