import { createGlobalStyle } from 'styled-components'
import sanitize from '../styles/sanitize'

export default createGlobalStyle`
  ${sanitize};

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
  }

  button {
    background-color: transparent;
  }

  strong {
    font-weight: 700;
  }
`
