import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import useTranslation from '../../../utils/translation'

const Message = styled.div`
  font-size: 11px;
  color: ${theme('colors.gray')};
  a {
    color: ${theme('colors.gray')};
  }
`

export const RecaptchaMessage = () => {
  const t = useTranslation('app.recaptcha')
  return (
    <Message
      dangerouslySetInnerHTML={{
        __html: t('message'),
      }}
    />
  )
}
