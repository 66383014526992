import React from 'react'

const LocationIcon = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 11 13" color={color}>
    <g>
      <g>
        <g>
          <path
            fill="none"
            stroke="#001b33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="1.2"
            d="M9.612 5.546c0 3.404-4.376 6.321-4.376 6.321v0S.86 8.95.86 5.547a4.376 4.376 0 0 1 8.752 0z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#001b33"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="1.2"
            d="M5.239 6.997a1.459 1.459 0 1 0 0-2.917 1.459 1.459 0 0 0 0 2.917z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default LocationIcon
