import React from 'react'
import PropTypes from 'prop-types'

const Doors = ({ width, height }) => (
  <svg viewBox="0 0 34 25" width={width} height={height}>
    <path
      d="M32,0 C33.1045695,0 34,0.8954305 34,2 L34,23 C34,24.1045695 33.1045695,25 32,25 L2,25 C0.8954305,25 0,24.1045695 0,23 L0,2 C0,0.8954305 0.8954305,0 2,0 L32,0 Z M16,2 L2,2 L2,23 L16,23 L16,2 Z M32,2 L18,2 L18,23 L32,23 L32,2 Z M13,14 C13.5522847,14 14,14.4477153 14,15 L14,20 C14,20.5522847 13.5522847,21 13,21 C12.4477153,21 12,20.5522847 12,20 L12,15 C12,14.4477153 12.4477153,14 13,14 Z M21,14 C21.5522847,14 22,14.4477153 22,15 L22,20 C22,20.5522847 21.5522847,21 21,21 C20.4477153,21 20,20.5522847 20,20 L20,15 C20,14.4477153 20.4477153,14 21,14 Z"
      id="Combined-Shape"
    />
  </svg>
)

Doors.defaultProps = {
  width: 50,
  height: 50,
}

Doors.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Doors
