import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'recompose'
import { scrollTo } from '../utils'
import { StepStores } from './Components'
import { badaPropTypes, badaDefaultProps } from '../../../../types'
import tracker from '../../../../utils/tracker'

class StoresStep extends Component {
  render() {
    const {
      props: {
        badaProps: { store, stores },
        wizard: {
          step: { one },
        },
        preselected,
        pageContent,
        storeId,
        selectedAddress,
        selectedCity,
        selectedStore,
        customerAddress,
        updateUiState,
        storesByAddressData,
        history,
      },
    } = this.props

    const cityName =
      storesByAddressData?.searchOpenAuthStoresByAddress?.locationName || ''
    const cityValue =
      !storesByAddressData.loading &&
      !selectedCity &&
      selectedAddress &&
      selectedAddress.postalCode
        ? cityName || selectedAddress.postalCode
        : selectedCity

    return (
      <>
        <StepStores
          show={one}
          props={{
            pageContent,
            storeId,
            store,
            stores,
            selectedAddress,
            selectedCity: cityValue,
            selectedStore,
            checked: !!store,
            storesByAddressData,
            customerAddress,
            storeIsPreSelectable: preselected,
            onChange: (state) => {
              const service = {}
              if (!state.preselected) {
                service.selectedService = null
              }

              updateUiState({
                ...state,
                ...service,
              })

              if (
                state.selectedStore &&
                !state.preselected &&
                !state.preSelectedMeetingTypeId
              ) {
                history.push(`${window.location.search}#select`, {
                  ...state,
                  ...service,
                  selectedService: null,
                  bookingCompleted: false,
                })

                scrollTo(this.ref)
              }
              if (state.selectedAddress) {
                history.push(`${window.location.search}#location`, {
                  ...state,
                })
                scrollTo(this.ref)
              }

              if (state.selectedStore && !state.preselected) {
                tracker.trackClick(
                  'BADA 2.0 pick a store',
                  `${state.selectedStore.name} - ${state.selectedStore.id} - ${state.selectedStore.distanceInKilometers}km`
                )
              }
            },
          }}
        />
        <div
          ref={(ref) => {
            this.ref = ref
          }}
        />
      </>
    )
  }
}

StoresStep.propTypes = {
  ...badaPropTypes,
}

StoresStep.defaultProps = {
  ...badaDefaultProps,
}

const enhance = compose(withApollo)

export default enhance(StoresStep)
