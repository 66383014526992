import { Component } from 'react'
import PropTypes from 'prop-types'
import PortalRegistry from './PortalRegistry'

/* eslint-disable react/sort-comp */
class PortalProvider extends Component {
  constructor(props) {
    super(props)
    this.portalRegistry = new PortalRegistry()
  }

  getChildContext() {
    return {
      portalRegistry: this.portalRegistry,
    }
  }

  render() {
    return this.props.children
  }
}

PortalProvider.childContextTypes = {
  portalRegistry: PropTypes.instanceOf(PortalRegistry).isRequired,
}

export default PortalProvider
