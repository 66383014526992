import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { prop } from 'styled-tools'
import { v4 } from 'uuid'
import Dropzone from './Dropzone'
import FileInput from './FileInput'
import GalleryThumbnail from './GalleryThumbnail'
import ErrorMessage from './ErrorMessage'
import { mediaMin } from '../../../styles/media'
import { Row, Column } from '../grid'
import { Title } from '../text'
import Button from '../button/baseButton'

const StyledDropzone = styled(Dropzone)`
  width: 100%;
`

const StyledLabel = styled(Button).attrs({ as: 'label' })`
  position: relative;
  z-index: 1;
  margin: 0;
  text-align: center;
`

const GalleryThumbnailsRow = styled(Row)`
  margin: -5px;
  padding: 0px 0px 15px;
`

const GalleryThumbnailColumn = styled(Column)`
  padding: 5px;
`

const FileInputRow = styled(Row)`
  border: 2px dashed ${prop('theme.colors.borderPrimary')};
  margin: 0;
  margin-bottom: 15px;
  padding: 20px 15px;
  align-items: center;
`

const StyledFileInput = styled(FileInput)`
  display: block !important;

  ${mediaMin(
    'sm',
    css`
      display: inline-block !important;
    `
  )};
`

const StyledTitle = styled(Title)`
  color: ${prop('theme.colors.borderPrimary')};
`

const Gallery = ({
  uploader,
  files,
  errors,
  multiple,
  debug,
  onClickThumbnail,
  fileInputLabel,
  buttonText,
}) => {
  const hasFiles = files.length > 0
  const hasErrors = errors.length > 0
  const id = useMemo(v4, [])
  return (
    <StyledDropzone uploader={uploader} multiple={multiple}>
      {hasFiles && (
        <GalleryThumbnailsRow debug={debug}>
          {files.map((file) => (
            <GalleryThumbnailColumn xs={6} sm={4} md={3} key={file.id}>
              <GalleryThumbnail
                id={file.id}
                fromServer={file.fromServer}
                uploader={uploader}
                status={file.status}
                metadata={file.metadata}
                onClick={onClickThumbnail}
                deleteButton={file.metadata && !!file.metadata.blobName}
              />
            </GalleryThumbnailColumn>
          ))}
        </GalleryThumbnailsRow>
      )}
      {(multiple || (!hasFiles && !multiple)) && (
        <FileInputRow justifyContent="space-between">
          {fileInputLabel && (
            <Column sm="auto">
              <StyledTitle>{fileInputLabel}</StyledTitle>
            </Column>
          )}
          <Column sm="auto" fluid>
            <StyledFileInput
              id={`fileInput_${id}`}
              uploader={uploader}
              multiple={multiple}
            >
              <StyledLabel htmlFor={`fileInput_${id}`} size="small" block>
                {buttonText || 'Browse'}
              </StyledLabel>
            </StyledFileInput>
          </Column>
        </FileInputRow>
      )}
      {hasErrors && (
        <Row>
          <Column>
            {errors.map((error) => (
              <ErrorMessage {...error} key={error.id} uploader={uploader} />
            ))}
          </Column>
        </Row>
      )}
    </StyledDropzone>
  )
}

Gallery.defaultProps = {
  multiple: true,
  files: [],
  errors: [],
  debug: false,
  onClickThumbnail: null,
  fileInputLabel: null,
  buttonText: null,
}

export default Gallery
