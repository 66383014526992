import React from 'react'

const ArrowRightIcon = ({ width, height, color }) => (
  <svg viewBox="0 0 96 96" width={width} height={height} color={color}>
    <path
      d="M23.903 88.855l4.194 4.29a2 2 0 0 0 2.829.032l44.74-43.747a2 2 0 0 0 0-2.86L30.927 2.823a2 2 0 0 0-2.829.032l-4.194 4.29a2 2 0 0 0 .031 2.828L61.363 46.57a2 2 0 0 1 0 2.86L23.934 86.027a2 2 0 0 0-.031 2.828z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowRightIcon
