import { ensureSubsystem } from '../../../utils/meetingType'
import { CUSTOMER_FORM, SIMPLY_BOOK_ME } from './reducer/Wizard'
import settings from '../../components/settings'

const getServiceData = (service) => {
  const data = {
    id: service.serviceId,
    code: service.serviceCode,
    name: service.name,
    heading: service.heading,
    type: service.serviceType,
    questions: [],
  }
  if (service.blocks) {
    service.blocks.reduce((acc, block) => {
      if (block.key) {
        // eslint-disable-next-line no-underscore-dangle
        if (block.text && block.__typename === 'TextBlock') {
          acc[block.key] = block.text
          // eslint-disable-next-line no-underscore-dangle
        } else if (block.__typename === 'BooleanBlock') {
          acc[block.key] = block.value
        } else if (block.image) {
          acc[block.key] = {
            ...block.image,
            url: block.image.url,
            alt: block.details && block.details.alt ? block.details.alt : null,
          }
        } else if (block.key === 'questions') {
          acc.questions.push(block)
        }
      }
      return acc
    }, data)
  }

  return data
}

const getDisplayData = (content) => {
  const displayData = {
    imagesDesktop: [],
    imagesMobile: [],
    uspsDesktop: [],
    uspsMobile: [],
    otherNeeds: [],
  }
  if (content && content.blocks) {
    content.blocks.reduce((acc, block) => {
      if (block.key) {
        switch (block.key) {
          // Services
          case 'meetingTypes':
            acc.services = block.meetingTypes.map((meetingType) =>
              getServiceData(meetingType)
            )
            break
          // Content Header
          case 'backgroundImageDesktop':
            if (block.image && block.image.url) {
              acc.backgroundImageDesktop = block.image.url
            }
            break
          case 'backgroundImageMobile':
            if (block.image && block.image.url) {
              acc.backgroundImageMobile = block.image.url
            }
            break
          case 'imagesDesktop':
            if (block.image && block.image.url) {
              acc.imagesDesktop.push(block.image.url)
            }
            break
          case 'imagesMobile':
            if (block.image && block.image.url) {
              acc.imagesMobile.push(block.image.url)
            }
            break
          case 'imageUspsDesktop':
            acc.uspsDesktop.push(block)
            break
          case 'imageUspsMobile':
            acc.uspsMobile.push(block)
            break
          case 'additionalMeetingTypeInformation':
            if (block.heading && block.text) {
              acc.otherNeeds.push(block)
            }
            break
          default:
            // eslint-disable-next-line no-underscore-dangle
            if (block.__typename === 'TextBlock') {
              acc[block.key] = block.text
            }
            // eslint-disable-next-line no-underscore-dangle
            if (block.__typename === 'BooleanBlock') {
              acc[block.key] = block.value
            }
            break
        }
      }
      return acc
    }, displayData)
  }
  return displayData
}

const findServiceData = (displayData, selectedService) => {
  if (!displayData || !displayData.services || !displayData.services.length < 0)
    return null

  if (!selectedService) return null

  const serviceData = displayData.services.find(
    (f) =>
      f.id === parseInt(selectedService.serviceId, 10) &&
      f.serviceType === selectedService.serviceType &&
      f.code === (selectedService.code || selectedService.id) // yes, this is terrible code
  )
  return serviceData
}

const getMeetingTypeContent = ({
  meetingTypes,
  currentMeetingTypeName,
  bookingSystem,
  storeSubSystem,
  serviceId,
}) => {
  if (!currentMeetingTypeName) return null

  let meetingTypeName = currentMeetingTypeName
    .replace('slot', '')
    .toLowerCase()
    .trim()

  if (bookingSystem === SIMPLY_BOOK_ME && meetingTypeName === SIMPLY_BOOK_ME) {
    meetingTypeName = 'store'
  }

  let currentMeetingType = CUSTOMER_FORM
  switch (bookingSystem) {
    case SIMPLY_BOOK_ME:
      currentMeetingType = SIMPLY_BOOK_ME
      break
    default:
      currentMeetingType = CUSTOMER_FORM
      break
  }

  const filteredMeetingTypesBySubsystem = meetingTypes?.filter((m) =>
    ensureSubsystem(m, storeSubSystem, settings.serviceMappings)
  )

  const meetingType =
    filteredMeetingTypesBySubsystem &&
    filteredMeetingTypesBySubsystem.find(
      (f) =>
        f.code.trim() === meetingTypeName &&
        f.type === currentMeetingType &&
        (currentMeetingType === CUSTOMER_FORM || f.id === serviceId)
    )

  return meetingType
}

const getMeetingTypeName = (appointment) => {
  let simplified = ''
  if (appointment && appointment.externalMeetingProvider) {
    simplified = appointment.externalMeetingProvider
      .replace('-appointment', '')
      .replace('slot', '')

    if (simplified === SIMPLY_BOOK_ME) {
      simplified = 'store'
    }
  }

  return simplified
}

const getMeetingTypeMeta = (appointment, customerFormInput) => {
  let meetingTypeName = ''
  let storeSubSystem = ''
  let serviceId = ''
  let bookingSystem = ''
  if (appointment?.appointment?.externalMeetingProvider) {
    meetingTypeName = appointment.appointment.externalMeetingProvider.replace(
      '-appointment',
      ''
    )
    storeSubSystem = appointment.appointment.simplyBookMeSubsystem
    serviceId = appointment.appointment.serviceId
    bookingSystem = appointment.appointment.bookingSystem
  } else if (customerFormInput?.service) {
    meetingTypeName = customerFormInput.service
    storeSubSystem = customerFormInput.subsystem
    serviceId = customerFormInput.serviceId
    bookingSystem = customerFormInput.bookingSystem
  }
  return { meetingTypeName, storeSubSystem, serviceId, bookingSystem }
}

const getService = ({
  appointment,
  pageContentPage,
  bookingSystem,
  storeSubSystem,
}) => {
  const content = getDisplayData(pageContentPage)
  const serviceName = getMeetingTypeName(appointment)
  const serviceContent = getMeetingTypeContent({
    meetingTypes: content.services,
    currentMeetingTypeName: serviceName,
    bookingSystem,
    storeSubSystem,
    serviceId: appointment.serviceId,
  })

  return serviceContent
}

export {
  getDisplayData,
  findServiceData,
  getMeetingTypeContent,
  getMeetingTypeName,
  getMeetingTypeMeta,
  getService,
}
