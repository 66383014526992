import { createFactory } from 'react'
import { setDisplayName, wrapDisplayName } from 'recompose'
import globals from '../globals'

export default globals.features
export const has = (key) => globals.features[key] !== undefined
export const get = (key, defaultValue) =>
  has(key) ? globals.features[key] : defaultValue

export const featureIf = (key, left, right) =>
  get(key) === true ? left : right

export const featureSwitch = (key, cases, defaultValue) => {
  const value = get(key)

  if (value in cases) {
    return cases[value]
  }

  return defaultValue
}

// HOC based on `recompose/branch`
export const featureBranch = (key, left, right) => (BaseComponent) => {
  let leftFactory
  let rightFactory

  const BranchFeature = (props) => {
    if (get(key) === true) {
      if (!leftFactory) {
        leftFactory = createFactory(left(BaseComponent))
      }

      return leftFactory(props)
    }

    if (!rightFactory) {
      rightFactory = createFactory(right ? right(BaseComponent) : BaseComponent)
    }

    return rightFactory(props)
  }

  if (process.env.NODE_ENV !== 'production') {
    const displayName = wrapDisplayName(BaseComponent, 'branchFeature')

    return setDisplayName(displayName)(BranchFeature)
  }

  return BranchFeature
}
