import React from 'react'

const Checkmark = (props) => (
  <svg {...props} viewBox="0 0 58 58">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    >
      <g
        transform="translate(-399.000000, -320.000000)"
        stroke={props.color}
        strokeWidth="2"
      >
        <g transform="translate(400.000000, 321.000000)">
          <path d="M28,56 C43.463973,56 56,43.463973 56,28 C56,12.536027 43.463973,0 28,0 C12.536027,0 0,12.536027 0,28 C0,43.463973 12.536027,56 28,56 Z M14.4583333,28.4230769 L25,39 M42,19 L25.4347826,38.5652174" />
        </g>
      </g>
    </g>
  </svg>
)

export default Checkmark
